import { environment } from '../../environments/environment';
// Placeholder for all Messages used in the application
var MessageConstants = /** @class */ (function () {
    function MessageConstants() {
    }
    // Direct Sales
    MessageConstants.UNABLE_TO_QUOTE = 'We are unable to provide a quote at this time.';
    // Login Screen
    MessageConstants.REQUIRED_USEREMAIL = 'Email is required';
    MessageConstants.REQUIRED_PASSWORD = 'Password is required';
    MessageConstants.FORMAT_USEREMAIL = 'Email has invalid format';
    // Payment Screens
    MessageConstants.REQUIRED_ROUTING_NUMBER = 'Routing Number is required';
    MessageConstants.REQUIRED_ACCOUNT_TYPE = 'Account Type is required';
    MessageConstants.REQUIRED_ACCOUNT_NUMBER = 'Account Number is required';
    MessageConstants.MATCH_ACCOUNT_NUMBER = 'Account Number does not match';
    MessageConstants.LENGTH_ROUTING_NUMBER = 'Routing number must be 9 digits';
    MessageConstants.LENGTH_ACCOUNT_NUMBER = 'Account number must be 12 digits';
    MessageConstants.REQUIRED_CCNUMBER = 'Card Number is required';
    MessageConstants.REQUIRED_CCEXP = 'Expiration is required';
    MessageConstants.REQUIRED_CCCVC = 'CVC is required';
    MessageConstants.REQUIRED_FIRSTNAME = 'First Name is required';
    MessageConstants.REQUIRED_ADDRESS = 'Address is required';
    MessageConstants.REQUIRED_CITY = 'City is required';
    MessageConstants.REQUIRED_STATE = 'State is required';
    MessageConstants.REQUIRED_AMOUNT = 'Amount is required';
    MessageConstants.REQUIRED_METHOD = 'Payment Method is required';
    MessageConstants.REQUIRED_OTHERAMOUNT = 'Other Amount is required';
    MessageConstants.PAY_NO_PAYMENTS = 'There are no remaining payments on this policy. Please contact your agent if you ' +
        'have any questions regarding your policy.';
    MessageConstants.REQUIRED_TERMS_CONDITIONS = 'Terms and Conditions are required';
    MessageConstants.REQUIRED_CANCEL_TERMS_CONDITIONS = 'Please review the Terms and Conditions, ' +
        'the \'I Agree\' check box must be acknowledged before proceeding.';
    MessageConstants.REQUIRED_PREMIUM_CHG_ACCEPT = 'Please check the acknowledgment box before confirming your request.';
    MessageConstants.PAY_SERVICE_DOWN = 'We are unable to retrieve your payment details at this time. Please try ' +
        'again or call our Service Operations at ';
    MessageConstants.PAYMENT_ERROR = 'We are unable to process your request online at this time. Please call our Service ' +
        'Operations during normal business hours at 1-888-888-0080 to make a payment.';
    MessageConstants.PAYMENT_METHOD_TYPE = 'Payment method type is required';
    // Registration Screen
    MessageConstants.REQUIRED_LASTNAME = 'Last Name is required';
    MessageConstants.REQUIRED_ZIPCODE = 'Zip Code is required';
    MessageConstants.REQUIRED_DOB = 'Date of Birth is required';
    MessageConstants.REQUIRED_POLICYNUMBER = 'Policy Number is required';
    MessageConstants.FORMAT_DOB = 'Please enter your date of birth in MM/DD/YYYY format';
    MessageConstants.FORMAT_POLICYNUMBER = 'Please enter the policy number in the format xxx-xxxxxxx-xx';
    MessageConstants.LENGTH_ZIPCODE = 'Zip Code must be 5 digits long.';
    MessageConstants.REQUIRED_PHEMAIL = 'Email is required';
    MessageConstants.FORMAT_PHEMAIL = 'Email  has invalid format';
    MessageConstants.MATCH_PHEMAIL = 'Email  does not match';
    MessageConstants.REQUIRED_PHPASSWORD = 'Password is required';
    MessageConstants.MINLEN_PHPASSWORD_NEW = 'Password must be 8 characters long with at least one numeric value, at least one uppercase , at least one lowercase, at least one special character';
    MessageConstants.MAXLEN_PHPASSWORD_NEW = 'Password must be 8 characters long with at least one numeric value, at least one uppercase , at least one lowercase, at least one special character';
    MessageConstants.MATCH_PHPASSWORD = 'Confirmed password does not match the password entered';
    MessageConstants.COMPLEXITY_PHPASSWORD_NEW = 'Password do not match the requirement';
    MessageConstants.MINLEN_PHPASSWORD = 'Password must be 6 characters long with at least one numeric value';
    MessageConstants.MAXLEN_PHPASSWORD = 'Password must be 6 characters long with at least one numeric value';
    MessageConstants.COMPLEXITY_PHPASSWORD = 'Password must be 6 characters long with at least one numeric value';
    MessageConstants.REQUIRED_PHSECQSN = 'Security Question is required';
    MessageConstants.REQUIRED_PHSECANS = 'Security Answer is required';
    MessageConstants.MATCH_SECQSN = 'Duplicate Security Questions are selected';
    MessageConstants.MATCH_SECANS = 'Please provide different answers for the security questions';
    MessageConstants.LOOKUP_ERR_MSG = 'The last name entered does not match our records for the Policy provided.';
    MessageConstants.INSERTPH_ERR_MSG = 'There is a problem Registering the User. Please contact our Service Operations at 1-888-888-0080';
    MessageConstants.LOGIN_ERROR = 'Login Failed.\n\nYour Email and/or Password was entered incorrectly.\nPlease correct and try again.';
    MessageConstants.POLICY_CANCELLED_MSG = 'There is a conflict in email address.Please choose another email address in Step2 page';
    // Registration Screen Edits
    MessageConstants.CA44_ERROR = '<h5>Your Policy type cannot be registered here.</h5> ' +
        '<p class="pt-1">&nbsp;</p><p>Please go to <a href="http://www.farmersspecialtyauto.com" target="_blank">' +
        'http://www.farmersspecialtyauto.com</a> to register your policy!</p>';
    MessageConstants.TFC_ERROR = '<h5>Your Policy type cannot be registered here.</h5> ' +
        '<p class="pt-1">&nbsp;</p<p>Please go to <a href="http://www.21st.com" target="_blank">http://www.21st.com</a> ' +
        'to register your policy!</p>';
    MessageConstants.ESIG_ERROR = 'Please go to <a href="http://www.esig.com" target="_blank" ' +
        'class="nav-link">http://www.esig.com</a> to complete your esig process!';
    MessageConstants.RESPCODE_98 = 'One or more of the data items provided are incorrect - please check your entries ' +
        'and try again.';
    MessageConstants.RESPCODE_97 = 'You are already registered; you do not need to register again. Please use the Login page to Login.';
    MessageConstants.RESPCODE_11 = 'The DOB entered does not match our records for the Policy provided. Please enter the ' +
        'DOB listed for the policy holder.';
    MessageConstants.RESPCODE_10 = 'The ZIP code entered does not match our records for the Policy provided. Please enter ' +
        'the mailing ZIP code listed for the policy holder.';
    MessageConstants.RESPCODE_90 = 'The Policy number entered was not found. Please enter the correct policy ' +
        'number listed for the policy holder.';
    MessageConstants.RESPCODE_91 = 'Please enter the correct Last Name listed for the policy holder.';
    MessageConstants.RESPCODE_100 = 'Service Error';
    MessageConstants.EMAIL_UPDATE_ERROR = 'Error Updating the Email Address';
    // Payment Screen Edits
    MessageConstants.PAYMENT_NSF = 'We are unable to process your payment due to Insufficient Funds.';
    // Docusign Response Edits
    MessageConstants.DOCUSIGN_CANCEL_MSG = 'You have chosen to CANCEL the electronic signature. Your policy may be cancelled or ' +
        'coverage changes may be made which will increase your policy premium. Please select Continue to proceed.';
    MessageConstants.DOCUSIGN_DECLINE_MSG = 'You have chosen to DECLINE the electronic signature. Your policy may be cancelled ' +
        'or coverage changes may be made which will increase your policy premium. Please select Continue to proceed.';
    MessageConstants.DOCUSIGN_EXCEPTION_MSG = 'An Exception occured during the electronic signature process. Please select ' +
        'Continue to return to the electronic signature process.';
    MessageConstants.DOCUSIGN_IDCHECK_FAILED_MSG = 'Your Identification Check Failed. Please select Continue to return to the ' +
        'electronic signature process.';
    MessageConstants.DOCUSIGN_SESSION_TIMEOUT_MSG = 'Your electronic signing session has timed out. Please select Continue ' +
        'to return to the electronic signature process.';
    MessageConstants.DOCUSIGN_COMPLETE_MSG = 'Congratulations on successfully completing the electronic signature process.';
    MessageConstants.DOCUSIGN_TTL_EXPIRED_MSG = 'Your Authentication with the vendor has expired. Please click Continue to ' +
        'attempt to electronically sign again.';
    MessageConstants.DOCUSIGN_VIEWING_COMPLETE_MSG = 'Thank you for viewing your documents. Please select Continue to proceed.';
    MessageConstants.DOCUSIGN_PENDING_MSG = 'A Document requires your Electronic Signature! Please click the Continue button to ' +
        'begin the eSignature process.';
    MessageConstants.DOCUSIGN_PENDING_STATE_SPECIFIC_MSG = 'A Document requires your Signature! Please click the Continue button ' +
        'to begin the Signature process.'; // keerthana
    MessageConstants.DOCUSIGN_PENDING_PNI_MSG = 'A Document requires your Electronic Signature due by {0}. Please select the ' +
        'Continue button to begin the eSignature Process.';
    MessageConstants.DOCUSIGN_PENDING_SECONDARY_MSG = 'A document requires the Additional Named Insured to sign by {0}. In ' +
        'order to access your policy information, you must ensure that the Additional Named Insured has signed before ' +
        'selecting Check For Updates.';
    MessageConstants.DOCUSIGN_LANDING_REGISTER_MSG = 'Thank you for signing up for eSignature. Please select Continue to ' +
        'register your policy.';
    MessageConstants.DOCUSIGN_LANDING_LOGIN_MSG = 'Thank you for signing up for eSignature. Your policy is already registered. ' +
        'Please select Continue to log in.';
    MessageConstants.INVALID_EMAIL = 'Please enter a valid email';
    MessageConstants.EMAIL_REGISTERED = 'The username/email entered already exists, please enter a different username/email';
    // FEEDBACK Messages
    MessageConstants.FEEDBACKRATE_REQUIRED = 'Please choose a rating above';
    // ESIG Messages
    MessageConstants.ESIG_PENDING_PNI_MESSAGE = 'A Document requires your Electronic Signature due by {0}. ' +
        'Please select the Continue button to begin the eSignature Process.';
    MessageConstants.ESIG_PENDING_SEC_MESSAGE = 'A document requires the Additional Named Insured to sign by {0}. ' +
        'In order to access your policy information, you must ensure that the Additional Named Insured ' +
        'has signed before selecting Check For Updates';
    MessageConstants.ESIG_PENDING_STATE_MESSAGE = 'A Document requires your Signature by {0}. ' +
        'Please select the Continue button to begin the Signature Process.'; // keerthana
    // External Landing Messages
    MessageConstants.GP_LOGIN_MSG = 'Thank you for signing up for Go Paperless. Please Login in to accept Terms & Conditions';
    MessageConstants.GP_REG_MSG = 'Thank you  for signing up for Go Paperless. ' +
        'Please continue to register and accept Terms & Conditions';
    MessageConstants.ESIG_LOGIN_MSG = 'Thank you for signing up for eSignature. Your policy is already registered. ' +
        'Please select Continue to log in';
    MessageConstants.ESIG_REG_MSG = 'Thank you for signing up for eSignature. ' +
        'Please select Continue to register your Policy';
    MessageConstants.WELCOME_LOGIN_MSG = 'Thank you for choosing business with BristolWest.com. Please log in to access your policy';
    MessageConstants.WELCOME_REG_MSG = 'Thank you for choosing business with BristolWest.com. ' +
        'Please select Continue to register your Policy';
    // EFT Messages
    MessageConstants.MANAGE_AUTOPAY_MSG = 'This information may not reflect scheduled payments or payments made in the last 24 hours.';
    MessageConstants.CHK_PREMIUM_MSG = 'Please confirm that you understand that a direct debit change may cause a change in premium. ' +
        'The quoted premium is subject to review and verification by Bristol West. ' +
        'All future installment amounts are not verified until the next business day after ' +
        'the current transaction is processed. You will receive updated documents via the mail. ' +
        'You can also view your updated information on BristolWest.com the next business day.';
    MessageConstants.CHK_CODE_UPDATE = 'Your Direct Debit account information has been updated.  As a reminder, due to the timing of your ' +
        'previous Direct Debit Enrollment Request, your payment due on {0} will not be automatically debited. ' +
        'You may check your payment schedule on our website at www.bristolwest.com.';
    MessageConstants.CHK_CODE_TERMINATE = 'Your enrollment in Direct Debit has been terminated. All future payments will not be ' +
        'automatically debited and must be remitted.  You may check your payment schedule on our website at www.bristolwest.com.';
    MessageConstants.NON_SWEEP_TXT_ADD = 'There is not enough time to enroll in the Electronic Funds Transfer (EFT) program before the ' +
        'next due date. Please confirm that you understand the payment due on {0} will not be ' +
        'automatically debited.';
    MessageConstants.NON_SWEEP_TXT_UPDATE = 'Due to the timing of your request, it may not be possible to make your requested change before ' +
        'the next payment debit. Please confirm that you understand the debit on {0} may not be ' +
        'charged to the new account.';
    MessageConstants.NON_SWEEP_TXT_TERMINATE = 'There is not enough time to stop your enrollment in the Electronic Funds Transfer (EFT) ' +
        'program before the next due date. Please confirm that you understand the debit on {0} will not be stopped.';
    MessageConstants.BRISTOLWEST_URL = 'http://www.21st.com';
    MessageConstants.FARMERS_SPECILATY_URL = 'http://www.farmersspecialtyauto.com';
    MessageConstants.EFT_TERMINATE_POLICY_CANCEL_NOTE = "If the intent is to cancel your policy instead of terminating Auto Pay (Direct Debit), please access ";
    MessageConstants.EFT_TERMINATE_TERMS_HEADER = 'You\'ve selected to terminate Direct Debit. Please read the Terms and Conditions ' +
        'below and select "Next" to continue your termination of Direct Debit request';
    MessageConstants.EFT_TERMINATE_TERMSNC = 'By clicking the box below, I hereby elect to discontinue installment payments in the form ' +
        'of electronic funds transfer (EFT). I understand that the company must receive this termination ' +
        'notice at least three (3) business days prior to the current installment due date. Otherwise, the ' +
        'payment will be debited from my account via EFT and this termination will be effective the next scheduled ' +
        'payment due date. In the event that EFT is terminated for the current installment, I understand that I ' +
        'continue to be obligated to make the current payment due as outlined on the payment schedule I received when ' +
        'I signed up for EFT. Note: Once processing of this termination request is complete, you will receive a letter ' +
        'advising you that your electronic funds transfer payment method has been terminated. At that time, all future ' +
        'premium payments must be made in the form of a check, money order or on-line at www.bristolwest.com.';
    MessageConstants.EFT_TERMINATE_TC_CHK = 'I have read and accept all of the Terms and Conditions above. You must agree to the ' +
        'Terms and Conditions in order to terminate direct debit.';
    MessageConstants.DISCOUNTS_EMPTY = 'There are no discounts.';
    MessageConstants.COVERAGES_EMPTY = 'No applicable coverages on this vehicle.';
    MessageConstants.MOBILE_NUMBER_FORMAT = 'Please enter 10-digit phone number';
    MessageConstants.REQUIRED_MOBILENUMBER = 'Mobile Number is required';
    MessageConstants.REQUIRED_PHONENUMBER = 'Phone Number is required';
    // POLICY CANCELLATION PAGE MSGS
    MessageConstants.REQUIRED_CANCEL_DATE = 'Cancel Date is required';
    MessageConstants.CANCEL_TERMS_N_COND = 'By submitting this request to cancel my policy, I hereby confirm my intent to cancel my policy ' +
        'for the reason and on the date specified above. I understand I will no longer have coverage under my policy ' +
        'after the cancellation date I have specified and I should not drive my vehicle without insurance coverage.';
    MessageConstants.CANCEL_CONFIRMATION = 'Your request for cancellation was received and will be processed within 24 hours. ' +
        'The confirmation number is ';
    MessageConstants.CANCEL_CONFIRM_MSG = 'You are requesting to cancel your policy. Once the policy is cancelled it may not be able to ' +
        'be reinstated. Please select ‘OK’ below if you wish to continue processing the cancellation request.';
    MessageConstants.CANCEL_HASRENEWAL_MSG = 'There is a pending renewal offer for this policy, by cancelling your current policy ' +
        'the renewal offer will be voided. To continue with the cancel request please select ‘I Agree’ below.';
    MessageConstants.CANCEL_HASEFT_MSG = 'If the Cancellation of this policy is processed within 3 business days of the current ' +
        'installment due date, the withdraw from your account may still occur period';
    MessageConstants.CANCEL_DATE_ERROR = 'Cancel date selected is outside of the range allowed. Please contact your producer ' +
        'for assistance.';
    MessageConstants.CANCEL_AMOUNT_CALC_ERROR = 'There was an error calculating amount. Please try again.';
    MessageConstants.CANCEL_AMT_DISCLAIMER = 'This amount is only an estimate. The actual amount may vary depending ' +
        'on policy activity and/or outstanding fees due.';
    MessageConstants.CANCEL_ADDR_CITY_ERROR = 'As per the given address, we found {0} is the correct city. Please correct and try again.';
    MessageConstants.CANCEL_ADDR_STATE_ERROR = 'As per the given address, we found {0} is the correct state. Please correct and try again.';
    MessageConstants.CANCEL_ADDR_ZIP_ERROR = 'As per the given address, we found {0} is the correct zipcode. Please correct and try again.';
    MessageConstants.CANCEL_REQUEST_TO_CANCEL_FAILED = 'A system error has occurred, please try again.';
    MessageConstants.CANCEL_PENDING_ENDORSEMENT = 'There is a pending transaction on this policy. Please check back in 24 hours ' +
        'and try again, ';
    // DOCUMENTS
    MessageConstants.REQUIRED_DOC_TRANS_TYPE = 'Document Type is required';
    MessageConstants.REQUIRED_FILE_UPLOAD = 'File to upload required';
    MessageConstants.FORMSERVICE_1001 = 'Transaction is successful';
    MessageConstants.FORMSERVICE_1003 = 'The input parameters passed are not valid to be processed';
    MessageConstants.FORMSERVICE_1017 = 'Error in decoding document bytes';
    MessageConstants.FORMSERVICE_1018 = 'Error in uploading document on Amazon S3';
    MessageConstants.FORMSERVICE_10019 = 'Error in inserting record in EDMR';
    MessageConstants.UPLOAD_DOC_SUCCESS = 'Your request has been submitted successfully. Attach another Document?';
    MessageConstants.UPLOAD_MISSING_DOC_SUCCESS = 'Your request has been submitted. Please allow up to five business days' +
        ' for your document to be reviewed and verified. Verified documents will be updated from pending to complete status.';
    MessageConstants.UPLOAD_DOC_FAILURE = 'An error has occurred. Please submit your document again.';
    MessageConstants.UPLOAD_DOC_EZL_ERROR_08 = 'An error has occurred. Please submit your document again.';
    MessageConstants.UPLOAD_DOC_TI_ERROR_10 = 'An error has occurred. Please submit your document again.';
    MessageConstants.UPLOAD_DOC_INVALID_TYPE = 'Invalid file type. Only .pdf, .jpg, .jpeg, .tif and .png files are allowed';
    MessageConstants.UPLOAD_DOC_SIZE = 'The file size has exceeded the allowable limit of '
        + environment.uploadDocFileSize + 'MB. Please try again.';
    // Modify User Preferences
    MessageConstants.INVALID_NEW_EMAIL = 'The email address entered is not valid. Please review and try again.';
    MessageConstants.USER_CHANGE_GENERIC_ERROR = 'We were not able to process your request at this time. Please try again.';
    MessageConstants.INVALID_CURRENT_PASSWORD = 'Invalid Credentials';
    MessageConstants.EMAIL_ALREADY_REGISTERED = 'The email entered already exists, please enter a different email';
    // EDIT - MAILING ADDRESS MSGS
    MessageConstants.ADDR_AGENT_CONTACT = 'Your request has been cancelled. Please contact your agent for assistance.';
    MessageConstants.REQUIRED_VEHICLE_LOCATION = 'Vehicle Location is required';
    MessageConstants.VEHICLE_DIFFERENT_GARAGGING_ADDRESS = 'Knockout due to different garaging address';
    // EDIT - PHONE NUMBER MSGS
    MessageConstants.REQUIRED_PH_NUMBER = 'Phone Number is required';
    MessageConstants.ADDR_NO_CHANGE = 'The information below matches our current records. Please make a change or select Cancel.';
    MessageConstants.ADDR_AGENT_MSG = 'This change cannot be made through our website. Please contact your agent for assistance.';
    MessageConstants.ADDR_CANCEL_MSG = 'Your request has been cancelled. Please contact your agent for assistance.';
    MessageConstants.ADDR_PREMIUM_INC_NOTIFY_MSG = 'The change you requested will increase your remaining policy premium by $';
    MessageConstants.ADDR_PREMIUM_DEC_NOTIFY_MSG = 'The change you requested will decrease your remaining policy premium by -$';
    MessageConstants.ADDR_PREMIUM_NO_CHANGE_NOTIFY_MSG = 'The change you requested has not caused a change in your premium.';
    MessageConstants.ADDR_COMMENT_REQ_MSG = 'Mailing and garaging address changed from ';
    MessageConstants.ADDR_PREMIUM_UPDATED_PREMIUM = 'Your new full term policy premium is ';
    MessageConstants.PH_COMMENT_REQ_MSG = 'The phone number updated  from ';
    MessageConstants.ADDR_AGENT_ERROR_MSG = 'There was an error while processing your request. Please contact your agent for assistance.';
    MessageConstants.BND_RESTRICTIONS_MSG = 'Due to restrictions we are not able to make changes to your policy at this time. ' +
        'Please contact your agent for assistance.';
    // Vehicle Endorsment
    MessageConstants.REQUIRED_VIN = 'VIN is required';
    MessageConstants.MAX_MIN_VIN = 'VIN must be 17 digits';
    MessageConstants.REQUIRED_VEHTYPE = 'Vehicle type is required';
    MessageConstants.REQUIRED_REGOWNVEH = 'Primary driver is required';
    MessageConstants.REQUIRED_COMPANY = 'Company is required';
    MessageConstants.REQUIRED_VEHUSETYPE = 'Primary use is required';
    MessageConstants.REQUIRED_ISVEHCUSTOM = 'Customized Vehicle is required';
    MessageConstants.REQUIRED_REPLACEMENT_VEHICLE = 'Replacement Vehicle is required';
    MessageConstants.REQUIRED_COMPR = 'Comprehensive deductible is required';
    MessageConstants.REQUIRED_COLLISION = 'Collision deductible is required';
    MessageConstants.REQUIRED_ASSISSTANCE = 'Towing and Roadside assistance is required';
    MessageConstants.REQUIRED_RENTAL = 'Rental is required';
    MessageConstants.REQUIRED_BUYBACK = 'Buyback is required';
    MessageConstants.AGENT_ERROR_MSG = 'There was an error while processing your request. Please contact your agent for assistance.';
    MessageConstants.REMOVE_VEH_MSG = 'This vehicle cannot be removed from the Policy .Please contact your agent for assistance.';
    MessageConstants.VIN_DUP_CHECK_ERROR_MSG = 'VIN already exists in our system. Please verify your VIN number.';
    MessageConstants.VIN_POLICY_DUP_CHECK_ERROR_MSG = 'VIN already exists on this policy. Please verify your VIN number.';
    MessageConstants.VEHICLE_INSPECTION_MSG = 'Please contact your agent to see if an inspection is needed.';
    // Coverage Edits
    MessageConstants.REQUIRED_COMP = 'Comprehensive coverage is required to select this coverage';
    MessageConstants.REQUIRED_SELECTION = 'Required field - please select.';
    MessageConstants.REQUIRED_ANTI_THEFT_TYPE = 'Anti-Theft is required';
    MessageConstants.REQUIRED_ANTI_THEFT = 'Anti-Theft type is required';
    MessageConstants.REQUIRED_CAMIL_LIMIT = 'Please verify the miles you drive annually. The average annual mileage for CA drivers is 13,000';
    MessageConstants.REQUIRED_VIN_ETCHING = 'VIN Etching is required';
    MessageConstants.REQUIRED_COLISSION = 'Collision Coverage is required';
    MessageConstants.COLLISION_SELECTED = 'Uninsured Motorist Property Damage coverage cannot be ' +
        'selected when Collision coverage already exists on policy';
    MessageConstants.UMPD_SELECTED = 'Collision coverage cannot be ' +
        'selected when Uninsured Motorist Property Damage coverage already exists on policy';
    MessageConstants.SELECTED_UMPD_HIGHER_PD = 'Uninsured Motorist Property Damage limit cannot be greater than Property Damage limit';
    // API error
    MessageConstants.API_ERROR = 'Sorry. Looks like something went wrong. Please try again.';
    MessageConstants.NO_POLICY_FOUND_ERROR = 'We are unable to find your policy. Please verify the information provided.';
    MessageConstants.MAKE_PAYMENT_BTN_LABEL = 'Make a Payment';
    MessageConstants.REINSTATE_BTN_LABEL = 'REINSTATE NOW';
    MessageConstants.REINSTATE_PRE_CANCEL_BTN_LABEL = 'Avoid Cancel Now';
    MessageConstants.REINSTATE_STATUS = 'Cancelled - Reinstatement Available';
    MessageConstants.PENDING_CANCEL_STATUS = 'Pending Cancel';
    MessageConstants.PRE_CANCEL_STATUS = 'Pre-Cancel';
    MessageConstants.CANCEL_STATUS = 'Cancelled';
    MessageConstants.REINSTATE_STATUS_HOME = 'Cancelled<br> Reinstatement Available';
    return MessageConstants;
}());
export { MessageConstants };
