import { OnInit, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { BwstoreService } from '../../shared/services/bwstore.service';
import { BwqueriesService } from '../../services/api/bwquery/bwqueries.service';
import { Feedbackreq } from '../../shared/model/api/bwquery/feedbackreq';
import { MessagesService } from '../../shared/services/messages.service';
import { TitleCasePipe } from '@angular/common';
import { Policynumber } from '../../shared/model/policynumber';
import { CommonUtil } from '../../shared/utils/commonutil';
import { GlobalConstants } from '../../constants/global.constant';
var FeedbackComponent = /** @class */ (function () {
    function FeedbackComponent(activeModal, fb, _storeService, _bwQueryService, _messageService, titlecasePipe) {
        this.activeModal = activeModal;
        this.fb = fb;
        this._storeService = _storeService;
        this._bwQueryService = _bwQueryService;
        this._messageService = _messageService;
        this.titlecasePipe = titlecasePipe;
        this.currentRate = 0;
        this.policyNumber = '';
        this.email = '';
        this.mco = '';
        this.firstname = '';
        this.lastname = '';
        this.ratingError = '';
        this.isReadOnly = false;
        this.globlalConstants = GlobalConstants;
    }
    FeedbackComponent.prototype.ngOnInit = function () {
        if (this._storeService.getPolicyInfoFromStore().policyInfo !== undefined
            && this._storeService.getPolicyInfoFromStore().policyInfo.PolicyNumber !== undefined) {
            this.polnum = new Policynumber(this._storeService.getPolicyInfoFromStore().policyInfo.PolicyNumber);
            this.policyNumber = this.polnum.toFormattedString();
            this.email = this._storeService.getLoginInfoFromStore().email;
            this.mco = this._storeService.getPolicyInfoFromStore().policyInfo.MASTER0CO;
            this.firstname = this._storeService.currentUserLoginInfo.firstName;
            this.lastname = this._storeService.currentUserLoginInfo.lastName;
            this.isReadOnly = true;
        }
        else {
            this.policyNumber = '';
            this.email = '';
            this.mco = '';
            this.isReadOnly = false;
        }
        this.createFeedbackForm();
        this.useragent = window.navigator.userAgent;
        if (this.useragent.length > 200) {
            this.useragent = this.useragent.substring(0, 199);
        }
    };
    FeedbackComponent.prototype.eventHandler = function (event) {
        if (event.keyCode === this.globlalConstants.TAB_KEY_CODE) {
            if (event.shiftKey) {
                this.ratingLabelField.nativeElement.focus();
                return;
            }
            this.firstnameField.nativeElement.focus();
        }
    };
    FeedbackComponent.prototype.onSubmit = function (formData) {
        var _this = this;
        this.submitted = true;
        if (this.feedbackForm.valid) {
            var datetime = CommonUtil.getDateTimeNowEST();
            var polnum = new Policynumber(formData.phpolicynum);
            this.feedbackreq = new Feedbackreq();
            this.feedbackreq.emailAddress = this.email;
            this.feedbackreq.enterdate = datetime;
            this.feedbackreq.feedbackComments = formData.feedback;
            this.feedbackreq.feedbackType = 'BWCSS';
            this.feedbackreq.firstName = formData.firstname;
            this.feedbackreq.lastName = formData.lastname;
            this.feedbackreq.mco = this.mco;
            this.feedbackreq.phoneNum = (formData.phonenumber !== null ? formData.phonenumber : '');
            this.feedbackreq.policy = polnum.toString();
            this.feedbackreq.rating = formData.currentRate;
            this.feedbackreq.userAgent = this.useragent;
            this.feedbackreq.version = '1.0.0.0';
            this._bwQueryService.saveFeedback(this.feedbackreq).subscribe(function (iddata) {
                _this.response = iddata;
            }, function (error) {
                console.error('payment-ngOnInit', error);
            });
        }
        else {
            this.ratingError = this._messageService.getErrorMessages(this.feedbackForm.controls['currentRate'].errors, 'currentRate');
            this.submitted = false;
        }
    };
    FeedbackComponent.prototype.createFeedbackForm = function () {
        this.feedbackForm = this.fb.group({
            'currentRate': [null, Validators.compose([Validators.required])],
            'firstname': [this.titlecasePipe.transform(this.firstname)],
            'lastname': [this.titlecasePipe.transform(this.lastname)],
            'phonenumber': [null],
            'feedback': ['']
        });
    };
    // Form validations
    FeedbackComponent.prototype.isFieldValid = function (field) {
        return ((!this.feedbackForm.get(field).valid && this.feedbackForm.get(field).touched) ||
            (this.feedbackForm.get(field).untouched && this.submitted));
    };
    FeedbackComponent.prototype.getErrMessage = function (errors, formCntrlName) {
        return this._messageService.getErrorMessages(errors, formCntrlName);
    };
    return FeedbackComponent;
}());
export { FeedbackComponent };
