import { Router } from '@angular/router';
import { BwstoreService } from '../services/bwstore.service';
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, _bwStoreService) {
        this.router = router;
        this._bwStoreService = _bwStoreService;
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        var currentUserInfo = this._bwStoreService.getLoginInfoFromStore();
        var bwAuthToken = currentUserInfo.jwtToken;
        if (bwAuthToken && bwAuthToken !== null && bwAuthToken !== undefined && bwAuthToken !== '') {
            return true;
        }
        this.router.navigate(['']);
        return false;
    };
    return AuthGuard;
}());
export { AuthGuard };
