<main  class=" pt-0 maxheight_width min-ht-mobile"#mainElnt>
  <div class="container maxheight_width">
    <div *ngIf="!isLoaded" class="my-5 text-center">
      <app-bw-spinner [smallSpinner]="false"></app-bw-spinner>
    </div>
    <div class="row col-sm-12 p-0 m-0 justify-content-center payment-iframe">
      <div class="col-sm-8 p-0" #iframeParent>
        <iframe #iframe class="thread-content" [src]="this.sourceURL | safe" title="Thread Content" scrolling="no"
          class="payment-iframe" height="100%"></iframe>
      </div>

      <!--Quick Reminders-->
    <div *ngIf="isLoaded" class="col-sm-4  quickReminders" #qckRemind>
        <div class="card pl-md-2 pr-md-2 px-md-2 py-md-2">
          <div class="card-body bg-color-gray-100">
            <div class="card-title">
              <h2 class="heading-4 mt-2 mb-2 pl-2">
                Quick reminders
              </h2>
            </div>
            <div class="pl-md-3">
              <ul class="list-unstyled mb-1">
                <li>
                  <p> Look on the most recent bill for the billing account or policy number.</p>
                </li>
                <li>
                  <p>
                    Use the same ZIP code in the mailing address associated with the account/policy.</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> 
    </div>
  </div>
</main>