<main id="main" class="main">
  <div class="container">
    <div>
      <p>Per the request of the New York Department of
        Financial Services, we are making you aware of a new regulation due to COVID-19.</p>
    </div>

    <div>
      <p>The New York State Department of Financial Services
        (DFS) adopted an emergency regulation requiring New York State regulated issuers
        of property and casualty insurance to provide relief to New York consumers
        experiencing financial hardship due to COVID-19.
        This follows Governor Andrew M. Cuomo’s Executive Order No. 202.13.

      </p>

    </div>
    <div>
      <b> Property and Casualty Insurance</b>
    </div>
    <div>
      <p>
        The emergency regulation directs property and casualty insurers to provide
        flexibility to consumers experiencing financial hardship caused by the pandemic
        under auto, homeowners, condo, renters and umbrella insurance policies.
        DFS requires these insurers to provide the following relief to consumers who can
        demonstrate financial hardship due to COVID-19:</p>
      <ul>
        <li>
          Provide a 60-day grace period for the cancellation, conditional renewal or non-renewal of a policyholder’s
          insurance policy;</li>
        <li>
          Allow premiums due but not paid during the 60-day period to be paid over the course of the following year
          in 12 equal monthly installments; and</li>
        <li> Waive any late payment fees, and not report late payments to credit
          rating agencies, during the 60-day period.</li>
      </ul>
      <div> The emergency amendments
        can be found in Section 405.6 of Title 3;
        and Sections 185.7(m)(4) and 187.6(f)(4) and Part 229 of Title 11 of the Official Compilation of Codes, Rules
        and Regulations of the State of New York. For additional information regarding DFS regulatory actions on the
        COVID-19 pandemic, visit<a href="http://www.dfs.ny.gov/industry/coronavirus" target="_blank">
          www.dfs.ny.gov/industry/coronavirus.</a>

      </div>

      <div>In order to receive relief as outlined above, please call at 1-888-888-0080. </div>

    </div>
  </div>
</main>