export class Changeuseremailreq {
    szMCO: string;
    szSymbol: string;
    szPolicyNum: string;
    szEmail: string;
    szEmailOptOut: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
