import { Action } from '@ngrx/store';
import { RequesttocancelStoreInfo } from '../../shared/model/requesttocancelinfo.model';

export const CREATE_CANCELPOLICYINFO = '[CANCELPOLICYINFO] Add';
export const UPDATE_CANCELPOLICYINFO = '[CANCELPOLICYINFO] Update';
export const REMOVE_CANCELPOLICYINFO = '[CANCELPOLICYINFO] Remove';


// Create PolicyCancellation Info upon User landing on  MyProfile Screen
export class CreatePolicyCancelInfo implements Action {

    readonly type = CREATE_CANCELPOLICYINFO;

    constructor(public payload: RequesttocancelStoreInfo) {}
}


// Update PolicyCancellation Info
export class UpdatePolicyCancelInfo implements Action {

    readonly type = UPDATE_CANCELPOLICYINFO;

    constructor(public payload: RequesttocancelStoreInfo) {}
}

// Remove PolicyCancellation upon User finishes the Cancel Process and while voiding the cancellation changes
export class RemovePolicyCancelInfo implements Action {

    readonly type = REMOVE_CANCELPOLICYINFO;

    constructor(public payload: RequesttocancelStoreInfo) {}
}

export type Actions = CreatePolicyCancelInfo | UpdatePolicyCancelInfo | RemovePolicyCancelInfo;
