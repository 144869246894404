import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { MessagesService } from '../shared/services/messages.service';
import { BWErrorhandler } from './errorhandler';
import { LoggerService } from './logger.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../shared/services/messages.service";
import * as i3 from "./logger.service";
import * as i4 from "./errorhandler";
var BwappService = /** @class */ (function () {
    function BwappService(_http, msgService, logger, systemErrorHandler) {
        this._http = _http;
        this.msgService = msgService;
        this.logger = logger;
        this.systemErrorHandler = systemErrorHandler;
    }
    BwappService.prototype.formatErrors = function (errors) {
        // return new ErrorObservable(errors);
        // Tracker.log('Errors from the request :::::', errors);
        return of(errors);
    };
    // Serve all GET requests
    BwappService.prototype.get = function (apipath, params) {
        var type = 'get/';
        return this._http.get("" + environment.apiServiceUrl + apipath, { params: params })
            .pipe(tap(function (_) {
            /*this.msgService.elapsedTimeMsgs.subscribe(msgs => {
            });*/
        }), catchError(this.systemErrorHandler.handleError('', "" + apipath, params)));
    };
    BwappService.prototype.getPaymentProfiles = function (apipath, accNumber) {
        var type = 'get/';
        return this._http.get(environment.apiServiceUrl + "epc/" + apipath + "/" + accNumber + "/ownerId")
            .pipe(tap(function (_) {
            /*this.msgService.elapsedTimeMsgs.subscribe(msgs => {
            });*/
        }), catchError(this.systemErrorHandler.handleError('', "" + apipath)));
    };
    // Serve all POST requests with Path and Payload
    BwappService.prototype.post = function (apipath, body) {
        var type = 'post/';
        return this._http.post("" + environment.apiServiceUrl + apipath, JSON.stringify(body))
            .pipe(tap(function (_) {
            /*this.msgService.elapsedTimeMsgs.subscribe(msgs => {
             });*/
        }), catchError(this.systemErrorHandler.handleError('', "" + apipath, body)));
    };
    BwappService.prototype.postOkta = function (apipath, body) {
        var type = 'post/';
        return this._http.post("" + environment.securityApiEndpoint + apipath, JSON.stringify(body))
            .pipe(tap(function (_) {
            /*this.msgService.elapsedTimeMsgs.subscribe(msgs => {
             });*/
        }), catchError(this.systemErrorHandler.handleError('', "" + apipath, body)));
    };
    BwappService.prototype.postNew = function (apipath, body) {
        var type = 'post/';
        return this._http.post("" + environment.apiServiceUrl + apipath, body)
            .pipe(tap(function (_) {
            /*this.msgService.elapsedTimeMsgs.subscribe(msgs => {
             });*/
        }), catchError(this.systemErrorHandler.handleError('', "" + apipath, body)));
    };
    // Serve all POST requests with Path, and multiple Payload
    BwappService.prototype.postwithmultiparams = function (apipath, body) {
        var type = 'postfile/';
        return this._http.post("" + environment.apiServiceUrl + apipath, body)
            .pipe(catchError(this.formatErrors));
    };
    // Serve all POST requests with Path, Payload, and Header
    BwappService.prototype.postwitheader = function (apipath, body, header) {
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'multipart/form-data'
            })
        };
        return this._http.post("" + environment.apiServiceUrl + apipath, body, httpOptions)
            .pipe(catchError(this.formatErrors));
    };
    // Serve all PUT requests
    BwappService.prototype.put = function (apipath, body) {
        if (body === void 0) { body = {}; }
        return this._http.post("" + environment.apiServiceUrl + apipath, JSON.stringify(body))
            .pipe(catchError(this.formatErrors));
    };
    // Serve all DELETE requests
    BwappService.prototype.delete = function (apipath) {
        return this._http.delete("" + environment.apiServiceUrl + apipath)
            .pipe(catchError(this.formatErrors));
    };
    // Local Data read
    BwappService.prototype.getlocaldata = function (localpath) {
        return this._http.get(localpath)
            .pipe(catchError(this.systemErrorHandler.handleError('', "" + localpath, '')));
    };
    BwappService.ngInjectableDef = i0.defineInjectable({ factory: function BwappService_Factory() { return new BwappService(i0.inject(i1.HttpClient), i0.inject(i2.MessagesService), i0.inject(i3.LoggerService), i0.inject(i4.BWErrorhandler)); }, token: BwappService, providedIn: "root" });
    return BwappService;
}());
export { BwappService };
