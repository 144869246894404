import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleAnalyticsService } from '../../shared/services/analytics/google-analytics.service';
import { Bwappenum } from '../../constants/bwappenum.enum';
import { FuturePayment } from '../../shared/model/api/policyeft/eftreturnres.model';
var AddressEditComponent = /** @class */ (function () {
    function AddressEditComponent(addressEditModal, googleAnalytics) {
        this.addressEditModal = addressEditModal;
        this.googleAnalytics = googleAnalytics;
        this.currentStep = Bwappenum.MAILING_ADDR_STEP1;
    }
    AddressEditComponent.prototype.ngOnInit = function () {
        this.headerMsg = 'Edit Mailing Address';
    };
    AddressEditComponent.prototype.getMailingAddress = function (addr) {
        this.mailingAddress = addr;
        this.currentStep = Bwappenum.MAILING_ADDR_STEP2;
    };
    AddressEditComponent.prototype.getUpdateStatus = function (status) {
        if (status === 'success') {
            if (this.mailingAddress.isPhoneNumberChanged && this.mailingAddress.isAddressChanged) {
                this.headerMsg = 'YOUR ADDRESS AND PHONE NUMBER CHANGE IS COMPLETE.';
            }
            else if (this.mailingAddress.isPhoneNumberChanged) {
                this.headerMsg = 'YOUR PHONE NUMBER CHANGE IS COMPLETE.';
            }
            else if (this.mailingAddress.isAddressChanged) {
                this.headerMsg = 'YOUR ADDRESS CHANGE IS COMPLETE.';
            }
            this.currentStep = Bwappenum.MAILING_ADDR_STEP3;
        }
    };
    AddressEditComponent.prototype.getfuturePayments = function (futurePayments) {
        this.futurePayments = futurePayments;
    };
    return AddressEditComponent;
}());
export { AddressEditComponent };
