/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./login-gp-terms.component";
import * as i4 from "../../services/bwstore.service";
import * as i5 from "../../../services/api/gopaperless/go-paperless.service";
import * as i6 from "../../services/analytics/google-analytics.service";
var styles_LoginGpTermsComponent = [];
var RenderType_LoginGpTermsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginGpTermsComponent, data: {} });
export { RenderType_LoginGpTermsComponent as RenderType_LoginGpTermsComponent };
export function View_LoginGpTermsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 20, "main", [["class", "main text-center"], ["id", "main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 19, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "col-md-7 justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "p", [["class", "lead mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Accept Go Paperless Terms and Conditions"])), (_l()(), i0.ɵeld(6, 0, null, null, 14, "div", [["class", "d-flex justify-content-center mt-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 13, "div", [["class", "col-md-10 justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 12, "div", [["class", "mt-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["By clicking 'I ACCEPT' below you are agreeing to the "])), (_l()(), i0.ɵeld(11, 0, null, null, 5, "a", [["routerLink", "/gopaperless-terms"], ["routerLinkActive", "active"], ["target", "_blank"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(12, 671744, [[2, 4]], 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { target: [0, "target"], routerLink: [1, "routerLink"] }, null), i0.ɵdid(13, 1720320, null, 2, i1.RouterLinkActive, [i1.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 1, { links: 1 }), i0.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i0.ɵted(-1, null, ["Go Paperless Terms and Conditions"])), (_l()(), i0.ɵeld(17, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Acceptance is required to complete your registration."])), (_l()(), i0.ɵeld(19, 0, null, null, 1, "button", [["class", "btn btn-primary mt-3"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.acceptGoPaperless() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["I Accept"]))], function (_ck, _v) { var currVal_2 = "_blank"; var currVal_3 = "/gopaperless-terms"; _ck(_v, 12, 0, currVal_2, currVal_3); var currVal_4 = "active"; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 12).target; var currVal_1 = i0.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_0, currVal_1); }); }
export function View_LoginGpTermsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-login-gp-terms", [], null, null, null, View_LoginGpTermsComponent_0, RenderType_LoginGpTermsComponent)), i0.ɵdid(1, 114688, null, 0, i3.LoginGpTermsComponent, [i1.Router, i4.BwstoreService, i5.GoPaperlessService, i6.GoogleAnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginGpTermsComponentNgFactory = i0.ɵccf("app-login-gp-terms", i3.LoginGpTermsComponent, View_LoginGpTermsComponent_Host_0, {}, {}, []);
export { LoginGpTermsComponentNgFactory as LoginGpTermsComponentNgFactory };
