 <div class="form-row" [formGroup]="form">
          <div class="col">
            <div class="form-group">
              <label for="{{controlName}}">Mobile Phone Number {{count}}</label>
              <input id="{{controlName}}" appMobileNumberinput type="text"
              name="{{controlName}}" class="form-control form-control-lg"
              formControlName="{{controlName}}"
              maxlength="12" [readonly]="phoneValue.phoneStatusCode !== undefined && phoneValue.phoneStatusCode !== 'V'"/>
              <!-- <label *ngIf="phoneValue.phoneStatusDescription!== undefined &&
              phoneValue.phoneStatusDescription!== ''">{{phoneValue.phoneStatusDescription}}</label> -->
              <app-field-error-display [displayError]="isFieldValid(controlName)"
              [errorMsg]="getMobileNumErrMsg(form.controls[controlName].errors)"></app-field-error-display>
            </div>
          </div>
        </div>
  