import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GlobalConstants } from '../../constants/global.constant';
import { BwappService } from '../../services/bwapp.service';
import { Chattokenres } from '../model/api/chat/chattokenres';
import { Chatparamreq } from '../model/api/chat/chatparamreq';


@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private globalConstants = GlobalConstants;

  constructor(private bwappService: BwappService, private _http: HttpClient) { }



  generateChatToken(): Observable<any> {
  const params = new HttpParams();
   return this.bwappService.post(this.globalConstants.CHAT_GENERATE_TOKEN, params);
  }


  postChartParams(chatParamReq: Chatparamreq , chatTokenRes: Chattokenres): Observable<any> {
    const pshttpOptions = {
      headers: new HttpHeaders({
        'Authorization': chatTokenRes.token_type.concat(' ').concat(chatTokenRes.access_token)
      })
    };
     return this.bwappService.post(this.globalConstants.CHAT_GENERATE_PARAM_RECORD, chatParamReq);

    }
}
