import { Action } from '@ngrx/store';
import { ValidValuesResponse } from '../../shared/model/api/validvalues/valid-values-res.model';

export const CREATE_VALIDVALUESINFO = '[VALIDVALUESINFO] Add';
export const UPDATE_VALIDVALUESINFO = '[VALIDVALUESINFO] Update';
export const REMOVE_VALIDVALUESINFO = '[VALIDVALUESINFO] Remove';


// Create validValues Info upon User landing on my policy screen
export class CreateValidValuesInfo implements Action {

    readonly type = CREATE_VALIDVALUESINFO;

    constructor(public payload: ValidValuesResponse) {}
}


// Update validValues Info
export class UpdateValidValuesInfo implements Action {

    readonly type = UPDATE_VALIDVALUESINFO;

    constructor(public payload: ValidValuesResponse) {}
}

// Remove validValues info
export class RemoveValidValuesInfo implements Action {

    readonly type = REMOVE_VALIDVALUESINFO;

    constructor(public payload: ValidValuesResponse) {}
}

export type Actions = CreateValidValuesInfo | UpdateValidValuesInfo | RemoveValidValuesInfo;
