import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConstants } from '../../../constants/global.constant';
import { BwappService } from '../../bwapp.service';


@Injectable({
  providedIn: 'root'
})
export class FindAgentsService {
  _baseURL: string;

  constructor(private appService: BwappService) {
    this._baseURL = GlobalConstants.baseURL;
   }

  findAgents(zip: string) {
    const params = new HttpParams().set('zipcode', zip);
    return this.appService.get(GlobalConstants.BWQRY_GETAGENTLIST, params);
  }
}
