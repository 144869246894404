import { AbstractControl, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

export class MobileNumberValidator {
    static validateMobileNumber(control: FormControl): { [key: string]: any } {
            const mobileNumPattern =  /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
            if ((control.value && !control.value.match(mobileNumPattern))) {
                        return {mobilenum: true};
            }
            return null;
        }
}
