var Textalertenrollmentreq = /** @class */ (function () {
    function Textalertenrollmentreq() {
    }
    return Textalertenrollmentreq;
}());
export { Textalertenrollmentreq };
var Phonenumberdetails = /** @class */ (function () {
    function Phonenumberdetails(phoneNumber, phoneStatusCode, phoneStatusDescription) {
        this.phoneNumber = phoneNumber;
        this.phoneStatusCode = phoneStatusCode;
        this.phoneStatusDescription = phoneStatusDescription;
    }
    return Phonenumberdetails;
}());
export { Phonenumberdetails };
