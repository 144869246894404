<div role="dialog" class="main-iframe-container" aria-label="Add payment method">
    <h4 class="iframe-header" aria-level="2" mat-dialog-title>
        {{ data.addOrEdit }} Payment Method
    </h4>
    <div class="wrapper">
        <mat-tab-group (selectedTabChange)="onSelectedTabChange($event)">
            <mat-tab class="label" *ngFor="let tab of tabs" label="{{tab.label}}"></mat-tab>
        </mat-tab-group>
        <mat-dialog-content>
            <div #tcSection class="tc-text" [hidden]="isSpinnerOn">
                <p class="mt-3 mb-0">
                    By saving your payment method, you certify that you have read and agreed to the
                </p>
                <div class="btn-link mt-2 mb-0 d-flex" (click)="toggleAuthoriseInfo()"
                    (keyup.enter)="toggleAuthoriseInfo()" aria-label="labelForCardArrow" role="button">
                    <p class="ml-1 mb-0" [ngClass]="{'mr-3': !isAuthoriseInfoOpened}">
                        Information Storage Authorization.
                    </p>
                    <div class="arrow-container">
                        <mat-icon class="toggle-arrow" [ngClass]="{'closed mt-2': !isAuthoriseInfoOpened}"
                            aria-hidden="true">
                            keyboard_arrow_up
                        </mat-icon>
                    </div>
                </div>
                <div class="authorize-container" [ngClass]="{'py-0 open': !isAuthoriseInfoOpened}"
                    [@smoothCollapse]="authoriseInfoAnimationState">
                    <p class="mt-3 mb-0 font-weight-bold" [ngClass]="{'pt-3': isAuthoriseInfoOpened}">
                        Information Storage Authorization
                    </p>
                    <p class="mt-3 mb-0">
                        You authorize us or one of our vendors to store any information you provide, including, but not
                        limited to, names,
                        addresses, birth dates, driver’s license numbers, social security numbers, vehicle
                        identification numbers (VINs),
                        Bank Account or Payment Card information.
                    </p>
                    <p class="mt-3 mb-0">
                        You certify that you are the owner or authorized signer for any Bank Account or Payment Card you
                        provide, and you
                        authorize the financial institution where any such Bank Account or Payment Card is held to honor
                        any withdrawals
                        or charges you authorize.
                    </p>
                </div>
            </div>
            <div *ngFor="let tab of tabs" [hidden]="tab.type !== selectedType">
                <iframe scrolling="no" [src]="tab.url | safe" [style.height.px]="iFrameHeight + tab.heightOffset"
                    title="Paymentus Manage Payments">
                </iframe>
                <mat-spinner *ngIf="isSpinnerOn"></mat-spinner>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button class="close mt-2" aria-label="close dialog" mat-dialog-close>
                <span aria-hidden="true"> × </span>
            </button>
        </mat-dialog-actions>
    </div>
</div>
