export class BreadcrumbRoute {
    routeName: string;
    routePath: string;

    constructor(routeName: string, routePath: string) {
        this.routeName = routeName;
        this.routePath = routePath;
    }

}
