import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalConstants } from '../../../constants/global.constant';
import { Checkstateenabledres } from '../../../shared/model/api/text-alerts/checkstateenabled.model';
import { Phonenumberdetails, Textalertenrollmentreq } from '../../../shared/model/api/text-alerts/textalertenrollment.model';
import { Policynumber } from '../../../shared/model/policynumber';
import { BwappService } from '../../bwapp.service';

@Injectable({
  providedIn: 'root'
})
export class TextAlertsService {
  private globlalConstants = GlobalConstants;
  textAlertEnrollmentReq: Textalertenrollmentreq;
  policyNumber: any;

  constructor(private appService: BwappService) { }

  checkStateEnabled(state: string, mco: string, rateBook: string): Observable<Checkstateenabledres> {
    const params = new HttpParams()
    .set('state', state)
    .set('mco', mco)
    .set('rateBook', rateBook);

  return this.appService.get(this.globlalConstants.CHECK_STATE_ENABLED, params);
  }

  GetTextAlertEnrollmentStatus(agentCode: string, agentType: string,
    mco: string, state: string, policyNumber: string): Observable<any> {
    this.policyNumber = new Policynumber(policyNumber);
    this.textAlertEnrollmentReq = new Textalertenrollmentreq();
    this.textAlertEnrollmentReq.agentCode = agentCode;
    this.textAlertEnrollmentReq.agentType = agentType;
    this.textAlertEnrollmentReq.applicationId = this.globlalConstants.APPID;
    this.textAlertEnrollmentReq.mco = mco;
    this.textAlertEnrollmentReq.policyMOD = this.policyNumber.module;
    this.textAlertEnrollmentReq.policyNumber = this.policyNumber.policy;
    this.textAlertEnrollmentReq.stateCode = state;
    this.textAlertEnrollmentReq.symbol = this.policyNumber.symbol;

  return this.appService.post(this.globlalConstants.GET_TEXT_ENROLLMENT_STATUS, this.textAlertEnrollmentReq);
  }

  ManageTextAlertEnrollment(agentCode: string, agentType: string,
    mco: string, state: string, policyNumber: string, phoneNumberDetails: Phonenumberdetails[]): Observable<any> {
    this.policyNumber = new Policynumber(policyNumber);
    this.textAlertEnrollmentReq = new Textalertenrollmentreq();
    this.textAlertEnrollmentReq.agentCode = agentCode;
    this.textAlertEnrollmentReq.agentType = agentType;
    this.textAlertEnrollmentReq.applicationId = this.globlalConstants.APPID;
    this.textAlertEnrollmentReq.mco = mco;
    this.textAlertEnrollmentReq.policyMOD = this.policyNumber.module;
    this.textAlertEnrollmentReq.policyNumber = this.policyNumber.policy;
    this.textAlertEnrollmentReq.stateCode = state;
    this.textAlertEnrollmentReq.symbol = this.policyNumber.symbol;
    this.textAlertEnrollmentReq.phoneNumberDetails = phoneNumberDetails;

  return this.appService.post(this.globlalConstants.MANAGE_TEXT_ALERT_ENROLLMENT, this.textAlertEnrollmentReq);
  }
}
