
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { BWError } from '../shared/model/error.model';
import { MessagesService } from '../shared/services/messages.service';
import { Tracker } from '../shared/utils/tracker';



@Injectable({
    providedIn: 'root'
  })
export class BWErrorhandler {

    constructor(private msgService: MessagesService) {}

   /**
    * Returns a function that handles Http operation failures.
    * This error handler lets the app continue to run as if no error occurred.
    * @param serviceName = name of the data service that attempted the operation
    * @param operation - name of the operation that failed
    * @param result - optional value to return as the observable result
    */
    public handleError<T> (serviceName = '', operation = 'operation', result = {} as T) {

        return (error: any): Observable<T> => {
            let message = '';
            // this.msgService.clearSystemMessages();

            const date = new Date().toISOString();
            if (error instanceof HttpErrorResponse) {
                message = 'There was an HTTP error.' + error.message + 'Status code:' + (<HttpErrorResponse>error).status;
                // Tracker.log(date, 'There was an HTTP error.', error.message, 'Status code:', (<HttpErrorResponse>error).status);
            } else if (error instanceof TypeError) {
                // Tracker.log(date, 'There was a Type error.', error.message);
            } else if (error instanceof Error) {
                // Tracker.log(date, 'There was a general error.', error.message);
            } else {
                // Tracker.log(date, 'Nobody threw an Error but something happened!', error);
            }

            // this.msgService.addSystemMessages(`${serviceName}: ${operation} failed: ${message}`);
            // this.msgService.addSystemMessages(`${operation} failed: ${message}`);

            if (error.error.code !== 'E0000004' && error.error.code !== 'E0000007' && error.error.code !== 'E0000080') this.msgService.addSystemMessages('Sorry. Looks like something went wrong. Please try again.');

            Tracker.logerror('BWErrorhandler', 'handleError()', 'System Errors', `${operation} failed`, error.error);

            const message1 = (error.error instanceof ErrorEvent)
                ? error.error.message : `server returned code ${error.status} with body '${error.message}'`;

             // Tracker.log('Error Handler Processor Final Message ::: ', message1);
            // TODO: transforming error for better user understanding


            // Let the app keep running by returning a safe result.
            // return new ErrorObservable(error);
            return throwError(error);
          };
    }

    /*public handleApplicationError(error: any): any {
        this.msgService.clearSystemMessages();
        const errorMsg = JSON.parse(error._body);
        //Tracker.log('Error handler :::: ', errorMsg.message);
        this.msgService.addAppMessage(errorMsg.message);

         return Observable.throw(errorMsg.message || 'Application Error');
    }*/

    public handleApplicationError(error: BWError) {
        this.msgService.clearAppMessages();
        // Tracker.log(error.errorcode, error.errordesc, error.source);
        this.msgService.addAppMessage(error);
        return throwError('Application Error');
    }

    public handleComponentErrors() {

    }

    public ClearApplicationError() {
        this.msgService.resetApplicationMsgs();
    }

}
