var MobileNumberValidator = /** @class */ (function () {
    function MobileNumberValidator() {
    }
    MobileNumberValidator.validateMobileNumber = function (control) {
        var mobileNumPattern = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
        if ((control.value && !control.value.match(mobileNumPattern))) {
            return { mobilenum: true };
        }
        return null;
    };
    return MobileNumberValidator;
}());
export { MobileNumberValidator };
