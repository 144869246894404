import { OnInit, EventEmitter } from '@angular/core';
import { MessageConstants } from '../../../constants/message.constant';
import { BWError } from '../../../shared/model/error.model';
import { BWErrorhandler } from '../../../services/errorhandler';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { MessagesService } from '../../../shared/services/messages.service';
import { GoogleAnalyticsService } from '../../../shared/services/analytics/google-analytics.service';
import { StatecdsService } from '../../../shared/services/statecds.service';
import { BwstoreService } from '../../../shared/services/bwstore.service';
import { Mailingaddress } from '../../../shared/model/endorsements/addressedit/mailingaddress.model';
import { Bwappenum } from '../../../constants/bwappenum.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RequesttocancelService } from '../../../services/myprofile/requesttocancel.service';
import { MobileNumberValidator } from '../../../shared/validators/mobilenumber.validator';
import { EndorsementReq } from '../../../shared/model/api/endorsement/endorsement-req';
import { GlobalConstants } from '../../../constants/global.constant';
import { EndorsementService } from '../../../services/api/endorsement/endorsement.service';
import { CommonUtil } from '../../../shared/utils/commonutil';
import { HelptextService } from '../../../services/api/helptext/helptext.service';
var MailingaddrChangeComponent = /** @class */ (function () {
    function MailingaddrChangeComponent(route, router, fb, _storeService, _messageService, errorHandler, googleAnalytics, stateCdService, cancelService, endorsementService, helptextService) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.fb = fb;
        this._storeService = _storeService;
        this._messageService = _messageService;
        this.errorHandler = errorHandler;
        this.googleAnalytics = googleAnalytics;
        this.stateCdService = stateCdService;
        this.cancelService = cancelService;
        this.endorsementService = endorsementService;
        this.helptextService = helptextService;
        this.stateCds = [];
        this.isAddrEditCancelled = false;
        this.isSubmitButtonDisable = false;
        this.submitted = false;
        this.isLoaded = true;
        this.count1 = '0';
        this.phoneNumber = 'Phone Number';
        this.updatedMailingAddress = new EventEmitter();
        this.endorsementReq = new EndorsementReq();
        this.globlalConstants = GlobalConstants;
        this.authenticatedPhDetails = this._storeService.getLoginInfoFromStore();
        this.basicPolicyDetails = this._storeService.getPolicyInfoFromStore();
        this.policyInfo = this.basicPolicyDetails.policyInfo;
        this.createAddressEditForm();
        // ToDo need to add the help text id
        this.helptextService.getHelpTextByIds(['612']).subscribe(function (helptextres) {
            _this.helptextMap = helptextres;
        });
    }
    MailingaddrChangeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateCdService.getStateCds().subscribe(function (stateCds) {
            _this.stateCds = stateCds;
        });
    };
    MailingaddrChangeComponent.prototype.addressEditModalClose = function (modal, formComponenetName) {
        this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressChangeCancel', 'MailingAddressChangeCancelClick', 200);
        modal.dismiss('formComponenetName');
        this.errorHandler.ClearApplicationError();
    };
    MailingaddrChangeComponent.prototype.onChange = function () {
        var _this = this;
        // this.addressEditForm.get('isVehicleInThisLocation').valueChanges.subscribe(value => {
        this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressChangeScreen', 'MailingAddressChangeKnockOut', 200);
        if (this.addressEditForm.get('isVehicleInThisLocation').value === 'no') {
            this.addressEditForm.get('address').disable();
            this.addressEditForm.get('city').disable();
            this.addressEditForm.get('state').disable();
            this.addressEditForm.get('zip').disable();
            this.addressEditForm.get('isVehicleInThisLocation').disable();
            this.addressEditForm.get('phoneNumber').disable();
            this.isSubmitButtonDisable = true;
            // Audit Log entry for knock out
            this.endorsementReq.symbol = this.policyInfo.SYMBOL;
            this.endorsementReq.policyNum = this.basicPolicyDetails.policyInfo.POLICY0NUM;
            this.endorsementReq.mod = this.basicPolicyDetails.policyInfo.MODULE;
            this.endorsementReq.mco = this.basicPolicyDetails.policyInfo.PolicyMasterCompany;
            this.endorsementReq.activityResult = this.globlalConstants.AUDIT_LOG_ACITIVITY_RESULT_K;
            this.endorsementReq.activity = this.globlalConstants.AUDIT_LOG_ACITIVITY_ADDRESS_CHANGE;
            this.endorsementReq.state = this.policyInfo.PolicyState;
            this.endorsementReq.activityMessage = MessageConstants.VEHICLE_DIFFERENT_GARAGGING_ADDRESS;
            this.endorsementService.createAuditLogRecord(this.endorsementReq).subscribe(function (auditLogRes) {
                // CommonUtil.scrollToTop();
                document.getElementById('headerAdd').scrollTop = 0;
                _this.errorHandler.handleApplicationError(new BWError({
                    'errorcode': 'MailingAddressEdit', 'errordesc': MessageConstants.ADDR_AGENT_MSG + '<br>' +
                        'Agent Name : ' + _this.basicPolicyDetails.agentInfo.AgentName1 + '<br>'
                        + 'Agent Phone Number : ' + _this.basicPolicyDetails.agentInfo.ACNM_TELE, 'source': 'isVehicleInThisLocation'
                }));
            });
        }
    };
    MailingaddrChangeComponent.prototype.submitAddressEndorsement = function (formdata) {
        var _this = this;
        this.formSubmitAttempt = true;
        if (this.addressEditForm.valid) {
            if (this.policyInfo.PolicyAddress.toUpperCase() === formdata.address.toUpperCase()
                && this.policyInfo.PolicyCity.toUpperCase() === formdata.city.toUpperCase()
                && this.policyInfo.PolicyZip.substr(0, 5) === formdata.zip
                && this.authenticatedPhDetails.phone === formdata.phoneNumber) {
                this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressChangeScreen', 'MailingAddressComparisonWithExistingOne', 200);
                CommonUtil.scrollToTop();
                this.errorHandler.handleApplicationError(new BWError({
                    'errorcode': 'MailingAddressEdit', 'errordesc': MessageConstants.ADDR_NO_CHANGE,
                    'source': 'MailingAddressNotChanged'
                }));
            }
            else {
                this.mailingAddress = new Mailingaddress();
                if (this.policyInfo.PolicyAddress.toUpperCase() !== formdata.address.toUpperCase()
                    || this.policyInfo.PolicyCity.toUpperCase() !== formdata.city.toUpperCase()
                    || this.policyInfo.PolicyZip.substr(0, 5) !== formdata.zip) {
                    this.mailingAddress.isAddressChanged = true;
                }
                if (this.authenticatedPhDetails.phone !== formdata.phoneNumber) {
                    this.mailingAddress.isPhoneNumberChanged = true;
                }
                this.errorHandler.ClearApplicationError();
                this.mailingAddress.street = formdata.address.toUpperCase();
                this.mailingAddress.city = formdata.city.toUpperCase();
                this.mailingAddress.state = this.policyInfo.PolicyState;
                this.mailingAddress.zip = formdata.zip;
                this.mailingAddress.garagingAddress = formdata.isVehicleInThisLocation;
                this.mailingAddress.phone = formdata.phoneNumber;
                this.mailingAddress.stage = Bwappenum.MAILING_ADDR_STEP2;
                // this.updatedMailingAddress.emit(this.mailingAddress);
                if (formdata.isVehicleInThisLocation === 'yes') {
                    // add address Scrubing
                    this.isLoaded = false;
                    this.cancelService.scrubAddress(formdata.address, formdata.city, this.policyInfo.PolicyState, formdata.zip)
                        .then(function (scrubAddressRes) {
                        if (scrubAddressRes) {
                            _this.isLoaded = true;
                            _this.updatedMailingAddress.emit(_this.mailingAddress);
                        }
                    }, function (scrubAddressError) {
                        _this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressChangeScreen', 'ScrubAddressFailure', 200);
                        _this.isLoaded = true;
                    });
                }
            }
        }
    };
    MailingaddrChangeComponent.prototype.voidEndorsement = function () {
        this.isAddrEditCancelled = true;
    };
    MailingaddrChangeComponent.prototype.cancelEndormentProcess = function () {
        this.isAddrEditCancelled = true;
    };
    // Tooltips
    MailingaddrChangeComponent.prototype.getToolTip = function (id) {
        if (this.helptextMap) {
            var helptextid = this.helptextMap.get(id);
            return helptextid !== null && helptextid !== undefined ? helptextid.producerText : '';
        }
    };
    MailingaddrChangeComponent.prototype.toggle = function (tooltip) {
        if (tooltip.isOpen()) {
            tooltip.close();
        }
        else {
            tooltip.open();
        }
    };
    MailingaddrChangeComponent.prototype.createAddressEditForm = function () {
        this.addressEditForm = this.fb.group({
            'address': [{ value: this.policyInfo.PolicyAddress, disabled: this.isAddrEditCancelled }, Validators.compose([Validators.required])],
            'city': [{ value: this.policyInfo.PolicyCity, disabled: this.isAddrEditCancelled }, Validators.compose([Validators.required])],
            'state': [{ value: this.policyInfo.PolicyState, disabled: true }, Validators.compose([Validators.required])],
            'zip': [{ value: this.policyInfo.PolicyZip.substr(0, 5), disabled: this.isAddrEditCancelled },
                Validators.compose([Validators.required, Validators.maxLength(5)])],
            'isVehicleInThisLocation': [{ value: '', disabled: this.isAddrEditCancelled },
                Validators.compose([Validators.required])],
            'phoneNumber': [{ value: this.authenticatedPhDetails.phone, disabled: false },
                Validators.compose([Validators.required, MobileNumberValidator.validateMobileNumber])],
        });
    };
    // Form Utilities
    MailingaddrChangeComponent.prototype.isFieldValid = function (field) {
        return ((!this.addressEditForm.get(field).valid && this.addressEditForm.get(field).touched) ||
            (this.addressEditForm.get(field).untouched && this.formSubmitAttempt));
    };
    MailingaddrChangeComponent.prototype.getErrMessage = function (errors, formCntrlName) {
        return this._messageService.getErrorMessages(errors, formCntrlName);
    };
    MailingaddrChangeComponent.prototype.getMobileNumErrMsg = function (errors) {
        if (errors) {
            if (errors.required === true) {
                return MessageConstants.REQUIRED_PHONENUMBER;
            }
            else if ((errors.mobilenum === true)) {
                return MessageConstants.MOBILE_NUMBER_FORMAT;
            }
        }
    };
    return MailingaddrChangeComponent;
}());
export { MailingaddrChangeComponent };
