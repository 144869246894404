<div class="card">
   <div class="card-body p-0">
      <h1 class="heading-3 m-0 px-3 py-2 border-bottom">Billing Details</h1>
      <div class="px-3 py-2 border-bottom d-flex align-items-center">
         <i class="fa fa-car" aria-hidden="true"></i>
         <label class="m-0 ml-2">
           <span style="display:inline;" *ngIf="billingDetails.policyInfo.LINE0BUS ===APV"><strong>Personal</strong></span>
           <span style="display:inline;" *ngIf="billingDetails.policyInfo.LINE0BUS ===CAL"><strong>Commercial</strong></span> 
           &nbsp;<span style="display:inline;" ><strong>Auto</strong></span>
         </label>
      </div>
      
      <div class="p-3 border-bottom">
         <label class="mb-3">
           <strong>Policy#: {{getPolicyNumber()}}</strong>
         </label>
         <p class="mb-1">Policy Term {{billingDetails.policyInfo.PolicyEffectiveDate.split('T')[0]|date : 'MM/dd/yyyy' : 'en-US'}} - {{billingDetails.policyInfo.PolicyExpireDate.split('T')[0]|date : 'MM/dd/yyyy' : 'en-US'}}</p>
         <label class="mb-1"><strong>Policy Status: {{billingDetails.policyInfo.PolicyStatusEPC}}</strong></label>
         <div class="mb-3">
            <div class="d-flex justify-content-between align-items-center mb-1" *ngIf="!isReinstatementAvailable()">
               Amount Due
               <label class="m-0" *ngIf="billingDetails.basicPolicyDetails.paymentInfo.minimumPayment">{{billingDetails.basicPolicyDetails.paymentInfo.minimumPayment | currency}}</label>
               <label class="m-0" *ngIf="!billingDetails.basicPolicyDetails.paymentInfo.minimumPayment">{{'0.00' | currency}}</label>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-1" *ngIf="isReinstatementAvailable()">
               Amount Due
               <label class="m-0" *ngIf="authenticatedPhDetails.reInstateDueamount">{{authenticatedPhDetails.reInstateDueamount | currency}}</label>
               <label class="m-0" *ngIf="!authenticatedPhDetails.reInstateDueamount">{{'0.00' | currency}}</label>
            </div>
            <div *ngIf="billingDetails.policyInfo.PolicyStatusEPC === status.pendingCancelled || billingDetails.policyInfo.PolicyStatusEPC === status.reinstatementAvailable" class="d-flex justify-content-between align-items-center mb-1">
               Last Day To Pay
               <label class="m-0" *ngIf="billingDetails.policyInfo.PolicyStatusEPC !== status.pendingCancelled && authenticatedPhDetails.reInstateDueamount && authenticatedPhDetails.reInstateDueamount!=='0.00' && authenticatedPhDetails.reInstateDueamount !=='0' ">{{this.getLastDayToPayDate() | date:'MM/dd/yyyy' : 'en-US'}}</label>
               <label class="m-0" *ngIf=" billingDetails.policyInfo.PolicyStatusEPC === status.pendingCancelled ">{{this.getDueDate() | date:'MM/dd/yyyy' : 'en-US'}}</label>
            </div>
            <div *ngIf="billingDetails.policyInfo.PolicyStatusEPC !== status.pendingCancelled && billingDetails.policyInfo.PolicyStatusEPC !== status.reinstatementAvailable" class="d-flex justify-content-between align-items-center mb-1">
               Due Date
               <label class="m-0" *ngIf="billingDetails.basicPolicyDetails.paymentInfo.minimumPayment && billingDetails.basicPolicyDetails.paymentInfo.minimumPayment!=='0.00' && billingDetails.basicPolicyDetails.paymentInfo.minimumPayment !=='0' && billingDetails.policyInfo.PolicyStatusEPC !== status.cancelled">{{this.getDueDate() | date:'MM/dd/yyyy' : 'en-US'}}</label>
               <label class="m-0" *ngIf="billingDetails.basicPolicyDetails.paymentInfo.minimumPayment && billingDetails.basicPolicyDetails.paymentInfo.minimumPayment!=='0.00' && billingDetails.basicPolicyDetails.paymentInfo.minimumPayment !=='0' && billingDetails.policyInfo.PolicyStatusEPC === status.cancelled">{{this.getCancelledDueDate() | date:'MM/dd/yyyy' : 'en-US'}}</label>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-1" *ngIf="billingDetails.policyInfo.PolicyStatusEPC!== status.reinstatementAvailable">
               Outstanding Balance
               <label class="m-0" *ngIf="billingDetails.basicPolicyDetails.paymentInfo.maximumPayment">{{billingDetails.basicPolicyDetails.paymentInfo.maximumPayment | currency}}</label>
               <label class="m-0" *ngIf="!billingDetails.basicPolicyDetails.paymentInfo.maximumPayment">{{'0.00' | currency}}</label>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-1" *ngIf="billingDetails.policyInfo.PolicyStatusEPC ===status.reinstatementAvailable">
               Outstanding Balance
               <label class="m-0" *ngIf="billingDetails.basicPolicyDetails.paymentInfo.maximumPayment">{{billingDetails.basicPolicyDetails.policyInfo.Balance | currency}}</label>
               <label class="m-0" *ngIf="!billingDetails.basicPolicyDetails.paymentInfo.maximumPayment">{{'0.00' | currency}}</label>
            </div>
         </div>
         <p class="m-0" *ngIf="(!billingDetails.basicPolicyDetails.paymentInfo.minimumPayment || billingDetails.basicPolicyDetails.paymentInfo.minimumPayment === '0.00' || billingDetails.basicPolicyDetails.paymentInfo.minimumPayment === '0' ) && billingDetails.policyInfo.PolicyStatusEPC===status.active ">As of today {{now|date:'MM/dd/yyyy': 'en-US'}} there is no payment due. For questions regarding your policy, please call our Customer Service Department at 1-888-888-0080 or mail to: PO BOX 31029, Independence, OH 44131-0029.</p>
         <p class="m-0" *ngIf="billingDetails.policyInfo.PolicyStatusEPC===status.reinstatementAvailable ">Your cancelled policy may be reinstated with a lapse in coverage if you pay the exact amount shown above. Your policy will be reinstated with a lapse in coverage from your cancel effective date to the date after postmark(date after this payment). Any applicable fee will be included in the next payment.</p>
         <p class="m-0" *ngIf="billingDetails.policyInfo.PolicyStatusEPC===status.cancelled ">Payment does not reinstate or extend coverage beyond the date noted on the most recent billing notice. Please contact an agent or Customer Service Department at 1-888-888-0080 to discuss what reinstatement or coverage option are available.</p>
         <!-- <p class="m-0" *ngIf="billingDetails.policyInfo.PolicyStatusEPC===status.pendingCancelled ">Payment does not reinstate or extend coverage beyond the sate noted on the most recent billing notice. Please contact an agent or Customer Service at 1-888-888-0080 to discuss what reinstatement or coverage option are available.</p> -->
         
      </div>
     
   </div>
 </div>
 
