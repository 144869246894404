<main id="main" class="main">
  <div class="container">
      
<div  class="d-flex justify-content-center">
    <div class="alert">  
          <div class="text-danger inline-icon padding-left" >
              <div class="text-center">
              <p>Please call {{ this.directSalesPhone }} and we will be happy to assist you! </p>
              </div>
          </div>
    </div>
  </div>

    </div>
    </main>


