
export class GetEsignEligibilityReq {

  policycontext: string;
  mco: string;
  policysymbol: string;
  policynum: string;
  policymod: string;
  hasRenewal: boolean;
  srcsystem: string;
  firstName: string;
  lastName: string;
  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
