import { GlobalConstants } from '../../../constants/global.constant';
import { BwappService } from '../../bwapp.service';
import { HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "../../bwapp.service";
var UploadfileService = /** @class */ (function () {
    function UploadfileService(appService) {
        this.appService = appService;
        this.globlalConstants = GlobalConstants;
    }
    UploadfileService.prototype.getTranasctionTypes = function (mco, statecd) {
        var params = new HttpParams()
            .set('reqMCO', mco)
            .set('reqState', statecd)
            .set('reqUserType', 'INS');
        return this.appService.get(this.globlalConstants.GET_DOCS_TYPES, params);
    };
    UploadfileService.prototype.getEasyLinkTransactionCode = function (transCodeReq) {
        return this.appService.post(this.globlalConstants.GET_EZL_TRAN_CODE, transCodeReq);
    };
    UploadfileService.prototype.createTaskInjectorRecord = function (taskInjReq) {
        return this.appService.post(this.globlalConstants.CREATE_TASKINJ_REC, taskInjReq);
    };
    UploadfileService.prototype.createUploadAuditRecord = function (auditReq) {
        return this.appService.post(this.globlalConstants.CREATE_UPLOAD_AUDIT, auditReq);
    };
    UploadfileService.ngInjectableDef = i0.defineInjectable({ factory: function UploadfileService_Factory() { return new UploadfileService(i0.inject(i1.BwappService)); }, token: UploadfileService, providedIn: "root" });
    return UploadfileService;
}());
export { UploadfileService };
