import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from '../../services/analytics/google-analytics.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-landing-tabs',
  template: `
  <div class="row text-center">
    <div class="col-md-12 col-sm-12 direct-sales-form">
    <app-directsales fromPage="Home" *ngIf="this.showQuickQuote === 'Y'"></app-directsales>
    </div>
  </div>

  `,
  styleUrls: ['./landing-tabs.component.scss']
})
export class LandingTabsComponent implements OnInit {
  showQuickQuote: string; // keerthana
  constructor(private googleAnalytics: GoogleAnalyticsService) { }

  ngOnInit() {
    this.showQuickQuote = `${environment.showQuickQuote}`;
  }

  addGATags(actionType: string) {
    if (actionType === 'idCard') {
      this.googleAnalytics.trackEvent(' Unauthenticated View IDCard', 'Home Screen', 'Unauthenticated view ID  Click', 200);
    } else if (actionType === 'payment') {
      this.googleAnalytics.trackEvent('Unauthenticated Make a payment', 'Home Screen', 'Unauthenticated Make a Payment Click', 200);
    } else if (actionType === 'login') {
      this.googleAnalytics.trackEvent('Login', 'Home Screen', 'Login Tab', 200);
    } else if (actionType === 'direct') {
      this.googleAnalytics.trackEvent('Direct', 'Home Screen', 'Direct Tab', 200);
    }
  }
}
