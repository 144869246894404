export class Policymgmtexstreamreq {

  appName: string;
  mco: string;
  symbol: string;
  policyNumber: string;
  module: string;
  confirmationNumber: string;
  cancellationDttmstmp: string;
  eftIndicator: string;
  renewalIndicator: string;
  cancelAmount: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

}
