export class DirectsalesParameters {
    id = '';
    zip = '';
    ppcsrc = '';
    firstname = '';
    lastname = '';
    streetaddress = '';
    streetapt = '';
    streetcity = '';
    emailid = '';
    phoneno = '';
    dob = '';
    gender = '';
    dsplyPhn = '';

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
