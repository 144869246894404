import { Component, Inject, OnInit,OnChanges, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { Router } from "@angular/router";
import { BwstoreService } from "../../shared/services/bwstore.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Ccpaymentreq } from "../../shared/model/api/payment/ccpaymentreq";
import {
  FullPolicyDetails,
  PolicyInfo,
} from "../../shared/model/api/bwibic/fullpolicydetails.model";
import {
  Paymentsummary,
  PaymentInfo,
  PaymentHistoryInfo,
} from "../../shared/model/api/bwibic/fullpolicydetails.model";
import { Keyvalue } from "../../shared/model/keyvalue";
import { Paymentres } from "../../shared/model/api/payment/paymentres";
import { GlobalConstants } from "../../constants/global.constant";
import { Policykey } from "../../shared/model/api/payment/policykey";
import { Paymentdetailsres } from "../../shared/model/api/payment/paymentdetailsres";
import { Previouspaymentreq } from "../../shared/model/api/payment/previouspaymentreq";
import { PaymentOptionType } from "../../shared/model/api/payment/payment-option-type";
import { Achpaymentreq } from "../../shared/model/api/payment/achpaymentreq";
import { MessagesService } from "../../shared/services/messages.service";
import { AutopayeftService } from "../../services/mypolicy/autopayeft.service";
import { Bankdetailsres } from "../../shared/model/api/payment/bankdetailsres.model";
import { GoogleAnalyticsService } from "../../shared/services/analytics/google-analytics.service";
import { MessageConstants } from "../../constants/message.constant";
import { Tracker } from "../../shared/utils/tracker";
import { Meta, Title } from "@angular/platform-browser";
import { BillingDetails } from "../../shared/model/billing-details.model";
import {
  PaymentAmountDetails,
  SelectedPayment,
} from "../../shared/model/payment-amount-details.model";
import { NewPaymentResponse } from "../../shared/model/new-payment.model";
import { SelectedPaymentMethod } from "../../shared/model/payment-method.model";
import { PaymentService } from "../../services/api/payment/payment.service";
import { NewPaymentService } from "../../services/api/payment/new-payment.service";
import { PaymentMethodInfo } from "../../shared/model/api/payment/payment-methods.model";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { PaymentusPaymentMethodDialogComponent } from "./paymentus-payment-method-dialog/paymentus-payment-method-dialog.component";
import { MatSnackBar } from "@angular/material";
import { filter } from "rxjs/operators";
import { fromEvent, Subscription } from "rxjs";
import { PolicyHolder } from "../../shared/model/authentication/authenticatephres";

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"],
})
export class PaymentComponent implements OnInit {
  // NG Store
  


 
  
  paymentError : boolean = false;
  selectedPaymentMethodNew: any;
  selectedPaymentMethod = {} as SelectedPaymentMethod;
  billingDetails = {} as BillingDetails;
  paymentAmountDetails = {} as PaymentAmountDetails;
  selectedPayment = {} as SelectedPayment;
  newPaymentResponse = {} as NewPaymentResponse;
  basicPolicyDetails: FullPolicyDetails;
  policyInfo: PolicyInfo;
  paymentinfo: PaymentInfo;
  paymentHistoryInfo: PaymentHistoryInfo[];
  paymentForm: FormGroup;
  paymentErrorMsg = "";
  serviceUnavailableMsg = MessageConstants.PAY_SERVICE_DOWN;
  serviceOpsNumber = GlobalConstants.SERVICE_OPS_NUMBER;
  noPaymentsLeftMsg = MessageConstants.PAY_NO_PAYMENTS;
  paymentMoniker: string;
  newMoniker: string;
  bankError = "";
  bankName = "";
  formSubmitAttempt: boolean;
  isOtherAmount = false;
  isCreditCard = false;
  isNew = false;
  isNewCC = false;
  isNewACH = false;
  isNewACHS = false;
  hasPayments = true;
  isServiceAvaiable = true;
  isNoLogin = false;
  paymentMethods: PaymentMethodInfo[];
  accNumber: string;
  showModal = false;
  selectedRadioButton: boolean = false;
  updatePaymentToken = '';

  // For payment header
  premium: string;
  lastPaymentDate: string;
  policystatus: string;
  paymentAmount: string;
  policystatusepc: string;

  // For ACH
  checkDate = Date.now();

  // For CC
  cvcLength = "3";
  ccLength = "18";
  selectedCard = "none";
  cvcSVG = "visa-mc-cvc-preview";

  
  // UI
  isLoaded = false;
  loadingMessage: string;
  amountOptions: Keyvalue[];
  keyvalueAmount: Keyvalue;
  keyvalueBalance: Keyvalue;
  selectedAmount: string;
  otherAmount = "";

  // Request and Response
  ccpaymentreq: Ccpaymentreq;
  achpaymentreq: Achpaymentreq;
  paymentres: Paymentres;
  paymentDetailsRes: Paymentdetailsres;
  previouspaymentreq: Previouspaymentreq;

  errorType: string = "";

  policyKey: Policykey;
  isPaymentMethodExits = false;

  DEFAULT_DIALOG_MIN_HEIGHT = "50vh";
  DEFAULT_DIALOG_WIDTH = "400px";
  DEFAULT_MAX_DIALOG_WIDTH = "98vw";
  dialogRef: MatDialogRef<PaymentusPaymentMethodDialogComponent>;
  EVENT_NAME = "message";
  PAYMENTUS_IO = "paymentus";
  PM_DETAILS = "pmDetails";
  newPaymentMethodSubscription: Subscription;
  authenticatedPhDetails: PolicyHolder;
  paymentusEventNewPaymentMethod$ = fromEvent(window, this.EVENT_NAME).pipe(
    filter((event: MessageEvent) => {
      return event.origin.includes(this.PAYMENTUS_IO);
    })
  );

  constructor(
    private ref : ChangeDetectorRef,
    private newPaymentService: NewPaymentService,
    private paymentService: PaymentService,
    private router: Router,
    private fb: FormBuilder,
    private _storeService: BwstoreService,
    private _messageService: MessagesService,
    private modalService: NgbModal,
    private eftService: AutopayeftService,
    private googleAnalytics: GoogleAnalyticsService,
    private title: Title,
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    private meta: Meta
     
  ) {
    title.setTitle("Bristol West Policy - Payments");
    this.googleAnalytics.trackEvent(
      "Billing",
      "BillingScreen",
      "BillingButtonClick",
      200
    );
  }

  ngOnInit() {
    this.loadingMessage = "Retrieving Payment Details...";
    this.basicPolicyDetails = this._storeService.getPolicyInfoFromStore();
    this.authenticatedPhDetails = this._storeService.getLoginInfoFromStore();
    this.selectedPayment.amount = "";
    this.selectedPayment.type = "";
    this.selectedRadioButton = false;

    // Load policy holder info from store
    this.policyInfo = this.basicPolicyDetails.policyInfo;
    this.billingDetails.basicPolicyDetails = this.basicPolicyDetails;
    this.billingDetails.policyInfo = this.policyInfo;
    this.paymentHistoryInfo = this.basicPolicyDetails.paymentHistoryInfo;
    this.paymentAmountDetails.basicPolicyDetails = this.basicPolicyDetails;
    this.paymentAmountDetails.policyInfo = this.policyInfo;
    this.accNumber =
      this.policyInfo.Location +
      this.policyInfo.MASTER0CO +
      this.policyInfo.PolicyCompany +
      this.policyInfo.SYMBOL +
      this.policyInfo.POLICY0NUM;
    this.paymentService
      .setPaymentMethods(this.accNumber)
      .subscribe((paymentMethods) => {
        if (paymentMethods) this.paymentMethods = paymentMethods;
      });
    this.addNewPaymentMethod();

    // first check to see if there is a PaymentInfo Object
    this.paymentinfo = this.basicPolicyDetails.paymentInfo;

    if (this.paymentinfo && this.basicPolicyDetails.isNoLogin) {
      this.isNoLogin = true;
      this.paymentDetailsRes = new Paymentdetailsres();
      this.paymentDetailsRes.address = this.paymentinfo.address;
      this.paymentDetailsRes.city = this.paymentinfo.city;
      this.paymentDetailsRes.firstName = this.paymentinfo.firstName;
      this.paymentDetailsRes.homePhoneNumber = this.paymentinfo.homePhoneNumber;
      this.paymentDetailsRes.lastName = this.paymentinfo.lastName;
      this.paymentDetailsRes.lastPayAmount = this.paymentinfo.lastPayAmount;
      this.paymentDetailsRes.lastPayDate = this.paymentinfo.lastPayDate;
      this.paymentDetailsRes.maximumPayment = this.paymentinfo.maximumPayment;
      this.paymentDetailsRes.minimumPayment = this.paymentinfo.minimumPayment;
      this.paymentDetailsRes.paymentMoniker = null; // this.paymentinfo.paymentMoniker;
      this.paymentDetailsRes.paymentType = this.paymentinfo.paymentType;
      this.paymentDetailsRes.paymentVendor = this.paymentinfo.paymentVendor;
      this.paymentDetailsRes.policyBalance = this.paymentinfo.policyBalance;
      this.paymentDetailsRes.state = this.paymentinfo.state;
      this.paymentDetailsRes.totalPremium = this.paymentinfo.totalPremium;
      this.paymentDetailsRes.zip = this.paymentinfo.zip;
      this.paymentDetailsRes.eftIndicator = this.paymentinfo.eftIndicator;
      this.paymentDetailsRes.policyCompany = this.policyInfo.PolicyCompany;
      if (
        this.vaildateServiceResponse(
          this.paymentDetailsRes,
          this.policyInfo,
          this.paymentHistoryInfo
        )
      ) {
        // Reactive form builder for Form Validations
        this.createPaymentForm();
        Tracker.loginfo(
          "PaymentComponent",
          "ngOnInit",
          "vaildateServiceResponse",
          "Loaded payment details " + this.paymentDetailsRes.responseText
        );

        // Remove session
        this._storeService.deletePolicyInfo(new FullPolicyDetails());
        this.isLoaded = true;
      } else {
        return false;
      }
    } else {
      if (this.policyInfo !== null) {
        // need to load the payment moniker from the service call
        this.paymentService
          .validatePolicy(
            this.policyInfo.PolicyZip,
            this.policyInfo.SYMBOL,
            this.policyInfo.POLICY0NUM
          )
          .subscribe(
            (data) => {
              this.paymentDetailsRes = data;
              if (
                this.paymentDetailsRes !== null &&
                this.vaildateServiceResponse(
                  this.paymentDetailsRes,
                  this.policyInfo,
                  this.paymentHistoryInfo
                )
              ) {
                Tracker.loginfo(
                  "PaymentComponent",
                  "ngOnInit",
                  "vaildateServiceResponse",
                  "Loaded payment details " +
                    this.paymentDetailsRes.responseText
                );

                // Reactive form builder for Form Validations
                this.createPaymentForm();
                this.isLoaded = true;
              } else {
                this.isLoaded = true;
                return false;
              }
            },
            (error) => {
              this.isServiceAvaiable = false;
              Tracker.logerror(
                "PaymentComponent",
                "ngOnInit",
                "",
                "Error loading payment screen.",
                error
              );
              this.isLoaded = true;
            }
          );
      } else {
        this.isServiceAvaiable = false;
        this.isLoaded = true;
      }
    }
  }

  vaildateServiceResponse(
    paymentdetialsres: Paymentdetailsres,
    policyinfo: PolicyInfo,
    paymentHistoryInfo: PaymentHistoryInfo[]
  ) {
    if (
      paymentdetialsres != null &&
      paymentdetialsres.policyBalance !== undefined
    ) {
      // Tracker.log(paymentdetialsres.policyBalance);
      if (+paymentdetialsres.minimumPayment <= 0) {
        this.hasPayments = false;
        return false;
      }
    } else {
      this.isServiceAvaiable = false;
      return false;
    }

    this.policyKey = new Policykey();
    this.policyKey.masterCompany = policyinfo.PolicyMasterCompany;
    this.policyKey.module =
      paymentdetialsres.mod !== undefined
        ? paymentdetialsres.mod
        : policyinfo.MODULE;
    this.policyKey.policyCompany = policyinfo.PolicyCompany;
    this.policyKey.policyNumber = policyinfo.POLICY0NUM;
    this.policyKey.symbol = policyinfo.SYMBOL;

    if (
      paymentdetialsres.paymentMoniker !== null &&
      paymentdetialsres.paymentMoniker !== undefined
    ) {
      this.isPaymentMethodExits = true;
      this.paymentMoniker = paymentdetialsres.paymentMoniker;
      this.newMoniker = this.paymentMoniker;
    } else {
      this.paymentMoniker = "Please select a payment method";
    }

    // For Payment Header
    if (!this.isNoLogin) {
      //console.log("in ");
      this.premium = paymentdetialsres.totalPremium;
      this.policystatus = this.policyInfo.PolicyStatus;
      this.policystatusepc = this.policyInfo.PolicyStatusEPC;

      if (
        paymentdetialsres.lastPayAmount && paymentdetialsres.lastPayAmount!==''  && paymentdetialsres.lastPayDate && 
        paymentdetialsres.lastPayDate !== null && paymentdetialsres.lastPayDate!=='null' && paymentdetialsres.lastPayDate!=='' && paymentdetialsres.lastPayDate!=='undefined' 
      ) {
       // console.log("in2");
        this.lastPaymentDate = paymentdetialsres.lastPayDate;
        this.paymentAmount = paymentdetialsres.lastPayAmount;
      } else {
        //console.log("in3");
        if (paymentHistoryInfo  && paymentHistoryInfo.length > 0) {
          //console.log("in4");
          for (let index = paymentHistoryInfo.length - 1; index >= 0; index--) {
            const currentPaymentHistory = paymentHistoryInfo[index];

            if (currentPaymentHistory.TransactionType === "Payment") {
              this.lastPaymentDate =
                paymentHistoryInfo[index].PaymentActivityDate;
              this.paymentAmount = paymentHistoryInfo[
                index
              ].PaymentAmount.replace("-", "");
              break;
            }
          }
        }
      }
    }

    // For Amount Due
    this.keyvalueAmount = new Keyvalue();
    this.keyvalueAmount.key = "Amount Due $" + paymentdetialsres.minimumPayment;
    this.keyvalueAmount.value = paymentdetialsres.minimumPayment;
    this.keyvalueBalance = new Keyvalue();
    this.keyvalueBalance.key =
      "Remaining Balance $" + paymentdetialsres.maximumPayment;
    this.keyvalueBalance.value = paymentdetialsres.maximumPayment;
    this.amountOptions = [this.keyvalueAmount, this.keyvalueBalance];
    this.selectedAmount = paymentdetialsres.minimumPayment;

    if (
      paymentdetialsres.paymentType === null ||
      paymentdetialsres.paymentType === ""
    ) {
      this.isNew = true;
    } else if (paymentdetialsres.paymentType === "Credit Card") {
      this.isCreditCard = true;
    }
    return true;
  }

  onSubmitNew(formData: any, modalsubmitpayment) {
    this.modalService
      .open(modalsubmitpayment, { size: "sm", centered: false })
      .result.then(
        (result) => {
          this.isLoaded = false;
          this.loadingMessage = "Online Payment In Progress";
          this.googleAnalytics.trackEvent(
            "PayMyBill",
            "PaymentDetailsScreen",
            "PayMyBillButtonFromHomeScreen",
            200
          );
          let newPaymentRequest = {
            payment: {
              id: "",
              duplicateCheck: false,
              paymentAmount: 0.0,
              paymentMethodToken: "",
              agentFieldOfficeId: "",
              walletId: "",
              transactionSourceSystem: "",
              exixtingCustomerInd: "",
              uwCompanyCode: "",
              policy: {
                uwCompanyCode: "",
              },
              customer: {
                firstName: "",
                lastName: "",
                phoneNumber: "",
                email: "",
                address: {
                  line1: "",
                  city: "",
                  state: "",
                  zipCode: "",
                  country: "",
                },
              },
            },
          };

          //console.log(result);
          //this.newPaymentRequest.payment.id = this.selectedPaymentMethod.data.id;
          newPaymentRequest.payment.id = this.accNumber;
          //newPaymentRequest.payment.id = "002800G011429321";
          newPaymentRequest.payment.paymentAmount = parseFloat(
            this.selectedPayment.amount
          );
          newPaymentRequest.payment.paymentMethodToken =
            this.selectedPaymentMethodNew.method.paymentToken;
          //newPaymentRequest.payment.paymentMethodToken ="9501B4C659A28AECD5ECC39EAB572D3AB8A4B342"
          //newPaymentRequest.payment.agentFieldOfficeId = this.basicPolicyDetails.agentInfo.AgentCode;
          newPaymentRequest.payment.agentFieldOfficeId = "5006337";
          newPaymentRequest.payment.walletId = newPaymentRequest.payment.id;
          //newPaymentRequest.payment.stateCode =
          newPaymentRequest.payment.transactionSourceSystem =
            GlobalConstants.APPID;
          newPaymentRequest.payment.exixtingCustomerInd = "N";
          newPaymentRequest.payment.uwCompanyCode =
            this.policyKey.masterCompany.concat(
              this.paymentDetailsRes.policyCompany
            );
          newPaymentRequest.payment.policy.uwCompanyCode =
            this.policyKey.masterCompany.concat(
              this.paymentDetailsRes.policyCompany
            );
          //newPaymentRequest.payment.uwCompanyCode = "2800";
          //newPaymentRequest.payment.policy.uwCompanyCode = "2800";

          //----customer details----
          newPaymentRequest.payment.customer.firstName = formData.fname;
          newPaymentRequest.payment.customer.lastName = formData.lname;
          //this.newPaymentRequest.payment.customer.phoneNumber =
          //this.newPaymentRequest.payment.customer.email =
          //----address----
          newPaymentRequest.payment.customer.address.city = formData.city;
          //this.newPaymentRequest.payment.customer.address.country=
          newPaymentRequest.payment.customer.address.line1 = formData.address;
          newPaymentRequest.payment.customer.address.state = formData.state;
          newPaymentRequest.payment.customer.address.zipCode = formData.zip;
          newPaymentRequest.payment.duplicateCheck = true;
          //console.log(newPaymentRequest);
          this.newPaymentService.makeNewPayment(newPaymentRequest).subscribe(
            (data) => {
              //console.log(data);
              this.newPaymentResponse = data;
              // this.newPaymentResponse.transactionNotification = null;
              // this.newPaymentResponse.payment.status = 'DECLINED';
              // this.newPaymentResponse.payment.statusDescription='Credit Floor';

              if (
                this.newPaymentResponse.transactionNotification ||
                this.newPaymentResponse.payment.status !== "ACCEPTED"
              ) {
                //console.log(this.newPaymentResponse);
                this.isLoaded = true;
                this.paymentError = true;
                this.selectedPayment.validated = false;
                this.selectedPaymentMethodNew = null;

                //this.paymentErrorService.transactionNotification.next(this.newPaymentResponse.transactionNotification);
                //this.router.navigate(['payment-failed']);
              } else {
                this.paymentError = false;
                this.updatePaymentSummaryNew(formData, this.newPaymentResponse);
                this.router.navigate(["payment-success"]);
                this.isLoaded = true;
                this.loadingMessage = "";
              }
            },
            (error) => {
              this.isLoaded = true;
              this.loadingMessage = "";
              this.paymentError = true;
              this.selectedPayment.validated = false;
              this.selectedPaymentMethodNew = null;
              this.snackbar.open(MessageConstants.API_ERROR, "", {
                duration: 3000,
              });
            }
          );
        },
        (reason) => {
          this.isLoaded = true;
          this.loadingMessage = "";
          //part of Defect DE84306
          //this.selectedPayment.validated = false;
          //this.selectedPaymentMethodNew = null;
          return;
        }
      )
      .catch((error) => {
        this.isLoaded = true;
        this.loadingMessage = "";
        this.selectedPayment.validated = false;
        this.selectedPaymentMethodNew =null;
        Tracker.logerror(
          "PaymentComponent",
          "onSubmit",
          "this.modalService.open",
          "Error occurred with payment modal.",
          error
        );
        this.snackbar.open(MessageConstants.API_ERROR, "", { duration: 3000 });
      });
  }

  addEditPaymentMethod(paymentType: string, token: string, addOrEdit: string) {
    //console.log("In here");
    let temp = {method : {
      paymentToken:''
    }}
    const name: string[] = this.policyInfo.ADD0LINE01.split(" ");
    this.dialogRef = this.dialog.open(PaymentusPaymentMethodDialogComponent, {
      disableClose: true,
      closeOnNavigation: true,
      minHeight: this.DEFAULT_DIALOG_MIN_HEIGHT,
      width: this.DEFAULT_DIALOG_WIDTH,
      maxWidth: this.DEFAULT_MAX_DIALOG_WIDTH,
      data: {
        paymentType,
        token,
        addOrEdit,
        pageName: "Pay Bill",
        firstName: name[1],
        lastName: name[0],
        ownerId: this.accNumber.toLowerCase(),
        postMessagePmDetailsOrigin: window.location.origin as string,
        paymentToken: token ? token : "",
      },
    });
    this.dialogRef.afterClosed().subscribe(() => {
      //console.log("In Here");
      temp.method.paymentToken = token;
      this.selectedPaymentMethodNew= temp;
      //console.log(this.selectedPaymentMethodNew);
      //console.log(this.selectedPayment);
      this.meta.updateTag(
        { content: "width=device-width, initial-scale=1" },
        `name='viewport'`
      );
      
    });
    this.showModal = true;
  }

  onSubmit(formData: any, modalsubmitpayment) {
    this.formSubmitAttempt = true;
    // Clear previous error
    this.paymentErrorMsg = "";
    // Tracker.log('form submitted');
    this.loadingMessage = "Processing Payment...";
    try {
      if (this.paymentForm.valid) {
        this.isLoaded = false;
        if (this.isOtherAmount) {
          this.selectedAmount = formData.otherAmount;
        }
        if (this.isValidAmount(this.selectedAmount)) {
          // Set moniker if new payment method before displaying the modal
          if (this.isNew) {
            if (this.isNewCC) {
              this.newMoniker = this.selectedCard
                .toUpperCase()
                .concat(" - ")
                .concat(
                  formData.ccNumber.substring(formData.ccNumber.length - 4)
                );
            } else if (this.isNewACH) {
              this.newMoniker = "Checking - ".concat(
                formData.accountNumber.substring(
                  formData.accountNumber.length - 4
                )
              );
            } else if (this.isNewACHS) {
              this.newMoniker = "Saving - ".concat(
                formData.accountNumber.substring(
                  formData.accountNumber.length - 4
                )
              );
            }
          }
          // Prompt user to confirm payment
          this.modalService
            .open(modalsubmitpayment, { size: "sm", centered: false })
            .result.then(
              (result) => {
                this.googleAnalytics.trackEvent(
                  "PayMyBill",
                  "PaymentDetailsScreen",
                  "PayMyBillButtonFromHomeScreen",
                  200
                );
                if (this.isNew) {
                  // Paying with new payment method
                  if (this.isNewCC) {
                    // populate credit card payment request
                    this.ccpaymentreq = new Ccpaymentreq();
                    this.ccpaymentreq.accountHolderFirstName = formData.fname;
                    this.ccpaymentreq.accountHolderLastName = formData.lname;
                    this.ccpaymentreq.address = formData.address;
                    this.ccpaymentreq.city = formData.city;
                    this.ccpaymentreq.postalCode = formData.zip;
                    this.ccpaymentreq.state = formData.state;
                    this.ccpaymentreq.policyKey = this.policyKey;
                    this.ccpaymentreq.eftEnrollment =
                      this.paymentDetailsRes.eftIndicator;
                    this.ccpaymentreq.referenceCode = "12";
                    this.ccpaymentreq.cardNumber = this.formatCC(
                      formData.ccNumber
                    );
                    this.ccpaymentreq.expirationMonth =
                      formData.ccExp.substring(0, 2);
                    this.ccpaymentreq.expirationYear =
                      "20" + formData.ccExp.substring(3);
                    this.ccpaymentreq.transactionSource = GlobalConstants.APPID;
                    this.ccpaymentreq.transactionSourceSystem =
                      GlobalConstants.APPID;
                    this.ccpaymentreq.cvv2 = formData.ccCvc;
                    // this.ccpaymentreq.browserType = 'IE';
                    this.ccpaymentreq.amount = this.selectedAmount;
                    this.ccpaymentreq.symbol = this.policyKey.symbol;
                    this.ccpaymentreq.policy = this.policyKey.policyNumber;
                    this.ccpaymentreq.mod = this.policyKey.module;
                    this.ccpaymentreq.mco = this.policyKey.masterCompany;
                    this.ccpaymentreq.pco =
                      this.paymentDetailsRes.policyCompany;
                    this.paymentService.ccPayment(this.ccpaymentreq).subscribe(
                      (data) => {
                        this.paymentres = data;
                        if (this.paymentres != null) {
                          if (this.paymentres.success) {
                            this.googleAnalytics.trackEvent(
                              "PayMyBill",
                              "PaymentDetailsScreen",
                              "PaymentSuccessfull",
                              200
                            );
                            // add the confirmation number and last 4 of CC to store
                            // or use service to pass to payment success page
                            // Tracker.log('Successful Payment:' + this.paymentres.confirmationNumber);
                            if (this.isNoLogin) {
                              const paymentSummary = new Paymentsummary();
                              this.policyInfo = new PolicyInfo();
                              this.policyInfo.SYMBOL = this.policyKey.symbol;
                              this.policyInfo.POLICY0NUM =
                                this.policyKey.policyNumber;
                              this.policyInfo.MODULE = this.policyKey.module;
                              this.paymentDetailsRes.policyCompany =
                                this.policyInfo.PolicyCompany;
                              const basicPolicyInfo =
                                this._storeService.getPolicyInfoFromStore();
                              basicPolicyInfo.paymentSummary = paymentSummary;
                              basicPolicyInfo.policyInfo = this.policyInfo;
                              this._storeService.updatePolicyInfo(
                                basicPolicyInfo
                              );
                            }

                            this.updatePaymentSummary(formData);
                            this.router.navigate(["payment-success"]);
                          } else {
                            // payment issue. Handle and display appropriate message
                            // return to payment screen
                            if (
                              this.paymentres.responseText !== null &&
                              this.paymentres.responseCode !== null
                            ) {
                              Tracker.loginfo(
                                "PaymentComponent",
                                "onSubmit",
                                "",
                                this.paymentres.responseText
                              );
                              this.paymentErrorMsg =
                                this.paymentres.responseText;
                              this.isLoaded = true;
                              this.googleAnalytics.trackEvent(
                                "PaymentSubmission Error Messages",
                                this.paymentErrorMsg,
                                "Proceed button click",
                                200
                              );
                            }
                          }
                        } else {
                          // display canned message
                          this.paymentErrorMsg = "Error submitting payment";
                          // Tracker.log(this.paymentErrorMsg);
                          this.isLoaded = true;
                          this.googleAnalytics.trackEvent(
                            "MobilePay Service Error",
                            this.paymentErrorMsg,
                            "MethodofPayment",
                            200
                          );
                        }
                      },
                      (error) => {
                        Tracker.logerror(
                          "PaymentComponent",
                          "onSubmit",
                          "this.paymentService.ccPayment",
                          "Error occurred during cc payment submit.",
                          error
                        );
                        this.isLoaded = true;
                      }
                    );
                  } else {
                    // ACH
                    this.achpaymentreq = new Achpaymentreq();
                    this.achpaymentreq.accountHolderFirstName = formData.fname;
                    this.achpaymentreq.accountHolderLastName = formData.lname;
                    this.achpaymentreq.address = formData.address;
                    this.achpaymentreq.city = formData.city;
                    this.achpaymentreq.postalCode = formData.zip;
                    this.achpaymentreq.homePhoneNumber = "";
                    this.achpaymentreq.state = formData.state;
                    this.achpaymentreq.eftEnrollment =
                      this.paymentDetailsRes.eftIndicator;
                    this.achpaymentreq.transactionSource =
                      GlobalConstants.APPID;
                    this.achpaymentreq.transactionSourceSystem =
                      GlobalConstants.APPID;
                    this.achpaymentreq.referenceCode = "12";
                    this.achpaymentreq.abanumber = formData.routingNumber;
                    this.achpaymentreq.accountNumber = formData.accountNumber;
                    this.achpaymentreq.amount = this.selectedAmount;
                    this.achpaymentreq.mco = this.policyKey.masterCompany;
                    this.achpaymentreq.symbol = this.policyKey.symbol;
                    this.achpaymentreq.policyNumber =
                      this.policyKey.policyNumber;
                    this.achpaymentreq.module = this.policyKey.module;
                    this.achpaymentreq.pco =
                      this.paymentDetailsRes.policyCompany;
                    //ACH Checking and Savings
                    if (this.isNewACH) {
                      this.achpaymentreq.checkType = "CHQ";
                    } else if (this.isNewACHS) {
                      this.achpaymentreq.checkType = "SAV";
                    }
                    this.paymentService
                      .achPayment(this.achpaymentreq)
                      .subscribe(
                        (data) => {
                          this.paymentres = data;
                          if (this.paymentres != null) {
                            if (this.paymentres.success) {
                              // add the confirmation number and last 4 of CC to store
                              // or use service to pass to payment success page
                              if (this.isNoLogin) {
                                const paymentSummary = new Paymentsummary();
                                this.policyInfo = new PolicyInfo();
                                this.policyInfo.SYMBOL = this.policyKey.symbol;
                                this.policyInfo.POLICY0NUM =
                                  this.policyKey.policyNumber;
                                this.policyInfo.MODULE = this.policyKey.module;
                                this.policyInfo.PolicyCompany =
                                  this.paymentDetailsRes.policyCompany;
                                const basicPolicyInfo =
                                  this._storeService.getPolicyInfoFromStore();
                                basicPolicyInfo.paymentSummary = paymentSummary;
                                basicPolicyInfo.policyInfo = this.policyInfo;
                                this._storeService.updatePolicyInfo(
                                  basicPolicyInfo
                                );
                              }

                              this.updatePaymentSummary(formData);
                              this.router.navigate(["payment-success"]);
                            } else {
                              // payment issue. Handle and display appropriate message
                              // return to payment screen
                              // Tracker.log('ERROR *****= ' + this.paymentres.responseText);
                              this.paymentErrorMsg =
                                this.paymentres.responseText;
                              this.isLoaded = true;
                            }
                          }
                        },
                        (error) => {
                          Tracker.logerror(
                            "PaymentComponent",
                            "onSubmit",
                            "this.paymentService.achPayment",
                            "Error occurred during ach payment submit.",
                            error
                          );
                          this.isLoaded = true;
                        }
                      );
                  }
                } else {
                  // Populate req obj for previous payment
                  this.previouspaymentreq = new Previouspaymentreq();
                  const paymentOptionType = new PaymentOptionType();

                  // Paying with previous payment method
                  paymentOptionType.paymentMoniker =
                    this.paymentDetailsRes.paymentMoniker;
                  paymentOptionType.paymentType =
                    this.paymentDetailsRes.paymentType;
                  paymentOptionType.paymentVendor =
                    this.paymentDetailsRes.paymentVendor;
                  this.previouspaymentreq.paymentOption = paymentOptionType;
                  this.previouspaymentreq.policyKey = this.policyKey;
                  this.previouspaymentreq.accountHolderFirstName =
                    formData.fname;
                  this.previouspaymentreq.accountHolderLastName =
                    formData.lname;
                  this.previouspaymentreq.address = formData.address;
                  this.previouspaymentreq.city = formData.city;
                  this.previouspaymentreq.postalCode = formData.zip;
                  this.previouspaymentreq.state = formData.state;
                  this.previouspaymentreq.homePhoneNumber =
                    this.paymentDetailsRes.homePhoneNumber;
                  this.previouspaymentreq.amount = this.selectedAmount;
                  this.previouspaymentreq.transactionSource =
                    GlobalConstants.APPID;
                  this.previouspaymentreq.transactionSourceSystem =
                    GlobalConstants.APPID;

                  this.paymentService
                    .previousPayment(this.previouspaymentreq)
                    .subscribe(
                      (data) => {
                        this.paymentres = data;
                        if (this.paymentres != null) {
                          if (this.paymentres.success) {
                            // add the confirmation number and last 4 of CC to store
                            // or use service to pass to payment success page
                            if (this.isNoLogin) {
                              const paymentSummary = new Paymentsummary();
                              this.policyInfo = new PolicyInfo();
                              this.policyInfo.SYMBOL = this.policyKey.symbol;
                              this.policyInfo.POLICY0NUM =
                                this.policyKey.policyNumber;
                              this.policyInfo.MODULE = this.policyKey.module;
                              this.basicPolicyDetails.paymentSummary =
                                paymentSummary;
                              this.basicPolicyDetails.policyInfo =
                                this.policyInfo;
                              this._storeService.updatePolicyInfo(
                                this.basicPolicyDetails
                              );
                            }
                            // Set payment response data in the store
                            this.updatePaymentSummary(formData);

                            this.router.navigate(["payment-success"]);
                          } else {
                            // payment issue. Handle and display appropriate message
                            // return to payment screen
                            // Tracker.log('ERROR *****= ' + this.paymentres.responseText);
                            /*
                       if (this.basicPolicyDetails.agentInfo.AgentType === GlobalConstants.EA_AGENT) {
                         this.paymentErrorMsg = MessageConstants.EA_PAYMENT_ERROR;
                       } else {
                         this.paymentErrorMsg = MessageConstants.IA_PAYMENT_ERROR;
                       }
                       */
                            if (
                              this.paymentres &&
                              this.paymentres.responseCode !== undefined
                            ) {
                              this.paymentErrorMsg =
                                this.paymentres.responseText;
                            }
                            this.sendToTop();
                            this.isLoaded = true;
                          }
                        }
                      },
                      (error) => {
                        Tracker.logerror(
                          "PaymentComponent",
                          "onSubmit",
                          "this.paymentService.previousPayment",
                          "Error occurred during previous payment submit.",
                          error
                        );
                      }
                    );
                }
              },
              (reason) => {
                this.isLoaded = true;
                return;
              }
            )
            .catch((error) => {
              this.isLoaded = true;
              Tracker.logerror(
                "PaymentComponent",
                "onSubmit",
                "this.modalService.open",
                "Error occurred with payment modal.",
                error
              );
            });
        } else {
          this.sendToTop();
          this.isLoaded = true;
        }
      }
    } catch (error) {
      Tracker.logerror(
        "PaymentComponent",
        "onSubmit",
        "main call",
        "Error occurred.",
        error
      );
      this.isLoaded = true;
    } finally {
      // Clean up needed?
    }
  }

  formatCC(ccNum: string) {
    if (ccNum !== null) {
      const re = /\ /gi;
      return ccNum.replace(re, "");
    }
  }

  updatePaymentSummary(formData: any) {
    const paymentSummary = new Paymentsummary();
    paymentSummary.amount = this.selectedAmount;
    paymentSummary.firstName = formData.fname;
    paymentSummary.lastName = formData.lname;
    paymentSummary.cardLastFour = this.newMoniker;
    paymentSummary.confirmation = this.paymentres.confirmationNumber;

    if (this.isCreditCard) {
      paymentSummary.isCreditCard = true;
    } else {
      paymentSummary.isCreditCard = false;
    }
    this.basicPolicyDetails.paymentSummary = paymentSummary;
    this._storeService.updatePolicyInfo(this.basicPolicyDetails);
  }

  updatePaymentSummaryNew(formData: any, data: NewPaymentResponse) {
    const paymentSummary = new Paymentsummary();
    paymentSummary.amount = this.selectedPayment.amount;
    paymentSummary.firstName = formData.fname;
    paymentSummary.lastName = formData.lname;
    paymentSummary.cardLastFour = this.newMoniker;
    paymentSummary.confirmation = data.payment.referenceNumber;

    if (this.isCreditCard) {
      paymentSummary.isCreditCard = true;
    } else {
      paymentSummary.isCreditCard = false;
    }
    this.basicPolicyDetails.paymentSummary = paymentSummary;
    this._storeService.updatePolicyInfo(this.basicPolicyDetails);
  }

  createPaymentForm() {
    this.paymentForm = this.fb.group({
      amount: [
        this.paymentDetailsRes.minimumPayment,
        Validators.compose([Validators.required]),
      ],
      otherAmount: [
        "",
        Validators.compose([Validators.pattern("^\\d+\\.\\d{2}$")]),
      ],
      selectedPaymentType: [
        this.paymentMoniker,
        Validators.compose([
          Validators.required,
          this.selectPaymentTypeValidator,
        ]),
      ],
      fname: [
        this.paymentDetailsRes.firstName,
        Validators.compose([Validators.required]),
      ],
      lname: [
        this.paymentDetailsRes.lastName,
        Validators.compose([Validators.required]),
      ],
      address: [
        this.paymentDetailsRes.address,
        Validators.compose([Validators.required]),
      ],
      city: [
        this.paymentDetailsRes.city,
        Validators.compose([Validators.required]),
      ],
      state: [
        this.paymentDetailsRes.state,
        Validators.compose([Validators.required]),
      ],
      zip: [
        this.paymentDetailsRes.zip,
        Validators.compose([Validators.required, Validators.maxLength(5)]),
      ],
      routingNumber: [""],
      accountNumber: [""],
      type: [""],
      ccNumber: [""],
      ccExp: [""],
      ccCvc: [""],
    });
  }
  // Custom validator to check the payment mentod type default value
  selectPaymentTypeValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (control.value === "Please select a payment method") {
      return { required: true };
    }
    return null;
  }
  // check the first two entered digit of the cc and set the appropriate svg
  onKey(event: any) {
    // Tracker.log('Event Value: ' +  '\'' + event.target.value + '\'');
    // Default
    this.cvcLength = "3";
    this.ccLength = "19";

    if (/^5[1-5]/.test(event.target.value)) {
      this.selectedCard = "mastercard";
    } else if (/^4/.test(event.target.value)) {
      // then check for Visa
      this.selectedCard = "visa";
    } else if (/^3[47]/.test(event.target.value)) {
      // then check for Amex
      this.selectedCard = "amex";
      this.cvcLength = "4";
      this.ccLength = "17";
    } else if (/^6(?:011|5)/.test(event.target.value)) {
      // then check for Discover
      this.selectedCard = "discover";
    } else {
      if (event.target.value.length === 0) {
        this.selectedCard = "none";
      }
    }
  }

  onKeyPress(event: any) {
    // Default
    this.selectedAmount = event.target.value;
  }

  showOtherAmount(formData: any) {
    formData.otherAmount = "";
    if (formData.amount === "other") {
      this.isOtherAmount = true;
      this.selectedAmount = formData.otherAmount;
    } else {
      this.isOtherAmount = false;
      this.selectedAmount = formData.amount;
    }
  }

  showNewPayment(formData: any) {
    this.isCreditCard = false;

    if (formData.selectedPaymentType === "cc") {
      this.isNew = true;
      this.isNewCC = true;
      this.isNewACH = false;
      this.isNewACHS = false;
      this.isCreditCard = true;

      // need to toggle which controls are required
      this.updateControlValidation(this.paymentForm.controls.ccNumber, true);
      this.updateControlValidation(this.paymentForm.controls.ccExp, true);
      this.updateControlValidation(this.paymentForm.controls.ccCvc, true);

      this.updateControlValidation(
        this.paymentForm.controls.routingNumber,
        false
      );
      this.updateControlValidation(
        this.paymentForm.controls.accountNumber,
        false
      );
    } else if (formData.selectedPaymentType === "ach") {
      this.isNew = true;
      this.isNewCC = false;
      this.isNewACH = true;
      this.isNewACHS = false;
      this.isCreditCard = false;

      this.updateControlValidation(
        this.paymentForm.controls.routingNumber,
        true
      );
      this.updateControlValidation(
        this.paymentForm.controls.accountNumber,
        true
      );

      this.updateControlValidation(this.paymentForm.controls.ccNumber, false);
      this.updateControlValidation(this.paymentForm.controls.ccExp, false);
      this.updateControlValidation(this.paymentForm.controls.ccCvc, false);
    } else if (formData.selectedPaymentType === "achs") {
      this.isNew = true;
      this.isNewCC = false;
      this.isNewACH = false;
      this.isNewACHS = true;
      this.isCreditCard = false;

      this.updateControlValidation(
        this.paymentForm.controls.routingNumber,
        true
      );
      this.updateControlValidation(
        this.paymentForm.controls.accountNumber,
        true
      );

      this.updateControlValidation(this.paymentForm.controls.ccNumber, false);
      this.updateControlValidation(this.paymentForm.controls.ccExp, false);
      this.updateControlValidation(this.paymentForm.controls.ccCvc, false);
    } else {
      // Previous payment
      if (this.paymentDetailsRes.paymentType === "Credit Card") {
        this.isCreditCard = true;
      } else {
        this.isCreditCard = false;
      }
      this.newMoniker = this.paymentMoniker;
      this.isNew = false;
      this.isNewCC = false;
      this.isNewACH = false;
      this.isNewACHS = false;

      this.updateControlValidation(
        this.paymentForm.controls.routingNumber,
        false
      );
      this.updateControlValidation(
        this.paymentForm.controls.accountNumber,
        false
      );
      this.updateControlValidation(this.paymentForm.controls.ccNumber, false);
      this.updateControlValidation(this.paymentForm.controls.ccExp, false);
      this.updateControlValidation(this.paymentForm.controls.ccCvc, false);
    }
  }

  updateControlValidation(fControl: AbstractControl, isRequired: boolean) {
    if (isRequired) {
      fControl.setValidators([Validators.compose([Validators.required])]);
    } else {
      fControl.setValidators([]);
    }
    fControl.updateValueAndValidity();
  }

  // Form validations
  isFieldValid(field: string) {
    return (
      (!this.paymentForm.get(field).valid &&
        this.paymentForm.get(field).touched) ||
      (this.paymentForm.get(field).untouched && this.formSubmitAttempt)
    );
  }

  getErrMessage(errors, formCntrlName: string) {
    return this._messageService.getErrorMessages(errors, formCntrlName);
  }

  populateBankDetails(event: KeyboardEvent) {
    const routingnumber = (<HTMLInputElement>event.target).value;

    if (routingnumber) {
      // this.loadingBankDetails = true;
      this.eftService.bankDetails(routingnumber).subscribe(
        (res: Bankdetailsres) => {
          if (+res.responseCode === 0) {
            this.bankName = res.bankName.trim();
            this.bankError = "";
          } else {
            this.bankName = "";
            this.bankError = res.responseText;
          }
        },
        (error) => {
          Tracker.logerror(
            "PaymentComponent",
            "populateBankDetails",
            "this.eftService.bankDetails",
            "Error occurred validating Bank Details.",
            error
          );
        }
      );
    } else {
      this.bankName = "";
      this.bankError = "";
    }
  }

  clearBankDetails(event: any) {
    const value = event.target.value;

    if (value === "") {
      this.bankName = "";
      this.bankError = "";
    }
  }

  isValidAmount(paymentAmount: string) {
    // Check if the entered ammount is greater than the outstanding balance
    // or less than the minimum payment
    let otherAmountValue = "";
    let minimumPaymentAmount = "";
    let policyBalanceValue = "";
    let isValid = false;

    this.paymentErrorMsg = "";
    if (paymentAmount !== "" && paymentAmount !== undefined) {
      if (paymentAmount.indexOf(".") > -1) {
        // Has Decimal
        otherAmountValue = paymentAmount.replace(".", "");
        minimumPaymentAmount = this.paymentDetailsRes.minimumPayment.replace(
          ".",
          ""
        );
        policyBalanceValue = this.paymentDetailsRes.maximumPayment.replace(
          ".",
          ""
        );
      } else {
        // No Decimal
        otherAmountValue = paymentAmount;
        minimumPaymentAmount = this.paymentDetailsRes.minimumPayment.substring(
          0,
          this.paymentDetailsRes.minimumPayment.indexOf(".")
        );
        policyBalanceValue = this.paymentDetailsRes.maximumPayment.substring(
          0,
          this.paymentDetailsRes.maximumPayment.indexOf(".")
        );
      }

      if (+otherAmountValue > +policyBalanceValue) {
        // return message
        this.paymentErrorMsg =
          "Other Amount can not be greater then the remaining balance.";
      } else if (+otherAmountValue < +minimumPaymentAmount) {
        // return message
        this.paymentErrorMsg =
          "Other Amount can not be less then the minimum amount due.";
      } else {
        isValid = true;
      }
    }

    return isValid;
  }

  formatAmount(formData: any) {
    if (formData) {
      if (formData.otherAmount.indexOf(".")) {
        formData.otherAmount = formData.otherAmount.concat(".00");
        this.updateControlValidation(
          this.paymentForm.controls.otherAmount,
          false
        );
      }
    }
  }

  sendToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  selectedPaymentItem(data: SelectedPayment) {
    this.selectedPayment = data;
    
   
  }

  isDisable(): boolean {
    return this.selectedPayment.amount && this.selectedPayment.amount !== ""
      ? false
      : true;
  }

  getSelectedPaymentMethod(selectedPaymentMethod: PaymentMethodInfo) {
    this.selectedPaymentMethodNew = selectedPaymentMethod;
    //console.log(this.selectedPaymentMethodNew);
    if(this.selectedPayment && this.selectedPayment.validated){
      this.ref.detectChanges();
      document.getElementById("finalButton").focus();
      //document.getElementById("finalButton1").focus();
    }else{

      this.ref.detectChanges();
      document.getElementById("finalButton").focus();

    }
    
  }

  
 
  // enablePayment():boolean{
  //   return (this.basicPolicyDetails.policyInfo.PolicyStatusEPC!=='Cancelled' && this.basicPolicyDetails.policyInfo.PolicyStatusEPC!='Expired'  ) && this.selectedPayment.amount!=='' && this.selectedPaymentMethodNew && this.selectedPaymentMethodNew.method.paymentToken;
  // }

  // disablePayment():boolean{

  //   return this.selectedPayment.amount==='' || !this.selectedPaymentMethodNew || !this.selectedPaymentMethodNew.method.paymentToken || this.olicyInfo.PolicyStatusEPC==='Cancelled' || this.policyInfo.PolicyStatusEPC==='Expired';
  // }

  editPaymentMethod(event: any) {
    
    if (event) {
      //console.log(event);
      if (event.selected == true){ this.selectedRadioButton = true;}
      else {this.selectedRadioButton = false;}
      //console.log(this.updatePaymentToken)
      this.updatePaymentToken = event.paymentToken;
      //console.log(event.paymentToken);
      this.addEditPaymentMethod(
        event.paymentusIframePaymentType,
        event.paymentToken,
        event.mode
      );
    }
  }

  addNewPaymentMethod(): void {
    
    this.newPaymentMethodSubscription = this.paymentusEventNewPaymentMethod$
      .pipe(
        filter((event: MessageEvent) => {
          const eventData = JSON.stringify(event.data);
          return eventData.includes(this.PM_DETAILS);
        })
      )
      .subscribe((event: MessageEvent) => {
        try {
          const pmDetailsObject =
            this.paymentService.parsePMDetailsMessageFromIframe(event.data);
          const paymentMethodForm =
            this.paymentService.buildPaymentMethodInfo(pmDetailsObject);
          this.paymentMethods =
            this.paymentService.resetPaymentMethodsIsDefaultAndAddNew(
              this.paymentMethods,
              paymentMethodForm
            );
            this.paymentMethods.forEach((paymentMethod) => {
              paymentMethod.isUpdated = paymentMethod.paymentToken === this.updatePaymentToken;
          });
        } catch (e) {
          // show error or alert?
        }
      });
  }
  routeToMyPolicy() {
    this.router.navigate(["policy"]);
  }

  // isReinstatementAvailable():boolean{

  //   return this.policyInfo.PolicyStatusEPC === GlobalConstants.status.reinstatementAvailable;
  // }

  isReinstatementAvailable():boolean{
    if ((this.paymentAmountDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC === GlobalConstants.status.reinstatementAvailable &&
      this.authenticatedPhDetails.reInstateDueamount && this.authenticatedPhDetails.reInstateDueamount !== '0' && this.authenticatedPhDetails.reInstateDueamount !== '0.00')
      || this.authenticatedPhDetails.isReinstate) {
      //this.paymentAmountDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC = GlobalConstants.status.reinstatementAvailable;
      return this.getStatus();
    }  else if (this.paymentAmountDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC === GlobalConstants.status.reinstatementAvailable &&
      (!this.authenticatedPhDetails.reInstateDueamount || this.authenticatedPhDetails.reInstateDueamount === '0'
        || this.authenticatedPhDetails.reInstateDueamount === '0.00')) {// if reinstate from backend and amount is zero
          this.paymentAmountDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC = GlobalConstants.status.cancelled;
      return false;
    } else {
      return false;
    }
    //return this.policyInfo.PolicyStatusEPC=== GlobalConstants.status.reinstatementAvailable || this.authenticatedPhDetails.isReinstate;
  }

  getStatus():boolean{
    if(!(this.authenticatedPhDetails.reInstateDueamount && this.authenticatedPhDetails.reInstateDueamount!=='0' && this.authenticatedPhDetails.reInstateDueamount!=='0.00')){
      this.policyInfo.PolicyStatusEPC = GlobalConstants.status.cancelled;
      return false;
    }
    this.policyInfo.PolicyStatusEPC =  GlobalConstants.status.reinstatementAvailable;
    return true;
  }

  showMakePayment():boolean{
    if(this.isReinstatementAvailable()){
      //console.log("1")
      if((this.authenticatedPhDetails.reInstateDueamount && this.authenticatedPhDetails.reInstateDueamount!=='0' && this.authenticatedPhDetails.reInstateDueamount!=='0.00')||
      this.policyInfo.Balance && this.policyInfo.Balance!=='0' && this.policyInfo.Balance!=='0.00' && !this.policyInfo.Balance.includes('-')){
        return true;
      }else{
        return false;
      }
    }else{
      //console.log("2")
      if((this.basicPolicyDetails.paymentInfo.minimumPayment && this.basicPolicyDetails.paymentInfo.minimumPayment!=='0' && this.basicPolicyDetails.paymentInfo.minimumPayment!=='0.00') || 
      (this.basicPolicyDetails.paymentInfo.maximumPayment && this.basicPolicyDetails.paymentInfo.maximumPayment!=='0' && this.basicPolicyDetails.paymentInfo.maximumPayment!=='0.00')){

        return true;
      }else{
        return false;
      }
    }
  }


}
