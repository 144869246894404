import { OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BwstoreService } from '../../shared/services/bwstore.service';
import { MessagesService } from '../../shared/services/messages.service';
import { BWErrorhandler } from '../../services/errorhandler';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleAnalyticsService } from '../../shared/services/analytics/google-analytics.service';
var PhoneEditComponent = /** @class */ (function () {
    function PhoneEditComponent(route, router, fb, _storeService, _messageService, errorHandler, phoneEditModal, googleAnalytics) {
        this.route = route;
        this.router = router;
        this.fb = fb;
        this._storeService = _storeService;
        this._messageService = _messageService;
        this.errorHandler = errorHandler;
        this.phoneEditModal = phoneEditModal;
        this.googleAnalytics = googleAnalytics;
        // Toggle Indicators
        this.inProgesss = false;
        this.phoneUpdated = false;
        this.continueToTxtAlerts = false;
        this.createPhoneEditForm();
    }
    PhoneEditComponent.prototype.ngOnInit = function () {
    };
    PhoneEditComponent.prototype.submitPhoneEndorsement = function (formdate) {
        this.formSubmitAttempt = true;
        this.inProgesss = true;
        if (this.phoneEditForm.valid) {
            console.log('submission successful..........', formdate.phoneNumber);
            this.inProgesss = false;
            this.phoneUpdated = true;
        }
    };
    PhoneEditComponent.prototype.navigateToEnrolTxtAlert = function () {
        this.phoneEditModal.close();
        this.router.navigate(['/profile/txtalert']);
    };
    PhoneEditComponent.prototype.createPhoneEditForm = function () {
        this.phoneEditForm = this.fb.group({
            'phoneNumber': [{ value: '', disabled: false }, Validators.compose([Validators.required])],
        });
    };
    // Form Utilities
    PhoneEditComponent.prototype.isFieldValid = function (field) {
        return ((!this.phoneEditForm.get(field).valid && this.phoneEditForm.get(field).touched) ||
            (this.phoneEditForm.get(field).untouched && this.formSubmitAttempt));
    };
    PhoneEditComponent.prototype.getErrMessage = function (errors, formCntrlName) {
        return this._messageService.getErrorMessages(errors, formCntrlName);
    };
    return PhoneEditComponent;
}());
export { PhoneEditComponent };
