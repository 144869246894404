import { HttpParams } from '@angular/common/http';
import { GlobalConstants } from '../../../constants/global.constant';
import { CreateEnvelopeReq } from '../../../shared/model/api/esig/createenvelopereq.model';
import { Policynumber } from '../../../shared/model/policynumber';
import { BwstoreService } from '../../../shared/services/bwstore.service';
import { BwappService } from '../../bwapp.service';
import { BWErrorhandler } from '../../errorhandler';
import { CreateEmbeddedEnvelopeReq } from '../../../shared/model/api/esig/createembeddedenvelopereq.model';
import * as i0 from "@angular/core";
import * as i1 from "../../bwapp.service";
import * as i2 from "../../../shared/services/bwstore.service";
import * as i3 from "../../errorhandler";
var EsigService = /** @class */ (function () {
    function EsigService(appService, storeService, errorHandler) {
        this.appService = appService;
        this.storeService = storeService;
        this.errorHandler = errorHandler;
        this.globlalConstants = GlobalConstants;
    }
    EsigService.prototype.isESigImplemented = function (mco, stateCd) {
        var params = new HttpParams()
            .set('mco', mco)
            .set('stateCd', stateCd);
        return this.appService.get(this.globlalConstants.ESIG_ISESIGIMPLEMENTED, params);
    };
    EsigService.prototype.getEnvelops = function (firstName, lastName, policynumber) {
        this.policyNumber = new Policynumber(policynumber);
        // Tracker.log('EsigService - getEnvelops() with policy number: ', policynumber, this.policyNumber );
        var params = new HttpParams()
            .set('firstName', firstName)
            .set('lastName', lastName)
            .set('policySym', this.policyNumber.symbol)
            .set('policyNum', this.policyNumber.policy)
            .set('policyMod', this.policyNumber.module);
        return this.appService.get(this.globlalConstants.ESIG_GETENVELOPES, params);
    };
    EsigService.prototype.createEnvelopeAndWaitForVendor = function (mco, policynumber, library) {
        this.policyNumber = new Policynumber(policynumber);
        // Tracker.log('EsigService - getEnvelops() with policy number: ', policynumber, this.policyNumber );
        this.phUser = this.storeService.getLoginInfoFromStore();
        this.createEnvelopeReq = new CreateEnvelopeReq();
        this.createEnvelopeReq.policycontext = library;
        this.createEnvelopeReq.mco = mco;
        this.createEnvelopeReq.symbol = this.policyNumber.symbol,
            this.createEnvelopeReq.policynum = this.policyNumber.policy;
        this.createEnvelopeReq.module = this.policyNumber.module;
        this.createEnvelopeReq.email = this.phUser.email;
        this.createEnvelopeReq.fullname = '';
        this.createEnvelopeReq.accesscode = '';
        this.createEnvelopeReq.srcsystem = this.globlalConstants.TFC_SRC_SYSTEM;
        return this.appService.post(this.globlalConstants.ESIG_CREATEENVELOPEANDWAITFORVENDOR, this.createEnvelopeReq);
    };
    // keerthana starts for MI project
    EsigService.prototype.getEsignEligibility = function (getEligibilityreq) {
        return this.appService.post(this.globlalConstants.ESIG_GET_ELIGIBLE_STATUS, getEligibilityreq);
    };
    EsigService.prototype.createEmbeddedEnvelope = function (mco, policynumber, library, hasRenewal, docSeqNoArry, recipientsToSignReqArry) {
        var activityType = '';
        this.policyNumber = new Policynumber(policynumber);
        if (hasRenewal) {
            activityType = 'RB';
        }
        else {
            activityType = 'NB';
        }
        // Tracker.log('EsigService - getEnvelops() with policy number: ', policynumber, this.policyNumber );
        //  this.phUser = this.storeService.getLoginInfoFromStore();
        this.createEmbeddedEnvelopeReq = new CreateEmbeddedEnvelopeReq();
        this.createEmbeddedEnvelopeReq.polContext = library;
        this.createEmbeddedEnvelopeReq.polMco = mco;
        this.createEmbeddedEnvelopeReq.polSym = this.policyNumber.symbol,
            this.createEmbeddedEnvelopeReq.polNum = this.policyNumber.policy;
        this.createEmbeddedEnvelopeReq.policyMod = this.policyNumber.module;
        this.createEmbeddedEnvelopeReq.sourceSystem = this.globlalConstants.APPID;
        this.createEmbeddedEnvelopeReq.documentSeqNums = docSeqNoArry;
        this.createEmbeddedEnvelopeReq.activityType = activityType;
        this.createEmbeddedEnvelopeReq.recipientsToSignRequests = recipientsToSignReqArry;
        return this.appService.post(this.globlalConstants.ESIG_CREATEEMBEDDEDENVELOPE, this.createEmbeddedEnvelopeReq);
    };
    EsigService.ngInjectableDef = i0.defineInjectable({ factory: function EsigService_Factory() { return new EsigService(i0.inject(i1.BwappService), i0.inject(i2.BwstoreService), i0.inject(i3.BWErrorhandler)); }, token: EsigService, providedIn: "root" });
    return EsigService;
}());
export { EsigService };
