
export const environment = {
  production: false,
  rememberMeId: 'bwcomuser',

  symbolLibrary: 'TSL50LIB00',
  pointContext: 'TSL50LIBWS',
  endorseContext: 'TSL50LIBWR',
  apiServiceUrl: 'https://qarating.bristolwest.com/PolicyHolderREST/',
  docusignResponseUrl: 'https://newqa.bristolwest.com/esigmessages',
  docusignRegistrationResponseUrl: 'https://newqa.bristolwest.com/register/register-result',
  enableLogger: true,
  ccpaDontSell: 'https://cssts.farmers.com/privacy-statement/#donotsell',
  ccpaCookieBanner: 'https://cssts.farmers.com/privacy-statement/#personaluse',
  ccpaPrivacyCenter: 'https://cssts.farmers.com/privacy-center',
  loggerUrl: 'https://newqa.bristolwest.com/log/',
  google_UAID: 'UA-130171213-1',
  google_AWID: 'AW-XXXXX',
  google_AW_CONVERSION: 'AW-XXXXX/XXXXXX',
  removePhAdminPassword: 'password2',
  
    // View Claim
    efnolActionURLViewClaims: 'https://uat4-farmers.cs16.force.com/cmp/s/login/?SO=04',
    efnolActionURLViewClaimSSO: 'https://uat4-farmers.cs16.force.com/cmp/login?so=00Df0000001oH5e',
    efnolViewRelayStateURL: 'https://uat4-farmers.cs16.force.com/bwcmp/s/?SO=10',
    //current and incident 
    efnolActionURLViewClaimsCurrent:'https://uat3-farmers.cs14.force.com/bwcmp/s/login/?SO=04',
    //others
    efnolActionURLViewClaimsOther:'https://uat3-farmers.cs14.force.com/bwcmp/s/login/?=33&source=cmp',
    //SomeOne else
    efnolActionURLViewClaimsSomeOne:'https://uat3-farmers.cs14.force.com/bwcmp/s/findrep?SO=33',
    // Report Claim
    efnolActionURLReportClaim: 'https://uat3-farmers.cs14.force.com/bwcmp/s/filealoss?SO=04',
    efnolReportRelayStateURL: 'https://uat4-farmers.cs16.force.com/bwcmp/s/filealoss?SO=10',
    efnolActionURLReportClaimSSO: 'https://uat4-farmers.cs16.force.com/eFNOL/login?so=00Df0000001oH5e',
    //current & some one 
    efnolActionURLReportClaimCurrent: 'https://uat3-farmers.cs14.force.com/bwcmp/s/filealoss?SO=04',
    //other
    efnolActionURLReportClaimOther: 'https://uat3-farmers.cs14.force.com/bwcmp/s/login/?=33&source=efnol',

  cancelPolicyRelayStateURL: 'http://www5.bwproducers.com/m.bwc/Default.aspx',
  uploadDocFileSize: 12, // MB
  idletime: 3600,
  timeout: 600,
  tealeafEndpoint: '/logger/log/tealeaf',

  // Chat - UAT region
  srmChatWindowURL: 'http://souat1-souat1-farmersinsuranceliveagent.cs9.force.com/SRM_BWPreChatCust?tmp=',
  // Direct Sales
  showQuickQuote: 'Y',
  bdqRetrieveQuoteCenter: 'https://cssta.farmers.com/bristolwest/ffq/ffq3.jsf',

  // Medical Provider claim support
  medicalProviderClaimURL: 'http://claimsupportqa.farmers.com/eClaims/ClaimantSelfService/medicalProviderLoginAndRegistration.action',
  // paymentUS iframe
  paymentUsIframeURL: 'https://frms-sit-319.paymentus.io/rotp/frms?iframe=yes&transSourceSystem=BWU&brand=BWU',
  //paymentUsIframeDomainUrl: 'https://frms-sit-319.paymentus.io/xotp/pm/frms?authToken=',
  paymentUsIframeDomainUrl: 'https://secure2.paymentus.com/xotp/pm/frms?authToken=',
  cesSurveyUrl:'https://farmers.qualtrics.com/jfe/form/SV_71IJejUTwV5dTcq', 
  securityApiEndpoint : 'https://css-api-bw-security-fm-emd-css-bw-qa.apps.rosanp.farmers.com/css-api-security/v1/',
  bwOktaEnabled: true,
  oktaRedirectUriForSSO : 'https://newqa2.bristolwest.com/sso',
  
}; 
