import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GlobalConstants } from '../../../constants/global.constant';
import { Docusignres } from '../../../shared/model/api/esig/docusignres';
import { BwstoreService } from '../../../shared/services/bwstore.service';
import { CommonUtil } from '../../../shared/utils/commonutil';
import { BwappService } from '../../bwapp.service';

@Injectable({
  providedIn: 'root'
})
export class DocusignService {

  private globlalConstants = GlobalConstants;
  docusignResponseUrl: string;

  constructor(private appService: BwappService, private storeService: BwstoreService) {
    if (!CommonUtil.isEmpty(this.storeService.getCurrentregistrationState())) {
      // Coming from Registration
      this.docusignResponseUrl = `${environment.docusignRegistrationResponseUrl}`;
    } else {
      // Coming from Esig Messages
      this.docusignResponseUrl = `${environment.docusignResponseUrl}`;
    }
  }

  // ceremonyReq.OnCancelUrl = String.Format('{0}?{1}={2}', ConfigurationManager.AppSettings['eSigMessage_URL_Cancel'],
// eSigAction', HttpUtility.UrlEncode(Utilities.EncryptData(String.Format('{0};{1};{2}', 'Cancelled', envID, recID))));
/*
1. eSigMessage_URL_Cancel + '?' {0}
2. 'eSigAction'                 {1}
3. 'Cancelled,Declined,Exception,IDCheckFailed,SessionTimedOut,Complete,TTLExpired,ViewingComplete'  -- Encrypted then UrlEncoded
4. envID        -- Encrypted then UrlEncoded
5. recID        -- Encrypted then UrlEncoded

*/

  getCeremonyURL(envId: string, recId: string): Observable<Docusignres> {
    const params = new HttpParams()
    .set('envelopeID', envId)
    .set('onCancelUrl', this.buildDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_CANCELLED, envId, recId))
    .set('onDeclineUrl', this.buildDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_DECLINED, envId, recId))
    .set('onExceptionUrl', this.buildDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_EXCEPTION, envId, recId))
    .set('onIdCheckFailedUrl', this.buildDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_IDCHECK, envId, recId))
    .set('onSessionTimeOutUrl', this.buildDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_SESSION_TIMEOUT, envId, recId))
    .set('onSigningCompleteUrl', this.buildDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_COMPLETE, envId, recId))
    .set('onTTLExpiredUrl', this.buildDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_TTL_EXPIRED, envId, recId))
    .set('onViewingCompleteUrl', this.buildDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_VIEWING, envId, recId))
    .set('recipientID', recId);

    return this.appService.get(this.globlalConstants.ESIG_GETCEREMONY_URL, params);
  }

  getCeremonyURLEncrypted(encrptyedValues: string): Observable<Docusignres> {
    const params = new HttpParams()
    .set('encryptedIds', encrptyedValues)
    .set('onCancelUrl', this.buildEncryptedDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_CANCELLED))
    .set('onDeclineUrl', this.buildEncryptedDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_DECLINED))
    .set('onExceptionUrl', this.buildEncryptedDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_EXCEPTION))
    .set('onIdCheckFailedUrl', this.buildEncryptedDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_IDCHECK))
    .set('onSessionTimeOutUrl', this.buildEncryptedDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_SESSION_TIMEOUT))
    .set('onSigningCompleteUrl', this.buildEncryptedDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_COMPLETE))
    .set('onTTLExpiredUrl', this.buildEncryptedDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_TTL_EXPIRED))
    .set('onViewingCompleteUrl', this.buildEncryptedDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_VIEWING));

    return this.appService.get(this.globlalConstants.ESIG_GETCEREMONY_URL, params);
  }

  buildDocusignResponseURL(actionType: string, envId: string, recId: string) {
    return this.docusignResponseUrl.concat(
      this.globlalConstants.DOCUSIGN_PARAMETER).concat(actionType).concat('?p=' + envId + recId); // envId.concat(recId));
  }

  buildEncryptedDocusignResponseURL(actionType: string) {
    return this.docusignResponseUrl.concat(
      this.globlalConstants.DOCUSIGN_PARAMETER);
  }
}

