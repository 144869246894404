import { NewUser } from '../../shared/model/newuser.model';
import * as NewUserActions from '../actions/custregistration.actions';

const defaultState: NewUser = {
    step: 1,
    phlastname: '',
    phfirstname: '',
    phzipcode: '',
    phdob: '',
    policynumber: '',
    userid: '',
    confirmedUserId: '',
    password: '',
    confirmedPassword: '',
    secq1: '',
    secans1: '',
    secq2: '',
    secans2: '',
    secq3: '',
    secans3: '',
    mco: '',
    producercode: '',
    esigstatus: false,
    envelopId: '',
    recipientId: '',
    goPaperlessInd: '',
    stateCd: '',
    hasRenewal: false,
    stateSpecificHasEnvelope: false
};

const removeUserState: NewUser = {
    step: 1,
    phlastname: '',
    phfirstname: '',
    phzipcode: '',
    phdob: '',
    policynumber: '',
    userid: '',
    confirmedUserId: '',
    password: '',
    confirmedPassword: '',
    secq1: '',
    secans1: '',
    secq2: '',
    secans2: '',
    secq3: '',
    secans3: '',
    mco: '',
    producercode: '',
    esigstatus: false,
    envelopId: '',
    recipientId: '',
    goPaperlessInd: '',
    stateCd: '',
    hasRenewal: false,
    stateSpecificHasEnvelope: false,
};

// Helper to create new state object as the state is immutable
const newState = (state, newDataObj) => {
    return Object.assign({}, state, newDataObj);
};

export function CustRegReducer(state: NewUser = defaultState, action: NewUserActions.Actions) {

    switch (action.type) {
        case NewUserActions.ADD_USER:
            return [state, action.payload];
        case NewUserActions.UPDATE_USER:
            return newState(state, action.payload);
        case NewUserActions.REMOVE_USER:
            return newState(state, removeUserState);
        default:
            return state;
    }
}

