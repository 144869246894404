import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { GlobalConstants } from '../../constants/global.constant';
import { BwappService } from '../../services/bwapp.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/bwapp.service";
import * as i2 from "@angular/common/http";
var ChatService = /** @class */ (function () {
    function ChatService(bwappService, _http) {
        this.bwappService = bwappService;
        this._http = _http;
        this.globalConstants = GlobalConstants;
    }
    ChatService.prototype.generateChatToken = function () {
        var params = new HttpParams();
        return this.bwappService.post(this.globalConstants.CHAT_GENERATE_TOKEN, params);
    };
    ChatService.prototype.postChartParams = function (chatParamReq, chatTokenRes) {
        var pshttpOptions = {
            headers: new HttpHeaders({
                'Authorization': chatTokenRes.token_type.concat(' ').concat(chatTokenRes.access_token)
            })
        };
        return this.bwappService.post(this.globalConstants.CHAT_GENERATE_PARAM_RECORD, chatParamReq);
    };
    ChatService.ngInjectableDef = i0.defineInjectable({ factory: function ChatService_Factory() { return new ChatService(i0.inject(i1.BwappService), i0.inject(i2.HttpClient)); }, token: ChatService, providedIn: "root" });
    return ChatService;
}());
export { ChatService };
