import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MyprofileService } from '../../../services/myprofile/myprofile.service';
import { PolicyHolder } from '../../model/authentication/authenticatephres';
import { PolicyInfo, FullPolicyDetails, AgentInfo } from '../../model/api/bwibic/fullpolicydetails.model';
import { BwstoreService } from '../../services/bwstore.service';
import { Router } from '@angular/router';
import { CommonUtil } from '../../utils/commonutil';
import { BWErrorhandler } from '../../../services/errorhandler';
import { BWError } from '../../model/error.model';
import { Tracker } from '../../utils/tracker';
import { MessageConstants } from '../../../constants/message.constant';
import { UsmsecurityService } from '../../../services/api/usmsecurity/usmsecurity.service';
import { Authenticatephreq } from '../../model/authentication/authenticatephreq';
import { GlobalConstants } from '../../../constants/global.constant';

@Component({
  selector: 'app-confirm-password',
  template: `
  <div class="modal-header">
  <h4 class="modal-title">Enter Current Password</h4>
  <button type="button" class="close" aria-label="Close" (click)="errorModal.dismiss('Cross click')">
  <span aria-hidden="true">&times;</span></button>
</div>
<form class="my-4" [formGroup]="passwordForm">
<div class="d-flex justify-content-center border-0">

  <app-app-errors *ngIf="this.errorFlag"></app-app-errors>

</div>
<div class="modal-body">
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        Please enter your current password to make any changes to your profile.
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
        <div class="form-group">
                  <div class="input-group">
                    <input [type]="showPassword ? 'text' : 'password'" placeholder="Enter your password" class="form-control"
                      formControlName="password" aria-label="Enter your password">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" type="button" (click)="togglePassword()">
                        <span *ngIf="showPassword === true"><i class="far fa-eye-slash"></i></span>
                        <span *ngIf="showPassword === false"><i class="far fa-eye"></i></span>
                      </button>
                    </div>
                  </div>
                  <app-field-error-display [displayError]="isFieldValid('password')"
                  [errorMsg]="usermessage.REQUIRED_PASSWORD"></app-field-error-display>
                </div>
      </div>
  </div>
</div>
<div class="modal-footer">
<button class="btn btn-secondary" (click)="errorModal.dismiss('Close click')">
Cancel
</button>
  <button *ngIf="loaded" type="submit" class="btn btn-primary"
  (click)='onSubmit(this.passwordForm.value)' data-dismiss="modal">Submit</button>
  <button *ngIf="!loaded" class="btn btn-primary" disabled="true"><i class="fas fa-circle-notch fa-spin">
  </i>&nbsp;Submit</button>
 
</div>
</form>
  `,
  styles: []
})

export class ConfirmPasswordComponent implements OnInit {
  private authenticatedPhDetails: PolicyHolder;
  returnUnenrollMsg: string;
  basicPolicyDetails: FullPolicyDetails;
  policyInfo: PolicyInfo;
  unenrollMsg: string;
  loaded = true;
  showPassword: boolean;
  passwordForm: FormGroup;
  formSubmitAttempt: boolean;
  usermessage = MessageConstants;
  @Output() emitService = new EventEmitter();
  errorFlag = false;
  constructor(public errorModal: NgbActiveModal, private _storeService: BwstoreService, private myProfileService: MyprofileService,
    private errorHandler: BWErrorhandler, private router: Router, private fb: FormBuilder,
    private usmSecurityService: UsmsecurityService) {
      this.showPassword = false;
      this.createConfirmPasswordFormGroup();
  }

  ngOnInit() {
    this.authenticatedPhDetails = this._storeService.getLoginInfoFromStore();
  }

  createConfirmPasswordFormGroup() {
    this.passwordForm = this.fb.group({
      password: ['', Validators.compose([Validators.required])],
    });
  }

  onSubmit(formData: any) {
    if (this.passwordForm.valid) {
      this.showPassword = false;
      this.formSubmitAttempt = true;
      this.loaded = false;
      const authPHRequest = new Authenticatephreq();
      authPHRequest.appID = GlobalConstants.APPID;
      authPHRequest.userName = this.authenticatedPhDetails.email;
      authPHRequest.password = formData.password;
      this.usmSecurityService.authenticatePH(authPHRequest).
          subscribe(
            data => {
              Tracker.loginfo('ConfirmPasswordComponent', 'onSubmit', '',
              'Response from authenticatePH()' + data.authenticatePolicyHolderResult);
              this.loaded = true;
              if (data.authenticatePolicyHolderResult === true) {
                this.errorFlag = false;
                this.emitService.emit({passwordValue: formData.password, authResponse: 'success'});
                this.errorModal.close();
              } else {
                this.errorFlag = true;
                this.emitService.next({passwordValue: formData.password, authResponse: 'failure'});
                this.errorHandler.handleApplicationError(new BWError(
                  {'errorcode': '100', 'errordesc': data.errorMsg, 'source': 'USMSecurityService'}));
              }
            },
            (error) => {
              this.errorFlag = true;
              this.loaded = true;
              this.errorHandler.handleApplicationError(new BWError(
                {'errorcode': error.error.code, 'errordesc': MessageConstants.INVALID_CURRENT_PASSWORD, 'source': 'USMSecurityService'}));
            }
          );
    }
  }

  // Form Validation Utilities
  isFieldValid(field: string) {
    return (
      (!this.passwordForm.get(field).valid && this.passwordForm.get(field).touched) ||
      (this.passwordForm.get(field).untouched &&
        this.passwordForm.get(field).value === '' &&
        this.formSubmitAttempt)
    );
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }
}
