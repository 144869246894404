/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./covid-msg.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../newyork-covid-msg/newyork-covid-msg.component.ngfactory";
import * as i3 from "../newyork-covid-msg/newyork-covid-msg.component";
import * as i4 from "../nj-covid-msg/nj-covid-msg.component.ngfactory";
import * as i5 from "../nj-covid-msg/nj-covid-msg.component";
import * as i6 from "../ca-covid-msg/ca-covid-msg.component.ngfactory";
import * as i7 from "../ca-covid-msg/ca-covid-msg.component";
import * as i8 from "@angular/common";
import * as i9 from "./covid-msg.component";
import * as i10 from "../../shared/services/bwstore.service";
var styles_CovidMsgComponent = [i0.styles];
var RenderType_CovidMsgComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CovidMsgComponent, data: {} });
export { RenderType_CovidMsgComponent as RenderType_CovidMsgComponent };
function View_CovidMsgComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-newyork-covid-msg", [], null, null, null, i2.View_NewyorkCovidMsgComponent_0, i2.RenderType_NewyorkCovidMsgComponent)), i1.ɵdid(2, 114688, null, 0, i3.NewyorkCovidMsgComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_CovidMsgComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-nj-covid-msg", [], null, null, null, i4.View_NjCovidMsgComponent_0, i4.RenderType_NjCovidMsgComponent)), i1.ɵdid(2, 114688, null, 0, i5.NjCovidMsgComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_CovidMsgComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-ca-covid-msg", [], null, null, null, i6.View_CaCovidMsgComponent_0, i6.RenderType_CaCovidMsgComponent)), i1.ɵdid(2, 114688, null, 0, i7.CaCovidMsgComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_CovidMsgComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CovidMsgComponent_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CovidMsgComponent_2)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CovidMsgComponent_3)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.policyInfo.PolicyState === "NY"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.policyInfo.PolicyState === "NJ"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.policyInfo.PolicyState === "CA"); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_CovidMsgComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-covid-msg", [], null, null, null, View_CovidMsgComponent_0, RenderType_CovidMsgComponent)), i1.ɵdid(1, 114688, null, 0, i9.CovidMsgComponent, [i10.BwstoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CovidMsgComponentNgFactory = i1.ɵccf("app-covid-msg", i9.CovidMsgComponent, View_CovidMsgComponent_Host_0, {}, {}, []);
export { CovidMsgComponentNgFactory as CovidMsgComponentNgFactory };
