import { HttpParams } from '@angular/common/http';
import { GlobalConstants } from '../../../constants/global.constant';
import { BwstoreService } from '../../../shared/services/bwstore.service';
import { BwappService } from '../../bwapp.service';
import * as i0 from "@angular/core";
import * as i1 from "../../bwapp.service";
import * as i2 from "../../../shared/services/bwstore.service";
var BwibicService = /** @class */ (function () {
    function BwibicService(appService, storeService) {
        this.appService = appService;
        this.storeService = storeService;
        this.globlalConstants = GlobalConstants;
    }
    BwibicService.prototype.getAllPolicyInfo = function (strMCO, strPolicySym, strPolicyNum, strPolicyMod) {
        // Tracker.log('globlalConstants: getAllPolicyInfo() is inkovked for policy:: ', strPolicySym, strPolicyNum, strPolicyMod, strMCO);
        var params = new HttpParams()
            .set('strMCO', strMCO)
            .set('strPolicySym', strPolicySym)
            .set('strPolicyNum', strPolicyNum)
            .set('strPolicyMod', strPolicyMod);
        return this.appService.get(this.globlalConstants.BWIBIC_GETALLPOLICYINFO, params);
    };
    BwibicService.ngInjectableDef = i0.defineInjectable({ factory: function BwibicService_Factory() { return new BwibicService(i0.inject(i1.BwappService), i0.inject(i2.BwstoreService)); }, token: BwibicService, providedIn: "root" });
    return BwibicService;
}());
export { BwibicService };
