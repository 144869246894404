export class Websessionlogreq {

    state: string;
    mco: string;
    symbol: string;
    policyNumber: string;
    module: string;
    userID: string;
    appName: string;
    taskType: string;
    statusCode: string;
    regStatus: string;
    eSigStatus: string;
    miscellaneousUse1: string;

    constructor(values: Object = {}) {
      Object.assign(this, values);
    }
  }
