<main id="main" class="main">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <app-app-errors></app-app-errors>
        <div class="card">
          <div class="card-body">
            <p class="pt-2 pl-1 text-center">{{message}}</p>

            <p>&nbsp;</p>
            <p class="d-flex justify-content-center">
              <span *ngIf="continueAction === 'LOGIN'">
                <a routerLink="/" routerLinkActive="active" class="btn btn-primary btn-lg">Continue</a>
              </span>
              <span *ngIf="continueAction === 'REGISTER' && landingsrc === 'WELCOME'">
                <a [routerLink]="['/register-one']" [queryParams]="{ policyNumber: policynumber, LastName: lastname, mco: mco}" routerLinkActive="active"  class="btn btn-primary btn-lg">Continue</a>
              </span>
              <span *ngIf="continueAction === 'REGISTER' && landingsrc !== 'WELCOME'">
                <a [routerLink]="['/register-one']" [queryParams]="{ policyNumber: policynumber, mco: mco}" routerLinkActive="active"  class="btn btn-primary btn-lg">Continue</a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
