var Requesttocancelchecksres = /** @class */ (function () {
    function Requesttocancelchecksres(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return Requesttocancelchecksres;
}());
export { Requesttocancelchecksres };
var RequestToCancelInfo = /** @class */ (function () {
    function RequestToCancelInfo(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return RequestToCancelInfo;
}());
export { RequestToCancelInfo };
