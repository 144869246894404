export class CheckEachAnswerRequest {
    userName: string;
    answer: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}

export class CheckEachAnswerResponse {
    responseCode: string;
    responseText: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
