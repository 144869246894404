import { TitleCasePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import fontawesome from '@fortawesome/fontawesome';
import { faAddressCard, faCreditCard } from '@fortawesome/fontawesome-free-regular';
import { faClipboardList } from '@fortawesome/fontawesome-free-solid';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { StoreModule } from '@ngrx/store';

// Components
import { AppComponent, SafePipe } from './app.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { HomeComponent } from './components/home/home.component';
import { AchAgreementComponent } from './components/payment/ach-agreement/ach-agreement.component';
import { PaymentSuccessComponent } from './components/payment/payment-success/payment-success.component';
import { PaymentComponent } from './components/payment/payment.component';
import { UploaddocsComponent } from './components/uploaddocs/uploaddocs.component';
// Modules
import { SharedModule } from './modules/shared/shared.module';
// Routes
import { AppRoutingModule } from './routes/app.routing';
import { FindAgentsService } from './services/api/find-agents/find-agents.service';
// Services
import { BwappService } from './services/bwapp.service';
import { BWErrorhandler } from './services/errorhandler';
import { CustomerRegistrationService } from './services/registration/customer-registration.service';
import { BwLandingComponent } from './shared/components/bw-landing/bw-landing.component';
import { EsiglandingComponent } from './shared/components/esiglanding/esiglanding.component';
import { EsigmessagesComponent } from './shared/components/esigmessages/esigmessages.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { HelpfulLinksComponent } from './shared/components/helpful-links/helpful-links.component';
import { LoginGpTermsComponent } from './shared/components/login-gp-terms/login-gp-terms.component';
import { MobileNumberInputComponent } from './shared/components/mobile-number-input/mobile-number-input.component';
import { ConfirmPasswordComponent } from './shared/components/modals/confirm-password.component';
import { EmailExistsComponent } from './shared/components/modals/email-exists.component';
import { ErrorRedirectsComponent } from './shared/components/modals/error-redirects.component';
import { ForgotUseridComponent } from './shared/components/modals/forgot-userid.component';
import { GooglemapComponent } from './shared/components/modals/googlemap/googlemap.component';
import { IdcardComponent } from './shared/components/modals/idcard.component';
import { RequestcancelPendingEndorsementComponent } from './shared/components/modals/requestcancel-pending-endorsement.component';
import { SessionTimeoutComponent } from './shared/components/modals/session-timeout/session-timeout.component';
import { UnEnrollGoPaperlessComponent } from './shared/components/modals/unenroll-gopaperless.component';
import { PagenotfoundComponent } from './shared/components/pagenotfound/pagenotfound.component';
// Guards
import { AuthGuard } from './shared/guards/auth.guard';
import { RedirectGuard } from './shared/guards/redirect.guard';
// Interceptors
import { bwHttpInterceptorProviders } from './shared/interceptors';
import { AuthenticationService } from './shared/services/authentication.service';
import { BwstoreService } from './shared/services/bwstore.service';
import { MessagesService } from './shared/services/messages.service';
import { SecurityqnsService } from './shared/services/securityqns.service';
import { BwLoginReducer } from './store/reducers/bwlogin.reducer';
import { BwPolicyInfoReduer } from './store/reducers/bwpolicy.reducer';
import { BwpolicycancelReducer } from './store/reducers/bwpolicycancel.reducer';
// Reducers
import { CustRegReducer } from './store/reducers/custregistration.reducer';
import { LoginMfaComponent } from './shared/components/login-mfa/login-mfa.component';
import { PrivacyOptOutComponent } from './shared/components/modals/privacy-opt-out/privacy-opt-out.component';
import { AddressEditComponent } from './components/address-edit/address-edit.component';
import { PhoneEditComponent } from './components/phone-edit/phone-edit.component';
import { ConfirmChangesComponent } from './shared/components/modals/confirm-changes.component';
import { MailingaddrChangeComponent } from './components/address-edit/mailingaddr-change/mailingaddr-change.component';
import { MailingaddrAcceptComponent } from './components/address-edit/mailingaddr-accept/mailingaddr-accept.component';
import { MailingaddrConfirmationComponent } from './components/address-edit/mailingaddr-confirmation/mailingaddr-confirmation.component';
import { DirectsalesComponent } from './shared/components/directsales/directsales.component';
import { DirectsaleshomeComponent } from './components/directsaleshome/directsaleshome.component';
import { SorryComponent } from './components/sorry/sorry.component';
import { DirectsalescallComponent } from './components/directsalescall/directsalescall.component';
import { BwCoveragesReduer } from './store/reducers/bwcoverages.reducer';
import { CancelChangeReqComponent } from './shared/components/modals/cancel-change-req/cancel-change-req.component';
import { BwEndorsementReducer } from './store/reducers/bwendorsement.reducer';
import { MobileAppBannerComponent } from './shared/components/mobile-app-banner/mobile-app-banner.component';
import { NewyorkCovidMsgComponent } from './components/newyork-covid-msg/newyork-covid-msg.component';
import { CustomerReviewsComponent } from './components/customer-reviews/customer-reviews.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { GoogleSEOService } from './shared/services/googleseo.service';
import { SsoLoginComponent } from './shared/components/sso-login/sso-login.component';
import { NjCovidMsgComponent } from './components/nj-covid-msg/nj-covid-msg.component';
import { CovidMsgComponent } from './components/covid-msg/covid-msg.component';
import { WithoutLoginComponent } from './shared/components/without-login/without-login.component';
import { LandingTabsComponent } from './shared/components/landing-tabs/landing-tabs.component';
import { PaymentIframeFrmsComponent } from './components/payment-iframe-frms/payment-iframe-frms.component';
import { VehicleSelectComponent } from './shared/components/modals/vehicle-select/vehicle-select.component';
import { CaCovidMsgComponent } from './components/ca-covid-msg/ca-covid-msg.component';
import { AutoInsurance101Component } from './components/static/auto-insurance101/auto-insurance101.component';
import { MaterialModule } from './components/material/material.module';
import { PaymentusPaymentMethodDialogComponent } from './components/payment/paymentus-payment-method-dialog/paymentus-payment-method-dialog.component';

import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { BillingDetailsComponent } from './components/payment/billing-details/billing-details.component';
import { PaymentAmountSectionComponent } from './components/payment/payment-amount-section/payment-amount-section.component';

// Fontawesome library
fontawesome.library.add(faCreditCard, faClipboardList, faAddressCard);

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname
  },
  palette: {
    popup: {
      background: '#0073cf'
    },
    button: {
      background: '#FFFFFF'
    }
  },
  theme: 'classic',
  type: 'opt-out',
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{messagelink}}{{compliance}}'
  },
  elements:{
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">
    <div class="row">
    <div class="d-only-desktop d-only-tablet">{{message}}</div>
      <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link" style="padding-top: 0px;" href="{{tosHref}}" target="_blank">{{tosLink}}</a>
    </div>
      </span>
    `,
  },
  content:{
    message: 'Bristol West is committed to your privacy and security. Learn more about our ',
    tosLink: 'Personal Information Use ',
    tosHref: 'https://www.farmers.com/privacy-statement',
  }
};

@NgModule({
  declarations: [
    // Components
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,

    // My Profile Components
    UnEnrollGoPaperlessComponent,

    // Payment Components
    PaymentComponent,
    PaymentSuccessComponent,
    AchAgreementComponent,

    // Login Components
    EsiglandingComponent,
    EsigmessagesComponent,
    LoginGpTermsComponent,

    // Application Management Components
    ErrorRedirectsComponent,
    SessionTimeoutComponent,
    PagenotfoundComponent,

    // Shared Components
    EmailExistsComponent,
    BwLandingComponent,
    WithoutLoginComponent,
    IdcardComponent,
    UploaddocsComponent,
    FeedbackComponent,
    ConfirmPasswordComponent,
    ForgotUseridComponent,

    HelpfulLinksComponent,
    GooglemapComponent,
    LoginMfaComponent,
    RequestcancelPendingEndorsementComponent,
    PrivacyOptOutComponent,
    AddressEditComponent,
    PhoneEditComponent,
    ConfirmChangesComponent,
    MailingaddrChangeComponent,
    MailingaddrAcceptComponent,
    MailingaddrConfirmationComponent,
    DirectsalesComponent,
    SafePipe,
    DirectsaleshomeComponent,
    SorryComponent,
    DirectsalescallComponent,
    CancelChangeReqComponent,



    // Mobile banner
    MobileAppBannerComponent,
    SsoLoginComponent,
    NewyorkCovidMsgComponent,
    CustomerReviewsComponent,
    NjCovidMsgComponent,
    CovidMsgComponent,

    AutoInsurance101Component,

    NewyorkCovidMsgComponent,

    LandingTabsComponent,

    PaymentIframeFrmsComponent,
    VehicleSelectComponent,
    CaCovidMsgComponent,
    BillingDetailsComponent,
    PaymentAmountSectionComponent,
    PaymentusPaymentMethodDialogComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    MaterialModule,
    BrowserAnimationsModule,
    NgIdleKeepaliveModule.forRoot(),
    StoreModule.forRoot({
      newUser: CustRegReducer,
      policyHolder: BwLoginReducer,
      bwPolicyDetails: BwPolicyInfoReduer,
      requestToCancelInfo: BwpolicycancelReducer,
      bwCoverages: BwCoveragesReduer,
      bwEndorsementInfo: BwEndorsementReducer
    }),
    NgxPaginationModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
    /* , Enable for Development only
    StoreDevtoolsModule.instrument({
      maxAge: 10 // number of states to retain
    }) */

  ],
  providers: [
    // MessageConstants,
    // Registering the AppServices
    BwappService,
    BwstoreService,
    AuthenticationService,
    CustomerRegistrationService,
    SecurityqnsService,
    FindAgentsService,
    MessagesService,
    // Registering the interceptors
    bwHttpInterceptorProviders,

    // Registering the Guards
    AuthGuard,
    RedirectGuard,

    // Registering Global Error Handler
    BWErrorhandler,
    TitleCasePipe,
    GoogleSEOService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    FeedbackComponent,
    ErrorRedirectsComponent,
    EmailExistsComponent,
    UnEnrollGoPaperlessComponent,
    IdcardComponent,
    ForgotUseridComponent,
    MobileNumberInputComponent,
    UploaddocsComponent,
    GooglemapComponent,
    SessionTimeoutComponent,
    ConfirmPasswordComponent,
    RequestcancelPendingEndorsementComponent,
    PrivacyOptOutComponent,
    AddressEditComponent,
    PhoneEditComponent,
    ConfirmChangesComponent,
    DirectsaleshomeComponent,
    CancelChangeReqComponent,
    VehicleSelectComponent,
    PaymentusPaymentMethodDialogComponent
  ]
})
export class AppModule {

}
