<h1 class="heading-3 m-0 pb-2 mb-2 border-bottom">Payment Amount</h1>

<div role="radiogroup" aria-label="Choose payment amount">
  <label id="group-payment-amount" aria-level="3" role="heading">
    Choose payment amount
  </label>

  <div
    class="d-flex justify-content-between align-items-center mb-1"
    *ngIf="!isReinstatementAvailable()"
  >
    <div class="d-flex align-items-center">
      <input
        type="radio"
        name="paymentDetailsGroup"
        id="paymentDue"
        required
        value="PD"
        ngModel
        [(ngModel)]="selectedAmountOption.type"
        (change)="onTypeChange($event.target.value)"
        #paymentDueRadio
      />
      <label for="paymentDue" class="m-0 ml-2"
        >Payment Due
        <span
          *ngIf="
            paymentAmountDetails.basicPolicyDetails.paymentInfo
              .minimumPayment &&
            paymentAmountDetails.basicPolicyDetails.paymentInfo
              .minimumPayment !== '0.00' &&
            paymentAmountDetails.basicPolicyDetails.paymentInfo
              .minimumPayment !== '0' &&  paymentAmountDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC !== status.cancelled
          "
          >{{
            getDueDate()
              | date: "MM/dd/yyyy"
          }}</span
        >
        <span
          *ngIf="
            paymentAmountDetails.basicPolicyDetails.paymentInfo
              .minimumPayment &&
            paymentAmountDetails.basicPolicyDetails.paymentInfo
              .minimumPayment !== '0.00' &&
            paymentAmountDetails.basicPolicyDetails.paymentInfo
              .minimumPayment !== '0' &&  paymentAmountDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC === status.cancelled
          "
          >{{
            getCancelledDueDate()
              | date: "MM/dd/yyyy"
          }}</span
        ></label
      >
    </div>
    <label for="paymentDue" class="m-0">{{
      paymentAmountDetails.basicPolicyDetails.paymentInfo.minimumPayment
        | currency
    }}</label>
  </div>
  <div
    class="d-flex justify-content-between align-items-center mb-1"
    *ngIf="isReinstatementAvailable()"
  >
    <div class="d-flex align-items-center">
      <input
        type="radio"
        name="paymentDetailsGroup"
        id="reinstatementAmount"
        required
        value="RA"
        ngModel
        [(ngModel)]="selectedAmountOption.type"
        (change)="onTypeChange($event.target.value)"
        #reinstatementAmountRadio
      />
      <label for="reinstatementAmount" class="m-0 ml-2">Reinstatement Amount</label>
    </div>
    <label for="reinstatementAmount" class="m-0">{{
      getReinstatementAmount() | currency 
    }}</label>
  </div>
  <div class="d-flex justify-content-between align-items-center mb-1" 
  *ngIf="!isReinstatementAvailable()">
    <div class="d-flex align-items-center">
      <input
        type="radio"
        name="paymentDetailsGroup"
        id="outstandingBalance"
        required
        value="TOB"
        ngModel
        [(ngModel)]="selectedAmountOption.type"
        (change)="onTypeChange($event.target.value)"
        #totalOutstandingBalanceRadio
      />
      <label for="outstandingBalance" class="m-0 ml-2">Total Outstanding Balance</label>
    </div>
    <label for="outstandingBalance" class="m--0">{{
      paymentAmountDetails.basicPolicyDetails.paymentInfo.maximumPayment
        | currency
    }}</label>
  </div>
  <div class="d-flex justify-content-between align-items-center mb-1" 
  *ngIf="isReinstatementAvailable()">
    <div class="d-flex align-items-center">
      <input
        type="radio"
        name="paymentDetailsGroup"
        id="totalOutstandingBalance"
        required
        value="TOB"
        ngModel
        [(ngModel)]="selectedAmountOption.type"
        (change)="onTypeChange($event.target.value)"
        #totalOutstandingBalanceRadio
      />
      <label for="totalOutstandingBalance" class="m-0 ml-2">Total Outstanding Balance</label>
    </div>
    <label for="totalOutstandingBalance" class="m--0">{{
      paymentAmountDetails.policyInfo.Balance
        | currency
    }}</label>
  </div>
  <!-- <div
    class="d-flex justify-content-between align-items-center mb-1"
    *ngIf="!isReinstatementAvailable()"
  >
    <div class="d-flex align-items-center">
      <input
        type="radio"
        name="paymentDetailsGroup"
        required
        value="MB"
        ngModel
        [(ngModel)]="selectedAmountOption.type"
        (change)="onTypeChange($event.target.value)"
        #minimumBalanceRadio
      />
      <label class="m-0 ml-2">Minimum Balance</label>
    </div>
    <label class="m-0">{{
      paymentAmountDetails.basicPolicyDetails.paymentInfo.minimumPayment
        | currency
    }}</label>
  </div> -->
  <div class="d-flex justify-content-between align-items-center mb-1"
    *ngIf="!isReinstatementAvailable()">
    <div class="d-flex align-items-center">
      <input
        id="oa"
        type="radio"
        name="paymentDetailsGroup"
        id="otherAmount"
        required
        value="OA"
        ngModel
        [(ngModel)]="selectedAmountOption.type"
        (change)="onTypeChange($event.target.value)"
        
        #otherAmountRadio
      />
      <label for="otherAmount" class="m-0 ml-2">Other Amount</label>
    </div>
    <label for="otherAmount" class="m-0"
      >$<input
        type="text"
        id="otherAmountInput"
        
        class="text-right ml-1"
        [(ngModel)]="otherAmount"
        #otherAmountInput
        (change)="validateNew(otherAmountInput.value)"
        (focus)="resetData()"
        [disabled]="inputFieldDisabled"
        style="width: 140px;"
        
    /></label>
  </div>
  <div align="right" *ngIf="
  paymentAmountDetails.basicPolicyDetails.paymentInfo.minimumPayment && 
  paymentAmountDetails.basicPolicyDetails.paymentInfo.maximumPayment &&
  !paymentAmountDetails.basicPolicyDetails.paymentInfo.minimumPayment.startsWith('-') &&
  !paymentAmountDetails.basicPolicyDetails.paymentInfo.maximumPayment.startsWith('-')

  
  ">
    <span
      *ngIf="inValidAmount && selectedType === 'OA'"
      style="font-size: smaller; color: red"
    >
      <!-- Other amount should be between
      <span
        
        >{{
          paymentAmountDetails.basicPolicyDetails.paymentInfo.minimumPayment
            | currency
        }}</span
      >
      to
      <span
        
        >{{
          paymentAmountDetails.basicPolicyDetails.paymentInfo.maximumPayment
            | currency
        }}</span -->
        {{finalMessage}}
      </span
    >
  </div>

  <div align="right" *ngIf="
  !paymentAmountDetails.basicPolicyDetails.paymentInfo.minimumPayment || 
  !paymentAmountDetails.basicPolicyDetails.paymentInfo.maximumPayment ||
  paymentAmountDetails.basicPolicyDetails.paymentInfo.minimumPayment.startsWith('-') ||
  paymentAmountDetails.basicPolicyDetails.paymentInfo.maximumPayment.startsWith('-')

  
  ">
    <span
      *ngIf="inValidAmount && selectedType === 'OA'"
      style="font-size: smaller; color: red"
    >
      Cannot select 'Other Amount'. Select other options.
    </span>
      
  </div>
</div>
