<header class="header">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <nav class="navbar navbar-expand-lg navbar-light">
          <a class="navbar-brand col-sm-3" routerLink="/" routerLinkActive="active">
            <img class="logo" src="assets/img/logo-bristol-west-new.svg" alt="Bristol West Logo"/>
          </a>

          <div class="bg-color-white collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }">
            <!-- X to close navbar collapsed menu -->
            <div class="d-lg-none close-x">Menu
              <!--<img tabindex="0" src="assets/img/icon-close.svg" alt="Close Icon" 
              class="float-right icon icon-svg icon-close d-inline-block d-md-none" (click)="toggleNavbar('policy')" />-->
              <span class="float-right pr-2 font-weight-bold" alt="Close Icon" (click)="toggleNavbar('policy')">X</span>
            </div>
            <!-- Navbar -->
            <ul class="navbar-nav mx-auto bg-color-white border-sm-1">
              <li *ngIf="isUserLoggedIn" class="nav-item">
                <a class="nav-link" routerLink="/policy" routerLinkActive="active" (click)="toggleNavbar('policy')">
                  My Policy <span class="sr-only">(current)</span>
                </a>
              </li>
              <li  *ngIf="this.directSalesPhone !== '' &&  this.directSalesPhone !== undefined && (this.routerUrl === '/quote' || this.routerUrl === '/directsalescall' || this.routerUrl === '/sorry' )" class="nav-item direct">
                <b style="font-size: 22px;">Call us for a quote: </b>
                <a href="{{'tel:'+this.directSalesPhone}}"><b style="font-size: 19px;"> <span style="animation: blinkingText 1s infinite !important;font: size 30px;">{{ this.directSalesPhone }}</span>
                </b></a>
                

            </li>
              <li class="nav-item text-sm-center">
                  <a class="nav-link" *ngIf="!isUserLoggedIn"  routerLink="/"
                    (click)="toggleNavbar('Home')">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" *ngIf="!isUserLoggedIn"  routerLink="/home/why-bristol" routerLinkActive="active"
                  (click)="toggleNavbar('why-bristol')">Why Bristol West</a>
              </li>

              <li class="nav-item">
                <a class="nav-link" routerLink="/auto-insurance101" routerLinkActive="active" 
                *ngIf="!isUserLoggedIn" (click)="toggleNavbar('autoInsurance101')">Auto Insurance 101</a>
              </li>

              <li class="nav-item">
                <a class="nav-link d-none" routerLink="/" routerLinkActive="active" (click)="toggleNavbar('getaQuote')">Get a Quote</a>
              </li>

              <li class="nav-item">
                <a class="nav-link" routerLink="/home/claims" (click)="toggleNavbar('claimsService')" routerLinkActive="active">Claims</a>
              </li>
              <li class="nav-item">
                <a class="nav-link d-none" routerLink="/home/about" routerLinkActive="active" (click)="toggleNavbar('aboutUs')">About Us</a>
              </li>
              <!-- <li class="nav-item">
                <a class="nav-link d-md-none" routerLink="/find-agent-broker" (click)="toggleNavbar('findAgent')">Find Agent or Broker</a>
              </li> -->
              <li *ngIf="!isUserLoggedIn" class="nav-item">
                <a class="nav-link " href="#loginheader" routerLink="/"  (click)="toggleNavbar('login')">Login</a>
                <a class="nav-link absolute-3 d-none" href="#" routerLink="/"  (click)="toggleNavbar('login')"><small>Login</small></a>
              </li>
              <li class="nav-item">
                <a class="nav-link start-quote-link" routerLink="/" 
                *ngIf="!isUserLoggedIn" (click)="toggleNavbar('startQuote');">Start Quote</a>
              </li>
              <li class="nav-item" *ngIf="isUserLoggedIn">
                <a class="nav-link" routerLink="/profile" routerLinkActive="active" (click)="toggleNavbar('profile')">My Profile</a>
              </li>
              <li *ngIf="isUserLoggedIn" class="nav-item">
                <a class="nav-link d-md-none" href="javascript:void(0)" (click)="logoutUser()" (click)="toggleNavbar('logout')">Logout</a>
              </li>
              <li class="nav-item d-none d-sm-none d-md-block" *ngIf="isUserLoggedIn && !isLogoutDisabled">
                <a class="nav-link" href="javascript:void(0)" (click)="logoutUser();toggleNavbar('logout')">Logout</a>
              </li>
            </ul>

            <section class="d-none mt-4 px-4 py-5 bg-color-white">
              <app-find-agent></app-find-agent>
            </section>
          </div>
          <button class="navbar-toggler ml-auto" type="button" (click)="toggleNavbar('toogle')">
            <span class="navbar-toggler-icon"></span>
          </button>
        </nav>
      </div>
    </div>
 </div>
</header>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header feedback-header">
        <h4 class="modal-title heading-2 mb-1">We Value Your Feedback!</h4>
        <!--<button  (click)="closeSurveyPopup()">X</button>-->
      </div>
      <div class="modal-body">
        <p>
          Thanks for visiting bristolwest.com.
          Please help us improve your experience on our site by taking a short survey.
        </p>
        <p>
          This survey is intended to get your feedback on this most recent visit.
        </p>
      </div>
      <div class="modal-footer">
        <button id="" type="button" class="btn btn-primary" (click)="openSurvey()">Yes, I'll Give Feedback</button><br>
        <button id="" type="button" class="btn no-thanks" (click)="closeSurveyPopup()">No, Thanks</button>
      </div>
    </div>
  </div>
</div>

