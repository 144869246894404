import { BwappService } from '../bwapp.service';
import { GlobalConstants } from '../../constants/global.constant';
import { GetEsignEligibilityReq } from '../../shared/model/api/esig/getesigneligibility.req.model';
import { EsigService } from '../api/esig/esig.service';
import { EdmrService } from '../api/edmr/edmr.service';
import { Tracker } from '../../shared/utils/tracker';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalyticsService } from '../../shared/services/analytics/google-analytics.service';
import { RecipientsToSignReq } from '../../shared/model/api/esig/recipientstosignreq.model';
import { BwstoreService } from '../../shared/services/bwstore.service';
import { StateWorkflow } from '../../shared/model/stateworkflow.model';
import { Envelope } from '../../shared/model/api/esig/envelop.model';
import { Recipient } from '../../shared/model/api/esig/recipient.model';
import * as i0 from "@angular/core";
import * as i1 from "../bwapp.service";
import * as i2 from "../api/esig/esig.service";
import * as i3 from "../api/edmr/edmr.service";
import * as i4 from "@angular/router";
import * as i5 from "../../shared/services/analytics/google-analytics.service";
import * as i6 from "../../shared/services/bwstore.service";
var StateworkflowService = /** @class */ (function () {
    // docSeqNoArray: string[] = [];
    // private recipientsToSignReqArry: Array<RecipientsToSignReq>;
    // recipientsToSignReq: RecipientsToSignReq;
    function StateworkflowService(bwappService, esigService, edmrService, router, route, googleAnalytics, _storeService) {
        this.bwappService = bwappService;
        this.esigService = esigService;
        this.edmrService = edmrService;
        this.router = router;
        this.route = route;
        this.googleAnalytics = googleAnalytics;
        this._storeService = _storeService;
        this.globlalConstants = GlobalConstants;
        this.getEligibilityreq = new GetEsignEligibilityReq();
        // Get the current state of NewUser
        this.newUser = this._storeService.getCurrentregistrationState();
    }
    // only for MI state
    StateworkflowService.prototype.stateMIWorkflow = function (mco, state, polSym, polNum, polMod, hasRenewal, library, firstName, lastName, flow, phEmail) {
        var _this = this;
        this.googleAnalytics.trackEvent(flow, 'MIStateLogic', 'stateMIWorkflow', 200);
        return new Promise(function (resolve, reject) {
            var stateWorkflowRes = new StateWorkflow();
            var docSeqNoArray = [];
            var recipientsToSignReqArray = [];
            var recipientsToSignReq = new RecipientsToSignReq();
            // let hasPendingEnvelops = false;
            _this.getEligibilityreq.mco = mco;
            _this.getEligibilityreq.policysymbol = polSym;
            _this.getEligibilityreq.policynum = polNum;
            _this.getEligibilityreq.policymod = polMod;
            _this.getEligibilityreq.firstName = firstName;
            _this.getEligibilityreq.lastName = lastName;
            _this.getEligibilityreq.policycontext = library;
            _this.getEligibilityreq.hasRenewal = hasRenewal;
            _this.getEligibilityreq.srcsystem = _this.globlalConstants.APPID;
            var policyNumber = polSym.concat(polNum).concat(polMod);
            _this.esigService.getEsignEligibility(_this.getEligibilityreq).subscribe(function (getEsignEligibilityRes) {
                Tracker.loginfo('Stateworkflow', 'getEsignEligibility()', 'getEsignEligibility service:' + flow, 'Response Payload' + JSON.stringify(getEsignEligibilityRes));
                if ((getEsignEligibilityRes.eligibilityIndicator === 'P' && hasRenewal) ||
                    (getEsignEligibilityRes.eligibilityIndicator === 'N' && !hasRenewal)) {
                    _this.esigService.getEnvelops(firstName, lastName, policyNumber)
                        .subscribe(function (envelopsres) {
                        Tracker.loginfo('Stateworkflow', 'getEnvelops()', 'getEnvelops service based on eligibilityIndicator:' + flow, 'Response Payload' + JSON.stringify(envelopsres));
                        if (envelopsres && envelopsres.status.toUpperCase() === 'SUCCESS'
                            && envelopsres.envelopes.length === 0) {
                            // } else { // when no pending evelopes are returned
                            if (hasRenewal) {
                                _this.docTransactionCd = '';
                                _this.srcSys = _this.globlalConstants.APPID;
                            }
                            else {
                                _this.docTransactionCd = _this.globlalConstants.EDMR_SIGNEDDOCUMENT_CD;
                                _this.srcSys = _this.globlalConstants.BWR_SYSTEM;
                            }
                            recipientsToSignReq.accessCode = '12345';
                            recipientsToSignReq.emailAddress = phEmail;
                            recipientsToSignReq.fullName = firstName + lastName;
                            recipientsToSignReq.id = '1';
                            recipientsToSignReq.recieptType = 'PrimaryNamedInsured';
                            recipientsToSignReqArray.push(recipientsToSignReq);
                            _this.edmrService.invokeEDMRDocuments(policyNumber, _this.docTransactionCd, _this.srcSys)
                                .subscribe(function (invokeDocsRes) {
                                Tracker.loginfo('Stateworkflow', 'invokeEDMRDocuments()', 'invokeEDMRDocuments service:' + flow, 'Response Payload' + JSON.stringify(invokeDocsRes));
                                docSeqNoArray[0] = invokeDocsRes.seqno;
                                if (invokeDocsRes && invokeDocsRes.responseStatus === '1' && invokeDocsRes.seqno !== '') {
                                    _this.esigService.createEmbeddedEnvelope(mco, policyNumber, library, hasRenewal, docSeqNoArray, recipientsToSignReqArray)
                                        .subscribe(function (data) {
                                        _this.createEmbedEnvRes = data;
                                        Tracker.loginfo('Stateworkflow', 'createEmbeddedEnvelope()', 'createEmbeddedEnvelope service when docseq no is NOT empty:' + flow, 'Response Payload:' + JSON.stringify(_this.createEmbedEnvRes));
                                        // Tracker.log('8. Invoked esigService.createEnvelopeAndWaitForVendor():::: ', createEnvRes);
                                        if (_this.createEmbedEnvRes && _this.createEmbedEnvRes.status === 1 &&
                                            _this.createEmbedEnvRes.envelopeID !== null) {
                                            _this.createEmbedEnvRes.createdEnvelope.recipients.forEach(function (env) {
                                                _this.newUser.envelopId = env.envelopeID;
                                                _this.newUser.recipientId = env.recipientID;
                                                // update receiptid enveId status in the session
                                                _this._storeService.updateRegistrationState(_this.newUser);
                                                stateWorkflowRes.envelopeCreated = true;
                                                stateWorkflowRes.envelopeID = _this.newUser.envelopId;
                                                stateWorkflowRes.recipientID = _this.newUser.recipientId;
                                                _this.createEnvelopeObj(_this.createEmbedEnvRes.createdEnvelope).then(function (createenvs) {
                                                    stateWorkflowRes.envelopes = createenvs;
                                                });
                                                resolve(stateWorkflowRes);
                                            });
                                        }
                                        else {
                                            stateWorkflowRes.envelopeCreated = false;
                                            resolve(stateWorkflowRes);
                                        }
                                    });
                                }
                                else if (invokeDocsRes && invokeDocsRes.responseStatus === '0' &&
                                    invokeDocsRes.errorMessageId === 'formservice.1010') {
                                    if (!hasRenewal) {
                                        _this.edmrService.invokeEDMRDocuments(policyNumber, _this.globlalConstants.EDMR_APPLICAIONPKG_CD, _this.srcSys)
                                            .subscribe(function (inDocsRes) {
                                            Tracker.loginfo('Stateworkflow', 'invokeEDMRDocuments()', 'invokeEDMRDocuments service for NB for errorCd:' + flow, 'Response Payload' + JSON.stringify(inDocsRes));
                                            if (inDocsRes && inDocsRes.responseStatus === '1' && inDocsRes.seqno !== '') {
                                                docSeqNoArray[0] = inDocsRes.seqno;
                                                _this.esigService.createEmbeddedEnvelope(mco, policyNumber, library, hasRenewal, docSeqNoArray, recipientsToSignReqArray)
                                                    .subscribe(function (data1) {
                                                    _this.createEmbedEnvResp = data1;
                                                    Tracker.loginfo('Stateworkflow', 'createEmbeddedEnvelope()', 'createEmbeddedEnvelope service for NB for errorCd:' + flow, 'Response Payload:' + JSON.stringify(_this.createEmbedEnvResp));
                                                    if (_this.createEmbedEnvResp && _this.createEmbedEnvResp.status === 1 &&
                                                        _this.createEmbedEnvResp.envelopeID !== null) {
                                                        _this.createEmbedEnvResp.createdEnvelope.recipients.forEach(function (env) {
                                                            _this.newUser.envelopId = env.envelopeID;
                                                            _this.newUser.recipientId = env.recipientID;
                                                            // update receiptid enveId status in the session
                                                            _this._storeService.updateRegistrationState(_this.newUser);
                                                            stateWorkflowRes.envelopeCreated = true;
                                                            stateWorkflowRes.envelopeID = _this.newUser.envelopId;
                                                            stateWorkflowRes.recipientID = _this.newUser.recipientId;
                                                            _this.createEnvelopeObj(_this.createEmbedEnvResp.createdEnvelope).then(function (createdenvs) {
                                                                stateWorkflowRes.envelopes = createdenvs;
                                                            });
                                                            resolve(stateWorkflowRes);
                                                        });
                                                    }
                                                    else {
                                                        stateWorkflowRes.envelopeCreated = false;
                                                        resolve(stateWorkflowRes);
                                                    }
                                                });
                                            }
                                            else {
                                                stateWorkflowRes.envelopeCreated = false;
                                                resolve(stateWorkflowRes);
                                            }
                                        }); // invokdeedmr call
                                    } // renewal check for edmr
                                }
                                else {
                                    stateWorkflowRes.envelopeCreated = false;
                                    resolve(stateWorkflowRes);
                                }
                            }); // invokdeedmr call
                            // }// when no pending enevlopes are in response from getEnvelope
                        }
                        else {
                            if (envelopsres && envelopsres.status.toUpperCase() === 'SUCCESS') {
                                envelopsres.envelopes.forEach(function (env) {
                                    env.recipients.forEach(function (rec) {
                                        if (rec.recipType === 'PrimaryNamedInsured') {
                                            if (rec.status === 'Completed' || rec.status === 'Declined' || rec.status === 'Signed') {
                                                stateWorkflowRes.envelopeCreated = false;
                                                resolve(stateWorkflowRes);
                                            }
                                            else {
                                                stateWorkflowRes.envelopeCreated = true;
                                                stateWorkflowRes.envelopeID = env.envelopeID;
                                                stateWorkflowRes.recipientID = rec.recipientID;
                                                stateWorkflowRes.envelopes = envelopsres.envelopes;
                                                _this.newUser.envelopId = env.envelopeID;
                                                _this.newUser.recipientId = rec.recipientID;
                                                _this._storeService.updateRegistrationState(_this.newUser);
                                                resolve(stateWorkflowRes);
                                            }
                                        }
                                    });
                                });
                            }
                        }
                    }); // getEnvelope
                }
                else {
                    stateWorkflowRes.envelopeCreated = false;
                    resolve(stateWorkflowRes);
                }
            }); // esignEligibility
        }); // stateWorkFlow promise
    }; // stateWorkFlow
    StateworkflowService.prototype.createEnvelopeObj = function (createEmEvnv) {
        return new Promise(function (resolve, reject) {
            var envs = [];
            var rcvs = [];
            var env = new Envelope();
            var rec = new Recipient();
            env.envelopeID = createEmEvnv.envelopeID.toString();
            env.expireDate = createEmEvnv.expireDate;
            createEmEvnv.recipients.forEach(function (recp) {
                rec.envelopeID = recp.envelopeID.toString();
                rec.recipType = 'PrimaryNamedInsured'; // recp.recipType;
                rec.recipientID = recp.recipientID.toString();
                rec.status = recp.statusDescription;
                rcvs.push(rec);
                env.recipients = rcvs;
                envs.push(env);
            });
            resolve(envs);
        });
    };
    StateworkflowService.ngInjectableDef = i0.defineInjectable({ factory: function StateworkflowService_Factory() { return new StateworkflowService(i0.inject(i1.BwappService), i0.inject(i2.EsigService), i0.inject(i3.EdmrService), i0.inject(i4.Router), i0.inject(i4.ActivatedRoute), i0.inject(i5.GoogleAnalyticsService), i0.inject(i6.BwstoreService)); }, token: StateworkflowService, providedIn: "root" });
    return StateworkflowService;
}()); // main class
export { StateworkflowService };
