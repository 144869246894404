<main class="main">
  <div class="container" *ngIf="!this.isPaymentusEnabled">
      <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/" routerLinkActive="active">Home</a></li>
            <li *ngIf="this.isPayment" class="breadcrumb-item active" aria-current="page">Make a Payment</li>
            <li *ngIf="!this.isPayment" class="breadcrumb-item active" aria-current="page">View ID Card</li>
          </ol>
        </nav>

    <div *ngIf="!this.isPageDataLoaded" class="small text-center"> {{ this.loadingMessage }} </div>
    <div *ngIf="!this.isPageDataLoaded" class="my-5 text-center">
      <app-bw-spinner [smallSpinner]="false"></app-bw-spinner>
    </div>

    <div *ngIf="this.isPageDataLoaded" class="row">
      <div class="col-md-12">
        <div class="d-flex justify-content-center border-0">
          <app-app-errors></app-app-errors>
        </div>

          <!-- Payment -->
          <div class="row d-flex justify-content-center">
            <div class="col-md-8 col-sm-12 text-center">
              <h1 *ngIf="this.isPayment" class="heading-3 mb-3">Make a Payment</h1>
              <h1 *ngIf="!this.isPayment" class="heading-3 mb-1">View ID Card</h1>
              <div *ngIf="!this.isPayment && !haveIDCard" class="mb-2">Get your Insurance ID Card</div>
              <div *ngIf="haveIDCard" class="mt-3">
                <div class="card">
                  <div class="card-body">
                    Your ID Card has been downloaded. Please click <button class="btn-link" (click)="showForm()">here</button> if your download did not start.
                  </div>
                </div>
              </div>
              <div *ngIf="!haveIDCard" class="card">
              <div class="card-body">
                <form class="mt-2 text-left col" [formGroup]="withoutloginForm">
                  <div class="form-group">
                    <div *ngIf="this.errorMsg !== ''" class="alert alert-danger alert-dismissible fade show" role="alert">
                        {{ this.errorMsg }}
                      </div>
                    <div class="row">
                      <label for="zip" class="col text-left titleText">Enter Policy Holder's Zip Code</label>
                    </div>
                    <div class="row mb-3">
                      <div class="col-sm-4 col-md-5 mb-2">
                        <input appNumbersonlyinput="true" type="text" maxlength="5" size="5" id="zip" name="zip" class="form-control form-control-sm" formControlName="zip" autocomplete="true"/>
                        <app-field-error-display [displayError]="isFieldValid('zip')" [errorMsg]="getErrMessage(withoutloginForm.controls['zip'].errors, 'zip')"></app-field-error-display>
                      </div>
                    </div>
                    <hr class="hr-text" data-content="AND">
                    <div class="row mt-1 mb-1">
                      <div class="col text-left titleText">Answer 2 of the 3 questions below</div>
                    </div>
                    <div *ngIf="this.twoOfThreeRequiredMessage !== ''" class="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                        {{ this.twoOfThreeRequiredMessage }}
                    </div>
                    <div class="row mb-3">
                      <div class="col-sm-10 col-md-10">
                        <label for="lastname">Policy Holder's Last Name</label>
                        <input id="lastname" name="lastname" class="form-control form-control-sm" formControlName="lastname"/>
                        <app-field-error-display [displayError]="isFieldValid('lastname')" [errorMsg]="getErrMessage(withoutloginForm.controls['lastname'].errors, 'lastname')"></app-field-error-display>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-sm-8 col-md-7 col-lg-6">
                        <label for="dob">Policy Holder's Date of Birth</label>
                        <div class="input-group input-group-sm">
                            <input id="dob" class="form-control form-control-sm" placeholder="MM/DD/YYYY" formControlName="dob" ngbDatepicker #d="ngbDatepicker" (blur)="d.close()">
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button" aria-label="Pick Date">
                                <i class="fas fa-calendar-alt" (click)="d.toggle()"></i>
                              </button>
                            </div>
                          </div>
                          <app-field-error-display [displayError]="isFieldValid('dob')" [errorMsg]="getErrMessage(withoutloginForm.controls['dob'].errors, 'dob')"></app-field-error-display>
                        </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-5 col-md-5">
                        <app-policy-number-input [formSubmitAttempt]="formSubmitAttempt" [form]="withoutloginForm" [validate]="false"></app-policy-number-input>
                      </div>
                    </div>
                    <div class="alert alert-privacy-banner row" role="alert" >
                        <a class="a-blue-link ml-2" href="{{cookieBannerUrl}}" target="_blank"
                            title="Personal Information Use">Personal Information Use</a>
                      </div>
                    <div class="row text-center">
                      <div class="col-12">
                        <button type="button" class="btn btn-lg btn-primary mt-3" (click)="onSubmit(withoutloginForm.value)">
                          <span id="payment-button-amount">Continue</span>
                        </button>
                      </div>   
                          <!-- <button type="button" class="btn btn-lg btn-secondary mt-2" routerLink="/" routerLinkActive="active" >Cancel</button> -->
                    </div>

                  </div>
                </form>
              </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
