import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appMobileNumberinput]'
})
export class MobilenumberinputDirective {

  private el: HTMLInputElement;
  private MOBILE_SEPARATOR = '-';

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
 }

  firstNumRegex = '^[0-9]{3}$';
  secondNumRegex = '^[0-9]{3}$';
  thirdNumRegex = '^[0-9]{4}$';

  @HostListener('keyup', ['$event']) onKeyUp(event) {
    const e = <KeyboardEvent> event;

    if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1) {
      return;
    }

    let fvalue = event.target.value;
    // Tracker.log("User Input: ", fvalue, fvalue.length);

    if (fvalue) {
      if (fvalue.length === 3) {
        fvalue += '-';
        this.el.value = fvalue;
      } else if (fvalue.length === 7) {
        fvalue += '-';
        this.el.value = fvalue;
      } else if (fvalue && fvalue.indexOf(this.MOBILE_SEPARATOR) === -1 && fvalue.length === 10) {
        // Tracker.log("User Input Change : ", value, value.length);
        const fotmattedValue = fvalue.substr(0, 3) + this.MOBILE_SEPARATOR + fvalue.substr(3, 3) +
        this.MOBILE_SEPARATOR + fvalue.substr(6, 4);
        this.el.value = fotmattedValue;
      }
    }
  }

  @HostListener('change', ['$event']) onchange(event, e: KeyboardEvent,  m: MouseEvent) {
    const value = event.target.value;
    // Tracker.log("User Input change : ", value);
    if (value && value.indexOf(this.MOBILE_SEPARATOR) === -1 && value.length === 10) {
      // Tracker.log("User Input Change : ", value, value.length);
      const fotmattedValue = value.substr(0, 3) + this.MOBILE_SEPARATOR + value.substr(3, 3) +
      this.MOBILE_SEPARATOR + value.substr(6, 4);
      event.target.value = fotmattedValue;
    }
  }
  /*
  @HostListener('input', ['$event']) onInput(event, e: KeyboardEvent,  m: MouseEvent) {
    const value = event.target.value;

    // Tracker.log("User Input change : ", value);
    if (value && value.indexOf(this.MOBILE_SEPARATOR) === -1 && value.length === 10) {
      // Tracker.log("User Input Change : ", value, value.length);
      const fotmattedValue = value.substr(0, 3) + this.MOBILE_SEPARATOR + value.substr(3, 3) +
      this.MOBILE_SEPARATOR + value.substr(6, 4);
      event.target.value = fotmattedValue;
    }
  }
  */
/*
  @HostListener("focus", ["$event.target"])
  onFocus(target) {
  }

  @HostListener("blur", ["$event.target"])
  onBlur(target) {

  }
  @HostListener("input", ["$event.target.value"])
  onInput(value) {

  }
*/
}
