export var ADD_USER = '[NEWUSER] Add';
export var UPDATE_USER = '[NEWUSER] Update';
export var REMOVE_USER = '[NEWUSER] Remove';
// Add User to the State
var NewUserAdd = /** @class */ (function () {
    function NewUserAdd(payload) {
        this.payload = payload;
        this.type = ADD_USER;
    }
    return NewUserAdd;
}());
export { NewUserAdd };
// Update User in the State
var NewUserUpdate = /** @class */ (function () {
    function NewUserUpdate(payload) {
        this.payload = payload;
        this.type = UPDATE_USER;
    }
    return NewUserUpdate;
}());
export { NewUserUpdate };
// Remove User from State
var NewUserRemove = /** @class */ (function () {
    function NewUserRemove(payload) {
        this.payload = payload;
        this.type = REMOVE_USER;
    }
    return NewUserRemove;
}());
export { NewUserRemove };
