export class Textalertenrollmentreq {

  agentCode: String;
  agentType: String;
  applicationId: String;
  mco: String;
  phoneNumberDetails: Phonenumberdetails[];
  policyMOD: String;
  policyNumber: String;
  stateCode: String;
  symbol: String;

  constructor() {}
}

export class Phonenumberdetails {

  phoneNumber: String;
  phoneStatusCode: String;
  phoneStatusDescription: String;
  constructor(phoneNumber: String, phoneStatusCode: String, phoneStatusDescription: String ) {
    this.phoneNumber = phoneNumber;
    this.phoneStatusCode = phoneStatusCode;
    this.phoneStatusDescription = phoneStatusDescription;
}
}
