<div class="modal-header">
  <h4 class="modal-title heading-2 mb-1">Submit Policy Documents</h4>
  <button type="button" class="close" aria-label="Close"
   (click)="uploadDocsModal.dismiss('Cross click')">
  </button>
</div>
<div class="modal-body">

  <p *ngIf="message.message"> <ngb-alert [type]="message.type" (close)="alertClosed" [dismissible]="false">{{message.message}} </ngb-alert></p>
 <!-- Page Loader -->
<div *ngIf="!isDataLoaded" class="small text-center">Loading...</div>
<div *ngIf="!isDataLoaded" class="my-5 text-center">
  <app-bw-spinner [smallSpinner]="false"></app-bw-spinner>
</div>

<div *ngIf="isDataLoaded && this.showForm">
<form class="my-1" [formGroup]="uploadDocForm" enctype="multipart/form-data">
  <div class="form-row">
      <div class="col-md-10">
          <div class="form-group">
            <label for="docType">Document Type</label>
            <select class="form-control form-control-sm" formControlName="docType">
                <option *ngFor="let doctypes of docTransactionTypes.documentTypes" value="{{ doctypes.docTypeCode }}">{{ doctypes.docTypeDesc }}</option>
              </select>
            <app-field-error-display [displayError]="isFieldValid('docType')" [errorMsg]="getErrMessage(uploadDocForm.controls['docType'].errors, 'docType')"></app-field-error-display>
          </div>
        </div>

  </div>
  <div class="form-row">
    <div class="col-md-10">
      <div class="form-group">
        <label for="">Select Document to Submit</label>
        <input type="file" class="form-control form-control-sm" formControlName="uploadfile" (change)="selectFile($event)" accept=".jpg,.jpeg,.png,.tif,.tiff,.pdf">
        <app-field-error-display [displayError]="isFieldValid('uploadfile')" [errorMsg]="getErrMessage(uploadDocForm.controls['uploadfile'].errors, 'uploadfile')"></app-field-error-display>
      </div>
    </div>
  </div>

  <p *ngIf="isUploading"><ngb-progressbar type="success" [value]="progress.percentage" [animated]="true"><i>{{uploadText}}</i></ngb-progressbar></p>

</form>
</div>
</div>
<div class="modal-footer"> 
  
  <button *ngIf="!this.basicPolicyDetails.isDefaultdocumentType" class="btn btn-secondary" (click)="cancelUploadDocs()">Cancel</button>
  <button *ngIf="!this.basicPolicyDetails.isDefaultdocumentType" class="btn btn-primary" (click)="uploadDocument(uploadDocForm.value)" [disabled]="isUploading">Submit</button>


  <button *ngIf="this.basicPolicyDetails.isDefaultdocumentType && this.message.type === 'success'" class="btn btn-secondary"  (click)="cancelUploadDocs()">close</button>
  <button *ngIf="this.basicPolicyDetails.isDefaultdocumentType && this.message.type !== 'success'" class="btn btn-secondary" (click)="cancelUploadDocs()">Cancel</button>
  <button *ngIf="this.basicPolicyDetails.isDefaultdocumentType && this.message.type !== 'success'" class="btn btn-primary" (click)="uploadDocument(uploadDocForm.value)" >Submit</button>
</div>
