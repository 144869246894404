import { RecipientsToSignReq } from './recipientstosignreq.model';

export class CreateEmbeddedEnvelopeReq {
  polMco: string;
  polSym: string;
  polNum: string;
  policyMod: string;
  polContext: string;
  sourceSystem: string ;
  activityType: string ;
  documentSeqNums: Array<String>;
  recipientsToSignRequests: Array <RecipientsToSignReq>;
  constructor() {}
}
