import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Title, Meta } from '../../../../node_modules/@angular/platform-browser';
import { CustomerReviewsService } from '../../services/customer-reviews/customer-reviews.service';
import { BWErrorhandler } from '../../services/errorhandler';
import { BWError } from '../../shared/model/error.model';
import { MessageConstants } from '../../constants/message.constant';
import { Tracker } from '../../shared/utils/tracker';
import { ReviewsInfoRes } from '../../shared/model/api/customer-reviews/customerreviews.model';
import { GoogleAnalyticsService } from '../../shared/services/analytics/google-analytics.service';

@Component({
  selector: 'app-customer-reviews',
  templateUrl: './customer-reviews.component.html',
  styleUrls: ['./customer-reviews.component.scss']
})
export class CustomerReviewsComponent implements OnInit {
  showQuickQuote: string;
  config: any;
  reviewsList = [];
  isLoaded = false;

  constructor(private title: Title, private customerReviews: CustomerReviewsService,
  private errorHandler: BWErrorhandler, private googleAnalytics: GoogleAnalyticsService, private meta: Meta) {

    this.googleAnalytics.trackEvent('Reviews', 'CustomerReviewsNavigation', 'CustomerReviewsListing');
    this.showQuickQuote = `${environment.showQuickQuote}`;
    this.customerReviews.getCustomerReviews().then(res => {
        if (res && res['CustomerReviews']) {
          this.reviewsList = res['CustomerReviews'];
          this.isLoaded = true;
        } else {
          this.isLoaded = true;
          this.errorHandler.handleApplicationError(new BWError(
            {
              'errorcode': '404',
              'errordesc': MessageConstants.API_ERROR
            }));
        }
      }).catch((error) => {
        this.isLoaded = true;
       this.errorHandler.handleApplicationError(new BWError(
          {
            'errorcode': '404',
            'errordesc': MessageConstants.API_ERROR
          }));
        Tracker.logerror('CustomerReviewsComponent', 'constructor()',
        'Fetching the customer reviews', 'Error Fetching the customer reviews', error);
      });

    this.config = {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.reviewsList.length
    };
   }

  ngOnInit() {}

  pageChanged(event) {
    this.config.currentPage = event;
  }

}
