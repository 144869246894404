import { Injectable } from '@angular/core';
import { LoggerService } from '../../services/logger.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class Tracker {

  constructor() {}

  public static loginfo(className: string, methodName: string, functionName: string, msg: string) {
    const enableLogger = `${environment.enableLogger}`;
    if (enableLogger === 'true') {
      LoggerService.log.info(className, methodName, functionName, msg);
    } else {
      console.log(className, methodName, functionName, msg);
    }
  }

  public static logerror(className: string, methodName: string, functionName: string, msg: string, error: any) {
    const enableLogger = `${environment.enableLogger}`;
    if (enableLogger === 'true') {
      LoggerService.log.error(className, methodName, functionName, msg, error);
    } else {
      console.log(className, methodName, functionName, msg, error);
    }
  }

  public static logtime(serviceName: string, timingmsg: string) {
    const enableLogger = `${environment.enableLogger}`;
    if (enableLogger === 'true') {
      LoggerService.log.logTimining(serviceName, timingmsg);
    } else {
      console.log(serviceName, timingmsg);
    }
  }

  public static gaevent() {

  }
}
