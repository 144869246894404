import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable, Subject, Observer, ReplaySubject } from 'rxjs';
import { GlobalConstants } from '../../constants/global.constant';
import { BwappService } from '../../services/bwapp.service';
import { Directsalesvendors } from '../model/api/policymgmt/directsalesvendors';
import { Statebyzipres } from '../model/api/policymgmt/statebyzipres';
import { Tracker } from '../utils/tracker';
import { Directsalesvendor } from '../model/api/policymgmt/directsalesvendor';
import { ActivatedRoute, Router } from '@angular/router';
import { EndorsementReq } from '../model/api/endorsement/endorsement-req';
import { EndorsementService } from '../../services/api/endorsement/endorsement.service';

@Injectable({
  providedIn: 'root'
})
export class DirectsalesService {
  sourceURL: any;
  // public phoneNumber = new Subject();

  private phoneNumberSubject = new ReplaySubject<string>(1);
  public phoneNumberObject = this.phoneNumberSubject.asObservable();
  endorsementReq: EndorsementReq = new EndorsementReq();
  private globlalConstants = GlobalConstants;
  private directSalesVendorsJSON = 'assets/data/directsalesvendors.json';
  private directSalesVendors: Directsalesvendors;
  isITCState: boolean;
  zipCode: any;
  source: string;
  sourceInd: string;

  marketingID: string;
  constructor(private appService: BwappService, private route: ActivatedRoute,
    private httpClient: HttpClient, private endorsementService: EndorsementService) {
  }

  // keerthana
  getStateByZipCode(zipcode: string): Observable<Statebyzipres> {
    try {
      const params = new HttpParams().set('zipCode', zipcode);
      return this.appService.get(GlobalConstants.GET_STATE_BY_ZIPCODE, params);
    } catch (e) {
      console.error(e);
    }
  }


  getSourceDtlsByState(lob: string, state: string, zipcode: string): Observable<Directsalesvendor[]> {
    try {
      const params = new HttpParams()
        .set('lob', lob)
        .set('state', state)
        .set('zipCode', zipcode);

      return this.appService.get(GlobalConstants.GET_DIRECTSALES_DTLS, params);
      //  .subscribe((res) => {
      // console.log('res--' + res)
      // this.directSalesVendors = res;
      // console.log('this.directSalesVendors--'+ this.directSalesVendors);
      // });
    } catch (e) {
      console.error(e);
    }
  }

  getSourceByState(lob: string, state: string, zipcode: string, directSalesVendors: Directsalesvendor[],
    marketingID: string): Observable<Directsalesvendor> {

    return Observable.create((observer: Observer<Directsalesvendor[]>) => {
      this.zipCode = zipcode;

      if (directSalesVendors !== undefined) {

        /*
              if (directSalesVendors.length !== 0) {
                for (let i = 0; i < directSalesVendors.length; i++) {
                  console.log(directSalesVendors[i] );
                    if (directSalesVendors[i] !== undefined) {
                      const phoneNumber = directSalesVendors[i].phonenumber;
                      const line = directSalesVendors[i].lob;
                      const src = directSalesVendors[i].source;
                      const src_ind = directSalesVendors[i].source_ind;
                      const stateId = directSalesVendors[i].state;
                      const url = directSalesVendors[i].url;
                      const zip = directSalesVendors[i].zipcode;

                      console.log(phoneNumber + line + src + src_ind + stateId + url + zip);

                    }
                  }
              }

        */

        // console.log('Params=[lob=' + lob + ', state=' + state + ', zip=' + zipcode + ']');

        let rest = directSalesVendors.filter(function (direct) {
          return direct.zipcode === zipcode;
        });

        // let rest = directSalesVendors.filter(
        //  value => value. lob === lob && value.state === state && value.zipcode === zipcode);
        // console.log(directSalesVendors.length);
        // console.log(rest);

        // By lob, state, zip code
        // let rest = directSalesVendors.filter(
        //  value => value. lob === lob && value.state === state && value.zipcode === zipcode);
        // console.log('rest.length--' + rest.length);
        // By lob and state
        if (rest === undefined || rest.length === 0) {
          rest = directSalesVendors.filter(value => value.lob === lob && value.state === state);
        }

        // By lob
        if (rest === undefined || rest.length === 0) {
          // console.log('lob');
          rest = directSalesVendors.filter(value => value.lob === lob);
        }

        // More than 1 result found
        if (rest.length > 1) {
          // console.log('>1');
          rest = directSalesVendors.filter(value => value.zipcode === 'ALL');
        }

        // set url / phone number
        // console.log('1) Set SourceURL in Service to ' + rest[0].url);
        //  this.marketingID = this.route.snapshot.queryParamMap.get('id');
        if (marketingID === null) {
          marketingID = 'none';
        }
        Tracker.loginfo('DirectsalesService', 'getSourceByState', '',
          'Marketing ID in Directsales: ' + marketingID);

        /* if (marketingID !== null) {
          // this.addMarketingIdAuditLog(zipcode, state[0], marketingID);
         } */
        const params = new HttpParams()
          .set('zip', zipcode)
          .set('m', marketingID);
        const URL = rest[0].url + '?' + params.toString();

        this.sourceURL = URL;
        this.sourceInd = rest[0].source_ind;

        // putting the data into subject
        this.phoneNumberSubject.next(rest[0].phonenumber);
        observer.next(rest);
      }
    });
  }
  /*
  addMarketingIdAuditLog(zip: string, state: string, marketingID: string) {
    Tracker.loginfo('directsalesService', 'addMarketingIdAuditLog', 'addMarketingIdAuditLog service',
    'direct sales MarketingID audit entry-' + state.concat('-').concat(marketingID).concat('-').concat(zip));

   this.endorsementReq.symbol = this.globlalConstants.AUDIT_LOG_MARKETING_ID_SYMBOL;
   this.endorsementReq.policyNum = this.globlalConstants.AUDIT_LOG_MARKETING_ID_POLICY_NUMBER;
   this.endorsementReq.mod = this.globlalConstants.AUDIT_LOG_MARKETING_ID_MODULE;
   this.endorsementReq.mco = this.globlalConstants.AUDIT_LOG_MARKETING_ID_MASTER_COMPANY;
   this.endorsementReq.state = state;
   this.endorsementReq.activityResult = this.globlalConstants.AUDIT_LOG_MARKETING_ID_ACTIVITY_RESULT;
   this.endorsementReq.activity = this.globlalConstants.AUDIT_LOG_MARKETING_ID_ACTIVITY;
   this.endorsementReq.activityMessage = marketingID;
   this.endorsementReq.binderNumber = zip;
   this.endorsementService.createAuditLogRecord(this.endorsementReq).subscribe();
}
*/
  getSourceURL() {
    return this.sourceURL;
  }

  getZipCode() {
    return this.zipCode;
  }
  getSource() {
    return this.source;
  }

  getSourceInd() {
    return this.sourceInd;
  }
}
