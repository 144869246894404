<main id="main" class="main">
  <div class="container">
    <div>
      <p>As you may have read, the California Department of Insurance recently called on California insurers to provide
        information to the Department about the possibility of providing additional premium refunds to customers due to
        the impact of the COVID-19 based “stay at home” orders on driving behavior. </p>
    </div>

    <div>
      <p>We look forward to continuing to share with the California Department of Insurance how we’ve been helping our
        customers through these challenging times, including finding ways to save. In 2020 alone, we provided more than
        $6.4 million in relief to our California customers through premium refunds and mileage reduction premium
        adjustments.
      </p>
    </div>

    <div>
      <P>Early indications show that driving in the state is returning to pre-pandemic levels as the nation and the
        economy continue to recover, and we will continue to closely monitor the consumer and industry driving
        behavior data as it becomes available. </P>
    </div>

    <div>
      <p>
        If you need to make any changes to your policy, please reach out to your broker. </p>
    </div>


  </div>
</main>