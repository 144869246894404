import { MessageConstants } from '../../constants/message.constant';
var ErrMsgMap = /** @class */ (function () {
    function ErrMsgMap() {
        // Tracker.log('Initializing ErrMsgMap...');
        if (this.map === undefined || this.map.size === 0) {
            this.map = new Map();
            this.map.set('amount', MessageConstants.REQUIRED_AMOUNT);
            this.map.set('method', MessageConstants.REQUIRED_METHOD);
            this.map.set('otherAmount', MessageConstants.REQUIRED_OTHERAMOUNT);
            this.map.set('accountType', MessageConstants.REQUIRED_ACCOUNT_TYPE);
            this.map.set('routingNumber', MessageConstants.REQUIRED_ROUTING_NUMBER);
            this.map.set('accountNumber', MessageConstants.REQUIRED_ACCOUNT_NUMBER);
            this.map.set('confirmAccountNumber', MessageConstants.REQUIRED_ACCOUNT_NUMBER);
            this.map.set('accountnummismatch', MessageConstants.MATCH_ACCOUNT_NUMBER);
            this.map.set('ccNumber', MessageConstants.REQUIRED_CCNUMBER);
            this.map.set('ccExp', MessageConstants.REQUIRED_CCEXP);
            this.map.set('ccCvc', MessageConstants.REQUIRED_CCCVC);
            this.map.set('fname', MessageConstants.REQUIRED_FIRSTNAME);
            this.map.set('lastname', MessageConstants.REQUIRED_LASTNAME);
            this.map.set('address', MessageConstants.REQUIRED_ADDRESS);
            this.map.set('city', MessageConstants.REQUIRED_CITY);
            this.map.set('state', MessageConstants.REQUIRED_STATE);
            this.map.set('zip', MessageConstants.REQUIRED_ZIPCODE);
            this.map.set('dob', MessageConstants.REQUIRED_DOB);
            this.map.set('acceptTC', MessageConstants.REQUIRED_TERMS_CONDITIONS);
            this.map.set('acceptCTC', MessageConstants.REQUIRED_CANCEL_TERMS_CONDITIONS);
            this.map.set('premiumChangeTxtAccept', MessageConstants.REQUIRED_PREMIUM_CHG_ACCEPT);
            this.map.set('nonSweepTxtAccept', MessageConstants.REQUIRED_PREMIUM_CHG_ACCEPT);
            this.map.set('code6TxtAccept', MessageConstants.REQUIRED_PREMIUM_CHG_ACCEPT);
            this.map.set('selectedPaymentType', MessageConstants.PAYMENT_METHOD_TYPE);
            this.map.set('currentRate', MessageConstants.FEEDBACKRATE_REQUIRED);
            this.map.set('docType', MessageConstants.REQUIRED_DOC_TRANS_TYPE);
            this.map.set('uploadfile', MessageConstants.REQUIRED_FILE_UPLOAD);
            this.map.set('plcyCancelDate', MessageConstants.REQUIRED_CANCEL_DATE);
            this.map.set('isVehicleInThisLocation', MessageConstants.REQUIRED_VEHICLE_LOCATION);
            this.map.set('phoneNumber', MessageConstants.REQUIRED_PH_NUMBER);
            this.map.set('isVehicleInThisLocation', MessageConstants.REQUIRED_VEHICLE_LOCATION);
            this.map.set('vin', MessageConstants.REQUIRED_VIN);
            this.map.set('vehOwntype', MessageConstants.REQUIRED_VEHTYPE);
            this.map.set('regownveh', MessageConstants.REQUIRED_REGOWNVEH);
            this.map.set('leasedCompany', MessageConstants.REQUIRED_COMPANY);
            this.map.set('vehusetype', MessageConstants.REQUIRED_VEHUSETYPE);
            this.map.set('isvehcustom', MessageConstants.REQUIRED_ISVEHCUSTOM);
            this.map.set('compdeduct', MessageConstants.REQUIRED_SELECTION);
            this.map.set('coldeduct', MessageConstants.REQUIRED_SELECTION);
            this.map.set('towassistance', MessageConstants.REQUIRED_SELECTION);
            this.map.set('rental', MessageConstants.REQUIRED_SELECTION);
            this.map.set('glassdeductbuyback', MessageConstants.REQUIRED_SELECTION);
            this.map.set('compcoveragerequired', MessageConstants.REQUIRED_COMP);
            this.map.set('isVehicleAntiTheft', MessageConstants.REQUIRED_ANTI_THEFT);
            this.map.set('antiTeftType', MessageConstants.REQUIRED_ANTI_THEFT_TYPE);
            this.map.set('camileageReq', MessageConstants.REQUIRED_CAMIL_LIMIT);
            this.map.set('camileage', MessageConstants.REQUIRED_SELECTION);
            this.map.set('umpd', MessageConstants.REQUIRED_SELECTION);
            this.map.set('collisonSelected', MessageConstants.COLLISION_SELECTED);
            this.map.set('umpdSelected', MessageConstants.UMPD_SELECTED);
            this.map.set('higherthanPD', MessageConstants.SELECTED_UMPD_HIGHER_PD);
            this.map.set('vinEtch', MessageConstants.REQUIRED_VIN_ETCHING);
            this.map.set('colRequired', MessageConstants.REQUIRED_COLISSION);
            this.map.set('vehicleId', MessageConstants.REQUIRED_SELECTION);
            this.map.set('autoLoanLease', MessageConstants.REQUIRED_SELECTION);
        }
    }
    ErrMsgMap.prototype.get = function (key) {
        return this.map.get(key);
    };
    return ErrMsgMap;
}());
export { ErrMsgMap };
