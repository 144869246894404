import { DatePipe } from '@angular/common';
import { GlobalConstants } from '../../constants/global.constant';
import { MessageConstants } from '../../constants/message.constant';
import { EsigMessages } from '../model/esignmessages.model';
import { Validators } from '@angular/forms';
var CommonUtil = /** @class */ (function () {
    function CommonUtil(datePipe) {
        this.datePipe = datePipe;
    }
    CommonUtil.isEmpty = function (obj) {
        // Tracker.log('OBJ= ' + obj);
        if (null !== obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    return false;
                }
            }
        }
        return true;
    };
    CommonUtil.createActiveIds = function (idPrefix, arrayLength) {
        var activeIds = [];
        if (arrayLength > 0) {
            for (var i = 0; i < arrayLength; i++) {
                activeIds[i] = idPrefix + '-' + (i + 1);
            }
        }
        // Tracker.log('Active IDs for ' + idPrefix + ' =' + activeIds);
        return activeIds;
    };
    CommonUtil.topFunction = function () {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };
    CommonUtil.findDeviceVIew = function () {
        return {
            isIOS: function () {
                return /iPhone|iPad|iPod/i.test(navigator.userAgent) ? true : false;
            },
            isMobileDevice: function () {
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i
                    .test(navigator.userAgent) ? true : false;
            }
        };
    };
    CommonUtil.base64toBlob = function (base64Data, contentType) {
        contentType = contentType || '';
        var sliceSize = 1024;
        var byteCharacters = atob(base64Data);
        var bytesLength = byteCharacters.length;
        var slicesCount = Math.ceil(bytesLength / sliceSize);
        var byteArrays = new Array(slicesCount);
        for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            var begin = sliceIndex * sliceSize;
            var end = Math.min(begin + sliceSize, bytesLength);
            var bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
    };
    CommonUtil.padNumber = function (value) {
        if (this.isNumber(value)) {
            return ("0" + value).slice(-2);
        }
        else {
            return '';
        }
    };
    CommonUtil.isNumber = function (value) {
        return !isNaN(this.toInteger(value));
    };
    CommonUtil.toInteger = function (value) {
        return parseInt("" + value, 10);
    };
    CommonUtil.formatDate = function (phdob) {
        var formattedDate = phdob;
        if (formattedDate instanceof Object) {
            formattedDate = this.padNumber(phdob.month) + '/' + this.padNumber(phdob.day) + '/' + phdob.year;
        }
        return formattedDate;
    };
    CommonUtil.getDateTimeNowEST = function () {
        var datePipe = new DatePipe('en-US');
        return datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss.sss', 'EST', 'en-US');
    };
    CommonUtil.getDateTimeNowDateFormat = function (dateFormat) {
        var datePipe = new DatePipe('en-US');
        return datePipe.transform(new Date(), dateFormat, 'en-US');
    };
    CommonUtil.getDateNowEST = function () {
        var datePipe = new DatePipe('en-US');
        return datePipe.transform(new Date(), 'yyyy-MM-dd', 'EST');
    };
    CommonUtil.getTimeNowEST = function () {
        var datePipe = new DatePipe('en-US');
        return datePipe.transform(new Date(), 'HH:mm:ss', 'EST', 'en-US');
    };
    CommonUtil.addDateNowEST = function (days) {
        var datePipe = new DatePipe('en-US');
        return datePipe.transform(new Date().getTime() + (1000 * 60 * 60 * (24 * days)), 'yyyy-MM-dd', 'EST');
    };
    CommonUtil.getDateTimeNow = function () {
        var datePipe = new DatePipe('en-US');
        return datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss.sss', 'en-US');
    };
    // this will remove only beginning and end of string whitespace.
    CommonUtil.lrtrim = function (x) {
        return x ? x.replace(/^\s+|\s+$/gm, '') : '';
    };
    // this will remove whole whitespace from the string
    CommonUtil.trim_all_spaces = function (x) {
        return x ? x.replace(/\s/g, '') : '';
    };
    CommonUtil.getMCO = function (authenticatedPhDetails) {
        return authenticatedPhDetails.pointHookID.substr(0, 2);
    };
    CommonUtil.getPolicySymbol = function (authenticatedPhDetails) {
        return authenticatedPhDetails.pointHookID.substr(2, 3);
    };
    CommonUtil.getPolicyNumber = function (authenticatedPhDetails) {
        return authenticatedPhDetails.pointHookID.substr(5);
    };
    CommonUtil.getPolicyMod = function (authenticatedPhDetails) {
        return authenticatedPhDetails.policyMod;
    };
    CommonUtil.getBWPolicyNumber = function (authenticatedPhDetails) {
        return this.getPolicySymbol(authenticatedPhDetails)
            .concat(this.getPolicyNumber(authenticatedPhDetails))
            .concat(this.getPolicyMod(authenticatedPhDetails));
    };
    CommonUtil.checkPlcyHasEnvelopes = function (envelopes) {
        var hasEnvelops = false;
        envelopes.forEach(function (env) {
            // Tracker.log('Looping Envelop elements::: ', env);
            env.recipients.forEach(function (rec) {
                // Tracker.log('Looping Recipient elements::: ', rec);
                // if (rec.recipType === 'PrimaryNamedInsured') {
                if (rec.status === 'Completed' || rec.status === 'Declined' || rec.status === 'Signed') {
                    hasEnvelops = false;
                }
                else {
                    hasEnvelops = true;
                    return hasEnvelops;
                }
                // }
            });
        });
        return hasEnvelops;
    };
    CommonUtil.getPendingEnvelopeMessage = function (envelopes) {
        var esignMessages = new EsigMessages();
        envelopes.forEach(function (env) {
            // // Tracker.log('Looping Envelop elements::: ', env);
            env.recipients.forEach(function (rec) {
                // Tracker.log('Looping Recipient elements::: ', rec);
                if (rec.status === 'Completed' || rec.status === 'Declined' || rec.status === 'Signed') {
                    esignMessages.hasPendingEnvelops = false;
                }
                else {
                    esignMessages.recepientType = rec.recipType;
                    esignMessages.hasPendingEnvelops = true;
                    esignMessages.envelopeId = env.envelopeID;
                    esignMessages.envelopeExpiryDate = env.expireDate;
                    esignMessages.recepientId = rec.recipientID;
                    return esignMessages;
                }
            });
        });
        return esignMessages;
    };
    CommonUtil.getEsigMessage = function (messageType) {
        var message;
        switch (messageType) {
            case GlobalConstants.DOCUSIGN_TYPE_CANCELLED: {
                message = MessageConstants.DOCUSIGN_CANCEL_MSG;
                break;
            }
            case GlobalConstants.DOCUSIGN_TYPE_DECLINED: {
                message = MessageConstants.DOCUSIGN_DECLINE_MSG;
                break;
            }
            case GlobalConstants.DOCUSIGN_TYPE_EXCEPTION: {
                message = MessageConstants.DOCUSIGN_EXCEPTION_MSG;
                break;
            }
            case GlobalConstants.DOCUSIGN_TYPE_IDCHECK: {
                message = MessageConstants.DOCUSIGN_IDCHECK_FAILED_MSG;
                break;
            }
            case GlobalConstants.DOCUSIGN_TYPE_SESSION_TIMEOUT: {
                message = MessageConstants.DOCUSIGN_SESSION_TIMEOUT_MSG;
                break;
            }
            case GlobalConstants.DOCUSIGN_TYPE_COMPLETE: {
                message = MessageConstants.DOCUSIGN_COMPLETE_MSG;
                break;
            }
            case GlobalConstants.DOCUSIGN_TYPE_TTL_EXPIRED: {
                message = MessageConstants.DOCUSIGN_TTL_EXPIRED_MSG;
                break;
            }
            case GlobalConstants.DOCUSIGN_TYPE_VIEWING: {
                message = MessageConstants.DOCUSIGN_VIEWING_COMPLETE_MSG;
                break;
            }
        }
        return message;
    };
    CommonUtil.getLandingMessage = function (registatus, src) {
        var landingMsg = '';
        if (registatus) {
            if (src === 'GP') {
                landingMsg = MessageConstants.GP_LOGIN_MSG;
            }
            else if (src === 'ESIG') {
                landingMsg = MessageConstants.ESIG_LOGIN_MSG;
            }
            else if (src === 'WELCOME') {
                landingMsg = MessageConstants.WELCOME_LOGIN_MSG;
            }
        }
        else {
            if (src === 'GP') {
                landingMsg = MessageConstants.GP_REG_MSG;
            }
            else if (src === 'ESIG') {
                landingMsg = MessageConstants.ESIG_REG_MSG;
            }
            else if (src === 'WELCOME') {
                landingMsg = MessageConstants.WELCOME_REG_MSG;
            }
        }
        return landingMsg;
    };
    CommonUtil.getContinueAction = function (registatus) {
        var action = 'LOGIN';
        if (registatus) {
            action = 'LOGIN';
        }
        else {
            action = 'REGISTER';
        }
        return action;
    };
    CommonUtil.formatCC = function (ccNum) {
        if (ccNum !== null) {
            var re = /\ /gi;
            return ccNum.replace(re, '');
        }
    };
    CommonUtil.formatCCExpDt = function (expDt) {
        if (expDt !== null) {
            var re = /\//gi;
            return expDt.replace(re, '');
        }
    };
    CommonUtil.removewhitespaces = function (name) {
        if (name !== null) {
            var re = /\ /gi;
            return name.replace(re, '');
        }
    };
    CommonUtil.updateControlValidation = function (fControl, isRequired) {
        if (isRequired) {
            fControl.setValidators([Validators.compose([Validators.required])]);
        }
        else {
            fControl.setValidators([]);
        }
        fControl.updateValueAndValidity();
    };
    CommonUtil.scrollToTop = function () {
        console.log('Scrolling to the top');
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };
    return CommonUtil;
}());
export { CommonUtil };
