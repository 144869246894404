import { Envelope } from "./api/esig/envelop.model";
import { CreatedEnvelope } from "./api/esig/createdEnvelope.model";

export class StateWorkflow {

  envelopeCreated: boolean;
  recipientID: string;
  envelopeID: string;
  envelopes: Envelope[];
  createdEnvelopeObj: CreatedEnvelope;
constructor() {}
}
