import fontawesome from '@fortawesome/fontawesome';
import { faAddressCard, faCreditCard } from '@fortawesome/fontawesome-free-regular';
import { faClipboardList } from '@fortawesome/fontawesome-free-solid';
import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
// Fontawesome library
fontawesome.library.add(faCreditCard, faClipboardList, faAddressCard);
var cookieConfig = {
    cookie: {
        domain: window.location.hostname
    },
    palette: {
        popup: {
            background: '#0073cf'
        },
        button: {
            background: '#FFFFFF'
        }
    },
    theme: 'classic',
    type: 'opt-out',
    layout: 'my-custom-layout',
    layouts: {
        "my-custom-layout": '{{messagelink}}{{compliance}}'
    },
    elements: {
        messagelink: "\n    <span id=\"cookieconsent:desc\" class=\"cc-message\">\n    <div class=\"row\">\n    <div class=\"d-only-desktop d-only-tablet\">{{message}}</div>\n      <a aria-label=\"learn more about our terms of service\" tabindex=\"2\" class=\"cc-link\" style=\"padding-top: 0px;\" href=\"{{tosHref}}\" target=\"_blank\">{{tosLink}}</a>\n    </div>\n      </span>\n    ",
    },
    content: {
        message: 'Bristol West is committed to your privacy and security. Learn more about our ',
        tosLink: 'Personal Information Use ',
        tosHref: 'https://www.farmers.com/privacy-statement',
    }
};
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
