var Policynumber = /** @class */ (function () {
    function Policynumber(policynumber) {
        // Unformatted policy number
        if (policynumber.length > 12) {
            policynumber = policynumber.replace(/-/g, '');
        }
        // formatted policynumber
        this.symbol = policynumber.substring(0, 3);
        this.policy = policynumber.substring(3, 10);
        this.module = policynumber.substring(10);
    }
    Policynumber.prototype.toString = function () {
        return this.symbol.concat(this.policy).concat(this.module);
    };
    Policynumber.prototype.toFormattedString = function () {
        return this.symbol.concat('-').concat(this.policy).concat('-').concat(this.module);
    };
    return Policynumber;
}());
export { Policynumber };
