
export class Recipient {

    recipientID: string;
    envelopeID: string;
    status: string;
    recipType: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
