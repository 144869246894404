import { ElementRef, EventEmitter } from '@angular/core';
import { CommonUtil } from '../utils/commonutil';
var TooltipCloseDirective = /** @class */ (function () {
    function TooltipCloseDirective(_elementRef) {
        this._elementRef = _elementRef;
        this.clickOutside = new EventEmitter();
    }
    TooltipCloseDirective.prototype.onClick = function (targetElement) {
        var clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (CommonUtil.findDeviceVIew().isIOS()) {
            if (!clickedInside) {
                if (this.appTooltipClose.isOpen()) {
                    this.appTooltipClose.close();
                }
            }
        }
    };
    return TooltipCloseDirective;
}());
export { TooltipCloseDirective };
