import { Lienholdercmpy } from '../policyholder/lienholdercmpy.model';
import { Vehicles } from '../policyinfo/vehicle.model';

export class EndorseVehicleInfo {
    unitNum: number;
    vin: string;
    year: string;
    make: string;
    manufacturerName:string;
    model: string;

    bodyStyle: string; // Possible values : PU/SD/TU/UT - Set from vintelligence (bodyStyleCode)
    type: string; // Possible values : MH/PP/PU/RT/UT/VN - Set from vintelligence (vehicleType)
    vehUse: string;
    doorCount: string;

    disablingDevice: string; // Set from vintelligence (disablingDeviceType)
    passiveRestraint: string; // Set from vintelligence (passiveRestraintType)
    antiLockBrake: string; // Set from vintelligence (antiLockBrakesOption) - required custom mapping
    vehicleRecovery: string; // Set from vintelligence (vehicleRecoveryIndicator)
    daytimeRunningLamps: string; // Set from vintelligence (daylightRunningLightsOption)
    isoSymb2: string; // Set from vintelligence (vsrSymbolForVehicle or comprehensiveOTCSymbolCode)

    customEquipCost: string; // Always 0.0

    replacedVehUnitNum: number;  // Only applicable for Replace Vehicle endorsement

    garagingAddress: GaragingAddress;

    selectedLnCompany: Lienholdercmpy;

    // TODO: Delete if not required - under clarification from Heather
    oneWayMileage: string;
    daysPerWeek: string;
    weeksPerMonth: string;
    annualMileage: string;
    percentBusiness: string;
    storage: string;
    storageDate: string;

    // Data capture fields
    isvehcustomized: string;
    vehOwnType: string;

    // Selected vehicle to replace or delete
    selectedVehicle: Vehicles;

    vinArray: String[];
    termLength: string;

    transactionDate: string;
    vinEtching: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}

export class GaragingAddress {
    type: string;  // Type= GL
    addressLine1: string;  // Policy Mailing address
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
  }



