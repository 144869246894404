import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalConstants } from '../../../constants/global.constant';
import { BasicPolicyContract } from '../../../shared/model/api/bwquery/basiccontract.model';
import { HasBeenWetSignedRes } from '../../../shared/model/api/bwquery/hasbeenwetsignedres.model';
import { HasMadePaymentorEFTRes } from '../../../shared/model/api/bwquery/hasmadepaymentoreftres.model';
import { PlcyLocationModStatusAndMaxModRes } from '../../../shared/model/api/bwquery/plcylocationandmodres.model';
import { Policyfeeres } from '../../../shared/model/api/bwquery/policyfeeres.model';
import { PolicyCurrentModRes } from '../../../shared/model/api/bwquery/policymodres.model';
import { TfcPolicyESigEligibleRes } from '../../../shared/model/api/bwquery/tfcpolicyesigeligibleres.model';
import { TfcRenewalOfferRes } from '../../../shared/model/api/bwquery/tfcrenewalofferres.model';
import { Policynumber } from '../../../shared/model/policynumber';
import { Cancelledpolicyres } from '../../../shared/model/registration/cancelledpolicyres';
import { BwstoreService } from '../../../shared/services/bwstore.service';
import { BwappService } from '../../bwapp.service';
import { BWErrorhandler } from '../../errorhandler';
import { Feedbackreq } from '../../../shared/model/api/bwquery/feedbackreq';

@Injectable({
  providedIn: 'root'
})
export class BwqueriesService {

  private globlalConstants = GlobalConstants;
  _baseURL: string;
  policyNumber: Policynumber;

  constructor(private appService: BwappService, private storeService: BwstoreService, private errorHandler: BWErrorhandler) {
    this._baseURL = GlobalConstants.baseURL;
   }

   isCancelledPolicy(): Observable<Cancelledpolicyres> {
    this.policyNumber = new Policynumber(this.storeService.newUser.policynumber);
    const params = new HttpParams()
      .set('polSym', this.policyNumber.symbol)
      .set('polNum', this.policyNumber.policy)
      .set('polMod', this.policyNumber.module)
      .set('mco', this.storeService.newUser.mco);

    return this.appService.get(this.globlalConstants.BWQRY_IS_CANCELLED_URL, params);
  }

  getPolicyBasicContract(policynumber: string): Observable<BasicPolicyContract> {

    this.policyNumber = new Policynumber(policynumber);
    // Tracker.log('BWQueryService: getPolicyBasicContract() is inkovked for policy:: ', policynumber, this.policyNumber);
    const params = new HttpParams()
    .set('polSym', this.policyNumber.symbol)
    .set('polNum', this.policyNumber.policy)
    .set('polMod', this.policyNumber.module);

    return this.appService.get(this.globlalConstants.BWQRY_GETPOLICYBASICCONTRACT, params);
  }

  getPolicyCurrentMod(policySymbol: string, policyNumber: string): Observable<PolicyCurrentModRes> {
    // Tracker.log('BWQueryService: getPolicyCurrentMod() is invoked for policy:: ', policyNumber);
    const params = new HttpParams()
    .set('polSym', policySymbol)
    .set('polNum', policyNumber);

    return this.appService.get(this.globlalConstants.BWQRY_GETPOLICYCURRENTMOD, params);
  }

  getPolicyLocationStatusAndMaxMod(policySymbol: string, policyNumber: string): Observable<PlcyLocationModStatusAndMaxModRes> {
    // Tracker.log('BWQueryService: getPolicyLocationStatusAndMaxMod() is invoked for policy:: ', policyNumber);
    const params = new HttpParams()
    .set('polSym', policySymbol)
    .set('polNum', policyNumber);

    return this.appService.get(this.globlalConstants.BWQRY_GETPOLICYLOCATIONSTATUSANDMAXMOD, params);
  }

  getPolicyFee(policynumber: string): Observable<Policyfeeres> {
    this.policyNumber = new Policynumber(policynumber);
    const params = new HttpParams()
    .set('polSym', this.policyNumber.symbol)
    .set('polNum', this.policyNumber.policy)
    .set('polMod', this.policyNumber.module);

    return this.appService.get(this.globlalConstants.BWQRY_GETPOLICYFEE, params);
  }


  checkIf21CPolicyRenewalOffer(mco: string, policySymbol: string, policyNumber: string,
    policyMod: string): Observable<TfcRenewalOfferRes> {
    const params = new HttpParams()
    .set('mco', mco)
    .set('polSym', policySymbol)
    .set('polNum', policyNumber)
    .set('polMod', policyMod);

    // Tracker.log('BWQueryService: checkIf21stPolicyRenewalOffer() is invoked for policy:: ', params);
    return this.appService.get(this.globlalConstants.BWQRY_IS_21CRENEWALOFFER, params);
  }

  checkIf21CPolicyEsigEligible(mco: string, policySymbol: string, policyNumber: string,
    policyMod: string): Observable<TfcPolicyESigEligibleRes> {
    // Tracker.log('BWQueryService: checkIf21CPolicyEsigEligible() is invoked for policy:: ', policyNumber, mco);
    const params = new HttpParams()
    .set('mco', mco)
    .set('polSym', policySymbol)
    .set('polNum', policyNumber)
    .set('polMod', policyMod);

    return this.appService.get(this.globlalConstants.BWQRY_IS_21CESIGELIGIBLE, params);
  }

  checkIfPolicyWetSigned(mco: string, policySymbol: string, policyNumber: string,
    policyMod: string): Observable<HasBeenWetSignedRes> {
    // Tracker.log('BWQueryService: checkIfPolicyWetSigned() is invoked for policy:: ', policyNumber, mco);
    const params = new HttpParams()
    .set('mco', mco)
    .set('polSym', policySymbol)
    .set('polNum', policyNumber)
    .set('polMod', policyMod);

    return this.appService.get(this.globlalConstants.BWQRY_IS_WETSIGNED, params);
  }

  checkIfPPaymentHasMadeOrEFT(mco: string, policySymbol: string, policyNumber: string,
    policyMod: string): Observable<HasMadePaymentorEFTRes> {
    // Tracker.log('BWQueryService: checkIfPPaymentHasMadeOrEFT() is invoked for policy:: ', policyNumber, mco);
    const params = new HttpParams()
    .set('mco', mco)
    .set('polSym', policySymbol)
    .set('polNum', policyNumber)
    .set('polMod', policyMod);

    return this.appService.get(this.globlalConstants.BWQRY_HAS_PAYMENTMADEOREFT, params);
  }

  saveFeedback(feedback: Feedbackreq) {
    return this.appService.post(this.globlalConstants.BWUTIL_SAVEFEEDBACK, feedback);
  }
}
