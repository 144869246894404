import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import { GlobalConstants } from '../../../constants/global.constant';
import { DocumentsService } from '../../../services/api/documents/documents.service';
import { PaymentService } from '../../../services/api/payment/payment.service';
import { DateValidator } from '../../validators/date.validator';
import { FullPolicyDetails, PaymentInfo, PolicyInfo } from '../../model/api/bwibic/fullpolicydetails.model';
import { Idcardreq } from '../../model/api/document/idcardreq';
import { Policynumber } from '../../model/policynumber';
import { CenturydatePipe } from '../../pipes/centurydate.pipe';
import { AuthenticationService } from '../../services/authentication.service';
import { BwstoreService } from '../../services/bwstore.service';
import { MessagesService } from '../../services/messages.service';
import { CommonUtil } from '../../utils/commonutil';
import { PolicymgtService } from '../../../services/api/policymgmt/policymgt.service';
import { Authforidcardreq } from '../../model/api/policymgmt/authforidcardreq';
import { Tracker } from '../../utils/tracker';
import { PolicyHolder } from '../../model/authentication/authenticatephres';
import { environment } from '../../../../environments/environment';
import { PolicyholderService } from '../../../services/api/policyholder/policyholder.service';
var WithoutLoginComponent = /** @class */ (function () {
    function WithoutLoginComponent(paymentService, documentService, _storeService, _messageService, authService, policyManageService, datePipe, router, route, fb, policyholderService) {
        this.paymentService = paymentService;
        this.documentService = documentService;
        this._storeService = _storeService;
        this._messageService = _messageService;
        this.authService = authService;
        this.policyManageService = policyManageService;
        this.datePipe = datePipe;
        this.router = router;
        this.route = route;
        this.fb = fb;
        this.policyholderService = policyholderService;
        // Messages
        this.errorMsg = '';
        this.isPageDataLoaded = true;
        this.haveIDCard = false;
        this.cookieBannerUrl = "" + environment.ccpaCookieBanner;
        this.isPaymentusEnabled = true;
    }
    WithoutLoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.createWithoutLoginForm();
        this.loadingMessage = 'Loading...';
        this.twoOfThreeRequiredMessage = '';
        // Determine if it is Make a Payment or Print ID Card
        this.route.queryParamMap.subscribe(function (params) {
            var typeVal = params.get('type');
            _this.isPayment = (typeVal !== null && typeVal === 'pay' ? true : false);
            if (typeVal === 'pay') {
                _this.policyholderService.getPaymentusStatus().subscribe(function (pniInfores) {
                    if (pniInfores && pniInfores.result === 'Success' && pniInfores.IsPaymentusAppFeatureEnabled === "true") {
                        _this.isPaymentusEnabled = true;
                        _this.router.navigate(['../payments']);
                    }
                    else {
                        _this.isPaymentusEnabled = false;
                    }
                });
            }
            else {
                _this.isPaymentusEnabled = false;
            }
        });
    };
    WithoutLoginComponent.prototype.onSubmit = function (formData) {
        var _this = this;
        this.formSubmitAttempt = true;
        this.loadingMessage = 'Verifying your information';
        // Clear previous error
        this.errorMsg = '';
        this.twoOfThreeRequiredMessage = '';
        // Tracker.log('form submitted');
        // Now check if 2 of the 3 required fields where provided
        this.hasTwoSelected = [false, false, false];
        this.hasTwoSelected[0] = (formData.lastname === '' || formData.lastname === undefined || formData.lastname === null ? false : true);
        this.hasTwoSelected[1] = (formData.dob === '' || formData.dob === undefined || formData.dob === null ? false : true);
        this.hasTwoSelected[2] = (formData.phpolicynum === '' || formData.phpolicynum === undefined
            || formData.phpolicynum === null ? false : true);
        /*
        if (this.isFieldValid(formData.dob)) {
          formData.dob.setValidators([Validators.minLength(10), DateValidator.usDate]);
          formData.dob.updateValueAndValidity();
        }
  */
        var numberOfAnswersProvided = this.hasTwoSelected.filter(function (value) {
            return value === true;
        }).length;
        try {
            if (this.withoutloginForm.valid) {
                if (numberOfAnswersProvided >= 2) {
                    this.isPageDataLoaded = false;
                    // Tracker.log(formData.phpolicynum);
                    if (formData.phpolicynum !== undefined && formData.phpolicynum !== null) {
                        this.policyNumber = new Policynumber(formData.phpolicynum.toUpperCase());
                    }
                    var _polsymbol = (this.policyNumber === undefined ? '' : this.policyNumber.symbol);
                    var _polnumber = (this.policyNumber === undefined ? '' : this.policyNumber.policy);
                    // const _polmod = (this.policyNumber.module === undefined ? '' : this.policyNumber.module);
                    var _lastname = (formData.lastname === undefined || formData.lastname === '' ? '' : formData.lastname);
                    var _dob = (formData.dob === undefined || formData.dob === '' || formData.dob === 'null'
                        || formData.dob === null ? '' : formData.dob);
                    if (!this.isPayment) {
                        // ID Card ** UPDATED TO USE authForIDCard from PolicyManagement Service
                        this.authIDCardReq = new Authforidcardreq();
                        this.authIDCardReq.dob = (_dob === '' || _dob === null ? '' : this.datePipe.transform(CommonUtil.formatDate(_dob)));
                        this.authIDCardReq.lastName = _lastname;
                        this.authIDCardReq.policyNumber = _polnumber;
                        this.authIDCardReq.policySymbol = _polsymbol;
                        this.authIDCardReq.zipCode = formData.zip;
                        this.policyManageService.authForIDCard(this.authIDCardReq).subscribe(function (data) {
                            _this.authIDCardRes = data;
                            if (_this.authIDCardRes !== null && _this.authIDCardRes !== undefined) {
                                if (_this.authIDCardRes.returnCode && _this.authIDCardRes.returnCode.trim() === ''
                                    && _this.authIDCardRes.policy) {
                                    var policyHolder = new PolicyHolder();
                                    policyHolder.jwtToken = _this.authIDCardRes.jwtToken;
                                    _this._storeService.createLoginInfo(policyHolder);
                                    _this.policyNumber = new Policynumber(_this.authIDCardRes.policy.substring(4));
                                    var state = _this.authIDCardRes.insuredCityStateZipPhone.substring(28, 30);
                                    if (state !== 'NJ' && state !== 'NY') {
                                        _this.idcardreq = new Idcardreq();
                                        _this.idcardreq.policyID = _this.policyNumber.toString();
                                        _this.idcardreq.mco = _this.authIDCardRes.policy.substring(0, 2);
                                        _this.idcardreq.futureTerm = 'N'; // TODO: what sets this
                                        _this.idcardreq.appName = GlobalConstants.APPID;
                                        _this.idcardreq.deliveryMode = 'Email';
                                        _this.loadingMessage = 'Retrieving ID Card...';
                                        _this.documentService.retrieveIDCard(_this.idcardreq).subscribe(function (iddata) {
                                            _this.idcardres = iddata;
                                            if (_this.idcardres !== null && _this.idcardres !== undefined) {
                                                if (_this.idcardres.idCard !== '') {
                                                    // download to browser
                                                    _this.downloadIdCard(_this.idcardres.idCard);
                                                    _this.isPageDataLoaded = true;
                                                    _this.haveIDCard = true;
                                                    // remove JWT
                                                    _this.authService.logout();
                                                }
                                                else {
                                                    _this.isPageDataLoaded = true;
                                                    // invalid login so display error message
                                                    _this.errorMsg = 'We are unable to retrieve your ID Card at this time. Please try again.';
                                                    // this.paymentDetailsRes.responseText;
                                                }
                                            }
                                            else {
                                                _this.isPageDataLoaded = true;
                                                throwError('We are unable to retrieve your ID Card at this time. Please try again.');
                                            }
                                        }, function (error) {
                                            Tracker.logerror('WithoutLoginComponent', 'onSubmit', 'retrieveIDCard', 'Error Retrieving ID Card', error);
                                            _this.authService.logout();
                                            _this.isPageDataLoaded = true;
                                        });
                                    }
                                    else {
                                        // NY or NJ so don't display ID Card. Show message
                                        _this.isPageDataLoaded = true;
                                        _this.errorMsg = 'Please contact your agent for assistance with your ID Card.';
                                        _this.authService.logout();
                                    }
                                }
                                else {
                                    _this.authService.logout();
                                    _this.isPageDataLoaded = true;
                                    // invalid login so display error message
                                    _this.errorMsg =
                                        'We could not locate a policy with the information you provided, please check your information and try again.';
                                    // this.paymentDetailsRes.responseText;
                                }
                            }
                            else {
                                _this.authService.logout();
                                _this.isPageDataLoaded = true;
                                throwError('Unable to identify user');
                            }
                        }, function (error) {
                            _this.errorMsg =
                                'There was an error trying to retrieve your policy details. Please try again.';
                            _this.authService.logout();
                            _this.isPageDataLoaded = true;
                            Tracker.logerror('WithoutLoginComponent', 'onSubmit', 'authForIDCard', 'Error Authorizing for ID Card', error);
                        });
                    }
                    else {
                        // Payment
                        this.paymentService.validatePolicyDetails(_polsymbol, _polnumber, formData.zip, formData.lastname, CommonUtil.formatDate(formData.dob)).subscribe(function (data) {
                            _this.paymentDetailsRes = data;
                            if (_this.paymentDetailsRes !== null && _this.paymentDetailsRes !== undefined) {
                                if (_this.paymentDetailsRes.responseCode === 0) {
                                    if (_this.paymentDetailsRes.paymentMoniker === null
                                        || _this.paymentDetailsRes.paymentMoniker === undefined) {
                                        _this.paymentDetailsRes.paymentMoniker = 'Please select a payment method';
                                    }
                                    // const policyHolder =  new PolicyHolder();
                                    // policyHolder.jwtToken = this.paymentDetailsRes.jwtToken;
                                    // this._storeService.createLoginInfo(policyHolder);
                                    // create PolicyInfo Object and save in store
                                    _this.basicPolicyDetails = new FullPolicyDetails();
                                    _this.basicPolicyDetails.isNoLogin = true;
                                    _this.policyInfo = new PolicyInfo();
                                    _this.paymentInfo = new PaymentInfo();
                                    // Payment Info
                                    _this.paymentInfo.paymentMoniker = _this.paymentDetailsRes.paymentMoniker;
                                    _this.paymentInfo.paymentType = _this.paymentDetailsRes.paymentType;
                                    _this.paymentInfo.paymentVendor = _this.paymentDetailsRes.paymentVendor;
                                    // this.paymentInfo.webpayStatus = this.paymentDetailsRes.webpayStatus;
                                    _this.paymentInfo.allowCCPayment = _this.paymentDetailsRes.allowCCPayment;
                                    _this.paymentInfo.eftIndicator = _this.paymentDetailsRes.eftIndicator;
                                    _this.paymentInfo.installmentInfo = _this.paymentDetailsRes.installmentInfo;
                                    _this.paymentInfo.firstName = _this.paymentDetailsRes.firstName;
                                    _this.paymentInfo.lastName = _this.paymentDetailsRes.lastName;
                                    _this.paymentInfo.minimumPayment = _this.paymentDetailsRes.minimumPayment;
                                    _this.paymentInfo.maximumPayment = _this.paymentDetailsRes.maximumPayment;
                                    _this.paymentInfo.totalPremium = _this.paymentDetailsRes.totalPremium;
                                    _this.paymentInfo.lastPayAmount = _this.paymentDetailsRes.lastPayAmount;
                                    _this.paymentInfo.policyBalance = _this.paymentDetailsRes.policyBalance;
                                    _this.paymentInfo.lastPayDate = _this.paymentDetailsRes.lastPayDate;
                                    _this.paymentInfo.address = _this.paymentDetailsRes.address;
                                    _this.paymentInfo.city = _this.paymentDetailsRes.city;
                                    _this.paymentInfo.state = _this.paymentDetailsRes.state;
                                    _this.paymentInfo.zip = _this.paymentDetailsRes.zip;
                                    _this.paymentInfo.expirationYear = _this.paymentDetailsRes.expirationYear;
                                    _this.paymentInfo.expirationMonth = _this.paymentDetailsRes.expirationMonth;
                                    _this.paymentInfo.cvc = _this.paymentDetailsRes.cvc;
                                    _this.paymentInfo.homePhoneNumber = _this.paymentDetailsRes.homePhoneNumber;
                                    _this.paymentInfo.masterCompany = _this.paymentDetailsRes.masterCompany;
                                    // PolicyInfo
                                    _this.policyInfo.SYMBOL = _this.paymentDetailsRes.symbol;
                                    _this.policyInfo.POLICY0NUM = _this.paymentDetailsRes.policyNumber;
                                    _this.policyInfo.MODULE = _this.paymentDetailsRes.mod;
                                    _this.policyInfo.PolicyMasterCompany = _this.paymentDetailsRes.masterCompany;
                                    _this.policyInfo.PolicyZip = formData.zip;
                                    _this.policyInfo.PolicyCompany = _this.paymentDetailsRes.policyCompany;
                                    // FullPolicyDetails
                                    _this.basicPolicyDetails.paymentInfo = _this.paymentInfo;
                                    _this.basicPolicyDetails.policyInfo = _this.policyInfo;
                                    _this._storeService.updatePolicyInfo(_this.basicPolicyDetails);
                                    _this.router.navigate(['/payment']);
                                }
                                else {
                                    // invalid login so display error message
                                    _this.errorMsg = 'We are unable to find your policy. Please verify the information provided.';
                                    _this.authService.logout();
                                    _this.isPageDataLoaded = true;
                                    // this.paymentDetailsRes.responseText;
                                }
                            }
                            else {
                                _this.authService.logout();
                                _this.isPageDataLoaded = true;
                                throwError('Unable to identify user');
                            }
                        }, function (error) {
                            Tracker.logerror('WithoutLoginComponent', 'onSubmit', 'validatePolicyDetails', 'Error Authorizing for Payment', error);
                            _this.authService.logout();
                            _this.isPageDataLoaded = true;
                        });
                    }
                }
                else {
                    this.twoOfThreeRequiredMessage = 'Please answer 2 of the 3 questions below.';
                    this.isPageDataLoaded = true;
                }
            }
        }
        catch (error) {
            this.authService.logout();
            // Tracker.log('Error Happened in payment submit service', error);
        }
        finally {
            // Clean up needed?
            // this.isPageDataLoaded = true;
        }
    };
    WithoutLoginComponent.prototype.downloadIdCard = function (pdf) {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(CommonUtil.base64toBlob(pdf, 'application/pdf'));
            return;
        }
        // All other browsers
        var linkSource = "data:application/pdf;base64," + pdf;
        var downloadLink = document.createElement('a');
        var fileName = 'IDCard.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            document.body.removeChild(downloadLink);
            // window.URL.revokeObjectURL(data);
        });
    };
    WithoutLoginComponent.prototype.createWithoutLoginForm = function () {
        this.withoutloginForm = this.fb.group({
            'zip': ['', Validators.compose([Validators.required])],
            'lastname': [''],
            'dob': [null, Validators.compose([Validators.minLength[10], DateValidator.usDate])],
        });
    };
    // Form validations
    WithoutLoginComponent.prototype.isFieldValid = function (field) {
        return ((!this.withoutloginForm.get(field).valid && this.withoutloginForm.get(field).touched) ||
            (this.withoutloginForm.get(field).untouched && this.formSubmitAttempt));
    };
    WithoutLoginComponent.prototype.showForm = function () {
        this.haveIDCard = false;
        this.isPageDataLoaded = true;
    };
    WithoutLoginComponent.prototype.getErrMessage = function (errors, formCntrlName) {
        return this._messageService.getErrorMessages(errors, formCntrlName);
    };
    return WithoutLoginComponent;
}());
export { WithoutLoginComponent };
