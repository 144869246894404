import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { GlobalConstants } from '../../../constants/global.constant';
import {AutoPayCancelRequest, AutoPayCancelResponse, AutoPayDetailsResponse, AutoPayEnrollResponse, AutoPaymentEnrollRequest } from '../../../shared/model/api/autoeft/autopayenrollment.model';
import { FullPolicyDetails } from '../../../shared/model/api/bwibic/fullpolicydetails.model';
import { PaymentMethodInfo } from '../../../shared/model/api/payment/payment-methods.model';
import { Currenteftres,Currenteftinfores } from '../../../shared/model/api/policyeft/currenteftres.model';
import { Eftreturnres } from '../../../shared/model/api/policyeft/eftreturnres.model';
import { Eftstatus } from '../../../shared/model/api/policyeft/eftstatus.model';
import { PolicyEftRequest } from '../../../shared/model/api/policyeft/policyeftreq.model';
import { SelectedPaymentMethod } from '../../../shared/model/payment-method.model';
import { Policynumber } from '../../../shared/model/policynumber';
import { BwappService } from '../../bwapp.service';
import { BWErrorhandler } from '../../errorhandler';

 

@Injectable({
  providedIn: 'root'
})
export class PolicyeftService {

  private globlalConstants = GlobalConstants;
  policyNumber: Policynumber;
  policyEftReq: PolicyEftRequest;

  paymentMethodSelected : PaymentMethodInfo;

  setPaymentMethod(paymentMehtod){
    this.paymentMethodSelected = paymentMehtod;
  }

  getPaymentMethod(){
    return this.paymentMethodSelected ;
  }

  paymentMethod = new Subject<PaymentMethodInfo>();

  constructor(private appService: BwappService, private errorHandler: BWErrorhandler) { }


  /**
    * @param policyNumber
    * @param mco
    */
   getEFTStatus(policynumber: string, mco: string): Observable<Eftstatus> {
     // Tracker.log('Policy Number passed :', policynumber, mco);
    this.policyNumber = new Policynumber(policynumber);
    this.policyEftReq = new PolicyEftRequest();
    this.policyEftReq.policySym = this.policyNumber.symbol;
    this.policyEftReq.policyNum = this.policyNumber.policy;
    this.policyEftReq.policyMod = this.policyNumber.module;
    this.policyEftReq.mco = mco;

    return this.appService.post(this.globlalConstants.EFT_GET_STATUS, this.policyEftReq);
   }

   /**
    * @param policyNumber
    * @param mco
    */
   eftIsEligible(policynumber: string, mco: string, plcyeffdate: string, eftType: string): Observable<Eftreturnres> {
    this.policyNumber = new Policynumber(policynumber);
    this.policyEftReq = new PolicyEftRequest();
    this.policyEftReq.policySym = this.policyNumber.symbol;
    this.policyEftReq.policyNum = this.policyNumber.policy;
    this.policyEftReq.policyMod = this.policyNumber.module;
    this.policyEftReq.mco = mco;
    this.policyEftReq.eftEffDate = plcyeffdate;
    this.policyEftReq.polEffectiveDate = plcyeffdate;
    this.policyEftReq.eftType = eftType;

    return this.appService.post(this.globlalConstants.EFT_IS_ELIGIBLE, this.policyEftReq);
  }


  /**
    * @param policyNumber
    * @param mco
    */
   prepareEFT(fullpolicyInfo: FullPolicyDetails): Observable<Eftreturnres> {

    const policyInfo = fullpolicyInfo.policyInfo;
    const eftInfo = fullpolicyInfo.eftInfo;

    const policyEffDate = formatDate(policyInfo.PolicyEffectiveDate, 'MM/dd/yyyy', 'en-US');

    this.policyNumber = new Policynumber(policyInfo.PolicyNumber);
    this.policyEftReq = new PolicyEftRequest();
    this.policyEftReq.policySym = this.policyNumber.symbol;
    this.policyEftReq.policyNum = this.policyNumber.policy;
    this.policyEftReq.policyMod = this.policyNumber.module;
    this.policyEftReq.mco = policyInfo.MASTER0CO;
    this.policyEftReq.eftEffDate = policyEffDate;
    this.policyEftReq.polEffectiveDate = policyEffDate;
    if (eftInfo) {
    this.policyEftReq.eftType = eftInfo.eftType;
    this.policyEftReq.accountType =  eftInfo.accountType;
    this.policyEftReq.routingNumber = eftInfo.routingNumber;
    this.policyEftReq.accountNumber = eftInfo.accountNum;
    }
    this.policyEftReq.source = GlobalConstants.APPID;
    this.policyEftReq.termReason = '';
    this.policyEftReq.pspAZCorp = '';
    this.policyEftReq.eftProcessedAs = '';
    this.policyEftReq.eftProcessedVendor = '';
    this.policyEftReq.eMove = false;

    // Tracker.log('Prepare EFT Request before passing to backend service ', this.policyEftReq);

    return this.appService.post(this.globlalConstants.EFT_PREPARE, this.policyEftReq);
  }


  /**
    * @param policyNumber
    * @param mco
    */
   voidEFT(fullpolicyInfo: FullPolicyDetails): Observable<Eftreturnres> {
    const policyInfo = fullpolicyInfo.policyInfo;
    const eftInfo = fullpolicyInfo.eftInfo;

    const policyEffDate = formatDate(policyInfo.PolicyEffectiveDate, 'MM/dd/yyyy', 'en-US');

    this.policyNumber = new Policynumber(policyInfo.PolicyNumber);
    this.policyEftReq = new PolicyEftRequest();
    this.policyEftReq.policySym = this.policyNumber.symbol;
    this.policyEftReq.policyNum = this.policyNumber.policy;
    this.policyEftReq.policyMod = this.policyNumber.module;
    this.policyEftReq.mco = policyInfo.MASTER0CO;
    this.policyEftReq.eftEffDate = policyEffDate;
    this.policyEftReq.polEffectiveDate = policyEffDate;
    this.policyEftReq.eftType = eftInfo.eftType;
    // this.policyEftReq.accountType =  eftInfo.accountType;
    // this.policyEftReq.routingNumber = eftInfo.routingNumber;
    // this.policyEftReq.accountNumber = eftInfo.accountNum;
    this.policyEftReq.source = GlobalConstants.APPID;
    this.policyEftReq.termReason = '';
    this.policyEftReq.pspAZCorp = '';
    this.policyEftReq.eftProcessedAs = '';
    this.policyEftReq.eftProcessedVendor = '';
    this.policyEftReq.eMove = false;

    // Tracker.log('VOID EFT Request before passing to backend service ', this.policyEftReq);

    return this.appService.post(this.globlalConstants.EFT_VOID, this.policyEftReq);
  }


  /**
    * @param policyNumber
    * @param mco
    */
   addEFT(fullpolicyInfo: FullPolicyDetails): Observable<Eftreturnres> {
    const policyInfo = fullpolicyInfo.policyInfo;
    const eftInfo = fullpolicyInfo.eftInfo;

    const policyEffDate = formatDate(policyInfo.PolicyEffectiveDate, 'MM/dd/yyyy', 'en-US');

    this.policyNumber = new Policynumber(policyInfo.PolicyNumber);
    this.policyEftReq = new PolicyEftRequest();
    this.policyEftReq.policySym = this.policyNumber.symbol;
    this.policyEftReq.policyNum = this.policyNumber.policy;
    this.policyEftReq.policyMod = this.policyNumber.module;
    this.policyEftReq.mco = policyInfo.MASTER0CO;
    this.policyEftReq.eftEffDate = policyEffDate;
    this.policyEftReq.polEffectiveDate = policyEffDate;
    this.policyEftReq.eftType = eftInfo.eftType;
    this.policyEftReq.accountType =  eftInfo.accountType;
    this.policyEftReq.routingNumber = eftInfo.routingNumber;
    this.policyEftReq.accountNumber = eftInfo.accountNum;
    this.policyEftReq.source = GlobalConstants.APPID;
    this.policyEftReq.termReason = '';
    this.policyEftReq.pspAZCorp = '';
    this.policyEftReq.eftProcessedAs = '';
    this.policyEftReq.eftProcessedVendor = '';
    this.policyEftReq.eMove = false;

    // Tracker.log('ADD EFT Request before passing to backend service ', this.policyEftReq);

    return this.appService.post(this.globlalConstants.EFT_ADD, this.policyEftReq);
  }


  /**
    * @param policyNumber
    * @param mco
    */
   updateEFT(fullpolicyInfo: FullPolicyDetails): Observable<Eftreturnres> {
    const policyInfo = fullpolicyInfo.policyInfo;
    const eftInfo = fullpolicyInfo.eftInfo;

    const policyEffDate = formatDate(policyInfo.PolicyEffectiveDate, 'MM/dd/yyyy', 'en-US');

    this.policyNumber = new Policynumber(policyInfo.PolicyNumber);
    this.policyEftReq = new PolicyEftRequest();
    this.policyEftReq.policySym = this.policyNumber.symbol;
    this.policyEftReq.policyNum = this.policyNumber.policy;
    this.policyEftReq.policyMod = this.policyNumber.module;
    this.policyEftReq.mco = policyInfo.MASTER0CO;
    this.policyEftReq.eftEffDate = policyEffDate;
    this.policyEftReq.polEffectiveDate = policyEffDate;
    this.policyEftReq.eftType = eftInfo.eftType;
    this.policyEftReq.accountType =  eftInfo.accountType;
    this.policyEftReq.routingNumber = eftInfo.routingNumber;
    this.policyEftReq.accountNumber = eftInfo.accountNum;
    this.policyEftReq.source = GlobalConstants.APPID;
    this.policyEftReq.termReason = '';
    this.policyEftReq.pspAZCorp = '';
    this.policyEftReq.eftProcessedAs = '';
    this.policyEftReq.eftProcessedVendor = '';
    this.policyEftReq.eMove = false;

    // Tracker.log('UPDATE EFT Request before passing to backend service ', this.policyEftReq);

    return this.appService.post(this.globlalConstants.EFT_UPDATE, this.policyEftReq);
  }


  /**
    * @param policyNumber
    * @param mco
    */
   terminateEFT(fullpolicyInfo: FullPolicyDetails): Observable<Eftreturnres> {
    const policyInfo = fullpolicyInfo.policyInfo;
    const eftInfo = fullpolicyInfo.eftInfo;

    const policyEffDate = formatDate(policyInfo.PolicyEffectiveDate, 'MM/dd/yyyy', 'en-US');

    this.policyNumber = new Policynumber(policyInfo.PolicyNumber);
    this.policyEftReq = new PolicyEftRequest();
    this.policyEftReq.policySym = this.policyNumber.symbol;
    this.policyEftReq.policyNum = this.policyNumber.policy;
    this.policyEftReq.policyMod = this.policyNumber.module;
    this.policyEftReq.mco = policyInfo.MASTER0CO;
    this.policyEftReq.eftEffDate = policyEffDate;
    this.policyEftReq.polEffectiveDate = policyEffDate;
    this.policyEftReq.eftType = eftInfo.eftType;
    this.policyEftReq.accountType =  eftInfo.accountType;
    this.policyEftReq.routingNumber = eftInfo.routingNumber;
    this.policyEftReq.accountNumber = eftInfo.accountNum;
    this.policyEftReq.source = GlobalConstants.APPID;
    this.policyEftReq.termReason = '';
    this.policyEftReq.pspAZCorp = '';
    this.policyEftReq.eftProcessedAs = '';
    this.policyEftReq.eftProcessedVendor = '';
    this.policyEftReq.eMove = false;

    return this.appService.post(this.globlalConstants.EFT_TERMINATE, this.policyEftReq);
  }


  /**
    * @param policyNumber
    * @param mco
    */
   getCurrentEFT(policynumber: string, mco: string): Observable<Currenteftres> {
    this.policyNumber = new Policynumber(policynumber);
    this.policyEftReq = new PolicyEftRequest();
    this.policyEftReq.policySym = this.policyNumber.symbol;
    this.policyEftReq.policyNum = this.policyNumber.policy;
    this.policyEftReq.policyMod = this.policyNumber.module;
    this.policyEftReq.mco = mco;

    return this.appService.post(this.globlalConstants.EFT_GET_CURRENTEFT, this.policyEftReq);
  }

  getCurrentEFTInfo(policynumber: string, mco: string): Observable<Currenteftinfores> {
    this.policyNumber = new Policynumber(policynumber);
    this.policyEftReq = new PolicyEftRequest();
    this.policyEftReq.policySym = this.policyNumber.symbol;
    this.policyEftReq.policyNum = this.policyNumber.policy;
    this.policyEftReq.policyMod = this.policyNumber.module;
    this.policyEftReq.mco = mco;

    return this.appService.post(this.globlalConstants.EFT_GET_CURRENTEFT_INFO, this.policyEftReq);
  }

  enrollEFTEIS(autoPaymentEnrollmentRequest : any):Observable<AutoPayEnrollResponse>{
    return this.appService.postNew(this.globlalConstants.ENROLL_EFT,autoPaymentEnrollmentRequest);
  }

  updateEFTEIS(autoPaymentEnrollmentRequest : any):Observable<AutoPayEnrollResponse>{
    return this.appService.postNew(this.globlalConstants.UPDATE_EFT,autoPaymentEnrollmentRequest);
  }

  terminateEFTEIS(autoPaymentEnrollmentRequest : any):Observable<AutoPayCancelResponse>{
    return this.appService.postNew(this.globlalConstants.TERMINATE_EFT,autoPaymentEnrollmentRequest);
  }

  getAutoPayDetails(customerId:string):Observable<AutoPayDetailsResponse>{

    return this.appService.get(this.globlalConstants.GET_EFT+customerId,null);
  }

}
