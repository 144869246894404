import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { Tracker } from '../../utils/tracker';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var GoogleAnalyticsService = /** @class */ (function () {
    function GoogleAnalyticsService(router) {
        this.router = router;
    }
    GoogleAnalyticsService.prototype.enableGA = function () {
        return new Promise(function (resolve, reject) {
            if (typeof gtag !== 'undefined') {
                gtag('js', new Date());
                gtag('config', "" + environment.google_UAID, {});
                gtag('config', "" + environment.google_AWID, {});
                resolve(true);
            }
            else {
                resolve(false);
            }
        });
    };
    /**
     * Track an event with custom data in google analytics
     * -
     * @param {string} category Typically the object that was interacted with (e.g. 'Video')
     * @param {string} label The type of interaction (e.g. 'play')
     * @param {string} [action=null] Useful for categorizing events (e.g. 'Fall Campaign')
     * @param {number} [value=null] A numeric value associated with the event (e.g. 42)
     * @memberof GoogleAnalyticsEventService
     */
    GoogleAnalyticsService.prototype.trackEvent = function (category, label, action, value) {
        if (action === void 0) { action = null; }
        if (value === void 0) { value = null; }
        try {
            /* ga('send', 'event', { eventCategory: category, eventLabel: label,
               eventAction: action, eventValue: value
             } );
             */
            gtag('event', action, { 'event_category': category, 'event_label': label });
        }
        catch (error) {
            // Tracker.log(`error: ${error}`);
        }
        // testing
        // Tracker.log(category, label, action, value);
    };
    GoogleAnalyticsService.prototype.gtag_report_conversion = function () {
        /*function callback() {
          if (typeof(url) !== 'undefined') {
           window.location.href = url;
           }
         }*/
        gtag('event', 'conversion', { 'send_to': "" + environment.google_AW_CONVERSION });
        return false;
    };
    GoogleAnalyticsService.prototype.subscribe = function () {
        var _this = this;
        if (!this.subscription) {
            var pagePath_1 = '';
            // subscribe to any router navigation and once it ends, write out the google script notices
            this.subscription = this.router.events.subscribe(function (e) {
                if (e instanceof NavigationEnd) {
                    // this will find & use the ga function pulled via the google scripts
                    try {
                        var splitedUrlArray = e.urlAfterRedirects.split('?');
                        if (splitedUrlArray.length === 2) {
                            // if there are query params
                            pagePath_1 = _this.updateQueryParam(splitedUrlArray);
                        }
                        else {
                            // no query params
                            pagePath_1 = e.urlAfterRedirects;
                        }
                        gtag('config', "" + environment.google_UAID, {
                            'page_path': pagePath_1
                        });
                        gtag('config', "" + environment.google_AWID, { 'page_path': pagePath_1 });
                        // Tracker.log(`logged url: ${e.urlAfterRedirects}`);
                    }
                    catch (e) {
                        Tracker.logerror('GoogleAnalyticsService', 'subscribe', 'subscribe', 'Unable to find the google GA Function', e);
                    }
                }
            });
        }
    };
    // Function that replaces the query parma values for the ploicy num,Lastname and mco
    GoogleAnalyticsService.prototype.updateQueryParam = function (splitedUrlArray) {
        // if there are query params
        var url = splitedUrlArray[0];
        url = url + '?';
        var queryParam = splitedUrlArray[1];
        var params = queryParam.split('&');
        for (var _i = 0, params_1 = params; _i < params_1.length; _i++) {
            var parameter = params_1[_i];
            if (parameter.split('=')[0].toUpperCase() === 'policyNumber'.toUpperCase()) {
                url = url + parameter.split('=')[0] + '=XXXXXXX';
            }
            else if (parameter.split('=')[0].toUpperCase() === 'LastName'.toUpperCase()) {
                url = url + parameter.split('=')[0] + '=XXXXX';
            }
            else if (parameter.split('=')[0].toUpperCase() === 'mco'.toUpperCase()) {
                url = url + parameter.split('=')[0] + '=XX';
            }
            else {
                url = url + parameter;
            }
            // this adds the & between query params
            if (params.indexOf(parameter) < (params.length - 1)) {
                url = url + '&';
            }
        }
        return url;
    };
    GoogleAnalyticsService.prototype.unsubscribe = function () {
        if (this.subscription) {
            // --- clear our observable subscription
            this.subscription.unsubscribe();
        }
    };
    GoogleAnalyticsService.ngInjectableDef = i0.defineInjectable({ factory: function GoogleAnalyticsService_Factory() { return new GoogleAnalyticsService(i0.inject(i1.Router)); }, token: GoogleAnalyticsService, providedIn: "root" });
    return GoogleAnalyticsService;
}());
export { GoogleAnalyticsService };
