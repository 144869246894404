
<!-- this change for loading bdq form when there is a zip in the url else it will load the home -->
<main *ngIf="!showhome" id="main"  class="main pt-0 border-0">
  <div class="container-fluid">
      <div class="col-xl-4 col-lg-5 col-md-7 col-sm-5 col-xs-12">
        <app-landing-tabs></app-landing-tabs>
      </div>
  </div>
</main>

<!-- loads when there is no zip in the url-->
<main *ngIf="showhome" id="main"  class="main pt-0 border-0">
    <div class="container-fluid">
      <!-- <div class="d-none d-sm-none d-md-block text-center mt-4">
          <p class="color-bristol-blue" tabindex="0"><b>Bristol West</b> insurance products are designed 
            with you in mind, to provide a superior combination of insurance coverage and value.
          </p>
      </div> -->
      <div class="text-center">
          <!--Home Page Announcement DIV  -->
          <div class="alert alert-primary alert-dismissible fade show" role="alert" *ngIf="welcomeMessage">
             {{welcomeMessage}}
             <a class="close" data-dismiss="alert" aria-label="Close">
             <span aria-hidden="true" class="align-text-top">&times;</span>
             </a>
          </div>
          <!-- <ngb-alert class="" *ngIf="!this.closed"  [type]="'ngb-custom'" [dismissible] ="true" (close)="this.closed=true">
             <strong>The Bristol West Mobile App </strong> has a new look and feel- here’s a <a target="_blank" href="https://farmersinsurance.box.com/s/887zqxbvyadh0tp7xmfsllu51gboef0l">sneak-peek</a>  of the redesign and new features.
            </ngb-alert> -->
         <!--<ngb-alert class="" *ngIf="!this.closed" [type]="'info'" (close)="this.closed=true" [dismissible]="true">
          <div class="special-heading-6 small">
           The Bristol West Mobile App has a new look and feel- here’s a <a href="https://farmersinsurance.box.com/s/887zqxbvyadh0tp7xmfsllu51gboef0l" target="_blank">sneak-peek</a> of the redesign and new features.
          </div>
          </ngb-alert> -->
       </div>
      <div class="row justify-content-center">
      <div class="col-md-12 landing-bg" id="landing-div">
           <div class="row d-none d-xs-none d-md-block">
              <div class="col-12" style="height: 8vh"></div>
           </div>
          <div class="row" id="land-app-div">
            <div class="col-xl-6 col-lg-6 col-md-4 col-sm-4 col-xs-0"></div>
            <div class="col-xl-4 col-lg-5 col-md-7 col-sm-5 col-xs-12">
              <app-landing-tabs></app-landing-tabs>
            </div>
            <div class="col-xl-2 col-lg-1 col-md-1 col-sm-3 col-xs-0"></div>
          </div>
          <div class="row d-none d-xs-none d-md-block">
              <div class="col-12" style="height: 8vh"></div>
           </div>
        </div>
      </div>
      <!-- <div class="d-sm-block d-md-none d-lg-none d-xl-none mt-2 mx-0 px-0">
        <img src="../../../../assets/img/BW_homepage_header.png">
      </div> -->
      <div class="row d-flex mt-5">
        <div class="col-md-3"></div>
        <div class="col-md-8">
          <!--
          <h2 class="heading-3 landing-title mt-3">Auto Insurance 101</h2>
          <p class="landing-intro">
            Bristol West® offers quality auto insurance at affordable rates for a wide range of drivers. Whether you have
            a less-than-perfect driving record, no prior insurance or traffic violations — even a DUI — our coverage
            options can help you get the coverage you want. We make getting covered as easy as possible.
          </p>
          <p class="landing-intro d-none d-sm-none d-md-block font-weight-bold">You can build your policy with three types of coverage:</p>
          <p class="landing-intro d-md-none d-lg-none d-xl-none d-sm-block font-weight-bold">You can be confident knowing Bristol West auto insurance has coverage for you</p>
  
          <ul class="landing-bullet-text">
            <li class="mb-2">Liability insurance helps cover the cost of the other driver's property and 
              bodily injurues if you're found at fault in an accident.</li>
            <li class="mb-2">Comprehensive insurance provides coverage for physical damage from events that 
              are out of your control, like theft or vandalism and weather events.</li>
            <li class="mb-2">Collision insurance helps cover damage when your vehicle collides with another car or object.</li>
          </ul>
  
          <p class="special-heading d-md-none d-lg-none d-xl-none d-sm-block">More coverage options:</p>
          <ul class="landing-bullet-text d-md-none d-lg-none d-xl-none d-sm-block">
            <li class="mb-2"><span>Uninsured/Underinsured Motorists</span>
            This type of coverage helps protect you if someone else is responsible for an accident you’re in and the other person
            has no insurance or minimal insurance.</li>
  
            <li class="mb-2"><span>Bodily Injury and Property Damage</span>
            This covers your legal responsibility for an accident where someone else is injured or there is damage to someone else’s
            property. The coverage is limited to the amount you select.</li>
            <li class="mb-2"><span>Rental Reimbursement</span>
            If you rent a car after you have a covered loss, we will put up to $50 per day back in your pocket for up to 30 days.</li>
            <li class="mb-2"><span>Medical Payments</span>
            Hopefully you’ll never need it, but this coverage can pay for your medical care due to a car accident (Up to
            $10,000.00).</li>
            <li class="mb-2"><span>Towing and Roadside Service</span>
            If your vehicle needs towing or assistance on the road, we will take care of the cost for you. This applies to each
            disablement on a covered vehicle subject to a maximum limit per policy.</li>
          </ul>
  
          <p class="special-heading d-md-none d-lg-none d-xl-none d-sm-block">Additional features*:</p>
          <ul class="landing-bullet-text d-md-none d-lg-none d-xl-none d-sm-block">
            <li class="mb-2"><span>Full permissive user coverage.</span>
              By providing a driver permission to use your vehicle, they are covered under your vehicle's insurance.</li>
          
            <li class="mb-2"><span>$500 trailer physical damage coverage.</span>
              Liability coverage is provided for a trailer you own that is attached to a covered vehicle.</li>
            <li class="mb-2"><span>Rental car coverage</span>
            If you rent a car, you'll enjoy the same coverage as your personal, Bristol West-insured vehicle.</li>
            <li class="mb-2"><span>Rideshare coverage option</span>
              Extends your personal auto policy limits and coverage if you conduct Rideshare activities, including Uber Eats.</li>
          </ul>
          <p class="text-center d-md-none d-lg-none d-xl-none d-sm-block landing-bullet-text">*Not all policies, discounts, coverages and features</p>
  -->
          <app-login></app-login>
          
        </div>
      </div>
      <div class="mt-4 jumbotron jumbotron-quick-links">
          <div class="row d-flex mt-2 w-100">
              <div class="col-md-3"></div>
              <div class="col-md-6 text-center">
               <h3 class="look-feel-heading-3_5">Quick Links</h3>
               <p class="subtitle-text">No Login Required</p>
              </div>
              <div class="col-md-3"></div>
          </div>
          <div class="row d-flex mt-3 flex-nowrap w-100">
            <div class="col-md-2 col-1"></div>
            <div (click)="addGATags('payment')" class="col-md-2 quick-links" routerLink="/nologin" [queryParams]="{ type:'pay' }" routerLinkActive="active">
              <div class="quick-links-icon d-flex justify-content-center">
                <img class="icon-car-img" src="assets/img/icon_wallet.svg"
                  alt="Make a payment" />
              </div>
              <div class="quick-links-text border-0">
                Make a payment
              </div>
            </div>
            <div class="col-md-2 quick-links" routerLink="/home/claims" routerLinkActive="active">
                <div (click)="addGATags('claims')" class="quick-links-icon d-flex justify-content-center">
                  <img class="icon-car-img" src="assets/img/icon_view_claim.svg"
                    alt="Report or view a claim" />
                </div>
                <div class="quick-links-text border-0">
                  Report/view a claim
                </div>
              </div>
              <div (click)="addGATags('idCard')" class="col-md-2 quick-links" routerLink="/nologin" [queryParams]="{ type:'id' }" routerLinkActive="active">
                  <div class="quick-links-icon d-flex justify-content-center">
                    <img class="icon-car-img" src="assets/img/icon_view_id.svg"
                      alt="View ID" />
                  </div>
                  <div class="quick-links-text border-0">
                    View ID
                  </div>
              </div>
          
          </div>
  
      </div>
  
      <div class="row d-flex mt-3">
          <div class="col-md-3"></div>
          <div class="col-md-6 text-center">
           <h3 tabindex="0" class="look-feel-heading-3_5">Why Bristol West</h3>
           <p tabindex="0" class="subtitle-text font-weight-normal">You can be confident knowing Bristol West
              auto insurance has coverage for you</p>
          </div>
          <div class="col-md-3"></div>
      </div>
        <div class="card-deck row d-flex mt-3">
  
  
          <!-- Affordable -->
          <div class="card border-0">
            <div class="card-body d-flex justify-content-center">
              <img class="icon-car-img" src="assets/img/car-icon.png"
                alt="Affordable, quality coverage" />
            </div>
            <div class="card-footer icon-text bg-color-white border-0">
              Affordable, quality coverage
            </div>
          </div>
        
          <!-- Accessible -->
          <div class="card border-0">
            <div class="card-body d-flex justify-content-center">
              <img class="icon-clock-img" src="assets/img/clock-icon.png"
                alt="24/7 access to your policy" />
            </div>
            <div class="card-footer icon-text bg-color-white border-0">
              24/7 access to your policy
            </div>
          </div>
        
          <!-- Payments -->
          <div class="card border-0">
            <div class="card-body d-flex justify-content-center">
              <img class="icon-cards-img"  src="assets/img/pay-icon.png"
                alt="Flexible payment options" />
            </div>
            <div class="card-footer icon-text bg-color-white border-0">
              Flexible payment options
            </div>
          </div>
        
        </div>
  
    </div>
  </main>