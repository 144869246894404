/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-errors.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ng-bootstrap/ng-bootstrap/alert/alert.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap/alert/alert";
import * as i4 from "@ng-bootstrap/ng-bootstrap/alert/alert-config";
import * as i5 from "@angular/common";
import * as i6 from "../../pipes/safe-html.pipe";
import * as i7 from "@angular/platform-browser";
import * as i8 from "./app-errors.component";
import * as i9 from "../../services/messages.service";
import * as i10 from "@angular/router";
var styles_AppErrorsComponent = [i0.styles];
var RenderType_AppErrorsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppErrorsComponent, data: {} });
export { RenderType_AppErrorsComponent as RenderType_AppErrorsComponent };
function View_AppErrorsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ngb-alert", [["role", "alert"]], null, null, null, i2.View_NgbAlert_0, i2.RenderType_NgbAlert)), i1.ɵdid(1, 49152, null, 0, i3.NgbAlert, [i4.NgbAlertConfig], { dismissible: [0, "dismissible"], type: [1, "type"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 5, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "error-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-exclamation-triangle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0 "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "multi_lines_text text-danger inline-icon padding-left"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.fetchRoutelink($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵppd(7, 1)], function (_ck, _v) { var currVal_0 = false; var currVal_1 = "danger"; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit.errordesc)); _ck(_v, 6, 0, currVal_2); }); }
function View_AppErrorsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppErrorsComponent_3)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.index === (_co.appMessages.length - 1)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_AppErrorsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppErrorsComponent_2)), i1.ɵdid(2, 802816, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.appMessages; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AppErrorsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.SafeHtmlPipe, [i7.DomSanitizer]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppErrorsComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.appMessages && _co.appMessages.length); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AppErrorsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-app-errors", [], null, null, null, View_AppErrorsComponent_0, RenderType_AppErrorsComponent)), i1.ɵdid(1, 114688, null, 0, i8.AppErrorsComponent, [i9.MessagesService, i10.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppErrorsComponentNgFactory = i1.ɵccf("app-app-errors", i8.AppErrorsComponent, View_AppErrorsComponent_Host_0, {}, {}, []);
export { AppErrorsComponentNgFactory as AppErrorsComponentNgFactory };
