export class Mailingaddress {
  street: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  garagingAddress: boolean;

  stage: string;
  isPhoneNumberChanged: boolean;
  isAddressChanged: boolean;
  premiumAmount: string;
  proratedPremium: string;

  bindingNumber: string;
  errorMsg: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
