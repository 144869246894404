
      <div id="modelHeader" class="modal-header">
        <h4 id="modalTitle" class="modal-title">Session Timeout warning</h4>
      </div>
      <div id="modalBody" class="modal-body">
        You will be timed out in
          {{(countMinutes !== 0 ? + countMinutes+' Minute'+(countMinutes > 1 ? 's ' : ' ') : '') + countSeconds+' Seconds'}}
        <p>
          <ngb-progressbar type="danger" [value]="countSeconds" [max]="timeout" animate="false" id="timeoutprogress"
              class="progress-striped active"></ngb-progressbar>
        </p>
      </div>
      <div id="modalFooter" class="modal-footer">
        <button type="button" id="ac90819bu1" class="btn btn-primary" (click)="continue()">Continue</button>
        <button type="button" id="ac90819bu2" class="btn btn-primary" (click)="logout()">Logout</button>
      </div>
  