
export class ValidateEmailReq {
    context: string;
    emailAddress: string;
    policyMCO: string;
    policyModule: string;
    policyNumber: string;
    policySymbol: string;
    producerCode: string;
    requestingProgram: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
