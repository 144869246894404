import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GlobalConstants } from '../../../constants/global.constant';
import { ValidateEmailReq } from '../../../shared/model/api/emailvalidate/emailvalreq.model';
import { EmailValidateResponse } from '../../../shared/model/api/emailvalidate/emailvalres.model';
import { Policynumber } from '../../../shared/model/policynumber';
import { BwappService } from '../../bwapp.service';

@Injectable({
  providedIn: 'root'
})
export class EmailvalidateService {

  private globlalConstants = GlobalConstants;
  policyNumber: Policynumber;

  emailValidateReq: ValidateEmailReq = new ValidateEmailReq();
  emailvalidateRes: EmailValidateResponse;
  bwOktaEnabled: boolean = environment.bwOktaEnabled;

  constructor(private appService: BwappService) { }

  validateEmail(email: string, policynum: string, mco: string, producercode: string): Observable<EmailValidateResponse> {
    this.policyNumber = new Policynumber(policynum);
    this.emailValidateReq.context = `${environment.pointContext}`;
      this.emailValidateReq.emailAddress = email;
      this.emailValidateReq.policyMCO = mco;
      this.emailValidateReq.policySymbol = this.policyNumber.symbol;
      this.emailValidateReq.policyNumber = this.policyNumber.policy;
      this.emailValidateReq.policyModule = this.policyNumber.module;
      this.emailValidateReq.producerCode = producercode;
      this.emailValidateReq.requestingProgram = GlobalConstants.APPID;

    return this.appService.post(this.bwOktaEnabled ? this.globlalConstants.EMAIL_VALIDATEEMAIL_OKTA : this.globlalConstants.EMAIL_VALIDATEEMAIAL, this.emailValidateReq);
  }
}
