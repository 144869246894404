import { HttpParams } from '@angular/common/http';
import { BwappService } from '../../bwapp.service';
import { GlobalConstants } from '../../../constants/global.constant';
import * as i0 from "@angular/core";
import * as i1 from "../../bwapp.service";
var PrivacyoptoutService = /** @class */ (function () {
    function PrivacyoptoutService(appService) {
        this.appService = appService;
        this.globlalConstants = GlobalConstants;
    }
    // opt service calls
    PrivacyoptoutService.prototype.hasOptedprivacyPolicy = function (policysym, policynum) {
        var params = new HttpParams()
            .set('polSym', policysym)
            .set('polNum', policynum);
        return this.appService.get(this.globlalConstants.HAS_OPTTED_PRIVACY, params);
    };
    PrivacyoptoutService.prototype.validatePolicyOptOut = function (policySym, policyNum, state, lastName) {
        var params = new HttpParams()
            .set('szSymbol', policySym)
            .set('szPolicy', policyNum)
            .set('szState', state)
            .set('szLastName', lastName);
        return this.appService.get(this.globlalConstants.VALIDATE_POLICY_OPT, params);
    };
    PrivacyoptoutService.prototype.logPrivacyOptOut = function (policySym, policyNum, state, lastName, firstname, mod, enrollDate, enrolltime) {
        var params = new HttpParams()
            .set('polSym', policySym)
            .set('polNum', policyNum)
            .set('szMOD', mod)
            .set('szLastName', lastName)
            .set('szEnrollDate', enrollDate)
            .set('szEnrollTime', enrolltime)
            .set('szState', state)
            .set('szFirstName', firstname);
        return this.appService.get(this.globlalConstants.LOG_PRIVICY_OPT, params);
    };
    PrivacyoptoutService.ngInjectableDef = i0.defineInjectable({ factory: function PrivacyoptoutService_Factory() { return new PrivacyoptoutService(i0.inject(i1.BwappService)); }, token: PrivacyoptoutService, providedIn: "root" });
    return PrivacyoptoutService;
}());
export { PrivacyoptoutService };
