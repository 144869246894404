import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalConstants } from '../../../constants/global.constant';
import { GoPaperlessService } from '../../../services/api/gopaperless/go-paperless.service';
import { Setepolicyflagreq } from '../../model/api/gopaperless/setepolicyflagreq';
import { PolicyHolder } from '../../model/authentication/authenticatephres';
import { GoogleAnalyticsService } from '../../services/analytics/google-analytics.service';
import { BwstoreService } from '../../services/bwstore.service';
import { CommonUtil } from '../../utils/commonutil';
import { Tracker } from '../../utils/tracker';

@Component({
  selector: 'app-login-gp-terms',
  template: `
  <main id="main" class="main text-center">
  <div class="container">
    <div class="d-flex justify-content-center">
      <div class="col-md-7 justify-content-center">
        <p class="lead mb-2">Accept Go Paperless Terms and Conditions</p>
      </div>
    </div>
    <div class="d-flex justify-content-center mt-3">
      <div class="col-md-10 justify-content-center">
        <div class="mt-3">
          <p>By clicking 'I ACCEPT' below you are agreeing to the
            <a routerLink="/gopaperless-terms" routerLinkActive="active" target="_blank">Go Paperless Terms and Conditions</a>
          </p>
          <p>Acceptance is required to complete your registration.</p>
          <button type="submit" class="btn btn-primary mt-3" (click)="acceptGoPaperless()">I Accept</button>
        </div>
      </div>
    </div>
  </div>
</main>
  `,
  styles: []
})
export class LoginGpTermsComponent implements OnInit {

  authenticatedPhDetails: PolicyHolder;
  setepolicyflagreq: Setepolicyflagreq;

  constructor(private router: Router,
    private _storeService: BwstoreService,
    private _gopaperlessService: GoPaperlessService,
    private googleAnalytics: GoogleAnalyticsService) {
      this.authenticatedPhDetails = this._storeService.getLoginInfoFromStore();
    }

  ngOnInit() {
  }

  acceptGoPaperless() {
    this.googleAnalytics.trackEvent('Login', 'GPConsentScreen', 'GPConsentClick', 200);

    const polsym = CommonUtil.getPolicySymbol(this.authenticatedPhDetails);
    const polNum = CommonUtil.getPolicyNumber(this.authenticatedPhDetails);
    const polMod = this.authenticatedPhDetails.policyMod;

    this.setepolicyflagreq = new Setepolicyflagreq();
    this.setepolicyflagreq.blnMidTermENR = 'false';
    this.setepolicyflagreq.szEmail = this.authenticatedPhDetails.email;
    this.setepolicyflagreq.szMCO = CommonUtil.getMCO(this.authenticatedPhDetails);
    this.setepolicyflagreq.szPolicyNbr = polsym.concat(polNum).concat(polMod);
    this.setepolicyflagreq.szSource = GlobalConstants.APPID;

    this._gopaperlessService.setEPolicyFlag(this.setepolicyflagreq).subscribe(data => {
      // if (data) {
        // Check for esig
        if (this.authenticatedPhDetails.esigstatus) {
          // If they have Docusign then send them to Docusign page
          this.router.navigate(['/esigmessages']);
        } else {
          // If no Docusign then show my policy screen
          this.router.navigate(['/policy']);
        }
      // }
    },
    error => {
      Tracker.logerror('LoginGpTermsComponent', 'acceptGoPaperless', 'setEPolicyFlag',
      'Error setting EPolicy flag in registration gopaperless step', error);
    });
  }

}
