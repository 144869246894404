import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Policynumber } from '../../../shared/model/policynumber';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { BwstoreService } from '../../../shared/services/bwstore.service';
import { Tracker } from '../../../shared/utils/tracker';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {

  
  policyNumber: string;
  paidAmount: string;
  paymentDate;
  cardHolder: string;
  cardNumber: string;
  confirmationNumber: string;
  message: string;
  isCreditCard: boolean;
  constructor(private route: ActivatedRoute, private router: Router, private _storeService: BwstoreService,
    private authService: AuthenticationService) {
  }

  ngOnInit() {
    this.message = '';
    if (this._storeService.getPolicyInfoFromStore().paymentSummary !== null
    && this._storeService.getPolicyInfoFromStore().paymentSummary.amount !== undefined) {
      const policyNum = new Policynumber(this._storeService.getPolicyInfoFromStore().policyInfo.SYMBOL.concat(
        this._storeService.getPolicyInfoFromStore().policyInfo.POLICY0NUM.concat(
          this._storeService.getPolicyInfoFromStore().policyInfo.MODULE
        )
      ));
      this.policyNumber = policyNum.toFormattedString();
      this.paidAmount = this._storeService.getPolicyInfoFromStore().paymentSummary.amount;
      this.paymentDate = Date.now();
      this.cardHolder = this._storeService.getPolicyInfoFromStore().paymentSummary.firstName.concat(' ').concat(
      this._storeService.getPolicyInfoFromStore().paymentSummary.lastName);
      this.cardNumber = this._storeService.getPolicyInfoFromStore().paymentSummary.cardLastFour;
      this.confirmationNumber = this._storeService.getPolicyInfoFromStore().paymentSummary.confirmation;
      this.isCreditCard = this._storeService.getPolicyInfoFromStore().paymentSummary.isCreditCard;

      if (this._storeService.getPolicyInfoFromStore().isNoLogin) {
        // Log out user and clean up session
        Tracker.loginfo('PaymentSuccessComponent', 'ngOnInit', '', 'Removing Without Login for: ' + this.policyNumber);
        this.authService.logout();
        }
    } else {
      this.message = 'Unable to retrieve payment response';
       // Log out user and clean up session
       this.authService.logout();
    }
  }
}
