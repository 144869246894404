import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MessageConstants } from '../../../constants/message.constant';
import { MobileNumberValidator } from '../../validators/mobilenumber.validator';
import { Phonenumberdetails } from '../../model/api/text-alerts/textalertenrollment.model';

@Component({
  selector: 'app-mobile-number-input',
  styleUrls: ['./mobile-number-input.component.scss'],
  template:
  ` <div class="form-row" [formGroup]="form">
          <div class="col">
            <div class="form-group">
              <label for="{{controlName}}">Mobile Phone Number {{count}}</label>
              <input id="{{controlName}}" appMobileNumberinput type="text"
              name="{{controlName}}" class="form-control form-control-lg"
              formControlName="{{controlName}}"
              maxlength="12" [readonly]="phoneValue.phoneStatusCode !== undefined && phoneValue.phoneStatusCode !== 'V'"/>
              <!-- <label *ngIf="phoneValue.phoneStatusDescription!== undefined &&
              phoneValue.phoneStatusDescription!== ''">{{phoneValue.phoneStatusDescription}}</label> -->
              <app-field-error-display [displayError]="isFieldValid(controlName)"
              [errorMsg]="getMobileNumErrMsg(form.controls[controlName].errors)"></app-field-error-display>
            </div>
          </div>
        </div>
  `
})
export class MobileNumberInputComponent implements OnInit {

  @Input() formSubmitAttempt: boolean;
  @Input() form: FormGroup;
  @Input() validate: boolean;
  @Input() controlName: string;
  @Input() count: string;
  @Input() phoneValue: Phonenumberdetails;

  private usermessage = MessageConstants;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {
    // Tracker.log("Child Component PH Policy Number: "+this.policynum);
    if (this.validate) {
      this.form.addControl(this.controlName, new FormControl(this.phoneValue.phoneNumber, Validators.compose(
        [Validators.required, MobileNumberValidator.validateMobileNumber])));
    } else {
      this.form.addControl(this.controlName, new FormControl(this.phoneValue.phoneNumber, Validators.compose(
        [MobileNumberValidator.validateMobileNumber])));
    }
  }

  // Form Utilities
  isFieldValid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  getMobileNumErrMsg(errors) {
    if (errors) {
      if (errors.required === true) {
        return this.usermessage.REQUIRED_MOBILENUMBER;
      } else if ((errors.mobilenum === true)) {
        return this.usermessage.MOBILE_NUMBER_FORMAT;
      }
    }
  }

}
