<main id="main" class="main">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <h1 class="heading-3">Go Paperless Terms & Conditions</h1>

        <p class="font-italic">By enrolling in Go Paperless, you agree to access insurance policy documents electronically 
        in lieu of delivery by the United States Postal Service (USPS) or other physical delivery method; provided, however, 
        that, as required by law, we will deliver certain insurance policy documents, such as cancellation and nonrenewal notices, 
        in paper format via the USPS.</p>

        <p class="font-italic">Please read the following Terms and Conditions carefully. By electing to enroll in Go Paperless, 
        you agree to be bound by them. If you do not agree with any of these terms and conditions, do not enroll in Go Paperless. 
        We reserve the right to modify these Terms and Conditions at any time. Continued participation in Go Paperless will constitute 
        your acceptance of any revisions to the Terms and Conditions.</p>

        <h2 class="heading-5">Hardware and software requirements</h2>
        <p>In order to participate in Go Paperless, you must have: (i) access to a computer capable of connecting to the Internet; 
          (ii) an Internet Service Provider; (iii) a browser capable of viewing our Web site; (iv) an email service account that 
          allows you to read, write, and send email; (v) the ability to use hyperlinks to access other Web sites; and (vi) the 
          ability to view PDF file format. Many updated PDF Readers are available for free download. 
          <a href='http://get.adobe.com/reader/otherversions/' target="_blank">Click here to download a 
          free copy of Adobe Reader.</a></p>

        <p>Should we make changes to the hardware or software requirements for Go Paperless such that you may no longer be capable 
          of accessing your electronic insurance documents, we will inform you of the revised hardware and software requirements. 
          You will then need to review the new requirements and indicate your consent to continue participating in Go Paperless 
          according to the new requirements.</p>

        <h2 class="heading-5">Your responsibilities</h2>
        <p>Once you enroll in Go Paperless, it is your responsibility to inform us of any changes to your e-mail address. It is also 
          your responsibility to keep your e-mail address active and capable of receiving new e-mails. To do this, ensure that your 
          e-mail account has sufficient space for new e-mails and that your e-mail server and spam-blocking software do not block 
          our e-mails. We are not responsible for problems arising from e-mails sent to an inactive or out-of-date e-mail address.

        <h2 class="heading-5">Enrollment in Go Paperless</h2>
        <p>To enroll in Go Paperless, you must successfully complete the Go Paperless authentication and registration process shown 
          in the Go Paperless Customer Summary you received with your policy documents. Shortly after you apply for coverage, you 
          will be sent an authentication e-mail to the e-mail address you provided the Company.</p>
          You must:
          <ul>
            <li>Open the authentication e-mail and click on the authentication link.</li>
            <li>Log in and register on foremost.com using your policy number.</li>
            <li>Click and accept the Terms and Conditions for Go Paperless.</li> 
          </ul>

        <h2 class="heading-5">Undeliverable e-mail</h2>
        <p class="font-weight-bold">Prior to enrollment in Go Paperless:</p>
        <p>If two consecutive “authentication” e-mails are returned as undeliverable before you complete the Go Paperless enrollment 
          process, we will send you written notice via the USPS informing you that the e-mail account you provided us in invalid. 
          You will have until 14 days from the time we process your application to update your account and complete the authentication 
          process. If you do not complete the authentication process by that time, you will not be enrolled in Go Paperless and any 
          discount, if applicable, will be removed.

          <p class="font-weight-bold">After enrollment in Go Paperless:</p>
          <p>If an e-mail is returned to us as undeliverable, we will send you a paper version of the relevant document. If two 
            consecutive e-mails sent to you are returned to us as undeliverable, you will be automatically unenrolled from 
            Go Paperless, and you will receive paper versions of all insurance documents via the USPS. If you are automatically 
            unenrolled from Go Paperless due to e-mails that are returned as undeliverable, any applicable Go Paperless discount will 
            be removed from your policy until you re-enroll in Go Paperless. You can re-enroll in GoPaperless by providing the Company 
            or your producer a corrected, valid e-mail address and completing the enrollment process again.</p>

            <h2 class="heading-5">Insurance documents available electronically</h2>
            <p>There may be some documents that you can not access electronically due to legal and technological constraints in your 
              state. These documents will be delivered to you by your producer or via the USPS.</p>

            <h2 class="heading-5">Accessing policy documents</h2>
            <p>To access your documents, go to foremost.com and log in to your account with the email address and password you 
              provided when you created or updated your account. After you log in, your policy documents will be accessible through your 
              online account. To print any of your policy documents from your online account, click the "print" button located on the 
              page or in your browser frame. To download and save any of your policy documents to your computer, open the insurance 
              policy document by clicking on its hyperlink or icon and follow Adobe Reader's directions for downloading and saving 
              documents.</p>

            <h2 class="heading-5">Unenrolling from Go Paperless</h2>
            <p>You may unenroll from Go Paperless at any time. Upon unenrolling, all insurance documents will be mailed to you via 
              the USPS (please allow up to 72 hours to process your unenrollment). If you choose to unenroll from Go Paperless, 
              any applicable Go Paperless discount will be removed from your policy.</p>
            
              <p class="font-weight-bold">You may unenroll by doing either of the following:</p>
              <ul>
                <li>Logging in to our Web site and following the instructions for unenrolling from Go Paperless.</li>
                <li>Calling your producer or a Customer Service Representative at 888-888-0080.</li>
              </ul>

            <h2 class="heading-5">Requesting documents to be sent via the USPS</h2>
            <p>If you are unable to view or print your documents from our Web site, you may request paper copies of your documents, 
              free of charge, at any time. The paper copy will be mailed to you via the USPS. You may request a paper copy of a 
              document by calling your producer or a Customer Service Representative at 888-888-0080.</p>

            <h2 class="heading-5">State Disclosures</h2>

            <p class="font-weight-bold">Tennessee and Kentucky:</p>
             <p>The policyholder electing to allow for this policy, notices and communication to be sent to the electronic mail address 
             provided by the policyholder should be aware that the insurer rightfully considers this election to be consent by the 
             policyholder that all notices may be sent electronically, including notice of nonrenewal and notice of cancellation. 
             Therefore, the policyholder should be diligent in updating the electronic mail address provided to the insurer in the 
             event that the address should change.</p>

             <p class="font-weight-bold">Georgia:</p>
             <p>I AGREE TO RECEIVE ALL MAILINGS AND COMMUNICATIONS ELECTRONICALLY. SUCH ELECTRONIC MAILING OR COMMUNICATIONS MAY 
               EVEN INCLUDE CANCELLATION OR NONRENEWAL NOTICES.</p>
      </div>
    </div>
  </div>
</main>
