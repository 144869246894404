
      <div class="app-loading">
          <div *ngIf="smallSpinner" class="logoloadSmall"></div>
          <svg *ngIf="smallSpinner" class="spinnerSmall" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
          </svg>
          <div *ngIf="!smallSpinner" class="logoload"></div>
          <svg *ngIf="!smallSpinner" class="spinner" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
          </svg>
        </div>
  