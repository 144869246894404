import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumbersonlyinput]'
})
export class NumbersonlyinputDirective {

  constructor(private el: ElementRef) { }

  @Input('appNumbersonlyinput') appNumbersonlyinput: boolean;
  regexStr = '^[0-9]*$';

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent> event;
    if (this.appNumbersonlyinput) {
      // Tracker.log(event, this.OnlyNumber);
      if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1) {
        return;
      }
      const ch = (e.key);
      const regEx =  new RegExp(this.regexStr);
      if (regEx.test(ch)) {
        return;
      } else {
         e.preventDefault();
      }
    }
  }

}
