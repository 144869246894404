import { OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MessageConstants } from '../../../constants/message.constant';
import { MobileNumberValidator } from '../../validators/mobilenumber.validator';
import { Phonenumberdetails } from '../../model/api/text-alerts/textalertenrollment.model';
var MobileNumberInputComponent = /** @class */ (function () {
    function MobileNumberInputComponent(fb) {
        this.fb = fb;
        this.usermessage = MessageConstants;
    }
    MobileNumberInputComponent.prototype.ngOnInit = function () {
        // Tracker.log("Child Component PH Policy Number: "+this.policynum);
        if (this.validate) {
            this.form.addControl(this.controlName, new FormControl(this.phoneValue.phoneNumber, Validators.compose([Validators.required, MobileNumberValidator.validateMobileNumber])));
        }
        else {
            this.form.addControl(this.controlName, new FormControl(this.phoneValue.phoneNumber, Validators.compose([MobileNumberValidator.validateMobileNumber])));
        }
    };
    // Form Utilities
    MobileNumberInputComponent.prototype.isFieldValid = function (field) {
        return ((!this.form.get(field).valid && this.form.get(field).touched) ||
            (this.form.get(field).untouched && this.formSubmitAttempt));
    };
    MobileNumberInputComponent.prototype.getMobileNumErrMsg = function (errors) {
        if (errors) {
            if (errors.required === true) {
                return this.usermessage.REQUIRED_MOBILENUMBER;
            }
            else if ((errors.mobilenum === true)) {
                return this.usermessage.MOBILE_NUMBER_FORMAT;
            }
        }
    };
    return MobileNumberInputComponent;
}());
export { MobileNumberInputComponent };
