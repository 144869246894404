export class TokenRequest {
    email: string;
    policyNumber: string;
    stateCode: string;
    symbol: string;
    policyMod: string;
    companyCode: string;
    state: string;
    policy: string;
    recipientFirstName: string;
    recipientLastName : string;
    lob: string;
    policyStatus: string;
    policyRoleType: string;
    policyExpirationDate: string;
    policyEffectiveDate: string;
    policyCancellationDate: string;
    agentOfRecordId: string;
    paymentDueAmount: string;
    nextPaymentDueDate: string;
    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
