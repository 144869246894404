import { ChangeDetectorRef, Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { GlobalConstants } from '../../constants/global.constant';
import { MessageConstants } from '../../constants/message.constant';
import { UploadfileService } from '../../services/api/documents/uploadfile.service';
import { EdmrService } from '../../services/api/edmr/edmr.service';
import { BWErrorhandler } from '../../services/errorhandler';
import { FullPolicyDetails } from '../../shared/model/api/bwibic/fullpolicydetails.model';
import { Docuploadauditreq } from '../../shared/model/api/document/docuploadauditreq.model';
import { Docuploadauditres } from '../../shared/model/api/document/docuploadauditres.model';
import { Ezltranscodereq } from '../../shared/model/api/document/ezltranscodereq.model';
import { Ezltranscoderes } from '../../shared/model/api/document/ezltranscoderes.model';
import { Taskinjrecreq } from '../../shared/model/api/document/taskinjrecreq.model';
import { Taskinjrecres, UploadMessage } from '../../shared/model/api/document/taskinjrecres.model';
import { Uploadfiletranstyperes } from '../../shared/model/api/document/uploadfiletranstyperes.model';
import { Savedocres } from '../../shared/model/api/edmr/savedocres.model';
import { Uploadfilereq } from '../../shared/model/api/edmr/uploadfilereq.model';
import { PolicyHolder } from '../../shared/model/authentication/authenticatephres';
import { GoogleAnalyticsService } from '../../shared/services/analytics/google-analytics.service';
import { BwstoreService } from '../../shared/services/bwstore.service';
import { MessagesService } from '../../shared/services/messages.service';
import { CommonUtil } from '../../shared/utils/commonutil';
import { Tracker } from '../../shared/utils/tracker';


@Component({
    selector: 'app-uploaddocs',
    templateUrl: './uploaddocs.component.html',
    styleUrls: ['./uploaddocs.component.scss']
})
export class UploaddocsComponent implements OnInit {
    @Output() responseEmit: EventEmitter<boolean> = new EventEmitter();
    @Input() type;
    authenticatedPhDetails: PolicyHolder;
    basicPolicyDetails: FullPolicyDetails;

    uploadDocForm: FormGroup;
    formSubmitAttempt = false;

    isDataLoaded = false;
    showForm = false;
    isUploading = false;
    uploadText = '';
    acceptedFileTypes: Array<string> = ['image/jpg', 'image/jpeg', 'image/png', 'image/tif', 'image/tiff', 'application/pdf'];
    message: UploadMessage = {
        type: '',
        message: ''
    };
    alertClosed = false;

    docTransactionTypes: Uploadfiletranstyperes;
    saveDocumentReq: Uploadfilereq;
    ezlTranCodeReq: Ezltranscodereq;
    taskInjRecReq: Taskinjrecreq;
    uploadAuditReq: Docuploadauditreq;

    selectedFiles: FileList;
    currentFileUpload: File;
    progress: { percentage: number } = { percentage: 0 };
    defaultType: string;
    @Input() docType: string;
    docTypeDisabled: boolean;

    constructor(public uploadDocsModal: NgbActiveModal, private fb: FormBuilder,
        private _messageService: MessagesService, private _storeService: BwstoreService,
        private uploadFileService: UploadfileService, private edmrService: EdmrService,
        private googleAnalytics: GoogleAnalyticsService,
        public uploadDocModal: NgbActiveModal) {
        this.authenticatedPhDetails = this._storeService.getLoginInfoFromStore();
        this.basicPolicyDetails = this._storeService.getPolicyInfoFromStore();
        if (this.basicPolicyDetails.isDefaultdocumentType) {
            if (this.basicPolicyDetails.isDefaultdocumentType === 'MultiPolicyDiscountStatus') {
                // to show other value in the drop down while navigating from the missiing document
                this.defaultType = 'E2';
                this.docTypeDisabled = true;
            } else {
                // to show Proff of Prior value in the drop down while navigating from the missiing document
                this.defaultType = this.basicPolicyDetails.policyInfo.PolicyType !== 'COMMERCIAL' ? 'O6' : 'K2';
                this.docTypeDisabled = true;
            }
        } else {
            // to show please select whilenavigating from the submit documents
            this.defaultType = '';
        }
        // Reactive form builder for Form Validations
        this.createUploadDocForm();
    }

    ngOnInit() {

        const mco = CommonUtil.getMCO(this.authenticatedPhDetails);
        const statecd = this.authenticatedPhDetails.riskstate;
        this.uploadFileService.getTranasctionTypes(mco, statecd).subscribe((res: Uploadfiletranstyperes) => {
            this.docTransactionTypes = res;
            this.isDataLoaded = true;
            this.showForm = true;
        },
            (error) => {
                this.isDataLoaded = true;
                this.showForm = true;
                Tracker.logerror('UploaddocsComponent', 'ngOnInit()', 'Retrieving Document Transaction Types',
                    'UploadFileService-getTransactionTypes() Failed', error);
            });

    }

    selectFile(event) {
        this.selectedFiles = event.target.files;
        // this.cd.markForCheck();
    }

    uploadDocument(formData: any) {

        this.formSubmitAttempt = true;
        this.message.message = '';
        this.alertClosed = true;
        // alert ('Is FOrm Valid: ' + this.uploadDocForm.valid + '  File: ' + this.selectedFiles.item(0));
        if (this.selectedFiles && this.selectedFiles.item(0)) {
            this.uploadDocForm.controls['uploadfile'].setErrors(null);
        }
        if (this.uploadDocForm.valid) {

            this.currentFileUpload = this.selectedFiles.item(0);
            const selectedFileType = this.currentFileUpload.type;
            const fileSize = Math.round(this.currentFileUpload.size / (1024 * 1024));

            if (this.acceptedFileTypes.indexOf(selectedFileType) === -1) {
                this.message.type = 'danger';
                this.message.message = MessageConstants.UPLOAD_DOC_INVALID_TYPE;
                this.googleAnalytics.trackEvent('PolicyDocuments', 'SubmitDocuments', 'DocumentTypeError', 200);
                return false;
            } else if (fileSize > environment.uploadDocFileSize) {
                this.message.type = 'danger';
                this.message.message = MessageConstants.UPLOAD_DOC_SIZE;
                this.googleAnalytics.trackEvent('PolicyDocuments', 'SubmitDocuments', 'DocumentSizeError', 200);
                return false;
            }

            this.isUploading = true;
            this.progress.percentage = 0;
            this.uploadText = 'Uploading Document...';
            let docType = formData.docType;
            if (this.defaultType && this.defaultType !== '') {
                 docType  = this.defaultType;
            } else {
                 docType = formData.docType;
            }
            const mco = CommonUtil.getMCO(this.authenticatedPhDetails);
            const polsym = CommonUtil.getPolicySymbol(this.authenticatedPhDetails);
            const polNum = CommonUtil.getPolicyNumber(this.authenticatedPhDetails);
            const polMod = this.authenticatedPhDetails.policyMod;

            const agentInfo = this.basicPolicyDetails.agentInfo;
            const policyInfo = this.basicPolicyDetails.policyInfo;

            this.uploadAuditReq = new Docuploadauditreq();

            this.saveDocumentReq = new Uploadfilereq();
            this.saveDocumentReq.policyNumber = polsym.concat(polNum).concat(polMod);
            this.saveDocumentReq.producerCode = agentInfo.AgentCode;
            this.saveDocumentReq.state = this.authenticatedPhDetails.riskstate;
            this.saveDocumentReq.documentType = docType;

            // this.saveDocumentReq.documentContent = this.currentFileUpload;

            this.saveDocumentReq.mailingName = policyInfo.PolicyName;
            this.saveDocumentReq.sourceSystem = GlobalConstants.APPID;
            this.saveDocumentReq.effectiveDate = '';
            /* Uncomment the below code once the file upload woorks
            this.edmrService.uploadDocument(this.saveDocumentReq, this.currentFileUpload).subscribe(event => {
              if (event.type === HttpEventType.UploadProgress) {
                this.progress.percentage = Math.round(100 * event.loaded / event.total);
              } else if (event instanceof HttpResponse) {
              }
            });*/

            // Audit log Object
            this.uploadAuditReq.mco = mco;
            this.uploadAuditReq.symbol = polsym;
            this.uploadAuditReq.policyNumber = polNum;
            this.uploadAuditReq.mod = polMod;
            this.uploadAuditReq.source = GlobalConstants.APPID;
            this.uploadAuditReq.uploadFileName = 'N/A';
            this.uploadAuditReq.uploadFilePath = 'N/A';
            this.uploadAuditReq.transactionCode = docType;

            this.edmrService.uploadDocument(this.saveDocumentReq, this.currentFileUpload).subscribe((saveDocRes: Savedocres) => {

                // 1019= Error in inserting record in EDMR - Your request has been submitted successfully
                // console.log('Upload Document Response ============= ', saveDocRes);

                if (saveDocRes && saveDocRes.status === true && saveDocRes.responseStatus === '1') {

                    this.progress.percentage = 50;

                    this.ezlTranCodeReq = new Ezltranscodereq();
                    this.ezlTranCodeReq.upldMCO = mco;
                    this.ezlTranCodeReq.upldTranCode = docType;
                    this.ezlTranCodeReq.upldSymbol = polsym;
                    this.ezlTranCodeReq.upldPolicy = polNum;
                    this.ezlTranCodeReq.upldMod = polMod;
                    this.ezlTranCodeReq.upldSourceSystem = GlobalConstants.APPID;
                    this.uploadFileService.getEasyLinkTransactionCode(this.ezlTranCodeReq).subscribe((trancoderes: Ezltranscoderes) => {
                        // console.log('Upload Document Ezlink transaction code ============= ', trancoderes);
                        if (trancoderes && trancoderes.easylinkTransactionCodeResult) {

                            this.progress.percentage = 75;
                            this.uploadText = 'Finalizing Upload';

                            this.taskInjRecReq = new Taskinjrecreq();
                            this.taskInjRecReq.edmrSeqNo = saveDocRes.documentSeqno;
                            this.taskInjRecReq.newEZYTranCode = trancoderes.newEZYTranCode;
                            this.taskInjRecReq.upldMCO = mco;
                            this.taskInjRecReq.upldTranCode = docType;
                            this.taskInjRecReq.upldSymbol = polsym;
                            this.taskInjRecReq.upldPolicy = polNum;
                            this.taskInjRecReq.upldMod = polMod;
                            this.taskInjRecReq.upldSourceSystem = GlobalConstants.APPID;
                            this.taskInjRecReq.riskState = this.authenticatedPhDetails.riskstate;
                            this.taskInjRecReq.producerCd = agentInfo.AgentCode;
                            this.taskInjRecReq.transactionCTSrc = 'SYSTEM GENERATED';

                            this.uploadFileService.createTaskInjectorRecord(this.taskInjRecReq).subscribe((taskinjres: Taskinjrecres) => {
                                // console.log('Upload Document task injector response ============= ', taskinjres);
                                this.formSubmitAttempt = false;
                                //  this.uploadDocForm.reset();
                                this.uploadDocForm.controls['docType'].patchValue('');
                                this.uploadDocForm.controls['uploadfile'].patchValue('');
                                this.uploadDocForm.markAsUntouched();

                                this.isUploading = false;
                                this.progress.percentage = 100;
                                this.message.type = 'success';
                                this.message.message = this.basicPolicyDetails.isDefaultdocumentType ?
                                    MessageConstants.UPLOAD_MISSING_DOC_SUCCESS : MessageConstants.UPLOAD_DOC_SUCCESS;
                                if (this.message.type === 'success' && this.basicPolicyDetails.isDefaultdocumentType) {
                                    this.showForm = false;
                                }

                                if (taskinjres && !taskinjres.createTaskInjectorRecordResult) {
                                    // ERROR_10 - Inserting a file in the Easy Link Task Injector Error
                                    // this.isUploading = false;
                                    // this.message.type = 'danger';
                                    // this.message.message = MessageConstants.UPLOAD_DOC_TI_ERROR_10;
                                    this.uploadAuditReq.returnCode = taskinjres.outReturnCode;
                                    this.uploadAuditReq.returnMessage = taskinjres.outReturnMsg;
                                    this.uploadDocumentAudit(this.uploadAuditReq);
                                } else {
                                    this.googleAnalytics.trackEvent('PolicyDocuments', 'SubmitDocuments', ' SubmitDocumentSuccessful', 200);
                                    Tracker.loginfo('UploaddocsComponent', 'uploadDocument', 'Uploading Document',
                                        'Document Upload Success');
                                }
                            }, (error) => {
                                this.googleAnalytics.trackEvent('Error', 'SubmitDocuments', 'TaskInjectorCreatingError', 200);
                                Tracker.logerror('UploaddocsComponent', 'uploadDocument', 'Creating Task Injector Record',
                                    'Error Creating Task Injector Record', error);
                                this.isUploading = false;
                            });
                        } else {
                            this.message.type = 'danger';
                            this.message.message = MessageConstants.UPLOAD_DOC_EZL_ERROR_08;
                            this.isUploading = false;

                            // ERROR_12 - Looking up the Easy Link transaction code Error
                            this.googleAnalytics.trackEvent('PolicyDocuments', 'SubmitDocuments', ' EZYLINKTransCodeError', 200);
                            this.uploadAuditReq.returnCode = trancoderes.outReturnCode;
                            this.uploadAuditReq.returnMessage = trancoderes.outReturnMsg;
                            this.uploadDocumentAudit(this.uploadAuditReq);
                        }
                    }, (error) => {
                        this.googleAnalytics.trackEvent('Error', 'SubmitDocuments', 'RetrievingEzyLinkTaransCodeError', 200);
                        Tracker.logerror('UploaddocsComponent', 'uploadDocument', 'Getting EasyLink Transaction Code',
                            'Error Getting EasyLink Transaction Code', error);
                        this.isUploading = false;
                    });
                    this.responseEmit.emit(true);
                } else {
                    this.responseEmit.emit(false);
                    // ERROR_12 - File is too large to upload Error
                    // ERROR_13 - PDF Conversion Error
                    // ERROR_1017 - Decoding Document bytes error
                    // ERROR_1018 - Uploading document to Amazon S3 server error
                    // ERROR_1003 - Input parameters passed are not valid to be processed error

                    this.message.type = 'danger';
                    this.message.message = MessageConstants.UPLOAD_DOC_FAILURE;
                    this.isUploading = false;

                    this.googleAnalytics.trackEvent('PolicyDocuments', 'SubmitDocuments', 'EDMRSaveDocumentError', 200);
                    this.uploadAuditReq.returnCode = saveDocRes.errorMessageId;
                    this.uploadAuditReq.returnMessage = saveDocRes.messageText;

                    this.uploadDocumentAudit(this.uploadAuditReq);

                }
            }, (error) => {
                this.googleAnalytics.trackEvent('Error', 'SubmitDocuments', 'EDMRSaveDocumentServiceError', 200);
                Tracker.logerror('UploaddocsComponent', 'uploadDocument', 'Uploading Document to EDMR', 'Error Uploading Document', error);
                this.isUploading = false;
            });

        }
    }

    uploadDocumentAudit(uploadAuditReq: Docuploadauditreq) {
        this.uploadFileService.createUploadAuditRecord(uploadAuditReq).subscribe((res: Docuploadauditres) => {
            Tracker.loginfo('UploaddocsComponent', 'uploadDocument', 'Upload Audit Log Creation',
                'Upload Audit Service for message' + uploadAuditReq.returnMessage + ' Status: ' + res.status);
        },
            (error) => {
                Tracker.logerror('UploaddocsComponent', 'uploadDocument', 'Upload Audit Log Creation',
                    'Upload Audit Service Failed for message ' + uploadAuditReq.returnMessage, error);
            });
    }

    createUploadDocForm() {
        this.uploadDocForm = this.fb.group({
            'docType': [{ value: this.defaultType, disabled: this.docTypeDisabled}, Validators.compose([Validators.required])],
            'uploadfile': ['', Validators.compose([Validators.required])]
        });

    }

    // Form validations
    isFieldValid(field: string) {
        if (field === 'uploadfile') {
          if (!this.uploadDocForm.get(field).valid &&  this.formSubmitAttempt ) {
            return true;
          }
        } else {
            return (
                (!this.uploadDocForm.get(field).valid && this.uploadDocForm.get(field).touched) ||
                (this.uploadDocForm.get(field).untouched && this.formSubmitAttempt)
            );
        }
    }
    getErrMessage(errors, formCntrlName: string) {
        return this._messageService.getErrorMessages(errors, formCntrlName);
    }


    cancelUploadDocs() {
        this.responseEmit.emit(false);
        this.uploadDocModal.close();
    }

}
