import { HttpParams } from '@angular/common/http';
import { GlobalConstants } from '../../constants/global.constant';
import { BwappService } from '../bwapp.service';
import { BWErrorhandler } from '../errorhandler';
import * as i0 from "@angular/core";
import * as i1 from "../bwapp.service";
import * as i2 from "../errorhandler";
var VehicleService = /** @class */ (function () {
    function VehicleService(appService, errorHandler) {
        this.appService = appService;
        this.errorHandler = errorHandler;
        this.globlalConstants = GlobalConstants;
    }
    VehicleService.prototype.getVehicleFullDetails = function (policynumber, mco) {
        var params = new HttpParams()
            .set('policyNumber', policynumber)
            .set('mco', mco);
        return this.appService.get(this.globlalConstants.GET_VEHICLEDETAILS, params);
    };
    VehicleService.prototype.getLeinholderInfo = function (policynumber, mco) {
        var params = new HttpParams()
            .set('policyNumber', policynumber)
            .set('mco', mco);
        return this.appService.get(this.globlalConstants.GET_LEINHOLDERDETAILS, params);
    };
    VehicleService.prototype.getVinDupCheckStatus = function (agentCode, vin) {
        var params = new HttpParams()
            .set('agentCode', agentCode)
            .set('vin', vin);
        return this.appService.get(this.globlalConstants.GET_VIN_DUP_CHECK_STATUS, params);
    };
    VehicleService.ngInjectableDef = i0.defineInjectable({ factory: function VehicleService_Factory() { return new VehicleService(i0.inject(i1.BwappService), i0.inject(i2.BWErrorhandler)); }, token: VehicleService, providedIn: "root" });
    return VehicleService;
}());
export { VehicleService };
