import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of , ReplaySubject} from 'rxjs';
import { MessageConstants } from '../../constants/message.constant';
import { ErrMsgMap } from '../maps/err-msg-map';
import { BWError } from '../model/error.model';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  appMessages: BWError[] = [];
  systemMessages: string[] = [];

  loggingMessages: string[] = [];
  elapsedTimes: string[] = [];

  uploadDocumentMsgs: string[] = [];

  // System Messages
  private systemMsgsSubject = new BehaviorSubject<string[]>([]);
  public systemMsgs = this.systemMsgsSubject.asObservable();


  public showbwcom = new BehaviorSubject(true);

  private errMsgMap: ErrMsgMap;

  constructor() {
    this.errMsgMap = new ErrMsgMap();
   }

  addAppMessage(error: BWError) {
    this.appMessages.push(error);
  }

  addSystemMessages(message: string) {
    // Tracker.log('Adding System Error', message);
    this.systemMessages.push(message);

    this.systemMsgsSubject.next(this.systemMessages);
      // Tracker.log('Length of System Error', this.elapsedTimes.length);
  }

  getSystemMessages(): Observable<string[]> {
    /*let promise = new Promise((resolve, reject) => {
      resolve(this.systemMessages);
    });
    return promise;*/
    return of(this.systemMessages);
  }

  getAppMessages(): BWError[] {
    return this.appMessages;
  }

  getErrorMessages(errors, key: string) {
    if (errors) {
      if (errors.required === true) {
        return this.errMsgMap.get(key);
      } else if (errors.maxLength === true) {
        return ''; // this.usermessage.REQUIRED_ACCOUNT_NUMBER;
      } else if (errors.usDate === true) {
        return MessageConstants.FORMAT_DOB;
      }
    }
  }

  clearAppMessages() {
    // Object.assign({}, this.appMessages, []);
    this.appMessages.pop();
  }

  resetApplicationMsgs() {
    if (this.appMessages.length > 0) {
      this.appMessages.splice(0, this.appMessages.length);
    }
  }

  clearSystemMessages() {
    // Tracker.log('Clear System Messages Called..........');
    Object.assign({}, this.systemMessages, []);
    this.systemMessages = [];
  }

  clearAll() {
    // Tracker.log('Clear All Messages Called..........');
    this.appMessages = [];
    this.systemMessages = [];
    this.systemMsgsSubject.next([]);
  }


  addLog(url: string, message: string) {
    this.loggingMessages.push(message);
    // console.log('Timing Message in MessageService: ', url, message);
  }

  clearLog() {
    // Object.assign({}, this.loggingMessages, []);
    this.loggingMessages = [];
  }

  getLog() {
    return this.loggingMessages;
  }

}
