import { Paymentdetails } from '../payment/paymentdetails';
import { RequestToCancelInfo } from '../policyinfo/requesttocancelchecksres.model';
import { CancelAmount } from '../policyinfo/requesttocancelamount.model';
import {  MissingDocument } from '../../../../shared/model/api/missingDocs/missing-document';
export class FullPolicyDetails {
  result: string;
  message: string;
  isNoLogin: boolean;
  policyInfo: PolicyInfo;
  agentInfo: AgentInfo;
  paymentHistoryInfo: PaymentHistoryInfo[];
  paymentInfo: PaymentInfo;
  paymentSummary: Paymentsummary;
  eftInfo: EFTInfo;
  isDefaultdocumentType: string;
  missingDocsRes: MissingDocument;
  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}


export class PaymentInfo {
  paymentMoniker: string;
  paymentType: string;
  paymentVendor: string;
  webpayStatus: number;
  allowCCPayment: boolean;
  eftIndicator: boolean;
  installmentInfo: Paymentdetails[];
  firstName: string;
  lastName: string;
  minimumPayment: string;
  maximumPayment: string;
  totalPremium: string;
  lastPayAmount: string;
  policyBalance: string;
  lastPayDate: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  expirationYear: string;
  expirationMonth: string;
  cvc: string;
  homePhoneNumber: string;
  masterCompany: string;
  paymentDueDate: string;

  constructor(values: Object = {}) {
      Object.assign(this, values);
    }
}

export class Paymentsummary {
  amount: string;
  firstName: string;
  lastName: string;
  cardLastFour: string;
  achLastFour: string;
  confirmation: string;
  isCreditCard: boolean;

  constructor(values: Object = {}) {
      Object.assign(this, values);
    }
}

export class PaymentHistoryInfo {
  Sequence: string;
  PaymentActivityDate: string;
  PaymentDate: string;
  TransactionType: string;
  Installment: string;
  HistoryOrder: string;
  PolicyType: string;
  PaymentDueDate: string;
  PaymentAmount: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class EFTInfo {
  accountType: string;
  eftType: string;
  accountNum: string;
  routingNumber: string; // This will be the Card Expiry Date in case of CC or DC
  confirmationPage: string;
  confirmationNumber: string;
  isEligible: boolean;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}


export class PolicyInfo {
  D0GQVA: string;
  D0GSVA: string;
  ADD0LINE01: string;
  D0EXPDTE: string;
  D0GUVA: string;
  ADD0LINE03: string;
  ADD0LINE04: string;
  PolicyMasterCompany: string;
  EXP0DA: string;
  D0POLAGY: string;
  SYMBOL: string;
  D0NXTNTC: string;
  DueDate: string;
  LINE0BUS: string;
  PolicyPremium: string;
  PolicyType: string;
  EXP0YR: string;
  D0POLTRN: string;
  D0NEXTDT: string;
  EXP0MO: string;
  BADZST: string;
  PolicyCancelDate: string;
  PolicyNumber: string;
  D0EFFDTE: string;
  Balance: string;
  PolicyPhone: string;
  CUST0NO: string;
  PolicyAddress: string;
  PolicyEffectiveDate: string;
  D0GTVA: string;
  D0GVVA: string;
  EFF0DA: string;
  PolicyCompany: string;
  RISK0STATE: string;
  D0CANDT: string;
  Location: string;
  BACZST: string;
  TOT0AG0PRM: string;
  PolicyRateBook: string;
  AmountDue: string;
  PolicyCity: string;
  D0DVST: string;
  PolicyExpireDate: string;
  PolicyState: string;
  PolicyAgentCode: string;
  EFF0YR: string;
  PolicyStatus: string;
  PolicyStatusEPC:string;
  POLICY0NUM: string;
  MODULE: string;
  EFF0MO: string;
  ZIP0POST: string;
  PolicyZip: string;
  COMPANY0NO: string;
  MASTER0CO: string;
  BAA3VA: string;
  PolicyName: string;
  D0CURNOT: string;
  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class AgentInfo {

  AgentCityState: string;
  AgentName1: string;
  AgentName2: string;
  ACNM_ZIP: string;
  ACNM_ADDRS: string;
  ACNM_NAME1: string;
  ACNM_TELE: string;
  AgentPhone: string;
  ACNM_NAME2: string;
  ACNM_CTYST: string;
  AGNM_GRP: string;
  AgentAddress: string;
  AgentCode: string;
  AgentMCO: string;
  AGNM_AGCY: string;
  AgentZip: string;
  AgentType: string;
  AGNM_MCO: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
