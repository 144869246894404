import { environment } from '../../../../environments/environment';
import { GlobalConstants } from '../../../constants/global.constant';
import { ValidateEmailReq } from '../../../shared/model/api/emailvalidate/emailvalreq.model';
import { Policynumber } from '../../../shared/model/policynumber';
import { BwappService } from '../../bwapp.service';
import * as i0 from "@angular/core";
import * as i1 from "../../bwapp.service";
var EmailvalidateService = /** @class */ (function () {
    function EmailvalidateService(appService) {
        this.appService = appService;
        this.globlalConstants = GlobalConstants;
        this.emailValidateReq = new ValidateEmailReq();
        this.bwOktaEnabled = environment.bwOktaEnabled;
    }
    EmailvalidateService.prototype.validateEmail = function (email, policynum, mco, producercode) {
        this.policyNumber = new Policynumber(policynum);
        this.emailValidateReq.context = "" + environment.pointContext;
        this.emailValidateReq.emailAddress = email;
        this.emailValidateReq.policyMCO = mco;
        this.emailValidateReq.policySymbol = this.policyNumber.symbol;
        this.emailValidateReq.policyNumber = this.policyNumber.policy;
        this.emailValidateReq.policyModule = this.policyNumber.module;
        this.emailValidateReq.producerCode = producercode;
        this.emailValidateReq.requestingProgram = GlobalConstants.APPID;
        return this.appService.post(this.bwOktaEnabled ? this.globlalConstants.EMAIL_VALIDATEEMAIL_OKTA : this.globlalConstants.EMAIL_VALIDATEEMAIAL, this.emailValidateReq);
    };
    EmailvalidateService.ngInjectableDef = i0.defineInjectable({ factory: function EmailvalidateService_Factory() { return new EmailvalidateService(i0.inject(i1.BwappService)); }, token: EmailvalidateService, providedIn: "root" });
    return EmailvalidateService;
}());
export { EmailvalidateService };
