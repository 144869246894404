/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./field-error-display.component";
var styles_FieldErrorDisplayComponent = [".error-msg[_ngcontent-%COMP%] {\n      font-size: 12px;\n      color: #a94442;\n      font-weight: bold;\n    }\n\n    .fix-error-icon[_ngcontent-%COMP%] {\n      top: 27px;\n    }"];
var RenderType_FieldErrorDisplayComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_FieldErrorDisplayComponent, data: {} });
export { RenderType_FieldErrorDisplayComponent as RenderType_FieldErrorDisplayComponent };
function View_FieldErrorDisplayComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["(error)"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "error-msg"], ["id", "elemID"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-exclamation-triangle"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMsg; _ck(_v, 5, 0, currVal_0); }); }
export function View_FieldErrorDisplayComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_FieldErrorDisplayComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.displayError && _co.errorMsg); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FieldErrorDisplayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-field-error-display", [], null, null, null, View_FieldErrorDisplayComponent_0, RenderType_FieldErrorDisplayComponent)), i0.ɵdid(1, 114688, null, 0, i2.FieldErrorDisplayComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FieldErrorDisplayComponentNgFactory = i0.ɵccf("app-field-error-display", i2.FieldErrorDisplayComponent, View_FieldErrorDisplayComponent_Host_0, { errorMsg: "errorMsg", elemID: "elemID", displayError: "displayError" }, {}, []);
export { FieldErrorDisplayComponentNgFactory as FieldErrorDisplayComponentNgFactory };
