import * as BwLoginActions from '../actions/bwlogin.actions';
import { PolicyHolder } from '../../shared/model/authentication/authenticatephres';
var defaultLoginState = new PolicyHolder();
var removeLoginState = {
    email: '',
    jwtToken: '',
    pin: '',
    pointHookID: '',
    policyMod: '',
    firstName: '',
    lastName: '',
    fullName: '',
    dob: '',
    phone: '',
    question1: '',
    question2: '',
    question3: '',
    sessionTicket: '',
    userId: '',
    riskstate: '',
    processedDocId: '',
    docuSignURL: '',
    esigstatus: false,
    eftStatus: '',
    gopaperlessInd: false,
    gopaperlessEnrollment: '',
    textAlertsEnrollment: '',
    requestToCanceEligible: '',
    textAlertStateEligible: '',
    textAlertEnrolledPhones: [],
    IsGaragingAddressDifferent: 'false',
    IsAgentEligibleForEndorsement: '',
    IsNNOPolicy: false,
    isRatebookActive: false,
    SR22FR44Status: '',
    hasRenewal: false,
    IsUpdateAddressEligible: '',
    IsReplaceVehicleEligible: '',
    IsAddVehicleEligible: '',
    IsRemoveVehicleEligible: '',
    IsAddDriverEligible: '',
    HasUnderwritingAlerts: false,
    HasCDWCoverage: false,
    HasUMPDCoverage: false,
    HasMissingDocuments: false,
    stateMIHasEnvelope: false,
    renewalPolEffDate: '',
    HasAutoLoanLeaseCoverage: false,
    HasUIMPDCoverage: false,
    isReinstate: false,
    reInstateDueDate: '',
    reInstateDueamount: '',
    reInstatePolicyStatus: '',
};
// Helper to create new state object as the state is immutable
var newState = function (state, newDataObj) {
    return Object.assign({}, state, newDataObj);
};
var ɵ0 = newState;
export function BwLoginReducer(state, action) {
    if (state === void 0) { state = defaultLoginState; }
    switch (action.type) {
        case BwLoginActions.CREATE_LOGININFO:
            // Tracker.loginfo('BwLoginReducer', 'BwLoginReducer()', 'This is the STORE: Adding to Store',
            //         'Action Type:' + action.type + ' Payload' + JSON.stringify(action.payload));
            return newState(state, action.payload);
        case BwLoginActions.UPDATE_LOGININFO:
            return newState(state, action.payload);
        case BwLoginActions.REMOVE_LOGININFO:
            // Tracker.loginfo('BwLoginReducer', 'BwLoginReducer()', 'This is the STORE: Deleting from Store',
            //           'Action Type:' + action.type + ' Payload' + JSON.stringify(action.payload));
            return newState(state, removeLoginState);
        default:
            return state;
    }
}
export { ɵ0 };
