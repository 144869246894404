import { Directive, ElementRef, Input, HostListener , Output, EventEmitter} from '@angular/core';
import { CommonUtil } from '../utils/commonutil';

@Directive({
  selector: '[appTooltipClose]'
})
export class TooltipCloseDirective {
  @Input('appTooltipClose') appTooltipClose: any;
  constructor(private _elementRef: ElementRef) {
  }

  @Output()
  public clickOutside = new EventEmitter();
  @HostListener('document:touchend', ['$event.target'])
  public onClick(targetElement) {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (CommonUtil.findDeviceVIew().isIOS()) {
      if (!clickedInside) {
        if (this.appTooltipClose.isOpen()) {
          this.appTooltipClose.close();
        }
      }
    }
  }
}
