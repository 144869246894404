<main id="main" class="main">
  <div class="container">
    <div class="row mb-3 d-flex justify-content-center border-0">
      <div class="col-md-10 col-sm-12">
        <app-app-errors *ngIf="this.errorFlag === true"></app-app-errors>
      </div>
    </div>
    <div class="row d-flex mb-3 justify-content-center">
      <div class="col-md-7 col-sm-10 pb-5">
        <h1 class="heading-3 mt-4">User Verification</h1>
              
        <div class="text-center"><ngb-alert *ngIf="this.errorMessage !== ''" 
         [type]="'danger'" [dismissible]="false">{{ this.errorMessage }}</ngb-alert></div>
          <form *ngIf="!this.showRegistrationMessage" [formGroup]="mfaForm">
            <label class="mt-4 mb-3 infoLabel">Please answer the security question below for verification</label><br/>
            <label class="questionText">{{ this.currentsecquestion }}</label>
            <input type="text" name="answer" class="form-control" formControlName="answer" aria-label="Please answer the security question below for verification"/>
            <app-field-error-display [displayError]="isFieldValid('answer')" [errorMsg]="getErrMsg(mfaForm.controls['answer'].errors)"></app-field-error-display>
            <button *ngIf="loaded" type="submit" class="btn btn-lg btn-primary mt-3 mb-3 float-right" (click)="onSubmit(mfaForm.value)">Submit</button>
            <button *ngIf="!loaded" class="btn btn-lg btn-primary mt-3 mb-3 float-right" disabled="true"><i class="fas fa-circle-notch fa-spin"></i>&nbsp;Submit</button>
            <br/> 
            <div *ngIf="this.questionNumber &lt; 2"><a href="javascript:void(0);" (click)="showNextQuestion(mfaForm.value)">Ask me another question</a></div>
          </form>
              
        <div *ngIf="this.showRegistrationMessage" class="col-md-12 col-sm-12 pb-5">
          <p class="mt-4 titleText">We were unable to verify you. Please click
            <a routerLink="/register" routerLinkActive="active" (click) = "removePolicyHolder()">here</a> to register.
          </p>
        </div>
      </div>
            
    </div>
  </div>
</main>
