
  <main id="mypolicycomponent" class="submain" *ngIf="closeBanner">
  <div class="container-fluid">
  <div class="mobile-app-banner row">
    <div class="pl-4 banner col-10 pr-0" (click)="FindMobileversion()">
      <img class="logo" src="assets/img/favicons/banner img.jpg" alt="Mobile Banner" />
      <span> View in the Bristol West app</span>
    </div>
    <div class="banner-close col-2 pl-0 pr-3">
      <span class="close-icon" (click)="closeBanner = !closeBanner"><i class="fa fa-times" aria-hidden="true"></i></span>
    </div>
  </div>
  </div>
  </main>
  