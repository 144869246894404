import { environment } from '../../environments/environment';
// Placeholder for all Global contants
var GlobalConstants = /** @class */ (function () {
    function GlobalConstants() {
    }
    Object.defineProperty(GlobalConstants, "baseURL", {
        // read from prop - vary by environment
        get: function () { return 'http://localhost:4200/api'; },
        enumerable: true,
        configurable: true
    });
    // APPLICATION
    GlobalConstants.APPID = 'BWC';
    GlobalConstants.APPID_OLD = 'BWM';
    GlobalConstants.TRANSCODE = 'RO';
    GlobalConstants.EDMR_SRC_SYSTEM = 'DAP';
    GlobalConstants.TFC_SRC_SYSTEM = '21CREN';
    GlobalConstants.SERVICE_OPS_NUMBER = '1-888-888-0080';
    GlobalConstants.POLICY_EMAIL_TYPE_REG_GP = 'G';
    GlobalConstants.POLICY_EMAIL_TYPE_REG_EMAIL = 'N';
    GlobalConstants.POLICY_EMAIL_REQ_TYPE = 'E';
    GlobalConstants.LOB_AUTO = 'APV';
    GlobalConstants.PH_VALIDATE_URL = 'validateNewUser';
    GlobalConstants.PH_INSERT_URL = 'api/phinsert';
    GlobalConstants.PH_AUTH_URL = 'authenticatePolicyHolder';
    GlobalConstants.PH_SECURITY_TOKEN_URL = 'getSecurityToken';
    GlobalConstants.PH_FUTURE_PAYMENTS = 'getFuturePayments';
    // Endorsement Constants
    GlobalConstants.EN_REPL_VEH_TYPE = '8';
    GlobalConstants.EN_REPL_VEH_TYPE_CD = 'RPV';
    GlobalConstants.EN_REPL_VEH_TYPE_DESC = 'Replace Vehicle';
    GlobalConstants.EN_ADD_VEH_TYPE = '1';
    GlobalConstants.EN_ADD_VEH_TYPE_CD = 'ADV';
    GlobalConstants.EN_ADD_VEH_TYPE_DESC = 'Add Vehicle';
    GlobalConstants.EN_REM_VEH_TYPE = '7';
    GlobalConstants.EN_REM_VEH_TYPE_CD = 'RMV';
    GlobalConstants.EN_REM_VEH_TYPE_DESC = 'Remove Vehicle';
    GlobalConstants.EN_POLICY_INTEREST_TYPE = '9';
    GlobalConstants.TAB_KEY_CODE = 9;
    GlobalConstants.ENTER_KEY_CODE = 13;
    // GOPAPERLESS API
    GlobalConstants.GP_AUTO_AUTH_URL = 'getAutoAuthValues';
    GlobalConstants.GP_UPDATE_EMAIL_URL = 'updateEpolicyEmail';
    GlobalConstants.GP_UNENROLL_URL = 'unEnrollEpolicy';
    GlobalConstants.IS_GPL_ROLLED_OUT_URL = 'isGPLRolledOut';
    GlobalConstants.GP_ENROLL_URL = 'createEpolicyCustomer';
    GlobalConstants.GP_SET_EPOLICY_FLAG = 'setEPolAuthFlag';
    GlobalConstants.GP_STATUS_V = 'V';
    GlobalConstants.GP_STATUS_V_DESC = 'Enrolled';
    GlobalConstants.GP_STATUS_R = 'R';
    GlobalConstants.GP_STATUS_R_DESC = 'Not Enrolled';
    GlobalConstants.GP_STATUS_P = 'P';
    GlobalConstants.GP_STATUS_P_DESC = 'Pending Enrollment';
    // CHAT API
    GlobalConstants.CHAT_GET_ID = 'getChatId';
    GlobalConstants.CHAT_GENERATE_TOKEN = 'generatechattoken';
    GlobalConstants.CHAT_GENERATE_PARAM_RECORD = 'generatechatparamrecord';
    // DIRECT SALES - BDQ
    GlobalConstants.GET_STATE_BY_ZIPCODE = 'getstatebyzipcode'; // keerthana
    GlobalConstants.GET_DIRECTSALES_DTLS = 'getDirectSalesVendorDtls';
    GlobalConstants.AUDIT_LOG_REQ_SYMBOL = 'BDQ';
    GlobalConstants.AUDIT_LOG_REQ_POLICYNUM = '9999999';
    GlobalConstants.AUDIT_LOG_REQ_MOD = '00';
    GlobalConstants.AUDIT_LOG__REQ_MCO = '0';
    // BWSECURITY API
    GlobalConstants.BWSEC_CHANGE_EMAIL_URL = 'changeUserEmail';
    GlobalConstants.BWSEC_CHANGE_EMAIL_IN_STAGING_URL = 'changeUserEmailInStaging';
    GlobalConstants.BWSEC_ADD_USER_SSO_URL = 'addNewUserSSO';
    GlobalConstants.BWSEC_ADD_USER_SSO_URL_OKTA = 'addNewUserSSO?bwoktaEnabled=true';
    GlobalConstants.BWSEC_VALIDATENEWUSERPOINT = 'validateNewUserPoint';
    GlobalConstants.BWSEC_GET_POLICY_BY_EMAIL_URL = 'getPolicyByEmail';
    GlobalConstants.BWSEC_CHECK_REGISTRATION_STATUS = 'checkRegistrationStatus';
    GlobalConstants.BWSEC_GETPOLICYLOCATIONSTATUSANDMAXMOD = 'getPolicyLocationStatusandMaxMod';
    GlobalConstants.BW_OKTA_ENABLED_PARAM_KEY = 'bwoktaEnabled';
    GlobalConstants.BW_OKTA_ENABLED_PARAM_VALUE = 'true';
    // BWQUERIES API
    GlobalConstants.BWQRY_IS_CANCELLED_URL = 'isCancelledPolicy';
    GlobalConstants.BWQRY_GETPOLICYBASICCONTRACT = 'getPolicyBasicContract';
    GlobalConstants.BWQRY_GETPOLICYCURRENTMOD = 'getPolicyCurrentMod';
    GlobalConstants.BWQRY_GETPOLICYLOCATIONSTATUSANDMAXMOD = 'getPolicyLocationStatusAndMaxMod';
    GlobalConstants.BWQRY_GETPOLICYFEE = 'getPolicyFee';
    GlobalConstants.BWQRY_GETAGENTLIST = 'getAgents';
    GlobalConstants.BWQRY_IS_21CRENEWALOFFER = 'is21stPolicyRenewalOffer';
    GlobalConstants.BWQRY_IS_21CESIGELIGIBLE = 'is21stPolicyESigEligible';
    GlobalConstants.BWQRY_IS_WETSIGNED = 'hasBeenWetSigned';
    GlobalConstants.BWQRY_HAS_PAYMENTMADEOREFT = 'hasMadePaymentOrEFT';
    // BWIBIC API
    GlobalConstants.BWIBIC_GETALLPOLICYINFO = 'getAllPolicyInfo';
    // BWUTILITY API
    GlobalConstants.BWUTIL_SAVEFEEDBACK = 'saveFeedback';
    // ESIGN API
    GlobalConstants.ESIG_ISESIGIMPLEMENTED = 'isESigImplemented';
    GlobalConstants.ESIG_GETENVELOPES = 'getEnvelops';
    GlobalConstants.ESIG_GETCEREMONY_URL = 'getDocuSignURL';
    GlobalConstants.ESIG_CREATEENVELOPEANDWAITFORVENDOR = 'createEnvelopeAndWaitForVendor';
    GlobalConstants.ESIG_CREATEEMBEDDEDENVELOPE = 'createEmbeddedEnvelope';
    GlobalConstants.ESIG_GET_ELIGIBLE_STATUS = 'getEsignEligibilityStatus';
    // EMAILVALIDATE API
    GlobalConstants.EMAIL_VALIDATEEMAIAL = 'validateEmail';
    GlobalConstants.EMAIL_VALIDATEEMAIL_OKTA = 'validateEmail?bwoktaEnabled=true';
    // EDMR API
    GlobalConstants.EDMR_INVOKEDOCUMENTS = 'invokeEDMRDocuments';
    GlobalConstants.EDMR_GETDOCUMENT = 'getDocument';
    GlobalConstants.EDMR_GETPOLICYCONTRACT = 'getplcycontractbytes';
    GlobalConstants.UPLOAD_DOCUMENT = 'saveDocument';
    // POLICY DETAILS REST API
    GlobalConstants.GET_POLICYCOVERAGES = 'getPolicyCoverages';
    GlobalConstants.GET_VEHICLEDETAILS = 'getVehicleFullDetails';
    GlobalConstants.GET_DRIVERDETAILS = 'getDriverDetails';
    GlobalConstants.GET_VIOLATIONDETAILS = 'getViolationDetails';
    GlobalConstants.GET_DISCOUNTDETAILS = 'getDiscountDetails';
    GlobalConstants.GET_LEINHOLDERDETAILS = 'getLeinholderDetails';
    GlobalConstants.GET_POLICYHOLDERINFO = 'getPolicyHolderInfo';
    GlobalConstants.GET_CANCELPOLICYCHECKS = 'getRequestToCancelChecks';
    GlobalConstants.GET_CANCELPOLICYAMOUNT = 'getCancelAmount';
    GlobalConstants.GET_CANCEL_CREATE_EDMR_RECORD = 'createPrintRecodInEDMR';
    GlobalConstants.GET_VIN_DUP_CHECK_STATUS = 'getVinDupCheckStatus';
    GlobalConstants.AUDIT_LOG_CNX = 'CNX';
    GlobalConstants.AUDIT_LOG_RQC_MSG = 'Request to cancel completed';
    // ADDRESS TOOLS SERVICE API
    GlobalConstants.SCRUB_ADDRESS = 'scrubAddress';
    // PAYMENTS MOBILE API
    GlobalConstants.GET_VALIDATEPOLICY = 'getValidatePolicy';
    GlobalConstants.GET_VALIDATEPOLICYDETAILS = 'getValidatePolicyDetails';
    GlobalConstants.GET_PAYMENTSCHEDULE = 'getPaymentSchedule';
    GlobalConstants.GET_BANK_DETAILS = 'getbankdetail';
    GlobalConstants.GET_PAYMENT_PROFILES = 'payment-profiles';
    // IVR PAYMENTS SERVICE API
    GlobalConstants.PREVIOUS_PAYMENT = 'processPreviousPayment';
    GlobalConstants.CC_PAYMENT = 'processCC';
    GlobalConstants.ACH_PAYMENT = 'processACH';
    GlobalConstants.VALIDATE_CARDNUMBER = 'validateCardNumber';
    // DEBITCARD PAYMENT SERVICE API
    GlobalConstants.DBCARD_PROCESS_INSTALLEMENT = 'processCardInstlmntPayment';
    // DOCUMENTS API
    GlobalConstants.PROCESS_IDCARD = 'processidcard';
    GlobalConstants.GET_POLICYDOCUMENTS = 'getpolicydocuments';
    GlobalConstants.GET_POLICY_CONTRACT = 'getplcycontractdoc';
    GlobalConstants.GET_DOCS_TYPES = 'getTransactionTypesByStateAbbr';
    GlobalConstants.GET_EZL_TRAN_CODE = 'getEasylinkTransactionCode';
    GlobalConstants.CREATE_TASKINJ_REC = 'createTaskInjectorRecord';
    GlobalConstants.CREATE_UPLOAD_AUDIT = 'documentUploadAuditRecord';
    // MissingDocds API
    GlobalConstants.MISSING_DOCS_API = 'getMissingdocs';
    // POLICY EFT SERVICE API
    GlobalConstants.EFT_GET_STATUS = 'eftstatus';
    GlobalConstants.EFT_IS_ELIGIBLE = 'isEligible';
    GlobalConstants.EFT_PREPARE = 'prepareEFT';
    GlobalConstants.EFT_VOID = 'voidEFTEndorsement';
    GlobalConstants.EFT_ADD = 'addEFT';
    GlobalConstants.EFT_UPDATE = 'updateEFT';
    GlobalConstants.EFT_TERMINATE = 'terminateEFT';
    GlobalConstants.EFT_GET_CURRENTEFT = 'getCurrentEFT';
    GlobalConstants.EFT_GET_CURRENTEFT_INFO = 'getCurrentEFTInfo';
    GlobalConstants.STATECD_04 = '04';
    GlobalConstants.STATECD_52 = '52';
    GlobalConstants.STATE_CA = 'CA';
    GlobalConstants.STATE_HI = 'HI';
    GlobalConstants.MCO_90 = '90';
    GlobalConstants.MCO_91 = '91';
    GlobalConstants.MCO_92 = '92';
    GlobalConstants.MCO_44 = '44';
    GlobalConstants.EFT_ENROLLED = 'Active';
    GlobalConstants.EFT_NOT_ENROLLED = 'Not Enrolled';
    GlobalConstants.EFT_TERMINATED = 'Terminated';
    GlobalConstants.EFT_PENDING_ACTIVATION = 'Pending Activation';
    GlobalConstants.EFT_PENDING_UPDATE = 'Pending Update';
    GlobalConstants.EFT_TERMINATION_PENDING = 'Pending Termination';
    GlobalConstants.EFT_TYPE_ADD = 'ADD';
    GlobalConstants.EFT_TYPE_UPDATE = 'CHG';
    GlobalConstants.EFT_TYPE_TERMINATE = 'TRM';
    GlobalConstants.EFT_ACC_TYPE_CHK = 'CHK';
    GlobalConstants.EFT_ACC_TYPE_SAV = 'SAV';
    GlobalConstants.EFT_ACC_TYPE_CC = 'CC';
    GlobalConstants.EFT_ACC_TYPE_DC = 'DC';
    // POLICY MGMT SERVICE API
    GlobalConstants.CREATE_RTAFORM = 'createRTAForm';
    GlobalConstants.PLCY_MGMT_ADD_COMMENT = 'addComment';
    GlobalConstants.PLCY_MGMT_POLICY_MAIL = 'policyMail';
    GlobalConstants.PLCY_MGMT_AUTH_FOR_IDCARD = 'verifyPolicyForIDCards';
    GlobalConstants.PLCY_MGMT_CRT_WEBSSN_LOG = 'createWebSessionLogRecord';
    GlobalConstants.PLCY_MGMT_GET_MFA_DATE = 'getmfadate';
    GlobalConstants.PLCY_MGMT_UPDATE_MFA_DATE = 'updatemfadate';
    GlobalConstants.PLCY_MGMT_REQUEST_CANCEL = 'requestcancel';
    GlobalConstants.PLCY_MGMT_EXSTREAM_RECORD = 'createExstreamRecordPostCancellation';
    GlobalConstants.PLCY_MGMT_ENDORSE_POLICY = 'endorsePolicy';
    GlobalConstants.PLCY_MGMT_ENDORSE_VOID = 'endorseVoid';
    GlobalConstants.PLCY_MGMT_IS_ELIGIBLE_FOR_ENDORSEMENT = 'isEligibleForEndorsement';
    GlobalConstants.PLCY_MGMT_ENDORSE_BIND = 'endorsebind';
    GlobalConstants.PLCY_MGMT_ENDORSE_SAVE = 'savePolicyChanges';
    GlobalConstants.ENDORSE_USER_TYPE = 'INS';
    GlobalConstants.VEH_OWN_TYPE_OWN = 'OWN';
    GlobalConstants.VEH_OWN_TYPE_LEASED = 'LP';
    GlobalConstants.VEH_OWN_TYPE_FINANCED = 'AI';
    GlobalConstants.ENDORSE_EMAIL_STATUS = 'P';
    // CC TYPES
    GlobalConstants.DRIVERS_LICENCE = 'DL';
    GlobalConstants.SOCIAL_SECURITY = 'SSN';
    GlobalConstants.VISA_CARD = 'VISA';
    GlobalConstants.AMERICAN_EXPRESS = 'AMEX';
    GlobalConstants.MASTER_CARD = 'MASTER';
    GlobalConstants.DISCOVER_CARD = 'DISCOVER';
    // DOCUSIGN API
    GlobalConstants.DOCUSIGN_PARAMETER = '?eSigAction=';
    GlobalConstants.DOCUSIGN_TYPE_CANCELLED = 'Cancelled';
    GlobalConstants.DOCUSIGN_TYPE_DECLINED = 'Declined';
    GlobalConstants.DOCUSIGN_TYPE_EXCEPTION = 'Exception';
    GlobalConstants.DOCUSIGN_TYPE_IDCHECK = 'IDCheckFailed';
    GlobalConstants.DOCUSIGN_TYPE_SESSION_TIMEOUT = 'SessionTimedOut';
    GlobalConstants.DOCUSIGN_TYPE_COMPLETE = 'Complete';
    GlobalConstants.DOCUSIGN_TYPE_TTL_EXPIRED = 'TTLExpired';
    GlobalConstants.DOCUSIGN_TYPE_VIEWING = 'ViewingComplete';
    // POLICY DETAILS REST API
    GlobalConstants.GET_STATEPAPERDOCFEE = 'statepaperdocfee';
    // AGENTS
    GlobalConstants.EA_AGENT = 'EA';
    GlobalConstants.IA_AGENT = 'IA';
    // POLICY Holder REST API
    GlobalConstants.SSO_LOGIN = 'tokenLogin';
    GlobalConstants.FIND_PH_DETAILS = 'findPH';
    GlobalConstants.FIND_PH_DETAILS_OKTA = 'findPH?bwoktaEnabled=true';
    GlobalConstants.FORGOT_PH_PASSWORD = 'forgotPHPassword';
    GlobalConstants.CHECK_ANSWERS = 'checkAnswers';
    GlobalConstants.INSERT_PH = 'insertPH';
    GlobalConstants.REMOVE_PH = 'removePH';
    GlobalConstants.REMOVE_PH_OKTA = 'removePH?bwoktaEnabled=true';
    GlobalConstants.GET_POLICY_MCO = 'getPolicyMco';
    GlobalConstants.CHANGE_PH_PASSWORD = 'changePHPassword';
    GlobalConstants.CHANGE_PH_PASSWORD_OKTA = 'changePHPassword?bwoktaEnabled=true';
    GlobalConstants.RESET_PH_PASSWORD = 'resetPHPassword';
    GlobalConstants.RESET_PH_PASSWORD_OKTA = 'resetPHPassword';
    GlobalConstants.UPDATE_PH = 'updatePHInfo';
    GlobalConstants.UPDATE_PH_OKTA = 'updatePHInfo?bwoktaEnabled=true';
    GlobalConstants.LIENHOLDER_SEARCH = 'searchLienholder';
    // eFNOL Service
    GlobalConstants.IS_EFNOL_ROLLOUT = 'IseFNOLRolledOut';
    GlobalConstants.EFNOL_SAML_TOKEN = 'geteFNOLSAMLToken';
    GlobalConstants.EFNOL_SAML_TOKEN_VIEW = 'geteFNOLSAMLTokenView';
    GlobalConstants.IS_EFNOL_ROLLOUT_VIEW = 'IseFNOLRolledOutView';
    GlobalConstants.EFNOL_USERTYPE = 'Insured';
    // Text Alert REST API
    GlobalConstants.CHECK_STATE_ENABLED = 'checkStateEnabledRPC';
    GlobalConstants.GET_TEXT_ENROLLMENT_STATUS = 'getTextAlertEnrollmentStatusRPC';
    GlobalConstants.MANAGE_TEXT_ALERT_ENROLLMENT = 'manageTextAlertEnrollmentRPC';
    // Logger API
    GlobalConstants.LOGGER_INFO = 'info';
    GlobalConstants.LOGGER_ERROR = 'error';
    GlobalConstants.LOGGER_TIME = 'time';
    // policy opt
    GlobalConstants.HAS_OPTTED_PRIVACY = 'hasOptedOutPrivacyPolicy';
    GlobalConstants.VALIDATE_POLICY_OPT = 'validatePolicyPrivacyOptOutPOINT';
    GlobalConstants.LOG_PRIVICY_OPT = 'logPrivacyOptOut';
    // MFA
    GlobalConstants.MFA_DAYS = 90;
    GlobalConstants.CHECK_EACH_ANSWER = 'checkEachAnswer';
    // Vehicle Service
    GlobalConstants.VINTELL_RET_VIN = 'retrieveVehicleSpecificationByVIN';
    GlobalConstants.VINTELL_IMPLEMENTED = 'isVintelligenceImplemented';
    GlobalConstants.GET_SYMBOL = 'getSymbols';
    GlobalConstants.GET_21C_SYMBOL = 'get21CSymbols';
    GlobalConstants.GET_SYMBOL_SET = 'getSymbolSet';
    GlobalConstants.CONVERT_ATTR_VALUE = 'convertAttributeValue';
    GlobalConstants.ANTI_THEFT_YES = 'Y';
    GlobalConstants.ANTI_THEFT_NO = 'N';
    // Endorsements api
    GlobalConstants.PROCESS_ENDOREMENTS = 'processEndorsements';
    GlobalConstants.ENDORSE_POLICY = 'endorsePolicy';
    GlobalConstants.ENDORSE_SAVE_CHANGES_TO_POLICY = 'savePolicyChanges';
    GlobalConstants.ENDORSE_BIND = 'endorsebind';
    GlobalConstants.ENDORSE_VOID = 'endorseVoid';
    GlobalConstants.ENDORSE_ASYNC = 'postEndorsement';
    GlobalConstants.BW_ENDORSE_SAVE = 'processEndorsementSave';
    GlobalConstants.BW_ENDORSE_BIND = 'processEndorsementBind';
    GlobalConstants.BW_ENDORSE_VOID = 'processEndorsementVoid';
    // Audit log
    GlobalConstants.AUDIT_LOG_ACITIVITY_RESULT_S = 'S';
    GlobalConstants.AUDIT_LOG_ACITIVITY_REPLACE_VEHICLE = 'RPV';
    GlobalConstants.AUDIT_LOG_ACITIVITY_ADD_VEHICLE = 'ADV';
    GlobalConstants.AUDIT_LOG_ACITIVITY_ADDRESS_CHANGE = 'ADD';
    GlobalConstants.AUDIT_LOG_SERVICE = 'auditLogRecord';
    GlobalConstants.AUDIT_LOG_ACITIVITY_RESULT_K = 'K';
    GlobalConstants.AUDIT_LOG_ACITIVITY_RESULT_FAILURE = 'F';
    GlobalConstants.AUDIT_LOG_ACITIVITY_MESSAGE_SUCCESS = 'Address Endorsement completed';
    // Marketing ID Audit log
    GlobalConstants.AUDIT_LOG_MARKETING_ID_SYMBOL = 'XXX';
    GlobalConstants.AUDIT_LOG_MARKETING_ID_POLICY_NUMBER = 'XXXXXXX';
    GlobalConstants.AUDIT_LOG_MARKETING_ID_MODULE = 'XX';
    GlobalConstants.AUDIT_LOG_MARKETING_ID_MASTER_COMPANY = 'XX';
    GlobalConstants.AUDIT_LOG_MARKETING_ID_STATE = 'XX';
    GlobalConstants.AUDIT_LOG_MARKETING_ID_ACTIVITY = 'MRK';
    GlobalConstants.AUDIT_LOG_MARKETING_ID_ACTIVITY_RESULT = 'Y';
    // comment
    GlobalConstants.COMMENT_REQ_ACTIVITY_TYPE = 'EN';
    GlobalConstants.COMMENT_REQ_REASON_CODE = 'PC';
    GlobalConstants.COMMENT_REQ_RECYCLE = 'RECYCLE';
    GlobalConstants.COMMENT_REQ_PRINT_BYTE = 'P';
    // Endorsements api
    // Valid Values
    GlobalConstants.VALID_VALUES_RATEBOOK = 'getValidValuesForRatebook';
    GlobalConstants.VALID_VALUES_POLICY_COVERAGES = 'PolicyCoverage';
    GlobalConstants.VALID_VALUES_VEHICLE_COVERAGES = 'VehicleCoverage';
    GlobalConstants.VALID_VALUES_VEHICLE_COVERAGES_COMP = 'Comprehensive';
    GlobalConstants.VALID_VALUES_VEHICLE_COVERAGES_COLL = 'Collision';
    GlobalConstants.VALID_VALUES_VEHICLE_COVERAGES_RENTAL = 'Rental';
    GlobalConstants.VALID_VALUES_VEHICLE_COVERAGES_TOW = 'Towing & Road Service';
    GlobalConstants.VALID_VALUES_POLICY_COVERAGES_BI = 'Bodily Injury';
    GlobalConstants.VALID_VALUES_POLICY_COVERAGES_PD = 'Property Damage';
    GlobalConstants.VALID_VALUES_POLICY_COVERAGES_MED = 'Medical Coverage';
    GlobalConstants.VALID_VALUES_POLICY_COVERAGES_UMBI = 'Basic and Supplementary Uninsured/Underinsured Motorist';
    GlobalConstants.VALID_VALUES_POLICY_COVERAGES_PIP = 'Personal Injury Protection';
    GlobalConstants.VALID_VALUES_POLICY_COVERAGES_EXTPIP = 'Extra PIP Package';
    GlobalConstants.VALID_VALUES_VEHICLE_COVERAGES_UMPD = 'Uninsured Motorist PD w/o Collision';
    GlobalConstants.VALID_VALUES_VEHICLE_COVERAGES_ALL = 'Auto Loan Lease';
    GlobalConstants.AUDIT_LOG = 'auditLogRecord';
    // Audit log
    GlobalConstants.AUDIT_LOG_ACITIVITY_RESULT = 'S';
    // HELPTEXT API
    GlobalConstants.GET_HELPTEXT = 'getAllHelpText';
    GlobalConstants.GET_HELPTEXT_BYID = 'getAllHelpTextByIds';
    // TERRITORY EXCLUSION SERVICE API
    GlobalConstants.CHECK_ZIP_RESTRICTION = 'checkZipRestriction';
    GlobalConstants.GET_ZIP_RESTRICTION_FLAG = 'isZipCodeRestricted';
    // Knock out
    GlobalConstants.APPLY_KNOCKOUT_RULES = 'applyKnockoutRules';
    // anti theft
    GlobalConstants.GET_ANTI_THEFT_TYPES = 'getAntiTheftTypes';
    // Customer Reviews
    GlobalConstants.GET_CUSTOMER_REVIEWS = 'getCustomerReviews';
    GlobalConstants.CUSTOMER_REVIEWS_PAGE_TITLE = 'Customer Reviews';
    GlobalConstants.DRIVERS_PAGE_TITLE = 'Bristol West Policy - Drivers';
    GlobalConstants.COVERAGES_PAGE_TITLE = 'Bristol West Policy - Coverages';
    GlobalConstants.DISCOUNTS_PAGE_TITLE = 'Bristol West Policy - Discounts';
    GlobalConstants.VEHICLES_PAGE_TITLE = 'Bristol West Policy - Vehicles';
    GlobalConstants.PAYMENT_SCHEDULE_PAGE_TITLE = 'Bristol West Policy - Payment Schedule';
    GlobalConstants.AUTOPAY_PAGE_TITLE = 'Bristol West Policy - Manage Auto Pay';
    GlobalConstants.MAKE_A_PAYMENT_PAGE_TITLE = 'Bristol West Policy - Make A Payment';
    GlobalConstants.CLAIMS_PAGE_TITLE = 'Bristol West Policy - Claims';
    GlobalConstants.WHY_BRISTOL_PAGE_TITLE = 'Bristol West - Why Bristol';
    GlobalConstants.AUTO_INS_101_PAGE_TITLE = 'Bristol West - Auto Insurance 101';
    GlobalConstants.PRIVACY_PAGE_TITLE = 'Bristol West - Privacy';
    GlobalConstants.FAQ_PAGE_TITLE = 'Bristol West - FAQ';
    GlobalConstants.TERMS_PAGE_TITLE = 'Bristol West - Terms & Conditions';
    GlobalConstants.ABOUT_PAGE_TITLE = 'Bristol West - About Us';
    GlobalConstants.CONTACT_PAGE_TITLE = 'Bristol West - Contact Us';
    GlobalConstants.SITEMAP_PAGE_TITLE = 'Bristol West - Sitemap';
    GlobalConstants.FINDAGENT_PAGE_TITLE = 'Bristol West - Find Agent or Broker';
    // Meta Keywords/Description
    GlobalConstants.CUSTOMER_REVIEWS_META_DESCRIPTION = 'Customer Reviews for Bristol West Insurance.' +
        'Read reviews from Bristol West customers on rates, service, claims, and more.';
    GlobalConstants.CUSTOMER_REVIEWS_META_KEYWORDS = 'reviews, ratings, Bristolwest customer reviews, Bristolwest ratings , ' +
        'Bristolwest reviews , Bristol west customer reviews, Bristol west ratings , Bristol west reviews ,' +
        'auto Insurance reviews, car insurance reviews, customer reviews';
    GlobalConstants.ANY_PAGE_TITLE = 'Welcome to Bristol West Insurance Group';
    GlobalConstants.ANY_META_DESCRIPTION = 'Bristol West works to make Auto Insurance easy.';
    GlobalConstants.ANY_META_KEYWORDS = 'Car Insurance, Auto Insurance, Insurance, online car insurance, discount car insurance,' +
        'www.bristolwest.com, Bristol West Insurance';
    // MI Reform Bill  //keerthana
    GlobalConstants.STATE_MI = 'MI';
    GlobalConstants.BWR_SYSTEM = 'BWR';
    GlobalConstants.EDMR_SIGNEDDOCUMENT_CD = 'SD';
    GlobalConstants.EDMR_APPLICAIONPKG_CD = 'AP';
    GlobalConstants.GET_POLICYHOLDERINFO_PAYMENTUS = 'getPaymentUsStatus';
    //New payment endpoint from service wrapper //sayantan
    GlobalConstants.MAKE_NEW_PAYMENT_URL = 'epc/makePayment';
    //PaymentUs Iframe
    GlobalConstants.PAYMENTUS_IFRAME = 'epc/iframeUrl';
    //EFT 
    GlobalConstants.ENROLL_EFT = 'epc/autopay-enroll';
    GlobalConstants.UPDATE_EFT = 'epc/autopay-update';
    GlobalConstants.TERMINATE_EFT = 'epc/autopay-cancel';
    GlobalConstants.GET_EFT = 'epc/autopay-details/';
    //CES
    GlobalConstants.GET_TOKEN = 'ces/survey-token';
    GlobalConstants.UPDATE_NOTHANKS = 'ces/survey-no-thanks';
    //OKTA
    GlobalConstants.AUTHN = 'security/authn';
    GlobalConstants.SSO_OKTA = 'users/accesstoken';
    GlobalConstants.OKTA_DECODE_JWT = "security/decode-jwt";
    GlobalConstants.OKTA_USERTYPE = "0001";
    GlobalConstants.OKTA_REDIRECT_URI = environment.oktaRedirectUriForSSO;
    GlobalConstants.OKTA_ISACCESSTOKENREQUIRED = true;
    GlobalConstants.STATE_TOKEN = 'users/statetoken';
    GlobalConstants.RESET_PASSWORD = 'users/reset/password';
    GlobalConstants.RECOVERY_PASSWORD = 'users/recovery/password';
    GlobalConstants.status = {
        pendingCancelled: 'Pending Cancel',
        reinstatementAvailable: 'Cancelled - Reinstatement Available',
        active: 'Active',
        expired: 'Expired',
        cancelled: 'Cancelled',
        futureEffective: 'Future Effective'
    };
    GlobalConstants.STATES_4_0 = ['AZ', 'IL'];
    return GlobalConstants;
}());
export { GlobalConstants };
