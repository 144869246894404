import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalConstants } from '../../../constants/global.constant';
import { GoPaperlessService } from '../../../services/api/gopaperless/go-paperless.service';
import { Setepolicyflagreq } from '../../model/api/gopaperless/setepolicyflagreq';
import { GoogleAnalyticsService } from '../../services/analytics/google-analytics.service';
import { BwstoreService } from '../../services/bwstore.service';
import { CommonUtil } from '../../utils/commonutil';
import { Tracker } from '../../utils/tracker';
var LoginGpTermsComponent = /** @class */ (function () {
    function LoginGpTermsComponent(router, _storeService, _gopaperlessService, googleAnalytics) {
        this.router = router;
        this._storeService = _storeService;
        this._gopaperlessService = _gopaperlessService;
        this.googleAnalytics = googleAnalytics;
        this.authenticatedPhDetails = this._storeService.getLoginInfoFromStore();
    }
    LoginGpTermsComponent.prototype.ngOnInit = function () {
    };
    LoginGpTermsComponent.prototype.acceptGoPaperless = function () {
        var _this = this;
        this.googleAnalytics.trackEvent('Login', 'GPConsentScreen', 'GPConsentClick', 200);
        var polsym = CommonUtil.getPolicySymbol(this.authenticatedPhDetails);
        var polNum = CommonUtil.getPolicyNumber(this.authenticatedPhDetails);
        var polMod = this.authenticatedPhDetails.policyMod;
        this.setepolicyflagreq = new Setepolicyflagreq();
        this.setepolicyflagreq.blnMidTermENR = 'false';
        this.setepolicyflagreq.szEmail = this.authenticatedPhDetails.email;
        this.setepolicyflagreq.szMCO = CommonUtil.getMCO(this.authenticatedPhDetails);
        this.setepolicyflagreq.szPolicyNbr = polsym.concat(polNum).concat(polMod);
        this.setepolicyflagreq.szSource = GlobalConstants.APPID;
        this._gopaperlessService.setEPolicyFlag(this.setepolicyflagreq).subscribe(function (data) {
            // if (data) {
            // Check for esig
            if (_this.authenticatedPhDetails.esigstatus) {
                // If they have Docusign then send them to Docusign page
                _this.router.navigate(['/esigmessages']);
            }
            else {
                // If no Docusign then show my policy screen
                _this.router.navigate(['/policy']);
            }
            // }
        }, function (error) {
            Tracker.logerror('LoginGpTermsComponent', 'acceptGoPaperless', 'setEPolicyFlag', 'Error setting EPolicy flag in registration gopaperless step', error);
        });
    };
    return LoginGpTermsComponent;
}());
export { LoginGpTermsComponent };
