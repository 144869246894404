import { Component, Input, OnInit } from '@angular/core';
import { PaymentMethodAssetImgUrl, PaymentusIframePaymentMethodType, PaymentMethodTypeMapping } from '../../../constants/billing.constants';
import { PaymentMethodInfo } from '../../../shared/model/api/payment/payment-methods.model';

@Component({
  selector: 'app-credit-card-info',
  templateUrl: './credit-card-info.component.html',
  styleUrls: ['./credit-card-info.component.scss']
})
export class CreditCardInfoComponent implements OnInit {

  @Input() paymentMethodInfo: PaymentMethodInfo;
  @Input() showExpDate = true;
  isCard: boolean;
  isBankAccount: boolean;
  imgSrcAttr: string;
  imgAriaLabel: string;
  accountInfoAriaLabel: string;
  cardInfoAriaLabel: string;

  constructor() { }

  ngOnInit() {
    this.configTemplateFor(this.paymentMethodInfo);
  }

  configTemplateFor(paymentMethodInfo: PaymentMethodInfo): void {
    const accountNumber = paymentMethodInfo.accountNumber;
    const expiryMonth = paymentMethodInfo.expiryMonth;
    const expiryYear = paymentMethodInfo.expiryYear;
    const type = paymentMethodInfo.type;
    const paymentusIframePaymentType = PaymentMethodTypeMapping[type]
    const hasExpirationDate = expiryMonth && expiryYear;
    this.isBankAccount =
      accountNumber && type &&
      paymentusIframePaymentType === PaymentusIframePaymentMethodType.DIRECT_DEBIT
    this.isCard = !this.isBankAccount && accountNumber && hasExpirationDate && type &&
      (paymentusIframePaymentType === PaymentusIframePaymentMethodType.CREDIT_CARD
        || paymentusIframePaymentType === PaymentusIframePaymentMethodType.DEBIT_CARD);
    if (this.isBankAccount) {
      this.accountInfoAriaLabel = `ending with ${accountNumber}`;
    }
    if (this.isCard) {
      this.cardInfoAriaLabel = `expires on ${expiryMonth}/${expiryYear}`;
    }
    if (this.isBankAccount || this.isCard) {
      if (PaymentMethodAssetImgUrl[type].url) this.imgSrcAttr = PaymentMethodAssetImgUrl[type].url;
      if (PaymentMethodAssetImgUrl[type].adaLabel) this.imgAriaLabel = PaymentMethodAssetImgUrl[type].adaLabel;
    }
  }

}
