export class Scrubaddrreq {
  fullAddress: string;
  city: string;
  state: string;
  zip: string;
  sourceSystem: string;
  addressType: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
