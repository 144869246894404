export class Policymailreq {
    symbol: string;
    policy: string;
    mod: string;
    mco: string;
    state: string;
    midterm: string;
    email: string;
    type: string;
    status: string;
    sentCount: Number;
    frequency: Number;
    firstName: string;
    lastName: string;
    miscellaneousUse1: string;
    miscellaneousUse2: string;
    miscellaneousUse3: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
