import { EndorseMetainfo } from '../../shared/model/endorsements/endorse-metainfo.model';
import * as BwEndorseInfoActions from '../actions/bwendorsement.action';
import { EndorsementSaveReq } from '../../shared/model/api/endorsement/endorsement-save-req.model';
import { Eftreturnres, FuturePayment } from '../../shared/model/api/policyeft/eftreturnres.model';
const defaultEndorsementInfoState: EndorseMetainfo = new EndorseMetainfo();
const removeEndosrmentInfoState: EndorseMetainfo = {
  endorseType: '',
  endorseTypeCd: '',
  endorseTypeDesc: '',
  endorseDate: '',
  endorseUser: '',
  endorsePolicyNum: '',
  endorseMco: '',
  endorseRatebook: '',
  endorseState: '',
  endorseBinderNum: '',
  endorseContext: '',
  endorsePremiumChange: 0,
  endorsePremium: 0,
  endorseBound: '',
  futurePayments: [],
  endorsementReqInfo: new EndorsementSaveReq(''),
  umpdValue: '',
  // endorsement request
  PolicyCoverages:  []

};

// Helper to create new state object as the state is immutable
const newState = (state, newDataObj) => {
    return Object.assign({}, state, newDataObj);
};

export function BwEndorsementReducer(state: EndorseMetainfo = defaultEndorsementInfoState, action: BwEndorseInfoActions.Actions) {

    switch (action.type) {
        case BwEndorseInfoActions.CREATE_ENDORSEMENTINFO:
          return newState(state, action.payload);

        case BwEndorseInfoActions.UPDATE_ENDORSEMENTINFO:
          return newState(state, action.payload);

        case BwEndorseInfoActions.REMOVE_ENDORSEMENTINFO:
          return newState(state, removeEndosrmentInfoState);

        default:
          return state;
      }
}
