/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./requestcancel-pending-endorsement.component";
import * as i2 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
var styles_RequestcancelPendingEndorsementComponent = [];
var RenderType_RequestcancelPendingEndorsementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RequestcancelPendingEndorsementComponent, data: {} });
export { RenderType_RequestcancelPendingEndorsementComponent as RenderType_RequestcancelPendingEndorsementComponent };
export function View_RequestcancelPendingEndorsementComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"], ["id", "request-to-cancel-nogo"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Pending Transaction"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", " or fill out the "])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel Request Form"])), (_l()(), i0.ɵted(-1, null, [" \u00A0and use Submit Documents to upload for processing."])), (_l()(), i0.ɵeld(12, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Ok "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pendingMessage; _ck(_v, 8, 0, currVal_0); var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.cancelFormLocation, ""); _ck(_v, 9, 0, currVal_1); }); }
export function View_RequestcancelPendingEndorsementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-requestcancel-pending-endorsement", [], null, null, null, View_RequestcancelPendingEndorsementComponent_0, RenderType_RequestcancelPendingEndorsementComponent)), i0.ɵdid(1, 114688, null, 0, i1.RequestcancelPendingEndorsementComponent, [i2.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RequestcancelPendingEndorsementComponentNgFactory = i0.ɵccf("app-requestcancel-pending-endorsement", i1.RequestcancelPendingEndorsementComponent, View_RequestcancelPendingEndorsementComponent_Host_0, {}, {}, []);
export { RequestcancelPendingEndorsementComponentNgFactory as RequestcancelPendingEndorsementComponentNgFactory };
