import { Component, Input, OnInit } from '@angular/core';
import { BillingDetails } from '../../../shared/model/billing-details.model';
import { GlobalConstants } from '../../../constants/global.constant';
import { PolicyHolder } from '../../../shared/model/authentication/authenticatephres';
import { BwstoreService } from '../../../shared/services/bwstore.service';



@Component({
  selector: 'app-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.scss']
})
export class BillingDetailsComponent implements OnInit {


  @Input('billingDetails') billingDetails = {} as BillingDetails;

  APV : string ="APV";
  CAL : string ="CAL";
  status : any;
  now:Date = new Date();
  authenticatedPhDetails: PolicyHolder;
  constructor(private _storeService: BwstoreService) {
    
    this.authenticatedPhDetails = this._storeService.getLoginInfoFromStore();
    //console.log(this.authenticatedPhDetails);

    setInterval(() => {
      this.now = new Date();
    }, 1);
   }

  ngOnInit() {
   console.log(this.billingDetails);
    this.status = GlobalConstants.status;
   // console.log("4")
  }

  getPolicyNumber(){

    return (this.billingDetails.policyInfo.PolicyNumber).split('-')[0].toUpperCase()+(this.billingDetails.policyInfo.PolicyNumber).split('-')[1].toUpperCase()+(this.billingDetails.policyInfo.PolicyNumber).split('-')[2].toUpperCase();
  }

  // isReinstatementAvailable():boolean{

  //   return this.billingDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC=== GlobalConstants.status.reinstatementAvailable;
  // }

  isReinstatementAvailable():boolean{
    if((this.billingDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC === 
      GlobalConstants.status.reinstatementAvailable && this.authenticatedPhDetails.reInstateDueamount && this.authenticatedPhDetails.reInstateDueamount!=='0' && this.authenticatedPhDetails.reInstateDueamount!=='0.00') || this.authenticatedPhDetails.isReinstate){
      //this.billingDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC = GlobalConstants.status.reinstatementAvailable;
      return this.getStatus();
    } else if (this.billingDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC === GlobalConstants.status.reinstatementAvailable &&
      (!this.authenticatedPhDetails.reInstateDueamount || this.authenticatedPhDetails.reInstateDueamount === '0'
        || this.authenticatedPhDetails.reInstateDueamount === '0.00')) {// if reinstate from backend and amount is zero
          this.billingDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC= GlobalConstants.status.cancelled;
      return false;
      } else{
      return false;
    }
    //return this.policyInfo.PolicyStatusEPC=== GlobalConstants.status.reinstatementAvailable || this.authenticatedPhDetails.isReinstate;
  }

  getStatus():boolean{
    if(!(this.authenticatedPhDetails.reInstateDueamount && this.authenticatedPhDetails.reInstateDueamount!=='0' && this.authenticatedPhDetails.reInstateDueamount!=='0.00')){
      this.billingDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC = GlobalConstants.status.cancelled;
      return false;
    }
    this.billingDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC =  GlobalConstants.status.reinstatementAvailable;
    return true;
  }

  getDueDate(){
    //console.log("3")
    return this.billingDetails.basicPolicyDetails.paymentInfo.paymentDueDate && (this.billingDetails.basicPolicyDetails.paymentInfo.paymentDueDate.includes("0001") || this.billingDetails.basicPolicyDetails.paymentInfo.paymentDueDate.includes("1899"))?"":this.billingDetails.basicPolicyDetails.paymentInfo.paymentDueDate;
  }

  getCancelledDueDate(){
   // console.log("4");
    return this.billingDetails.basicPolicyDetails.policyInfo.DueDate && (this.billingDetails.basicPolicyDetails.policyInfo.DueDate.includes("0001") || this.billingDetails.basicPolicyDetails.policyInfo.DueDate.includes("1899"))?"":this.billingDetails.basicPolicyDetails.policyInfo.DueDate;
  }

  getLastDayToPayDate(){
    return this.authenticatedPhDetails.reInstateDueDate && (this.authenticatedPhDetails.reInstateDueDate.includes("0001") || this.authenticatedPhDetails.reInstateDueDate.includes("1899"))?"":this.authenticatedPhDetails.reInstateDueDate;
  }

}
