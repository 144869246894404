import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PaymentInfo } from '../../../shared/model/api/bwibic/fullpolicydetails.model';
import { PaymentMethodInfo } from '../../../shared/model/api/payment/payment-methods.model';

@Component({
  selector: 'app-payment-method-form',
  templateUrl: './payment-method-form.component.html',
  styleUrls: ['./payment-method-form.component.scss']
})
export class PaymentMethodFormComponent implements OnInit, OnDestroy {

  constructor() { }

  @Input() paymentMethods: PaymentMethodInfo[];
  @Output() selectedPaymentMethod = new EventEmitter<PaymentMethodInfo>();
  @Output() editPaymentMethod = new EventEmitter();

  formGroup: FormGroup;
  methodSelected: string;
  subscriptions: Subscription[] = [];
  EVENT_NAME = 'message';
  PAYMENTUS_IO = 'paymentus';
  PM_DETAILS = "pmDetails";
  editedPaymentMethod: boolean = false;
  newPaymentMethodSubscription: Subscription;
  tokenCopy: string;
  selected: boolean = false;
  selectedPayment: PaymentInfo;
  paymentusEventNewPaymentMethod$ = fromEvent(window, this.EVENT_NAME).pipe(
    filter((event: MessageEvent) => {
      this.editedPaymentMethod = false;
      return event.origin.includes(this.PAYMENTUS_IO);
    }),
  );

  ngOnInit() {
    this.buildPaymentMethodsForm();
  }

  get value() {
    return this.formGroup.value;
  }

  set value(_value) {
    this.onTouched();
  }

  onChange: any = () => {
    // do nothing
  };

  onTouched: any = () => {
    // do nothing
  };

  validate() {
    if (this.formGroup.value.method.accountNumber) this.methodSelected = this.formGroup.value.method.accountNumber;
    return this.formGroup.valid ? null : { expired: true };
  }

  buildPaymentMethodsForm(): void {
    this.formGroup = new FormGroup({
      method: new FormControl(null, []),
    });

    this.subscriptions.push(
      this.formGroup.valueChanges.subscribe((value) => {
        if (value) this.selectedPaymentMethod.emit(value);
        this.onChange(value);
        this.onTouched();
      }),
    );
  }

  onRadioChange(event: any) {
    if (event.source.checked == true) {
      this.paymentMethods.forEach(method => {
        if (method.paymentToken == event.value.paymentToken) {
          this.selectedPayment = event.value;
          this.selected = true;
        }
      })
    }
  }

  addEditPaymentMethod(paymentusIframePaymentType: string, paymentToken: string, mode: string) {
    this.newPaymentMethodSubscription = this.paymentusEventNewPaymentMethod$
      .pipe(
        filter((event: MessageEvent) => {
          const eventData = JSON.stringify(event.data);
          return eventData.includes(this.PM_DETAILS);
        })
      ).subscribe((event: MessageEvent) => {
        if (!event) this.editedPaymentMethod = false;
        if (event) {
          this.editedPaymentMethod = true;
          const pmDetailsObjectString = event.data.substring(
            this.PM_DETAILS.length + event.data.indexOf(this.PM_DETAILS) + 1,
          );
          const pmDetailsObject = JSON.parse(pmDetailsObjectString);
          this.tokenCopy = pmDetailsObject.Token;
        }
      })
    this.paymentMethods.forEach((paymentMethod) => {
      paymentMethod.isUpdated = paymentMethod.paymentToken === this.tokenCopy;
    });
    if (this.formGroup.value && this.formGroup.value.method && (this.formGroup.value.method.paymentToken == paymentToken)) {
      this.editPaymentMethod.emit({ paymentusIframePaymentType: paymentusIframePaymentType, paymentToken: paymentToken, mode: mode, selected: this.selected })
    }
    else this.editPaymentMethod.emit({ paymentusIframePaymentType: paymentusIframePaymentType, paymentToken: paymentToken, mode: mode })
  }

  ngOnDestroy(): void {
    if (this.newPaymentMethodSubscription) this.newPaymentMethodSubscription.unsubscribe();
  }

}
