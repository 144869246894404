import { Policykey } from './policykey';
import { PaymentOptionType } from './payment-option-type';

export class Previouspaymentreq {
    paymentOption: PaymentOptionType;
    policyKey: Policykey;
    amount: string;
    transactionSource: string;
    transactionSourceSystem: string;
    browserType: string;
    address: string;
    postalCode: string;
    city: string;
    state: string;
    homePhoneNumber: string;
    accountHolderFirstName: string;
    accountHolderMiddleInitial: string;
    accountHolderLastName: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
