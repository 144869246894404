import { Injectable } from '@angular/core';
import { PrivacyoptoutService } from '../api/privacyoptout/privacyoptout.service';


@Injectable({
  providedIn: 'root'
})
export class PrivacypolicyService {

  constructor(private privacyoptoutService: PrivacyoptoutService ) { }
// this function is used to call the hasOptedprivacyPolicy services wrapper calls  to check the policy is opted or not
// if opt in response object returns true then it is already opted for privacy
  policyOpt(policySym: string , policyNum: string, state: string, lastname: string, firstName: string) {
    return new Promise((resolve, reject) => {
      this.privacyoptoutService.hasOptedprivacyPolicy(policySym, policyNum)
      .subscribe((res: any) => {
        resolve( res.opt);
      }, error => {
        reject(error);
      });

    });
  }
// this function is used to call the validatePolicyOptOut  wrapper call to update the record
// returns true if it is succesfully updated
  validatepolicyOpt(policySym: string , policyNum: string, state: string, lastName: string , firstName: string) {
    return new Promise((resolve, reject) => {
      this.privacyoptoutService.validatePolicyOptOut(policySym, policyNum, state, lastName)
      .subscribe((validateResponse: any) => {
       resolve( validateResponse.policyPrivacyOptOutPOINT);
      }, error => {
        reject(error);
      });

    });
  }

  // this function is used to call the logPrivacyOpt wrapper call to log the logPrivacyOpt
  logPrivacyOpt(policySym: string , policyNum: string, state: string,
    lastName: string , firstName: string , mod: string , enrollDate: string, enrollTime: string) {
    return new Promise((resolve, reject) => {
      this.privacyoptoutService.logPrivacyOptOut(policySym , policyNum, state,
         lastName , firstName, mod, enrollDate, enrollTime).subscribe((res: any) => {
          resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
}
