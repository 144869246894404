import { HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { GlobalConstants } from '../../../constants/global.constant';
import { BwappService } from '../../bwapp.service';
import { OktaService } from '../okta/okta.service';
import * as i0 from "@angular/core";
import * as i1 from "../../bwapp.service";
import * as i2 from "../okta/okta.service";
var UsmsecurityService = /** @class */ (function () {
    function UsmsecurityService(appService, oktaService) {
        this.appService = appService;
        this.oktaService = oktaService;
        this.globlalConstants = GlobalConstants;
    }
    UsmsecurityService.prototype.encodeKey = function (key) {
        return encodeURIComponent(key);
    };
    UsmsecurityService.prototype.encodeValue = function (value) {
        return encodeURIComponent(value);
    };
    UsmsecurityService.prototype.decodeKey = function (key) {
        return decodeURIComponent(key);
    };
    UsmsecurityService.prototype.decodeValue = function (value) {
        return decodeURIComponent(value);
    };
    UsmsecurityService.prototype.authenticatePH = function (phreq) {
        if (environment.bwOktaEnabled == true) {
            return this.oktaService.oktaLogin(phreq);
        }
        else {
            return this.appService.post(this.globlalConstants.PH_AUTH_URL, phreq);
        }
    };
    // This method get token from USM service required for SSO Request.
    UsmsecurityService.prototype.getSecurityToken = function (paramSessionTicket) {
        var params = new HttpParams({ encoder: new UsmsecurityService(this.appService, this.oktaService) })
            .set('sessionTicket', encodeURI(paramSessionTicket));
        return this.appService.get(this.globlalConstants.PH_SECURITY_TOKEN_URL, params);
    };
    UsmsecurityService.ngInjectableDef = i0.defineInjectable({ factory: function UsmsecurityService_Factory() { return new UsmsecurityService(i0.inject(i1.BwappService), i0.inject(i2.OktaService)); }, token: UsmsecurityService, providedIn: "root" });
    return UsmsecurityService;
}());
export { UsmsecurityService };
