import { Action } from '@ngrx/store';
import { PolicyHolder } from '../../shared/model/authentication/authenticatephres';

export const CREATE_LOGININFO = '[LOGININFO] Add';
export const UPDATE_LOGININFO = '[LOGININFO] Update';
export const REMOVE_LOGININFO = '[LOGININFO] Remove';


// Create Login Info upon User Login
export class CreateLoginInfo implements Action {

    readonly type = CREATE_LOGININFO;

    constructor(public payload: PolicyHolder) {}
}


// Update Login Info
export class UpdateLoginInfo implements Action {

    readonly type = UPDATE_LOGININFO;

    constructor(public payload: PolicyHolder) {}
}

// Remove LoginInfo upon User Logout
export class RemoveLoginInfo implements Action {

    readonly type = REMOVE_LOGININFO;

    constructor(public payload: PolicyHolder) {}
}

export type Actions = CreateLoginInfo | UpdateLoginInfo | RemoveLoginInfo;
