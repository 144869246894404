export class Setepolicyflagreq {
    szPolicyNbr: string;
    szMCO: string;
    szEmail: string;
    blnMidTermENR: string;
    szSource: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
