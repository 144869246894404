import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalConstants } from '../../../constants/global.constant';
import { SignOn, Validatecardreq } from '../../../shared/model/api/ivrpayment/validatecardreq.model';
import { Validatecardres } from '../../../shared/model/api/ivrpayment/validatecardres.model';
import { Policynumber } from '../../../shared/model/policynumber';
import { CommonUtil } from '../../../shared/utils/commonutil';
import { BwappService } from '../../bwapp.service';
import { BWErrorhandler } from '../../errorhandler';

@Injectable({
  providedIn: 'root'
})
export class IvrpaymentService {

  private globlalConstants = GlobalConstants;
  policyNumber: Policynumber;
  validateCardReq: Validatecardreq;

  constructor(private appService: BwappService, private errorHandler: BWErrorhandler) { }

  validateCardNumber(cardnumber: string): Observable<Validatecardres> {

    this.validateCardReq = new Validatecardreq();
    this.validateCardReq.paymentGatewayChannel = 'INTERNET_PSS';
    this.validateCardReq.cardNumber = CommonUtil.formatCC(cardnumber);

    const signOn = new SignOn();
    signOn.client = 'MOBILE_APP';
    signOn.requestId = '1233';
    signOn.clientId = 'IVRB';

    this.validateCardReq.signOn = signOn;

    // Tracker.log('Validate Card Request in IvrpaymentService ::: ', this.validateCardReq);
    return this.appService.post(this.globlalConstants.VALIDATE_CARDNUMBER, this.validateCardReq);
  }
}
