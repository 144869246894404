import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { throwError } from 'rxjs';
import { GlobalConstants } from '../../../constants/global.constant';
import { DocumentsService } from '../../../services/api/documents/documents.service';
import { PaymentService } from '../../../services/api/payment/payment.service';
import { DateValidator } from '../../validators/date.validator';
import { FullPolicyDetails, PaymentInfo, PolicyInfo } from '../../model/api/bwibic/fullpolicydetails.model';
import { Idcardreq } from '../../model/api/document/idcardreq';
import { Idcardres } from '../../model/api/document/idcardres';
import { Paymentdetailsres } from '../../model/api/payment/paymentdetailsres';
import { Policynumber } from '../../model/policynumber';
import { CenturydatePipe } from '../../pipes/centurydate.pipe';
import { AuthenticationService } from '../../services/authentication.service';
import { BwstoreService } from '../../services/bwstore.service';
import { MessagesService } from '../../services/messages.service';
import { CommonUtil } from '../../utils/commonutil';
import { NgbDateFormatter } from '../../utils/ngbdateformatter';
import { PolicymgtService } from '../../../services/api/policymgmt/policymgt.service';
import { Authforidcardreq } from '../../model/api/policymgmt/authforidcardreq';
import { Authforidcardres } from '../../model/api/policymgmt/authforidcardres';
import { Tracker } from '../../utils/tracker';
import { PolicyHolder } from '../../model/authentication/authenticatephres';
import { environment } from '../../../../environments/environment';
import { PolicyholderService } from '../../../services/api/policyholder/policyholder.service';


@Component({
  selector: 'app-without-login',
  templateUrl: './without-login.component.html',
  styleUrls: ['./without-login.component.scss'],
  providers: [{provide: NgbDateParserFormatter, useClass: NgbDateFormatter}, CenturydatePipe]
})

export class WithoutLoginComponent implements OnInit {

  // Reactive Form
  withoutloginForm: FormGroup;

  // Stateful Classes
  policyNumber: Policynumber;
  basicPolicyDetails: FullPolicyDetails;
  policyInfo: PolicyInfo;
  paymentInfo: PaymentInfo;

  // Messages
  errorMsg = '';
  loadingMessage: string;
  twoOfThreeRequiredMessage: string;

  // Boolean
  formSubmitAttempt: boolean;
  isPayment: boolean;
  isPageDataLoaded = true;
  hasTwoSelected: boolean[];
  haveIDCard = false;

  // Model
  paymentDetailsRes: Paymentdetailsres;
  authIDCardReq: Authforidcardreq;
  authIDCardRes: Authforidcardres;
  idcardreq: Idcardreq;
  idcardres: Idcardres;
  pdfTest: string;
  cookieBannerUrl = `${environment.ccpaCookieBanner}`;
  
  isPaymentusEnabled: boolean = true;

  constructor(private paymentService: PaymentService, private documentService: DocumentsService,
    private _storeService: BwstoreService,
    private _messageService: MessagesService,
    private authService: AuthenticationService,
    private policyManageService: PolicymgtService,
    private datePipe: CenturydatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private policyholderService: PolicyholderService) {
  }

  ngOnInit() {
    
   this.createWithoutLoginForm();
    this.loadingMessage = 'Loading...';
    this.twoOfThreeRequiredMessage = '';
    // Determine if it is Make a Payment or Print ID Card
      this.route.queryParamMap.subscribe(params => {
        const typeVal = params.get('type');
        this.isPayment = (typeVal !== null && typeVal === 'pay' ? true : false);

        if(typeVal === 'pay'){
          this.policyholderService.getPaymentusStatus().subscribe((pniInfores: any) => {
            if (pniInfores && pniInfores.result === 'Success' && pniInfores.IsPaymentusAppFeatureEnabled === "true") {
              this.isPaymentusEnabled = true;
              this.router.navigate(['../payments']);
            }else {
              this.isPaymentusEnabled = false;
            }
          });
        }else {
          this.isPaymentusEnabled = false;
        }
      });

      
    }

  onSubmit(formData: any) {
    this.formSubmitAttempt = true;
    this.loadingMessage = 'Verifying your information';

    // Clear previous error
    this.errorMsg = '';
    this.twoOfThreeRequiredMessage = '';
    // Tracker.log('form submitted');

     // Now check if 2 of the 3 required fields where provided
     this.hasTwoSelected = [false, false, false];
     this.hasTwoSelected[0] = (formData.lastname === '' || formData.lastname === undefined || formData.lastname === null ? false : true);
     this.hasTwoSelected[1] = (formData.dob === '' || formData.dob === undefined || formData.dob === null ? false : true);
     this.hasTwoSelected[2] = (formData.phpolicynum === '' || formData.phpolicynum === undefined
      || formData.phpolicynum === null ? false : true);

      /*
      if (this.isFieldValid(formData.dob)) {
        formData.dob.setValidators([Validators.minLength(10), DateValidator.usDate]);
        formData.dob.updateValueAndValidity();
      }
*/
     const numberOfAnswersProvided = this.hasTwoSelected.filter(function(value) {
       return value === true;
     }).length;

    try {
       if (this.withoutloginForm.valid) {

        if (numberOfAnswersProvided >= 2) {
          this.isPageDataLoaded = false;
          // Tracker.log(formData.phpolicynum);
          if (formData.phpolicynum !== undefined && formData.phpolicynum !== null) {
            this.policyNumber = new Policynumber(formData.phpolicynum.toUpperCase());
          }
          const _polsymbol = (this.policyNumber === undefined ? '' : this.policyNumber.symbol);
          const _polnumber = (this.policyNumber === undefined ? '' : this.policyNumber.policy);
          // const _polmod = (this.policyNumber.module === undefined ? '' : this.policyNumber.module);
          const _lastname = (formData.lastname === undefined || formData.lastname === '' ? '' : formData.lastname);
          const _dob = (formData.dob === undefined || formData.dob === '' || formData.dob === 'null'
          || formData.dob === null ? '' : formData.dob);

          if (!this.isPayment) {
            // ID Card ** UPDATED TO USE authForIDCard from PolicyManagement Service
            this.authIDCardReq = new Authforidcardreq();

            this.authIDCardReq.dob = (_dob === '' || _dob === null ? '' : this.datePipe.transform(CommonUtil.formatDate(_dob)));
            this.authIDCardReq.lastName = _lastname;
            this.authIDCardReq.policyNumber = _polnumber;
            this.authIDCardReq.policySymbol = _polsymbol;
            this.authIDCardReq.zipCode = formData.zip;

            this.policyManageService.authForIDCard(this.authIDCardReq).subscribe(data => {
                this.authIDCardRes = data;
                if (this.authIDCardRes !== null && this.authIDCardRes !== undefined) {
                  if (this.authIDCardRes.returnCode && this.authIDCardRes.returnCode.trim() === ''
                  && this.authIDCardRes.policy ) {

                    const policyHolder =  new PolicyHolder();
                    policyHolder.jwtToken = this.authIDCardRes.jwtToken;
                    this._storeService.createLoginInfo(policyHolder);

                    this.policyNumber = new Policynumber(this.authIDCardRes.policy.substring(4));
                    const state = this.authIDCardRes.insuredCityStateZipPhone.substring(28, 30);

                    if (state !== 'NJ' && state !== 'NY') {
                      this.idcardreq = new Idcardreq();
                      this.idcardreq.policyID = this.policyNumber.toString();
                      this.idcardreq.mco = this.authIDCardRes.policy.substring(0, 2);
                      this.idcardreq.futureTerm = 'N'; // TODO: what sets this
                      this.idcardreq.appName = GlobalConstants.APPID;
                      this.idcardreq.deliveryMode = 'Email';
                      this.loadingMessage = 'Retrieving ID Card...';

                      this.documentService.retrieveIDCard(this.idcardreq).subscribe(iddata => {
                        this.idcardres = iddata;

                        if (this.idcardres !== null && this.idcardres !== undefined) {
                          if (this.idcardres.idCard !== '') {
                            // download to browser
                            this.downloadIdCard(this.idcardres.idCard);
                            this.isPageDataLoaded = true;
                            this.haveIDCard = true;
                            // remove JWT
                            this.authService.logout();
                          } else {
                            this.isPageDataLoaded = true;
                            // invalid login so display error message
                            this.errorMsg = 'We are unable to retrieve your ID Card at this time. Please try again.';
                            // this.paymentDetailsRes.responseText;
                          }
                        } else {
                          this.isPageDataLoaded = true;
                          throwError('We are unable to retrieve your ID Card at this time. Please try again.');
                        }
                      },
                      error => {
                        Tracker.logerror('WithoutLoginComponent', 'onSubmit', 'retrieveIDCard',
                          'Error Retrieving ID Card', error);

                          this.authService.logout();
                          this.isPageDataLoaded = true;
                      });
                  } else {
                    // NY or NJ so don't display ID Card. Show message
                    this.isPageDataLoaded = true;
                    this.errorMsg = 'Please contact your agent for assistance with your ID Card.';
                    this.authService.logout();
                  }

                } else {
                  this.authService.logout();
                  this.isPageDataLoaded = true;
                  // invalid login so display error message
                  this.errorMsg =
                  'We could not locate a policy with the information you provided, please check your information and try again.';
                  // this.paymentDetailsRes.responseText;
                }
              } else {
                this.authService.logout();
                this.isPageDataLoaded = true;
                throwError('Unable to identify user');
              }
            },
            error => {
              this.errorMsg =
                  'There was an error trying to retrieve your policy details. Please try again.';
                  this.authService.logout();
                  this.isPageDataLoaded = true;

              Tracker.logerror('WithoutLoginComponent', 'onSubmit', 'authForIDCard',
                          'Error Authorizing for ID Card', error);
            });

          } else {
            // Payment
            this.paymentService.validatePolicyDetails(_polsymbol, _polnumber,
              formData.zip, formData.lastname, CommonUtil.formatDate(formData.dob)).subscribe(data => {
                this.paymentDetailsRes = data;

                if (this.paymentDetailsRes !== null && this.paymentDetailsRes !== undefined) {
                  if (this.paymentDetailsRes.responseCode === 0) {
                    if (this.paymentDetailsRes.paymentMoniker === null
                      || this.paymentDetailsRes.paymentMoniker === undefined) {
                        this.paymentDetailsRes.paymentMoniker = 'Please select a payment method';
                    }

                    // const policyHolder =  new PolicyHolder();
                    // policyHolder.jwtToken = this.paymentDetailsRes.jwtToken;
                    // this._storeService.createLoginInfo(policyHolder);

                    // create PolicyInfo Object and save in store
                    this.basicPolicyDetails = new FullPolicyDetails();
                    this.basicPolicyDetails.isNoLogin = true;
                    this.policyInfo = new PolicyInfo();
                    this.paymentInfo = new PaymentInfo();

                    // Payment Info
                    this.paymentInfo.paymentMoniker = this.paymentDetailsRes.paymentMoniker;
                    this.paymentInfo.paymentType = this.paymentDetailsRes.paymentType;
                    this.paymentInfo.paymentVendor = this.paymentDetailsRes.paymentVendor;
                    // this.paymentInfo.webpayStatus = this.paymentDetailsRes.webpayStatus;
                    this.paymentInfo. allowCCPayment = this.paymentDetailsRes.allowCCPayment;
                    this.paymentInfo.eftIndicator = this.paymentDetailsRes.eftIndicator;
                    this.paymentInfo.installmentInfo = this.paymentDetailsRes.installmentInfo;
                    this.paymentInfo.firstName = this.paymentDetailsRes.firstName;
                    this.paymentInfo.lastName = this.paymentDetailsRes.lastName;
                    this.paymentInfo.minimumPayment = this.paymentDetailsRes.minimumPayment;
                    this.paymentInfo.maximumPayment = this.paymentDetailsRes.maximumPayment;
                    this.paymentInfo.totalPremium = this.paymentDetailsRes.totalPremium;
                    this.paymentInfo.lastPayAmount = this.paymentDetailsRes.lastPayAmount;
                    this.paymentInfo.policyBalance = this.paymentDetailsRes.policyBalance;
                    this.paymentInfo.lastPayDate = this.paymentDetailsRes.lastPayDate;
                    this.paymentInfo.address = this.paymentDetailsRes.address;
                    this.paymentInfo.city = this.paymentDetailsRes.city;
                    this.paymentInfo.state = this.paymentDetailsRes.state;
                    this.paymentInfo.zip = this.paymentDetailsRes.zip;
                    this.paymentInfo.expirationYear = this.paymentDetailsRes.expirationYear;
                    this.paymentInfo.expirationMonth = this.paymentDetailsRes.expirationMonth;
                    this.paymentInfo.cvc = this.paymentDetailsRes.cvc;
                    this.paymentInfo.homePhoneNumber = this.paymentDetailsRes.homePhoneNumber;
                    this.paymentInfo.masterCompany = this.paymentDetailsRes.masterCompany;

                    // PolicyInfo
                    this.policyInfo.SYMBOL = this.paymentDetailsRes.symbol;
                    this.policyInfo.POLICY0NUM = this.paymentDetailsRes.policyNumber;
                    this.policyInfo.MODULE = this.paymentDetailsRes.mod;
                    this.policyInfo.PolicyMasterCompany = this.paymentDetailsRes.masterCompany;
                    this.policyInfo.PolicyZip = formData.zip;
                    this.policyInfo.PolicyCompany = this.paymentDetailsRes.policyCompany;
                    // FullPolicyDetails
                    this.basicPolicyDetails.paymentInfo = this.paymentInfo;
                    this.basicPolicyDetails.policyInfo = this.policyInfo;
                    this._storeService.updatePolicyInfo(this.basicPolicyDetails);

                    this.router.navigate(['/payment']);

                  } else {
                    // invalid login so display error message
                    this.errorMsg = 'We are unable to find your policy. Please verify the information provided.';
                    this.authService.logout();
                    this.isPageDataLoaded = true;
                    // this.paymentDetailsRes.responseText;
                  }
                } else {
                  this.authService.logout();
                  this.isPageDataLoaded = true;
                  throwError('Unable to identify user');
                }
              },
              error => {
                Tracker.logerror('WithoutLoginComponent', 'onSubmit', 'validatePolicyDetails',
                          'Error Authorizing for Payment', error);
                this.authService.logout();
                this.isPageDataLoaded = true;
              });
            }
          } else {
            this.twoOfThreeRequiredMessage = 'Please answer 2 of the 3 questions below.';
            this.isPageDataLoaded = true;
          }
        }
    } catch (error) {
      this.authService.logout();
      // Tracker.log('Error Happened in payment submit service', error);
    } finally {
      // Clean up needed?
      // this.isPageDataLoaded = true;
    }
  }

  downloadIdCard(pdf: string) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(CommonUtil.base64toBlob(pdf, 'application/pdf'));
      return;
    }

    // All other browsers
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement('a');
    const fileName = 'IDCard.pdf';

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      document.body.removeChild(downloadLink);
      // window.URL.revokeObjectURL(data);
    });
  }

  createWithoutLoginForm() {
    this.withoutloginForm = this.fb.group({
      'zip' : ['', Validators.compose([Validators.required])],
      'lastname' : [''],
      'dob' : [null, Validators.compose([Validators.minLength[10], DateValidator.usDate])],
    });
  }

   // Form validations
   isFieldValid(field: string) {
    return (
      (!this.withoutloginForm.get(field).valid && this.withoutloginForm.get(field).touched) ||
      (this.withoutloginForm.get(field).untouched && this.formSubmitAttempt)
    );
  }

  showForm() {
    this.haveIDCard = false;
    this.isPageDataLoaded = true;
  }

  getErrMessage(errors, formCntrlName: string) {
    return this._messageService.getErrorMessages(errors, formCntrlName);
  }
}
