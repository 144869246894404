import { ReplaySubject } from 'rxjs';
import { GlobalConstants } from '../../constants/global.constant';
import { BwstoreService } from '../../shared/services/bwstore.service';
import { BwibicService } from '../api/bwibic/bwibic.service';
import { PaymentService } from '../api/payment/payment.service';
import { BWErrorhandler } from '../errorhandler';
import * as i0 from "@angular/core";
import * as i1 from "../api/bwibic/bwibic.service";
import * as i2 from "../../shared/services/bwstore.service";
import * as i3 from "../errorhandler";
import * as i4 from "../api/payment/payment.service";
var MypolicyService = /** @class */ (function () {
    function MypolicyService(bwibicService, storeService, errorHandler, paymentService) {
        this.bwibicService = bwibicService;
        this.storeService = storeService;
        this.errorHandler = errorHandler;
        this.paymentService = paymentService;
        this.globlalConstants = GlobalConstants;
        this.paymentHistorySubject = new ReplaySubject(1);
        this.paymentHistory = this.paymentHistorySubject.asObservable();
        this.agentInfoSubject = new ReplaySubject(1);
        this.agentInfo = this.agentInfoSubject.asObservable();
    }
    MypolicyService.prototype.getAllBasicPolicyInfo = function (mco, polsym, polnum, polmod) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.bwibicService.getAllPolicyInfo(mco, polsym, polnum, polmod).subscribe(function (policyres) {
                _this.paymentHistorySubject.next(policyres.paymentHistoryInfo);
                _this.agentInfoSubject.next(policyres.agentInfo);
                //this.storeService.addPolicyInfo(policyres);
                if (policyres && policyres.policyInfo && policyres.policyInfo.PolicyZip) {
                    _this.paymentService.validatePolicy(policyres.policyInfo.PolicyZip, polsym, polnum)
                        .subscribe(function (data) {
                        if (data) {
                            policyres.paymentInfo = data;
                            _this.storeService.addPolicyInfo(policyres);
                            resolve(policyres);
                        }
                    }, function (error) {
                        return reject(error);
                    });
                }
            }, function (error) {
                // Tracker.log('Error Occurred in getAllBasicPolicyInfo', error);
                return reject(error);
            });
        });
    };
    MypolicyService.prototype.retrieveAgentInfoFromStore = function () {
        var policyDetails = this.storeService.getPolicyInfoFromStore();
        this.agentInfoSubject.next(policyDetails.agentInfo);
        return policyDetails.agentInfo;
    };
    MypolicyService.prototype.retrievePaymentHistoryInfoFromStore = function () {
        var policyDetails = this.storeService.getPolicyInfoFromStore();
        this.paymentHistorySubject.next(policyDetails.paymentHistoryInfo);
        return policyDetails.paymentHistoryInfo;
    };
    MypolicyService.ngInjectableDef = i0.defineInjectable({ factory: function MypolicyService_Factory() { return new MypolicyService(i0.inject(i1.BwibicService), i0.inject(i2.BwstoreService), i0.inject(i3.BWErrorhandler), i0.inject(i4.PaymentService)); }, token: MypolicyService, providedIn: "root" });
    return MypolicyService;
}());
export { MypolicyService };
