import * as NewUserActions from '../actions/custregistration.actions';
var defaultState = {
    step: 1,
    phlastname: '',
    phfirstname: '',
    phzipcode: '',
    phdob: '',
    policynumber: '',
    userid: '',
    confirmedUserId: '',
    password: '',
    confirmedPassword: '',
    secq1: '',
    secans1: '',
    secq2: '',
    secans2: '',
    secq3: '',
    secans3: '',
    mco: '',
    producercode: '',
    esigstatus: false,
    envelopId: '',
    recipientId: '',
    goPaperlessInd: '',
    stateCd: '',
    hasRenewal: false,
    stateSpecificHasEnvelope: false
};
var removeUserState = {
    step: 1,
    phlastname: '',
    phfirstname: '',
    phzipcode: '',
    phdob: '',
    policynumber: '',
    userid: '',
    confirmedUserId: '',
    password: '',
    confirmedPassword: '',
    secq1: '',
    secans1: '',
    secq2: '',
    secans2: '',
    secq3: '',
    secans3: '',
    mco: '',
    producercode: '',
    esigstatus: false,
    envelopId: '',
    recipientId: '',
    goPaperlessInd: '',
    stateCd: '',
    hasRenewal: false,
    stateSpecificHasEnvelope: false,
};
// Helper to create new state object as the state is immutable
var newState = function (state, newDataObj) {
    return Object.assign({}, state, newDataObj);
};
var ɵ0 = newState;
export function CustRegReducer(state, action) {
    if (state === void 0) { state = defaultState; }
    switch (action.type) {
        case NewUserActions.ADD_USER:
            return [state, action.payload];
        case NewUserActions.UPDATE_USER:
            return newState(state, action.payload);
        case NewUserActions.REMOVE_USER:
            return newState(state, removeUserState);
        default:
            return state;
    }
}
export { ɵ0 };
