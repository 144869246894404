import { HttpParams } from '@angular/common/http';
import { GlobalConstants } from '../../../constants/global.constant';
import { BwappService } from '../../bwapp.service';
import * as i0 from "@angular/core";
import * as i1 from "../../bwapp.service";
var DocumentsService = /** @class */ (function () {
    function DocumentsService(appService) {
        this.appService = appService;
        this.globlalConstants = GlobalConstants;
    }
    DocumentsService.prototype.retrieveIDCard = function (idcardrequest) {
        return this.appService.post(this.globlalConstants.PROCESS_IDCARD, idcardrequest);
    };
    DocumentsService.prototype.getPolicyDocuments = function (policyNumber, mco) {
        var params = new HttpParams()
            .set('policyNumber', policyNumber)
            .set('mco', mco);
        return this.appService.get(this.globlalConstants.GET_POLICYDOCUMENTS, params);
    };
    DocumentsService.prototype.getPolicyContract = function (policyNumber, mco) {
        var params = new HttpParams()
            .set('policyNumber', policyNumber)
            .set('mco', mco);
        return this.appService.get(this.globlalConstants.GET_POLICY_CONTRACT, params);
    };
    DocumentsService.ngInjectableDef = i0.defineInjectable({ factory: function DocumentsService_Factory() { return new DocumentsService(i0.inject(i1.BwappService)); }, token: DocumentsService, providedIn: "root" });
    return DocumentsService;
}());
export { DocumentsService };
