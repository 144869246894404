import { ValidValuesCoverages } from './valid-values-coverages.model';

export class ValidValuesResponse {
    policyCvgs: ValidValuesCoverages[];
    vehicleCvgs: ValidValuesCoverages[];
    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}

export class ValidValuesServiceResponse {
    coverage: ValidValuesCoverages[];
    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
