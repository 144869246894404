export class Chatparamreq {
    primaryInsuredFirstName__c: string;
    primaryInsuredLastName__c: string;
    policyContactNumber__c: string;
    access_token:string;
    token_type:string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
