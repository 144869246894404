export class Mfareq {

    symbol: string;
    policy: string;
    mod: string;
    mco: string;
    mfadate: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
