import { HttpParams } from '@angular/common/http';
import { GlobalConstants } from '../../../constants/global.constant';
import { BwappService } from '../../bwapp.service';
import * as i0 from "@angular/core";
import * as i1 from "../../bwapp.service";
var FindAgentsService = /** @class */ (function () {
    function FindAgentsService(appService) {
        this.appService = appService;
        this._baseURL = GlobalConstants.baseURL;
    }
    FindAgentsService.prototype.findAgents = function (zip) {
        var params = new HttpParams().set('zipcode', zip);
        return this.appService.get(GlobalConstants.BWQRY_GETAGENTLIST, params);
    };
    FindAgentsService.ngInjectableDef = i0.defineInjectable({ factory: function FindAgentsService_Factory() { return new FindAgentsService(i0.inject(i1.BwappService)); }, token: FindAgentsService, providedIn: "root" });
    return FindAgentsService;
}());
export { FindAgentsService };
