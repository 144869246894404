export var CREATE_LOGININFO = '[LOGININFO] Add';
export var UPDATE_LOGININFO = '[LOGININFO] Update';
export var REMOVE_LOGININFO = '[LOGININFO] Remove';
// Create Login Info upon User Login
var CreateLoginInfo = /** @class */ (function () {
    function CreateLoginInfo(payload) {
        this.payload = payload;
        this.type = CREATE_LOGININFO;
    }
    return CreateLoginInfo;
}());
export { CreateLoginInfo };
// Update Login Info
var UpdateLoginInfo = /** @class */ (function () {
    function UpdateLoginInfo(payload) {
        this.payload = payload;
        this.type = UPDATE_LOGININFO;
    }
    return UpdateLoginInfo;
}());
export { UpdateLoginInfo };
// Remove LoginInfo upon User Logout
var RemoveLoginInfo = /** @class */ (function () {
    function RemoveLoginInfo(payload) {
        this.payload = payload;
        this.type = REMOVE_LOGININFO;
    }
    return RemoveLoginInfo;
}());
export { RemoveLoginInfo };
