
export class Docuploadauditreq {
 uploadFileName: string;
 uploadFilePath: string;
 mco: string;
 mod: string;
 policyNumber: string;
 source: string;
 symbol: string;
 transactionCode: string;
 returnCode: string;
 returnMessage: string;

  constructor() {}
}
