import { OnInit } from '@angular/core';
import { MessageConstants } from '../../../constants/message.constant';
var PaymentheaderComponent = /** @class */ (function () {
    function PaymentheaderComponent() {
        this.isPrecancel = false;
    }
    PaymentheaderComponent.prototype.ngOnInit = function () {
        if (this.policystatus === MessageConstants.PENDING_CANCEL_STATUS) {
            this.isPrecancel = true;
        }
    };
    PaymentheaderComponent.prototype.getLastDayToPayDate = function () {
        return this.reInstateDueDate && (this.reInstateDueDate.includes("0001") || this.reInstateDueDate.includes("1899")) ? "" : this.reInstateDueDate;
    };
    PaymentheaderComponent.prototype.getLastPayDate = function () {
        return this.lastPaymentDate && (this.lastPaymentDate.includes("0001") || this.lastPaymentDate.includes("1899")) ? "" : this.lastPaymentDate;
    };
    return PaymentheaderComponent;
}());
export { PaymentheaderComponent };
