import { Component, OnInit } from '@angular/core';
import { DirectsalesService } from '../../shared/services/directsales.service';
import { ActivatedRoute } from '../../../../node_modules/@angular/router';

@Component({
  selector: 'app-sorry',
  templateUrl: './sorry.component.html',
  styleUrls: ['./sorry.component.scss'],
})
export class SorryComponent implements OnInit {

  mediaAlphazipCode: string;
  mediaalphaURL: string;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
   /*this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        console.log('Media Alpha Zip Code Sorry component---' + params['zipCode']);
        this.mediaAlphazipCode = params['zipCode'];
      }); */
      // this.mediaalphaURL = '../../../assets/html/test.html?zip={{mediaAlphazipCode}}';
      // this.loadScripts();
      /*var MediaAlphaExchange = {
        "placement_id": "FDiY-QmQ3q8uQvSqaMzipxDdi8VAaA",
        "version": "17",
        "type": "ad_unit",
        "ua_class": "auto",
        "data": {
            "zip": "90210"
        }
    }; */
   /* MediaAlphaExchange.success = function(numAds) {
      console.log("ads from mediaalpha", numAds);
    }; */
    // MediaAlphaExchange__load('target');
  }

  /*private loadScripts() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.dynamicScriptLoaderService.load('mediaalpha').then(data => {
      // Script Loaded Successfully
    }).catch(error => console.log(error));
  }*/

}
