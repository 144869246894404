import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { BwappService } from '../bwapp.service';
import { GlobalConstants } from '../../constants/global.constant';
import { GetEsignEligibilityReq } from '../../shared/model/api/esig/getesigneligibility.req.model';
import { GetEsignEligibilityRes } from '../../shared/model/api/esig/getesigneligibility.res.model';
import { EsigService } from '../api/esig/esig.service';
import { EdmrService } from '../api/edmr/edmr.service';
import { EnvelopesResp } from '../../shared/model/api/esig/esigenvelopres.model';
import { CommonUtil } from '../../shared/utils/commonutil';
import { InvokeEDMRDocsRes } from '../../shared/model/api/edmr/invokedocsres.model';
import { Tracker } from '../../shared/utils/tracker';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateEmbeddedEnvelopeRes } from '../../shared/model/api/esig/createembeddedenveloperes.model';
import { GoogleAnalyticsService } from '../../shared/services/analytics/google-analytics.service';
import { RecipientsToSignReq } from '../../shared/model/api/esig/recipientstosignreq.model';
import { BwstoreService } from '../../shared/services/bwstore.service';
import { NewUser } from '../../shared/model/newuser.model';
import { StateWorkflow } from '../../shared/model/stateworkflow.model';
import { CreatedEnvelope } from '../../shared/model/api/esig/createdEnvelope.model';
import { Envelope } from '../../shared/model/api/esig/envelop.model';
import { Recipient } from '../../shared/model/api/esig/recipient.model';

@Injectable({
  providedIn: 'root'
})
export class StateworkflowService {
  private globlalConstants = GlobalConstants;
  getEligibilityreq: GetEsignEligibilityReq = new GetEsignEligibilityReq();
  docTransactionCd: string;
  srcSys: string;
  newUser: NewUser;
  createEmbedEnvRes: CreateEmbeddedEnvelopeRes;
  createEmbedEnvResp: CreateEmbeddedEnvelopeRes;
  // docSeqNoArray: string[] = [];
  // private recipientsToSignReqArry: Array<RecipientsToSignReq>;
  // recipientsToSignReq: RecipientsToSignReq;
  constructor(private bwappService: BwappService,
    private esigService: EsigService,
    private edmrService: EdmrService,
    private router: Router,
    private route: ActivatedRoute,
    private googleAnalytics: GoogleAnalyticsService,
    private _storeService: BwstoreService
  ) {
        // Get the current state of NewUser
        this.newUser = this._storeService.getCurrentregistrationState();
  }

  // only for MI state
  stateMIWorkflow(mco: string, state: string, polSym: string, polNum: string, polMod: string, hasRenewal: boolean,
    library: string, firstName: string, lastName: string, flow: string, phEmail: string): Promise<StateWorkflow>  {
      this.googleAnalytics.trackEvent(flow, 'MIStateLogic', 'stateMIWorkflow', 200);
      return new Promise((resolve, reject) => {
        const stateWorkflowRes = new StateWorkflow();
        const docSeqNoArray: string[] = [];
        const recipientsToSignReqArray = [];
        const recipientsToSignReq = new RecipientsToSignReq();

        // let hasPendingEnvelops = false;
    this.getEligibilityreq.mco = mco;
    this.getEligibilityreq.policysymbol = polSym;
    this.getEligibilityreq.policynum = polNum;
    this.getEligibilityreq.policymod = polMod;
    this.getEligibilityreq.firstName = firstName;
    this.getEligibilityreq.lastName = lastName;
    this.getEligibilityreq.policycontext = library;
    this.getEligibilityreq.hasRenewal = hasRenewal;
    this.getEligibilityreq.srcsystem = this.globlalConstants.APPID;
    const policyNumber = polSym.concat(polNum).concat(polMod);
    this.esigService.getEsignEligibility(this.getEligibilityreq).subscribe((getEsignEligibilityRes: GetEsignEligibilityRes) => {
      Tracker.loginfo('Stateworkflow'
      , 'getEsignEligibility()', 'getEsignEligibility service:' + flow,
      'Response Payload' + JSON.stringify(getEsignEligibilityRes));
      if ((getEsignEligibilityRes.eligibilityIndicator === 'P' && hasRenewal) ||
        (getEsignEligibilityRes.eligibilityIndicator === 'N' && !hasRenewal)) {
        this.esigService.getEnvelops(firstName, lastName, policyNumber)
          .subscribe((envelopsres: EnvelopesResp) => {
            Tracker.loginfo('Stateworkflow'
            , 'getEnvelops()', 'getEnvelops service based on eligibilityIndicator:' + flow,
            'Response Payload' + JSON.stringify(envelopsres));
            if (envelopsres && envelopsres.status.toUpperCase() === 'SUCCESS'
            && envelopsres.envelopes.length === 0) { // when envelopes are not returned
              // } else { // when no pending evelopes are returned
                if (hasRenewal) {// Renewal
                  this.docTransactionCd = '';
                  this.srcSys = this.globlalConstants.APPID;
                } else { // NB
                  this.docTransactionCd = this.globlalConstants.EDMR_SIGNEDDOCUMENT_CD;
                  this.srcSys = this.globlalConstants.BWR_SYSTEM;
                }
                recipientsToSignReq.accessCode = '12345';
                recipientsToSignReq.emailAddress = phEmail;
                recipientsToSignReq.fullName = firstName + lastName;
                recipientsToSignReq.id = '1';
                recipientsToSignReq.recieptType = 'PrimaryNamedInsured';
                recipientsToSignReqArray.push(recipientsToSignReq);
                this.edmrService.invokeEDMRDocuments(policyNumber, this.docTransactionCd, this.srcSys)
                  .subscribe((invokeDocsRes: InvokeEDMRDocsRes) => {
                    Tracker.loginfo('Stateworkflow'
                    , 'invokeEDMRDocuments()', 'invokeEDMRDocuments service:' + flow,
                    'Response Payload' + JSON.stringify(invokeDocsRes));
                    docSeqNoArray[0] = invokeDocsRes.seqno;
                    if (invokeDocsRes && invokeDocsRes.responseStatus === '1' && invokeDocsRes.seqno !== '') {
                      this.esigService.createEmbeddedEnvelope(mco, policyNumber, library, hasRenewal,
                        docSeqNoArray, recipientsToSignReqArray)
                        .subscribe((data) => {
                          this.createEmbedEnvRes = data;
                          Tracker.loginfo('Stateworkflow'
                          , 'createEmbeddedEnvelope()', 'createEmbeddedEnvelope service when docseq no is NOT empty:' + flow,
                          'Response Payload:' + JSON.stringify(this.createEmbedEnvRes));
                          // Tracker.log('8. Invoked esigService.createEnvelopeAndWaitForVendor():::: ', createEnvRes);
                          if (this.createEmbedEnvRes && this.createEmbedEnvRes.status === 1 &&
                          this.createEmbedEnvRes.envelopeID !== null) {
                  this.createEmbedEnvRes.createdEnvelope.recipients.forEach(env => {
                                    this.newUser.envelopId = env.envelopeID;
                                    this.newUser.recipientId = env.recipientID;
                                    // update receiptid enveId status in the session
                                    this._storeService.updateRegistrationState(this.newUser);
                                    stateWorkflowRes.envelopeCreated = true;
                                    stateWorkflowRes.envelopeID = this.newUser.envelopId;
                                    stateWorkflowRes.recipientID = this.newUser.recipientId;
                                    this.createEnvelopeObj(this.createEmbedEnvRes.createdEnvelope).then((createenvs: Envelope[]) => {
                                    stateWorkflowRes.envelopes = createenvs;
                                    });
                                    resolve(stateWorkflowRes);
                            });
                          } else {
                                    stateWorkflowRes.envelopeCreated = false;
                                    resolve(stateWorkflowRes);
                          }
                        });
                    } else if (invokeDocsRes && invokeDocsRes.responseStatus === '0' &&
                      invokeDocsRes.errorMessageId === 'formservice.1010') {
                      if (!hasRenewal) {
                        this.edmrService.invokeEDMRDocuments(policyNumber, this.globlalConstants.EDMR_APPLICAIONPKG_CD, this.srcSys)
                          .subscribe((inDocsRes: InvokeEDMRDocsRes) => {
                            Tracker.loginfo('Stateworkflow'
                            , 'invokeEDMRDocuments()', 'invokeEDMRDocuments service for NB for errorCd:' + flow,
                            'Response Payload' + JSON.stringify(inDocsRes));
                            if (inDocsRes && inDocsRes.responseStatus === '1' && inDocsRes.seqno !== '') {
                              docSeqNoArray[0] = inDocsRes.seqno;
                              this.esigService.createEmbeddedEnvelope(mco, policyNumber, library,
                                hasRenewal, docSeqNoArray, recipientsToSignReqArray)
                                .subscribe((data1) => {
                                  this.createEmbedEnvResp = data1;
                                  Tracker.loginfo('Stateworkflow'
                                  , 'createEmbeddedEnvelope()', 'createEmbeddedEnvelope service for NB for errorCd:' + flow,
                                  'Response Payload:' + JSON.stringify(this.createEmbedEnvResp));
                                  if (this.createEmbedEnvResp && this.createEmbedEnvResp.status === 1 &&
                                   this.createEmbedEnvResp.envelopeID !== null) {
                             this.createEmbedEnvResp.createdEnvelope.recipients.forEach(env => {
                                      this.newUser.envelopId = env.envelopeID;
                                      this.newUser.recipientId = env.recipientID;
                                      // update receiptid enveId status in the session
                                      this._storeService.updateRegistrationState(this.newUser);

                                      stateWorkflowRes.envelopeCreated = true;
                                      stateWorkflowRes.envelopeID = this.newUser.envelopId;
                                      stateWorkflowRes.recipientID = this.newUser.recipientId;
                                      this.createEnvelopeObj(this.createEmbedEnvResp.createdEnvelope).then((createdenvs: Envelope[]) => {
                                        stateWorkflowRes.envelopes = createdenvs;
                                        });
                                      resolve(stateWorkflowRes);
                              });
                                  } else {
                                    stateWorkflowRes.envelopeCreated = false;
                                    resolve(stateWorkflowRes);
                                  }
                                });
                            } else {
                              stateWorkflowRes.envelopeCreated = false;
                              resolve(stateWorkflowRes);
                          }
                          }); // invokdeedmr call
                      } // renewal check for edmr

                    } else { // edmr errorcode check for renewal
                    stateWorkflowRes.envelopeCreated = false;
                    resolve(stateWorkflowRes);
                    }
                  }); // invokdeedmr call
               // }// when no pending enevlopes are in response from getEnvelope
            } else { // getEnvelope res check -when envelopes are returned in resp
               if (envelopsres && envelopsres.status.toUpperCase() === 'SUCCESS') {
                envelopsres.envelopes.forEach(env => {
                  env.recipients.forEach(rec => {
                    if (rec.recipType === 'PrimaryNamedInsured') {
                      if (rec.status === 'Completed' || rec.status === 'Declined' || rec.status === 'Signed') {
                        stateWorkflowRes.envelopeCreated = false;
                        resolve(stateWorkflowRes);
                      } else {
                        stateWorkflowRes.envelopeCreated = true;
                        stateWorkflowRes.envelopeID = env.envelopeID;
                        stateWorkflowRes.recipientID = rec.recipientID;
                        stateWorkflowRes.envelopes = envelopsres.envelopes;

                        this.newUser.envelopId = env.envelopeID;
                        this.newUser.recipientId = rec.recipientID;
                        this._storeService.updateRegistrationState(this.newUser);
                        resolve(stateWorkflowRes);
                      }
                    }
                  });
                });
              }
              }
            }); // getEnvelope
          } else { // esigneligibility check
           stateWorkflowRes.envelopeCreated = false;
           resolve(stateWorkflowRes);
            }
      }); // esignEligibility
    }); // stateWorkFlow promise
  } // stateWorkFlow

  createEnvelopeObj(createEmEvnv: CreatedEnvelope): Promise<Envelope[]> {
    return new Promise((resolve, reject) => {
      const envs = [];
      const rcvs = [];
      const env = new Envelope();
      const rec = new Recipient();
    env.envelopeID = createEmEvnv.envelopeID.toString();
    env.expireDate = createEmEvnv.expireDate;
    createEmEvnv.recipients.forEach(recp => {
             rec.envelopeID = recp.envelopeID.toString();
             rec.recipType = 'PrimaryNamedInsured'; // recp.recipType;
             rec.recipientID = recp.recipientID.toString();
             rec.status = recp.statusDescription;
      rcvs.push(rec);
      env.recipients = rcvs;
      envs.push(env);
    });
      resolve(envs);
    });
  }

} // main class
