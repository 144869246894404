export class FindPHRequest {
    pointHookID: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}

export class FindPHResponse {
    email: string;
    responseCode: string;
    responseText: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}

export class McoResponse {
    mco: string;
        constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}

export class SSOLoginRequest {
    status: string;
    ssot: string;
    error: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}

export class SSOOKTALoginRequest{
    usertype : string;
    code:string;
    redirect_uri:string;
    
    isaccesstokenrequired:boolean;
    
    
    constructor(values: Object = {}) {
        Object.assign(this, values);
    }

}

export class SSOOKTALoginResponse{
    access_token:string;
    code:string;
    description:string;
    error : SSOOKTAError;
    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
    
}
export class SSOOKTAError{
    error : string;
    error_description:string;
    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}

export class SSOTokenResponse {
    ssot: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}

