import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagenotfound',
  template: `
  <main id="main" class="main">
    <div class="containter text-center mt-0">
      <div class="row">
        <div class="col-12">
          <a href="#" class="btn btn-icon flex-column">
                  <span class="btn-icon-circle m-auto">
                    <span class="icon icon-svg mr-2 pb-5 icon-color-white">
                      <!--<i class="fas fa-file fa-stack-2x"></i>-->
                      <i class="fa fa-file fa-stack-1x fa-3x icon-align color-white"></i>
                    </span>
                  </span>
                  <span class="btn-icon-label text-center mt-2">
                  <h1 class="heading-1 mt-4">404 Page Not Found</h1>
                  </span>
                </a>
        </div>
      </div>
    </div>
  </main>
  `,
  styles: []
})
export class PagenotfoundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
