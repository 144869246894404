import { UserInfo } from '../../registration/userinfo.model';
import { SecurityAnswers } from '../../registration/securityanswers.model';

export class UpdatePHRequest {
    password: string;
    userInfo: UserInfo;
    currentEmail: string;
    currentPassword: string;
    securityAnswers: SecurityAnswers;
    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}

export class UpdatePHResponse {
    responseCode: string;
    responseMessage: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
