import { Injectable } from '@angular/core';
import { GlobalConstants } from '../../constants/global.constant';
import { AgentInfo, PolicyInfo, FullPolicyDetails } from '../../shared/model/api/bwibic/fullpolicydetails.model';
import { EmailValidateResponse } from '../../shared/model/api/emailvalidate/emailvalres.model';
import { CreateEPolicyRequest } from '../../shared/model/api/gopaperless/createepolicyreq.model';
import { IsGplRolledOutRes } from '../../shared/model/api/gopaperless/isgplrolledoutres.model';
import { UpdateEpolicyEmailResponse } from '../../shared/model/api/gopaperless/updateemailres.model';
import { Phonenumberdetails } from '../../shared/model/api/text-alerts/textalertenrollment.model';
import { PolicyHolder } from '../../shared/model/authentication/authenticatephres';
import { BWError } from '../../shared/model/error.model';
import { Gopaperlessauthres } from '../../shared/model/registration/gopaperlessauthres';
import { CommonUtil } from '../../shared/utils/commonutil';
import { BwSecurityService } from '../api/bwsecurity/bw-security.service';
import { EmailvalidateService } from '../api/emailvalidate/emailvalidate.service';
import { GoPaperlessService } from '../api/gopaperless/go-paperless.service';
import { PolicymgtService } from '../api/policymgmt/policymgt.service';
import { TextAlertsService } from '../api/text-alerts/text-alerts.service';
import { BwappService } from '../bwapp.service';
import { BWErrorhandler } from '../errorhandler';
import { Tracker } from '../../shared/utils/tracker';
import { Setepolicyflagreq } from '../../shared/model/api/gopaperless/setepolicyflagreq';
import { Policynumber } from '../../shared/model/policynumber';
import { PolicyholderService } from '../api/policyholder/policyholder.service';
import { UpdatePHRequest, UpdatePHResponse } from '../../shared/model/api/policyholder/updateph.model';
import { UserInfo } from '../../shared/model/registration/userinfo.model';
import { SecurityAnswers } from '../../shared/model/registration/securityanswers.model';
import { Authenticatephreq } from '../../shared/model/authentication/authenticatephreq';
import { UsmsecurityService } from '../api/usmsecurity/usmsecurity.service';
import { forkJoin } from 'rxjs';
import { PolicydetailsrestService } from '../api/policy/policydetailsrest.service';
import { MessageConstants } from '../../constants/message.constant';
import { RequesttocancelStoreInfo } from '../../shared/model/requesttocancelinfo.model';
import { RequestToCancelInfo } from '../../shared/model/api/policyinfo/requesttocancelchecksres.model';
import { CancelAmount } from '../../shared/model/api/policyinfo/requesttocancelamount.model';
import { BwstoreService } from '../../shared/services/bwstore.service';
import { Userpreferencesstatus } from '../../shared/model/userpreferencesstatus.model';
import { PolicyeftService } from '../api/policyeft/policyeft.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyprofileService {
  appService: BwappService;
  gopaperlessauthres: Gopaperlessauthres;
  isGplRolledOutRes: IsGplRolledOutRes;
  globalConstants = GlobalConstants;
  setepolicyflagreq: Setepolicyflagreq;
  userpreferencesStatus: Userpreferencesstatus;
  policyHolder: PolicyHolder;

  requestToCancelPolicyStoreInfo: RequesttocancelStoreInfo;
  requesToCancelInfo: RequestToCancelInfo;
  cancelAmountInfo: CancelAmount;
  private usermessage = MessageConstants;

  constructor(
    private goPaperlessService: GoPaperlessService,
    private policymgtService: PolicymgtService,
    private emailValidatorService: EmailvalidateService,
    private errorHandler: BWErrorhandler,
    private bwSecurityService: BwSecurityService,
    private textAlertsService: TextAlertsService,
    private policyHolderService: PolicyholderService,
    private usmSecurityService: UsmsecurityService,
    private policyDetailsService: PolicydetailsrestService,
    private policyEftService: PolicyeftService,
    private bwStoreService: BwstoreService
  ) { }

  // TextAlerts Enrollments Status, GoPaperless Enrollment status, Cancel Policy Eligibility, EFT Status
  getPreferencesStatus(policyDetails: FullPolicyDetails, policyHolderInfo: PolicyHolder): Promise<Userpreferencesstatus> {

    return new Promise((resolve, reject) => {

      const mco = policyDetails.policyInfo.MASTER0CO;
      const bwPolNum = policyDetails.policyInfo.PolicyNumber;
      const policyState = policyHolderInfo.riskstate;
      const rateBook = policyDetails.policyInfo.PolicyRateBook;
      const agentInfo = policyDetails.agentInfo;

      this.policyHolder = policyHolderInfo;
      this.userpreferencesStatus = new Userpreferencesstatus();

      forkJoin(this.textAlertsService.checkStateEnabled(policyState, mco, rateBook),
              this.goPaperlessService.getAutoAuthValues(new Policynumber(bwPolNum).toString(), mco),
              this.policyDetailsService.getRequestToCancelChecks(new Policynumber(bwPolNum).toString(), mco),
              this.policyEftService.getEFTStatus(new Policynumber(bwPolNum).toString(), mco))
              .subscribe(([checkstateenabledres, gopaperlessauthres, requesttocancelchecksres, eftStatusRes]) => {

                console.log('GoPapaperless : ', gopaperlessauthres, '   Request To Cancel : ', requesttocancelchecksres,
                                      ' Text Alerts State Enabled : ', checkstateenabledres);

                  if ( checkstateenabledres.checkStateEnabledResult ) {
                    this.policyHolder.textAlertStateEligible = '' + checkstateenabledres.checkStateEnabledResult;
                    this.userpreferencesStatus.textAlertStateEligible = '' + checkstateenabledres.checkStateEnabledResult;
                    this.textAlertsService.
                          GetTextAlertEnrollmentStatus(agentInfo.AgentCode, agentInfo.AgentType, mco, policyState, bwPolNum)
                      .subscribe(textAlertRes => {
                        this.policyHolder.textAlertsEnrollment = textAlertRes.length === 0 ? 'false' : 'true';
                        this.userpreferencesStatus.textAlertsEnrollment = textAlertRes.length === 0 ? 'false' : 'true';

                        // If there are phone store them in store
                        if (textAlertRes.length > 0) {
                          this.policyHolder.textAlertEnrolledPhones = textAlertRes;
                        }

                        if (gopaperlessauthres) {
                          // Setting Gopaperless Response
                          this.policyHolder.gopaperlessEnrollment = gopaperlessauthres.getAutoAuthValuesResult;
                          this.userpreferencesStatus.gopaperlessEnrollment = gopaperlessauthres.getAutoAuthValuesResult;
                        }

                        if (eftStatusRes && eftStatusRes.eftStatusResult === GlobalConstants.EFT_ENROLLED) {
                          this.userpreferencesStatus.eftStatus = GlobalConstants.EFT_ENROLLED;
                          this.policyHolder.eftStatus = GlobalConstants.EFT_ENROLLED;
                        } else {
                          this.userpreferencesStatus.eftStatus = GlobalConstants.EFT_NOT_ENROLLED;
                          this.policyHolder.eftStatus = GlobalConstants.EFT_NOT_ENROLLED;
                        }

                        console.log('Request To Cancel Res: : ', requesttocancelchecksres.Result);
                        // Setting Request To Cancel Reponse
                        if ( requesttocancelchecksres && requesttocancelchecksres.Result === 'success') {

                          const isEligibleToCancel = requesttocancelchecksres.RequestToCancelInfo.IsCancelEligible
                                                        && !requesttocancelchecksres.RequestToCancelInfo.HasPendingCancelEndorsement
                                                        ? true : false;
                          this.policyHolder.requestToCanceEligible = '' + isEligibleToCancel;
                          this.userpreferencesStatus.requestToCanceEligible = '' + isEligibleToCancel;
                          this.requestToCancelPolicyStoreInfo = new RequesttocancelStoreInfo();
                          this.requesToCancelInfo = new RequestToCancelInfo();
                          this.requesToCancelInfo = requesttocancelchecksres.RequestToCancelInfo;
                          this.requestToCancelPolicyStoreInfo.cancelChecks = this.requesToCancelInfo;
                          this.bwStoreService.addRequestToCancelPolicyInfo(this.requestToCancelPolicyStoreInfo);
                          this.bwStoreService.updateLoginInfo(policyHolderInfo);
                        } else {
                          return reject(this.errorHandler.handleApplicationError(new BWError(
                            {'errorcode': 'RequestToCancel', 'errordesc': requesttocancelchecksres.Message,
                                                            'source': 'PolicyDetailsService'})));
                        }

                        resolve(this.userpreferencesStatus);

                      },
                      error => {
                        return reject(this.errorHandler.handleApplicationError(new BWError({'errorcode': 'TextAlertsService',
                        'errordesc': 'Error occured while retrieving Text Alerts Enrollment Status. Please try again',
                                'source': 'TextAlertsService'})));
                      });
                  } else {

                    // Setting  the Text Alerts State Eligibility
                    this.policyHolder.textAlertStateEligible = 'false';
                    this.userpreferencesStatus.textAlertStateEligible = 'false';

                    // Setting the Text Alerts Enrollment Status
                    this.policyHolder.textAlertsEnrollment = 'false';
                    this.userpreferencesStatus.textAlertsEnrollment = 'false';

                     // Setting Gopaperless Response
                    if (gopaperlessauthres) {
                      this.policyHolder.gopaperlessEnrollment = gopaperlessauthres.getAutoAuthValuesResult;
                      this.userpreferencesStatus.gopaperlessEnrollment = gopaperlessauthres.getAutoAuthValuesResult;
                    }

                    if (eftStatusRes && eftStatusRes.eftStatusResult === GlobalConstants.EFT_ENROLLED) {
                      this.userpreferencesStatus.eftStatus = eftStatusRes.eftStatusResult;
                      this.policyHolder.eftStatus = eftStatusRes.eftStatusResult;
                    } else {
                      this.userpreferencesStatus.eftStatus = GlobalConstants.EFT_NOT_ENROLLED;
                      this.policyHolder.eftStatus = eftStatusRes.eftStatusResult;
                    }

                    // Setting Request To Cancel Reponse
                    if ( requesttocancelchecksres && requesttocancelchecksres.Result === 'success') {
                      this.policyHolder.requestToCanceEligible = '' + requesttocancelchecksres.RequestToCancelInfo.IsCancelEligible;
                      this.userpreferencesStatus.requestToCanceEligible = '' +
                                                                        requesttocancelchecksres.RequestToCancelInfo.IsCancelEligible;
                      this.requestToCancelPolicyStoreInfo = new RequesttocancelStoreInfo();
                      this.requesToCancelInfo = new RequestToCancelInfo();
                      this.requesToCancelInfo = requesttocancelchecksres.RequestToCancelInfo;
                      this.requestToCancelPolicyStoreInfo.cancelChecks = this.requesToCancelInfo;
                      this.bwStoreService.addRequestToCancelPolicyInfo(this.requestToCancelPolicyStoreInfo);
                      this.bwStoreService.updateLoginInfo(policyHolderInfo);
                    } else {
                      return reject(this.errorHandler.handleApplicationError(new BWError(
                        {'errorcode': 'RequestToCancel', 'errordesc': requesttocancelchecksres.Message,
                                                        'source': 'PolicyDetailsService'})));
                    }

                    resolve(this.userpreferencesStatus);
                  }
              },
              error => {
                return reject(error);
              });

    });
  }

  getAutoAuthValues(policynumber: string, mco: string): Promise<Gopaperlessauthres> {
    return new Promise((resolve, reject) => {
      this.goPaperlessService.getAutoAuthValues(policynumber, mco).subscribe(gopaperlessauthres => {
          if (gopaperlessauthres.getAutoAuthValuesResult === 'null') {
            return reject(
              this.errorHandler.handleApplicationError(
                new BWError({
                  errorcode: '100',
                  errordesc: gopaperlessauthres.getAutoAuthValuesResult,
                  source: 'MyprofileService'
                })
              )
            );
          } else {
            resolve(gopaperlessauthres);
          }
        });
    });
  }

  getStatePaperDocFee(state: string, mco: string, rateBook: string, lob: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.policymgtService
        .getStatePaperDocFee(state, mco, rateBook, lob)
        .subscribe(data => {
          if (data === 'null') {
            return reject(
              this.errorHandler.handleApplicationError(
                new BWError({
                  errorcode: '100',
                  errordesc: data,
                  source: 'MyprofileService'
                })
              )
            );
          } else {
            resolve(data);
          }
        });
    });
  }

  unenrollGP(policynumber: string, mco: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.goPaperlessService.unenrollGP(policynumber, mco).subscribe(data => {
        // Tracker.log('UnenrollGP response :: ', data);
        if (data === 'null') {
          // Tracker.log('The services retured something else....', data);
          return reject(
            this.errorHandler.handleApplicationError(
              new BWError({
                errorcode: '100',
                errordesc: data,
                source: 'MyprofileService'
              })
            )
          );
        } else {
          resolve(data);
        }
      });
    });
  }

  enrollGP(email: string, authenticatedPhDetails: PolicyHolder, policyInfo: PolicyInfo, agentInfo: AgentInfo): Promise<any> {
    return new Promise((resolve, reject) => {
      const mco = CommonUtil.getMCO(authenticatedPhDetails);
      const bwPolNum = CommonUtil.getBWPolicyNumber(authenticatedPhDetails);
      const producercode = policyInfo.PolicyAgentCode;
      const state = authenticatedPhDetails.riskstate;
      const ratebook = policyInfo.PolicyRateBook;
      const pco = policyInfo.PolicyCompany;
      const createEPolicyRequest = new CreateEPolicyRequest();

      this.emailValidatorService.validateEmail(email, bwPolNum, mco, producercode).subscribe((validateres: EmailValidateResponse) => {
            Tracker.loginfo('MyprofileService', 'enrollGP', 'Brite Verify Email Validation service call',
                                  'Response from validateEmail()' + JSON.stringify(validateres));
        if (validateres && (validateres.status === 'VALID' || validateres.status === 'ERROR_SERVICE' ||
          validateres.status === 'ERROR_VENDOR' || validateres.responseCode === 'E0000007' ||
          validateres.responseCode === 'E' || validateres.responseCode === 'S')) {
              this.goPaperlessService.isGPLRolledOut(state, ratebook, mco, pco).subscribe((isGplRolledOutRes: IsGplRolledOutRes) => {
                  Tracker.loginfo('MyprofileService', 'enrollGP', 'isGPLRolledOut service call',
                                                                    'Response from isGPLRolledOut()' + isGplRolledOutRes);
                  if (isGplRolledOutRes) {
                    createEPolicyRequest.szSource = this.globalConstants.APPID;
                    createEPolicyRequest.szLocation = policyInfo.Location;
                    createEPolicyRequest.szPolicyCo = policyInfo.PolicyCompany;
                    createEPolicyRequest.szPolicyNbr = bwPolNum;
                    createEPolicyRequest.szMCO = mco;
                    createEPolicyRequest.szEmail = email;
                    createEPolicyRequest.szAgency = policyInfo.PolicyAgentCode;

                    createEPolicyRequest.szFirstName = authenticatedPhDetails.firstName;
                    createEPolicyRequest.szLastName = authenticatedPhDetails.lastName;
                    createEPolicyRequest.blnMidTermENR = 'true';
                    createEPolicyRequest.szDiscountFlag = isGplRolledOutRes.strDiscount;
                    createEPolicyRequest.szAgentType = agentInfo.AgentType;

                    this.goPaperlessService.enrollGP(createEPolicyRequest).subscribe(data => {
                        Tracker.loginfo('MyprofileService', 'enrollGP', 'enrollGP service call', 'Response from enrollGP()' +
                                                                                                                JSON.stringify(data));
                        if (data.createEpolicyCustomerResult === true) {
                          Tracker.loginfo('MyprofileService', 'enrollGP', 'enrollGP service call',
                                                                    'Enrollment Successful' + data.strResponse);

                          this.setepolicyflagreq = new Setepolicyflagreq();
                          const policynum = new Policynumber(policyInfo.PolicyNumber);
                          this.setepolicyflagreq.blnMidTermENR = 'false';
                          this.setepolicyflagreq.szEmail = email;
                          this.setepolicyflagreq.szMCO = mco;
                          this.setepolicyflagreq.szPolicyNbr = policynum.toString();
                          this.setepolicyflagreq.szSource = GlobalConstants.APPID;

                          this.goPaperlessService.setEPolicyFlag(this.setepolicyflagreq).subscribe(setepolres => {
                            Tracker.loginfo('MyprofileService', 'enrollGP', 'setEPolicyFlag service call',
                                                          'setEPolicyFlag service response' + JSON.stringify(setepolres));
                              if (setepolres) {
                                 this.goPaperlessService.updateEPolicyEmail(email, bwPolNum, mco)
                                    .subscribe((res: UpdateEpolicyEmailResponse) => {
                                    Tracker.loginfo('MyprofileService', 'enrollGP', 'updateEPolicyEmail service call',
                                      'updateEPolicyEmail service response' + JSON.stringify(res));
                                    if (res.updateEpolicyEmailResult === true) {
                                      this.bwSecurityService.getPolicyLocationStatusandMaxMod(bwPolNum).subscribe(datap => {
                                          Tracker.loginfo('MyprofileService', 'enrollGP', 'getPolicyLocationStatusandMaxMod service call',
                                            'getPolicyLocationStatusandMaxMod service response' + JSON.stringify(datap));
                                          if (datap.getPolicyLocationStatusAndMaxModResult === true && datap.status === 'V') {
                                            this.bwSecurityService.changeUserEmail_POINT_GP('N', email, bwPolNum, mco)
                                              .subscribe(datag => {
                                                Tracker.loginfo('MyprofileService', 'enrollGP', 'changeUserEmail_POINT_GP service call',
                                                  'changeUserEmail_POINT_GP service response' + JSON.stringify(datag));
                                                  resolve('You are now enrolled in Go Paperless');
                                              });
                                          } else if (datap.getPolicyLocationStatusAndMaxModResult === true && datap.status === 'P') {
                                            this.bwSecurityService.changeUserEmail_POINT_IN_STAGING('3', email, bwPolNum, mco)
                                              .subscribe(datagp => {
                                                Tracker.loginfo('MyprofileService', 'enrollGP',
                                                    'changeUserEmail_POINT_IN_STAGING service call',
                                                'changeUserEmail_POINT_IN_STAGING service response' + JSON.stringify(datagp));
                                                resolve('You are now enrolled in Go Paperless');
                                              });
                                          } else {
                                            return reject(
                                              this.errorHandler.handleApplicationError(
                                                new BWError({
                                                  errorcode: '100',
                                                  errordesc:
                                                    'There is issue while retrieving the status of the Policy',
                                                  source: 'bwSecurityService'
                                                })
                                              )
                                            );
                                          }
                                        });
                                    } else {
                                      return reject(
                                        this.errorHandler.handleApplicationError(
                                          new BWError({
                                            errorcode: '100',
                                            errordesc:
                                              'There is technical issue while Updating the Policy',
                                            source: 'GoPaperlessService'
                                          })
                                        )
                                      );
                                    }
                                  });
                          } else {
                            return reject(
                              this.errorHandler.handleApplicationError(
                                new BWError({
                                  errorcode: '100',
                                  errordesc:
                                    'Unable to Update the Go Paperless Policy flag',
                                  source: 'GoPaperlessService'
                                })
                              )
                            );
                          }
                        });
                      } else {
                          return reject(
                            this.errorHandler.handleApplicationError(
                              new BWError({
                                errorcode: '100',
                                errordesc: 'Your request could not be processed, please contact your agent for assistance.',
                                source: 'MyprofileService'
                              })
                            )
                          );
                        }
                      });
                  } else {
                    return reject(
                      this.errorHandler.handleApplicationError(
                        new BWError({
                          errorcode: '100',
                          errordesc:
                            'There is some error while invoking the isGPLRolledOut service',
                          source: 'GoPaperlessService'
                        })
                      )
                    );
                  }
                });
            } else {
              return reject(
                this.errorHandler.handleApplicationError(
                  new BWError({
                    errorcode: '100',
                    errordesc: 'Email is Not Valid',
                    source: 'MyprofileService'
                  })
                )
              );
            }
          },
          error => {
            return reject(error);
          }
        );
    });
  }

  getTextAlertEnrollmentStatus(
    state: string,
    mco: string,
    rateBook: string,
    agentInfo: AgentInfo,
    policyNumber: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      // Tracker.log('Response :: ', data);
      this.textAlertsService.GetTextAlertEnrollmentStatus(agentInfo.AgentCode, agentInfo.AgentType,
        mco, state, policyNumber).subscribe
        (textAlertRes => {
          resolve(textAlertRes);
        },
        error => {
          return reject(error);
        });
    });
  }

  manageTextAlertEnrollment(state: string, mco: string, agentInfo: AgentInfo, policyNumber: string,
    phoneNumbers: Phonenumberdetails[]): Promise<any> {
    return new Promise((resolve, reject) => {
      this.textAlertsService.ManageTextAlertEnrollment(agentInfo.AgentCode, agentInfo.AgentType,
        mco, state, policyNumber, phoneNumbers).subscribe
        (textAlertEnrollmentRes => {
          if (textAlertEnrollmentRes.manageTextAlertEnrollmentResult === true) {
            this.textAlertsService.GetTextAlertEnrollmentStatus(agentInfo.AgentCode, agentInfo.AgentType,
              mco, state, policyNumber).subscribe(textAlerStatustRes => {
                resolve(textAlerStatustRes);
              });

          } else {
            // Tracker.log('The services retured something else....', data);
            return reject(
              this.errorHandler.handleApplicationError(
                new BWError({
                  errorcode: '100',
                  errordesc: 'An unexpected error occured.Please try again later!',
                  source: 'MyprofileService'
                })
              )
            );
          }
        });
    });
  }

  checkStateEnabled(
    state: string,
    mco: string,
    rateBook: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.textAlertsService
        .checkStateEnabled(state, mco, rateBook)
        .subscribe(data => {
          resolve(data.checkStateEnabledResult);
        },
          error => {
            return reject(error);
          }
        );
    });
  }

  updateUserID(
    email: string,
    password: string,
    authenticatedPhDetails: PolicyHolder,
    policyInfo: PolicyInfo
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const mco = CommonUtil.getMCO(authenticatedPhDetails);
      const bwPolNum = CommonUtil.getBWPolicyNumber(authenticatedPhDetails);
      const updatePHRequest = new UpdatePHRequest();
      const userInfo = new UserInfo();
      userInfo.email = email;
      const producercode = policyInfo.PolicyAgentCode;
      userInfo.pointHookId = authenticatedPhDetails.pointHookID;
      userInfo.question1 = authenticatedPhDetails.question1;
      userInfo.question2 = authenticatedPhDetails.question2;
      userInfo.question3 = authenticatedPhDetails.question3;
      updatePHRequest.currentEmail = authenticatedPhDetails.email;
      updatePHRequest.currentPassword = password;
      updatePHRequest.password = password;
      updatePHRequest.userInfo = userInfo;
      this.emailValidatorService
      .validateEmail(email, bwPolNum, mco, producercode)
      .subscribe(
        (validateres: EmailValidateResponse) => {
          Tracker.loginfo('MyprofileService', 'updateUserID', 'Brite Verify Email Validation service call',
            'Response from validateEmail()' + JSON.stringify(validateres));
          if (
            validateres &&
            (validateres.status === 'VALID' ||
              validateres.status === 'ERROR_SERVICE' ||
              validateres.status === 'ERROR_VENDOR' ||
              validateres.responseCode === 'E0000007' ||
              validateres.responseCode === 'E')
          ) {
                this.policyHolderService.updatePH(updatePHRequest)
                  .subscribe(
                    (updatePHRes: UpdatePHResponse) => {
                      Tracker.loginfo('MyprofileService', 'updateUserID', 'Update User ID service call',
                        'Response from updatePH()' + JSON.stringify(updatePHRes));
                      if (updatePHRes && (updatePHRes.responseCode === '-1' || updatePHRes.responseCode ==='S')) {
                        this.bwSecurityService
                          .changeUserEmail_POINT_GP(
                            'N',
                            email,
                            bwPolNum,
                            mco
                          )
                          .subscribe(datag => {
                            Tracker.loginfo('MyprofileService', 'updateUserID', 'changeUserEmail_POINT_GP service call',
                              'changeUserEmail_POINT_GP service response' + JSON.stringify(datag));
                            if (datag.szResponse === '00' || datag.szResponse === '01') {
                              this.goPaperlessService
                                .getAutoAuthValues(bwPolNum, mco)
                                .subscribe(gopaperlessauthres => {
                                  // Tracker.log('Gopaperlessauthres :: ', gopaperlessauthres);
                                  if (gopaperlessauthres.getAutoAuthValuesResult === 'P' ||
                                    gopaperlessauthres.getAutoAuthValuesResult === 'V') {
                                    this.goPaperlessService
                                      .updateEPolicyEmail(email, bwPolNum, mco)
                                      .subscribe((res: UpdateEpolicyEmailResponse) => {
                                        Tracker.loginfo('MyprofileService', 'updateUserID', 'updateEPolicyEmail service call',
                                          'updateEPolicyEmail service response' + JSON.stringify(res));
                                      });
                                  }
                                });
                            } else {
                              return reject(
                                this.errorHandler.handleApplicationError(
                                  new BWError({
                                    errorcode: '100',
                                    errordesc:
                                      'User ID was not updated',
                                    source: 'GoPaperlessService'
                                  })
                                )
                              );
                            }
                          });
                        return resolve('User Id was successfully Updated');
                      } else if (updatePHRes && (updatePHRes.responseCode === '21' || updatePHRes.responseCode === 'E')) {
                        return reject(
                          this.usermessage.EMAIL_REGISTERED
                        );
                      } else {
                        return reject(
                          this.errorHandler.handleApplicationError(
                            new BWError({
                              errorcode: '100',
                              errordesc: 'No Changes Made',
                              source: 'MyprofileService'
                            })
                          )
                        );
                      }
                });
              } else {
                if(environment.bwOktaEnabled){
                  const errStatus = validateres.responseText==='invalid'? this.usermessage.INVALID_EMAIL:this.usermessage.EMAIL_REGISTERED;
                  return reject(errStatus);
                }else{
                  return reject(this.usermessage.INVALID_EMAIL);
                }
              
              }
          },
          error => {
            return reject(error);
          }
        );
    });
  }
  // this funtions prepares the post body that is required to update policy holder rest call
  modifySecurityQuestions(values: any, authenticatedPhDetails: PolicyHolder, confirmPassword: string) {
    return new Promise((resolve, reject) => {
      const updatePHRequest = new UpdatePHRequest();
      const userInfo = new UserInfo();
      const answers: SecurityAnswers = { answer1: values.phsecans1, answer2: values.phsecans2, answer3: values.phsecans3 };
      userInfo.email = authenticatedPhDetails.email;
      userInfo.pointHookId = authenticatedPhDetails.pointHookID;
      userInfo.question1 = values.phsecqsn1;
      userInfo.question2 = values.phsecqsn2;
      userInfo.question3 = values.phsecqsn3;
      updatePHRequest.currentEmail = authenticatedPhDetails.email;
      updatePHRequest.currentPassword = confirmPassword;
      updatePHRequest.password = confirmPassword;
      updatePHRequest.securityAnswers = answers;
      updatePHRequest.userInfo = userInfo;
      this.policyHolderService.updatePH(updatePHRequest).subscribe((updateSecurityQuesRes: any) => {
        return resolve(updateSecurityQuesRes);
      }, error => {
        return reject(error);
      });
    });
  }

  updatePassword(
    newPassword: string,
    currPassword: string,
    authenticatedPhDetails: PolicyHolder
  ): Promise<any> {
    return new Promise((resolve, reject) => {

      const mco = CommonUtil.getMCO(authenticatedPhDetails);
      const bwPolNum = CommonUtil.getBWPolicyNumber(authenticatedPhDetails);
      const updatePHRequest = new UpdatePHRequest();
      const userInfo = new UserInfo();
      userInfo.email = authenticatedPhDetails.email;
      userInfo.pointHookId = authenticatedPhDetails.pointHookID;
      userInfo.question1 = authenticatedPhDetails.question1;
      userInfo.question2 = authenticatedPhDetails.question2;
      userInfo.question3 = authenticatedPhDetails.question3;

      // Invoke authenticatePH to validate current password
      const authPHRequest = new Authenticatephreq();
      authPHRequest.appID = GlobalConstants.APPID;
      authPHRequest.userName = authenticatedPhDetails.email;
      authPHRequest.password = currPassword;
      this.usmSecurityService.authenticatePH(authPHRequest).
        subscribe(
          data => {
            Tracker.loginfo('ConfirmPasswordComponent', 'onSubmit', '',
              'Response from authenticatePH()' + data.authenticatePolicyHolderResult);
            if (data.authenticatePolicyHolderResult === true) {
              // After current password successfull validation update the new password.
              updatePHRequest.currentEmail = authenticatedPhDetails.email;
              updatePHRequest.currentPassword = currPassword;
              updatePHRequest.password = newPassword;
              updatePHRequest.userInfo = userInfo;
              this.policyHolderService.updatePH(updatePHRequest)
                .subscribe((updatePHRes: UpdatePHResponse) => {
                  Tracker.loginfo('MyprofileService', 'updateUserID', 'Update Password service call',
                    'Response from updatePH()' + JSON.stringify(updatePHRes));
                  if (updatePHRes && (updatePHRes.responseCode === '-1' || updatePHRes.responseCode === 'S')) {
                    return resolve('Password was successfully Updated');
                  } else {
                    return reject(
                      this.usermessage.USER_CHANGE_GENERIC_ERROR
                    );
                  }
                },
                  error => {
                    return reject(error);
                  }
                );
            } else {
              return reject(
                this.usermessage.INVALID_CURRENT_PASSWORD
              );
            }
          },
          (error) => {
            if (error.error.code === 'E0000004') {
              return reject(this.usermessage.INVALID_CURRENT_PASSWORD);
            }
          })
    });
  }
}
