import { OnInit } from '@angular/core';
import { BwstoreService } from '../../services/bwstore.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FullPolicyDetails } from '../../model/api/bwibic/fullpolicydetails.model';
import { AuthenticationService } from '../../services/authentication.service';
import { Policynumber } from '../../model/policynumber';
import { Tracker } from '../../utils/tracker';
import { CommonUtil } from '../../utils/commonutil';
import { GlobalConstants } from '../../../constants/global.constant';
import { PolicyholderService } from '../../../services/api/policyholder/policyholder.service';
import { CheckEachAnswerRequest } from '../../model/api/policyholder/checkeachanswer.model';
import { RemovePHRequest } from '../../model/api/policyholder/removeph.model';
import { environment } from '../../../../environments/environment';
import { GoogleAnalyticsService } from '../../services/analytics/google-analytics.service';
var LoginMfaComponent = /** @class */ (function () {
    function LoginMfaComponent(authService, plcyhlderService, storeService, fb, router, googleAnalytics) {
        this.authService = authService;
        this.plcyhlderService = plcyhlderService;
        this.storeService = storeService;
        this.fb = fb;
        this.router = router;
        this.googleAnalytics = googleAnalytics;
        this.errorMessage = '';
        this.answerTryCount = 1;
        this.questionNumber = 0;
        this.showRegistrationMessage = false;
        this.loaded = true;
        this.createMFAForm();
    }
    LoginMfaComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authService.phUserObject.subscribe(function (data) {
            _this.phUserObject = data;
            _this.userQuestions = [
                { 'id': 1, 'question': _this.phUserObject.question1, 'tries': 1 },
                { 'id': 2, 'question': _this.phUserObject.question2, 'tries': 1 },
                { 'id': 3, 'question': _this.phUserObject.question3, 'tries': 1 }
            ];
        });
        if (this.phUserObject === undefined) {
            return this.router.navigate(['/policy']);
        }
        this.currentsecquestion = this.userQuestions[0].question;
    };
    LoginMfaComponent.prototype.onSubmit = function (formData) {
        var _this = this;
        this.formSubmitAttempt = true;
        var testVar = false;
        if (this.mfaForm.valid) {
            this.loaded = false;
            // Verify the answer is correct
            this.googleAnalytics.trackEvent('MFA', 'ChageSecurityQuestion', 'clickonAnswerSubmit', 200);
            var policyNumber_1 = new Policynumber(this.phUserObject.pointHookID.substring(2).
                concat(this.phUserObject.policyMod));
            var checkEachAnswerRequest = new CheckEachAnswerRequest();
            checkEachAnswerRequest.userName = this.phUserObject.email;
            checkEachAnswerRequest.answer = formData.answer;
            // PolicyHolderLDAP checkAnswer
            this.plcyhlderService.checkEachAnswer(checkEachAnswerRequest)
                .subscribe(function (checkAnswerRes) {
                if (checkAnswerRes.responseCode === '-1') {
                    _this.loaded = true;
                    // correctly answered
                    _this.authService._setLoginStatus(true);
                    // web session log for mfa
                    _this.authService.createWebSessLogMFA(_this.phUserObject, true);
                    var newMFADate = CommonUtil.addDateNowEST(GlobalConstants.MFA_DAYS);
                    // Update MFA Date - Fire and Forget
                    _this.plcyhlderService.updateMFADate(policyNumber_1.symbol, policyNumber_1.policy, policyNumber_1.module, _this.phUserObject.pointHookID.substring(0, 2), newMFADate)
                        .subscribe(function (mfaDateData) {
                        _this.mfaRes = mfaDateData;
                        _this.storeService.createLoginInfo(_this.phUserObject);
                        if (_this.phUserObject.gopaperlessInd
                            && _this.phUserObject.gopaperlessInd === true) {
                            return _this.router.navigate(['/gptermsnconditions']);
                        }
                        else if (_this.phUserObject
                            && _this.phUserObject.esigstatus === true) {
                            return _this.router.navigate(['/esigmessages']);
                        }
                        else {
                            _this.storeService.deletePolicyInfo(new FullPolicyDetails());
                            return _this.router.navigate(['/policy']);
                        }
                    }, function (error) {
                        _this.loaded = true;
                        _this.googleAnalytics.trackEvent('Login', 'MFA', 'LoginAttempt', 500);
                        Tracker.logerror('LoginMfaComponent', 'onSubmit', 'updateMFADate', 'Error updating MFA Date.', error);
                    });
                }
                else {
                    _this.loaded = true;
                    if (_this.userQuestions[_this.questionNumber].tries < 3) {
                        switch (_this.userQuestions[_this.questionNumber].tries) {
                            case 1:
                            case 2: {
                                _this.errorMessage = '';
                                _this.formSubmitAttempt = false;
                                _this.mfaForm.reset();
                                _this.userQuestions[_this.questionNumber].tries++;
                                _this.errorMessage = 'Incorrect answer provided';
                                _this.googleAnalytics.trackEvent('Login', 'MFA', 'LoginAttempt', 200);
                                break;
                            }
                            case 3: {
                                _this.errorMessage = '';
                                _this.showRegistrationMessage = true;
                                // remove all session info
                                _this.storeService.removeUserSessionFromBrowserSession();
                                _this.storeService.deletePolicyInfo(new FullPolicyDetails());
                                _this.authService.logout();
                                _this.googleAnalytics.trackEvent('Login', 'MFA', 'LoginAttempt', 200);
                                break;
                            }
                        }
                        _this.answerTryCount++;
                    }
                    else {
                        if (_this.questionNumber < 2) {
                            _this.mfaForm.reset();
                            _this.errorMessage = '';
                            _this.currentsecquestion = _this.userQuestions[++_this.questionNumber].question;
                        }
                        else {
                            // over 3 tries show message for registration
                            // web session log for mfa
                            _this.googleAnalytics.trackEvent('Login', 'MFA', 'LoginAttempt', 200);
                            _this.errorMessage = '';
                            _this.authService.createWebSessLogMFA(_this.phUserObject, false);
                            _this.showRegistrationMessage = true;
                        }
                    }
                }
            }, function (error) {
                _this.loaded = true;
                _this.googleAnalytics.trackEvent('Login', 'MFA', 'LoginAttempt', 500);
                Tracker.logerror('LoginMfaComponent', 'onSubmit', 'checkEachAnswer', 'Error verifying the answer.', error);
            });
        }
    };
    LoginMfaComponent.prototype.showNextQuestion = function (mfaForm) {
        if (this.questionNumber < 2) {
            this.googleAnalytics.trackEvent('Login', 'MFA', 'LoginAttempt', 200);
            this.mfaForm.reset();
            this.errorMessage = '';
            this.userQuestions[this.questionNumber].tries = 3;
            this.currentsecquestion = this.userQuestions[++this.questionNumber].question;
        }
    };
    LoginMfaComponent.prototype.createMFAForm = function () {
        this.mfaForm = this.fb.group({
            answer: ['', Validators.compose([Validators.required])
            ],
        });
    };
    // Form Utilities
    LoginMfaComponent.prototype.isFieldValid = function (field) {
        return ((!this.mfaForm.get(field).valid && this.mfaForm.get(field).touched) ||
            (this.mfaForm.get(field).untouched && this.formSubmitAttempt));
    };
    LoginMfaComponent.prototype.getErrMsg = function (errors) {
        if (errors && errors != null) {
            if (errors.required === true) {
                return 'Please answer the security question above.';
            }
        }
    };
    LoginMfaComponent.prototype.removePolicyHolder = function () {
        var _this = this;
        var removePhReq = new RemovePHRequest();
        removePhReq.email = this.phUserObject.email;
        removePhReq.adminPassword = environment.removePhAdminPassword;
        Tracker.loginfo('LoginMfaComponent', 'removePolicyHolder', '', 'start calling service operation removePH for ' + this.phUserObject.email);
        this.googleAnalytics.trackEvent('Login', 'MFA', 'LoginAttempt', 200);
        // service call for removePH
        this.plcyhlderService.removePH(removePhReq).subscribe(function (removePhRes) {
            if (removePhRes && removePhRes.removeStatusCode === '-1') {
                Tracker.loginfo('LoginMfaComponent', 'removePolicyHolder', '', 'response from removePH' + JSON.stringify(removePhRes));
                _this.googleAnalytics.trackEvent('Login', 'MFA', 'LoginAttempt', 200);
                _this.router.navigate(['/register']);
            }
            else {
                Tracker.logerror('AppComponent', 'ngOnInit()', 'Removing the policy holder', 'Error Removing the policy holder', '');
                _this.googleAnalytics.trackEvent('Login', 'MFA', 'LoginAttempt', 200);
                // return ('There is some error while removing the policy');
            }
        });
    };
    return LoginMfaComponent;
}());
export { LoginMfaComponent };
