import { OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { GlobalConstants } from '../../constants/global.constant';
import { MessageConstants } from '../../constants/message.constant';
import { UploadfileService } from '../../services/api/documents/uploadfile.service';
import { EdmrService } from '../../services/api/edmr/edmr.service';
import { Docuploadauditreq } from '../../shared/model/api/document/docuploadauditreq.model';
import { Ezltranscodereq } from '../../shared/model/api/document/ezltranscodereq.model';
import { Taskinjrecreq } from '../../shared/model/api/document/taskinjrecreq.model';
import { Uploadfilereq } from '../../shared/model/api/edmr/uploadfilereq.model';
import { GoogleAnalyticsService } from '../../shared/services/analytics/google-analytics.service';
import { BwstoreService } from '../../shared/services/bwstore.service';
import { MessagesService } from '../../shared/services/messages.service';
import { CommonUtil } from '../../shared/utils/commonutil';
import { Tracker } from '../../shared/utils/tracker';
var UploaddocsComponent = /** @class */ (function () {
    function UploaddocsComponent(uploadDocsModal, fb, _messageService, _storeService, uploadFileService, edmrService, googleAnalytics, uploadDocModal) {
        this.uploadDocsModal = uploadDocsModal;
        this.fb = fb;
        this._messageService = _messageService;
        this._storeService = _storeService;
        this.uploadFileService = uploadFileService;
        this.edmrService = edmrService;
        this.googleAnalytics = googleAnalytics;
        this.uploadDocModal = uploadDocModal;
        this.responseEmit = new EventEmitter();
        this.formSubmitAttempt = false;
        this.isDataLoaded = false;
        this.showForm = false;
        this.isUploading = false;
        this.uploadText = '';
        this.acceptedFileTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/tif', 'image/tiff', 'application/pdf'];
        this.message = {
            type: '',
            message: ''
        };
        this.alertClosed = false;
        this.progress = { percentage: 0 };
        this.authenticatedPhDetails = this._storeService.getLoginInfoFromStore();
        this.basicPolicyDetails = this._storeService.getPolicyInfoFromStore();
        if (this.basicPolicyDetails.isDefaultdocumentType) {
            if (this.basicPolicyDetails.isDefaultdocumentType === 'MultiPolicyDiscountStatus') {
                // to show other value in the drop down while navigating from the missiing document
                this.defaultType = 'E2';
                this.docTypeDisabled = true;
            }
            else {
                // to show Proff of Prior value in the drop down while navigating from the missiing document
                this.defaultType = this.basicPolicyDetails.policyInfo.PolicyType !== 'COMMERCIAL' ? 'O6' : 'K2';
                this.docTypeDisabled = true;
            }
        }
        else {
            // to show please select whilenavigating from the submit documents
            this.defaultType = '';
        }
        // Reactive form builder for Form Validations
        this.createUploadDocForm();
    }
    UploaddocsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var mco = CommonUtil.getMCO(this.authenticatedPhDetails);
        var statecd = this.authenticatedPhDetails.riskstate;
        this.uploadFileService.getTranasctionTypes(mco, statecd).subscribe(function (res) {
            _this.docTransactionTypes = res;
            _this.isDataLoaded = true;
            _this.showForm = true;
        }, function (error) {
            _this.isDataLoaded = true;
            _this.showForm = true;
            Tracker.logerror('UploaddocsComponent', 'ngOnInit()', 'Retrieving Document Transaction Types', 'UploadFileService-getTransactionTypes() Failed', error);
        });
    };
    UploaddocsComponent.prototype.selectFile = function (event) {
        this.selectedFiles = event.target.files;
        // this.cd.markForCheck();
    };
    UploaddocsComponent.prototype.uploadDocument = function (formData) {
        var _this = this;
        this.formSubmitAttempt = true;
        this.message.message = '';
        this.alertClosed = true;
        // alert ('Is FOrm Valid: ' + this.uploadDocForm.valid + '  File: ' + this.selectedFiles.item(0));
        if (this.selectedFiles && this.selectedFiles.item(0)) {
            this.uploadDocForm.controls['uploadfile'].setErrors(null);
        }
        if (this.uploadDocForm.valid) {
            this.currentFileUpload = this.selectedFiles.item(0);
            var selectedFileType = this.currentFileUpload.type;
            var fileSize = Math.round(this.currentFileUpload.size / (1024 * 1024));
            if (this.acceptedFileTypes.indexOf(selectedFileType) === -1) {
                this.message.type = 'danger';
                this.message.message = MessageConstants.UPLOAD_DOC_INVALID_TYPE;
                this.googleAnalytics.trackEvent('PolicyDocuments', 'SubmitDocuments', 'DocumentTypeError', 200);
                return false;
            }
            else if (fileSize > environment.uploadDocFileSize) {
                this.message.type = 'danger';
                this.message.message = MessageConstants.UPLOAD_DOC_SIZE;
                this.googleAnalytics.trackEvent('PolicyDocuments', 'SubmitDocuments', 'DocumentSizeError', 200);
                return false;
            }
            this.isUploading = true;
            this.progress.percentage = 0;
            this.uploadText = 'Uploading Document...';
            var docType_1 = formData.docType;
            if (this.defaultType && this.defaultType !== '') {
                docType_1 = this.defaultType;
            }
            else {
                docType_1 = formData.docType;
            }
            var mco_1 = CommonUtil.getMCO(this.authenticatedPhDetails);
            var polsym_1 = CommonUtil.getPolicySymbol(this.authenticatedPhDetails);
            var polNum_1 = CommonUtil.getPolicyNumber(this.authenticatedPhDetails);
            var polMod_1 = this.authenticatedPhDetails.policyMod;
            var agentInfo_1 = this.basicPolicyDetails.agentInfo;
            var policyInfo = this.basicPolicyDetails.policyInfo;
            this.uploadAuditReq = new Docuploadauditreq();
            this.saveDocumentReq = new Uploadfilereq();
            this.saveDocumentReq.policyNumber = polsym_1.concat(polNum_1).concat(polMod_1);
            this.saveDocumentReq.producerCode = agentInfo_1.AgentCode;
            this.saveDocumentReq.state = this.authenticatedPhDetails.riskstate;
            this.saveDocumentReq.documentType = docType_1;
            // this.saveDocumentReq.documentContent = this.currentFileUpload;
            this.saveDocumentReq.mailingName = policyInfo.PolicyName;
            this.saveDocumentReq.sourceSystem = GlobalConstants.APPID;
            this.saveDocumentReq.effectiveDate = '';
            /* Uncomment the below code once the file upload woorks
            this.edmrService.uploadDocument(this.saveDocumentReq, this.currentFileUpload).subscribe(event => {
              if (event.type === HttpEventType.UploadProgress) {
                this.progress.percentage = Math.round(100 * event.loaded / event.total);
              } else if (event instanceof HttpResponse) {
              }
            });*/
            // Audit log Object
            this.uploadAuditReq.mco = mco_1;
            this.uploadAuditReq.symbol = polsym_1;
            this.uploadAuditReq.policyNumber = polNum_1;
            this.uploadAuditReq.mod = polMod_1;
            this.uploadAuditReq.source = GlobalConstants.APPID;
            this.uploadAuditReq.uploadFileName = 'N/A';
            this.uploadAuditReq.uploadFilePath = 'N/A';
            this.uploadAuditReq.transactionCode = docType_1;
            this.edmrService.uploadDocument(this.saveDocumentReq, this.currentFileUpload).subscribe(function (saveDocRes) {
                // 1019= Error in inserting record in EDMR - Your request has been submitted successfully
                // console.log('Upload Document Response ============= ', saveDocRes);
                if (saveDocRes && saveDocRes.status === true && saveDocRes.responseStatus === '1') {
                    _this.progress.percentage = 50;
                    _this.ezlTranCodeReq = new Ezltranscodereq();
                    _this.ezlTranCodeReq.upldMCO = mco_1;
                    _this.ezlTranCodeReq.upldTranCode = docType_1;
                    _this.ezlTranCodeReq.upldSymbol = polsym_1;
                    _this.ezlTranCodeReq.upldPolicy = polNum_1;
                    _this.ezlTranCodeReq.upldMod = polMod_1;
                    _this.ezlTranCodeReq.upldSourceSystem = GlobalConstants.APPID;
                    _this.uploadFileService.getEasyLinkTransactionCode(_this.ezlTranCodeReq).subscribe(function (trancoderes) {
                        // console.log('Upload Document Ezlink transaction code ============= ', trancoderes);
                        if (trancoderes && trancoderes.easylinkTransactionCodeResult) {
                            _this.progress.percentage = 75;
                            _this.uploadText = 'Finalizing Upload';
                            _this.taskInjRecReq = new Taskinjrecreq();
                            _this.taskInjRecReq.edmrSeqNo = saveDocRes.documentSeqno;
                            _this.taskInjRecReq.newEZYTranCode = trancoderes.newEZYTranCode;
                            _this.taskInjRecReq.upldMCO = mco_1;
                            _this.taskInjRecReq.upldTranCode = docType_1;
                            _this.taskInjRecReq.upldSymbol = polsym_1;
                            _this.taskInjRecReq.upldPolicy = polNum_1;
                            _this.taskInjRecReq.upldMod = polMod_1;
                            _this.taskInjRecReq.upldSourceSystem = GlobalConstants.APPID;
                            _this.taskInjRecReq.riskState = _this.authenticatedPhDetails.riskstate;
                            _this.taskInjRecReq.producerCd = agentInfo_1.AgentCode;
                            _this.taskInjRecReq.transactionCTSrc = 'SYSTEM GENERATED';
                            _this.uploadFileService.createTaskInjectorRecord(_this.taskInjRecReq).subscribe(function (taskinjres) {
                                // console.log('Upload Document task injector response ============= ', taskinjres);
                                _this.formSubmitAttempt = false;
                                //  this.uploadDocForm.reset();
                                _this.uploadDocForm.controls['docType'].patchValue('');
                                _this.uploadDocForm.controls['uploadfile'].patchValue('');
                                _this.uploadDocForm.markAsUntouched();
                                _this.isUploading = false;
                                _this.progress.percentage = 100;
                                _this.message.type = 'success';
                                _this.message.message = _this.basicPolicyDetails.isDefaultdocumentType ?
                                    MessageConstants.UPLOAD_MISSING_DOC_SUCCESS : MessageConstants.UPLOAD_DOC_SUCCESS;
                                if (_this.message.type === 'success' && _this.basicPolicyDetails.isDefaultdocumentType) {
                                    _this.showForm = false;
                                }
                                if (taskinjres && !taskinjres.createTaskInjectorRecordResult) {
                                    // ERROR_10 - Inserting a file in the Easy Link Task Injector Error
                                    // this.isUploading = false;
                                    // this.message.type = 'danger';
                                    // this.message.message = MessageConstants.UPLOAD_DOC_TI_ERROR_10;
                                    _this.uploadAuditReq.returnCode = taskinjres.outReturnCode;
                                    _this.uploadAuditReq.returnMessage = taskinjres.outReturnMsg;
                                    _this.uploadDocumentAudit(_this.uploadAuditReq);
                                }
                                else {
                                    _this.googleAnalytics.trackEvent('PolicyDocuments', 'SubmitDocuments', ' SubmitDocumentSuccessful', 200);
                                    Tracker.loginfo('UploaddocsComponent', 'uploadDocument', 'Uploading Document', 'Document Upload Success');
                                }
                            }, function (error) {
                                _this.googleAnalytics.trackEvent('Error', 'SubmitDocuments', 'TaskInjectorCreatingError', 200);
                                Tracker.logerror('UploaddocsComponent', 'uploadDocument', 'Creating Task Injector Record', 'Error Creating Task Injector Record', error);
                                _this.isUploading = false;
                            });
                        }
                        else {
                            _this.message.type = 'danger';
                            _this.message.message = MessageConstants.UPLOAD_DOC_EZL_ERROR_08;
                            _this.isUploading = false;
                            // ERROR_12 - Looking up the Easy Link transaction code Error
                            _this.googleAnalytics.trackEvent('PolicyDocuments', 'SubmitDocuments', ' EZYLINKTransCodeError', 200);
                            _this.uploadAuditReq.returnCode = trancoderes.outReturnCode;
                            _this.uploadAuditReq.returnMessage = trancoderes.outReturnMsg;
                            _this.uploadDocumentAudit(_this.uploadAuditReq);
                        }
                    }, function (error) {
                        _this.googleAnalytics.trackEvent('Error', 'SubmitDocuments', 'RetrievingEzyLinkTaransCodeError', 200);
                        Tracker.logerror('UploaddocsComponent', 'uploadDocument', 'Getting EasyLink Transaction Code', 'Error Getting EasyLink Transaction Code', error);
                        _this.isUploading = false;
                    });
                    _this.responseEmit.emit(true);
                }
                else {
                    _this.responseEmit.emit(false);
                    // ERROR_12 - File is too large to upload Error
                    // ERROR_13 - PDF Conversion Error
                    // ERROR_1017 - Decoding Document bytes error
                    // ERROR_1018 - Uploading document to Amazon S3 server error
                    // ERROR_1003 - Input parameters passed are not valid to be processed error
                    _this.message.type = 'danger';
                    _this.message.message = MessageConstants.UPLOAD_DOC_FAILURE;
                    _this.isUploading = false;
                    _this.googleAnalytics.trackEvent('PolicyDocuments', 'SubmitDocuments', 'EDMRSaveDocumentError', 200);
                    _this.uploadAuditReq.returnCode = saveDocRes.errorMessageId;
                    _this.uploadAuditReq.returnMessage = saveDocRes.messageText;
                    _this.uploadDocumentAudit(_this.uploadAuditReq);
                }
            }, function (error) {
                _this.googleAnalytics.trackEvent('Error', 'SubmitDocuments', 'EDMRSaveDocumentServiceError', 200);
                Tracker.logerror('UploaddocsComponent', 'uploadDocument', 'Uploading Document to EDMR', 'Error Uploading Document', error);
                _this.isUploading = false;
            });
        }
    };
    UploaddocsComponent.prototype.uploadDocumentAudit = function (uploadAuditReq) {
        this.uploadFileService.createUploadAuditRecord(uploadAuditReq).subscribe(function (res) {
            Tracker.loginfo('UploaddocsComponent', 'uploadDocument', 'Upload Audit Log Creation', 'Upload Audit Service for message' + uploadAuditReq.returnMessage + ' Status: ' + res.status);
        }, function (error) {
            Tracker.logerror('UploaddocsComponent', 'uploadDocument', 'Upload Audit Log Creation', 'Upload Audit Service Failed for message ' + uploadAuditReq.returnMessage, error);
        });
    };
    UploaddocsComponent.prototype.createUploadDocForm = function () {
        this.uploadDocForm = this.fb.group({
            'docType': [{ value: this.defaultType, disabled: this.docTypeDisabled }, Validators.compose([Validators.required])],
            'uploadfile': ['', Validators.compose([Validators.required])]
        });
    };
    // Form validations
    UploaddocsComponent.prototype.isFieldValid = function (field) {
        if (field === 'uploadfile') {
            if (!this.uploadDocForm.get(field).valid && this.formSubmitAttempt) {
                return true;
            }
        }
        else {
            return ((!this.uploadDocForm.get(field).valid && this.uploadDocForm.get(field).touched) ||
                (this.uploadDocForm.get(field).untouched && this.formSubmitAttempt));
        }
    };
    UploaddocsComponent.prototype.getErrMessage = function (errors, formCntrlName) {
        return this._messageService.getErrorMessages(errors, formCntrlName);
    };
    UploaddocsComponent.prototype.cancelUploadDocs = function () {
        this.responseEmit.emit(false);
        this.uploadDocModal.close();
    };
    return UploaddocsComponent;
}());
export { UploaddocsComponent };
