import { OnInit, EventEmitter } from '@angular/core';
import { Mailingaddress } from '../../../shared/model/endorsements/addressedit/mailingaddress.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EndorsementService } from '../../../services/api/endorsement/endorsement.service';
import { EndorsementReq } from '../../../shared/model/api/endorsement/endorsement-req';
import { EndorseAddressReq } from '../../../shared/model/api/endorsement/endorse-address-req';
import { EndorsePhoneReq } from '../../../shared/model/api/endorsement/endorse-phone-req';
import { BwstoreService } from '../../../shared/services/bwstore.service';
import { AddresseditService } from '../../../services/addressedit/addressedit.service';
import { environment } from '../../../../environments/environment';
import { GlobalConstants } from '../../../constants/global.constant';
import { CommonUtil } from '../../../shared/utils/commonutil';
import { formatDate } from '@angular/common';
import { Tracker } from '../../../shared/utils/tracker';
import { BWError } from '../../../shared/model/error.model';
import { BWErrorhandler } from '../../../services/errorhandler';
import { MessageConstants } from '../../../constants/message.constant';
import { AutopayeftService } from '../../../services/mypolicy/autopayeft.service';
import { GoogleAnalyticsService } from '../../../shared/services/analytics/google-analytics.service';
import { PolicyholderService } from '../../../services/api/policyholder/policyholder.service';
import { VehicleService } from '../../../services/policydetails/vehicle.service';
var MailingaddrAcceptComponent = /** @class */ (function () {
    function MailingaddrAcceptComponent(endorsementService, _storeService, addressEditService, locale, eftService, errorHandler, googleAnalytics, policyholderService, vehicleService) {
        var _this = this;
        this.endorsementService = endorsementService;
        this._storeService = _storeService;
        this.addressEditService = addressEditService;
        this.locale = locale;
        this.eftService = eftService;
        this.errorHandler = errorHandler;
        this.googleAnalytics = googleAnalytics;
        this.policyholderService = policyholderService;
        this.vehicleService = vehicleService;
        this.updateStatus = new EventEmitter();
        this.updateFuturePayments = new EventEmitter();
        this.endorsementReq = new EndorsementReq();
        this.endorseAddressReq = new EndorseAddressReq();
        this.endorsePhoneReq = new EndorsePhoneReq();
        this.globlalConstants = GlobalConstants;
        this.isLoaded = true;
        this.submitted = false;
        this.showAddress = true;
        this.showPhoneNumber = false;
        // futurePayments: FuturePayment[];
        this.skip = false;
        this.basicPolicyDetails = this._storeService.getPolicyInfoFromStore();
        this.policyInfo = this.basicPolicyDetails.policyInfo;
        this.authenticatedPhDetails = this._storeService.getLoginInfoFromStore();
        var policynumber = CommonUtil.getBWPolicyNumber(this.authenticatedPhDetails);
        var mco = CommonUtil.getMCO(this.authenticatedPhDetails);
        var vehicleids = [];
        this.isLoaded = false;
        this.vehicleService.getVehicleFullDetails(policynumber, mco)
            .subscribe(function (vehiclesRes) {
            _this.isLoaded = true;
            Tracker.loginfo('MailingaddrAcceptComponent', 'ngOnInit', '', 'Vehicle response ' + JSON.stringify(vehiclesRes));
            if (vehiclesRes.Result && vehiclesRes.Result.toLocaleLowerCase() === 'success') {
                vehiclesRes.Vehicles.forEach(function (vehicle) {
                    vehicleids.push(vehicle.VehicleID);
                });
                _this.endorseAddressReq.vehicleId = vehicleids;
            }
        }, function (error) {
            Tracker.logerror('VehicleReplaceComponent', 'ngOnInit', 'getVehicleFullDetails', 'Error getting Vehicle details for VehicleReplaceComponent', error);
        });
    }
    MailingaddrAcceptComponent.prototype.ngOnInit = function () {
        this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressAcceptScreen', 'MailingAddressAcceptScreen', 200);
        this.showAddress = this.mailingAddress.isAddressChanged;
        this.showPhoneNumber = this.mailingAddress.isPhoneNumberChanged;
        Tracker.loginfo('MailingaddrAcceptComponent', 'ngOnInit', '', 'Updated Address :'.concat(this.mailingAddress.street));
    };
    // this function will call service calls like endorse policy, saveChangesToPolicy,EndoseBind,EndorseVoid
    MailingaddrAcceptComponent.prototype.acceptAddressChange = function () {
        var _this = this;
        try {
            this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressAcceptScreen', 'MailingAddressAcceptButtonClick', 200);
            this.endorsementReq.policyID = this.basicPolicyDetails.policyInfo.SYMBOL + this.basicPolicyDetails.policyInfo.POLICY0NUM +
                this.basicPolicyDetails.policyInfo.MODULE;
            this.endorsementReq.mco = this.basicPolicyDetails.policyInfo.PolicyMasterCompany;
            this.endorsementReq.pco = this.basicPolicyDetails.policyInfo.PolicyCompany;
            this.endorsementReq.lob = this.basicPolicyDetails.policyInfo.LINE0BUS;
            this.endorsementReq.appName = this.globlalConstants.APPID;
            this.endorsementReq.userID = this.globlalConstants.APPID;
            this.endorsementReq.changeType = '2';
            this.endorsementReq.policyStatus = 'P'; // .basicPolicyDetails.policyInfo.PolicyStatus;
            this.endorsementReq.databaseContext = "" + environment.pointContext;
            this.endorsementReq.user = this.globlalConstants.APPID;
            this.endorseAddressReq.addressLine1 = this.mailingAddress.street;
            this.endorseAddressReq.city = this.mailingAddress.city;
            this.endorseAddressReq.state = this.mailingAddress.state;
            this.endorseAddressReq.zipCode = this.mailingAddress.zip;
            this.endorsementReq.endoseAddress = this.endorseAddressReq;
            this.endorsePhoneReq.areaCode = this.mailingAddress.phone.split('-')[0];
            this.endorsePhoneReq.phoneOne = this.mailingAddress.phone.split('-')[1];
            this.endorsePhoneReq.phoneTwo = this.mailingAddress.phone.split('-')[2];
            this.endorsementReq.phoneNumber = this.endorsePhoneReq;
            this.endorsementReq.appSource = 8;
            this.endorsementReq.emailDiscount = false;
            this.endorsementReq.applyToRenewal = true;
            this.endorsementReq.endorseContext = "" + environment.endorseContext;
            this.endorsementReq.producerCode = this.basicPolicyDetails.policyInfo.PolicyAgentCode;
            this.endorsementReq.userType = GlobalConstants.ENDORSE_USER_TYPE;
            this.endorsementReq.symbol = this.policyInfo.SYMBOL;
            this.endorsementReq.policyNum = this.policyInfo.POLICY0NUM;
            this.endorsementReq.mod = this.policyInfo.MODULE;
            this.endorsementReq.state = this.mailingAddress.state;
            this.isLoaded = false;
            if (this.endorseAddressReq.vehicleId && this.endorseAddressReq.vehicleId.length > 0) {
                this.addressEditService.endosePolicy(this.endorsementReq).then(function (res) {
                    if (res && res.status === '0') {
                        // invoke save chnages to policy
                        _this.mailingAddress.bindingNumber = res.confirmationNum;
                        _this.endorsementReq.binderNumber = res.confirmationNum;
                        _this.addressEditService.saveChangesToPolicy(_this.endorsementReq).then(function (saveChangesRes) {
                            if (saveChangesRes.status === '0') {
                                _this.mailingAddress.premiumAmount = saveChangesRes.premium.toFixed(2);
                                _this.mailingAddress.proratedPremium = saveChangesRes.proratedPremium.toFixed(2);
                                // this error message gives the change in the change in the premium amount value
                                _this.mailingAddress.errorMsg = saveChangesRes.errorMsg;
                                _this.addressEditService.endorseBind(_this.endorsementReq).then(function (endorsebindRes) {
                                    if (endorsebindRes.status === '0') {
                                        _this.addressEditService.getFuturePayments(_this.policyInfo.SYMBOL, _this.policyInfo.POLICY0NUM, _this.policyInfo.MODULE, _this.policyInfo.PolicyMasterCompany).then(function (futurePaymentsRes) {
                                            if (futurePaymentsRes.FuturePaymentList.length > 0) {
                                                futurePaymentsRes.FuturePaymentList.forEach(function (payment) {
                                                    var formattedDt = formatDate(payment.PayDate, 'MM/dd/yyyy', 'en-US');
                                                    payment.PayDate = formattedDt;
                                                });
                                                _this.updateFuturePayments.emit(futurePaymentsRes.FuturePaymentList);
                                            }
                                            // Update the behaviour subject
                                            _this.addressEditService.endorsementEligibilitySubject.next(false);
                                            _this.errorHandler.ClearApplicationError();
                                            _this.updateStatus.emit('success');
                                            _this.isLoaded = true;
                                        }).catch(function (error) {
                                            _this.isLoaded = true;
                                            // need to remove once properties file updated in QA
                                        });
                                        _this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressAcceptScreen', 'MailingAddressChangeCompleted', 200);
                                        _this.isLoaded = true;
                                        // Update the behaviour subject
                                        _this.addressEditService.endorsementEligibilitySubject.next(false);
                                        // this.updateStatus.emit('success');
                                    }
                                    else {
                                        _this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressAcceptScreen', 'MailingAddressChangeFailure', 200);
                                        _this.addressEditService.endorseVoid(_this.endorsementReq).then(function (endorseVoidRes) {
                                            _this.isLoaded = true;
                                            // Audit Log entry for Failure
                                            _this.endorsementReq.activityResult = _this.globlalConstants.AUDIT_LOG_ACITIVITY_RESULT_FAILURE;
                                            _this.endorsementReq.activity = _this.globlalConstants.AUDIT_LOG_ACITIVITY_ADDRESS_CHANGE;
                                            _this.endorsementReq.activityMessage = endorsebindRes.errorMsg;
                                            _this.endorsementService.createAuditLogRecord(_this.endorsementReq).subscribe(function (auditLogRes) {
                                                CommonUtil.scrollToTop();
                                                _this.errorHandler.handleApplicationError(new BWError({
                                                    'errorcode': 'Endorsement', 'errordesc': MessageConstants.ADDR_AGENT_ERROR_MSG + '<br>' +
                                                        'Agent Name :' + _this.basicPolicyDetails.agentInfo.AgentName1 + '<br>'
                                                        + 'Agent Phone Number :' + _this.basicPolicyDetails.agentInfo.ACNM_TELE,
                                                    'source': 'Endorsebind'
                                                }));
                                            });
                                        }, function (endorseVoidError) {
                                            Tracker.logerror('MailingaddrAcceptComponent', 'Endorse Address', 'acceptAddressChange', 'Endorse Void Error.', endorseVoidError);
                                            _this.isLoaded = true;
                                        });
                                    }
                                }, function (endorseBindError) {
                                    Tracker.logerror('MailingaddrAcceptComponent', 'Endorse Address', 'acceptAddressChange', 'Error during endorse bind.', endorseBindError);
                                    _this.isLoaded = true;
                                });
                            }
                            else {
                                _this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressAcceptScreen', 'MailingAddressChangeFailure', 200);
                                _this.addressEditService.endorseVoid(_this.endorsementReq).then(function (endorseVoidRes) {
                                    _this.isLoaded = true;
                                    // Audit Log entry for Failure
                                    _this.endorsementReq.activityResult = _this.globlalConstants.AUDIT_LOG_ACITIVITY_RESULT_FAILURE;
                                    _this.endorsementReq.activity = _this.globlalConstants.AUDIT_LOG_ACITIVITY_ADDRESS_CHANGE;
                                    _this.endorsementReq.activityMessage = saveChangesRes.errorMsg;
                                    _this.endorsementService.createAuditLogRecord(_this.endorsementReq).subscribe(function (auditLogRes) {
                                        CommonUtil.scrollToTop();
                                        _this.errorHandler.handleApplicationError(new BWError({
                                            'errorcode': 'Endorsement', 'errordesc': MessageConstants.ADDR_AGENT_ERROR_MSG + '<br>' +
                                                'Agent Name :' + _this.basicPolicyDetails.agentInfo.AgentName1 + '<br>'
                                                + 'Agent Phone Number :' + _this.basicPolicyDetails.agentInfo.ACNM_TELE,
                                            'source': 'EndorsePolicySaveError'
                                        }));
                                    });
                                }, function (endorseVoidError) {
                                    Tracker.logerror('MailingaddrAcceptComponent', 'Endorse Address', 'acceptAddressChange', 'Endorse Void Error.', endorseVoidError);
                                    _this.isLoaded = true;
                                });
                            }
                        }, function (saveChnagesError) {
                            _this.isLoaded = true;
                            Tracker.logerror('MailingaddrAcceptComponent', 'Endorse Address', 'acceptAddressChange', 'Error during saveChangesToPolicy policy.', saveChnagesError);
                        });
                    }
                    else {
                        _this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressAcceptScreen', 'MailingAddressChangeFailure', 200);
                        _this.addressEditService.endorseVoid(_this.endorsementReq).then(function (endorseVoidRes) {
                            _this.isLoaded = true;
                            // Audit Log entry for Failure
                            _this.endorsementReq.activityResult = _this.globlalConstants.AUDIT_LOG_ACITIVITY_RESULT_FAILURE;
                            _this.endorsementReq.activity = _this.globlalConstants.AUDIT_LOG_ACITIVITY_ADDRESS_CHANGE;
                            _this.endorsementReq.activityMessage = res.errorMsg;
                            _this.endorsementService.createAuditLogRecord(_this.endorsementReq).subscribe(function (auditLogRes) {
                                CommonUtil.scrollToTop();
                                _this.errorHandler.handleApplicationError(new BWError({
                                    'errorcode': 'Endorsement', 'errordesc': MessageConstants.ADDR_AGENT_ERROR_MSG + '<br>' +
                                        'Agent Name :' + _this.basicPolicyDetails.agentInfo.AgentName1 + '<br>'
                                        + 'Agent Phone Number :' + _this.basicPolicyDetails.agentInfo.ACNM_TELE,
                                    'source': 'EndorsePolicy'
                                }));
                            });
                        }, function (endorseVoidError) {
                            _this.isLoaded = true;
                            Tracker.logerror('MailingaddrAcceptComponent', 'Endorse Address', 'acceptAddressChange', 'Endorse Void Error.', endorseVoidError);
                        });
                    }
                }, function (error) {
                    _this.isLoaded = true;
                    Tracker.logerror('MailingaddrAcceptComponent', 'Endorse Address', 'acceptAddressChange', 'Error endorse policy.', error);
                });
            }
            else {
                this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressAcceptScreen', 'MailingAddressChangeFailure', 200);
                this.isLoaded = true;
                // Audit Log entry for Failure
                this.endorsementReq.activityResult = this.globlalConstants.AUDIT_LOG_ACITIVITY_RESULT_FAILURE;
                this.endorsementReq.activity = this.globlalConstants.AUDIT_LOG_ACITIVITY_ADDRESS_CHANGE;
                this.endorsementReq.activityMessage = 'Error while retrieving vehicle information';
                this.endorsementService.createAuditLogRecord(this.endorsementReq).subscribe(function (auditLogRes) {
                    CommonUtil.scrollToTop();
                    _this.errorHandler.handleApplicationError(new BWError({
                        'errorcode': 'Endorsement', 'errordesc': MessageConstants.ADDR_AGENT_ERROR_MSG + '<br>' +
                            'Agent Name :' + _this.basicPolicyDetails.agentInfo.AgentName1 + '<br>'
                            + 'Agent Phone Number :' + _this.basicPolicyDetails.agentInfo.ACNM_TELE,
                        'source': 'EndorsePolicySaveError'
                    }));
                });
            }
        }
        catch (error) {
            Tracker.logerror('MailingaddrAcceptComponent', 'On Accept', 'endorse Address', 'Error occurred.', error);
            this.isLoaded = true;
        }
    };
    MailingaddrAcceptComponent.prototype.cancelEndorsement = function () {
        this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressAcceptScreen', 'MailingAddressChangeCancelClick', 200);
        this.submitted = true;
        this.errorHandler.ClearApplicationError();
        CommonUtil.scrollToTop();
        this.errorHandler.handleApplicationError(new BWError({
            'errorcode': 'MailingAddressEdit', 'errordesc': MessageConstants.ADDR_CANCEL_MSG + '<br>' +
                'Agent Name :' + this.basicPolicyDetails.agentInfo.AgentName1 + '<br>'
                + 'Agent Phone Number :' + this.basicPolicyDetails.agentInfo.ACNM_TELE, 'source': 'isVehicleInThisLocation'
        }));
    };
    MailingaddrAcceptComponent.prototype.close = function (endorseModal) {
        this.errorHandler.ClearApplicationError();
        endorseModal.close();
    };
    return MailingaddrAcceptComponent;
}());
export { MailingaddrAcceptComponent };
