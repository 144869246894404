<div class="modal-body">
<div class="col-12">
          <div class="row mt-4 mb-4 mb-4">
            <div class="container-fluid px-3">
              <div class="col-12">
                <h1 class="heading-3 text-center">
                  <p> <i class="far fa-check-circle circle-success"></i></p>
                </h1>
              </div>
              <div class="col-12">
                <p class="text-center">Here is the confirmation number: {{ mailingAddress.bindingNumber }}</p>
              </div>
            </div>
          </div>

          <div *ngIf="showAddress" class="row mt-md-4 mt-lg-4 mb-md-3 mb-lg-3">
            <div class="container-fluid px-3">
                  <label>Your mailing and garaging address has been changed to:</label>
                  <address id="ph-address" class="form-control-sm form-control-plaintext pt-0">
                    {{mailingAddress.street | titlecase }}
                    <br/> {{mailingAddress.city | titlecase}}
                    <br/> {{mailingAddress.state}} {{mailingAddress.zip}}
                  </address>
            </div>
          </div>
          <div *ngIf="showPhoneNumber" class="row mt-md-4 mt-lg-4 mb-md-3 mb-lg-3">
            <div class="container-fluid px-3">
                  <label>Your phone number has been changed to:</label>
                  <input id="ph-phone" type="text" readonly class="form-control-plaintext"
                     value="{{mailingAddress.phone}}" />
            </div>
          </div>
          <div class="row mt-md-4 mt-lg-4 mb-md-3 mb-lg-3">
            <div class="container-fluid px-3">
              <div class="card">
                <div id="tab-1-card-1-collapse" class="collapse show" aria-labelledby="tab-1-card-1-header" data-parent="#tab-1-cards">
                  <div class="card-body">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <span>Policy Number</span>
                        <span>{{policyInfo.PolicyNumber}}</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <span>Effective Date</span>
                        <span>{{ datetime.split(' ')[0] }}</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <span>Effective Time</span>
                        <span>{{ datetime | date:'shortTime' : 'en-US' }}</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <span>{{premiumChangeMsg}}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-10">
              <div class="card">
                <div class="card-header">
                  <h2 class="heading-4 mb-1">Remaining Payments</h2>
                  <p class="mt-1 small color-red font-weight-bold  mb-0" *ngIf="futurePayments?.length">Future installments do not include service charge</p>
                </div>
                <div class="card-body p-0">
                
                 <div class="row mr-0 ml-0 p-2 bg-color-gray-200">
                   <div class="col font-weight-bold">
                    Amount
                   </div>
                   <div class="col font-weight-bold">
                     Due Date
                    </div>
                 </div>
                 <div class="row mr-auto ml-auto border-bottom border-right p-2"
                 *ngFor= "let payment of futurePayments">
                  <div class="col">{{payment.PayAmt  | currency}}</div>
                  <div class="col">{{ payment.PayDate }}</div>
                </div>
                </div>
              </div>
            </div>
          </div> 
</div>
</div>
<div class="modal-footer mt-1">
   <div> <a href="javascript:void(0)" class="view-id-card-endorse" (click)="viewIDCard()" >View ID Card</a> </div>
  <button type="submit" class="btn btn-primary" (click)="closeModal()">Close</button>
</div>
