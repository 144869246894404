import { OnInit } from '@angular/core';
import { BwstoreService } from '../../shared/services/bwstore.service';
var CovidMsgComponent = /** @class */ (function () {
    function CovidMsgComponent(_storeService) {
        this._storeService = _storeService;
        this.basicPolicyDetails = this._storeService.getPolicyInfoFromStore();
        this.policyInfo = this.basicPolicyDetails.policyInfo;
    }
    CovidMsgComponent.prototype.ngOnInit = function () {
    };
    return CovidMsgComponent;
}());
export { CovidMsgComponent };
