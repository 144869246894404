import { Directive, HostListener, ElementRef} from '@angular/core';
import { keyframes } from '@angular/animations';

@Directive({
  selector: '[appPolicynumberformat]'
})
export class PolicynumberformatDirective {

  private el: HTMLInputElement;
  private POLICY_SEPARATOR = '-';

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
 }

  onlyCharactersRegEx = '^[a-zA-Z]*$';
  symbolRegex = '^[a-zA-Z]{1}[0-9]{2}$';
  policyNumRegex =  '^[0-9]{7}$';
  modRegex = '^[0-9]{2}$';

  @HostListener('keyup', ['$event']) onKeyUp(event) {
    const e = <KeyboardEvent> event;

    if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1) {
      return;
    }

    let fvalue = event.target.value;
    // Tracker.log("User Input: ", fvalue, fvalue.length);

    if (fvalue) {
      if (fvalue.length === 3) {
        fvalue += '-';
        this.el.value = fvalue;
      } else if (fvalue.length === 11) {
        fvalue += '-';
        this.el.value = fvalue;
      } else if (fvalue && fvalue.indexOf(this.POLICY_SEPARATOR) === -1 && fvalue.length === 12) {
        // Tracker.log("User Input Change : ", value, value.length);
        const fotmattedValue = fvalue.substr(0, 3) + this.POLICY_SEPARATOR + fvalue.substr(3, 7) +
        this.POLICY_SEPARATOR + fvalue.substr(10, 2);
        this.el.value = fotmattedValue;
      }
    }
  }

  // TODO: Revisit this logic to handle paste event
  /*@HostListener('paste', ['$event']) on(event) {
    let e = <KeyboardEvent> event;

    if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1) {
      return;
    }
    let value = event.target.value;

    if(value && value.indexOf(this.POLICY_SEPARATOR) === -1 && value.length === 12){
      // Tracker.log("User Input Blur : ", value, value.length);
      let fotmattedValue = value.substr(0, 3) + this.POLICY_SEPARATOR + value.substr(3, 7) + this.POLICY_SEPARATOR + value.substr(10,2);
      this.el.value = fotmattedValue;
      this.el.form.noValidate;
    }
  }*/

  @HostListener('change', ['$event']) onchange(event, e: KeyboardEvent,  m: MouseEvent) {
    const value = event.target.value;

    // Tracker.log("User Input change : ", value);
    if (value && value.indexOf(this.POLICY_SEPARATOR) === -1 && value.length === 12) {
      // Tracker.log("User Input Change : ", value, value.length);
      const fotmattedValue = value.substr(0, 3) + this.POLICY_SEPARATOR + value.substr(3, 7) +
      this.POLICY_SEPARATOR + value.substr(10, 2);
      event.target.value = fotmattedValue;
    }
  }

}
