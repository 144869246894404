import { EndorsementService } from '../api/endorsement/endorsement.service';
import { ReplaySubject } from 'rxjs';
import { GlobalConstants } from '../../constants/global.constant';
import { BwappService } from '../bwapp.service';
import { PolicyholderService } from '../api/policyholder/policyholder.service';
import * as i0 from "@angular/core";
import * as i1 from "../api/endorsement/endorsement.service";
import * as i2 from "../bwapp.service";
import * as i3 from "../api/policyholder/policyholder.service";
var AddresseditService = /** @class */ (function () {
    function AddresseditService(endorsementService, appService, policyholderService) {
        this.endorsementService = endorsementService;
        this.appService = appService;
        this.policyholderService = policyholderService;
        this.globalConstants = GlobalConstants;
        // Behaviour subject for endorsement eligibility
        this.endorsementEligibilitySubject = new ReplaySubject(1);
        this.endorsementEligibilityObject = this.endorsementEligibilitySubject.asObservable();
    }
    AddresseditService.prototype.endosePolicy = function (endorsementReq) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.endorsementService.endosePolicy(endorsementReq).subscribe(function (res) {
                resolve(res);
            }, function (error) {
                reject(error);
            });
        });
    };
    AddresseditService.prototype.saveChangesToPolicy = function (endorsementReq) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.endorsementService.saveChangesToPolicy(endorsementReq).subscribe(function (res) {
                resolve(res);
            }, function (error) {
                reject(error);
            });
        });
    };
    AddresseditService.prototype.endorseBind = function (endorsementReq) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.endorsementService.endorseBind(endorsementReq).subscribe(function (res) {
                resolve(res);
            }, function (error) {
                reject(error);
            });
        });
    };
    AddresseditService.prototype.endorseVoid = function (endorsementReq) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.endorsementService.endorseVoid(endorsementReq).subscribe(function (res) {
                resolve(res);
            }, function (error) {
                reject(error);
            });
        });
    };
    AddresseditService.prototype.isEligibleForEndorsement = function (endorsementReq) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.endorsementService.isEligibleForEndorsement(endorsementReq).subscribe(function (res) {
                // Update the behaviour subject
                if (res.status === '0') {
                    console.log('isEligibleForEndorsement Address edit service-');
                    _this.endorsementEligibilitySubject.next(true);
                }
                else {
                    _this.endorsementEligibilitySubject.next(false);
                }
                resolve(res);
            }, function (error) {
                reject(error);
            });
        });
    };
    AddresseditService.prototype.endorseAsyncCalls = function (endorsementReq) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.endorsementService.endorseAsyncCalls(endorsementReq).subscribe(function (res) {
                resolve(res);
            }, function (error) {
                reject(error);
            });
        });
    };
    AddresseditService.prototype.getFuturePayments = function (symbol, num, company, mco) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.policyholderService.getFuturePayments(symbol, num, company, mco).subscribe(function (endorseFuturePayments) {
                resolve(endorseFuturePayments);
            }, function (error) {
                reject(error);
            });
        });
    };
    AddresseditService.ngInjectableDef = i0.defineInjectable({ factory: function AddresseditService_Factory() { return new AddresseditService(i0.inject(i1.EndorsementService), i0.inject(i2.BwappService), i0.inject(i3.PolicyholderService)); }, token: AddresseditService, providedIn: "root" });
    return AddresseditService;
}());
export { AddresseditService };
