export class Taskinjrecreq {

  upldTranCode: string;
  upldMCO: string;
  upldSymbol: string;
  upldPolicy: string;
  upldMod: string;
  riskState: string;
  producerCd: string;
  transactionCTSrc: string;
  upldSourceSystem: string;
  newEZYTranCode: string;
  edmrSeqNo: string;

  constructor() {}
}



