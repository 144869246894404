import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
var EsiglandingComponent = /** @class */ (function () {
    function EsiglandingComponent(route) {
        this.route = route;
    }
    EsiglandingComponent.prototype.ngOnInit = function () {
        this.route.queryParams.subscribe(function (params) {
            // Tracker.log('Params from externa service::', params);
            if (params) {
                // Call a service to verify if the user is registered or not. If registered take them to Login screen
                // Else take them to Registration screen and populate the values
            }
        });
    };
    return EsiglandingComponent;
}());
export { EsiglandingComponent };
