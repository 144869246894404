export class Authenticatephreq {
    appID: string;
    userName: string;
    password: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }

}
