import { animate, query, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, AfterViewInit, PipeTransform, Pipe, ViewChild, ElementRef, Renderer } from '@angular/core';
import { NgbDatepickerConfig, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { TimeoutService } from './services/timeout.service';
import { PolicyHolder } from './shared/model/authentication/authenticatephres';
import { GoogleAnalyticsService } from './shared/services/analytics/google-analytics.service';
import { AuthenticationService } from './shared/services/authentication.service';
import { Tracker } from './shared/utils/tracker';
import { Subscription } from 'rxjs';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CommonUtil } from './shared/utils/commonutil';
import { ChatService } from './shared/services/chat.service';
import { Chatparamreq } from './shared/model/api/chat/chatparamreq';
import { Chattokenres } from './shared/model/api/chat/chattokenres';
import { Chatparamres } from './shared/model/api/chat/chatparamres';
import { UsmsecurityService } from './services/api/usmsecurity/usmsecurity.service';
import { BwstoreService } from './shared/services/bwstore.service';
import { environment } from '../environments/environment';
import { DomSanitizer, Title, Meta } from '@angular/platform-browser';
import { SSOTokenResponse } from './shared/model/api/policyholder/findph.model';
import { MessagesService } from './shared/services/messages.service';
import 'core-js/es7/array';
import { GoogleSEOService } from './shared/services/googleseo.service';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('routerTransition', [
      transition('* <=> *', [
        // Initial state of new route
        query(':enter',
          style({
            position: 'fixed',
            width: '100%',
            transform: 'translateX(-100%)'
          }),
          {optional: true}),

        // move page off screen right on leave
        query(':leave',
          animate('500ms ease',
            style({
              position: 'fixed',
              width: '100%',
              transform: 'translateX(100%)'
            })
          ),
        {optional: true}),

        // move page in screen from left to right
        query(':enter',
          animate('500ms ease',
            style({
              opacity: 1,
              transform: 'translateX(0%)'
            })
          ),
        {optional: true}),
      ])
    ])
  ]
})


export class AppComponent implements  OnInit, AfterViewInit, OnDestroy {
  @ViewChild('main') main: ElementRef;
  isUserLoggedIn: boolean;
  isFeedbackClicked = false;
  bwTokenFromSession: string;
  authenticatedPhDetails: PolicyHolder;
  modalRef: NgbModalRef;

  mobileView: boolean;
  bannerFlag: boolean;

  browserRefreshSubscription: Subscription;


  chattokenres: Chattokenres;
  chatparamres: Chatparamres;
  chatparamreq: Chatparamreq;
  sourceURL: string;
  w: number;
  h: number;
  win: any;
  phUser: PolicyHolder;
  // stateStrArry: Array<string> = ['GA', 'MI', 'NJ', 'PA', 'VA'];
  displayChat: boolean;
  chatInterval: any;
  disableChatButton: boolean;
  routerUrl = '';
  showPage: boolean;
  commercialMCO: Array<String> = ['61', '62'];

  /*@HostListener('window:beforeunload', ['$event']) unloadNotification($event: any) {
    }*/

  constructor(private router: Router, private modalService: NgbModal, private ngbConfig: NgbDatepickerConfig,
    private gaService: GoogleAnalyticsService, private authService: AuthenticationService,
    private timeoutService: TimeoutService,
    private usmSecurityService: UsmsecurityService,
    private _storeService: BwstoreService, private title: Title, private meta: Meta,
    private chatService: ChatService, private renderer: Renderer, private route: ActivatedRoute,
     private seoService: GoogleSEOService , private msgservicec: MessagesService) {
    ngbConfig.minDate = {year: 1900, month: 1, day: 1};
    ngbConfig.maxDate = {year: 2099, month: 12, day: 31};

    // setting page title and meta stags for google SEO
    this.seoService.updateSEO();

    // Logic to check if browser refresh
    this.browserRefreshSubscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.bannerFlag = event.url === '/' ? true : false;
        this.showPage = event.url.includes('/externalRedirect') || event.url.includes('zip=') ? false : true;
        browserRefresh = !router.navigated;
        this.msgservicec.showbwcom.next(this.showPage);
      }
      if (event instanceof NavigationEnd) {
        this.showPage = event.url.includes('/externalRedirect') || event.url.includes('zip=') ? false : true;
        this.msgservicec.showbwcom.next(this.showPage);
      }
  });

    this.disableChatButton = false;
  }

  ngOnInit() {
    this.showPage = this.route.snapshot.queryParamMap.get('zip') ? false : true;
    this.mobileView = CommonUtil.findDeviceVIew().isMobileDevice();
    // enable GA then track the page views
    this.gaService.enableGA().then(res => {
      if (res) {
        // subscribe to the google analytics
        this.gaService.subscribe();
      }
    }).catch(error => {
      Tracker.logerror('AppComponent', 'ngOnInit()', 'Enabling GA Tracking', 'Error Enabling GA Tracking', error);
    });

    this.authService.isUserLoggedIn.subscribe((authenticated) => {
      this.isUserLoggedIn = authenticated;
    });

    this.msgservicec.showbwcom.subscribe((val) => {
      this.showPage = val;
    });
    this.showChatButton();

  // Fallback Scenario when browser is refreshed
  if (this.isUserLoggedIn === undefined) {
    this.bwTokenFromSession = this.authService._bwToken();
    if (this.bwTokenFromSession !== undefined && this.bwTokenFromSession !== null && this.bwTokenFromSession !== '') {
      this.isUserLoggedIn = true;
      this.authService._setLoginStatus(true);

      // reset the showing the CHAT button once page refreshes
      if ( this._storeService.getLoginInfoFromStore().riskstate !== undefined
     // && this.stateStrArry.indexOf(this._storeService.getLoginInfoFromStore().riskstate ) > -1
      &&  !this.commercialMCO.includes(this._storeService.getLoginInfoFromStore().pointHookID.substr(0, 2))) {
          this.displayChat = true;
        } else {
          this.displayChat = false;
      }
    }
  }

  this.router.events.forEach((event) => {// keerthana
    if (event instanceof NavigationEnd) {
      this.routerUrl = this.router.url;
      // console.log('this.routerUrl-' + this.routerUrl);
    }
    // NavigationEnd
    // NavigationCancel
    // NavigationError
    // RoutesRecognized
  });
  }

  ngAfterViewInit() {
   // this.createBwCookie();
  }

  ngOnDestroy() {
    // unsubscribe to the google analytics
    this.gaService.unsubscribe();

    this.timeoutService.unsubsribeTimeout();

    this.browserRefreshSubscription.unsubscribe();
  }

  onActivate(event) {
    window.scroll(0, 0);
  }

  getPage(outlet) {
    return outlet.activatedRouteData['page'] || 'one';
  }


  showFeedbackComp() {
    if (!this.modalRef) {
      this.modalRef = this.modalService.open(FeedbackComponent);
    }
  }

  skipLink() {
    this.renderer.invokeElementMethod(this.main.nativeElement, 'focus');
  }

  showFeedback() {
    this.modalService.open(FeedbackComponent).result.then((result) => {
        this.isFeedbackClicked = true;
      // console.log(`Closed with: ${result}`);
    }, (reason) => {
      if (`${reason}` === 'Submit') {
        this.isFeedbackClicked = true;
      }
      // console.log(`Dismissed ${reason}`);
    });
  }


  // This method is used to navigate mobile website
  goToMobileWeb(featureType: any) {
    this.isFeedbackClicked = true;
    this.authenticatedPhDetails = this._storeService.getLoginInfoFromStore();

    this.usmSecurityService.getSecurityToken(this.authenticatedPhDetails.sessionTicket).subscribe((response: SSOTokenResponse) => {
      if (response != null) {
        this.gaService.trackEvent('ViewPolicyDocumentsSSO', 'LoginSSOAttempt', 'LoginSSOAttemptSuccess', 200);
        window.location.href = `${environment.cancelPolicyRelayStateURL}` + '?View=' + featureType + '&ssot=' + response.ssot;
      }
    }, error => {
       return null;
     });

 }


showChat() {
  // Log to Google analytics
  this.gaService.trackEvent('Chat', 'ChatButtonHomeScreen', 'ChatButtonClick', 200);

  // the chat button will be disabled
  this.disableChatButton = true;

  // First get the token needed for next service call
  this.chatService.generateChatToken().subscribe((response) => {
    if (response != null) {
      this.chattokenres = response;
      console.log('Chat generate call resp is not null');
     this.chatparamreq = new Chatparamreq();

      // Request params to call 2nd Chat API
     this.chatparamreq.primaryInsuredFirstName__c = this._storeService.getLoginInfoFromStore().firstName;
     this.chatparamreq.primaryInsuredLastName__c = this._storeService.getLoginInfoFromStore().lastName;
     this.chatparamreq.policyContactNumber__c =
     this._storeService.getPolicyInfoFromStore().policyInfo.SYMBOL.concat(
       this._storeService.getPolicyInfoFromStore().policyInfo.POLICY0NUM);
      this.chatparamreq.access_token = this.chattokenres.access_token;
      this.chatparamreq.token_type = this.chattokenres.token_type;

     this.chatService.postChartParams(
      this.chatparamreq, this.chattokenres).subscribe((data) => {
          this.chatparamres = data;
          console.log('Chat param call:' + this.chatparamres.success);
        if (this.chatparamres && this.chatparamres.success) {

          // Open chat window
          this.sourceURL = `${environment.srmChatWindowURL}`.concat(this.chatparamres.id);

          // default sizes
          this.w = 400;
          this.h = 440;

          this.win =  window.open(this.sourceURL, 'Chat_Window1', 'width=' + this.w + ',height=' + this.h);
         // Check each 1 millisec whether chat window is closed, then enable the CHat button.
          const chatInterval = setInterval(
          () => {
            if (this.win !== null  && this.win.closed) { // when chat window is closed, then enable the CHAT button again
              this.disableChatButton = false;
                  clearInterval(chatInterval);
            }
            }, 1000); // 1millisec
        } else {
          // COULD NOT SAVE SRM CHAT PARAMS
          console.log('FAILURE');
        }
      }, error => {
         return null;
       });
    }
  }, error => {
     return null;
   });

}

// Logic to when to show the Chat button
showChatButton() {
  this.authService.phUserObject.subscribe((data) => {
    this.phUser = data;
    // show chat button only for 5 states and NOT for commercial policy(MCO=62)
  if (this.phUser.riskstate !== undefined &&
   // this.stateStrArry.indexOf(this.phUser.riskstate ) > -1 &&
    this.phUser.pointHookID !== undefined && !this.commercialMCO.includes(this.phUser.pointHookID.substr(0, 2))) {
      this.displayChat = true;
    } else {
      this.displayChat = false;
    }

  });
}

}
