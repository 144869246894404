export class Ezltranscodereq {

  upldTranCode: string;
  upldMCO: string;
  upldSymbol: string;
  upldPolicy: string;
  upldMod: string;
  upldSourceSystem: string;

  constructor() {}
}
