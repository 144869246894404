export class Userpreferencesstatus {
  gopaperlessEnrollment: string;
  textAlertsEnrollment: string;
  requestToCanceEligible: string;
  textAlertStateEligible: string;
  eftStatus: string;

  constructor() {}
}

