
export class FindPhResponse {
    policyMatch: boolean;
    responseCode: string;
    responseText: string;


    constructor() {

    }
}
