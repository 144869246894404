import { OnInit } from '@angular/core';
import { PaymentMethodAssetImgUrl, PaymentusIframePaymentMethodType, PaymentMethodTypeMapping } from '../../../constants/billing.constants';
var CreditCardInfoComponent = /** @class */ (function () {
    function CreditCardInfoComponent() {
        this.showExpDate = true;
    }
    CreditCardInfoComponent.prototype.ngOnInit = function () {
        this.configTemplateFor(this.paymentMethodInfo);
    };
    CreditCardInfoComponent.prototype.configTemplateFor = function (paymentMethodInfo) {
        var accountNumber = paymentMethodInfo.accountNumber;
        var expiryMonth = paymentMethodInfo.expiryMonth;
        var expiryYear = paymentMethodInfo.expiryYear;
        var type = paymentMethodInfo.type;
        var paymentusIframePaymentType = PaymentMethodTypeMapping[type];
        var hasExpirationDate = expiryMonth && expiryYear;
        this.isBankAccount =
            accountNumber && type &&
                paymentusIframePaymentType === PaymentusIframePaymentMethodType.DIRECT_DEBIT;
        this.isCard = !this.isBankAccount && accountNumber && hasExpirationDate && type &&
            (paymentusIframePaymentType === PaymentusIframePaymentMethodType.CREDIT_CARD
                || paymentusIframePaymentType === PaymentusIframePaymentMethodType.DEBIT_CARD);
        if (this.isBankAccount) {
            this.accountInfoAriaLabel = "ending with " + accountNumber;
        }
        if (this.isCard) {
            this.cardInfoAriaLabel = "expires on " + expiryMonth + "/" + expiryYear;
        }
        if (this.isBankAccount || this.isCard) {
            if (PaymentMethodAssetImgUrl[type].url)
                this.imgSrcAttr = PaymentMethodAssetImgUrl[type].url;
            if (PaymentMethodAssetImgUrl[type].adaLabel)
                this.imgAriaLabel = PaymentMethodAssetImgUrl[type].adaLabel;
        }
    };
    return CreditCardInfoComponent;
}());
export { CreditCardInfoComponent };
