import { Policykey } from './policykey';

export class Achpaymentreq {
    accountHolderFirstName: string;
    accountHolderMiddleInitial: string;
    accountHolderLastName: string;
    address: string;
    city: string;
    postalCode: string;
    homePhoneNumber: string;
    state: string;
    eftEnrollment: boolean;
    browserType: string;
    transactionSource: string;
    transactionSourceSystem: string;
    referenceCode: string;
    abanumber: string;
    accountNumber: string;
    checkType: string;
    checkNumber: string;
    amount: string;
    mco: string;
    symbol: string;
    policyNumber: string;
    module: string;
    pco: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
