/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./address-edit.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./mailingaddr-change/mailingaddr-change.component.ngfactory";
import * as i3 from "./mailingaddr-change/mailingaddr-change.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/forms";
import * as i6 from "../../shared/services/bwstore.service";
import * as i7 from "../../shared/services/messages.service";
import * as i8 from "../../services/errorhandler";
import * as i9 from "../../shared/services/analytics/google-analytics.service";
import * as i10 from "../../shared/services/statecds.service";
import * as i11 from "../../services/myprofile/requesttocancel.service";
import * as i12 from "../../services/api/endorsement/endorsement.service";
import * as i13 from "../../services/api/helptext/helptext.service";
import * as i14 from "./mailingaddr-accept/mailingaddr-accept.component.ngfactory";
import * as i15 from "./mailingaddr-accept/mailingaddr-accept.component";
import * as i16 from "../../services/addressedit/addressedit.service";
import * as i17 from "../../services/mypolicy/autopayeft.service";
import * as i18 from "../../services/api/policyholder/policyholder.service";
import * as i19 from "../../services/policydetails/vehicle.service";
import * as i20 from "./mailingaddr-confirmation/mailingaddr-confirmation.component.ngfactory";
import * as i21 from "./mailingaddr-confirmation/mailingaddr-confirmation.component";
import * as i22 from "@ng-bootstrap/ng-bootstrap/modal/modal";
import * as i23 from "../../shared/components/app-errors/app-errors.component.ngfactory";
import * as i24 from "../../shared/components/app-errors/app-errors.component";
import * as i25 from "@angular/common";
import * as i26 from "./address-edit.component";
import * as i27 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
var styles_AddressEditComponent = [i0.styles];
var RenderType_AddressEditComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddressEditComponent, data: {} });
export { RenderType_AddressEditComponent as RenderType_AddressEditComponent };
function View_AddressEditComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-mailingaddr-change", [], null, [[null, "updatedMailingAddress"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("updatedMailingAddress" === en)) {
        var pd_0 = (_co.getMailingAddress($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MailingaddrChangeComponent_0, i2.RenderType_MailingaddrChangeComponent)), i1.ɵdid(2, 114688, null, 0, i3.MailingaddrChangeComponent, [i4.ActivatedRoute, i4.Router, i5.FormBuilder, i6.BwstoreService, i7.MessagesService, i8.BWErrorhandler, i9.GoogleAnalyticsService, i10.StatecdsService, i11.RequesttocancelService, i12.EndorsementService, i13.HelptextService], { addressEditModal: [0, "addressEditModal"] }, { updatedMailingAddress: "updatedMailingAddress" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.addressEditModal; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AddressEditComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-mailingaddr-accept", [], null, [[null, "updateFuturePayments"], [null, "updateStatus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("updateFuturePayments" === en)) {
        var pd_0 = (_co.getfuturePayments($event) !== false);
        ad = (pd_0 && ad);
    } if (("updateStatus" === en)) {
        var pd_1 = (_co.getUpdateStatus($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i14.View_MailingaddrAcceptComponent_0, i14.RenderType_MailingaddrAcceptComponent)), i1.ɵdid(2, 114688, null, 0, i15.MailingaddrAcceptComponent, [i12.EndorsementService, i6.BwstoreService, i16.AddresseditService, i1.LOCALE_ID, i17.AutopayeftService, i8.BWErrorhandler, i9.GoogleAnalyticsService, i18.PolicyholderService, i19.VehicleService], { mailingAddress: [0, "mailingAddress"], addressEditModal: [1, "addressEditModal"] }, { updateStatus: "updateStatus", updateFuturePayments: "updateFuturePayments" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mailingAddress; var currVal_1 = _co.addressEditModal; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_AddressEditComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-mailingaddr-confirmation", [], null, null, null, i20.View_MailingaddrConfirmationComponent_0, i20.RenderType_MailingaddrConfirmationComponent)), i1.ɵdid(2, 114688, null, 0, i21.MailingaddrConfirmationComponent, [i16.AddresseditService, i6.BwstoreService, i8.BWErrorhandler, i17.AutopayeftService, i4.Router, i22.NgbModal, i9.GoogleAnalyticsService], { addressEditModal: [0, "addressEditModal"], mailingAddress: [1, "mailingAddress"], futurePayments: [2, "futurePayments"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.addressEditModal; var currVal_1 = _co.mailingAddress; var currVal_2 = _co.futurePayments; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_AddressEditComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-header"], ["id", "headerAdd"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title heading-2 mb-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-app-errors", [], null, null, null, i23.View_AppErrorsComponent_0, i23.RenderType_AppErrorsComponent)), i1.ɵdid(4, 114688, null, 0, i24.AppErrorsComponent, [i7.MessagesService, i4.Router], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i25.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressEditComponent_1)), i1.ɵdid(8, 278528, null, 0, i25.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i25.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressEditComponent_2)), i1.ɵdid(10, 278528, null, 0, i25.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i25.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressEditComponent_3)), i1.ɵdid(12, 278528, null, 0, i25.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i25.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_1 = _co.currentStep; _ck(_v, 6, 0, currVal_1); var currVal_2 = "STEP1"; _ck(_v, 8, 0, currVal_2); var currVal_3 = "STEP2"; _ck(_v, 10, 0, currVal_3); var currVal_4 = "STEP3"; _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headerMsg; _ck(_v, 2, 0, currVal_0); }); }
export function View_AddressEditComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-address-edit", [], null, null, null, View_AddressEditComponent_0, RenderType_AddressEditComponent)), i1.ɵdid(1, 114688, null, 0, i26.AddressEditComponent, [i27.NgbActiveModal, i9.GoogleAnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddressEditComponentNgFactory = i1.ɵccf("app-address-edit", i26.AddressEditComponent, View_AddressEditComponent_Host_0, {}, {}, []);
export { AddressEditComponentNgFactory as AddressEditComponentNgFactory };
