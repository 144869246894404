<div id="policydetails" class="border mb-1">
    <div id="policy-header" class="container-fluid my-2">
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <div id="policyNumber" class="text-sm-small">
                    <strong>Policy Premium: </strong>{{ premium | currency }}
                </div>
            </div>
            <!-- ll -->
            <div class="col-sm-12 col-md-4 text-md-center" *ngIf="!showLastDayToPay">
                <div id="policyPeriod" class="text-sm-small">
                    <strong>Last Payment: </strong>{{ paymentAmount | currency }} <small>on</small> {{ (lastPaymentDate ) ? (lastPaymentDate | date: 'MM/dd/yyyy':'en-US') : "" }} 
                </div> 
            </div>
            <div class="col-sm-12 col-md-4 text-md-center" *ngIf="showLastDayToPay">
                <div id="policyPeriod" class="text-sm-small">
                    <strong>Last Day to Pay: </strong>{{ (reInstateDueDate !== null) ? (reInstateDueDate | date: 'MM/dd/yyyy') : "" }} 
                </div> 
            </div>
            <div class="col-sm-12 col-md-4 text-md-right">
                <div id="policyStatus" class="text-sm-small">
                    <strong>Policy Status: </strong> <span class="badge {{policystatus === 'Active' ? 'badge-light' : 'badge-danger'}}">{{policystatus}}</span>
                </div>
            </div>
        </div>
    </div>
</div>