import { EndorseCoverageInfo } from './endorse-coverage-info';
import { EndorseVehicleInfo } from './endorse-vehicle-info';
import { EndorsePhoneReq } from './endorse-phone-req';
import { EndorseAddressReq } from './endorse-address-req';
import { EndorsePolicyInfo } from './endorse-policy-info.model';

export class EndorsementSaveReq {

  endorsePolicyInfo: EndorsePolicyInfo;

  endorseDate: string;

  // Mailing Address & Phone Number Endorsement Reqs
  endoseAddress: EndorseAddressReq;
  phoneNumber: EndorsePhoneReq;

  // Vehicle Endorsement - Add, Replace
  endorseVehicleInfo: EndorseVehicleInfo;

  // Coverage Endorsement - Add, Edit
  endorseCoverageInfo: EndorseCoverageInfo[];

  // Upon successfull endorsePolicy this field will be populated
  binderNumber: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

}


