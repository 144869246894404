/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./email-exists.component";
import * as i2 from "@angular/router";
import * as i3 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
var styles_EmailExistsComponent = [];
var RenderType_EmailExistsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EmailExistsComponent, data: {} });
export { RenderType_EmailExistsComponent as RenderType_EmailExistsComponent };
export function View_EmailExistsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-info-circle text-danger"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Email Address Conflict"])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["There was a conflict with the currently entered email address. Please go back to Step 2 and provide a different email address."])), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["OK"]))], null, null); }
export function View_EmailExistsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-email-exists", [], null, null, null, View_EmailExistsComponent_0, RenderType_EmailExistsComponent)), i0.ɵdid(1, 114688, null, 0, i1.EmailExistsComponent, [i2.ActivatedRoute, i2.Router, i3.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmailExistsComponentNgFactory = i0.ɵccf("app-email-exists", i1.EmailExistsComponent, View_EmailExistsComponent_Host_0, {}, {}, []);
export { EmailExistsComponentNgFactory as EmailExistsComponentNgFactory };
