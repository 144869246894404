import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { Tracker } from '../../utils/tracker';

// --- use a declare to allow the compiler find the ga function
// declare let ga: Function;
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  private subscription: Subscription;

  constructor(private router: Router) { }

  enableGA(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (typeof gtag !== 'undefined') {
        gtag('js', new Date());
        gtag('config', `${environment.google_UAID}`, {
        });
        gtag('config',  `${environment.google_AWID}`, { });
        resolve(true);
      } else {
        resolve(false);
      }

    });
  }

  /**
   * Track an event with custom data in google analytics
   * -
   * @param {string} category Typically the object that was interacted with (e.g. 'Video')
   * @param {string} label The type of interaction (e.g. 'play')
   * @param {string} [action=null] Useful for categorizing events (e.g. 'Fall Campaign')
   * @param {number} [value=null] A numeric value associated with the event (e.g. 42)
   * @memberof GoogleAnalyticsEventService
   */
  public trackEvent(category: string, label: string, action: string = null, value: number = null) {
    try {
      /* ga('send', 'event', { eventCategory: category, eventLabel: label,
         eventAction: action, eventValue: value
       } );
       */
      gtag('event', action, { 'event_category': category, 'event_label': label });
    } catch (error) {
      // Tracker.log(`error: ${error}`);
    }

    // testing
    // Tracker.log(category, label, action, value);
  }

   gtag_report_conversion() {
     /*function callback() {
       if (typeof(url) !== 'undefined') {
        window.location.href = url;
        }
      }*/
      gtag('event', 'conversion', { 'send_to':  `${environment.google_AW_CONVERSION}` });
     return false;
  }


  public subscribe() {
    if (!this.subscription) {
      let pagePath = '';
      // subscribe to any router navigation and once it ends, write out the google script notices
      this.subscription = this.router.events.subscribe(e => {
        if (e instanceof NavigationEnd) {
          // this will find & use the ga function pulled via the google scripts
          try {
            const splitedUrlArray = e.urlAfterRedirects.split('?');
            if (splitedUrlArray.length === 2) {
              // if there are query params
              pagePath = this.updateQueryParam(splitedUrlArray);
            } else {
              // no query params
              pagePath = e.urlAfterRedirects;
            }
            gtag('config', `${environment.google_UAID}`, {
              'page_path': pagePath
            });

            gtag('config',  `${environment.google_AWID}`, { 'page_path': pagePath});
            // Tracker.log(`logged url: ${e.urlAfterRedirects}`);
          } catch (e) {
            Tracker.logerror('GoogleAnalyticsService', 'subscribe', 'subscribe',
              'Unable to find the google GA Function', e);
          }
        }
      });
    }
  }

  // Function that replaces the query parma values for the ploicy num,Lastname and mco
  updateQueryParam(splitedUrlArray: string[]) {
    // if there are query params
    let url = splitedUrlArray[0];
    url = url + '?';
    const queryParam = splitedUrlArray[1];
    const params = queryParam.split('&');
    for (const parameter of params) {
      if (parameter.split('=')[0].toUpperCase() === 'policyNumber'.toUpperCase()) {
        url = url + parameter.split('=')[0] + '=XXXXXXX';
      } else if (parameter.split('=')[0].toUpperCase() === 'LastName'.toUpperCase()) {
        url = url + parameter.split('=')[0] + '=XXXXX';
      }  else if (parameter.split('=')[0].toUpperCase() === 'mco'.toUpperCase()) {
        url = url + parameter.split('=')[0] + '=XX';
      } else {
        url = url + parameter;
      }
      // this adds the & between query params
       if (params.indexOf(parameter) < (params.length - 1)) {
        url = url + '&';
      }
    }
    return url;
  }
  public unsubscribe() {
    if (this.subscription) {
      // --- clear our observable subscription
      this.subscription.unsubscribe();
    }
  }


  /* enableGA(): Promise<boolean> {
   return new Promise((resolve, reject) => {
     if (typeof ga !== 'undefined') {
       ga('create', `${environment.google_UAID}`, 'auto');
       resolve(true);
     } else {
       resolve(false);
     }

   });
 }*/

  /* public subscribe() {

    if (!this.subscription) {
      // subscribe to any router navigation and once it ends, write out the google script notices
      this.subscription = this.router.events.subscribe( e => {
        if (e instanceof NavigationEnd) {
          // this will find & use the ga function pulled via the google scripts
          try {
            ga('set', 'page', e.urlAfterRedirects);
            ga('send', 'pageview');
            // Tracker.log(`logged url: ${e.urlAfterRedirects}`);
          } catch {
            // Tracker.log('tracking not found');
          }
        }
      });
    }
  }*/


}
