/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-reviews.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/components/bw-spinner/bw-spinner.component.ngfactory";
import * as i3 from "../../shared/components/bw-spinner/bw-spinner.component";
import * as i4 from "../../../../node_modules/@ng-bootstrap/ng-bootstrap/rating/rating.ngfactory";
import * as i5 from "@angular/forms";
import * as i6 from "@ng-bootstrap/ng-bootstrap/rating/rating";
import * as i7 from "@ng-bootstrap/ng-bootstrap/rating/rating-config";
import * as i8 from "@angular/common";
import * as i9 from "../../shared/components/directsales/directsales.component.ngfactory";
import * as i10 from "../../shared/components/directsales/directsales.component";
import * as i11 from "@angular/common/http";
import * as i12 from "@angular/router";
import * as i13 from "../../shared/services/directsales.service";
import * as i14 from "../../shared/services/messages.service";
import * as i15 from "../../services/api/endorsement/endorsement.service";
import * as i16 from "../../shared/services/analytics/google-analytics.service";
import * as i17 from "ngx-pagination";
import * as i18 from "../../../../node_modules/ngx-pagination/dist/ngx-pagination.ngfactory";
import * as i19 from "./customer-reviews.component";
import * as i20 from "@angular/platform-browser";
import * as i21 from "../../services/customer-reviews/customer-reviews.service";
import * as i22 from "../../services/errorhandler";
var styles_CustomerReviewsComponent = [i0.styles];
var RenderType_CustomerReviewsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerReviewsComponent, data: {} });
export { RenderType_CustomerReviewsComponent as RenderType_CustomerReviewsComponent };
function View_CustomerReviewsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "small text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Getting Reviews..."]))], null, null); }
function View_CustomerReviewsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "my-5 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-bw-spinner", [], null, null, null, i2.View_BwSpinnerComponent_0, i2.RenderType_BwSpinnerComponent)), i1.ɵdid(2, 114688, null, 0, i3.BwSpinnerComponent, [], { smallSpinner: [0, "smallSpinner"] }, null)], function (_ck, _v) { var currVal_0 = false; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CustomerReviewsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [["class", "heading-6"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\"", "\" "])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "ngb-rating", [["aria-valuemin", "0"], ["class", "d-inline-flex"], ["role", "slider"], ["tabindex", "0"]], [[1, "aria-valuemax", 0], [1, "aria-valuenow", 0], [1, "aria-valuetext", 0], [1, "aria-disabled", 0]], [[null, "rateChange"], [null, "blur"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("blur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).handleBlur() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).handleKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4).reset() !== false);
        ad = (pd_2 && ad);
    } if (("rateChange" === en)) {
        var pd_3 = ((_v.parent.context.$implicit.Stars = $event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i4.View_NgbRating_0, i4.RenderType_NgbRating)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.NgbRating]), i1.ɵdid(4, 638976, null, 1, i6.NgbRating, [i7.NgbRatingConfig, i1.ChangeDetectorRef], { max: [0, "max"], rate: [1, "rate"], readonly: [2, "readonly"] }, { rateChange: "rateChange" }), i1.ɵqud(335544320, 1, { starTemplate: 0 })], function (_ck, _v) { var currVal_5 = 5; var currVal_6 = _v.parent.context.$implicit.Stars; var currVal_7 = true; _ck(_v, 4, 0, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.Title; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 4).max; var currVal_2 = i1.ɵnov(_v, 4).nextRate; var currVal_3 = i1.ɵnov(_v, 4).ariaValueText(); var currVal_4 = (i1.ɵnov(_v, 4).readonly ? true : null); _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_CustomerReviewsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "heading-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "ngb-rating", [["aria-valuemin", "0"], ["class", "d-inline-flex"], ["role", "slider"], ["tabindex", "0"]], [[1, "aria-valuemax", 0], [1, "aria-valuenow", 0], [1, "aria-valuetext", 0], [1, "aria-disabled", 0]], [[null, "rateChange"], [null, "blur"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("blur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).handleBlur() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).handleKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3).reset() !== false);
        ad = (pd_2 && ad);
    } if (("rateChange" === en)) {
        var pd_3 = ((_v.parent.context.$implicit.Stars = $event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i4.View_NgbRating_0, i4.RenderType_NgbRating)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.NgbRating]), i1.ɵdid(3, 638976, null, 1, i6.NgbRating, [i7.NgbRatingConfig, i1.ChangeDetectorRef], { max: [0, "max"], rate: [1, "rate"], readonly: [2, "readonly"] }, { rateChange: "rateChange" }), i1.ɵqud(335544320, 2, { starTemplate: 0 })], function (_ck, _v) { var currVal_4 = 5; var currVal_5 = _v.parent.context.$implicit.Stars; var currVal_6 = true; _ck(_v, 3, 0, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).max; var currVal_1 = i1.ɵnov(_v, 3).nextRate; var currVal_2 = i1.ɵnov(_v, 3).ariaValueText(); var currVal_3 = (i1.ɵnov(_v, 3).readonly ? true : null); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_CustomerReviewsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "my-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "heading-6"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " \u00A0 ", ""])), i1.ɵppd(3, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerReviewsComponent_5)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerReviewsComponent_6)), i1.ɵdid(7, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "hr", [], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = _v.context.$implicit.Title; _ck(_v, 5, 0, currVal_2); var currVal_3 = !_v.context.$implicit.Title; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.FirstName; var currVal_1 = i1.ɵunv(_v, 2, 1, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.Date, "mediumDate")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.Body; _ck(_v, 8, 0, currVal_4); }); }
function View_CustomerReviewsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-directsales", [["fromPage", "customerreview"]], null, null, null, i9.View_DirectsalesComponent_0, i9.RenderType_DirectsalesComponent)), i1.ɵdid(1, 114688, null, 0, i10.DirectsalesComponent, [i11.HttpClient, i5.FormBuilder, i12.Router, i12.ActivatedRoute, i13.DirectsalesService, i14.MessagesService, i15.EndorsementService, i16.GoogleAnalyticsService, i8.DatePipe], { fromPage: [0, "fromPage"] }, null)], function (_ck, _v) { var currVal_0 = "customerreview"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CustomerReviewsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "col-lg-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "heading-4"], ["tabindex", "0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Customer Reviews"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_CustomerReviewsComponent_4)), i1.ɵdid(6, 802816, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i17.PaginatePipe, [i17.PaginationService]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "my-2"], ["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "pagination-controls", [["class", "my-pagination"]], null, [[null, "pageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        var pd_0 = (_co.pageChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i18.View_PaginationControlsComponent_0, i18.RenderType_PaginationControlsComponent)), i1.ɵdid(10, 49152, null, 0, i17.PaginationControlsComponent, [], null, { pageChange: "pageChange" }), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "col-lg-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerReviewsComponent_7)), i1.ɵdid(13, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.reviewsList, _co.config)); _ck(_v, 6, 0, currVal_0); var currVal_1 = (_co.showQuickQuote === "Y"); _ck(_v, 13, 0, currVal_1); }, null); }
export function View_CustomerReviewsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i8.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 7, "main", [["class", "main"], ["id", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerReviewsComponent_1)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerReviewsComponent_2)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerReviewsComponent_3)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoaded; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.isLoaded; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.isLoaded; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_CustomerReviewsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-reviews", [], null, null, null, View_CustomerReviewsComponent_0, RenderType_CustomerReviewsComponent)), i1.ɵdid(1, 114688, null, 0, i19.CustomerReviewsComponent, [i20.Title, i21.CustomerReviewsService, i22.BWErrorhandler, i16.GoogleAnalyticsService, i20.Meta], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerReviewsComponentNgFactory = i1.ɵccf("app-customer-reviews", i19.CustomerReviewsComponent, View_CustomerReviewsComponent_Host_0, {}, {}, []);
export { CustomerReviewsComponentNgFactory as CustomerReviewsComponentNgFactory };
