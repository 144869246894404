import { EndorseMetainfo } from '../../shared/model/endorsements/endorse-metainfo.model';
import * as BwEndorseInfoActions from '../actions/bwendorsement.action';
import { EndorsementSaveReq } from '../../shared/model/api/endorsement/endorsement-save-req.model';
var defaultEndorsementInfoState = new EndorseMetainfo();
var removeEndosrmentInfoState = {
    endorseType: '',
    endorseTypeCd: '',
    endorseTypeDesc: '',
    endorseDate: '',
    endorseUser: '',
    endorsePolicyNum: '',
    endorseMco: '',
    endorseRatebook: '',
    endorseState: '',
    endorseBinderNum: '',
    endorseContext: '',
    endorsePremiumChange: 0,
    endorsePremium: 0,
    endorseBound: '',
    futurePayments: [],
    endorsementReqInfo: new EndorsementSaveReq(''),
    umpdValue: '',
    // endorsement request
    PolicyCoverages: []
};
// Helper to create new state object as the state is immutable
var newState = function (state, newDataObj) {
    return Object.assign({}, state, newDataObj);
};
var ɵ0 = newState;
export function BwEndorsementReducer(state, action) {
    if (state === void 0) { state = defaultEndorsementInfoState; }
    switch (action.type) {
        case BwEndorseInfoActions.CREATE_ENDORSEMENTINFO:
            return newState(state, action.payload);
        case BwEndorseInfoActions.UPDATE_ENDORSEMENTINFO:
            return newState(state, action.payload);
        case BwEndorseInfoActions.REMOVE_ENDORSEMENTINFO:
            return newState(state, removeEndosrmentInfoState);
        default:
            return state;
    }
}
export { ɵ0 };
