var HelpTextMap = /** @class */ (function () {
    function HelpTextMap() {
        if (this.map === undefined || this.map.size === 0) {
            this.map = new Map();
        }
    }
    HelpTextMap.prototype.set = function (key, value) {
        this.map.set(key, value);
    };
    HelpTextMap.prototype.get = function (key) {
        return this.map.get(key);
    };
    HelpTextMap.prototype.clear = function () {
        this.map = new Map();
    };
    return HelpTextMap;
}());
export { HelpTextMap };
