
export class UserInfo {
    email: string;
    question1: string;
    question2: string;
    question3: string;

    // optional
    pointHookId: string;
    ping: string;
    pin:string;

    constructor() {}
}
