import { GlobalConstants } from '../../../constants/global.constant';
import { BwappService } from '../../bwapp.service';
import { BWErrorhandler } from '../../errorhandler';
import { Tracker } from '../../../shared/utils/tracker';
import { EndorsementVoidReq } from '../../../shared/model/api/endorsement/endorsement-void-req.model';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../../bwapp.service";
import * as i2 from "../../errorhandler";
var EndorsementService = /** @class */ (function () {
    function EndorsementService(appService, errorHandler) {
        this.appService = appService;
        this.errorHandler = errorHandler;
        this.globlalConstants = GlobalConstants;
    }
    EndorsementService.prototype.endosePolicy = function (endorsementReq) {
        return this.appService.post(this.globlalConstants.ENDORSE_POLICY, endorsementReq);
    };
    EndorsementService.prototype.saveChangesToPolicy = function (endorsementReq) {
        return this.appService.post(this.globlalConstants.ENDORSE_SAVE_CHANGES_TO_POLICY, endorsementReq);
    };
    EndorsementService.prototype.endorseBind = function (endorsementReq) {
        return this.appService.post(this.globlalConstants.ENDORSE_BIND, endorsementReq);
    };
    EndorsementService.prototype.endorseVoid = function (endorsementReq) {
        return this.appService.post(this.globlalConstants.ENDORSE_VOID, endorsementReq);
    };
    EndorsementService.prototype.endorseAsyncCalls = function (endorsementReq) {
        return this.appService.post(this.globlalConstants.ENDORSE_ASYNC, endorsementReq);
    };
    EndorsementService.prototype.isEligibleForEndorsement = function (endorsementReq) {
        return this.appService.post(this.globlalConstants.PLCY_MGMT_IS_ELIGIBLE_FOR_ENDORSEMENT, endorsementReq);
    };
    EndorsementService.prototype.createAuditLogRecord = function (endorsementReq) {
        return this.appService.post(this.globlalConstants.AUDIT_LOG_SERVICE, endorsementReq);
    };
    EndorsementService.prototype.auditLogRecord = function (logReq) {
        Tracker.loginfo('PolicymgtService', 'auditLogRecord', '', 'auditLogRecord for the policy  ' +
            logReq.policyID);
        return this.appService.post(this.globlalConstants.AUDIT_LOG, logReq);
    };
    // Replace Vehicle, Add Vehicle, Add Coverage, Update Coverage, Remove Coverage, Add Driver, Edit Driver, Remove Driver Endorsement
    EndorsementService.prototype.processEndorsePolicy = function (endorsementSaveReq) {
        return this.appService.post(this.globlalConstants.BW_ENDORSE_SAVE, endorsementSaveReq);
    };
    EndorsementService.prototype.processEndorseBind = function (endorsementBindReq) {
        return this.appService.post(this.globlalConstants.BW_ENDORSE_BIND, endorsementBindReq);
    };
    EndorsementService.prototype.processEndorseVoid = function (bwEndorsementInfo, msg) {
        var voidReq = this.prepareEndorseVoidReq(bwEndorsementInfo, msg);
        console.log('Void Req : ', JSON.stringify(voidReq));
        return this.appService.post(this.globlalConstants.BW_ENDORSE_VOID, voidReq);
    };
    EndorsementService.prototype.prepareEndorseVoidReq = function (bwEndorsementInfo, msg) {
        var endorsevoidReq = new EndorsementVoidReq();
        endorsevoidReq.policyID = bwEndorsementInfo.endorsePolicyNum;
        endorsevoidReq.mco = bwEndorsementInfo.endorseMco;
        endorsevoidReq.state = bwEndorsementInfo.endorseState;
        endorsevoidReq.binderNumber = bwEndorsementInfo.endorseBinderNum;
        endorsevoidReq.changeTypeCd = bwEndorsementInfo.endorseTypeCd;
        endorsevoidReq.appName = GlobalConstants.APPID;
        endorsevoidReq.userID = GlobalConstants.APPID;
        endorsevoidReq.databaseContext = environment.pointContext;
        endorsevoidReq.endorseContext = bwEndorsementInfo.endorseContext;
        endorsevoidReq.notes = '';
        endorsevoidReq.reason = msg;
        return endorsevoidReq;
    };
    // will move to knock out service in next commit
    EndorsementService.prototype.applyknockoutRules = function (konckoutRulesReq) {
        return this.appService.post(this.globlalConstants.APPLY_KNOCKOUT_RULES, konckoutRulesReq);
    };
    EndorsementService.ngInjectableDef = i0.defineInjectable({ factory: function EndorsementService_Factory() { return new EndorsementService(i0.inject(i1.BwappService), i0.inject(i2.BWErrorhandler)); }, token: EndorsementService, providedIn: "root" });
    return EndorsementService;
}());
export { EndorsementService };
