import { LoggerService } from '../../services/logger.service';
import { environment } from '../../../environments/environment';
var Tracker = /** @class */ (function () {
    function Tracker() {
    }
    Tracker.loginfo = function (className, methodName, functionName, msg) {
        var enableLogger = "" + environment.enableLogger;
        if (enableLogger === 'true') {
            LoggerService.log.info(className, methodName, functionName, msg);
        }
        else {
            console.log(className, methodName, functionName, msg);
        }
    };
    Tracker.logerror = function (className, methodName, functionName, msg, error) {
        var enableLogger = "" + environment.enableLogger;
        if (enableLogger === 'true') {
            LoggerService.log.error(className, methodName, functionName, msg, error);
        }
        else {
            console.log(className, methodName, functionName, msg, error);
        }
    };
    Tracker.logtime = function (serviceName, timingmsg) {
        var enableLogger = "" + environment.enableLogger;
        if (enableLogger === 'true') {
            LoggerService.log.logTimining(serviceName, timingmsg);
        }
        else {
            console.log(serviceName, timingmsg);
        }
    };
    Tracker.gaevent = function () {
    };
    return Tracker;
}());
export { Tracker };
