import { OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PolicyNumberValidator } from '../../validators/policynumber.validator';
import { MessageConstants } from '../../../constants/message.constant';
var PolicyNumberInputComponent = /** @class */ (function () {
    function PolicyNumberInputComponent() {
        this.usermessage = MessageConstants;
    }
    PolicyNumberInputComponent.prototype.ngOnInit = function () {
        // Tracker.log("Child Component PH Policy Number: "+this.policynum);
        if (this.validate) {
            this.form.addControl('phpolicynum', new FormControl(this.policynum, Validators.compose([Validators.required, PolicyNumberValidator.validateBwPolicyNumber])));
        }
        else {
            this.form.addControl('phpolicynum', new FormControl(this.policynum, Validators.compose([PolicyNumberValidator.validateBwPolicyNumber])));
        }
    };
    PolicyNumberInputComponent.prototype.toggle = function (tooltip) {
        if (tooltip.isOpen()) {
            tooltip.close();
        }
        else {
            tooltip.open();
        }
    };
    // Form Utilities
    PolicyNumberInputComponent.prototype.isFieldValid = function (field) {
        return ((!this.form.get(field).valid && this.form.get(field).touched) ||
            (this.form.get(field).untouched && this.formSubmitAttempt));
    };
    PolicyNumberInputComponent.prototype.getPolicyNumErrMsg = function (errors) {
        if (errors) {
            if (errors.required === true) {
                return this.usermessage.REQUIRED_POLICYNUMBER;
            }
            else if (errors.plcynum === true) {
                return this.usermessage.FORMAT_POLICYNUMBER;
            }
        }
    };
    return PolicyNumberInputComponent;
}());
export { PolicyNumberInputComponent };
