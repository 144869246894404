import { Injectable } from '@angular/core';
import { ReviewsInfoRes } from '../../../shared/model/api/customer-reviews/customerreviews.model';
import { Observable } from '../../../../../node_modules/rxjs';
import { HttpParams } from '../../../../../node_modules/@angular/common/http';
import { BwappService } from '../../bwapp.service';
import { GlobalConstants } from '../../../constants/global.constant';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {
  globalConstants = GlobalConstants;

  constructor(private appService: BwappService) { }


  getReviews(): Observable<ReviewsInfoRes> {
    const params = new HttpParams();

    return this.appService.get(this.globalConstants.GET_CUSTOMER_REVIEWS, params);
  }
}
