/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mobile-app-banner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./mobile-app-banner.component";
var styles_MobileAppBannerComponent = [i0.styles];
var RenderType_MobileAppBannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MobileAppBannerComponent, data: {} });
export { RenderType_MobileAppBannerComponent as RenderType_MobileAppBannerComponent };
function View_MobileAppBannerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "main", [["class", "submain"], ["id", "mypolicycomponent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "mobile-app-banner row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "pl-4 banner col-10 pr-0"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.FindMobileversion() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "Mobile Banner"], ["class", "logo"], ["src", "assets/img/favicons/banner img.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" View in the Bristol West app"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "banner-close col-2 pl-0 pr-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "close-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.closeBanner = !_co.closeBanner) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-times"]], null, null, null, null, null))], null, null); }
export function View_MobileAppBannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileAppBannerComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.closeBanner; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MobileAppBannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mobile-banner", [], null, null, null, View_MobileAppBannerComponent_0, RenderType_MobileAppBannerComponent)), i1.ɵdid(1, 114688, null, 0, i3.MobileAppBannerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MobileAppBannerComponentNgFactory = i1.ɵccf("app-mobile-banner", i3.MobileAppBannerComponent, View_MobileAppBannerComponent_Host_0, {}, {}, []);
export { MobileAppBannerComponentNgFactory as MobileAppBannerComponentNgFactory };
