
  <div class="row d-flex justify-content-center mb-3 breadcrumb-text">
    <div class="col-md-12 col-sm-12">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-arrow">
          <li *ngFor="let breadcrumb of this.breadCrumbRoutes; let i = index;" class="breadcrumb-item">
            <a tabindex="0" *ngIf="i < this.breadCrumbRoutes.length - 1 else other_content"
            routerLink="/{{ breadcrumb.routePath }}"
            routerLinkActive="active">{{ breadcrumb.routeName }}</a>
            <ng-template #other_content><span role="menuitem"
            tabindex="0" id="currentPage" aria-describedby="currentPage_desc">
            <u class="no-decoration">{{ breadcrumb.routeName }}</u></span></ng-template>
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <span class="invisible" id="currentPage_desc">
    current page
  </span>
  