import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newyork-covid-msg',
  templateUrl: './newyork-covid-msg.component.html',
  styleUrls: ['./newyork-covid-msg.component.scss']
})
export class NewyorkCovidMsgComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
