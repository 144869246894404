import { Injectable } from '@angular/core';
import { ReviewsService } from '../api/reviews/reviews.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerReviewsService {

  constructor(private reviewsService: ReviewsService) { }

  getCustomerReviews() {
    return new Promise((resolve, reject) => {
      this.reviewsService.getReviews().subscribe(res => {
        resolve(res);

      } , error => {
        reject(error);
      });

    });
  }
}
