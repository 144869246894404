
// Placeholder to add the enums
export enum Bwappenum {
    SUCCESS,
    FAILURE,
    YES,
    NO,
    TRUE,
    FALSE,
    REQUIRED,
    EMAILFORMAT,
    MATCH,
    true,
    false,
    'PAGE_NOT_FOUND' = 404,
    MAILING_ADDR_STEP1 = 'STEP1',
    MAILING_ADDR_STEP2 = 'STEP2',
    MAILING_ADDR_STEP3 = 'STEP3'
}
