export class EndorseAddressReq {
vehicleId: Number[];
addressLine1: string;
addressLine2: string;
city: string;
state: string;
zipCode: string;
constructor(values: Object = {}) {
    Object.assign(this, values);
}
}
