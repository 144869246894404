import { CancelAmount } from './api/policyinfo/requesttocancelamount.model';
import { RequestToCancelInfo } from './api/policyinfo/requesttocancelchecksres.model';


export class RequesttocancelStoreInfo {
  cancelDate: string;
  cancelTime: string;
  confirmationNumber: string;

  cancelChecks: RequestToCancelInfo;
  cancelAmount: CancelAmount;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

}
