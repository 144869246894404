import { Component, OnInit } from '@angular/core';
import { MessagesService } from '../../services/messages.service';
import { BWError } from '../../model/error.model';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-app-errors',
  templateUrl: './app-errors.component.html',
  styleUrls: ['./app-errors.component.scss']
})
export class AppErrorsComponent implements OnInit {

  appMessages: BWError[] = [];
  

  constructor(private messageService: MessagesService, private router: Router ) { }
  ngOnInit() {
    this.appMessages = this.messageService.getAppMessages();
  }

  




  fetchRoutelink(event) {
    const routeUrl = event.target.getAttribute('data-link');
    if (routeUrl) {
    this.router.navigate([routeUrl]);
    }
  }

}
