export class Requesttocancelamount {
  RequestToCancelAmount: CancelAmount;
  Result: string;
  Message: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

}


export class CancelAmount {
  AmountType: string;
  cancelAmount: string;
  HasCancelFee: boolean;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
