export class Validatecardreq {
  paymentGatewayChannel: string;
  cardNumber: string;
  signOn: SignOn;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}


export class SignOn {
  client: string;
  requestId: string;
  clientId: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

