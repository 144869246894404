
export class NewuserResponse {
    phemail: string;
    esigstatus: boolean;
    ca44: boolean;
    firstName: string;
    lastName: string;
    mco: string;
    producercode: string;
    envelopId: string;
    recipientId: string;
    responsecode: string;
    jwtToken: string;
    userRole: string;
    stateCd: string;
    hasRenewal: string;
    stateSpecificHasEnvelope: boolean;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
