import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BwappService } from '../../services/bwapp.service';
import { StateCds } from '../model/state-cds.model';

@Injectable({
  providedIn: 'root'
})
export class StatecdsService {

  private stateCdsPath = 'assets/data/states.json';


  constructor(private bwappService: BwappService) { }

  getStateCds(): Observable<StateCds[]>  {
    return this.bwappService.getlocaldata(this.stateCdsPath);
  }
}
