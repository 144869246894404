/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared/components/mobile-app-banner/mobile-app-banner.component.ngfactory";
import * as i3 from "./shared/components/mobile-app-banner/mobile-app-banner.component";
import * as i4 from "./shared/components/header/header.component.ngfactory";
import * as i5 from "./shared/components/header/header.component";
import * as i6 from "@angular/router";
import * as i7 from "./shared/services/authentication.service";
import * as i8 from "./services/timeout.service";
import * as i9 from "./shared/services/messages.service";
import * as i10 from "./shared/services/analytics/google-analytics.service";
import * as i11 from "./app.component";
import * as i12 from "./shared/services/directsales.service";
import * as i13 from "@ng-bootstrap/ng-bootstrap/modal/modal";
import * as i14 from "./shared/services/bwstore.service";
import * as i15 from "./shared/services/ces.service";
import * as i16 from "./shared/components/footer/footer.component.ngfactory";
import * as i17 from "./shared/components/footer/footer.component";
import * as i18 from "./services/api/usmsecurity/usmsecurity.service";
import * as i19 from "@angular/common";
import * as i20 from "./shared/components/system-errors/system-errors.component.ngfactory";
import * as i21 from "./shared/components/system-errors/system-errors.component";
import * as i22 from "@ng-bootstrap/ng-bootstrap/datepicker/datepicker-config";
import * as i23 from "@angular/platform-browser";
import * as i24 from "./shared/services/chat.service";
import * as i25 from "./shared/services/googleseo.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: { "animation": [{ type: 7, name: "routerTransition", definitions: [{ type: 1, expr: "* <=> *", animation: [{ type: 11, selector: ":enter", animation: { type: 6, styles: { position: "fixed", width: "100%", transform: "translateX(-100%)" }, offset: null }, options: { optional: true } }, { type: 11, selector: ":leave", animation: { type: 4, styles: { type: 6, styles: { position: "fixed", width: "100%", transform: "translateX(100%)" }, offset: null }, timings: "500ms ease" }, options: { optional: true } }, { type: 11, selector: ":enter", animation: { type: 4, styles: { type: 6, styles: { opacity: 1, transform: "translateX(0%)" }, offset: null }, timings: "500ms ease" }, options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mobile-banner", [], null, null, null, i2.View_MobileAppBannerComponent_0, i2.RenderType_MobileAppBannerComponent)), i1.ɵdid(1, 114688, null, 0, i3.MobileAppBannerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i4.View_HeaderComponent_0, i4.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i5.HeaderComponent, [i6.Router, i7.AuthenticationService, i8.TimeoutService, i9.MessagesService, i10.GoogleAnalyticsService, i11.AppComponent, i12.DirectsalesService, i13.NgbModal, i14.BwstoreService, i15.CesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["chatbutton", 1]], null, 2, "button", [["class", "btn btn-primary chatbutton_top_right d-md-block btn-responsive"], ["id", "chatbutton"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showChat() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-comments fa-lg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disableChatButton; _ck(_v, 0, 0, currVal_0); }); }
function View_AppComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "alert alert-warning"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "error-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-2x fa-info-circle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A0 "])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "text-danger inline-icon padding-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "alert-text-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your Chat window is open."])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [["class", "alert-text-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please close the Chat window before starting a new Chat session."]))], null, null); }
function View_AppComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, i16.View_FooterComponent_0, i16.RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i17.FooterComponent, [i6.Router, i10.GoogleAnalyticsService, i18.UsmsecurityService, i7.AuthenticationService, i14.BwstoreService, i13.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { main: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["accesskey", "k"], ["class", "sr-only sr-only-focusable"], ["href", ""], ["onclick", "return false;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.skipLink() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Skip to content"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(4, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(6, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(8, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_4)), i1.ɵdid(10, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-system-errors", [], null, null, null, i20.View_SystemErrorsComponent_0, i20.RenderType_SystemErrorsComponent)), i1.ɵdid(12, 114688, null, 0, i21.SystemErrorsComponent, [i9.MessagesService], null, null), (_l()(), i1.ɵeld(13, 16777216, [[1, 0], ["main", 1]], null, 1, "router-outlet", [["tabindex", "-1"]], null, [[null, "activate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activate" === en)) {
        var pd_0 = (_co.onActivate($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, { activateEvents: "activate" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_5)), i1.ɵdid(16, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.mobileView && _co.bannerFlag); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.showPage; _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.isUserLoggedIn && _co.displayChat); _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.disableChatButton; _ck(_v, 10, 0, currVal_3); _ck(_v, 12, 0); _ck(_v, 14, 0); var currVal_4 = _co.showPage; _ck(_v, 16, 0, currVal_4); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 4440064, null, 0, i11.AppComponent, [i6.Router, i13.NgbModal, i22.NgbDatepickerConfig, i10.GoogleAnalyticsService, i7.AuthenticationService, i8.TimeoutService, i18.UsmsecurityService, i14.BwstoreService, i23.Title, i23.Meta, i24.ChatService, i1.Renderer, i6.ActivatedRoute, i25.GoogleSEOService, i9.MessagesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i11.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
