import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GlobalConstants } from '../../../constants/global.constant';
import { Dairycommentreq } from '../../../shared/model/api/policymgmt/dairycommentreq.model';
import { Policymgmtres } from '../../../shared/model/api/policymgmt/policymgmtres.model';
import { Policynumber } from '../../../shared/model/policynumber';
import { Policymailreq } from '../../../shared/model/registration/policymailreq';
import { Policymailres } from '../../../shared/model/registration/policymailres';
import { Tracker } from '../../../shared/utils/tracker';
import { BwappService } from '../../bwapp.service';
import { Authforidcardreq } from '../../../shared/model/api/policymgmt/authforidcardreq';
import { Authforidcardres } from '../../../shared/model/api/policymgmt/authforidcardres';
import { Websessionlogreq } from '../../../shared/model/api/policymgmt/websessionlogreq.model';
import { Mfares } from '../../../shared/model/api/policymgmt/mfares';
import { Mfareq } from '../../../shared/model/api/policymgmt/mfareq';
import { Policymgmtreq } from '../../../shared/model/api/policymgmt/policymgmtreq.model';
import { Policymgmtexstreamreq } from '../../../shared/model/api/policymgmt/policymgmtexstreamreq.model';
import { Endorsementreq } from '../../../shared/model/api/policymgmt/endorsementreq';
import { Endorsementres } from '../../../shared/model/api/policymgmt/endorsementres';
import { RTAFormreq } from '../../../shared/model/api/edmr/rtaformreq';
import { RTAFormres } from '../../../shared/model/api/edmr/rtaformres';

@Injectable({
  providedIn: 'root'
})
export class PolicymgtService {

  private globalConstants = GlobalConstants;
  policyMgmtReq: Policymgmtreq;
  policymgmtexstreamreq: Policymgmtexstreamreq;

  dairyCommentReq: Dairycommentreq;
  policyNumber: Policynumber;

  constructor(private appService: BwappService) { }

  createRTAForm(rtaFormreq: RTAFormreq): Observable<RTAFormres> {
    return this.appService.post(GlobalConstants.CREATE_RTAFORM, rtaFormreq);
  }

  getStatePaperDocFee(state: string, mco: string, ratebook: string, lob: string): Observable<any> {

    const params = new HttpParams()
      .set('state', state)
      .set('mco', mco)
      .set('rateBook', ratebook)
      .set('lob', lob);
    return this.appService.get(this.globalConstants.GET_STATEPAPERDOCFEE, params);
  }

  addComment(policynumber: string, mco: string, eftdairycomment: string, userId: string): Observable<Policymgmtres> {
    this.policyNumber = new Policynumber(policynumber);

    this.dairyCommentReq = new Dairycommentreq();
    this.dairyCommentReq.policyID = this.policyNumber.toString();
    this.dairyCommentReq.mco = mco;
    this.dairyCommentReq.appName = this.globalConstants.APPID;
    this.dairyCommentReq.userID = userId;
    this.dairyCommentReq.comment = eftdairycomment;
    this.dairyCommentReq.activityType = '';
    this.dairyCommentReq.reasonCode = 'PC';
    this.dairyCommentReq.destination = 'RECYCLE';
    this.dairyCommentReq.printByte = '';
    this.dairyCommentReq.prodContext = environment.pointContext;
    this.dairyCommentReq.endorseContext = environment.pointContext;

    return this.appService.post(this.globalConstants.PLCY_MGMT_ADD_COMMENT, this.dairyCommentReq);


  }

  sendPolicyMail(policyMailReq: Policymailreq): Observable<Policymailres> {

    Tracker.loginfo('PolicymgtService', 'sendPolicyMail', '', 'Sending policy mail for ' +
    policyMailReq.symbol.concat(policyMailReq.policy).concat(policyMailReq.mod));

    return this.appService.post(this.globalConstants.PLCY_MGMT_POLICY_MAIL, policyMailReq);
  }

  authForIDCard(authforidCardreq: Authforidcardreq): Observable<Authforidcardres> {
    Tracker.loginfo('PolicymgtService', 'authForIDCard', '', 'Verifying policy for ' +
    authforidCardreq.policySymbol.concat(authforidCardreq.policyNumber));

    return this.appService.post(this.globalConstants.PLCY_MGMT_AUTH_FOR_IDCARD, authforidCardreq);
  }

  createWebSessionLogRecord(webSessLogReq: Websessionlogreq): Observable<String> {
    Tracker.loginfo('PolicymgtService', 'createWebSessionLogRecord', '', 'createWebSessionLogRecord for ' +
    webSessLogReq.policyNumber);

    return this.appService.post(this.globalConstants.PLCY_MGMT_CRT_WEBSSN_LOG, webSessLogReq);
  }

  requestCancel(policyID: string, cancelDate: string, mco: string, riskState: string,
    isMailingAddressChanged: boolean, isShortRateState: boolean, addressLine1: string,
    city: string, state: string, zip: string): Observable<Policymgmtres> {
    this.policyMgmtReq = new Policymgmtreq();

    this.policyMgmtReq.appName = this.globalConstants.APPID;
    this.policyMgmtReq.policyID = policyID;
    this.policyMgmtReq.cancelDate = cancelDate;
    this.policyMgmtReq.mco = mco;
    this.policyMgmtReq.isMailingAddressChanged = isMailingAddressChanged;
    this.policyMgmtReq.riskState = riskState;
    if (isShortRateState) {
      this.policyMgmtReq.cancelReason = 'SIR';
    } else {
      this.policyMgmtReq.cancelReason = 'PIR';
    }
    this.policyMgmtReq.addressLine1 = addressLine1;
    this.policyMgmtReq.city = city;
    this.policyMgmtReq.state = state;
    this.policyMgmtReq.zip = zip;

    return this.appService.post(this.globalConstants.PLCY_MGMT_REQUEST_CANCEL, this.policyMgmtReq);

  }

  createExstreamRecordPostCancellation(mco: string, policynumber: string,
    cancellationDttmstmp: string, cancelAmount: string,
    confirmationNumber: string, eftIndicator: string,
    renewalIndicator: string): Observable<Policymgmtres> {

    this.policyNumber = new Policynumber(policynumber);

    this.policymgmtexstreamreq = new Policymgmtexstreamreq();

    this.policymgmtexstreamreq.appName = this.globalConstants.APPID;
    this.policymgmtexstreamreq.mco = mco;
    this.policymgmtexstreamreq.symbol = this.policyNumber.symbol;
    this.policymgmtexstreamreq.policyNumber = this.policyNumber.policy;
    this.policymgmtexstreamreq.module = this.policyNumber.module;
    this.policymgmtexstreamreq.cancelAmount = cancelAmount;

    this.policymgmtexstreamreq.cancellationDttmstmp = cancellationDttmstmp;
    this.policymgmtexstreamreq.confirmationNumber = confirmationNumber;
    this.policymgmtexstreamreq.eftIndicator = eftIndicator;
    this.policymgmtexstreamreq.renewalIndicator = renewalIndicator;

    return this.appService.post(this.globalConstants.PLCY_MGMT_EXSTREAM_RECORD, this.policymgmtexstreamreq);
  }

  endorsePolicy(endorsementReq: Endorsementreq): Observable<Endorsementres> {
    Tracker.loginfo('PolicymgtService', 'endorsePolicy', '', 'Endorsing policy for ' +
    endorsementReq.policyID);
    return this.appService.post(this.globalConstants.PLCY_MGMT_ENDORSE_POLICY, endorsementReq);
  }

  endorseVoid(endorsementReq: Endorsementreq): Observable<Endorsementres> {
    Tracker.loginfo('PolicymgtService', 'endorseVoid', '', 'Endorsing policy for ' +
    endorsementReq.policyID);
    return this.appService.post(this.globalConstants.PLCY_MGMT_ENDORSE_VOID, endorsementReq);
  }

  isEligibleForEndorsement(endorsementReq: Endorsementreq): Observable<Policymgmtres> {
    Tracker.loginfo('PolicymgtService', 'isEligibleForEndorsement', '', 'IsisEligibleForEndorsement for the policy  ' +
    endorsementReq.policyID);
    return this.appService.post(this.globalConstants.PLCY_MGMT_IS_ELIGIBLE_FOR_ENDORSEMENT, endorsementReq);
  }
}
