<div [formGroup]="formGroup" role="group" class="payment-method-form" aria-label="Choose payment method">
  <label id="group-payment-method" *ngIf="paymentMethods" aria-level="3" role="heading">
    Choose payment method
  </label>
  <mat-radio-group *ngIf="paymentMethods" aria-labelledby="group-payment-method expired-error" formControlName="method">
    <ng-container *ngFor="let paymentInfo of paymentMethods">
      <div class="d-flex justify-content-between">
        <mat-radio-button class="col-lg-6 col-md-10 col-sm-4" style="padding-right: 0px; padding-left:0px"
          color="primary" [value]="paymentInfo" [class.radio-red]="formGroup.invalid"
          [checked]="selectedPayment?.paymentToken == paymentInfo.paymentToken || (paymentInfo?.isUpdated && tokenCopy== paymentInfo.paymentToken)"
          (change)="onRadioChange($event)"  >
          <app-credit-card-info [paymentMethodInfo]="paymentInfo"></app-credit-card-info>
        </mat-radio-button>
        <a href="javascript:void(0)" role="button" mat-icon-button
          (click)="addEditPaymentMethod(paymentInfo.paymentusIframePaymentType, paymentInfo.paymentToken, 'Edit')"
          onclick="blur()">
          <span class="sr-only">Edit payment method ending with card number {{ paymentInfo.accountNumber }}</span>
          <mat-icon color="primary">mode_edit</mat-icon>
        </a>
      </div>
      <mat-error *ngIf="formGroup.invalid && methodSelected === paymentInfo.accountNumber" attr.id="expired-error"
        aria-live="polite" class="mb-2">
        Please update the expiration date or select a different payment method.
      </mat-error>
    </ng-container>
  </mat-radio-group>
</div>