import { HttpResponse } from '@angular/common/http';
import { finalize, tap } from 'rxjs/operators';
import { Bwappenum } from '../../constants/bwappenum.enum';
import { MessagesService } from '../services/messages.service';
import { Tracker } from '../utils/tracker';
var LoggingInterceptor = /** @class */ (function () {
    function LoggingInterceptor(messageService) {
        this.messageService = messageService;
        this.appenum = Bwappenum;
    }
    LoggingInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        var started = Date.now();
        var ok;
        var logreqs = '/log/';
        // extend server response observable with logging
        return next.handle(req)
            .pipe(tap(function (event) { return ok = event instanceof HttpResponse ? 'SUCCESS ' + _this.appenum.SUCCESS : ''; }, function (error) { return ok = 'FAILED ' + Bwappenum.FAILURE; }), finalize(function () {
            var elapsed = Date.now() - started;
            /*const msg = `${req.method} '${req.urlWithParams}'
               ${ok} in ${elapsed} ms.`;*/
            var msg = ok + " in " + elapsed + " ms.";
            // console.log('Logging Interceptor', req.urlWithParams, `${elapsed}`);
            Tracker.logtime("" + req.urlWithParams, msg);
        }));
    };
    return LoggingInterceptor;
}());
export { LoggingInterceptor };
