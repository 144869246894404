import { ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { MessageConstants } from '../../../constants/message.constant';
import { PolicyholderService } from '../../../services/api/policyholder/policyholder.service';
import { TimeoutService } from '../../../services/timeout.service';
import { GoogleAnalyticsService } from '../../services/analytics/google-analytics.service';
import { FullPolicyDetails } from '../../model/api/bwibic/fullpolicydetails.model';
import { BWError } from '../../model/error.model';
import { AuthenticationService } from '../../services/authentication.service';
import { BwstoreService } from '../../services/bwstore.service';
import { CommonUtil } from '../../utils/commonutil';
import { Tracker } from '../../utils/tracker';
import { EmailValidator } from '../../validators/email.validator';
import { ErrorRedirectsComponent } from '../modals/error-redirects.component';
import { ForgotUseridComponent } from '../modals/forgot-userid.component';
import { Policynumber } from '../../model/policynumber';
import { GlobalConstants } from '../../../constants/global.constant';
import { MypolicyService } from '../../../services/mypolicy/mypolicy.service';
import { OktaMapperService } from '../../services/okta-mapper.service';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(route, router, fb, authService, plcyhlderService, storeService, modalService, googleAnalytics, timeoutService, policyService, oktaMapperService) {
        this.route = route;
        this.router = router;
        this.fb = fb;
        this.authService = authService;
        this.plcyhlderService = plcyhlderService;
        this.storeService = storeService;
        this.modalService = modalService;
        this.googleAnalytics = googleAnalytics;
        this.timeoutService = timeoutService;
        this.policyService = policyService;
        this.oktaMapperService = oktaMapperService;
        this.usermessage = MessageConstants;
        this.policyNumber = '';
        this.rememberMeId = '';
        this.isChecked = false;
        this.loaded = true;
        this.policynum = '';
        this.cookieBannerUrl = "" + environment.ccpaCookieBanner;
        this.bwOktaEnabled = environment.bwOktaEnabled;
        // Retrieve Remember my UserIDfrom Local
        var rememberMeLocal = localStorage.getItem("" + environment.rememberMeId + '_userId');
        if (rememberMeLocal !== null &&
            rememberMeLocal !== undefined &&
            rememberMeLocal !== '') {
            this.rememberMeId = rememberMeLocal;
            this.isChecked = true;
        }
        this.showPassword = false;
        this.createLoginFormGroup();
        this.newuser = this.storeService.getCurrentregistrationState();
    }
    LoginComponent.prototype.ngOnInit = function () { };
    LoginComponent.prototype.createLoginFormGroup = function () {
        this.loginForm = this.fb.group({
            userid: [
                this.rememberMeId,
                Validators.compose([Validators.required, EmailValidator.validateEmail])
            ],
            password: ['', Validators.compose([Validators.required])],
            rememberMe: [this.isChecked]
        });
    };
    LoginComponent.prototype.loginUser = function (logindata) {
        var _this = this;
        this.formSubmitAttempt = true;
        this.showPassword = false;
        var pendingDocsExists = this.route.snapshot.queryParamMap.get('Pendingdocs') === 'true';
        var pendingDocsExistsUrl = this.router.url.includes('Pendingdocs=true');
        var rrPaymentExists = this.route.snapshot.queryParamMap.get('rrPayment') === 'true';
        var rrPaymentExistsUrl = this.router.url.includes('rrPayment=true');
        if (this.loginForm.valid) {
            // Tracker.log('Remember me check: ', logindata.rememberMe);
            this.loaded = false;
            this.authService
                .login(CommonUtil.lrtrim(logindata.userid), logindata.password)
                .then(function (phuser) {
                _this.googleAnalytics.trackEvent('Login', 'LoginAttemptSuccess', 'LoginAttempt', 200);
                Tracker.loginfo('MyPolicyComponent', 'ngOnInit()', 'Initializing onload functions', 'Component ## Policy Data from Store :::: ');
                if (phuser && phuser.jwtToken && phuser.jwtToken !== null && phuser.jwtToken !== '') {
                    // this.authService.saveUserLogin(phuser);
                    _this.authService.getPolicyModAndVerifyPendingDocs(phuser).then(function (plcyHolder) {
                        // Check for Remember Me
                        if (logindata.rememberMe) {
                            localStorage.setItem("" + environment.rememberMeId + '_userId', logindata.userid);
                        }
                        else {
                            localStorage.removeItem("" + environment.rememberMeId + '_userId');
                        }
                        _this.loaded = true;
                        _this.timeoutService.start();
                        var policyNumber = new Policynumber(plcyHolder.pointHookID.substring(2).concat(plcyHolder.policyMod));
                        // Check if MFA Date is expired
                        if (!_this.bwOktaEnabled) {
                            _this.plcyhlderService.getMFADate(policyNumber.symbol, policyNumber.policy, policyNumber.module, plcyHolder.pointHookID.substring(0, 2))
                                .subscribe(function (mfaDateData) {
                                _this.mfaRes = mfaDateData;
                                console.log('1=' + _this.mfaRes.Result);
                                if (_this.mfaRes && _this.mfaRes.Result === '0') {
                                    console.log('2=' + _this.mfaRes.Date);
                                    // check if set
                                    if (_this.mfaRes.Date === undefined || _this.mfaRes.Date === '' || _this.mfaRes.Date === 'null') {
                                        // update the date and continue if  not okta login
                                        _this.plcyhlderService.updateMFADate(policyNumber.symbol, policyNumber.policy, policyNumber.module, plcyHolder.pointHookID.substring(0, 2), CommonUtil.addDateNowEST(GlobalConstants.MFA_DAYS))
                                            .subscribe(function (mfaUpdateDate) {
                                            _this.mfaRes = mfaDateData;
                                        }, function (error) {
                                            Tracker.logerror('LoginComponent', 'onSubmit', 'updateMFADate', 'Error updating MFA Date.', error);
                                            _this.loaded = true;
                                        });
                                        _this.storeService.updateLoginInfo(plcyHolder);
                                        _this.authService.createWebSessLog(plcyHolder);
                                        if (_this.router.url !== undefined && _this.router.url !== null) {
                                            if (plcyHolder.HasMissingDocuments && (pendingDocsExists || pendingDocsExistsUrl)) {
                                                return _this.navigateByQuery(plcyHolder, _this.router, 'Pendingdocs');
                                            }
                                            if (rrPaymentExists || rrPaymentExistsUrl) {
                                                return _this.navigateByQuery(plcyHolder, _this.router, 'rrPayment');
                                            }
                                        }
                                        if (plcyHolder && plcyHolder.gopaperlessInd === true) {
                                            return _this.router.navigate(['/gptermsnconditions']);
                                        }
                                        else if (plcyHolder && plcyHolder.esigstatus === true) {
                                            return _this.router.navigate(['/esigmessages']);
                                        }
                                        else {
                                            _this.storeService.deletePolicyInfo(new FullPolicyDetails());
                                            return _this.router.navigate(['/policy']);
                                        }
                                    }
                                    else {
                                        if (new Date().getTime() >= new Date(_this.mfaRes.Date).getTime()) {
                                            // Show MFA Question Page
                                            /*this.newuser.policynumber = policyNumber.symbol + policyNumber.policy + policyNumber.module;
                                            this.newuser.mco = plcyHolder.pointHookID.substring(0, 2);
                                            this.newuser.userid = plcyHolder.email;
                                            this.newuser.password = logindata.password;
                                            this.newuser.secq1 = plcyHolder.question1;
                                            this.newuser.secq2 = plcyHolder.question2;
                                            this.newuser.secq3 = plcyHolder.question3;
                                            this.storeService.updateRegistrationState(this.newuser);*/
                                            // this.createphUserObject();
                                            _this.authService._setLoginStatus(false);
                                            // this.storeService.deleteLoginInfo(phuser);
                                            return _this.router.navigate(['/mfa']);
                                        }
                                        else {
                                            _this.storeService.updateLoginInfo(plcyHolder);
                                            _this.authService.createWebSessLog(plcyHolder);
                                            if (_this.router.url !== undefined && _this.router.url !== null) {
                                                if (plcyHolder.HasMissingDocuments && (pendingDocsExists || pendingDocsExistsUrl)) {
                                                    return _this.navigateByQuery(plcyHolder, _this.router, 'Pendingdocs');
                                                }
                                                if (rrPaymentExists || rrPaymentExistsUrl) {
                                                    return _this.navigateByQuery(plcyHolder, _this.router, 'rrPayment');
                                                }
                                            }
                                            if (plcyHolder && plcyHolder.gopaperlessInd === true) {
                                                return _this.router.navigate(['/gptermsnconditions']);
                                            }
                                            else if (plcyHolder && plcyHolder.esigstatus === true) {
                                                return _this.router.navigate(['/esigmessages']);
                                            }
                                            else {
                                                _this.storeService.deletePolicyInfo(new FullPolicyDetails());
                                                return _this.router.navigate(['/policy']);
                                            }
                                        }
                                    }
                                }
                            }, function (error) {
                                Tracker.logerror('LoginComponent', 'onSubmit', 'getMFADate', 'Error getting MFA Date.', error);
                                _this.loaded = true;
                            });
                        }
                        else {
                            console.log("getMFADate call skipped due to okta flow");
                            _this.storeService.updateLoginInfo(plcyHolder);
                            _this.authService.createWebSessLog(plcyHolder);
                            if (_this.router.url !== undefined && _this.router.url !== null) {
                                if (plcyHolder.HasMissingDocuments && (pendingDocsExists || pendingDocsExistsUrl)) {
                                    return _this.navigateByQuery(plcyHolder, _this.router, 'Pendingdocs');
                                }
                                if (rrPaymentExists || rrPaymentExistsUrl) {
                                    return _this.navigateByQuery(plcyHolder, _this.router, 'rrPayment');
                                }
                            }
                            if (plcyHolder && plcyHolder.gopaperlessInd === true) {
                                _this.router.navigate(['/gptermsnconditions']);
                            }
                            else if (plcyHolder && plcyHolder.esigstatus === true) {
                                _this.router.navigate(['/esigmessages']);
                            }
                            else {
                                _this.storeService.deletePolicyInfo(new FullPolicyDetails());
                                _this.router.navigate(['/policy']);
                            }
                        }
                        // return;
                    }).catch(function (error) {
                        _this.loaded = true;
                        _this.storeService.deleteLoginInfo(null);
                        if (error instanceof BWError) {
                            // Tracker.log('Error Happened in main login service', error.errorcode, error.errordesc);
                            /* Fallback logic to handle opening Child Modals from Parent - ExpressionChangedAfterItHasBeenCheckedError
                            Object.keys(this.loginForm.controls).forEach(key => {
                              this.loginForm.controls[key].markAsTouched();
                           });
                            this.openModal(error.errordesc);*/
                        }
                        Tracker.logerror('LoginComponent', 'loginUser', 'getPolicyModAndVerifyPendingDocs', 'Error during login.', error);
                    });
                }
            }).catch(function (error) {
                _this.googleAnalytics.trackEvent('Login', 'LoginAttemptFail', 'LoginAttempt', 500);
                _this.loaded = true;
                _this.storeService.deleteLoginInfo(null);
                if (error instanceof BWError) {
                    if (error && error.errordesc === '90') {
                        Tracker.logerror('LoginComponent', 'loginUser', 'login', 'Error during login.', error.errordesc);
                        return _this.router.navigate(['/home/reset-password'], { queryParams: { reset: '1' } });
                    }
                }
            });
        }
        else {
            setTimeout(function () {
                document.getElementById("loginForm").querySelector('[id=elemID]').focus();
            });
        }
    };
    LoginComponent.prototype.openModal = function (errormsg) {
        var modalRef = this.modalService.open(ErrorRedirectsComponent);
        modalRef.componentInstance.errormsg = errormsg;
    };
    LoginComponent.prototype.togglePassword = function () {
        this.showPassword = !this.showPassword;
    };
    LoginComponent.prototype.showForgotEmail = function () {
        this.modalService.open(ForgotUseridComponent);
    };
    // Form Validation Utilities
    LoginComponent.prototype.isFieldValid = function (field) {
        return ((!this.loginForm.get(field).valid && this.loginForm.get(field).touched) ||
            (this.loginForm.get(field).untouched &&
                this.loginForm.get(field).value === '' &&
                this.formSubmitAttempt));
    };
    LoginComponent.prototype.getUserIDErrMsg = function (errors) {
        if (errors && errors !== null) {
            if (errors.required === true) {
                return this.usermessage.REQUIRED_USEREMAIL;
            }
            else if (errors.email === true) {
                return this.usermessage.FORMAT_USEREMAIL;
            }
        }
    };
    // this functio s called when user clicked on the new User button.It will navigate to registration page
    LoginComponent.prototype.newUser = function () {
        this.router.navigate(['/register']);
        this.googleAnalytics.trackEvent('UserRegistration', 'UserRegistrationScreen', 'UserRegistrationContinueClick', 200);
    };
    LoginComponent.prototype.navigateByQuery = function (plcyHolder, router, linType) {
        var mco = CommonUtil.getMCO(plcyHolder);
        var polsym = CommonUtil.getPolicySymbol(plcyHolder);
        var polNum = CommonUtil.getPolicyNumber(plcyHolder);
        var polMod = plcyHolder.policyMod;
        this.policyService.getAllBasicPolicyInfo(mco, polsym, polNum, polMod)
            .then(function (policyres) {
            if (linType === 'rrPayment') {
                return router.navigate(['/payment']);
            }
            else if (linType === 'Pendingdocs') {
                // commented code for pending docs
                //return router.navigate(['/policy/missing-document']);
            }
        }).catch(function (error) {
            Tracker.logerror('MyPolicyComponent', 'ngOnInit()', 'Initializing onload functions', 'MypolicyService-getAllBasicPolicyInfo() Failed', error);
            return router.navigate(['/policy']);
        });
    };
    return LoginComponent;
}());
export { LoginComponent };
