
export class EsigMessages {
  envelopeId: string;
  envelopeExpiryDate: string;
  recepientId: string;
  recepientType: string;
  hasPendingEnvelops: boolean;
  envMessage: string;
  envMessageId: string;

  ceremonyURL: string;

  constructor() {}

}
