import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbRoute } from '../../model/breadcrumb-route';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-breadcrumb',
  styleUrls: ['./breadcrumb.component.scss'],
  template:
  `
  <div class="row d-flex justify-content-center mb-3 breadcrumb-text">
    <div class="col-md-12 col-sm-12">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-arrow">
          <li *ngFor="let breadcrumb of this.breadCrumbRoutes; let i = index;" class="breadcrumb-item">
            <a tabindex="0" *ngIf="i < this.breadCrumbRoutes.length - 1 else other_content"
            routerLink="/{{ breadcrumb.routePath }}"
            routerLinkActive="active">{{ breadcrumb.routeName }}</a>
            <ng-template #other_content><span role="menuitem"
            tabindex="0" id="currentPage" aria-describedby="currentPage_desc">
            <u class="no-decoration">{{ breadcrumb.routeName }}</u></span></ng-template>
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <span class="invisible" id="currentPage_desc">
    current page
  </span>
  `
})
export class BreadcrumbComponent implements OnInit {

  breadCrumbRoutes: BreadcrumbRoute[];
  currentBreadCrumb: string;
  isUserLoggedIn: boolean;

  constructor(private route: ActivatedRoute , private authService: AuthenticationService) {
    this.authService.isUserLoggedIn.subscribe((authenticated) => {
      this.isUserLoggedIn = authenticated;
    });
   }

  ngOnInit() {
    this.breadCrumbRoutes = new Array();
    let hasRoot = false;
    let prevPath;
    let count = 0;

    let  builtPath = '';

    for (const pfr of this.route.pathFromRoot) {
      if (pfr.routeConfig !== null && pfr.routeConfig.data !== undefined) {
        this.currentBreadCrumb = (this.route.routeConfig !== null &&
          this.route.routeConfig.data !== undefined ? this.route.routeConfig.data[ 'breadcrumb' ] : '');

          builtPath += pfr.routeConfig.path.concat('/');

        if (!hasRoot) {
          if (this.route.pathFromRoot.length === 2 ) {
            this.breadCrumbRoutes[0] = this.isUserLoggedIn ? new BreadcrumbRoute('My Policy', '/') : new BreadcrumbRoute('Home', '/') ;
            this.breadCrumbRoutes[1] = new BreadcrumbRoute(pfr.routeConfig.data[ 'breadcrumb' ], pfr.routeConfig.path);
          } else {
          this.breadCrumbRoutes[count] = new BreadcrumbRoute(pfr.routeConfig.data[ 'breadcrumb' ], pfr.routeConfig.path);
          }

          if (pfr.routeConfig.path === 'home') {
            this.breadCrumbRoutes[count].routePath = '/';
          }

          hasRoot = true;
        } else {
          this.breadCrumbRoutes[count] =
            new BreadcrumbRoute(pfr.routeConfig.data[ 'breadcrumb' ], builtPath);
        }

        prevPath = pfr.routeConfig.path;
        ++count;
      }
    }
  }

}
