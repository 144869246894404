var FindPHRequest = /** @class */ (function () {
    function FindPHRequest(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return FindPHRequest;
}());
export { FindPHRequest };
var FindPHResponse = /** @class */ (function () {
    function FindPHResponse(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return FindPHResponse;
}());
export { FindPHResponse };
var McoResponse = /** @class */ (function () {
    function McoResponse(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return McoResponse;
}());
export { McoResponse };
var SSOLoginRequest = /** @class */ (function () {
    function SSOLoginRequest(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return SSOLoginRequest;
}());
export { SSOLoginRequest };
var SSOOKTALoginRequest = /** @class */ (function () {
    function SSOOKTALoginRequest(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return SSOOKTALoginRequest;
}());
export { SSOOKTALoginRequest };
var SSOOKTALoginResponse = /** @class */ (function () {
    function SSOOKTALoginResponse(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return SSOOKTALoginResponse;
}());
export { SSOOKTALoginResponse };
var SSOOKTAError = /** @class */ (function () {
    function SSOOKTAError(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return SSOOKTAError;
}());
export { SSOOKTAError };
var SSOTokenResponse = /** @class */ (function () {
    function SSOTokenResponse(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return SSOTokenResponse;
}());
export { SSOTokenResponse };
