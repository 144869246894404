import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BwappService } from '../../bwapp.service';
import { GlobalConstants } from '../../../constants/global.constant';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PrivacyoptoutService {

  private globlalConstants = GlobalConstants;
  constructor(private appService: BwappService) { }

  // opt service calls

hasOptedprivacyPolicy(policysym: string , policynum: string) {
  const params = new HttpParams()
      .set('polSym', policysym)
      .set('polNum', policynum);
  return this.appService.get(this.globlalConstants.HAS_OPTTED_PRIVACY, params);
}

 validatePolicyOptOut(policySym: string , policyNum: string, state: string, lastName: string) {
  const params = new HttpParams()
  .set('szSymbol', policySym)
  .set('szPolicy', policyNum)
  .set('szState', state)
  .set('szLastName', lastName);
return this.appService.get(this.globlalConstants.VALIDATE_POLICY_OPT, params);

 }

 logPrivacyOptOut(policySym: string , policyNum: string, state: string, lastName: string ,
  firstname: string , mod: string , enrollDate: string, enrolltime: string) {

  const params = new HttpParams()
  .set('polSym', policySym)
  .set('polNum', policyNum)
  .set('szMOD', mod)
  .set('szLastName', lastName)
  .set('szEnrollDate', enrollDate)
  .set('szEnrollTime', enrolltime)
  .set('szState', state)
  .set('szFirstName', firstname);
return this.appService.get(this.globlalConstants.LOG_PRIVICY_OPT, params);

 }
}
