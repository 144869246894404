import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalConstants } from '../../../constants/global.constant';
import { FullPolicyDetails } from '../../../shared/model/api/bwibic/fullpolicydetails.model';
import { CardHolder, EFTCard, PolicyKey, Processcardreq } from '../../../shared/model/api/dbcardpayment/processcardreq.model';
import { Processcardres } from '../../../shared/model/api/dbcardpayment/processcardres.model';
import { Policynumber } from '../../../shared/model/policynumber';
import { CommonUtil } from '../../../shared/utils/commonutil';
import { BwappService } from '../../bwapp.service';
import { BWErrorhandler } from '../../errorhandler';

@Injectable({
  providedIn: 'root'
})
export class DbcardpaymentService {

  private globlalConstants = GlobalConstants;
  policyNumber: Policynumber;

  processCardReq: Processcardreq;

  constructor(private appService: BwappService, private errorHandler: BWErrorhandler) { }


  processCardInstlmntPayment(ccformdata: any, fullpolicyInfo: FullPolicyDetails, cardtype: string): Observable<Processcardres> {
    this.policyNumber = new Policynumber(fullpolicyInfo.policyInfo.PolicyNumber);

    this.processCardReq = new Processcardreq();
    this.processCardReq.amount = '0';
    this.processCardReq.eCommerceIndicator = 'ECOMMERCE';
    this.processCardReq.browserType = 'Chrome69';
    this.processCardReq.eftEnrollment = true;
    this.processCardReq.transactionSource = 'POS';
    this.processCardReq.transactionSourceSystem = 'BWM';
    this.processCardReq.applyPayment = false;

    const policyKey = new PolicyKey();
    policyKey.masterCompany = fullpolicyInfo.policyInfo.MASTER0CO;
    policyKey.policyCompany = '00';
    policyKey.symbol = this.policyNumber.symbol;
    policyKey.policyNumber = this.policyNumber.policy;
    policyKey.module = this.policyNumber.module;
    this.processCardReq.policyKey = policyKey;

    const eftCard = new EFTCard();
    eftCard.eftCardNumber = CommonUtil.formatCC(ccformdata.ccNumber);
    eftCard.eftCardType = cardtype;
    eftCard.eftCardExpirationDate = CommonUtil.formatCCExpDt(ccformdata.ccExp);
    eftCard.eftCardCVV = ccformdata.ccCvc;
    this.processCardReq.eftCard = eftCard;

    const cardHolder = new CardHolder();
    cardHolder.eftFirstName = ccformdata.fname;
    cardHolder.eftLastName = ccformdata.lname;
    cardHolder.eftStreetAddress = ccformdata.address;
    cardHolder.eftCity = ccformdata.city;
    cardHolder.eftState = ccformdata.state;
    cardHolder.eftCountry = 'US';
    cardHolder.eftZipCode = ccformdata.zip;
    this.processCardReq.eftCardHolder = cardHolder;

    // Tracker.log('Biggest Request of new BW.com ', this.processCardReq);

    return this.appService.post(this.globlalConstants.DBCARD_PROCESS_INSTALLEMENT, this.processCardReq);
  }
}
