export class MissingDocument {

    PolicyNumber: string;
    MasterCompany: string;
    RiskState: string;
    ProducerCode: string;
    Channel: string;
    PolicyStatus: string;
    PolicyEffectiveDate: string;
    EsignatureIndicator: string;
    GoPaperlessStatus: string;
    ProofOfPriorStatus: string;
    HomeownersProofStatus: string;
    MultiPolicyDiscountStatus: string;
    CustomerName: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
