
  <!-- Modal -->
  <!--Defect 13342-->
      <div class="modal-header">
       <h6 class="modal-title" id="modal-label">Restrict Information Sharing With Our Affiliated Companies</h6>
          <button type="button" class="close" (click)="dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body">
       <div class="container-fluid">
       <p *ngIf ="returnMessage !== '' && returnMessage == 'success'"
       class ="alert alert-success alert-dismissible fade show ng-star-inserted" >Your opt out form has been successfully submitted</p>
       <app-app-errors *ngIf ="returnMessage !== '' && returnMessage == 'fail'" ></app-app-errors>
            <p>Please do not share consumer report information about me with your affiliates except as otherwise permitted by law.</p>
            <p><strong>Remember, it is only necessary to notify us once of your intention to opt out.</strong></p>
          <form  class="my-4" [formGroup]="optForm">
            <!--First Name & Last Name-->
              <div class="form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">First Name</label>
                    <input type="text" class="form-control form-control-lg" formControlName="firstName"/>
                    <app-field-error-display [displayError]="isFieldValid('firstName')"
                    [errorMsg]="getErrorMessageMsg(optForm.controls['firstName'].errors,'firstName')"></app-field-error-display>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">Last Name</label>
                    <input type="text" class="form-control form-control-lg" formControlName="lastName"/>
                    <app-field-error-display [displayError]="isFieldValid('lastName')"
                    [errorMsg]="getErrorMessageMsg(optForm.controls['lastName'].errors,'lastName')"></app-field-error-display>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <app-policy-number-input [policynum]="policynum" [formSubmitAttempt]="formSubmitAttempt"
                    [form]="optForm" [validate]="true"></app-policy-number-input>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">State</label>
                    <select class="form-control"  formControlName="state">
                    <option *ngFor="let statecode of stateCds" value="{{ statecode.stateCd }}">{{ statecode.stateDesc }}</option>
                      </select>
                      <app-field-error-display [displayError]="isFieldValid('state')"
                      [errorMsg]="getErrorMessageMsg(optForm.controls['state'].errors,'state')"></app-field-error-display>
                  </div>
                </div>
              </div>
            </form>
          </div>
      </div>
      <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="dismiss()">Cancel</button>
      <button *ngIf="loaded" type="button" class="btn btn-primary" (click)="submitOptForm(optForm.value)">Submit</button>
      <button *ngIf="!loaded" class="btn  btn-primary" disabled="true"><i class="fas fa-circle-notch fa-spin">
      </i>Submit</button>
      </div>
  