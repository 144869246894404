import { Injectable } from '@angular/core';
import { BwappService } from '../../bwapp.service';
import { GlobalConstants } from '../../../constants/global.constant';
import { Scrubaddrres } from '../../../shared/model/api/addrtools/scrubaddrres.model';
import { Observable } from 'rxjs';
import { Scrubaddrreq } from '../../../shared/model/api/addrtools/scrubaddrreq.model';

@Injectable({
  providedIn: 'root'
})
export class AddresstoolsService {
  scrubAddrsReq: Scrubaddrreq;

  private globlalConstants = GlobalConstants;

  constructor(private appService: BwappService) { }

  scrubAddress(address: string, city: string, state: string, zip: string): Observable<Scrubaddrres> {
    this.scrubAddrsReq = new Scrubaddrreq();
    this.scrubAddrsReq.fullAddress = address;
    this.scrubAddrsReq.city = city;
    this.scrubAddrsReq.state = state;
    this.scrubAddrsReq.zip = zip;
    this.scrubAddrsReq.sourceSystem = this.globlalConstants.APPID;
    this.scrubAddrsReq.addressType = 'Mailing';

    return this.appService.post(this.globlalConstants.SCRUB_ADDRESS, this.scrubAddrsReq);
  }
}
