import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalConstants } from '../../constants/global.constant';
import { BwappService } from '../../services/bwapp.service';
import { TokenRequest } from '../model/api/cessurvey/token-request';
import { TokenResponse } from '../model/api/cessurvey/token-response';

@Injectable({
  providedIn: 'root'
})
export class CesService {
  private globlalConstants = GlobalConstants;
  constructor(private appService: BwappService) { }

  getSurveyToken(tokenRequest :TokenRequest): Observable<TokenResponse> {
    return this.appService.post(this.globlalConstants.GET_TOKEN, tokenRequest);
  }
  closeSurvey(tokenRequest :TokenRequest): Observable<TokenResponse> {
    return this.appService.post(this.globlalConstants.UPDATE_NOTHANKS, tokenRequest);
  }

}
