import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gopaperless-terms',
  templateUrl: './gopaperless-terms.component.html',
  styleUrls: ['./gopaperless-terms.component.scss']
})
export class GopaperlessTermsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
