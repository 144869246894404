<!-- <div  class ="main" >

  <div class="card mt-2" style="height:50%;width: 50%;margin-left: 25%;">
    <div class="card-header text-center">
      <span class="fa-2x text-success">
          <i class="far fa-circle-xmark fa-lg text-danger"></i>
      </span>
      <h2 class="heading-4">Payment Failed</h2>  
    </div>
    
  
    <div class="card-body pr-4 pl-4">
  
      <ngb-alert   [type]="'danger'" role="alert" [dismissible]="false">
        <div class="text-center">
          
          <div class="error-icon"><i class="fas fa-exclamation-triangle"></i></div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Error<br/>
          <div align="left" class="multi_lines_text text-danger inline-icon padding-left" (click)="fetchRoutelink($event)"   >
            <ul>
              <li *ngFor="let em of finalErrorMessages">{{em}}</li>
            </ul>
          </div>
        </div>
      </ngb-alert>
    </div>
  </div>

</div>  -->



<div  style="padding:1%;">
  

  <h6><strong>Important!</strong></h6>
  <span>

    <ul>
      <li *ngFor="let em of finalErrorMessages">{{em}}</li>
      
    </ul>
  </span>
  
  
  

</div>

<!--<button class="clear" (click)="messageService.clear()">clear</button>-->