<main id="main" class="main">
  <div class="container">
    <div>
      <p>Per the request of the New Jersey Department of Banking and Insurance (DOBI), we are making you aware of a new
        regulation due to COVID-19.</p>
    </div>

    <div>
      <p>The New Jersey Department of Banking and Insurance (DOBI) issued Bulletin No. 20-15 requiring New Jersey State
        regulated issuers of property and casualty insurance to provide relief to New Jersey consumers experiencing
        financial hardship due to COVID-19. This follows Governor Phillip Murphy’s Executive Orders No. 103, 107 and
        123.
      </p>
    </div>

    <div>
      <P>The Bulletin directs property and casualty insurers to provide their insureds who may be experiencing a
        financial hardship due to COVID-19 with at least a 90-day grace period to pay insurance premiums. A
        policyholder may elect this 90-day emergency grace period to begin either on April 1, 2020 or May 1, 2020. </P>
    </div>

    <div>
      <p>
        Insurers shall also: </p>
    </div>
    <div>
      <ul>
        <li>
          Waive any late payment fees otherwise due, and not report late payments to credit rating agencies, during the
          90-day period; and</li>
          <br>
        <li>
          Allow premiums due but not paid during the 90-day period to be paid over the remainder of the current policy
          term or up to 12 months in up to 12 equal monthly installments, whichever is longer.</li>
      </ul>
    </div>
    <div>
      <P>If you have any questions, please call 1-888-888-0080</P>
    </div>

  </div>
</main>