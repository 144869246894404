import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../../../shared/services/analytics/google-analytics.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
var AutoInsurance101Component = /** @class */ (function () {
    function AutoInsurance101Component(carosel, router, googleAnalytics) {
        this.carosel = carosel;
        this.router = router;
        this.googleAnalytics = googleAnalytics;
        carosel.interval = 30000;
        carosel.wrap = true;
        carosel.showNavigationArrows = false;
    }
    AutoInsurance101Component.prototype.ngOnInit = function () {
    };
    AutoInsurance101Component.prototype.ngAfterViewInit = function () {
        var accDiv = document.getElementsByClassName("acc-nocard-header");
        var CardsList = accDiv[0].getElementsByClassName("card");
        var CardHeaderList = accDiv[0].getElementsByClassName("card-header");
        var btnLinkList = accDiv[0].getElementsByClassName("btn-link");
        for (var index = CardsList.length - 1; index >= 0; index--) {
            var element = CardsList[index];
            element.classList.remove("card");
        }
        for (var index = CardHeaderList.length - 1; index >= 0; index--) {
            var element = CardHeaderList[index];
            element.classList.remove("card-header");
        }
        for (var index = btnLinkList.length - 1; index >= 0; index--) {
            var element = btnLinkList[index];
            element.classList.remove("btn");
        }
        var dnar = document.getElementsByClassName("down-arrow-div-pos");
        var zipDiv = document.getElementsByClassName("zip-code");
        var agent = window.navigator.userAgent.toLowerCase();
        if (agent.indexOf('trident') > -1) {
            zipDiv[0].classList.add("zip-ie");
            for (var index = dnar.length - 1; index >= 0; index--) {
                var element = dnar[index];
                element.classList.remove("arrow-down");
            }
        }
        else {
            zipDiv[0].classList.remove("zip-ie");
            for (var index = dnar.length - 1; index >= 0; index--) {
                var element = dnar[index];
                element.classList.add("arrow-down");
            }
        }
    };
    AutoInsurance101Component.prototype.previousSlide = function () {
        this.carousel.prev();
    };
    AutoInsurance101Component.prototype.nextSlide = function () {
        this.carousel.next();
    };
    return AutoInsurance101Component;
}());
export { AutoInsurance101Component };
