import { AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { iframeResizer } from 'iframe-resizer';
var DirectsalesDirective = /** @class */ (function () {
    function DirectsalesDirective(element) {
        this.element = element;
    }
    DirectsalesDirective.prototype.ngAfterViewInit = function () {
        var components = iframeResizer({
            checkOrigin: false,
            heightCalculationMethod: 'documentElementOffset',
            log: false
        }, this.element.nativeElement);
        /* save component reference so we can close it later */
        this.component = components && components.length > 0 ? components[0] : null;
    };
    DirectsalesDirective.prototype.ngOnDestroy = function () {
        if (this.component && this.component.iFrameResizer) {
            this.component.iFrameResizer.close();
        }
    };
    return DirectsalesDirective;
}());
export { DirectsalesDirective };
