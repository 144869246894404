export class Policymgmtreq {

  policyID: string;
  mco: string;
  cancelDate: string;
  appName: string;
  isMailingAddressChanged: boolean;
  addressLine1: string;
  city: string;
  state: string;
  zip: string;
  cancelReason: string;
  riskState: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
