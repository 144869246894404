export class Dairycommentreq {

  policyID: string;
  mco: string;
  appName: string;
  userID: string;
  entryDate: string;
  comment: string;
  activityType: string;
  reasonCode: string;
  destination: string;
  printByte: string;
  prodContext: string;
  endorseContext: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
}
}
