import { OnInit } from '@angular/core';
import { GlobalConstants } from '../../../constants/global.constant';
import { BwstoreService } from '../../../shared/services/bwstore.service';
var BillingDetailsComponent = /** @class */ (function () {
    function BillingDetailsComponent(_storeService) {
        var _this = this;
        this._storeService = _storeService;
        this.billingDetails = {};
        this.APV = "APV";
        this.CAL = "CAL";
        this.now = new Date();
        this.authenticatedPhDetails = this._storeService.getLoginInfoFromStore();
        //console.log(this.authenticatedPhDetails);
        setInterval(function () {
            _this.now = new Date();
        }, 1);
    }
    BillingDetailsComponent.prototype.ngOnInit = function () {
        console.log(this.billingDetails);
        this.status = GlobalConstants.status;
        // console.log("4")
    };
    BillingDetailsComponent.prototype.getPolicyNumber = function () {
        return (this.billingDetails.policyInfo.PolicyNumber).split('-')[0].toUpperCase() + (this.billingDetails.policyInfo.PolicyNumber).split('-')[1].toUpperCase() + (this.billingDetails.policyInfo.PolicyNumber).split('-')[2].toUpperCase();
    };
    // isReinstatementAvailable():boolean{
    //   return this.billingDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC=== GlobalConstants.status.reinstatementAvailable;
    // }
    BillingDetailsComponent.prototype.isReinstatementAvailable = function () {
        if ((this.billingDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC ===
            GlobalConstants.status.reinstatementAvailable && this.authenticatedPhDetails.reInstateDueamount && this.authenticatedPhDetails.reInstateDueamount !== '0' && this.authenticatedPhDetails.reInstateDueamount !== '0.00') || this.authenticatedPhDetails.isReinstate) {
            //this.billingDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC = GlobalConstants.status.reinstatementAvailable;
            return this.getStatus();
        }
        else if (this.billingDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC === GlobalConstants.status.reinstatementAvailable &&
            (!this.authenticatedPhDetails.reInstateDueamount || this.authenticatedPhDetails.reInstateDueamount === '0'
                || this.authenticatedPhDetails.reInstateDueamount === '0.00')) {
            this.billingDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC = GlobalConstants.status.cancelled;
            return false;
        }
        else {
            return false;
        }
        //return this.policyInfo.PolicyStatusEPC=== GlobalConstants.status.reinstatementAvailable || this.authenticatedPhDetails.isReinstate;
    };
    BillingDetailsComponent.prototype.getStatus = function () {
        if (!(this.authenticatedPhDetails.reInstateDueamount && this.authenticatedPhDetails.reInstateDueamount !== '0' && this.authenticatedPhDetails.reInstateDueamount !== '0.00')) {
            this.billingDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC = GlobalConstants.status.cancelled;
            return false;
        }
        this.billingDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC = GlobalConstants.status.reinstatementAvailable;
        return true;
    };
    BillingDetailsComponent.prototype.getDueDate = function () {
        //console.log("3")
        return this.billingDetails.basicPolicyDetails.paymentInfo.paymentDueDate && (this.billingDetails.basicPolicyDetails.paymentInfo.paymentDueDate.includes("0001") || this.billingDetails.basicPolicyDetails.paymentInfo.paymentDueDate.includes("1899")) ? "" : this.billingDetails.basicPolicyDetails.paymentInfo.paymentDueDate;
    };
    BillingDetailsComponent.prototype.getCancelledDueDate = function () {
        // console.log("4");
        return this.billingDetails.basicPolicyDetails.policyInfo.DueDate && (this.billingDetails.basicPolicyDetails.policyInfo.DueDate.includes("0001") || this.billingDetails.basicPolicyDetails.policyInfo.DueDate.includes("1899")) ? "" : this.billingDetails.basicPolicyDetails.policyInfo.DueDate;
    };
    BillingDetailsComponent.prototype.getLastDayToPayDate = function () {
        return this.authenticatedPhDetails.reInstateDueDate && (this.authenticatedPhDetails.reInstateDueDate.includes("0001") || this.authenticatedPhDetails.reInstateDueDate.includes("1899")) ? "" : this.authenticatedPhDetails.reInstateDueDate;
    };
    return BillingDetailsComponent;
}());
export { BillingDetailsComponent };
