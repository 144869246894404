
export class Feedbackreq {
    enterdate: string;
    feedbackComments: string;
    feedbackType: string;
    rating: number;
    userAgent: string;
    version: string;
    emailAddress: string;
    mco: string;
    policy: string;
    firstName: string;
    lastName: string;
    phoneNum: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
