import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login/login.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-forgot-userid',
  template: `

        <form [formGroup]="forgotUserIdForm">
          <div class="modal-header">
            <h5 class="modal-title heading-4" id="modal-label">Forgot Email</h5>
            <button tabindex="-1" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div *ngIf="errorForgetId">
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
                <div class="text-danger">{{fgusidErrorMsg}}</div>
            </div>
          </div>
          <div class="modal-body p-5">
            <label tabindex="0">We will need your Policy Number in order to re-register you.</label>
              <app-policy-number-input [policynum]="policynum"
              [formSubmitAttempt]="forgotIdformSubmitAttempt" [form]="forgotUserIdForm" [validate]="true"></app-policy-number-input>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="dismiss()">Cancel</button>
            <button type="button" class="btn btn-primary" (click)="resetUserID(forgotUserIdForm)">Continue</button>
          </div>
        </form>

  `,
  styles: []
})
export class ForgotUseridComponent implements OnInit {

  forgotUserIdForm: FormGroup = new FormGroup({});
  errorForgetId = false;
  forgotIdformSubmitAttempt: boolean;
  fgusidErrorMsg = '';
  policynum = '';

  constructor(private _phService: LoginService,  private router: Router, private modal: NgbActiveModal) { }

  ngOnInit() {
  }


  resetUserID(form: any) {
    // make call to service to remove the existing user registration
    // route to registration-one to complete the regestration set up
    // If User Found then call service removePH() -> when removed get the policy number and route it to regiration page 1
    // Else Show message Please try again.
    this.forgotIdformSubmitAttempt = true;
    if (this.forgotUserIdForm.valid) {
      // Tracker.log('Calling processForgotUserIdWf Service.........');
      // service call
      this._phService.processForgotUserIdWf(form.value.phpolicynum).then(
        data => {
          // Tracker.log('forgot userId Response', data);
          let queryParams = {};
          this.modal.dismiss();
          queryParams = { policyNumber: form.value.phpolicynum.toUpperCase(),
          LastName: ''}; // Need to check how we will get the last name of Ph
          // Tracker.log('navigating to register step 1', data);
          this.router.navigate(['/register'], { queryParams: queryParams });
        }).catch(error => {
          this.errorForgetId = true;
          this.fgusidErrorMsg = error;
          // Tracker.log('There is an error occurred while enrolling the policy');
        });
    }
  }

  dismiss() {
    this.modal.dismiss();
  }

}
