import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { OktaResponse } from '../model/api/okta/okta-model';
import { Authenticatephres } from '../model/authentication/authenticatephres';

@Injectable({
  providedIn: 'root'
})
export class OktaMapperService {

  phres: Authenticatephres;

  constructor() { }

  oktaToAuthenticatePhres(oktaResponse: OktaResponse) {
    if (environment.bwOktaEnabled == true && oktaResponse && oktaResponse !== null) {
      this.phres = new Authenticatephres({
        authenticatePolicyHolderResult: true,
        phUser: ({
          email: oktaResponse.users.profile.email,
          userId: oktaResponse.users.profile.email,
          jwtToken: oktaResponse.okta.sessionToken,
          pointHookID: oktaResponse.users.profile.policyHookId,
          okta: oktaResponse,
        })
      })
      return this.phres;
    }
  }
}
