import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { GlobalConstants } from '../constants/global.constant';
import { SSOOKTALoginRequest } from '../shared/model/api/policyholder/findph.model';
import { MessagesService } from '../shared/services/messages.service';
import { BWErrorhandler } from './errorhandler';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class BwappService {

  constructor(private _http: HttpClient, private msgService: MessagesService,
    private logger: LoggerService, private systemErrorHandler: BWErrorhandler) { }

  private formatErrors(errors: any) {
    // return new ErrorObservable(errors);
    // Tracker.log('Errors from the request :::::', errors);
    return of(errors);
  }

  // Serve all GET requests
  get(apipath: string, params: HttpParams): Observable<any> {
    const type = 'get/';
    return this._http.get(`${environment.apiServiceUrl}${apipath}`, {params})
    .pipe(
      tap(_ =>  {
        /*this.msgService.elapsedTimeMsgs.subscribe(msgs => {
        });*/
      }),
      catchError(this.systemErrorHandler.handleError('', `${apipath}`, params)));
  }

  getPaymentProfiles(apipath: string, accNumber: string): Observable<any> {
    const type = 'get/';
    return this._http.get(`${environment.apiServiceUrl}epc/${apipath}/${accNumber}/ownerId`)
      .pipe(
        tap(_ => {
          /*this.msgService.elapsedTimeMsgs.subscribe(msgs => {
          });*/
        }),
        catchError(this.systemErrorHandler.handleError('', `${apipath}`)));
  }

  // Serve all POST requests with Path and Payload
  post(apipath: string, body: any): Observable<any> {
    const type = 'post/';
    return this._http.post(`${environment.apiServiceUrl}${apipath}`, JSON.stringify(body))
    .pipe(
      tap(_ =>  {
       /*this.msgService.elapsedTimeMsgs.subscribe(msgs => {
        });*/
      }),
      catchError(this.systemErrorHandler.handleError('', `${apipath}`, body)));
  }

  

  postOkta(apipath: string, body: any): Observable<any> {
    const type = 'post/';
    return this._http.post(`${environment.securityApiEndpoint}${apipath}`, JSON.stringify(body))
    .pipe(
      tap(_ =>  {
       /*this.msgService.elapsedTimeMsgs.subscribe(msgs => {
        });*/
      }),
      catchError(this.systemErrorHandler.handleError('', `${apipath}`, body)));
  }

  postNew(apipath: string, body: any): Observable<any> {
    const type = 'post/';
    return this._http.post(`${environment.apiServiceUrl}${apipath}`, body)
    .pipe(
      tap(_ =>  {
       /*this.msgService.elapsedTimeMsgs.subscribe(msgs => {
        });*/
      }),
      catchError(this.systemErrorHandler.handleError('', `${apipath}`, body)));
  }

   // Serve all POST requests with Path, and multiple Payload
   postwithmultiparams(apipath: string, body: any): Observable<any> {
    const type = 'postfile/';
    return this._http.post(`${environment.apiServiceUrl}${apipath}`, body)
    .pipe(catchError(this.formatErrors));
  }

  // Serve all POST requests with Path, Payload, and Header
  postwitheader(apipath: string, body: any, header: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'multipart/form-data'
      })
    };

    return this._http.post(`${environment.apiServiceUrl}${apipath}`, body, httpOptions)
    .pipe(catchError(this.formatErrors));
  }

  // Serve all PUT requests
  put(apipath: string, body: Object = {}): Observable<any> {
    return this._http.post(`${environment.apiServiceUrl}${apipath}`, JSON.stringify(body))
    .pipe(catchError(this.formatErrors));
  }

  // Serve all DELETE requests
  delete(apipath: string): Observable<any> {
    return this._http.delete(`${environment.apiServiceUrl}${apipath}`)
      .pipe(catchError(this.formatErrors));
  }

  // Local Data read
  getlocaldata(localpath: string): Observable<any> {
    return this._http.get(localpath)
    .pipe(catchError(this.systemErrorHandler.handleError('', `${localpath}`, '')));
  }

}
