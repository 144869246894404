import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { GlobalConstants } from '../../constants/global.constant';
import { MessageConstants } from '../../constants/message.constant';
import { Scrubaddrres } from '../../shared/model/api/addrtools/scrubaddrres.model';
import { CancelAmount, Requesttocancelamount } from '../../shared/model/api/policyinfo/requesttocancelamount.model';
import { Policymgmtres } from '../../shared/model/api/policymgmt/policymgmtres.model';
import { BWError } from '../../shared/model/error.model';
import { RequesttocancelStoreInfo } from '../../shared/model/requesttocancelinfo.model';
import { GoogleAnalyticsService } from '../../shared/services/analytics/google-analytics.service';
import { BwstoreService } from '../../shared/services/bwstore.service';
import { AddresstoolsService } from '../api/addrtools/addresstools.service';
import { PolicydetailsrestService } from '../api/policy/policydetailsrest.service';
import { PolicymgtService } from '../api/policymgmt/policymgt.service';
import { BWErrorhandler } from '../errorhandler';
import { formatDate } from '@angular/common';
import { Tracker } from '../../shared/utils/tracker';

@Injectable({
  providedIn: 'root'
})
export class RequesttocancelService {

  private globlalConstants = GlobalConstants;

  constructor(@Inject(LOCALE_ID) private locale: string, private _storeService: BwstoreService,
    private googleAnalytics: GoogleAnalyticsService,
    private errorHandler: BWErrorhandler, private addressService: AddresstoolsService,
    private policyMgmtService: PolicymgtService, private policyDetailsService: PolicydetailsrestService) { }

  calculateCancelAmount(cancelCenturyDate: string, mco: string, policyNum: string): Promise<CancelAmount> {
    return new Promise((resolve, reject) => {
        this.policyDetailsService.getRequestToCancelAmount(policyNum, mco, cancelCenturyDate)
              .subscribe((res: Requesttocancelamount) => {
                if (res && res.Result.toUpperCase() === 'SUCCESS') {
                  this.googleAnalytics.trackEvent('RequestCancel', 'RequestToCancelScreen', 'CalculateRefundAmountDueSuccess', 200);
                  resolve(res.RequestToCancelAmount);
                } else {
                  this.googleAnalytics.trackEvent('RequestCancel', 'RequestToCancelScreen', 'CalculateRefundAmountDueError', 200);
                  return reject(this.errorHandler.handleApplicationError(new BWError(
                    {'errorcode': 'RequestToCancelAmtCalc', 'errordesc': MessageConstants.CANCEL_AMOUNT_CALC_ERROR,
                                                    'source': 'CalculateAmountService'})));
                }
              },
              (error) => {
                return reject(this.errorHandler.handleApplicationError(new BWError(
                  {'errorcode': 'RequestToCancelAmtCalc', 'errordesc': MessageConstants.CANCEL_AMOUNT_CALC_ERROR,
                                                  'source': 'CalculateAmountService'})));
              });
    });
  }

  scrubAddress(address: string, city: string, state: string, zip: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
       this.addressService.scrubAddress(address, city, state, zip).subscribe((res: Scrubaddrres) => {
        Tracker.loginfo('RequesttocancelService', 'scrubAddress', 'scrubAddress Invoked ', JSON.stringify(res));
         if (res.status === 'FOUND_ADDRESS_CORRECT') {
           if (res.parsedAddresses[0].state.toUpperCase().trim() !== state.toUpperCase().trim()) {
            this.googleAnalytics.trackEvent('RequestCancel', 'RequestToCancelScreen', 'Mailing Address not correct info', 200);
              return reject(this.errorHandler.handleApplicationError(new BWError(
                  {'errorcode': 'RequestToCancelAddrScrub',
                    'errordesc': MessageConstants.CANCEL_ADDR_STATE_ERROR.replace('{0}', res.parsedAddresses[0].state),
                                            'source': 'AddressToolsService'})));
          } else if (res.parsedAddresses[0].city.toUpperCase().trim() !== city.toUpperCase().trim()) {
            this.googleAnalytics.trackEvent('RequestCancel', 'RequestToCancelScreen', 'Mailing Address not correct info', 200);
            return reject(this.errorHandler.handleApplicationError(new BWError(
              {'errorcode': 'RequestToCancelAddrScrub',
                      'errordesc': MessageConstants.CANCEL_ADDR_CITY_ERROR.replace('{0}', res.parsedAddresses[0].city),
                                              'source': 'AddressToolsService'})));
          } else {
           resolve(true);
          }
         } else if (res.status === 'FOUND_ADDRESS_INCORRECT_ZIP' && res.parsedAddresses[0].zipCode.trim() !== zip.trim()) {
          this.googleAnalytics.trackEvent('RequestCancel', 'RequestToCancelScreen', 'Mailing Address not correct info', 200);
          return reject(this.errorHandler.handleApplicationError(new BWError(
            {'errorcode': 'RequestToCancelAddrScrub',
                    'errordesc': MessageConstants.CANCEL_ADDR_ZIP_ERROR.replace('{0}', res.parsedAddresses[0].zipCode),
                                            'source': 'AddressToolsService'})));
         } else if (res.status === 'SERVICE_FAILURE') {
          return reject(this.errorHandler.handleApplicationError(new BWError(
            {'errorcode': 'RequestToCancelAddrScrub',
                    'errordesc': 'Scrubing service failure',
                                            'source': 'AddressToolsService'})));
         } else {
          this.googleAnalytics.trackEvent('RequestCancel', 'RequestToCancelScreen', 'Mailing Address bad Scrub', 200);
          return reject(this.errorHandler.handleApplicationError(new BWError(
            {'errorcode': 'RequestToCancelAddrScrub',
                    'errordesc': res.addressResultDescription,
                                            'source': 'AddressToolsService'})));
         }
       });
    });
  }

  requestToCancel(policyNum: string, cancelDate: string, cancelCenturyDate: string,
                        mco: string, riskState: string, isMailingAddressChanged: boolean,
                        addressLine1: string, city: string, state: string, zip: string, eftIndicator: string,
                        requestToCancelInfo: RequesttocancelStoreInfo): Promise<Boolean> {
    return new Promise((resolve, reject) => {
      this.policyMgmtService.requestCancel(policyNum, cancelDate, mco, riskState,
        isMailingAddressChanged, requestToCancelInfo.cancelChecks.IsShortRateState, addressLine1,
        city, state, zip).subscribe((res: Policymgmtres) => {
          Tracker.loginfo('RequesttocancelService', 'requestToCancel', 'requestCancel Invoked ', JSON.stringify(res));

          if (res.status === '0') {
            requestToCancelInfo.cancelDate = cancelDate;
            const formattedCancelDate = formatDate(cancelDate, 'MM/dd/yyyy', this.locale);
            const formattedCurrentDate = formatDate(new Date(), 'MM/dd/yyyy', this.locale);
            if (formattedCancelDate !== formattedCurrentDate) {
              requestToCancelInfo.cancelTime = '12:01 AM';
            } else {
              requestToCancelInfo.cancelTime = formatDate(new Date(), 'hh:mm a', this.locale);
            }
            const cancelDateTimstamp = formattedCancelDate.concat(' ').concat(requestToCancelInfo.cancelTime);

            requestToCancelInfo.confirmationNumber = res.errorMsg;

            // Call the calculate cancelAmount
            this.calculateCancelAmount(cancelCenturyDate, mco, policyNum)
                  .then((cancelAmtRes: CancelAmount) => {
                    Tracker.loginfo('RequesttocancelService', 'requestToCancel', 'calculateCancelAmount Invoked ',
                                                                                  JSON.stringify(cancelAmtRes));
                    // Store cancel amount in store
                    requestToCancelInfo.cancelAmount = cancelAmtRes;
                    this._storeService.updateRequestToCancelPolicyInfo(requestToCancelInfo);

                    const renewalInd = requestToCancelInfo.cancelChecks.HasRenewal ? 'Yes' : 'No';
                    // Call the ExtreamRecordPostCancellation operation
                    this.policyMgmtService.createExstreamRecordPostCancellation(mco, policyNum,  cancelDateTimstamp,
                      cancelAmtRes.AmountType.trim() === 'Amount Due' ? cancelAmtRes.cancelAmount : '-' + cancelAmtRes.cancelAmount,
                      res.errorMsg, eftIndicator, renewalInd)
                          .subscribe((exstreamres: Policymgmtres) => {
                            Tracker.loginfo('RequesttocancelService', 'requestToCancel', 'createExstreamRecordPostCancellation Invoked ',
                                                                                        JSON.stringify(exstreamres));
                            if (exstreamres && exstreamres.status === '0') {
                                // Call Exstream to create a document in EDMR
                                  this.policyDetailsService.createDocRequestInEDMR(policyNum, mco)
                                      .subscribe(edmrres => {
                                        Tracker.loginfo('RequesttocancelService', 'requestToCancel', 'createDocRequestInEDMR Invoked ',
                                                                                            JSON.stringify(edmrres));
                                        if (edmrres && edmrres.response.toUpperCase() === 'SUCCESS') {
                                          this.googleAnalytics.trackEvent('RequestCancel', 'RequestToCancelScreen',
                                                                        'CancelSubmitSuccessful', 200);
                                          resolve(true);
                                        }
                                      });
                            } else {
                            this.googleAnalytics.trackEvent('RequestCancel', 'RequestToCancelScreen', 'Cannot process request Error', 200);
                              return reject(this.errorHandler.handleApplicationError(new BWError(
                                {'errorcode': 'RequestToCancel', 'errordesc': MessageConstants.CANCEL_REQUEST_TO_CANCEL_FAILED,
                                                                'source': 'PolicyMgmtService'})));
                            }
                      });

            });
          } else {
            Tracker.loginfo('RequesttocancelService', 'requestToCancel', 'Request To Cancel', JSON.stringify(res));
            if (res.errorMsg === null || res.errorMsg === '') {
              this.googleAnalytics.trackEvent('RequestCancel', 'RequestToCancelScreen', 'Cannot process request Error', 200);
              return reject(this.errorHandler.handleApplicationError(new BWError(
                {'errorcode': 'RequestToCancel', 'errordesc': MessageConstants.CANCEL_REQUEST_TO_CANCEL_FAILED,
                                                'source': 'PolicyMgmtService'})));
            } else if (res.status === '2') {
              this.googleAnalytics.trackEvent('RequestCancel', 'RequestToCancelScreen', 'Pending Transaction Error', 200);
              return reject(this.errorHandler.handleApplicationError(new BWError(
                {'errorcode': 'RequestToCancel', 'errordesc': res.errorMsg, 'source': 'PolicyMgmtService'})));
            } else if (res.status === '3') {
              this.googleAnalytics.trackEvent('RequestCancel', 'RequestToCancelScreen', 'Future Cancel Error', 200);
              return reject(this.errorHandler.handleApplicationError(new BWError(
                {'errorcode': 'RequestToCancel', 'errordesc': res.errorMsg, 'source': 'PolicyMgmtService'})));
            } else {
              this.googleAnalytics.trackEvent('RequestCancel', 'RequestToCancelScreen', 'Cannot process request Error', 200);
              return reject(this.errorHandler.handleApplicationError(new BWError(
                {'errorcode': 'RequestToCancel', 'errordesc': res.errorMsg, 'source': 'PolicyMgmtService'})));
            }
          }
        });
    });
  }



}
