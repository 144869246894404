import { BwappService } from '../../bwapp.service';
import { GlobalConstants } from '../../../constants/global.constant';
import { Scrubaddrreq } from '../../../shared/model/api/addrtools/scrubaddrreq.model';
import * as i0 from "@angular/core";
import * as i1 from "../../bwapp.service";
var AddresstoolsService = /** @class */ (function () {
    function AddresstoolsService(appService) {
        this.appService = appService;
        this.globlalConstants = GlobalConstants;
    }
    AddresstoolsService.prototype.scrubAddress = function (address, city, state, zip) {
        this.scrubAddrsReq = new Scrubaddrreq();
        this.scrubAddrsReq.fullAddress = address;
        this.scrubAddrsReq.city = city;
        this.scrubAddrsReq.state = state;
        this.scrubAddrsReq.zip = zip;
        this.scrubAddrsReq.sourceSystem = this.globlalConstants.APPID;
        this.scrubAddrsReq.addressType = 'Mailing';
        return this.appService.post(this.globlalConstants.SCRUB_ADDRESS, this.scrubAddrsReq);
    };
    AddresstoolsService.ngInjectableDef = i0.defineInjectable({ factory: function AddresstoolsService_Factory() { return new AddresstoolsService(i0.inject(i1.BwappService)); }, token: AddresstoolsService, providedIn: "root" });
    return AddresstoolsService;
}());
export { AddresstoolsService };
