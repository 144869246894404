import { BwappService } from '../../bwapp.service';
import { Observable } from 'rxjs';
import { HelpTextRes } from '../../../shared/model/api/helptext/helptext.model';
import { HttpParams } from '@angular/common/http';
import { GlobalConstants } from '../../../constants/global.constant';
import { map } from 'rxjs/operators';
import { HelpTextMap } from '../../../shared/maps/helptext-map';
import { BwstoreService } from '../../../shared/services/bwstore.service';
import * as i0 from "@angular/core";
import * as i1 from "../../bwapp.service";
import * as i2 from "../../../shared/services/bwstore.service";
var HelptextService = /** @class */ (function () {
    function HelptextService(appService, bwStoreService) {
        this.appService = appService;
        this.bwStoreService = bwStoreService;
        this.globlalConstants = GlobalConstants;
        this.helpTextMap = new HelpTextMap();
    }
    HelptextService.prototype.getHelpText = function (mco, statecd, ratebook) {
        var helpTextmap = new HelpTextMap();
        var params = new HttpParams()
            .set('appid', GlobalConstants.APPID_OLD)
            .set('mco', '30')
            .set('state', 'FL')
            .set('ratebook', ratebook)
            .set('lob', GlobalConstants.LOB_AUTO);
        return this.appService.get(this.globlalConstants.GET_HELPTEXT, params)
            .pipe(map(function (res) {
            res.forEach(function (ht) {
                helpTextmap.set(ht.fieldID, ht);
            });
            return helpTextmap;
        }));
    };
    HelptextService.prototype.getHelpTextByIds = function (fieldIds) {
        var _this = this;
        this.policyInfo = this.bwStoreService.getPolicyInfoFromStore().policyInfo;
        var helpTextmap = new HelpTextMap();
        var params = new HttpParams()
            .set('fieldIds', fieldIds)
            .set('appid', GlobalConstants.APPID_OLD)
            .set('mco', this.policyInfo.MASTER0CO)
            .set('state', this.policyInfo.RISK0STATE)
            .set('ratebook', this.policyInfo.PolicyRateBook)
            .set('lob', GlobalConstants.LOB_AUTO);
        return this.appService.get(this.globlalConstants.GET_HELPTEXT_BYID, params)
            .pipe(map(function (res) {
            res.forEach(function (ht) {
                helpTextmap.set(ht.fieldID, ht);
            });
            console.log('mco:', _this.policyInfo.MASTER0CO, '   statecd:', _this.policyInfo.RISK0STATE, '   ratebook: ', _this.policyInfo.PolicyRateBook, '   fieldIds: ', fieldIds);
            return helpTextmap;
        }));
    };
    HelptextService.ngInjectableDef = i0.defineInjectable({ factory: function HelptextService_Factory() { return new HelptextService(i0.inject(i1.BwappService), i0.inject(i2.BwstoreService)); }, token: HelptextService, providedIn: "root" });
    return HelptextService;
}());
export { HelptextService };
