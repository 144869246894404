import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login/login.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
var ForgotUseridComponent = /** @class */ (function () {
    function ForgotUseridComponent(_phService, router, modal) {
        this._phService = _phService;
        this.router = router;
        this.modal = modal;
        this.forgotUserIdForm = new FormGroup({});
        this.errorForgetId = false;
        this.fgusidErrorMsg = '';
        this.policynum = '';
    }
    ForgotUseridComponent.prototype.ngOnInit = function () {
    };
    ForgotUseridComponent.prototype.resetUserID = function (form) {
        var _this = this;
        // make call to service to remove the existing user registration
        // route to registration-one to complete the regestration set up
        // If User Found then call service removePH() -> when removed get the policy number and route it to regiration page 1
        // Else Show message Please try again.
        this.forgotIdformSubmitAttempt = true;
        if (this.forgotUserIdForm.valid) {
            // Tracker.log('Calling processForgotUserIdWf Service.........');
            // service call
            this._phService.processForgotUserIdWf(form.value.phpolicynum).then(function (data) {
                // Tracker.log('forgot userId Response', data);
                var queryParams = {};
                _this.modal.dismiss();
                queryParams = { policyNumber: form.value.phpolicynum.toUpperCase(),
                    LastName: '' }; // Need to check how we will get the last name of Ph
                // Tracker.log('navigating to register step 1', data);
                _this.router.navigate(['/register'], { queryParams: queryParams });
            }).catch(function (error) {
                _this.errorForgetId = true;
                _this.fgusidErrorMsg = error;
                // Tracker.log('There is an error occurred while enrolling the policy');
            });
        }
    };
    ForgotUseridComponent.prototype.dismiss = function () {
        this.modal.dismiss();
    };
    return ForgotUseridComponent;
}());
export { ForgotUseridComponent };
