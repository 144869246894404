export class Newuserssoreq {

    szSymbol: string;
    szPolicy: string;
    szMOD: string;
    szDOB: string;
    szZIP: string;
    szMCO: string;
    szUsername: string;
    szPassword: string;
    szFirstName: string;
    szLastName: string;
    szPIN: string;
    szQuestion1: string;
    szQuestion2: string;
    szQuestion3: string;
    szAnswer1: string;
    szAnswer2: string;
    szAnswer3: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }

}
