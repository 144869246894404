<form class="mt-2 col-md-12 col-sm-12" [formGroup]="phoneEditForm">
  <div class="modal-header">
    <h4 class="modal-title heading-2 mb-1">Edit Phone Number</h4>
  </div>
  <div class="modal-body">
    <div *ngIf="inProgesss">
      <!--Loading Icon-->
    </div>
    <div *ngIf="inProgesss" class="small text-center"> Updating </div>
    <div *ngIf="inProgesss" class="my-5 text-center">
      <app-bw-spinner [smallSpinner]="false"></app-bw-spinner>
    </div>


    <div class="form-row" *ngIf="!phoneUpdated">
      <div class="col-md-10">
        <div class="form-group" *ngIf="!inProgesss">
          <label for="">Phone</label>
          <input appMobileNumberinput type="text" class="form-control form-control-lg" formControlName="phoneNumber" maxlength="12"/>
          <app-field-error-display [displayError]="isFieldValid('phoneNumber')" [errorMsg]="getErrMessage(phoneEditForm.controls['phoneNumber'].errors, 'phoneNumber')"></app-field-error-display>
        </div>
      </div>
    </div>

    <div *ngIf="phoneUpdated">
      <div class="col-md-10">
          <div class="row mt-4 mb-4 mb-4">
            <div class="container-fluid px-3">
              <div class="col-12">
                <h1 class="heading-3 text-center">
                  <p> <i class="far fa-check-circle circle-success"></i></p>
                </h1>
              </div>
            </div>
          </div>

          <div class="row mt-md-4 mt-lg-4 mb-md-3 mb-lg-3">
            <div class="container-fluid px-3 ">
              <p class="text-center">Your Phone Number has been updated to: 123-456-7890</p>
              <p class="text-center"><a href="javascript:void(0);" (click)="navigateToEnrolTxtAlert()">Enrol this Phone Number to Text Alerts</a></p>
            </div>
          </div>

      </div>
    </div>

  </div>

  <div class="modal-footer mt-1">
    
    <div *ngIf="phoneUpdated">
        <button type="submit" class="btn btn-primary" (click)="phoneEditModal.dismiss('Close')">Close</button>
    </div>
    <div *ngIf="!phoneUpdated">       
        <button *ngIf="!submitted" type="button" class="btn btn-secondary" (click)="phoneEditModal.dismiss('Close')">Cancel</button>
        <button *ngIf="!submitted" type="submit" class="btn btn-primary" (click)="submitPhoneEndorsement(phoneEditForm.value)">Submit</button>&nbsp;&nbsp;
      </div>
  </div>

</form>
