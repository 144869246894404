import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment.qa";
import { map } from "rxjs/operators";
import { GlobalConstants } from "../../../constants/global.constant";
import { OktaMapperService } from "../../../shared/services/okta-mapper.service";
import { BwappService } from "../../bwapp.service";
import { SSOOKTALoginRequest } from "../../../shared/model/api/policyholder/findph.model";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../bwapp.service";
import * as i3 from "../../../shared/services/okta-mapper.service";
var OktaService = /** @class */ (function () {
    function OktaService(_http, appService, oktaMapperService) {
        this._http = _http;
        this.appService = appService;
        this.oktaMapperService = oktaMapperService;
        this.globlalConstants = GlobalConstants;
    }
    OktaService.prototype.oktaLogin = function (loginRequestData) {
        var _this = this;
        var oktaRequest = {
            username: loginRequestData.userName,
            password: loginRequestData.password
        };
        return this.appService.postOkta(this.globlalConstants.AUTHN, oktaRequest).pipe(map(function (OktaResponse) {
            return _this.oktaMapperService.oktaToAuthenticatePhres(OktaResponse);
        }));
    };
    OktaService.prototype.oktaLoginNative = function (loginRequestData) {
        return Observable.create(function (observer) {
            var oktaLoginUrl = environment.securityApiEndpoint;
            var xhttp = new XMLHttpRequest();
            xhttp.onload = function () {
                if (this.readyState == 4 && this.status == 200) {
                    observer.next((JSON.parse(this.responseText)));
                    observer.complete();
                }
                else {
                    observer.error(JSON.parse(this.responseText));
                }
            };
            xhttp.open("POST", oktaLoginUrl, true);
            xhttp.setRequestHeader("Content-Type", "application/json");
            xhttp.withCredentials = false;
            xhttp.send(JSON.stringify(loginRequestData));
        });
    };
    //US396636_Okta_ForgotPwd Changes Start
    OktaService.prototype.forgotPassword = function (forgotPasswordRequest) {
        return this.appService.postOkta(this.globlalConstants.RECOVERY_PASSWORD, forgotPasswordRequest);
    };
    //US396636_Okta_ForgotPwd Changes End
    OktaService.prototype.oktaSSO = function (token) {
        //create a object for the service 
        var ssoOktaLoginRequest = new SSOOKTALoginRequest();
        ssoOktaLoginRequest.code = token;
        ssoOktaLoginRequest.usertype = GlobalConstants.OKTA_USERTYPE;
        ssoOktaLoginRequest.redirect_uri = GlobalConstants.OKTA_REDIRECT_URI;
        ssoOktaLoginRequest.isaccesstokenrequired = GlobalConstants.OKTA_ISACCESSTOKENREQUIRED;
        // set the headers
        var headers = new HttpHeaders();
        //headers.set('Cookie','a4da344fbe4f02fce60cab9f7bc9cc1e=f3b32514a4c92af1cdb32e79935eed69; ADRUM_BT=R:0|i:353894|g:a8335141-fbd9-429b-9a2f-8b981789333d349|e:0|s:f|h:e|n:fig_f08e7d36-53bd-409c-a070-91e6f5d79d0f');
        headers.set('Content-Type', 'application/json');
        //headers.set(environment.farmersRegionHeaderKey,environment.farmersRegionHeaderValue);
        // set the url
        var url = "" + environment.securityApiEndpoint + GlobalConstants.SSO_OKTA;
        return this._http.post(url, ssoOktaLoginRequest, { headers: headers });
    };
    OktaService.prototype.oktaJWTDecode = function (accessToken) {
        var body = {
            authorization: accessToken
        };
        var url = "" + environment.securityApiEndpoint + GlobalConstants.OKTA_DECODE_JWT;
        return this._http.post(url, body);
    };
    //US400326 changes start
    OktaService.prototype.validateRecoveryToken = function (stateTokenRequest) {
        return this.appService.postOkta(this.globlalConstants.STATE_TOKEN, stateTokenRequest);
    };
    OktaService.prototype.resetPassword = function (resetPasswordRequest) {
        return this.appService.postOkta(this.globlalConstants.RESET_PASSWORD, resetPasswordRequest);
    };
    OktaService.ngInjectableDef = i0.defineInjectable({ factory: function OktaService_Factory() { return new OktaService(i0.inject(i1.HttpClient), i0.inject(i2.BwappService), i0.inject(i3.OktaMapperService)); }, token: OktaService, providedIn: "root" });
    return OktaService;
}());
export { OktaService };
