import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../../../environments/environment.qa";
import { ForgotPasswordRequest } from "../../../shared/model/api/okta/forgot-password-request.model";
import { OktaRequest, OktaResponse } from "../../../shared/model/api/okta/okta-model";
import { map } from "rxjs/operators";
import { GlobalConstants } from "../../../constants/global.constant";
import { Authenticatephreq } from "../../../shared/model/authentication/authenticatephreq";
import { OktaMapperService } from "../../../shared/services/okta-mapper.service";
import { BwappService } from "../../bwapp.service";
import { SSOOKTALoginRequest } from "../../../shared/model/api/policyholder/findph.model";
import { StateTokenRequest } from "../../../shared/model/api/okta/state-token-request";
import { ResetPasswordRequest } from "../../../shared/model/api/okta/reset-password-request";

@Injectable({
  providedIn: "root",
})
export class OktaService {

  private globlalConstants = GlobalConstants;

  constructor(private _http: HttpClient, private appService: BwappService, private oktaMapperService: OktaMapperService) {}


  oktaLogin(loginRequestData: Authenticatephreq) {
    let oktaRequest: OktaRequest = {
      username: loginRequestData.userName,
      password: loginRequestData.password
    }
    return this.appService.postOkta(this.globlalConstants.AUTHN, oktaRequest).pipe(map((OktaResponse: OktaResponse) => {
      return this.oktaMapperService.oktaToAuthenticatePhres(OktaResponse);
    }))
  }
 
  oktaLoginNative(loginRequestData: OktaRequest): Observable<OktaResponse> {
    return  Observable.create(observer => {

      let oktaLoginUrl :string = environment.securityApiEndpoint;
      let xhttp = new XMLHttpRequest();

      xhttp.onload = function () {
        if (this.readyState == 4 && this.status == 200) {
          observer.next((JSON.parse(this.responseText)));
          observer.complete();
        }else{
          observer.error(JSON.parse(this.responseText));
        }
      };
      xhttp.open(
        "POST",
        oktaLoginUrl,
        true
      );
      xhttp.setRequestHeader("Content-Type", "application/json");
      xhttp.withCredentials = false;
      xhttp.send(JSON.stringify(loginRequestData));

    });
  }
  //US396636_Okta_ForgotPwd Changes Start
  forgotPassword(forgotPasswordRequest: ForgotPasswordRequest){
    return this.appService.postOkta(this.globlalConstants.RECOVERY_PASSWORD, forgotPasswordRequest);
  }
  //US396636_Okta_ForgotPwd Changes End

  oktaSSO(token : string):Observable<any>{
    //create a object for the service 
    let ssoOktaLoginRequest = new SSOOKTALoginRequest();
    ssoOktaLoginRequest.code = token;
    ssoOktaLoginRequest.usertype = GlobalConstants.OKTA_USERTYPE;
    ssoOktaLoginRequest.redirect_uri = GlobalConstants.OKTA_REDIRECT_URI;
    ssoOktaLoginRequest.isaccesstokenrequired = GlobalConstants.OKTA_ISACCESSTOKENREQUIRED;
    // set the headers
    let headers = new HttpHeaders();
    //headers.set('Cookie','a4da344fbe4f02fce60cab9f7bc9cc1e=f3b32514a4c92af1cdb32e79935eed69; ADRUM_BT=R:0|i:353894|g:a8335141-fbd9-429b-9a2f-8b981789333d349|e:0|s:f|h:e|n:fig_f08e7d36-53bd-409c-a070-91e6f5d79d0f');
    headers.set('Content-Type','application/json');
    //headers.set(environment.farmersRegionHeaderKey,environment.farmersRegionHeaderValue);
    // set the url
    let url  = `${environment.securityApiEndpoint}${GlobalConstants.SSO_OKTA}`;
    return this._http.post(url,ssoOktaLoginRequest,{headers : headers});
  
  }

  oktaJWTDecode(accessToken:string):Observable<any>{
    let body = {
      authorization : accessToken
    }
    let url = `${environment.securityApiEndpoint}${GlobalConstants.OKTA_DECODE_JWT}`;
    return this._http.post(url,body);
  }
  //US400326 changes start
  validateRecoveryToken(stateTokenRequest : StateTokenRequest){
    return this.appService.postOkta(this.globlalConstants.STATE_TOKEN, stateTokenRequest);
  }
  resetPassword(resetPasswordRequest:ResetPasswordRequest){
    return this.appService.postOkta(this.globlalConstants.RESET_PASSWORD, resetPasswordRequest);
  }
  //US400326 changes End

}
