import { FullPolicyDetails, PolicyInfo, AgentInfo, EFTInfo } from '../../shared/model/api/bwibic/fullpolicydetails.model';
import { Paymentsummary, PaymentInfo } from '../../shared/model/api/bwibic/fullpolicydetails.model';
import * as BwPolicyInfoActions from '../actions/bwpolicy.actions';
import { MissingDocument } from '../../shared/model/api/missingDocs/missing-document';
var defaultPolicyInfoState = new FullPolicyDetails();
var removePolicyInfoState = {
    result: '',
    message: '',
    isNoLogin: false,
    policyInfo: new PolicyInfo(''),
    agentInfo: new AgentInfo(''),
    paymentHistoryInfo: [],
    paymentInfo: new PaymentInfo(''),
    paymentSummary: new Paymentsummary(''),
    eftInfo: new EFTInfo(''),
    isDefaultdocumentType: '',
    missingDocsRes: new MissingDocument()
};
// Helper to create new state object as the state is immutable
var newState = function (state, newDataObj) {
    return Object.assign({}, state, newDataObj);
};
var ɵ0 = newState;
export function BwPolicyInfoReduer(state, action) {
    if (state === void 0) { state = defaultPolicyInfoState; }
    switch (action.type) {
        case BwPolicyInfoActions.CREATE_POLICYINFO:
            //  Tracker.loginfo('BwPolicyInfoReduer', 'BwPolicyInfoReduer()', 'This is the POLICYINFO STORE: Adding to Store',
            //   'ActionType: ' + action.type + ' Payload:' + action.payload);
            return newState(state, action.payload);
        case BwPolicyInfoActions.UPDATE_POLICYINFO:
            // Tracker.loginfo('BwPolicyInfoReduer', 'BwPolicyInfoReduer()', 'This is the POLICYINFO STORE: Updating state to Store',
            // 'ActionType: ' + action.type + ' Payload:' + action.payload);
            return newState(state, action.payload);
        case BwPolicyInfoActions.REMOVE_POLICYINFO:
            // Tracker.loginfo('BwPolicyInfoReduer', 'BwPolicyInfoReduer()', 'This is the POLICYINFO STORE: Removing from Store',
            // 'ActionType: ' + action.type + ' Payload:' + action.payload);
            return newState(state, removePolicyInfoState);
        default:
            return state;
    }
}
export { ɵ0 };
