import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalConstants } from '../../../constants/global.constant';
import { InvokeEDMRDocsRes } from '../../../shared/model/api/edmr/invokedocsres.model';
import { BwappService } from '../../bwapp.service';
import { Uploadfilereq } from '../../../shared/model/api/edmr/uploadfilereq.model';
import { Savedocres } from '../../../shared/model/api/edmr/savedocres.model';
import { Documentres } from '../../../shared/model/api/edmr/documentres';

@Injectable({
  providedIn: 'root'
})
export class EdmrService {

  private globlalConstants = GlobalConstants;

  constructor(private http: HttpClient, private appService: BwappService) { }

  invokeEDMRDocuments(policynumber: string, transactioncode: string, srcsystem: string): Observable<InvokeEDMRDocsRes> {
    const params = new HttpParams()
    .set('policyNum', policynumber)
    .set('transactionCode', transactioncode)
    .set('sourceSystem', srcsystem);

  return this.appService.get(this.globlalConstants.EDMR_INVOKEDOCUMENTS, params);
  }

  getDocument(seqNumber: string): Observable<Documentres> {
    const params = new HttpParams()
    .set('seqNumber', seqNumber);

    return this.appService.get(this.globlalConstants.EDMR_GETDOCUMENT, params);
  }

  getPolicyContract(url: string): Observable<Documentres> {
    const params = new HttpParams()
    .set('url', url);

    return this.appService.get(this.globlalConstants.EDMR_GETPOLICYCONTRACT, params);
  }

  uploadDocument(saveDocumentReq: Uploadfilereq, currentFileUpload: File): Observable<Savedocres> {
    // : Observable<HttpEvent<{}>> {

    const fileData: FormData = new FormData();
    fileData.append('uploadDoc', currentFileUpload, currentFileUpload.name);
    fileData.append('saveDocumentReq', new Blob([JSON.stringify(saveDocumentReq)],
    {
        type: 'application/json'
    }));

    /*const apipath =  GlobalConstants.UPLOAD_DOCUMENT;
    const req = new HttpRequest('POST', `${environment.apiServiceUrl}${apipath}`, fileData,  {
      reportProgress: true
    });

   return this.http.request(req);*/

   return this.appService.postwithmultiparams(this.globlalConstants.UPLOAD_DOCUMENT, fileData);

  }

}
