import { Component, OnInit, ViewEncapsulation , ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../../../shared/services/analytics/google-analytics.service';
import { NgbCarouselConfig  } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-auto-insurance101',
  templateUrl: './auto-insurance101.component.html',
  styleUrls: ['./auto-insurance101.component.scss']
})
export class AutoInsurance101Component implements OnInit {
  @ViewChild('carousel')
  carousel: any;
  constructor(private carosel: NgbCarouselConfig,private router: Router,private googleAnalytics: GoogleAnalyticsService,) {
    carosel.interval = 30000;
    carosel.wrap = true;
    carosel.showNavigationArrows = false;
   }

  ngOnInit() {
  }
  ngAfterViewInit(): void {
    var accDiv = document.getElementsByClassName("acc-nocard-header");
    var CardsList = accDiv[0].getElementsByClassName("card");
    var CardHeaderList = accDiv[0].getElementsByClassName("card-header");
    var btnLinkList = accDiv[0].getElementsByClassName("btn-link");
    
    for (let index = CardsList.length-1; index >= 0; index--) {
      const element = CardsList[index];
      element.classList.remove("card");
    }
    for (let index = CardHeaderList.length-1; index >= 0; index--) {
      const element = CardHeaderList[index];
      element.classList.remove("card-header");
    }
    for (let index = btnLinkList.length-1; index >= 0; index--) {
      const element = btnLinkList[index];
      element.classList.remove("btn");
    }

    var dnar = document.getElementsByClassName("down-arrow-div-pos");
    var zipDiv = document.getElementsByClassName("zip-code");
    const agent = window.navigator.userAgent.toLowerCase();
    if(agent.indexOf('trident') > -1)
    {
      zipDiv[0].classList.add("zip-ie");
      for (let index = dnar.length-1; index >= 0; index--) {
        const element = dnar[index];
        element.classList.remove("arrow-down");
      }
    } else {
      zipDiv[0].classList.remove("zip-ie");
      for (let index = dnar.length-1; index >= 0; index--) {
        const element = dnar[index];
        element.classList.add("arrow-down");
      }

    }  
  }

  previousSlide () {
    this.carousel.prev();
  }
  nextSlide() {
    this.carousel.next();
  }
}
