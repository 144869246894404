import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../environments/environment';
import { GlobalConstants } from '../../../../constants/global.constant';
import { EdmrService } from '../../../../services/api/edmr/edmr.service';
import { PolicymgtService } from '../../../../services/api/policymgmt/policymgt.service';
import { BWErrorhandler } from '../../../../services/errorhandler';
import { VehicleService } from '../../../../services/policydetails/vehicle.service';
import { Keyvalue } from '../../../../shared/model/keyvalue';
import { FullPolicyDetails } from '../../../model/api/bwibic/fullpolicydetails.model';
import { RTAFormreq } from '../../../model/api/edmr/rtaformreq';
import { RTAFormres } from '../../../model/api/edmr/rtaformres';
import { Vehicles } from '../../../model/api/policyinfo/vehicle.model';
import { VehiclesResponse } from '../../../model/api/policyinfo/vehicledetailsres.model';
import { BWError } from '../../../model/error.model';
import { Policynumber } from '../../../model/policynumber';
import { GoogleAnalyticsService } from '../../../services/analytics/google-analytics.service';
import { BwstoreService } from '../../../services/bwstore.service';
import { MessagesService } from '../../../services/messages.service';
import { CommonUtil } from '../../../utils/commonutil';
import { Tracker } from '../../../utils/tracker';

@Component({
  selector: 'app-vehicle-select',
  template: `
  <div class="vehicle-select">
    <div class="modal-body pb-3">
      <div *ngIf="(!this.showLoadingMessage)" class="text-center"><p class="font-weight-bold">{{ this.vehicleSelectMessage }}</p></div>
      <div *ngIf="(this.hasError)" class="text-center weight-300"><p>{{ this.errorMsg }}</p></div>
      <div *ngIf="(this.showLoadingMessage)" class="text-center mt-2 mb-3"><p>{{ this.loadingMessage }}</p></div>
      <div *ngIf="(this.showLoadingMessage)" class="app-loading mb-2">
        <div class="logoload" style="width:75px;height:75px;"></div>
        <svg class="spinner" style="width:125px;height:125px;" viewBox="25 25 50 50">
           <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
        </svg>
      </div>

      <form class="my-4" *ngIf="(this.showForm)" [formGroup]="vehicleSelectForm">
        <div class="form-group">
        <label for="amount">Please select a vehicle</label><br/>
        <select class="form-control form-control-sm" formControlName="vehicleId" id="vehicleId">
          <option *ngFor="let keyvalue of this.vehicleOptions" value="{{ keyvalue.value }}">
              {{ keyvalue.key }}
          </option>
        </select>
        <app-field-error-display [displayError]="isFieldValid('vehicleId')"
        [errorMsg]="getErrMessage(vehicleSelectForm.controls['vehicleId'].errors, 'vehicleId')">
        </app-field-error-display>
      </div>
      </form>
      </div>
    <div *ngIf="this.allowClose" class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="dismiss()">Close</button>
      <button *ngIf="(!this.hasError)"
      type="button" class="btn btn-primary" (click)="onSubmit(vehicleSelectForm.value)">Generate Form</button>
    </div>
  </div>
  `,
  styleUrls: ['./vehicle-select.component.scss']
})
export class VehicleSelectComponent implements OnInit {

  showForm = false;
  allowClose = false;
  hasError = false;
  showLoadingMessage = false;
  errorMsg = 'Unable to retrieve your RTA Form at this time.';
  loadingMessage = 'Loading your RTA Form';
  rtaForm = '';
  vehicleSelectForm: FormGroup;
  vehicleSelectMessage = '';
  basicPolicyInfo: FullPolicyDetails;
  keyvalue: Keyvalue;
  vehicleOptions: Keyvalue[];
  vehiclesResponse: VehiclesResponse;

  formSubmitAttempt: boolean;
  rtaFormReq: RTAFormreq;
  rtaFormRes: RTAFormres;

  policyNumber: Policynumber;
  mco = '';

  constructor(private fb: FormBuilder,
    public vehicleSelectModal: NgbActiveModal,
    private policyManagementService: PolicymgtService,
    private edmrService: EdmrService,
    private vehicleService: VehicleService,
    private _messageService: MessagesService,
    private errorHandler: BWErrorhandler,
    private _storeService: BwstoreService,
    private googleAnalytics: GoogleAnalyticsService) {}

  ngOnInit() {
    this.vehicleSelectMessage = 'Request RTA Form';
    this.basicPolicyInfo = this._storeService.getPolicyInfoFromStore();

    this.vehicleSelectForm = this.fb.group({
      'vehicleId' : ['', Validators.compose([Validators.required])]
    });

    this.loadVehicles();
  }

  loadVehicles() {
    this.policyNumber = new Policynumber(this.basicPolicyInfo.policyInfo.PolicyNumber);
    this.mco = this.basicPolicyInfo.policyInfo.MASTER0CO;
    this.vehicleService.getVehicleFullDetails(this.policyNumber.toString(), this.mco)
    .subscribe((vehiclesRes) => {
      this.vehiclesResponse = vehiclesRes;
        if (vehiclesRes.Result && vehiclesRes.Result.toLocaleLowerCase() !== 'success') {
            this.errorHandler.handleApplicationError(new BWError(
            {'errorcode': '100', 'errordesc': vehiclesRes.Message, 'source': 'VehicleService'}));
            this.hasError = true;
            this.showLoadingMessage = false;
            this.allowClose = true;
         } else {
          // check which vehicle the form should be generated for
           if (vehiclesRes.Vehicles.length === 1) {
            this.showLoadingMessage = true;
            this.allowClose = false;
            this.generateRTAForm(0);
           } else {
            this.showForm = true;
            this.allowClose = true;
            this.vehicleOptions = [];
            for (let vehicle of vehiclesRes.Vehicles) {
              this.keyvalue = new Keyvalue();
              this.keyvalue.key = vehicle.Year.concat(' ').concat(vehicle.VehicleName);
              this.keyvalue.value = vehicle.VehicleID.toString();
              this.vehicleOptions.push(this.keyvalue);
            }
          }
        }
    }, error => {
      this.showLoadingMessage = false;
      this.allowClose = true;
      this.hasError = true;
    Tracker.logerror('VehicleSelectComponent', 'loadVehicles', 'getVehicleFullDetails',
    '' , error);
    });
  }

  onSubmit(formData: any) {
    this.formSubmitAttempt = true;
    if (this.vehicleSelectForm.valid) {
      this.showLoadingMessage = true;
      this.allowClose = false;
      this.showForm = false;
      this.generateRTAForm(formData.vehicleId - 1);
    }
  }

  isFieldValid(field: string) {
    return (
      (!this.vehicleSelectForm.get(field).valid && this.vehicleSelectForm.get(field).touched) ||
      (this.vehicleSelectForm.get(field).untouched && this.formSubmitAttempt)
    );
  }

  getErrMessage(errors, formCntrlName: string) {
    return this._messageService.getErrorMessages(errors, formCntrlName);
  }

  generateRTAForm(index: number) {
    // then generate the RT Form through the EDMR Forms Service
    this.rtaFormReq = new RTAFormreq();

    this.rtaFormReq.sym = this.policyNumber.symbol;
    this.rtaFormReq.polnum = this.policyNumber.policy;
    this.rtaFormReq.mod = this.policyNumber.module;
    this.rtaFormReq.mco = this.mco;
    this.rtaFormReq.appName = GlobalConstants.APPID;
    this.rtaFormReq.vin = this.vehiclesResponse.Vehicles[index].VIN;
    this.rtaFormReq.libraryContext = environment.pointContext;

    this.policyManagementService.createRTAForm(this.rtaFormReq).subscribe(rtaFormResData => {

      this.rtaFormRes = rtaFormResData;
      if (!CommonUtil.isEmpty(this.rtaFormRes) && this.rtaFormRes.status === '0') {
        Tracker.loginfo('VehicleSelectComponent', 'generateRTAForm', 'generateRTAForm',
        'Generated RTA Form for ' + this.policyNumber.toFormattedString());

        this.rtaForm = this.rtaFormRes.byteArray;
        this.downloadRTAForm(this.rtaForm);
        this.dismiss();
      } else {
        this.hasError = true;
        this.showLoadingMessage = false;
        this.allowClose = true;
      }
    },
    error => {
      this.allowClose = true;
      this.hasError = true;
      this.googleAnalytics.trackEvent('Error', 'RTAForm Error', 'policyManagementServiceError', 200);
      Tracker.logerror('VehicleSelectComponent', 'generateRTAForm', '', 'Error generating RTA Form for policy '
      + this.policyNumber.toString(), error);
    });
  }

  downloadRTAForm(pdf: string) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(CommonUtil.base64toBlob(pdf, 'application/pdf'));
      return;
    }

    // All other browsers
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement('a');
    const fileName = 'RTAForm.pdf';

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    this.googleAnalytics.trackEvent('RTAForm', 'MyPolicy', 'RTAForm Generated', 200);
    downloadLink.click();

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      document.body.removeChild(downloadLink);
      // window.URL.revokeObjectURL(data);
    });

  }
  navigate() {
    this.vehicleSelectModal.close();
  }

  dismiss() {
    this.vehicleSelectModal.dismiss();
  }

}
