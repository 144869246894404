import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConstants } from '../../../constants/global.constant';
import { ChangePHPasswordRequest } from '../../../shared/model/api/policyholder/changepassword.model';
import { CheckAnswersRequest } from '../../../shared/model/api/policyholder/checkanswers.model';
import { FindPHRequest, SSOLoginRequest } from '../../../shared/model/api/policyholder/findph.model';
import { ForgotPHPasswordRequest } from '../../../shared/model/api/policyholder/forgotphpassword.model';
import { InsertPHRequest } from '../../../shared/model/api/policyholder/insertph.model';
import { RemovePHRequest } from '../../../shared/model/api/policyholder/removeph.model';
import { ResetPHPasswordRequest } from '../../../shared/model/api/policyholder/resetpassword.model';
import { Policynumber } from '../../../shared/model/policynumber';
import { BwappService } from '../../bwapp.service';
import { UpdatePHRequest } from '../../../shared/model/api/policyholder/updateph.model';
import { Observable, of } from 'rxjs';
import { Mfares } from '../../../shared/model/api/policymgmt/mfares';
import { Mfareq } from '../../../shared/model/api/policymgmt/mfareq';
import { CheckEachAnswerRequest } from '../../../shared/model/api/policyholder/checkeachanswer.model';
import { Lienholdercmpy } from '../../../shared/model/api/policyholder/lienholdercmpy.model';
import { InsertPhRequest } from '../../../shared/model/registration/insertphreq.model';
import { environment } from '../../../../environments/environment';
import { OktaService } from '../okta/okta.service';
import { Authenticatephres, PolicyHolder } from '../../../shared/model/authentication/authenticatephres';


@Injectable({
  providedIn: 'root'
})
export class PolicyholderService {

  private globalConstants = GlobalConstants;
  policyNumber: Policynumber;
  bwoktaEnabled: boolean = environment.bwOktaEnabled;

  bwOktaEnabled : boolean = environment.bwOktaEnabled;
  constructor(private appService: BwappService,private oktaService:OktaService) { }

  tokenLogin(tokenRequest: SSOLoginRequest,bwOktaSSOEnabled :boolean) {
    if(bwOktaSSOEnabled){
     return this.tokenLoginOkta(tokenRequest);
    }else{
      return this.appService.post(this.globalConstants.SSO_LOGIN, tokenRequest);
    }
  }

  findPH(findPHRequest: FindPHRequest) {
    // Tracker.log('IN Policy Holder Service findPH()');
    return this.appService.post(this.bwoktaEnabled ? this.globalConstants.FIND_PH_DETAILS_OKTA : this.globalConstants.FIND_PH_DETAILS, findPHRequest);
  }


  tokenLoginOkta(tokenRequest:SSOLoginRequest):Observable<any>{
    return Observable.create(observer =>{
      this.oktaService.oktaSSO(tokenRequest.ssot).subscribe(
        data=>{
          // if data received correctly then call the decode-jwt 
          if(data.error && data.error.error){
            observer.error();           
           
          }else{
            let jwtTokenObject = data;
            // need to call the decode-jwt api 
            this.oktaService.oktaJWTDecode(jwtTokenObject.access_token).subscribe(
              data=>{
                if(data.policyHookId){
                  // construct the object and return phUser
                  let authenticatePHRes = new Authenticatephres();
                  let phUserInfo = new PolicyHolder();
                  phUserInfo.pointHookID = data.policyHookId;
                  phUserInfo.userId = data.sub;
                  phUserInfo.email = data.sub;
                  phUserInfo.jwtToken = jwtTokenObject.access_token;
                  phUserInfo.sessionTicket = jwtTokenObject.access_token;
                  authenticatePHRes.phUser = phUserInfo;
                  authenticatePHRes.authenticatePolicyHolderResult = true;
                  authenticatePHRes.errorMsg = "";
                  observer.next(authenticatePHRes);
                  
                }else{
                  observer.error();
                  
                }
              },
              error=>{
                observer.error();
                
              }
            );
          }
        },
        error=>{

          observer.error();
         
        }
      );
    });
    
  }

  forgotPHPassword(forgotPHPasswordRequest: ForgotPHPasswordRequest) {
    // Tracker.log('IN Policy Holder Service forgotPHPassword()');
    return this.appService.post(this.globalConstants.FORGOT_PH_PASSWORD, forgotPHPasswordRequest);
  }

  checkAnswers(checkAnswersRequest: CheckAnswersRequest) {
    // Tracker.log('IN Policy Holder Service checkAnswers()');
    return this.appService.post(this.globalConstants.CHECK_ANSWERS, checkAnswersRequest);
  }

  insertPH(inserPHRequest: InsertPHRequest) {
    // Tracker.log('IN Policy Holder Service inserPH()');
    return this.appService.post(this.globalConstants.INSERT_PH, inserPHRequest);
  }

  insertPHNew(inserPHRequest: InsertPhRequest) {
    // Tracker.log('IN Policy Holder Service inserPH()');
    return this.appService.post(this.globalConstants.INSERT_PH, inserPHRequest);
  }

  removePH(removePHRequest: RemovePHRequest) {
    // Tracker.log('IN Policy Holder Service removePH()');
    return this.appService.post(this.bwoktaEnabled ? this.globalConstants.REMOVE_PH_OKTA : this.globalConstants.REMOVE_PH, removePHRequest);
  }

  getPolicyMco(policyNumber: string) {
    // Tracker.log('IN Policy Holder Service getPolicyMco()');
    this.policyNumber = new Policynumber(policyNumber);
    const params = new HttpParams()
      .set('polSym', this.policyNumber.symbol)
      .set('polNum', this.policyNumber.policy)
      .set('polMod', this.policyNumber.module);
    return this.appService.get(this.globalConstants.GET_POLICY_MCO, params);
  }

  changePassword(changePasswordRequest: ChangePHPasswordRequest) {
    // Tracker.log('IN Policy Holder Service changePassword()');
    let bwOktaEnable : boolean = environment.bwOktaEnabled;
    return this.appService.post(bwOktaEnable
      ?this.globalConstants.CHANGE_PH_PASSWORD_OKTA
      :this.globalConstants.CHANGE_PH_PASSWORD, 
      changePasswordRequest);
  }

  resetPassword(resetPHPasswordRequest: ResetPHPasswordRequest) {
    // Tracker.log('IN Policy Holder Service resetPassword()');
    return this.appService.post(this.globalConstants.RESET_PH_PASSWORD, resetPHPasswordRequest);
  }

  updatePH(updatePHRequest: UpdatePHRequest) {
    // Tracker.log('IN Policy Holder Service updatePH()');
    return this.appService.post(this.bwoktaEnabled ? this.globalConstants.UPDATE_PH_OKTA : this.globalConstants.UPDATE_PH, updatePHRequest);
  }

  // MFA
  getMFADate(symbol: string, policy: string, mod: string, mco: string): Observable<any> {
    const params = new HttpParams()
      .set('policyNumber', symbol.concat(policy).concat(mod))
      .set('mco', mco);
    return this.appService.get(this.globalConstants.PLCY_MGMT_GET_MFA_DATE, params);
  }

  updateMFADate(symbol: string, policy: string, mod: string, mco: string, date: string): Observable<Mfares> {
    const mfareq = new Mfareq();
    mfareq.mco = mco;
    mfareq.mfadate = date;
    mfareq.mod = mod;
    mfareq.policy = policy;
    mfareq.symbol = symbol;

      return this.appService.post(this.globalConstants.PLCY_MGMT_UPDATE_MFA_DATE, mfareq);
  }

  checkEachAnswer(checkEachAnswerRequest: CheckEachAnswerRequest) {
    // Tracker.log('IN Policy Holder Service checkEachAnswer()');
    return this.appService.post(this.globalConstants.CHECK_EACH_ANSWER, checkEachAnswerRequest);
  }

  // get future Payments
  getFuturePayments(symbol: string, policy: string, mod: string, mco: string): Observable<any> {
    const params = new HttpParams()
      .set('policyNumber', symbol.concat(policy).concat(mod))
      .set('mco', mco);
    return this.appService.get(this.globalConstants.PH_FUTURE_PAYMENTS, params);
  }

  getLienholdersByKey(searchTerm: string): Observable<Lienholdercmpy[]> {
    const params = new HttpParams()
      .set('searchTerm', searchTerm);

      if (!searchTerm.trim()) {
        // if not search term, return empty lienholder array.
        return of([]);
      }
    return this.appService.get(this.globalConstants.LIENHOLDER_SEARCH, params);
  }


  getPaymentusStatus(): Observable<any> {
    const params = new HttpParams();
    return this.appService.get(this.globalConstants.GET_POLICYHOLDERINFO_PAYMENTUS, params);
  }
}
