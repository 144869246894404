<div *ngIf="!this.isLoaded" class="my-5 text-center">
  <app-bw-spinner [smallSpinner]="false"></app-bw-spinner>
  </div>

<div *ngIf="this.isLoaded" class="modal-body">

  <form [formGroup]="addressEditForm">

  <div class="form-row">
    <div class="col-md-10">
      <div class="form-group">
        <label for="" tabindex="0">Address</label>
        <input tabindex="0" type="text" aria-describedby="address_desc" aria-required="true" [attr.aria-invalid]="getErrMessage(addressEditForm.controls['address'].errors, 'address')" class="form-control form-control-sm" formControlName="address" maxlength="30"/>
        <app-field-error-display [elemID]="address_desc"  role="alert" [displayError]="isFieldValid('address')" [errorMsg]="getErrMessage(addressEditForm.controls['address'].errors, 'address')"></app-field-error-display>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="col-md-10">
      <div class="form-group">
        <label for="" tabindex="0">City</label>
        <input tabindex="0" aria-describedby="city_desc" aria-required="true" [attr.aria-invalid]="getErrMessage(addressEditForm.controls['city'].errors, 'city')" type="text" class="form-control form-control-sm" formControlName="city"/>
        <app-field-error-display [elemID]="city_desc"  role="alert" [displayError]="isFieldValid('city')" [errorMsg]="getErrMessage(addressEditForm.controls['city'].errors, 'city')"></app-field-error-display>
      </div>
    </div>
  </div>


  <div class="form-row">
    <div class="col-md-10">
      <div class="form-group">
        <label tabindex="0" for="">State</label>
        <ng-template #state><div [innerHTML]="getToolTip('612')"></div></ng-template>
        <button tabindex="0" class="btn-icon pl-1" placement="right" #t3="ngbTooltip" [appTooltipClose]=t3 (click)="toggle(t3)" (blur)="t3.close()" [ngbTooltip]="state">
            <i class="text-icon fa-info-circle fas"></i>
            </button>
        <select tabindex="0" aria-describedby="state_desc" aria-required="true" [attr.aria-invalid]="getErrMessage(addressEditForm.controls['state'].errors, 'state')" class="form-control form-control-sm" formControlName="state" readonly>
          <option *ngFor="let statecode of stateCds" value="{{ statecode.stateCd }}">{{ statecode.stateDesc }}</option>
        </select>
        <app-field-error-display [elemID]="state_desc"  role="alert" [displayError]="isFieldValid('state')" [errorMsg]="getErrMessage(addressEditForm.controls['state'].errors, 'state')"></app-field-error-display>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="col-md-10">
      <div class="form-group">
        <label tabindex="0" for="">Zip</label>
        <input tabindex="0" aria-required="true" [attr.aria-invalid]="getErrMessage(addressEditForm.controls['zip'].errors, 'zip')"
        aria-describedby="zip_desc" [appNumbersonlyinput]="true" type="text" class="form-control form-control-sm" maxlength="5" formControlName="zip"/>
        <app-field-error-display [elemID]="zip_desc"  role="alert" [displayError]="isFieldValid('zip')" [errorMsg]="getErrMessage(addressEditForm.controls['zip'].errors, 'zip')"></app-field-error-display>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="col-md-10">
      <div class="form-group">
        <label tabindex="0" for="">Phone Number</label>
      <!--  <app-mobile-number-input  [formSubmitAttempt]="submitAddressEndorsement"
         [form]="addressEditForm" [validate]="false" [controlName]="phoneNumber" [count]="count1" 
        [phoneValue]="authenticatedPhDetails.phone!=undefined?authenticatedPhDetails.phone:''"></app-mobile-number-input> -->
        <input tabindex="0" aria-required="true" [attr.aria-invalid]="getMobileNumErrMsg(addressEditForm.controls['phoneNumber'].errors)"
        aria-describedby="phone_desc" appMobileNumberinput type="text" class="form-control form-control-lg" formControlName="phoneNumber" maxlength="12"/>
        <app-field-error-display [elemID]="phone_desc"  role="alert" [displayError]="isFieldValid('phoneNumber')" [errorMsg]="getMobileNumErrMsg(addressEditForm.controls['phoneNumber'].errors)"></app-field-error-display>
      </div>
    </div>
  </div>

  

  <div class="form-row">
      <div class="col-md-11">
        <div class="form-group">
          <label tabindex="0">Will all of your vehicles be kept at this mailing address?</label><br/>
          <div class="form-check-inline mt-1">
              <label tabindex="0" class="form-check-label">
                <input aria-required="true" [attr.aria-invalid]="getErrMessage(addressEditForm.controls['isVehicleInThisLocation'].errors, 'isVehicleInThisLocation')"
                aria-describedby="mailing_addr_desc" type="radio" class="form-check-input" value="yes" name="isVehicleInThisLocation" formControlName="isVehicleInThisLocation">Yes
              </label>
            </div>
            <div class="form-check-inline">
              <label tabindex="0" class="form-check-label">
                <input aria-required="true" [attr.aria-invalid]="getErrMessage(addressEditForm.controls['isVehicleInThisLocation'].errors, 'isVehicleInThisLocation')"
                aria-describedby="mailing_addr_desc" type="radio" class="form-check-input" value="no" name="isVehicleInThisLocation"   (change)="onChange()" formControlName="isVehicleInThisLocation">No
              </label>
            </div>
      <app-field-error-display [elemID]="mailing_addr_desc"  role="alert" [displayError]="isFieldValid('isVehicleInThisLocation')" [errorMsg]="getErrMessage(addressEditForm.controls['isVehicleInThisLocation'].errors, 'isVehicleInThisLocation')"></app-field-error-display>
        </div>
      </div>
    </div>

 </form>

</div>

<div class="modal-footer mt-1">
 
  <button tabindex="0" *ngIf="!submitted" type="button" class="btn btn-secondary" (click)="addressEditModalClose(addressEditModal,'Submit')" [disabled]="!this.isLoaded">Cancel</button>
  <button tabindex="0" *ngIf="submitted" type="button" class="btn btn-secondary" (click)="addressEditModal.dismiss('Submit')">Close</button>
  <button tabindex="0" *ngIf="!submitted" type="submit" class="btn btn-primary" (click)="submitAddressEndorsement(addressEditForm.value)" [disabled]="isSubmitButtonDisable || !this.isLoaded">Submit</button>
</div>