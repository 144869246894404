
import {throwError,  Observable } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';


@Injectable()
export class BWTokenInterceptor implements HttpInterceptor {

    constructor(private _authService: AuthenticationService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Prepare Header config
        const headerConfig = {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept' : 'application/json; charset=utf-8'
        };

        // Get the Token
        const bwtoken = this._authService._bwToken();

        if (bwtoken !== undefined && bwtoken !== null && bwtoken !== '') {
          req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + bwtoken) });
        }

        if (!req.headers.has('Content-Type') && req.url.indexOf('saveDocument') === -1) {
          req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
        }

        req = req.clone({ headers: req.headers.set('Accept', 'application/json') , withCredentials: true});
                                        // withCredentials added to pass the Cookie to server part of Tealeaf Integration

        return next.handle(req)
           .pipe(catchError(error => {
                return throwError(error);
            }));
    }
}
