
    <div class="modal-header" >
      <h4 class="modal-title" id="request-to-cancel-confirmation">Cancel Change Requested</h4>
    </div>

    <div class="modal-body">
      <p>The change being requested will be cancelled and not saved. Do you still want to navigate away from this page?</p>
    </div>

    <div class="modal-footer mt-1">
      <button type="button" class="btn btn-secondary" (click)="close ()">Cancel</button>
      <button *ngIf="!inProgress" type="submit" class="btn btn-primary" (click)="confirmChanges()">Continue</button>
      <button *ngIf="inProgress" type="submit" class="btn btn-primary" disabled="true">
        <i class="fas fa-circle-notch fa-spin"></i>&nbsp;Continue
      </button>
    </div>
  