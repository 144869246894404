/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@angular/common";
import * as i3 from "../policy-number-input/policy-number-input.component.ngfactory";
import * as i4 from "../policy-number-input/policy-number-input.component";
import * as i5 from "./forgot-userid.component";
import * as i6 from "../../../services/login/login.service";
import * as i7 from "@angular/router";
import * as i8 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
var styles_ForgotUseridComponent = [];
var RenderType_ForgotUseridComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ForgotUseridComponent, data: {} });
export { RenderType_ForgotUseridComponent as RenderType_ForgotUseridComponent };
function View_ForgotUseridComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "alert alert-danger alert-dismissible fade show"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "text-danger"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fgusidErrorMsg; _ck(_v, 3, 0, currVal_0); }); }
export function View_ForgotUseridComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 22, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ɵangular_packages_forms_forms_bg, [], null, null), i0.ɵdid(2, 540672, null, 0, i1.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.FormGroupDirective]), i0.ɵdid(4, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "h5", [["class", "modal-title heading-4"], ["id", "modal-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Forgot Email"])), (_l()(), i0.ɵeld(8, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["data-dismiss", "modal"], ["tabindex", "-1"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ForgotUseridComponent_1)), i0.ɵdid(12, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(13, 0, null, null, 4, "div", [["class", "modal-body p-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 1, "label", [["tabindex", "0"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["We will need your Policy Number in order to re-register you."])), (_l()(), i0.ɵeld(16, 0, null, null, 1, "app-policy-number-input", [], null, null, null, i3.View_PolicyNumberInputComponent_0, i3.RenderType_PolicyNumberInputComponent)), i0.ɵdid(17, 114688, null, 0, i4.PolicyNumberInputComponent, [], { policynum: [0, "policynum"], validate: [1, "validate"], formSubmitAttempt: [2, "formSubmitAttempt"], form: [3, "form"] }, null), (_l()(), i0.ɵeld(18, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 1, "button", [["class", "btn btn-secondary"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"])), (_l()(), i0.ɵeld(21, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resetUserID(_co.forgotUserIdForm) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Continue"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.forgotUserIdForm; _ck(_v, 2, 0, currVal_7); var currVal_8 = _co.errorForgetId; _ck(_v, 12, 0, currVal_8); var currVal_9 = _co.policynum; var currVal_10 = true; var currVal_11 = _co.forgotIdformSubmitAttempt; var currVal_12 = _co.forgotUserIdForm; _ck(_v, 17, 0, currVal_9, currVal_10, currVal_11, currVal_12); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 4).ngClassValid; var currVal_5 = i0.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_ForgotUseridComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-forgot-userid", [], null, null, null, View_ForgotUseridComponent_0, RenderType_ForgotUseridComponent)), i0.ɵdid(1, 114688, null, 0, i5.ForgotUseridComponent, [i6.LoginService, i7.Router, i8.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForgotUseridComponentNgFactory = i0.ɵccf("app-forgot-userid", i5.ForgotUseridComponent, View_ForgotUseridComponent_Host_0, {}, {}, []);
export { ForgotUseridComponentNgFactory as ForgotUseridComponentNgFactory };
