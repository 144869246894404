

        <form [formGroup]="forgotUserIdForm">
          <div class="modal-header">
            <h5 class="modal-title heading-4" id="modal-label">Forgot Email</h5>
            <button tabindex="-1" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div *ngIf="errorForgetId">
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
                <div class="text-danger">{{fgusidErrorMsg}}</div>
            </div>
          </div>
          <div class="modal-body p-5">
            <label tabindex="0">We will need your Policy Number in order to re-register you.</label>
              <app-policy-number-input [policynum]="policynum"
              [formSubmitAttempt]="forgotIdformSubmitAttempt" [form]="forgotUserIdForm" [validate]="true"></app-policy-number-input>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="dismiss()">Cancel</button>
            <button type="button" class="btn btn-primary" (click)="resetUserID(forgotUserIdForm)">Continue</button>
          </div>
        </form>

  