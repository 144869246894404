import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
var PaymentMethodFormComponent = /** @class */ (function () {
    function PaymentMethodFormComponent() {
        var _this = this;
        this.selectedPaymentMethod = new EventEmitter();
        this.editPaymentMethod = new EventEmitter();
        this.subscriptions = [];
        this.EVENT_NAME = 'message';
        this.PAYMENTUS_IO = 'paymentus';
        this.PM_DETAILS = "pmDetails";
        this.editedPaymentMethod = false;
        this.selected = false;
        this.paymentusEventNewPaymentMethod$ = fromEvent(window, this.EVENT_NAME).pipe(filter(function (event) {
            _this.editedPaymentMethod = false;
            return event.origin.includes(_this.PAYMENTUS_IO);
        }));
        this.onChange = function () {
            // do nothing
        };
        this.onTouched = function () {
            // do nothing
        };
    }
    PaymentMethodFormComponent.prototype.ngOnInit = function () {
        this.buildPaymentMethodsForm();
    };
    Object.defineProperty(PaymentMethodFormComponent.prototype, "value", {
        get: function () {
            return this.formGroup.value;
        },
        set: function (_value) {
            this.onTouched();
        },
        enumerable: true,
        configurable: true
    });
    PaymentMethodFormComponent.prototype.validate = function () {
        if (this.formGroup.value.method.accountNumber)
            this.methodSelected = this.formGroup.value.method.accountNumber;
        return this.formGroup.valid ? null : { expired: true };
    };
    PaymentMethodFormComponent.prototype.buildPaymentMethodsForm = function () {
        var _this = this;
        this.formGroup = new FormGroup({
            method: new FormControl(null, []),
        });
        this.subscriptions.push(this.formGroup.valueChanges.subscribe(function (value) {
            if (value)
                _this.selectedPaymentMethod.emit(value);
            _this.onChange(value);
            _this.onTouched();
        }));
    };
    PaymentMethodFormComponent.prototype.onRadioChange = function (event) {
        var _this = this;
        if (event.source.checked == true) {
            this.paymentMethods.forEach(function (method) {
                if (method.paymentToken == event.value.paymentToken) {
                    _this.selectedPayment = event.value;
                    _this.selected = true;
                }
            });
        }
    };
    PaymentMethodFormComponent.prototype.addEditPaymentMethod = function (paymentusIframePaymentType, paymentToken, mode) {
        var _this = this;
        this.newPaymentMethodSubscription = this.paymentusEventNewPaymentMethod$
            .pipe(filter(function (event) {
            var eventData = JSON.stringify(event.data);
            return eventData.includes(_this.PM_DETAILS);
        })).subscribe(function (event) {
            if (!event)
                _this.editedPaymentMethod = false;
            if (event) {
                _this.editedPaymentMethod = true;
                var pmDetailsObjectString = event.data.substring(_this.PM_DETAILS.length + event.data.indexOf(_this.PM_DETAILS) + 1);
                var pmDetailsObject = JSON.parse(pmDetailsObjectString);
                _this.tokenCopy = pmDetailsObject.Token;
            }
        });
        this.paymentMethods.forEach(function (paymentMethod) {
            paymentMethod.isUpdated = paymentMethod.paymentToken === _this.tokenCopy;
        });
        if (this.formGroup.value && this.formGroup.value.method && (this.formGroup.value.method.paymentToken == paymentToken)) {
            this.editPaymentMethod.emit({ paymentusIframePaymentType: paymentusIframePaymentType, paymentToken: paymentToken, mode: mode, selected: this.selected });
        }
        else
            this.editPaymentMethod.emit({ paymentusIframePaymentType: paymentusIframePaymentType, paymentToken: paymentToken, mode: mode });
    };
    PaymentMethodFormComponent.prototype.ngOnDestroy = function () {
        if (this.newPaymentMethodSubscription)
            this.newPaymentMethodSubscription.unsubscribe();
    };
    return PaymentMethodFormComponent;
}());
export { PaymentMethodFormComponent };
