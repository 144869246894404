import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { GlobalConstants } from '../../../constants/global.constant';
import { MessageConstants } from '../../../constants/message.constant';
import { EndorsementService } from '../../../services/api/endorsement/endorsement.service';
import { EndorsementReq } from '../../model/api/endorsement/endorsement-req';
import { Directsalesvendor } from '../../model/api/policymgmt/directsalesvendor';
import { Statebyzipres } from '../../model/api/policymgmt/statebyzipres';
import { DirectsalesParameters } from '../../model/directsales-parameters';
import { GoogleAnalyticsService } from '../../services/analytics/google-analytics.service';
import { DirectsalesService } from '../../services/directsales.service';
import { MessagesService } from '../../services/messages.service';
import { CommonUtil } from '../../utils/commonutil';
import { Tracker } from '../../utils/tracker';

declare function iFrameResize({ }, string): any;

@Component({
  selector: 'app-directsales',
  template:
    `
    <div *ngIf="showBdq" class="container">
      <div class="row d-flex mb-3 mt-2">
        <div class="col-md-12 col-sm-12" id="startquotepnl">
          <!-- <div class="small" *ngIf="this.errorMessage !== ''">{{ this.errorMessage }}</div> -->
            <div class="d-inline-flex btn-more-focus">
              <h1 [ngClass]="fromPage !='autoIns101' ? 'look-feel-heading-1': 'look-feel-heading-3'" class="color-white tab-title mt-3">
                Bristol West works to make Auto Insurance easy
              </h1>
            </div>
            <form [formGroup]="directSalesForm">
              <div class="mb-2 d-none">
                <select class="form-control form-control-sm" formControlName="lob">
                  <option value="APV">Auto</option>
                  <!-- <option value="Home">Home</option> -->
                  <!-- <option value="Fire">Fire</option> -->
                  <!-- <option value="Motorcycle">Motorcycle</option> -->
                </select>
              </div>
              <div class="row mb-2">
                <div class="col-md-12 col-sm-12">
                  <label class="label-white">Get a Car Insurance quote today</label>
                </div>
              </div>
            <div *ngIf="fromPage!='autoIns101'">
              <div class="row word-wrap justify-content-center">
                <div class="col-md-12 col-sm-12">
                    <input style="width:11rem" id="zipCode" [appNumbersonlyinput]="true" type="tel" formControlName="zipcode"
                    class="form-control" maxlength="5" minlength="5"
                    placeholder="Enter your Zip Code" aria-label="Zip Code" />
                </div>
              </div>
              <div class="row d-flex justify-content-center mt-1">
                <div class="col-md-12 col-sm-12"><span>
                  <app-field-error-display [displayError]="isFieldValid('zipcode')"
                  [errorMsg]="getZipCodeErrMsg(directSalesForm.controls['zipcode'].errors)"></app-field-error-display></span>
                </div>
              </div>
              <div class="row mt-4 mb-1">
                <div class="col-md-12 col-sm-12">
                <button class="btn btn-lg orange-startQuote-button" type="submit" (click)="onSubmit(directSalesForm.value, 'startquote')">
                  Start Quote
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="fromPage=='autoIns101'" class="row">
                <div class="col-md-2"></div>
                <div class="col-md-4 aln-right">
                    <input style="width:11rem;margin-bottom: 2px;" id="zipCode" [appNumbersonlyinput]="true" type="tel" formControlName="zipcode"
                    class="form-control zip-code" maxlength="5" minlength="5"
                    placeholder="Enter your Zip Code" aria-label="Zip Code" />
                    <div class="aln-right zip-err">
                      <app-field-error-display [displayError]="isFieldValid('zipcode')"
                      [errorMsg]="getZipCodeErrMsg(directSalesForm.controls['zipcode'].errors)"></app-field-error-display>
                    </div>
                </div>
                <div class="col-md-3">
                    <button class="btn btn-lg orange-startQuote-button" type="submit" (click)="onSubmit(directSalesForm.value, 'startquote')">
                    Start Quote
                    </button>
                </div>
                <div class="col-md-3"></div>
            </div>
            </form>
            <div class="d-inline-flex btn-more-focus">
            <a href="javascript:void(0)"
            (click)="onSubmit(directSalesForm.value, 'retrievequote')" target="_self" class="label-white-medium white-links">
            Retrieve a Saved Quote
            </a>
            </div>
          </div>
        </div>
    </div>
  `,
  styleUrls: ['./directsales.component.scss']
})
export class DirectsalesComponent implements OnInit {
  @Input()
  fromPage:string;

  directSalesForm: FormGroup;
  errorMessage = '';
  formSubmitAttempt: boolean;
  usermessage = MessageConstants;
  stateByZipRes: Statebyzipres;
  isITState: boolean;
  showBdq: boolean;
  
  retrieveQuoteURL: string;
  auditReq: EndorsementReq = new EndorsementReq();
  params: any;
  directsalesParameters: DirectsalesParameters;
  genderValues: Array<string> = ['MALE', 'FEMALE', 'F', 'M'];

  constructor(private http: HttpClient, private fb: FormBuilder,
    private router: Router, private route: ActivatedRoute,
    private _directSalesService: DirectsalesService, private msgService: MessagesService,
    private endorsementService: EndorsementService,
    private gaService: GoogleAnalyticsService, public datepipe: DatePipe) {
    this.createForm();

    // Logic to check if browser refresh
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.showBdq = event.url.includes('/externalRedirect') || event.url.includes('zip=') ? false : true;
      }
      if (event instanceof NavigationEnd) {
        this.showBdq = event.url.includes('/externalRedirect') || event.url.includes('zip=') ? false : true;
      }
    });
  }

  ngOnInit() {
    this.retrieveQuoteURL = `${environment.bdqRetrieveQuoteCenter}`;

    // Read the query parameters into the DirectsalesParameters object
    this.directsalesParameters = new DirectsalesParameters(this.route.snapshot.queryParams);

    Tracker.loginfo('directsalesComponent', 'onInit', '', 'Incoming BDQ parameters'
    + JSON.stringify(this.directsalesParameters));

    // Set any parameters that have non-alphanumneric values to a blank string
    this.cleanRequestParameters();

    if (this.directsalesParameters.zip) {
      this.directSalesForm = this.fb.group({
        'zipcode': [this.directsalesParameters.zip],
        'lob': ['APV']
      });
      this.onSubmit(this.directSalesForm.value, 'startquote');
    } else {
      this.showBdq = true;
      this.msgService.showbwcom.next(true);
    }

    this.msgService.showbwcom.subscribe((val) => {
      this.showBdq = val;
    });
  }

  createForm() {
    this.directSalesForm = this.fb.group({
      'zipcode': ['', Validators.compose([Validators.required])],
      'lob': ['APV', Validators.compose([Validators.required])]
    });
  }

  // TO DO - Review this method and modify according to retrievequote and startquote
  onSubmit(directSalesForm: any, featureFlag: any) {
    if (this.directsalesParameters.zip === '') {
      this.directsalesParameters.zip = directSalesForm.zipcode;
    }
    this.directsalesParameters.zip = (this.directsalesParameters.zip.length === 4 ?
      '0'.concat(this.directsalesParameters.zip) : this.directsalesParameters.zip);

    console.log('featureFlag-->' + featureFlag);
    // Log to Google analytics
    if (featureFlag === 'startquote') {
      this.gaService.trackEvent('BDQ', 'GetQuickQuoteScreen', 'ZipButtonClick', 200);
    } else {
      this.gaService.trackEvent('BDQ', 'RetrieveAQuoteScreen', 'ZipButtonClick', 200);
    }

    if(this.fromPage === "Home"){
      this.gaService.trackEvent('BDQ', 'HomeScreen', 'StartQuoteButtonFromHome', 200);
    }else if(this.fromPage === "autoIns101"){
      this.gaService.trackEvent('BDQ', 'AutoInsurance101Screen', 'StartQuoteButtonFromAutoInsurance101', 200);
    }

    this.formSubmitAttempt = true;

    if (this.directSalesForm.valid) {
      // 1. Check if it is a valid zip and get the state
      this._directSalesService.getStateByZipCode(directSalesForm.zipcode)
        .subscribe((res) => {
          this.stateByZipRes = res;
          if (this.stateByZipRes && this.stateByZipRes.States !== undefined && this.stateByZipRes.States !== '') {
            // Get state and look up URL and Phone Number
            // 2. Look up the URL and SOURCE from the DirectSalesService
            try {
              this._directSalesService.getSourceDtlsByState(directSalesForm.lob, this.stateByZipRes.States, directSalesForm.zipcode)
                .subscribe((resp: Directsalesvendor[]) => {

                  if (!CommonUtil.isEmpty(resp)) {
                    this._directSalesService.getSourceByState(directSalesForm.lob, this.stateByZipRes.States,
                      directSalesForm.zipcode, resp, this.directsalesParameters.id)
                      .subscribe((response: Directsalesvendor) => {
                        this.gaService.gtag_report_conversion();

                        // Handles routing of request
                        this.handleDirectSalesRequest(response[0], featureFlag);

                        if (response[0].source_ind !== 'BDQ' && response[0].source_ind !== 'MDA') {
                          this.auditLogging(response[0].state, this.auditReq.activityResult, response[0].source_ind,
                            directSalesForm.zipcode);
                        }
                      }, error => {
                        this.auditLogging(this.stateByZipRes.States, 'F', 'Error occured- Vendor Table source', directSalesForm.zipcode);

                        this.errorMessage = MessageConstants.UNABLE_TO_QUOTE;
                        Tracker.logerror('DirectsalesComponent', 'onSubmit', 'getSourceByState',
                          'Error searching for zip ' + directSalesForm.zipcode, error);
                      });
                  } else {
                    this.auditLogging('XX', 'F', 'Response empty-Directsalesvendor data', directSalesForm.zipcode);
                    this.router.navigate(['sorry']);
                  }
                }, error => {
                  this.auditLogging('XX', 'F', 'Error occured-Directsalesvendor data', directSalesForm.zipcode);
                  this.errorMessage = MessageConstants.UNABLE_TO_QUOTE;
                  Tracker.logerror('DirectsalesComponent', 'onSubmit', 'getSourceByState',
                    'Error searching for zip ' + directSalesForm.zipcode, error);
                });
            } catch (e) {
              this.auditLogging('XX', 'F', 'Error occured- after State retrieval', directSalesForm.zipcode);
              Tracker.logerror('DirectsalesComponent', 'onSubmit', 'getSourceByState',
                'Error searching for zip ' + directSalesForm.zipcode, e);
            }
          } else {
            this.auditLogging('XX', 'F', 'State is empty', directSalesForm.zipcode);
            this.errorMessage = MessageConstants.UNABLE_TO_QUOTE;
            this.msgService.showbwcom.next(true);
          }
        }, error => {
          this.auditLogging('XX', 'F', 'Error- Get state by zipcode', directSalesForm.zipcode);
          this.errorMessage = MessageConstants.UNABLE_TO_QUOTE;
          Tracker.logerror('DirectsalesComponent', 'onSubmit', 'getStateByZipCode',
            'Error retrieving state by zip code ' + directSalesForm.zipcode, error);
          this.msgService.showbwcom.next(true);
        });
    } else {
      this.auditLogging('XX', 'F', 'Direct Sales form is empty', directSalesForm.zipcode);
      this.errorMessage = 'Please enter a valid zip code';
      this.msgService.showbwcom.next(true);
    }
    setTimeout(() => {
      (document.getElementById("startquotepnl").querySelector('[id=elemID]') as HTMLElement).focus();
    });
  }

  handleDirectSalesRequest(direct_sales_vendor: Directsalesvendor, feature_flag: string) {
    if (direct_sales_vendor.source === 'INTERNAL') {
      if (direct_sales_vendor.source_ind === 'ITC') {
        // when ITC , then launch the ITC screen in IFrame
        this.auditReq.activityResult = 'S';
        this.router.navigate(['quote']);
      } else if (direct_sales_vendor.source_ind === 'DSA') {
        // When 'DSA' , then show Sorry screen
        this.auditReq.activityResult = 'F';
        this.router.navigate(['directsalescall']);
      } else if (direct_sales_vendor.source_ind === 'MDA') {
        // When 'MDA' then launch Sorry page or MediaAlpha with in BW.com
        this.auditReq.activityResult = 'F';
        Tracker.loginfo('directsalesComponent', 'onSubmit', 'getSourceByState',
          'Media Alpha Zip Code' + this.directsalesParameters.zip);
        this.auditLogging(direct_sales_vendor.state, this.auditReq.activityResult, direct_sales_vendor.source_ind,
          this.directsalesParameters.zip);
        window.open('../../../assets/html/media-alpha.html?zip=' + this.directsalesParameters.zip, '_self');
      }
    } else if (direct_sales_vendor.source === 'EXTERNAL' && direct_sales_vendor.source_ind === 'BDQ'
      && feature_flag === 'startquote') {
      this.auditReq.activityResult = 'S';
      this.params = new HttpParams().set('zip', this.directsalesParameters.zip).set('link', direct_sales_vendor.url);

      if (this.directsalesParameters.dob) {
        const datePipe = new DatePipe('en-US');
        const matches = this.directsalesParameters.dob.match(/\d+/g);
        const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (!format.test(this.directsalesParameters.dob)) {
          const year = this.directsalesParameters.dob.substring(4, 8); // 0,4
          const month = this.directsalesParameters.dob.substring(0, 2); // 4,6
          const day = this.directsalesParameters.dob.substring(2, 4); // 6,8
          this.directsalesParameters.dob = month + '/' + day + '/' + year;
        } else {
          // replaces all occurances of "-" with "/"
          this.directsalesParameters.dob = this.directsalesParameters.dob.replace(/-/g, '/');
        }
        let formattedDOB;
        try {
          formattedDOB = datePipe.transform(new Date(this.directsalesParameters.dob), 'MM/dd/yyyy', 'EST', 'en-US');
        } catch (e) {
          formattedDOB = '';
          Tracker.logerror('DirectsalesComponent', 'onSubmit', 'getSourceByState',
          'Error invalid date for date of birth ' + this.directsalesParameters.dob, '');
        } finally {
          this.params = this.params.set('dob', formattedDOB);
        }
      }
      if (this.directsalesParameters) {
        this.params = this.params.set('id', this.directsalesParameters.id);
        this.params = this.params.set('emailID', this.directsalesParameters.emailid);
        this.params = this.params.set('firstName', this.directsalesParameters.firstname);
        this.params = this.params.set('lastName', this.directsalesParameters.lastname);
        this.params = this.params.set('phNo', this.directsalesParameters.phoneno);
        this.params = this.params.set('dsplyPhn', this.directsalesParameters.dsplyPhn);
        this.params = this.params.set('ppcSrc', this.directsalesParameters.ppcsrc);
        this.params = this.params.set('streetAddress', this.directsalesParameters.streetaddress);
        this.params = this.params.set('streetApt', this.directsalesParameters.streetapt);
        this.params = this.params.set('streetCity', this.directsalesParameters.streetcity);
        if (this.directsalesParameters.gender
          && this.genderValues.indexOf(this.directsalesParameters.gender.toLocaleUpperCase()) !== -1) {
          this.directsalesParameters.gender = this.directsalesParameters.gender.charAt(0);
          this.params = this.params.set('gender', this.directsalesParameters.gender);
        }
      }
      this.auditLogging(direct_sales_vendor.state, this.auditReq.activityResult, direct_sales_vendor.source_ind,
      this.directsalesParameters.zip);
    } else if (direct_sales_vendor.source === 'EXTERNAL' && direct_sales_vendor.source_ind === 'BDQ' &&
      feature_flag === 'retrievequote') {
      this.auditReq.activityResult = 'S';

      this.auditLogging(direct_sales_vendor.state, this.auditReq.activityResult, direct_sales_vendor.source_ind + '-' + feature_flag,
      this.directsalesParameters.zip);
      // To DO - Append zip code here
      const URL = this.retrieveQuoteURL + '?Zip_Code=' + this.directsalesParameters.zip;
      window.open(URL, '_self');
    } else {
      this.auditReq.activityResult = 'F';
      const url = this.directsalesParameters.zip !== null ? 'home/?zip=' + this.directsalesParameters.zip : 'home';
      this.msgService.showbwcom.next(false);
      this.router.navigateByUrl(url, { skipLocationChange: true }).then(() =>
        this.router.navigate(['/externalRedirect', { externalUrl: url }]));
    }
  }

  cleanRequestParameters() {
    if (this.directsalesParameters) {
      for (const prop in this.directsalesParameters) {
        if ( this.directsalesParameters.hasOwnProperty(prop) ) {
          // const regexNonAlphaNumeric = '^[/\W/g].*[/\W/g]$';
          // const regexNonAlphaNumeric = '[^a-zA-Z\d\s:]';
          if ( prop !== 'id' && this.directsalesParameters[prop].match(/[^a-zA-Z\d\s:]/)) {
            Tracker.logerror('DirectsalesComponent', 'onSubmit', 'cleanRequestParameters',
          'Invalid characters in parameter ' + prop + '=' + this.directsalesParameters[prop] +
          'for ' + this.directsalesParameters.id, '');
          // alert('Invalid characters in parameter ' + prop + '= \'' + this.directsalesParameters[prop] +
          // '\' for ' + this.directsalesParameters.id);
            if (prop === 'dob') {  // dob can have / and - symbols
              if (this.directsalesParameters[prop].match(/[^a-zA-Z\d\s-/:]/)) {
                this.directsalesParameters[prop] = '';
              }
            } else if (prop === 'emailid') {// dob can have / and - symbols
              if (this.directsalesParameters[prop].match(/[^a-zA-Z\d\s.@_:]/)) {
                this.directsalesParameters[prop] = '';
              }
            } else {
              this.directsalesParameters[prop] = '';
            }
          }
        }
      }
    }
  }

  // Form Utilities
  isFieldValid(field: string) {
    return (
      (!this.directSalesForm.get(field).valid && this.directSalesForm.get(field).touched) ||
      (this.directSalesForm.get(field).untouched && this.formSubmitAttempt)
    );
  }

  getZipCodeErrMsg(errors: any) {
    if (errors) {
      if (errors.required === true) {
        return this.usermessage.REQUIRED_ZIPCODE;
      } else if (!errors.minLength) {
        return this.usermessage.LENGTH_ZIPCODE;
      }
    }
  }

  onPhoneClick() {
    this.gaService.trackEvent('Clicking ph# on home page', 'HomeScreen', 'PhoneNumClick', 200);
      // window.location.href = 'tel:1-866-228-4622';
  }

  auditLogging(state: string, actRslt: string, actMsg: string, zip: string): Promise<string> {
    return new Promise((resolve, reject) => {
      Tracker.loginfo('directsalesComponent', 'auditLogging', 'auditLogging service',
        'direct sales audit entry-' + state.concat('-').concat(actRslt).concat('-').concat(actMsg).concat('-').concat(zip));
        if (this.directsalesParameters.id === null) {
          this.directsalesParameters.id = 'none';
        }
        if (this.directsalesParameters.id !== null) {
        // this._directSalesService.addMarketingIdAuditLogForBDQ(zip, state, this.marketingID);
        this.auditReq.symbol = GlobalConstants.AUDIT_LOG_MARKETING_ID_SYMBOL;
        this.auditReq.policyNum = GlobalConstants.AUDIT_LOG_MARKETING_ID_POLICY_NUMBER;
        this.auditReq.mod = GlobalConstants.AUDIT_LOG_MARKETING_ID_MODULE;
        this.auditReq.mco = GlobalConstants.AUDIT_LOG_MARKETING_ID_MASTER_COMPANY;
        this.auditReq.state = state;
        this.auditReq.activityResult = GlobalConstants.AUDIT_LOG_MARKETING_ID_ACTIVITY_RESULT;
        this.auditReq.activity = GlobalConstants.AUDIT_LOG_MARKETING_ID_ACTIVITY;
        this.auditReq.activityMessage = this.directsalesParameters.id;
        this.auditReq.binderNumber = zip;
        this.endorsementService.createAuditLogRecord(this.auditReq).subscribe();
      }
      this.auditReq.symbol = GlobalConstants.AUDIT_LOG_REQ_SYMBOL;
      this.auditReq.policyNum = GlobalConstants.AUDIT_LOG_REQ_POLICYNUM;
      this.auditReq.mod = GlobalConstants.AUDIT_LOG_REQ_MOD;
      this.auditReq.mco = GlobalConstants.AUDIT_LOG__REQ_MCO;
      this.auditReq.activity = GlobalConstants.AUDIT_LOG_REQ_SYMBOL;
      this.auditReq.state = state;
      this.auditReq.activityResult = actRslt;
      this.auditReq.activityMessage = actMsg;
      this.auditReq.binderNumber = zip;
      this.endorsementService.createAuditLogRecord(this.auditReq).subscribe(
        (res) => {
          if (res.status === 0) {
            if (actMsg === 'BDQ') {
            const URL = '../../../assets/html/bdq-quote.html' + '?' + this.params.toString();
            Tracker.loginfo('directsalesComponent', 'onSubmit', '', 'BDQ URL from BWC:' + URL);
            window.open(URL, '_self');
            }
            resolve(res);
          } else {
            reject(res);
          }
        }, (err) => {
          reject(err);
        });
    });
  }
}