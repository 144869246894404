import { AbstractControl, FormControl } from '@angular/forms';
import { CommonUtil } from '../utils/commonutil';

export class EmailValidator {

    static validateEmail(control: FormControl): { [key: string]: any } {
        // tslint:disable-next-line:max-line-length
        const emailPattern =  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        // Tracker.log('Validator Input Value: ', control.value);
        if (!CommonUtil.lrtrim(control.value).match(emailPattern)) {
            return { 'email': true };
        }
        return null;
     }

     static MatchUserId(AC: AbstractControl) {
       const group = AC.parent;
       if (group) {
        const userId = group.controls['phuserid'].value;
        const confirmUserId = group.controls['phconfirmeduserid'].value;
        // Tracker.log("EmailValidator Validator : "+userId+"  and "+confirmUserId);

        if (userId && confirmUserId && userId.toUpperCase() !== confirmUserId.toUpperCase()) {
            // Tracker.log('false');
            return {'confirmemail': true};
            // group.controls['phconfirmeduserid'].setErrors( {MatchUserId: true} )
        } else {
            // Tracker.log('true');
            return null;
        }
       }
       return null;
    }
}
