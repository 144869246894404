export class RTAFormreq {
    mco: string;
    sym: string;
    polnum: string;
    mod: string;
    vin: string;
    appName: string;
    libraryContext: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
