import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { GlobalConstants } from '../../../constants/global.constant';
import { ChangeuseremailreqInStaging } from '../../../shared/model/api/bwsecurity/changeuseremailreqinstaging';
import { Policynumber } from '../../../shared/model/policynumber';
import { Changeuseremailreq } from '../../../shared/model/registration/changeuseremailreq';
import { Newuserssoreq } from '../../../shared/model/registration/newuserssoreq';
import { BwstoreService } from '../../../shared/services/bwstore.service';
import { BwappService } from '../../bwapp.service';
import { BWErrorhandler } from '../../errorhandler';
import * as i0 from "@angular/core";
import * as i1 from "../../bwapp.service";
import * as i2 from "../../../shared/services/bwstore.service";
import * as i3 from "../../errorhandler";
var BwSecurityService = /** @class */ (function () {
    function BwSecurityService(appService, storeService, errorHandler) {
        this.appService = appService;
        this.storeService = storeService;
        this.errorHandler = errorHandler;
        this.globlalConstants = GlobalConstants;
        this.bwOktaEnabled = environment.bwOktaEnabled;
        this._baseURL = GlobalConstants.baseURL;
    }
    /**
     *
     * @param changeuseremailreq
     */
    BwSecurityService.prototype.changeUserEmail_POINT = function (optout) {
        this.policyNumber = new Policynumber(this.storeService.newUser.policynumber);
        this.changeuseremailreq = new Changeuseremailreq();
        this.changeuseremailreq.szEmail = this.storeService.newUser.userid;
        this.changeuseremailreq.szEmailOptOut = optout;
        this.changeuseremailreq.szMCO = this.storeService.newUser.mco;
        this.changeuseremailreq.szPolicyNum = this.policyNumber.policy;
        this.changeuseremailreq.szSymbol = this.policyNumber.symbol;
        return this.appService.post(this.globlalConstants.BWSEC_CHANGE_EMAIL_URL, this.changeuseremailreq);
    };
    /**
     *
     * @param changeuseremailreq
     */
    BwSecurityService.prototype.changeUserEmail_POINT_GP = function (optout, email, bwPolNum, mco) {
        this.policyNumber = new Policynumber(bwPolNum);
        this.changeuseremailreq = new Changeuseremailreq();
        this.changeuseremailreq.szEmail = email;
        this.changeuseremailreq.szEmailOptOut = optout;
        this.changeuseremailreq.szMCO = mco;
        this.changeuseremailreq.szPolicyNum = this.policyNumber.policy;
        this.changeuseremailreq.szSymbol = this.policyNumber.symbol;
        return this.appService.post(this.globlalConstants.BWSEC_CHANGE_EMAIL_URL, this.changeuseremailreq);
    };
    /**
    *
    * @param changeuseremailreqinstaging
    */
    BwSecurityService.prototype.changeUserEmail_POINT_IN_STAGING = function (optout, email, bwPolNum, mco) {
        this.policyNumber = new Policynumber(bwPolNum);
        this.changeuseremailreqinstaging = new ChangeuseremailreqInStaging();
        this.changeuseremailreqinstaging.szEmail = email;
        this.changeuseremailreqinstaging.szEmailOptOut = optout;
        this.changeuseremailreqinstaging.szMCO = mco;
        this.changeuseremailreqinstaging.szPolicyNum = this.policyNumber.policy;
        this.changeuseremailreqinstaging.module = this.policyNumber.module;
        this.changeuseremailreqinstaging.szSymbol = this.policyNumber.symbol;
        return this.appService.post(this.globlalConstants.BWSEC_CHANGE_EMAIL_IN_STAGING_URL, this.changeuseremailreqinstaging);
    };
    /**
     *
     * @param policynum
     */
    BwSecurityService.prototype.getPolicyLocationStatusandMaxMod = function (policynum) {
        this.policyNumber = new Policynumber(policynum);
        // Tracker.log('BWSecurity Service - getPolicyLocationStatusandMaxMod() invoked with policy :::', policynum, this.policyNumber);
        var params = new HttpParams()
            .set('strPolicySym', this.policyNumber.symbol)
            .set('strPolicyNum', this.policyNumber.policy);
        return this.appService.get(this.globlalConstants.BWSEC_GETPOLICYLOCATIONSTATUSANDMAXMOD, params);
    };
    BwSecurityService.prototype.addNewUserSSO = function () {
        var bwOktaEnabled = environment.bwOktaEnabled;
        this.policyNumber = new Policynumber(this.storeService.newUser.policynumber);
        this.newuserssoreq = new Newuserssoreq();
        this.newuserssoreq.szAnswer1 = this.storeService.newUser.secans1;
        this.newuserssoreq.szAnswer2 = this.storeService.newUser.secans2;
        this.newuserssoreq.szAnswer3 = this.storeService.newUser.secans3;
        this.newuserssoreq.szDOB = this.storeService.newUser.phdob;
        this.newuserssoreq.szFirstName = this.storeService.newUser.phfirstname;
        this.newuserssoreq.szLastName = this.storeService.newUser.phlastname;
        this.newuserssoreq.szMCO = this.storeService.newUser.mco;
        this.newuserssoreq.szMOD = this.policyNumber.module;
        this.newuserssoreq.szPassword = this.storeService.newUser.password;
        this.newuserssoreq.szPIN = '';
        this.newuserssoreq.szPolicy = this.policyNumber.policy;
        this.newuserssoreq.szQuestion1 = this.storeService.newUser.secq1;
        this.newuserssoreq.szQuestion2 = this.storeService.newUser.secq2;
        this.newuserssoreq.szQuestion3 = this.storeService.newUser.secq3;
        this.newuserssoreq.szSymbol = this.policyNumber.symbol;
        this.newuserssoreq.szUsername = this.storeService.newUser.userid;
        this.newuserssoreq.szZIP = this.storeService.newUser.phzipcode;
        // add new user and insert ldap record
        return this.appService
            .post(bwOktaEnabled ? this.globlalConstants.BWSEC_ADD_USER_SSO_URL_OKTA : this.globlalConstants.BWSEC_ADD_USER_SSO_URL, this.newuserssoreq)
            .pipe(map(function (data) {
            // Tracker.log('BwSecurityService Service Response for addNewUserSSO: ', data);
            return data;
        }));
    };
    /**
     *
     * @param policynum
     * @param dob
     * @param zip
     */
    BwSecurityService.prototype.validateNewUserPOINT = function (policynum, dob, zip) {
        this.policyNumber = new Policynumber(policynum);
        // Tracker.log('BWSecurity Service - validateNewUserPOINT() invoked with policy :::', policynum, this.policyNumber);
        var params = new HttpParams()
            .set('szSymbol', this.policyNumber.symbol)
            .set('szPolicy', this.policyNumber.policy)
            .set('szDOB', dob)
            .set('szZIP', zip);
        return this.appService.get(this.globlalConstants.BWSEC_VALIDATENEWUSERPOINT, params);
    };
    BwSecurityService.prototype.getpolicyByEmail = function (email) {
        // Tracker.log('BWSecurity Service - getpolicyByEmail() invoked with email :::', this.storeService.newUser.userid);
        var params = new HttpParams().set('szEmail', email);
        return this.appService.get(this.globlalConstants.BWSEC_GET_POLICY_BY_EMAIL_URL, params);
    };
    BwSecurityService.prototype.checkRegistrationStatus = function (mco, policynum) {
        this.policyNumber = new Policynumber(policynum);
        var pointHookId = mco.concat(this.policyNumber.symbol).concat(this.policyNumber.policy);
        var isBWOktaEnabled = environment.bwOktaEnabled;
        var params = new HttpParams()
            .set('szPointHookID', pointHookId)
            .set(this.globlalConstants.BW_OKTA_ENABLED_PARAM_KEY, isBWOktaEnabled ? this.globlalConstants.BW_OKTA_ENABLED_PARAM_VALUE : 'false');
        return this.appService.get(this.globlalConstants.BWSEC_CHECK_REGISTRATION_STATUS, params);
    };
    BwSecurityService.ngInjectableDef = i0.defineInjectable({ factory: function BwSecurityService_Factory() { return new BwSecurityService(i0.inject(i1.BwappService), i0.inject(i2.BwstoreService), i0.inject(i3.BWErrorhandler)); }, token: BwSecurityService, providedIn: "root" });
    return BwSecurityService;
}());
export { BwSecurityService };
