<main class="main">
  <div class="container">
     <div *ngIf="!this.isLoaded" class="small text-center"> {{ this.loadingMessage }} </div>
     <div *ngIf="!this.isLoaded" class="my-5 text-center">
        <app-bw-spinner [smallSpinner]="false"></app-bw-spinner>
     </div>
     <div *ngIf="this.isLoaded">
        <!-- <app-paymentheader *ngIf="this.hasPayments && this.isServiceAvaiable && !this.isNoLogin && !isReinstatementAvailable()" [premium]="premium" [lastPaymentDate]="lastPaymentDate" [paymentAmount]="paymentAmount" [isReinstate]="false"[policystatus]="policystatusepc"></app-paymentheader>
        <app-paymentheader *ngIf="this.hasPayments && this.isServiceAvaiable && !this.isNoLogin && isReinstatementAvailable()" [premium]="premium" [reInstateDueDate]="authenticatedPhDetails.reInstateDueDate" [isReinstate]="true" [paymentAmount]="authenticatedPhDetails.reInstateDueamount" [policystatus]="policystatusepc"></app-paymentheader> -->
        <!-- not canelled not reinstate -->
        <app-paymentheader *ngIf=" this.isServiceAvaiable && !this.isNoLogin && !isReinstatementAvailable() && policyInfo.PolicyStatusEPC !== 'Cancelled'  && policyInfo.PolicyStatusEPC !== 'Pending Cancel'" [premium]="policyInfo.PolicyPremium" [lastPaymentDate]="basicPolicyDetails.paymentInfo.lastPayDate" [paymentAmount]="basicPolicyDetails.paymentInfo.lastPayAmount" [showLastDayToPay]="false" [policystatus]="policyInfo.PolicyStatusEPC"></app-paymentheader>
        <!-- canelled but not reinstate -->
        <app-paymentheader *ngIf=" this.isServiceAvaiable && !this.isNoLogin && !isReinstatementAvailable() && policyInfo.PolicyStatusEPC === 'Cancelled' && policyInfo.PolicyStatusEPC !== 'Pending Cancel'" [premium]="policyInfo.PolicyPremium" [lastPaymentDate]="lastPaymentDate" [paymentAmount]="paymentAmount" [showLastDayToPay]="false" [policystatus]="policyInfo.PolicyStatusEPC"></app-paymentheader>
        <!-- cancelled reinstate-->
        <app-paymentheader *ngIf=" this.isServiceAvaiable && !this.isNoLogin && isReinstatementAvailable()  && policyInfo.PolicyStatusEPC !== 'Pending Cancel'" [premium]="policyInfo.PolicyPremium" [reInstateDueDate]="authenticatedPhDetails.reInstateDueDate" [showLastDayToPay]="true" [paymentAmount]="authenticatedPhDetails.reInstateDueamount" [policystatus]="policyInfo.PolicyStatus"></app-paymentheader>
        <!--precancel-->
        <app-paymentheader *ngIf=" this.isServiceAvaiable && !this.isNoLogin && !isReinstatementAvailable() && policyInfo.PolicyStatusEPC === 'Pending Cancel'" [premium]="policyInfo.PolicyPremium" [reInstateDueDate]="authenticatedPhDetails.reInstateDueDate" [showLastDayToPay]="true" [paymentAmount]="authenticatedPhDetails.reInstateDueamount" [policystatus]="policyInfo.PolicyStatusEPC"></app-paymentheader>
        
        <app-breadcrumb></app-breadcrumb>
        <div class="card" *ngIf="newPaymentResponse  && (newPaymentResponse.transactionNotification || (newPaymentResponse.payment && newPaymentResponse.payment.status!=='ACCEPTED'))" style="margin-top: 0px; border-width: 2px;border-color: red;">
           <app-payment-error  [amount]="selectedPayment.amount" [transactionNotification]="newPaymentResponse.transactionNotification" [paymentResponse]="newPaymentResponse"></app-payment-error>
        </div>
         <form>
            <div class="row">
               <div class="col-lg-7">
                  <!-- <div class="card" *ngIf="(basicPolicyDetails.paymentInfo.minimumPayment && basicPolicyDetails.paymentInfo.minimumPayment !== '0' && basicPolicyDetails.paymentInfo.minimumPayment !== '0.00')||(basicPolicyDetails.paymentInfo.maximumPayment && basicPolicyDetails.paymentInfo.maximumPayment!=='0' && basicPolicyDetails.paymentInfo.maximumPayment !== '0.00') || ( authenticatedPhDetails.reInstateDueamount && authenticatedPhDetails.reInstateDueamount!=='0' && authenticatedPhDetails.reInstateDueamount!=='0.00') || ( policyInfo.Balance && policyInfo.Balance!=='0' && policyInfo.Balance!=='0.00' && !policyInfo.Balance.includes('-'))"> -->
                  <div class="card" *ngIf="showMakePayment()">
                     <div class="card-body p-0">
                        <h1 class="heading-3 m-0 px-3 py-2 border-bottom">Make a Payment</h1>
                        <p class="font-weight-bold m-0 px-3 py-2 border-bottom">
                           This information may not reflect scheduled payments or payments made in the last 24 hours.
                        </p>
                        <div class="px-3 py-2 border-bottom">
                           <app-payment-amount-section (data)="selectedPaymentItem($event)"
                              [paymentAmountDetails]="paymentAmountDetails"></app-payment-amount-section>
                        </div>
                        <div class="px-3 py-2">
                           <h1 class="heading-3 m-0 pb-2 mb-2 border-bottom">Payment Method</h1>
                           <app-payment-method-form [paymentMethods]="paymentMethods"
                           (selectedPaymentMethod)="getSelectedPaymentMethod($event)"
                           (editPaymentMethod)="editPaymentMethod($event)"></app-payment-method-form>
                           <button class="d-block font-weight-bold" role="button" aria-label="add payment method"
                              (click)="addEditPaymentMethod(null, null, 'Add')">
                              <span class="add-button-text-container d-flex align-items-center ml-3">
                                 <mat-icon color="primary">add</mat-icon>
                                 <span class="add-button-text">ADD PAYMENT METHOD</span>
                              </span>
                           </button>
                        </div>
                     </div>
                  </div>
                  <!-- <div class="card" *ngIf=" (!basicPolicyDetails.paymentInfo.minimumPayment || basicPolicyDetails.paymentInfo.minimumPayment === '0.00' || basicPolicyDetails.paymentInfo.minimumPayment==='0') && (!basicPolicyDetails.paymentInfo.maximumPayment || basicPolicyDetails.paymentInfo.maximumPayment === '0.00' || basicPolicyDetails.paymentInfo.maximumPayment === '0')  && (!authenticatedPhDetails.reInstateDueamount || authenticatedPhDetails.reInstateDueamount==='0' || authenticatedPhDetails.reInstateDueamount==='0.00') && (!policyInfo.Balance || policyInfo.Balance==='0' || policyInfo.Balance ==='0.00' || policyInfo.Balance.includes('-') )" > -->
                     <div class="card" *ngIf="!showMakePayment()" >   
                     <div class="card-body p-0">
                        <h1 class="heading-3 m-0 px-3 py-2 border-bottom">Make a Payment</h1>
                        <!-- border-width: 2px;border-color: red;  -->
                        <div class="card" style="margin: 10px;padding: 5px;border-radius: 10px;   background-color: rgb(211, 178, 178);">

                           <p class=" m-0 px-3 py-2 ">
                              <!-- <i class="fas fa-exclamation"></i>&nbsp;  -->
                              Your account has no balance due. No payment is due at this time.
                           </p>
                           
                        </div>
                        <!-- <div style="margin: 10px;padding: 5px;">
                           <button class="btn btn-lg btn-secondary mt-2" (click)="routeToMyPolicy()" >BACK</button>
                        </div> -->
                        <!-- <div class="px-3 py-2 border-bottom">
                           <app-payment-amount-section (data)="selectedPaymentItem($event)" [paymentAmountDetails]="paymentAmountDetails"></app-payment-amount-section>
                        </div>
                        <div class="px-3 py-2">
                           <h1 class="heading-3 m-0 pb-2 mb-2 border-bottom">Payment Method</h1>
                           <app-payment-method-form [paymentMethods]="paymentMethods" (selectedPaymentMethod)="getSelectedPaymentMethod($event)"></app-payment-method-form>
                           <button class="d-block font-weight-bold" role="button" aria-label="add payment method"
                              (click)="addEditPaymentMethod(null, null, 'Add')">
                              <span class="add-button-text-container d-flex align-items-center ml-3">
                                 <mat-icon color="primary">add</mat-icon>
                                 <span class="add-button-text">ADD PAYMENT METHOD</span>
                              </span>
                           </button>
                        </div> -->
                     </div>
                  </div>
               </div>
               <div class="col-lg-5">
                  <app-billing-details [billingDetails]="billingDetails"></app-billing-details>
               </div>
            </div>
            <div class="form-row m-0 pb-2 mb-2">
               <div class="col-md-3">
                  <button type="button" role="button" class="btn btn-lg btn-secondary mt-2" routerLink="/"
                     routerLinkActive="active">Cancel</button>
               </div>
               <div id="disable" class="col-md-3" *ngIf="!selectedPayment.validated || selectedPayment.amount==='' || selectedPayment.amount.startsWith('-') || !selectedPaymentMethodNew || !selectedPaymentMethodNew.method.paymentToken ">
                  <button type="button" id="finalButton" disabled="true"  class="btn btn-lg btn-primary mr-2 mt-2" >
                  <i class="fa fa-lock fa-lg"></i>&nbsp;<span id="payment-button-amount font-size-14" *ngIf="!selectedPayment.amount.startsWith('-')">Pay {{ this.selectedPayment.amount | currency }}</span> <span *ngIf="selectedPayment.amount.startsWith('-')">Pay {{ '0.00' | currency }}</span>
                  </button>
               </div>
               <!-- <button hidden="true" #finalBtn id="hiddenBtn"(click)="hiddenMsg()">Hidden Button</button> -->
               <div id="active" class="col-md-3" *ngIf="(!selectedPayment.amount.startsWith('-') ) && selectedPayment.validated  && selectedPayment.amount!=='' && selectedPaymentMethodNew && selectedPaymentMethodNew.method.paymentToken " >
               <!-- <div class="col-md-3" *ngIf="selectedPayment.amount!=='' "> -->
                  <button type="button" id="finalButton" role="button" class="btn btn-lg btn-primary mr-2 mt-2" (click)="onSubmitNew(paymentForm.value, modalsubmitpayment)">
                  <i class="fa fa-lock fa-lg"></i>&nbsp;<span id="payment-button-amount font-size-14">Pay {{ this.selectedPayment.amount | currency }}</span>
                  </button>
               </div>
            </div>
         </form>
      </div>
   </div>
</main>

<!-- Submit CC Modal -->
<ng-template #modalsubmitpayment let-c="close" let-d="dismiss" ariaLabelledBy="Payment Confirmation">
   <div class="modal-header">
      <h4 class="modal-title"><strong>Payment Confirmation</strong></h4>
   </div>
   <div class="modal-body">
      <label>Please verify that this is the amount you are paying.</label>
      Payment Amount: <strong>{{ selectedPayment.amount | currency }}</strong><br />
      <!-- <div *ngIf="this.isCreditCard || this.isNewCC">
        Card Number: <strong>{{ this.newMoniker }}</strong><br/>
     </div> -->
      <!-- <div *ngIf=" this.isNewACHS && !this.isCreditCard && !this.isNewACH">
      Account Number: <strong>{{ this.newMoniker }}</strong>
    </div>
     <div *ngIf="!this.isCreditCard && this.isNewACH">
        Account Number: <strong>{{ this.newMoniker }}</strong>
     </div> -->

   </div>
   <div class="modal-footer">
      <button id="" type="button" class="btn btn-secondary" (click)="d('Close click')"
         aria-label="Cancel">Cancel</button>
      <button id="" type="button" class="btn btn-primary" (click)="c('Submit click')"
         aria-label="Proceed">Proceed</button>
   </div>
</ng-template>

<!-- <ng-template #modalconfirmpayment let-c="close" let-d="dismiss" ariaLabelledBy="Payment Confirmation">
   <div class="modal-header" align="center">
      <h4 class="modal-title"><strong>Payment Confirmation</strong></h4>
      <label>All payments processed online will be posted to your policy with 24 to 48 hours</label>
   </div>
   <div class="modal-body">
      <table>
         <tr>
            <td>
               Policy Number: 
            </td>
            <td>
               {{basicPolicyDetails.policyInfo.PolicyNumber}}
            </td>
         </tr>
         <tr>
            <td>
              Amount:
            </td>
            <td>
               {{newPaymentResponse.payment.amount|currency}}
            </td>
         </tr>
         <tr>
            <td>
              Date:
            </td>
            <td>
               {{newPaymentResponse.payment.createdDate | date : 'MM/dd/yyyy'}}
            </td>
         </tr>
         <tr>
            <td>
              Confirmation Number:
            </td>
            <td>
              {{newPaymentResponse.payment.referenceNumber}}
            </td>
         </tr>
      </table>
      
      
   </div>
   <div class="modal-footer">
      <button id="" type="button" class="btn btn-secondary" (click)="d('Close click')" aria-label="Cancel">Cancel</button>
      <button id="" type="button" class="btn btn-primary" (click)="c('Submit click')" aria-label="Proceed">Proceed</button>
   </div>
 </ng-template> -->