import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
var ErrorRedirectsComponent = /** @class */ (function () {
    function ErrorRedirectsComponent(errorModal) {
        this.errorModal = errorModal;
    }
    ErrorRedirectsComponent.prototype.ngOnInit = function () {
        // Tracker.log('Error Message in Child', this.errormsg);
    };
    return ErrorRedirectsComponent;
}());
export { ErrorRedirectsComponent };
