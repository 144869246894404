import { HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { GlobalConstants } from '../../../constants/global.constant';
import { BwstoreService } from '../../../shared/services/bwstore.service';
import { CommonUtil } from '../../../shared/utils/commonutil';
import { BwappService } from '../../bwapp.service';
import * as i0 from "@angular/core";
import * as i1 from "../../bwapp.service";
import * as i2 from "../../../shared/services/bwstore.service";
var DocusignService = /** @class */ (function () {
    function DocusignService(appService, storeService) {
        this.appService = appService;
        this.storeService = storeService;
        this.globlalConstants = GlobalConstants;
        if (!CommonUtil.isEmpty(this.storeService.getCurrentregistrationState())) {
            // Coming from Registration
            this.docusignResponseUrl = "" + environment.docusignRegistrationResponseUrl;
        }
        else {
            // Coming from Esig Messages
            this.docusignResponseUrl = "" + environment.docusignResponseUrl;
        }
    }
    // ceremonyReq.OnCancelUrl = String.Format('{0}?{1}={2}', ConfigurationManager.AppSettings['eSigMessage_URL_Cancel'],
    // eSigAction', HttpUtility.UrlEncode(Utilities.EncryptData(String.Format('{0};{1};{2}', 'Cancelled', envID, recID))));
    /*
    1. eSigMessage_URL_Cancel + '?' {0}
    2. 'eSigAction'                 {1}
    3. 'Cancelled,Declined,Exception,IDCheckFailed,SessionTimedOut,Complete,TTLExpired,ViewingComplete'  -- Encrypted then UrlEncoded
    4. envID        -- Encrypted then UrlEncoded
    5. recID        -- Encrypted then UrlEncoded
    
    */
    DocusignService.prototype.getCeremonyURL = function (envId, recId) {
        var params = new HttpParams()
            .set('envelopeID', envId)
            .set('onCancelUrl', this.buildDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_CANCELLED, envId, recId))
            .set('onDeclineUrl', this.buildDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_DECLINED, envId, recId))
            .set('onExceptionUrl', this.buildDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_EXCEPTION, envId, recId))
            .set('onIdCheckFailedUrl', this.buildDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_IDCHECK, envId, recId))
            .set('onSessionTimeOutUrl', this.buildDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_SESSION_TIMEOUT, envId, recId))
            .set('onSigningCompleteUrl', this.buildDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_COMPLETE, envId, recId))
            .set('onTTLExpiredUrl', this.buildDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_TTL_EXPIRED, envId, recId))
            .set('onViewingCompleteUrl', this.buildDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_VIEWING, envId, recId))
            .set('recipientID', recId);
        return this.appService.get(this.globlalConstants.ESIG_GETCEREMONY_URL, params);
    };
    DocusignService.prototype.getCeremonyURLEncrypted = function (encrptyedValues) {
        var params = new HttpParams()
            .set('encryptedIds', encrptyedValues)
            .set('onCancelUrl', this.buildEncryptedDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_CANCELLED))
            .set('onDeclineUrl', this.buildEncryptedDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_DECLINED))
            .set('onExceptionUrl', this.buildEncryptedDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_EXCEPTION))
            .set('onIdCheckFailedUrl', this.buildEncryptedDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_IDCHECK))
            .set('onSessionTimeOutUrl', this.buildEncryptedDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_SESSION_TIMEOUT))
            .set('onSigningCompleteUrl', this.buildEncryptedDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_COMPLETE))
            .set('onTTLExpiredUrl', this.buildEncryptedDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_TTL_EXPIRED))
            .set('onViewingCompleteUrl', this.buildEncryptedDocusignResponseURL(this.globlalConstants.DOCUSIGN_TYPE_VIEWING));
        return this.appService.get(this.globlalConstants.ESIG_GETCEREMONY_URL, params);
    };
    DocusignService.prototype.buildDocusignResponseURL = function (actionType, envId, recId) {
        return this.docusignResponseUrl.concat(this.globlalConstants.DOCUSIGN_PARAMETER).concat(actionType).concat('?p=' + envId + recId); // envId.concat(recId));
    };
    DocusignService.prototype.buildEncryptedDocusignResponseURL = function (actionType) {
        return this.docusignResponseUrl.concat(this.globlalConstants.DOCUSIGN_PARAMETER);
    };
    DocusignService.ngInjectableDef = i0.defineInjectable({ factory: function DocusignService_Factory() { return new DocusignService(i0.inject(i1.BwappService), i0.inject(i2.BwstoreService)); }, token: DocusignService, providedIn: "root" });
    return DocusignService;
}());
export { DocusignService };
