import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalConstants } from '../../constants/global.constant';
import { LeinholderInfoRes } from '../../shared/model/api/policyinfo/leinholderinfores.model';
import { VehiclesResponse } from '../../shared/model/api/policyinfo/vehicledetailsres.model';
import { BwappService } from '../bwapp.service';
import { BWErrorhandler } from '../errorhandler';


@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  private globlalConstants = GlobalConstants;

  constructor(private appService: BwappService, private errorHandler: BWErrorhandler) { }


  getVehicleFullDetails(policynumber: string, mco: string): Observable<VehiclesResponse> {
    const params = new HttpParams()
    .set('policyNumber', policynumber)
    .set('mco', mco);

    return this.appService.get(this.globlalConstants.GET_VEHICLEDETAILS, params);
  }

  getLeinholderInfo(policynumber: string, mco: string): Observable<LeinholderInfoRes> {
    const params = new HttpParams()
    .set('policyNumber', policynumber)
    .set('mco', mco);

    return this.appService.get(this.globlalConstants.GET_LEINHOLDERDETAILS, params);
  }

  getVinDupCheckStatus(agentCode: string, vin: string): Observable<VehiclesResponse> {
    const params = new HttpParams()
    .set('agentCode', agentCode)
    .set('vin', vin);

    return this.appService.get(this.globlalConstants.GET_VIN_DUP_CHECK_STATUS, params);
  }
}
