import { HttpParams } from '@angular/common/http';
import { GlobalConstants } from '../../../constants/global.constant';
import { Policynumber } from '../../../shared/model/policynumber';
import { BwappService } from '../../bwapp.service';
import { Observable, of } from 'rxjs';
import { Mfareq } from '../../../shared/model/api/policymgmt/mfareq';
import { environment } from '../../../../environments/environment';
import { OktaService } from '../okta/okta.service';
import { Authenticatephres, PolicyHolder } from '../../../shared/model/authentication/authenticatephres';
import * as i0 from "@angular/core";
import * as i1 from "../../bwapp.service";
import * as i2 from "../okta/okta.service";
var PolicyholderService = /** @class */ (function () {
    function PolicyholderService(appService, oktaService) {
        this.appService = appService;
        this.oktaService = oktaService;
        this.globalConstants = GlobalConstants;
        this.bwoktaEnabled = environment.bwOktaEnabled;
        this.bwOktaEnabled = environment.bwOktaEnabled;
    }
    PolicyholderService.prototype.tokenLogin = function (tokenRequest, bwOktaSSOEnabled) {
        if (bwOktaSSOEnabled) {
            return this.tokenLoginOkta(tokenRequest);
        }
        else {
            return this.appService.post(this.globalConstants.SSO_LOGIN, tokenRequest);
        }
    };
    PolicyholderService.prototype.findPH = function (findPHRequest) {
        // Tracker.log('IN Policy Holder Service findPH()');
        return this.appService.post(this.bwoktaEnabled ? this.globalConstants.FIND_PH_DETAILS_OKTA : this.globalConstants.FIND_PH_DETAILS, findPHRequest);
    };
    PolicyholderService.prototype.tokenLoginOkta = function (tokenRequest) {
        var _this = this;
        return Observable.create(function (observer) {
            _this.oktaService.oktaSSO(tokenRequest.ssot).subscribe(function (data) {
                // if data received correctly then call the decode-jwt 
                if (data.error && data.error.error) {
                    observer.error();
                }
                else {
                    var jwtTokenObject_1 = data;
                    // need to call the decode-jwt api 
                    _this.oktaService.oktaJWTDecode(jwtTokenObject_1.access_token).subscribe(function (data) {
                        if (data.policyHookId) {
                            // construct the object and return phUser
                            var authenticatePHRes = new Authenticatephres();
                            var phUserInfo = new PolicyHolder();
                            phUserInfo.pointHookID = data.policyHookId;
                            phUserInfo.userId = data.sub;
                            phUserInfo.email = data.sub;
                            phUserInfo.jwtToken = jwtTokenObject_1.access_token;
                            phUserInfo.sessionTicket = jwtTokenObject_1.access_token;
                            authenticatePHRes.phUser = phUserInfo;
                            authenticatePHRes.authenticatePolicyHolderResult = true;
                            authenticatePHRes.errorMsg = "";
                            observer.next(authenticatePHRes);
                        }
                        else {
                            observer.error();
                        }
                    }, function (error) {
                        observer.error();
                    });
                }
            }, function (error) {
                observer.error();
            });
        });
    };
    PolicyholderService.prototype.forgotPHPassword = function (forgotPHPasswordRequest) {
        // Tracker.log('IN Policy Holder Service forgotPHPassword()');
        return this.appService.post(this.globalConstants.FORGOT_PH_PASSWORD, forgotPHPasswordRequest);
    };
    PolicyholderService.prototype.checkAnswers = function (checkAnswersRequest) {
        // Tracker.log('IN Policy Holder Service checkAnswers()');
        return this.appService.post(this.globalConstants.CHECK_ANSWERS, checkAnswersRequest);
    };
    PolicyholderService.prototype.insertPH = function (inserPHRequest) {
        // Tracker.log('IN Policy Holder Service inserPH()');
        return this.appService.post(this.globalConstants.INSERT_PH, inserPHRequest);
    };
    PolicyholderService.prototype.insertPHNew = function (inserPHRequest) {
        // Tracker.log('IN Policy Holder Service inserPH()');
        return this.appService.post(this.globalConstants.INSERT_PH, inserPHRequest);
    };
    PolicyholderService.prototype.removePH = function (removePHRequest) {
        // Tracker.log('IN Policy Holder Service removePH()');
        return this.appService.post(this.bwoktaEnabled ? this.globalConstants.REMOVE_PH_OKTA : this.globalConstants.REMOVE_PH, removePHRequest);
    };
    PolicyholderService.prototype.getPolicyMco = function (policyNumber) {
        // Tracker.log('IN Policy Holder Service getPolicyMco()');
        this.policyNumber = new Policynumber(policyNumber);
        var params = new HttpParams()
            .set('polSym', this.policyNumber.symbol)
            .set('polNum', this.policyNumber.policy)
            .set('polMod', this.policyNumber.module);
        return this.appService.get(this.globalConstants.GET_POLICY_MCO, params);
    };
    PolicyholderService.prototype.changePassword = function (changePasswordRequest) {
        // Tracker.log('IN Policy Holder Service changePassword()');
        var bwOktaEnable = environment.bwOktaEnabled;
        return this.appService.post(bwOktaEnable
            ? this.globalConstants.CHANGE_PH_PASSWORD_OKTA
            : this.globalConstants.CHANGE_PH_PASSWORD, changePasswordRequest);
    };
    PolicyholderService.prototype.resetPassword = function (resetPHPasswordRequest) {
        // Tracker.log('IN Policy Holder Service resetPassword()');
        return this.appService.post(this.globalConstants.RESET_PH_PASSWORD, resetPHPasswordRequest);
    };
    PolicyholderService.prototype.updatePH = function (updatePHRequest) {
        // Tracker.log('IN Policy Holder Service updatePH()');
        return this.appService.post(this.bwoktaEnabled ? this.globalConstants.UPDATE_PH_OKTA : this.globalConstants.UPDATE_PH, updatePHRequest);
    };
    // MFA
    PolicyholderService.prototype.getMFADate = function (symbol, policy, mod, mco) {
        var params = new HttpParams()
            .set('policyNumber', symbol.concat(policy).concat(mod))
            .set('mco', mco);
        return this.appService.get(this.globalConstants.PLCY_MGMT_GET_MFA_DATE, params);
    };
    PolicyholderService.prototype.updateMFADate = function (symbol, policy, mod, mco, date) {
        var mfareq = new Mfareq();
        mfareq.mco = mco;
        mfareq.mfadate = date;
        mfareq.mod = mod;
        mfareq.policy = policy;
        mfareq.symbol = symbol;
        return this.appService.post(this.globalConstants.PLCY_MGMT_UPDATE_MFA_DATE, mfareq);
    };
    PolicyholderService.prototype.checkEachAnswer = function (checkEachAnswerRequest) {
        // Tracker.log('IN Policy Holder Service checkEachAnswer()');
        return this.appService.post(this.globalConstants.CHECK_EACH_ANSWER, checkEachAnswerRequest);
    };
    // get future Payments
    PolicyholderService.prototype.getFuturePayments = function (symbol, policy, mod, mco) {
        var params = new HttpParams()
            .set('policyNumber', symbol.concat(policy).concat(mod))
            .set('mco', mco);
        return this.appService.get(this.globalConstants.PH_FUTURE_PAYMENTS, params);
    };
    PolicyholderService.prototype.getLienholdersByKey = function (searchTerm) {
        var params = new HttpParams()
            .set('searchTerm', searchTerm);
        if (!searchTerm.trim()) {
            // if not search term, return empty lienholder array.
            return of([]);
        }
        return this.appService.get(this.globalConstants.LIENHOLDER_SEARCH, params);
    };
    PolicyholderService.prototype.getPaymentusStatus = function () {
        var params = new HttpParams();
        return this.appService.get(this.globalConstants.GET_POLICYHOLDERINFO_PAYMENTUS, params);
    };
    PolicyholderService.ngInjectableDef = i0.defineInjectable({ factory: function PolicyholderService_Factory() { return new PolicyholderService(i0.inject(i1.BwappService), i0.inject(i2.OktaService)); }, token: PolicyholderService, providedIn: "root" });
    return PolicyholderService;
}());
export { PolicyholderService };
