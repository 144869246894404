import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PolicyNumberValidator } from '../../validators/policynumber.validator';
import { MessageConstants } from '../../../constants/message.constant';

@Component({
  selector: 'app-policy-number-input',
  styleUrls: ['./policy-number-input.component.scss'],
  template:
  `
  <div [formGroup]="form">
    <label for="policy" tabindex="0">Policy Number</label>
    <ng-template #policyNumber><div >Policy Number is located on your declaration page or billing notice.
    It starts with a letter followed by 11 numeric digits i.e. G00123456700</div></ng-template>
    <button tabindex="0" aria-describedby="tooltip_desc"
     class="btn p-0 align-text-bottom" placement="right" #polNumTooltip="ngbTooltip" aria-label="Policy Number Help"
    (click)="toggle(polNumTooltip)" (blur)="polNumTooltip.close()" [ngbTooltip]="policyNumber">
      <img src="assets/img/icon-tooltip.svg" class="icon icon-svg ml-1" alt="tooltip"/>
    </button>
    <input id="policy" aria-required="true" aria-describedby="phpolicynum_desc" appPolicynumberformat class="form-control"
    placeholder="xxx-xxxxxxx-xx" [readonly]="this.isReadOnly" [attr.aria-invalid]="isFieldValid('phpolicynum')"
    formControlName="phpolicynum" maxlength="14" #p (blur)="form.get('phpolicynum').setValue(p.value, {emitEvent: false})"/>
    <app-field-error-display [displayError]="isFieldValid('phpolicynum')"
    [errorMsg]="getPolicyNumErrMsg(form.controls['phpolicynum'].errors)" [elemID]="phpolicynum_desc"
      role="alert"></app-field-error-display>
  </div>
  <p class="invisible d-none d-sm-none d-md-none" id="phpolicynum_desc" >Enter only Aplha Numeric.</p>
  <p class="invisible d-none d-sm-none d-md-none" id="tooltip_desc" > Click enter to read tooltip content.On tab tooltip closes.</p>
  `
})
export class PolicyNumberInputComponent implements OnInit {

  @Input() policynum: string;
  @Input() validate: boolean;
  @Input() formSubmitAttempt: boolean;
  @Input() form: FormGroup;
  @Input() isReadOnly: boolean;
  phpolicynum_desc: any;
  private usermessage = MessageConstants;

  constructor() { }

  ngOnInit() {
    // Tracker.log("Child Component PH Policy Number: "+this.policynum);
    if (this.validate) {
      this.form.addControl('phpolicynum', new FormControl(this.policynum, Validators.compose(
        [Validators.required, PolicyNumberValidator.validateBwPolicyNumber])));
    } else {
      this.form.addControl('phpolicynum', new FormControl(this.policynum, Validators.compose(
        [PolicyNumberValidator.validateBwPolicyNumber])));
    }
  }
  toggle(tooltip) {
    if (tooltip.isOpen()) {
      tooltip.close();
    } else {
      tooltip.open();
    }
  }
  // Form Utilities
  isFieldValid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  getPolicyNumErrMsg(errors) {
    if (errors) {
      if (errors.required === true) {
        return this.usermessage.REQUIRED_POLICYNUMBER;
      } else if (errors.plcynum === true) {
        return this.usermessage.FORMAT_POLICYNUMBER;
      }
    }
  }

}
