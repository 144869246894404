import { OnInit } from '@angular/core';
import { ActivatedRoute } from '../../../../node_modules/@angular/router';
var SorryComponent = /** @class */ (function () {
    function SorryComponent(route) {
        this.route = route;
    }
    SorryComponent.prototype.ngOnInit = function () {
        /*this.route
           .queryParams
           .subscribe(params => {
             // Defaults to 0 if no query param provided.
             console.log('Media Alpha Zip Code Sorry component---' + params['zipCode']);
             this.mediaAlphazipCode = params['zipCode'];
           }); */
        // this.mediaalphaURL = '../../../assets/html/test.html?zip={{mediaAlphazipCode}}';
        // this.loadScripts();
        /*var MediaAlphaExchange = {
          "placement_id": "FDiY-QmQ3q8uQvSqaMzipxDdi8VAaA",
          "version": "17",
          "type": "ad_unit",
          "ua_class": "auto",
          "data": {
              "zip": "90210"
          }
      }; */
        /* MediaAlphaExchange.success = function(numAds) {
           console.log("ads from mediaalpha", numAds);
         }; */
        // MediaAlphaExchange__load('target');
    };
    return SorryComponent;
}());
export { SorryComponent };
