
  <div class="modal-header">
  <h4 class="modal-title">Enter Current Password</h4>
  <button type="button" class="close" aria-label="Close" (click)="errorModal.dismiss('Cross click')">
  <span aria-hidden="true">&times;</span></button>
</div>
<form class="my-4" [formGroup]="passwordForm">
<div class="d-flex justify-content-center border-0">

  <app-app-errors *ngIf="this.errorFlag"></app-app-errors>

</div>
<div class="modal-body">
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        Please enter your current password to make any changes to your profile.
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
        <div class="form-group">
                  <div class="input-group">
                    <input [type]="showPassword ? 'text' : 'password'" placeholder="Enter your password" class="form-control"
                      formControlName="password" aria-label="Enter your password">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" type="button" (click)="togglePassword()">
                        <span *ngIf="showPassword === true"><i class="far fa-eye-slash"></i></span>
                        <span *ngIf="showPassword === false"><i class="far fa-eye"></i></span>
                      </button>
                    </div>
                  </div>
                  <app-field-error-display [displayError]="isFieldValid('password')"
                  [errorMsg]="usermessage.REQUIRED_PASSWORD"></app-field-error-display>
                </div>
      </div>
  </div>
</div>
<div class="modal-footer">
<button class="btn btn-secondary" (click)="errorModal.dismiss('Close click')">
Cancel
</button>
  <button *ngIf="loaded" type="submit" class="btn btn-primary"
  (click)='onSubmit(this.passwordForm.value)' data-dismiss="modal">Submit</button>
  <button *ngIf="!loaded" class="btn btn-primary" disabled="true"><i class="fas fa-circle-notch fa-spin">
  </i>&nbsp;Submit</button>
 
</div>
</form>
  