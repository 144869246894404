/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./googlemap.component";
var styles_GooglemapComponent = [];
var RenderType_GooglemapComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GooglemapComponent, data: {} });
export { RenderType_GooglemapComponent as RenderType_GooglemapComponent };
export function View_GooglemapComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { gmapElement: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["gmap", 1]], null, 1, "div", [["style", "\u201Dwidth:100%;height:400px\u201D"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["GMAP"]))], null, null); }
export function View_GooglemapComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-googlemap", [], null, null, null, View_GooglemapComponent_0, RenderType_GooglemapComponent)), i0.ɵdid(1, 114688, null, 0, i1.GooglemapComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GooglemapComponentNgFactory = i0.ɵccf("app-googlemap", i1.GooglemapComponent, View_GooglemapComponent_Host_0, { address: "address" }, {}, []);
export { GooglemapComponentNgFactory as GooglemapComponentNgFactory };
