import { Component, OnInit , EventEmitter, Output  } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleAnalyticsService } from '../../../services/analytics/google-analytics.service';
import { EndorsementRes } from '../../../model/api/endorsement/endorsement-res';
import { EndorsementService } from '../../../../services/api/endorsement/endorsement.service';
import { EndorseMetainfo } from '../../../model/endorsements/endorse-metainfo.model';
import { BwstoreService } from '../../../services/bwstore.service';
import { BWErrorhandler } from '../../../../services/errorhandler';
import { BWError } from '../../../model/error.model';
import { MessageConstants } from '../../../../constants/message.constant';
import { FullPolicyDetails } from '../../../model/api/bwibic/fullpolicydetails.model';
@Component({
  selector: 'app-cancel-change-requested',
  template: `
    <div class="modal-header" >
      <h4 class="modal-title" id="request-to-cancel-confirmation">Cancel Change Requested</h4>
    </div>

    <div class="modal-body">
      <p>The change being requested will be cancelled and not saved. Do you still want to navigate away from this page?</p>
    </div>

    <div class="modal-footer mt-1">
      <button type="button" class="btn btn-secondary" (click)="close ()">Cancel</button>
      <button *ngIf="!inProgress" type="submit" class="btn btn-primary" (click)="confirmChanges()">Continue</button>
      <button *ngIf="inProgress" type="submit" class="btn btn-primary" disabled="true">
        <i class="fas fa-circle-notch fa-spin"></i>&nbsp;Continue
      </button>
    </div>
  `,
  styles: []
})
export class CancelChangeReqComponent implements OnInit {
  @Output() responseEmit: EventEmitter<boolean> = new EventEmitter();
  bwEndorsementInfo: EndorseMetainfo;
  basicPolicyDetails: FullPolicyDetails;

  inProgress = false;

  constructor(public cancelChangeRequestedModal: NgbActiveModal,
              private ga: GoogleAnalyticsService,
              private _storeService: BwstoreService,
              private errorHandler: BWErrorhandler,
              private endorsementService: EndorsementService) {
                this.bwEndorsementInfo = this._storeService.getEndorsementInfoFromStore();
                this.basicPolicyDetails = this._storeService.getPolicyInfoFromStore();
              }

  ngOnInit() {}

  confirmChanges() {
    this.ga.trackEvent('CancelEndorsement', 'CancelChange', 'ContinueClick');
    this.inProgress = true;
    // console.log('BWLog: CancelChangeReqComponent -> confirmChanges -> this.bwEndorsementInfo.endorseBinderNum',
    // this.bwEndorsementInfo.endorseBinderNum);
    if (this.bwEndorsementInfo && this.bwEndorsementInfo.endorseBinderNum !== undefined
          && this.bwEndorsementInfo.endorseBinderNum !== ''
          && this.bwEndorsementInfo.endorseBound === 'false') {
        const msg = 'Customer abandoned the endorsement';
        this.endorsementService.processEndorseVoid(this.bwEndorsementInfo , msg).subscribe((endorseVoidRes: EndorsementRes) => {
        if (endorseVoidRes && endorseVoidRes.status === '0') {
          this._storeService.deleteBwEndorsementInfo(new EndorseMetainfo());
          this.responseEmit.emit(true);
          this.cancelChangeRequestedModal.close();
        } else {
          this.errorHandler.handleApplicationError(new BWError(
            {
              'errorcode': 'Endorsement', 'errordesc': MessageConstants.AGENT_ERROR_MSG + '<br>' +
              'Agent Name :' + this.basicPolicyDetails.agentInfo.AgentName1 + '<br>'
              + 'Agent Phone Number :' + this.basicPolicyDetails.agentInfo.ACNM_TELE,
              'source': 'EndorseBind'
            }));

          this._storeService.deleteBwEndorsementInfo(new EndorseMetainfo());
          this.responseEmit.emit(false);
          this.cancelChangeRequestedModal.close();
        }
     });
    } else {
      console.log(' No changes to void hence returning to my policy');
      this._storeService.deleteBwEndorsementInfo(new EndorseMetainfo());
      this.responseEmit.emit(true);
      this.cancelChangeRequestedModal.close();
    }
  }

  close () {
    this.ga.trackEvent('ReplaceVehicle', 'CancelChange', 'CancelClick');
    this.responseEmit.emit(false);
    this.cancelChangeRequestedModal.close();
  }
}
