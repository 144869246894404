import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BwstoreService } from '../../../services/bwstore.service';
import { GoogleAnalyticsService } from '../../../services/analytics/google-analytics.service';
import { StateCds } from '../../../model/state-cds.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NewUser } from '../../../model/newuser.model';
import { Policynumber } from '../../../model/policynumber';
import { MessageConstants } from '../../../../constants/message.constant';
import { PolicyHolder } from '../../../model/authentication/authenticatephres';
import { FullPolicyDetails } from '../../../model/api/bwibic/fullpolicydetails.model';
import { StatecdsService } from '../../../services/statecds.service';
import { BWErrorhandler } from '../../../../services/errorhandler';
import { CenturydatePipe } from '../../../pipes/centurydate.pipe';
import { PrivacypolicyService } from '../../../../services/privacypolicy/privacypolicy.service';
import { BWError } from '../../../model/error.model';
import { CommonUtil } from '../../../utils/commonutil';

@Component({
  selector: 'app-privacy-opt-out',
  template: `
  <!-- Modal -->
  <!--Defect 13342-->
      <div class="modal-header">
       <h6 class="modal-title" id="modal-label">Restrict Information Sharing With Our Affiliated Companies</h6>
          <button type="button" class="close" (click)="dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body">
       <div class="container-fluid">
       <p *ngIf ="returnMessage !== '' && returnMessage == 'success'"
       class ="alert alert-success alert-dismissible fade show ng-star-inserted" >Your opt out form has been successfully submitted</p>
       <app-app-errors *ngIf ="returnMessage !== '' && returnMessage == 'fail'" ></app-app-errors>
            <p>Please do not share consumer report information about me with your affiliates except as otherwise permitted by law.</p>
            <p><strong>Remember, it is only necessary to notify us once of your intention to opt out.</strong></p>
          <form  class="my-4" [formGroup]="optForm">
            <!--First Name & Last Name-->
              <div class="form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">First Name</label>
                    <input type="text" class="form-control form-control-lg" formControlName="firstName"/>
                    <app-field-error-display [displayError]="isFieldValid('firstName')"
                    [errorMsg]="getErrorMessageMsg(optForm.controls['firstName'].errors,'firstName')"></app-field-error-display>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">Last Name</label>
                    <input type="text" class="form-control form-control-lg" formControlName="lastName"/>
                    <app-field-error-display [displayError]="isFieldValid('lastName')"
                    [errorMsg]="getErrorMessageMsg(optForm.controls['lastName'].errors,'lastName')"></app-field-error-display>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <app-policy-number-input [policynum]="policynum" [formSubmitAttempt]="formSubmitAttempt"
                    [form]="optForm" [validate]="true"></app-policy-number-input>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">State</label>
                    <select class="form-control"  formControlName="state">
                    <option *ngFor="let statecode of stateCds" value="{{ statecode.stateCd }}">{{ statecode.stateDesc }}</option>
                      </select>
                      <app-field-error-display [displayError]="isFieldValid('state')"
                      [errorMsg]="getErrorMessageMsg(optForm.controls['state'].errors,'state')"></app-field-error-display>
                  </div>
                </div>
              </div>
            </form>
          </div>
      </div>
      <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="dismiss()">Cancel</button>
      <button *ngIf="loaded" type="button" class="btn btn-primary" (click)="submitOptForm(optForm.value)">Submit</button>
      <button *ngIf="!loaded" class="btn  btn-primary" disabled="true"><i class="fas fa-circle-notch fa-spin">
      </i>Submit</button>
      </div>
  `,
  providers: [ CenturydatePipe],
  styleUrls: ['./privacy-opt-out.component.scss']
})
export class PrivacyOptOutComponent implements OnInit {

  stateCds: StateCds[] = [];
  // FormGroup For validationsp
  optForm: FormGroup;
  newUser: NewUser;
  policyNumber: Policynumber;
  returnMessage: string;
  formSubmitAttempt: boolean;
  policynum = '';
  usermessage = MessageConstants;
  loaded = true;
  dateobj = {
     'day': '',
     'month': '',
     'year' : ''
   };
   enrollDate: string;
   enrollTime: string;
   policyState: string;
   currentUserLoginInfo: PolicyHolder;
   fullPolicydetails: FullPolicyDetails;

  constructor(private fb: FormBuilder, private stateCdService: StatecdsService,
    private _storeService: BwstoreService , private privacyPolicyService: PrivacypolicyService,
    private errorHandler: BWErrorhandler, private datePipe: CenturydatePipe, public privacyOptOutModal: NgbActiveModal) {

       // Get the current state of NewUser
       this.currentUserLoginInfo = this._storeService.getLoginInfoFromStore();
       this.fullPolicydetails = this._storeService.getPolicyInfoFromStore();
       if (this.fullPolicydetails && this.fullPolicydetails.policyInfo) {
        this.policynum = this.fullPolicydetails.policyInfo.PolicyNumber;
        this.policyState = this.fullPolicydetails.policyInfo.PolicyState;
       }
    }

  ngOnInit() {
    this.stateCdService.getStateCds().subscribe((stateCds: StateCds[]) => {
      this.stateCds = stateCds;
    });

    this.optForm = this.fb.group({
      'firstName' : [ this.currentUserLoginInfo.firstName, Validators.compose([Validators.required])],
      'lastName' : [ this.currentUserLoginInfo.lastName, Validators.compose([Validators.required])],
      'state' : [ this.policyState, Validators.compose([Validators.required])]
    });
  }

  navigate() {
    this.privacyOptOutModal.close();
  }

  dismiss() {
    this.privacyOptOutModal.dismiss();
  }

  // privacy opt form submit
  submitOptForm(values: any) {
    this.formSubmitAttempt = true;
    if (this.optForm.valid) {
    this.policyNumber = new Policynumber(values.phpolicynum.toUpperCase());
    this.loaded = false;
    const date = new Date();
    this.dateobj.day = date.getDate().toString();
    this.dateobj.month = (date.getMonth() + 1).toString();
    this.dateobj.year = date.getFullYear().toString();
    // this.enrollDate = this.datePipe.transform(CommonUtil.formatDate(this.dateobj));
    // console.log(CommonUtil.getDateNowEST());
    this.enrollDate = this.datePipe.transform(CommonUtil.getDateTimeNowEST());
    // this.enrollTime = date.toTimeString().slice(0, 8);
    this.enrollTime = CommonUtil.getTimeNowEST();
    // console.log('enrolldate-----------' + this.enrollDate);
    // console.log('enrolltime-----------' + this.enrollTime);
    // call's HasOptedOutPrivacyPolicy to check wheather the policy is opted or not
    this.privacyPolicyService.policyOpt( this.policyNumber.symbol, this.policyNumber.policy,
       values.state.toUpperCase(), values.lastName.toUpperCase(), values.firstName.toUpperCase()).then(
        res => {
          if (!res) {
            // calling validatepolicyOpt to validate and creates the privacy policy opt
            this.privacyPolicyService.validatepolicyOpt(this.policyNumber.symbol,
               this.policyNumber.policy, values.state.toUpperCase(), values.lastName.toUpperCase() , values.firstName.toUpperCase()).then(
              validatePolicyres => {
                if (validatePolicyres) {
                  // after the success we are loging using service logPrivacyOpt.
                  this.privacyPolicyService.logPrivacyOpt(this.policyNumber.symbol,
                    this.policyNumber.policy, values.state.toUpperCase(), values.lastName.toUpperCase(),
                     values.firstName.toUpperCase(), this.policyNumber.module, this.enrollDate, this.enrollTime).then (
                      logOptOutRes => {
                        this.loaded = true;
                          this.returnMessage = logOptOutRes ?  'success' : 'fail';
                      }).catch(error => {
                        this.loaded = true;
                      });
                 } else {
                  this.loaded = true;
                  this.returnMessage = 'fail';
                  this.errorHandler.handleApplicationError(new BWError(
                    {'errorcode': '100', 'errordesc': 'There was no record found for the policy information entered.' +
                    'Please check your entries and try again.If you have any questions,' +
                     'please contact customer service at 1-888-888-0080.', 'source': 'privacyOptOutService'}));
                 }
               }).catch(error => {
                this.loaded = true;
               });
          } else {
            this.loaded = true;
            this.returnMessage = 'fail';
            this.errorHandler.handleApplicationError(new BWError(
              {'errorcode': '100', 'errordesc': 'Already Opted Out,Our records indicate that you have already opted out.' +
              'This form should only be submitted once.', 'source': 'privacyOptOutService'}));
          }
         }).catch(error => {
           this.loaded = true;
            });
        }
  }

   // Form Utilities
    isFieldValid(field: string) {
      return (
        (!this.optForm.get(field).valid && this.optForm.get(field).touched) ||
        (this.optForm.get(field).untouched && this.formSubmitAttempt)
      );
    }

    // to display error messages
    getErrorMessageMsg(errors, field) {
      if (errors) {
        if ( errors.required === true ) {
          if (field === 'firstName') {
            return this.usermessage.REQUIRED_FIRSTNAME;
          } else if ( field === 'lastName' ) {
            return this.usermessage.REQUIRED_LASTNAME;
          } else if ( field === 'state' ) {
            return 'state is required';
          }
        }
      }
    }
}
