export class CreateEPolicyRequest {

    szLocation: string;
    szPolicyCo: string;
    szPolicyNbr: string;
    szMCO: string;
    szEmail: string;
    szAgency: string;
    szFirstName: string;
    szLastName: string;
    blnMidTermENR: string;
    szSource: string;
    szDiscountFlag: string;
    szAgentType: string;

}
