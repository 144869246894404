import { Injectable } from '@angular/core';
import { BwappService } from '../../bwapp.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { Helptext, HelpTextRes } from '../../../shared/model/api/helptext/helptext.model';
import { HttpParams } from '@angular/common/http';
import { GlobalConstants } from '../../../constants/global.constant';
import { map } from 'rxjs/operators';
import { HelpTextMap } from '../../../shared/maps/helptext-map';
import { BwstoreService } from '../../../shared/services/bwstore.service';
import { PolicyInfo } from '../../../shared/model/api/bwibic/fullpolicydetails.model';
import { Tracker } from '../../../shared/utils/tracker';

@Injectable({
  providedIn: 'root'
})
export class HelptextService {

  private globlalConstants = GlobalConstants;
  policyInfo: PolicyInfo;

  private helpTextMap: HelpTextMap = new HelpTextMap();

  constructor(private appService: BwappService, private bwStoreService: BwstoreService) { }

  getHelpText(mco: string, statecd: string, ratebook: string): Observable<HelpTextMap> {

    const helpTextmap: HelpTextMap = new HelpTextMap();
    const params = new HttpParams()
      .set('appid', GlobalConstants.APPID_OLD)
      .set('mco', '30')
      .set('state', 'FL')
      .set('ratebook', ratebook)
      .set('lob', GlobalConstants.LOB_AUTO);

      return this.appService.get(this.globlalConstants.GET_HELPTEXT, params)
        .pipe(
          map((res: HelpTextRes[]) => {
            res.forEach(ht => {
              helpTextmap.set(ht.fieldID, ht);
            });
            return helpTextmap;
          })
        );
  }

  getHelpTextByIds(fieldIds: any): Observable<HelpTextMap> {
    this.policyInfo = this.bwStoreService.getPolicyInfoFromStore().policyInfo;
    const helpTextmap: HelpTextMap = new HelpTextMap();
    const params = new HttpParams()
      .set('fieldIds', fieldIds)
      .set('appid', GlobalConstants.APPID_OLD)
      .set('mco', this.policyInfo.MASTER0CO)
      .set('state', this.policyInfo.RISK0STATE)
      .set('ratebook', this.policyInfo.PolicyRateBook)
      .set('lob', GlobalConstants.LOB_AUTO);

      return this.appService.get(this.globlalConstants.GET_HELPTEXT_BYID, params)
        .pipe(
          map((res: HelpTextRes[]) => {
            res.forEach(ht => {
              helpTextmap.set(ht.fieldID, ht);
            });

            console.log('mco:', this.policyInfo.MASTER0CO, '   statecd:',
            this.policyInfo.RISK0STATE, '   ratebook: ', this.policyInfo.PolicyRateBook, '   fieldIds: ', fieldIds);

            return helpTextmap;
          })
        );
  }
}


