
  <main id="main" class="main text-center">
  <div class="container">
    <div class="d-flex justify-content-center">
      <div class="col-md-7 justify-content-center">
        <p class="lead mb-2">Accept Go Paperless Terms and Conditions</p>
      </div>
    </div>
    <div class="d-flex justify-content-center mt-3">
      <div class="col-md-10 justify-content-center">
        <div class="mt-3">
          <p>By clicking 'I ACCEPT' below you are agreeing to the
            <a routerLink="/gopaperless-terms" routerLinkActive="active" target="_blank">Go Paperless Terms and Conditions</a>
          </p>
          <p>Acceptance is required to complete your registration.</p>
          <button type="submit" class="btn btn-primary mt-3" (click)="acceptGoPaperless()">I Accept</button>
        </div>
      </div>
    </div>
  </div>
</main>
  