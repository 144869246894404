import { PrivacyoptoutService } from '../api/privacyoptout/privacyoptout.service';
import * as i0 from "@angular/core";
import * as i1 from "../api/privacyoptout/privacyoptout.service";
var PrivacypolicyService = /** @class */ (function () {
    function PrivacypolicyService(privacyoptoutService) {
        this.privacyoptoutService = privacyoptoutService;
    }
    // this function is used to call the hasOptedprivacyPolicy services wrapper calls  to check the policy is opted or not
    // if opt in response object returns true then it is already opted for privacy
    PrivacypolicyService.prototype.policyOpt = function (policySym, policyNum, state, lastname, firstName) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.privacyoptoutService.hasOptedprivacyPolicy(policySym, policyNum)
                .subscribe(function (res) {
                resolve(res.opt);
            }, function (error) {
                reject(error);
            });
        });
    };
    // this function is used to call the validatePolicyOptOut  wrapper call to update the record
    // returns true if it is succesfully updated
    PrivacypolicyService.prototype.validatepolicyOpt = function (policySym, policyNum, state, lastName, firstName) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.privacyoptoutService.validatePolicyOptOut(policySym, policyNum, state, lastName)
                .subscribe(function (validateResponse) {
                resolve(validateResponse.policyPrivacyOptOutPOINT);
            }, function (error) {
                reject(error);
            });
        });
    };
    // this function is used to call the logPrivacyOpt wrapper call to log the logPrivacyOpt
    PrivacypolicyService.prototype.logPrivacyOpt = function (policySym, policyNum, state, lastName, firstName, mod, enrollDate, enrollTime) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.privacyoptoutService.logPrivacyOptOut(policySym, policyNum, state, lastName, firstName, mod, enrollDate, enrollTime).subscribe(function (res) {
                resolve(res);
            }, function (error) {
                reject(error);
            });
        });
    };
    PrivacypolicyService.ngInjectableDef = i0.defineInjectable({ factory: function PrivacypolicyService_Factory() { return new PrivacypolicyService(i0.inject(i1.PrivacyoptoutService)); }, token: PrivacypolicyService, providedIn: "root" });
    return PrivacypolicyService;
}());
export { PrivacypolicyService };
