var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { HttpParams, HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { PaymentMethodAssetImgUrl, PaymentMethodTypeMapping, PaymentusIframePaymentMethodType } from '../../../constants/billing.constants';
import { GlobalConstants } from '../../../constants/global.constant';
import { BwappService } from '../../bwapp.service';
import * as i0 from "@angular/core";
import * as i1 from "../../bwapp.service";
import * as i2 from "@angular/common/http";
var PaymentService = /** @class */ (function () {
    function PaymentService(appService, http) {
        this.appService = appService;
        this.http = http;
        this.globlalConstants = GlobalConstants;
        this.CHECKING_ACCOUNT = 'CHQ';
        this.SAVINGS_ACCOUNT = 'SAV';
        this.CREDIT_CARD = 'CC';
        this.PM_DETAILS = 'pmDetails';
        this.paymentMethodFormValue$ = new Subject();
    }
    PaymentService.prototype.previousPayment = function (previouspaymentreq) {
        // Tracker.log('IN PAYMENT SERVICE CCPAYMENT');
        return this.appService.post(this.globlalConstants.PREVIOUS_PAYMENT, previouspaymentreq);
    };
    PaymentService.prototype.achPayment = function (achpaymentreq) {
        // Tracker.log('IN PAYMENT SERVICE ACH-PAYMENT');
        return this.appService.post(this.globlalConstants.ACH_PAYMENT, achpaymentreq);
    };
    PaymentService.prototype.ccPayment = function (ccpaymentreq) {
        // Tracker.log('IN PAYMENT SERVICE CCPAYMENT');
        return this.appService.post(this.globlalConstants.CC_PAYMENT, ccpaymentreq);
    };
    PaymentService.prototype.getPaymentSchedule = function (strZip, strPolicySym, strPolicyNum) {
        // Tracker.log('Getting payment schedule for policy number ' + strPolicySym);
        var params = new HttpParams()
            .set('szZipcode', strZip)
            .set('szPolicySymbol', strPolicySym)
            .set('szPolicyNumber', strPolicyNum);
        return this.appService.get(this.globlalConstants.GET_PAYMENTSCHEDULE, params);
    };
    PaymentService.prototype.getPaymentProfiles = function (accountNumber) {
        return this.appService.getPaymentProfiles(this.globlalConstants.GET_PAYMENT_PROFILES, accountNumber);
    };
    PaymentService.prototype.setPaymentMethods = function (accountNumber) {
        var _this = this;
        return this.getPaymentProfiles(accountNumber).pipe(map(function (customer) {
            var methods = customer['data']['customer']['paymentMethods'];
            if (methods && methods.length) {
                _this.paymentMethods = methods.map(function (paymentMethod) {
                    var paymentMethodInfo = {
                        bankName: paymentMethod.bankName,
                        accountHolderName: paymentMethod.accountHolderName,
                        accountNumber: paymentMethod.accountNumber.slice(-4),
                        expiryMonth: paymentMethod.expiryMonth,
                        expiryYear: paymentMethod.expiryYear,
                        isDefault: paymentMethod.recurringEnabled,
                        type: paymentMethod.type,
                        paymentusIframePaymentType: PaymentMethodTypeMapping[paymentMethod.type],
                        paymentToken: paymentMethod.paymentToken,
                        bankRoutingNumber: paymentMethod.bankRoutingNumber,
                    };
                    return _this.populatePaymentMethodAdditionalValues(paymentMethodInfo);
                });
                _this.paymentMethods = _this.sortPaymentMethods(_this.paymentMethods);
                return _this.paymentMethods;
            }
        }));
    };
    PaymentService.prototype.populatePaymentMethodAdditionalValues = function (paymentMethodInfo) {
        var expirationDate = null;
        var imageUrl, imageAlt;
        var accountNumber = paymentMethodInfo.accountNumber;
        var expiryMonth = paymentMethodInfo.expiryMonth;
        var expiryYear = paymentMethodInfo.expiryYear;
        var type = paymentMethodInfo.type;
        var paymentusIframePaymentType = PaymentMethodTypeMapping[type];
        var bankName = paymentMethodInfo.bankName;
        var hasExpirationDate = expiryMonth && expiryYear;
        var isBankAccount = accountNumber &&
            type &&
            paymentusIframePaymentType === PaymentusIframePaymentMethodType.DIRECT_DEBIT;
        var isCard = !isBankAccount &&
            accountNumber &&
            hasExpirationDate &&
            type &&
            (paymentusIframePaymentType === PaymentusIframePaymentMethodType.CREDIT_CARD ||
                paymentusIframePaymentType === PaymentusIframePaymentMethodType.DEBIT_CARD);
        if (PaymentMethodAssetImgUrl[type].url)
            imageUrl = PaymentMethodAssetImgUrl[type].url;
        if (PaymentMethodAssetImgUrl[type].adaLabel)
            imageAlt = PaymentMethodAssetImgUrl[type].adaLabel;
        if (hasExpirationDate) {
            expirationDate = expiryMonth + "/" + expiryYear.slice(-2);
        }
        var detailsContent = isCard ? expirationDate : bankName;
        var accountHolderNameTitle = isCard ? 'Name on card' : 'Account holder';
        var detailsTitle = isCard ? 'Expires' : 'Bank Name';
        return __assign({ isCard: isCard,
            isBankAccount: isBankAccount,
            imageUrl: imageUrl,
            imageAlt: imageAlt,
            accountHolderNameTitle: accountHolderNameTitle,
            detailsContent: detailsContent,
            detailsTitle: detailsTitle }, paymentMethodInfo);
    };
    PaymentService.prototype.validatePolicy = function (strZip, strPolicySym, strPolicyNum) {
        // Tracker.log('validatePolicy for policy number ' + strPolicySym);
        var params = new HttpParams()
            .set('szZipcode', strZip)
            .set('szPolicySymbol', strPolicySym)
            .set('szPolicyNumber', strPolicyNum);
        return this.appService.get(this.globlalConstants.GET_VALIDATEPOLICY, params);
    };
    PaymentService.prototype.validatePolicyDetails = function (strPolicySym, strPolicyNum, strZip, strLastName, strDOB) {
        // Tracker.log('validatePolicy for policy number ' + strPolicySym);
        var params = new HttpParams()
            .set('szSymbol', strPolicySym)
            .set('szPolicyNumber', strPolicyNum)
            .set('szZipCode', strZip)
            .set('szLastName', strLastName)
            .set('szBirthDate', strDOB);
        return this.appService.get(this.globlalConstants.GET_VALIDATEPOLICYDETAILS, params);
    };
    PaymentService.prototype.getBankDetails = function (routingNumber) {
        var params = new HttpParams()
            .set('routingNumber', routingNumber);
        return this.appService.get(this.globlalConstants.GET_BANK_DETAILS, params);
    };
    PaymentService.prototype.sortPaymentMethods = function (paymentMethods) {
        var _this = this;
        var sortedMethodsObject = {
            checkingBankAccounts: [],
            savingsBankAccounts: [],
            businessBankAccounts: [],
            debitCardMethods: [],
            creditCardMethods: [],
            otherPaymentMethods: [],
        };
        paymentMethods.forEach(function (paymentMethod) {
            sortedMethodsObject = _this.insertPaymentMethodBasedOnType(paymentMethod, sortedMethodsObject);
        });
        return sortedMethodsObject.checkingBankAccounts.concat(sortedMethodsObject.savingsBankAccounts, sortedMethodsObject.businessBankAccounts, sortedMethodsObject.debitCardMethods, sortedMethodsObject.creditCardMethods, sortedMethodsObject.otherPaymentMethods);
    };
    PaymentService.prototype.insertPaymentMethodBasedOnType = function (paymentMethod, sortedPaymentMethods) {
        if (paymentMethod.isBankAccount) {
            switch (paymentMethod.type) {
                case this.CHECKING_ACCOUNT:
                    sortedPaymentMethods.checkingBankAccounts.push(paymentMethod);
                    break;
                case this.SAVINGS_ACCOUNT:
                    sortedPaymentMethods.savingsBankAccounts.push(paymentMethod);
                    break;
                default:
                    sortedPaymentMethods.businessBankAccounts.push(paymentMethod);
            }
        }
        else if (paymentMethod.isCard) {
            switch (paymentMethod.paymentusIframePaymentType) {
                case this.CREDIT_CARD:
                    sortedPaymentMethods.creditCardMethods.push(paymentMethod);
                    break;
                default:
                    sortedPaymentMethods.debitCardMethods.push(paymentMethod);
            }
        }
        else {
            sortedPaymentMethods.otherPaymentMethods.push(paymentMethod);
        }
        return sortedPaymentMethods;
    };
    PaymentService.prototype.getPaymentusIframe = function (payload) {
        var paymentusIframeUrl = environment.paymentUsIframeDomainUrl;
        return this.appService.post(this.globlalConstants.PAYMENTUS_IFRAME, payload)
            .pipe(map(function (response) { return ({ url: paymentusIframeUrl + response.encryptedMessage, paymentType: payload.pmCategory }); }));
    };
    PaymentService.prototype.parsePMDetailsMessageFromIframe = function (paymentMethodDetails) {
        if (!paymentMethodDetails) {
            return null;
        }
        var pmDetailsObjectString = paymentMethodDetails.substring(this.PM_DETAILS.length + paymentMethodDetails.indexOf(this.PM_DETAILS) + 1);
        var pmDetailsObject = JSON.parse(pmDetailsObjectString);
        return {
            CardHolderName: pmDetailsObject.CardHolderName,
            BankName: pmDetailsObject.BankName,
            token: pmDetailsObject.Token,
            type: pmDetailsObject.Type,
            maskedAccountNumber: pmDetailsObject.MaskedAccountNumber,
            expiryDate: pmDetailsObject.ExpiryDate,
        };
    };
    PaymentService.prototype.buildPaymentMethodInfo = function (paymentMethodDetails) {
        if (!paymentMethodDetails) {
            return null;
        }
        var expiryMonth = null;
        var expiryYear = null;
        var paymentType = PaymentMethodTypeMapping[paymentMethodDetails.type];
        if (paymentType === PaymentusIframePaymentMethodType.CREDIT_CARD ||
            paymentType === PaymentusIframePaymentMethodType.DEBIT_CARD) {
            expiryMonth = paymentMethodDetails.expiryDate.slice(0, 2);
            expiryYear = paymentMethodDetails.expiryDate.slice(-4);
        }
        var paymentMethodInfo = {
            accountHolderName: paymentMethodDetails.CardHolderName,
            bankName: paymentMethodDetails.BankName,
            accountNumber: paymentMethodDetails.maskedAccountNumber.slice(-4),
            expiryMonth: expiryMonth,
            expiryYear: expiryYear,
            isDefault: false,
            type: paymentMethodDetails.type,
            paymentusIframePaymentType: PaymentMethodTypeMapping[paymentMethodDetails.type],
            paymentToken: paymentMethodDetails.token,
        };
        return this.populatePaymentMethodAdditionalValues(paymentMethodInfo);
    };
    PaymentService.prototype.resetPaymentMethodsIsDefaultAndAddNew = function (paymentMethods, paymentMethodInfo) {
        if (!paymentMethodInfo) {
            return;
        }
        if (paymentMethods && paymentMethods.length) {
            var index = paymentMethods
                .map(function (paymentDetails) { return paymentDetails.paymentToken; })
                .indexOf(paymentMethodInfo.paymentToken);
            if (index > -1) {
                paymentMethods[index] = paymentMethodInfo;
            }
            else {
                paymentMethods.push(paymentMethodInfo);
            }
        }
        else {
            paymentMethods = [];
            paymentMethods.push(paymentMethodInfo);
        }
        this.paymentMethodFormValue$.next(paymentMethodInfo);
        return paymentMethods;
    };
    PaymentService.ngInjectableDef = i0.defineInjectable({ factory: function PaymentService_Factory() { return new PaymentService(i0.inject(i1.BwappService), i0.inject(i2.HttpClient)); }, token: PaymentService, providedIn: "root" });
    return PaymentService;
}());
export { PaymentService };
