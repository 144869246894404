import { Recipient } from './recipient.model';

export class Envelope {

    envelopeID: string;
    expireDate: string;
    recipients: Recipient[];

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
