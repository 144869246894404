import { formatDate } from '@angular/common';
import { forkJoin } from 'rxjs';
import { GlobalConstants } from '../../constants/global.constant';
import { EFTInfo } from '../../shared/model/api/bwibic/fullpolicydetails.model';
import { BWError } from '../../shared/model/error.model';
import { BwstoreService } from '../../shared/services/bwstore.service';
import { StatecdsService } from '../../shared/services/statecds.service';
import { CommonUtil } from '../../shared/utils/commonutil';
import { Tracker } from '../../shared/utils/tracker';
import { BwqueriesService } from '../api/bwquery/bwqueries.service';
import { DbcardpaymentService } from '../api/dbcardpayment/dbcardpayment.service';
import { IvrpaymentService } from '../api/ivrpayment/ivrpayment.service';
import { PaymentService } from '../api/payment/payment.service';
import { PolicyeftService } from '../api/policyeft/policyeft.service';
import { PolicymgtService } from '../api/policymgmt/policymgt.service';
import { BWErrorhandler } from '../errorhandler';
import * as i0 from "@angular/core";
import * as i1 from "../api/ivrpayment/ivrpayment.service";
import * as i2 from "../api/policyeft/policyeft.service";
import * as i3 from "../api/payment/payment.service";
import * as i4 from "../api/dbcardpayment/dbcardpayment.service";
import * as i5 from "../api/bwquery/bwqueries.service";
import * as i6 from "../api/policymgmt/policymgt.service";
import * as i7 from "../../shared/services/statecds.service";
import * as i8 from "../../shared/services/bwstore.service";
import * as i9 from "../errorhandler";
var AutopayeftService = /** @class */ (function () {
    function AutopayeftService(ivrpaymentService, policyEftService, paymentMobileService, dbcardPaymentService, bwqueriesService, policyMgmgtService, stateCdService, storeService, errorHandler) {
        this.ivrpaymentService = ivrpaymentService;
        this.policyEftService = policyEftService;
        this.paymentMobileService = paymentMobileService;
        this.dbcardPaymentService = dbcardPaymentService;
        this.bwqueriesService = bwqueriesService;
        this.policyMgmgtService = policyMgmgtService;
        this.stateCdService = stateCdService;
        this.storeService = storeService;
        this.errorHandler = errorHandler;
    }
    AutopayeftService.prototype.getManageEftDetails = function (policyInfo) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            forkJoin(_this.policyEftService.getEFTStatus(policyInfo.PolicyNumber, policyInfo.MASTER0CO), _this.paymentMobileService.validatePolicy(policyInfo.PolicyZip, policyInfo.SYMBOL, policyInfo.POLICY0NUM))
                .subscribe(function (_a) {
                var eftStatus = _a[0], paymentDetailsRes = _a[1];
                Tracker.loginfo('AutopayeftService', 'getManageEftDetails()', 'Fork Joined policyEftService.getEFTStatus() and ' +
                    ' paymentMobileService.validatePolicy()', '' + eftStatus);
                if (eftStatus && paymentDetailsRes) {
                    if (eftStatus.eftStatusResult === GlobalConstants.EFT_ENROLLED
                        || eftStatus.eftStatusResult === GlobalConstants.EFT_PENDING_ACTIVATION
                        || eftStatus.eftStatusResult === GlobalConstants.EFT_PENDING_UPDATE) {
                        paymentDetailsRes.eftIndicator = true;
                    }
                    else {
                        paymentDetailsRes.eftIndicator = false;
                    }
                    resolve(paymentDetailsRes);
                }
                else {
                    return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'S01', 'errordesc': 'Error reading details from Service', 'source': 'PaymentsMobileService' })));
                }
            }, function (error) {
                Tracker.logerror('AutopayeftService', 'getManageEftDetails()', 'Fork Joined policyEftService.getEFTStatus() and ' +
                    ' paymentMobileService.validatePolicy()', 'Error occured in AutopayeftService', error);
                return reject(error);
            });
        });
    };
    AutopayeftService.prototype.getEftStatus = function (policyInfo) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.policyEftService.getEFTStatus(policyInfo.PolicyNumber, policyInfo.MASTER0CO).subscribe(function (eftstatus) {
                // Tracker.log('1. Invoked policyEftService.getEFTStatus :::', eftstatus.eftStatusResult);
                if (eftstatus && eftstatus.eftStatusResult === GlobalConstants.EFT_ENROLLED) {
                    resolve(true);
                }
                else {
                    resolve(false);
                }
            }, function (error) {
                // Tracker.log('Error Occurred in getEftStatus', error);
                return reject(error);
            });
        });
    };
    AutopayeftService.prototype.isEFTEligible = function (basicPolicyInfo, eftType) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var policyEffDate = formatDate(basicPolicyInfo.policyInfo.PolicyEffectiveDate, 'MM/dd/yyyy', 'en-US');
            _this.policyEftService.eftIsEligible(basicPolicyInfo.policyInfo.PolicyNumber, basicPolicyInfo.policyInfo.MASTER0CO, policyEffDate, eftType)
                .subscribe(function (res) {
                // Tracker.log('1. Invoked policyEftService.eftIsEligible :::', res);
                var eftInfo = new EFTInfo();
                if (res && res.success) {
                    eftInfo.isEligible = true;
                    eftInfo.eftType = eftType;
                    if (eftType === GlobalConstants.EFT_TYPE_TERMINATE) {
                        _this.policyEftService.getCurrentEFT(basicPolicyInfo.policyInfo.PolicyNumber, basicPolicyInfo.policyInfo.MASTER0CO)
                            .subscribe(function (eftres) {
                            var accType = eftres.eftStatusResult.substr(4);
                            var accountNumber = eftres.eftStatusResult.substr(0, 4);
                            if (accType === GlobalConstants.EFT_ACC_TYPE_CHK || accType === GlobalConstants.EFT_ACC_TYPE_SAV) {
                                _this.policyEftService.getCurrentEFTInfo(basicPolicyInfo.policyInfo.PolicyNumber, basicPolicyInfo.policyInfo.MASTER0CO)
                                    .subscribe(function (eftinfores) {
                                    if (eftinfores.bankBilling && eftinfores.bankBilling.bank)
                                        eftInfo.routingNumber = eftinfores.bankBilling.bank.routingNumber;
                                    eftInfo.accountType = accType;
                                    eftInfo.accountNum = accountNumber;
                                    basicPolicyInfo.eftInfo = eftInfo;
                                    _this.storeService.updatePolicyInfo(basicPolicyInfo);
                                    resolve(true);
                                });
                            }
                            else {
                                eftInfo.accountType = accType;
                                eftInfo.accountNum = accountNumber;
                                basicPolicyInfo.eftInfo = eftInfo;
                                _this.storeService.updatePolicyInfo(basicPolicyInfo);
                                resolve(true);
                            }
                        });
                    }
                    else {
                        basicPolicyInfo.eftInfo = eftInfo;
                        _this.storeService.updatePolicyInfo(basicPolicyInfo);
                        resolve(true);
                    }
                }
                else {
                    eftInfo.isEligible = false;
                    basicPolicyInfo.eftInfo = eftInfo;
                    _this.storeService.updatePolicyInfo(basicPolicyInfo);
                    return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'S01', 'errordesc': res.errorMsg, 'source': 'PolicyEFTService' })));
                }
            }, function (error) {
                // Tracker.log('Error Occurred in isEFTEligible', error);
                return reject(error);
            });
        });
    };
    AutopayeftService.prototype.authorizeCCorDCTransaction = function (ccformdata, cardtype) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.ivrpaymentService.validateCardNumber(ccformdata.ccNumber).subscribe(function (validcardres) {
                // Tracker.log('1. Invoked ivrpaymentService.validateCardNumber :::', validcardres);
                if (validcardres && validcardres.status.statusCd === '0') {
                    var policyInfo_1 = _this.storeService.getPolicyInfoFromStore();
                    _this.dbcardPaymentService.processCardInstlmntPayment(ccformdata, policyInfo_1, cardtype)
                        .subscribe(function (processRes) {
                        // Tracker.log('2. Invoked dbcardPaymentService.processCardInstlmntPayment :::', processRes.responseCode);
                        if (processRes && processRes.eftProcessedAs) {
                            if (processRes.eftProcessedAs.toUpperCase() === 'CREDIT') {
                                policyInfo_1.eftInfo.accountType = GlobalConstants.EFT_ACC_TYPE_CC;
                            }
                            else {
                                policyInfo_1.eftInfo.accountType = GlobalConstants.EFT_ACC_TYPE_DC;
                            }
                            policyInfo_1.eftInfo.accountNum = 'XXXX'.concat(CommonUtil.formatCC(ccformdata.ccNumber).substr(12));
                            policyInfo_1.eftInfo.routingNumber = ccformdata.ccExp;
                            _this.storeService.updatePolicyInfo(policyInfo_1);
                            resolve(true);
                        }
                        else {
                            // Tracker.log('I came to else bloack and errored');
                            return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'S01', 'errordesc': processRes.responseText, 'source': 'DebitCardPaymentService' })));
                        }
                    });
                }
                else {
                    return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'S01', 'errordesc': 'Invalid Card', 'source': 'PolicyEFTService' })));
                }
            }, function (error) {
                // // Tracker.log('Error Occurred in validateCardNumber', error);
                return reject(error);
            });
        });
    };
    AutopayeftService.prototype.bankDetails = function (rountingnumber) {
        return this.paymentMobileService.getBankDetails(rountingnumber);
    };
    AutopayeftService.prototype.getCurrentEFT = function (policynumber, mco) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.policyEftService.getCurrentEFT(policynumber, mco).subscribe(function (eftres) {
                if (eftres) {
                    resolve(eftres);
                }
            }, function (error) {
                // Tracker.log('Error Occurred in getCurrentEFT', error);
                return reject(error);
            });
        });
    };
    AutopayeftService.prototype.prepareEFT = function (fullpolicyInfo) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.policyEftService.prepareEFT(fullpolicyInfo)
                .subscribe(function (eftreturnres) {
                // Tracker.log('1. Invoked policyEftService.prepareEFT :::', eftreturnres);
                if (eftreturnres && eftreturnres.success === true) {
                    if (fullpolicyInfo.eftInfo && fullpolicyInfo.eftInfo.eftType === GlobalConstants.EFT_TYPE_UPDATE) {
                        _this.bwqueriesService.getPolicyFee(fullpolicyInfo.policyInfo.PolicyNumber).subscribe(function (polfeeres) {
                            if (polfeeres) {
                                eftreturnres.policyFee = polfeeres.policyFee;
                                resolve(eftreturnres);
                            }
                            else {
                                resolve(eftreturnres);
                            }
                        });
                    }
                    else {
                        resolve(eftreturnres);
                    }
                }
                else {
                    return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'S01', 'errordesc': eftreturnres.errorMsg, 'source': 'PolicyEFTService' })));
                }
            }, function (error) {
                // Tracker.log('Error Occurred in prepareEFT', error);
                return reject(error);
            });
        });
    };
    AutopayeftService.prototype.voidEFT = function (policyInfo) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.policyEftService.voidEFT(policyInfo)
                .subscribe(function (eftreturnres) {
                // Tracker.log('1. Invoked policyEftService.voidEFT :::', eftreturnres);
                if (eftreturnres) {
                    resolve(eftreturnres);
                }
            }, function (error) {
                // Tracker.log('Error Occurred in prepareEFT', error);
                return reject(error);
            });
        });
    };
    AutopayeftService.prototype.addEFT = function (policyInfo) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.policyEftService.addEFT(policyInfo)
                .subscribe(function (eftreturnres) {
                // Tracker.log('1. Invoked policyEftService.addEFT :::', eftreturnres);
                if (eftreturnres && eftreturnres.success) {
                    resolve(eftreturnres);
                }
                else {
                    return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'S01', 'errordesc': eftreturnres.errorMsg, 'source': 'PolicyEFTService' })));
                }
            }, function (error) {
                // Tracker.log('Error Occurred in addEFT', error);
                return reject(error);
            });
        });
    };
    AutopayeftService.prototype.updateEFT = function (policyInfo) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.policyEftService.updateEFT(policyInfo)
                .subscribe(function (eftreturnres) {
                // Tracker.log('1. Invoked policyEftService.updateEFT :::', eftreturnres);
                if (eftreturnres && eftreturnres.success) {
                    resolve(eftreturnres);
                }
                else {
                    return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'S01', 'errordesc': eftreturnres.errorMsg, 'source': 'PolicyEFTService' })));
                }
            }, function (error) {
                // Tracker.log('Error Occurred in updateEFT', error);
                return reject(error);
            });
        });
    };
    AutopayeftService.prototype.terminateEFT = function (policyInfo) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.policyEftService.terminateEFT(policyInfo).subscribe(function (eftreturnres) {
                // Tracker.log('1. Invoked policyEftService.terminateEFT :::', eftreturnres);
                if (eftreturnres && eftreturnres.success) {
                    resolve(eftreturnres);
                }
                else {
                    return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'S01', 'errordesc': eftreturnres.errorMsg, 'source': 'PolicyEFTService' })));
                }
            }, function (error) {
                // Tracker.log('Error Occurred in Terminate EFT', error);
                return reject(error);
            });
        });
    };
    AutopayeftService.prototype.addDairyComment = function (fullpolicyInfo) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var policyInfo = fullpolicyInfo.policyInfo;
            var eftInfo = fullpolicyInfo.eftInfo;
            var comment = '';
            if (eftInfo.eftType === GlobalConstants.EFT_TYPE_ADD) {
                comment = 'BWM: Policy enrolled in EFT ' + eftInfo.accountType + '. Confirmation No. ' + eftInfo.confirmationNumber;
            }
            else if (eftInfo.eftType === GlobalConstants.EFT_TYPE_UPDATE) {
                comment = 'BWM: EFT account changed to ' + eftInfo.accountType + '. Confirmation No. ' + eftInfo.confirmationNumber;
            }
            else if (eftInfo.eftType === GlobalConstants.EFT_TYPE_TERMINATE) {
                comment = 'BWM: EFT Terminated. Confirmation No. ' + eftInfo.confirmationNumber;
            }
            else {
                comment = 'BWM: Policy enrolled in EFT ' + eftInfo.accountType + '. Confirmation No. ' + eftInfo.confirmationNumber;
            }
            _this.policyMgmgtService.addComment(policyInfo.PolicyNumber, policyInfo.MASTER0CO, comment, CommonUtil.removewhitespaces(policyInfo.PolicyName)).subscribe(function (plcymgmtres) {
                if (plcymgmtres) {
                    resolve(plcymgmtres);
                }
            }, function (error) {
                // Tracker.log('Error Occurred in Add Comment', error);
                return reject(error);
            });
        });
    };
    AutopayeftService.prototype.getStateCds = function () {
        return this.stateCdService.getStateCds();
    };
    AutopayeftService.ngInjectableDef = i0.defineInjectable({ factory: function AutopayeftService_Factory() { return new AutopayeftService(i0.inject(i1.IvrpaymentService), i0.inject(i2.PolicyeftService), i0.inject(i3.PaymentService), i0.inject(i4.DbcardpaymentService), i0.inject(i5.BwqueriesService), i0.inject(i6.PolicymgtService), i0.inject(i7.StatecdsService), i0.inject(i8.BwstoreService), i0.inject(i9.BWErrorhandler)); }, token: AutopayeftService, providedIn: "root" });
    return AutopayeftService;
}());
export { AutopayeftService };
