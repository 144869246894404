
export class CreateEnvelopeReq {

  policycontext: string;
  mco: string;
  symbol: string;
  policynum: string;
  module: string;
  email: string;
  fullname: string;
  accesscode: string;
  srcsystem: string;

  constructor() {}
}
