/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./credit-card-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./credit-card-info.component";
var styles_CreditCardInfoComponent = [i0.styles];
var RenderType_CreditCardInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CreditCardInfoComponent, data: {} });
export { RenderType_CreditCardInfoComponent as RenderType_CreditCardInfoComponent };
function View_CreditCardInfoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "autoPay"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Auto Pay!"]))], null, null); }
function View_CreditCardInfoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [["class", "bank-account-icon"]], [[1, "aria-label", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["account_balance"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [], [[1, "aria-label", 0]], null, null, null, null)), (_l()(), i1.ɵted(6, null, ["\u00A0\u2022\u2022\u2022\u2022\u00A0", ""])), i1.ɵpid(0, i4.SlicePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreditCardInfoComponent_2)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_4 = _co.paymentMethodInfo.isDefault; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imgAriaLabel; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.accountInfoAriaLabel; _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.paymentMethodInfo.accountNumber, (0 - 4))); _ck(_v, 6, 0, currVal_3); }); }
function View_CreditCardInfoComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], [[1, "aria-label", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\u00A0| Exp ", "/", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cardInfoAriaLabel; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.paymentMethodInfo.expiryMonth; var currVal_2 = _co.paymentMethodInfo.expiryYear; _ck(_v, 1, 0, currVal_1, currVal_2); }); }
function View_CreditCardInfoComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "autoPay"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Auto Pay!"]))], null, null); }
function View_CreditCardInfoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["aria-label", "ending with"], ["class", "redacted-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0\u2022\u2022\u2022\u2022\u00A0"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(0, i4.SlicePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreditCardInfoComponent_4)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreditCardInfoComponent_5)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.showExpDate; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.paymentMethodInfo.isDefault; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imgSrcAttr; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.imgAriaLabel, ""); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.paymentMethodInfo.accountNumber, (0 - 4))); _ck(_v, 5, 0, currVal_2); }); }
export function View_CreditCardInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "info-container d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreditCardInfoComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreditCardInfoComponent_3)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isBankAccount; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isCard; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_CreditCardInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-credit-card-info", [], null, null, null, View_CreditCardInfoComponent_0, RenderType_CreditCardInfoComponent)), i1.ɵdid(1, 114688, null, 0, i5.CreditCardInfoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreditCardInfoComponentNgFactory = i1.ɵccf("app-credit-card-info", i5.CreditCardInfoComponent, View_CreditCardInfoComponent_Host_0, { paymentMethodInfo: "paymentMethodInfo", showExpDate: "showExpDate" }, {}, []);
export { CreditCardInfoComponentNgFactory as CreditCardInfoComponentNgFactory };
