import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../../../shared/services/analytics/google-analytics.service';
import { UsmsecurityService } from '../../../services/api/usmsecurity/usmsecurity.service';
import { BwstoreService } from '../../services/bwstore.service';
import { Tracker } from '../../utils/tracker';
import { environment } from '../../../../environments/environment';
import { CommonUtil } from '../../../shared/utils/commonutil';
import { AuthenticationService } from '../../services/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FeedbackComponent } from '../../../components/feedback/feedback.component';
var FooterComponent = /** @class */ (function () {
    function FooterComponent(router, gaService, usmSecurityService, authService, _storeService, modalService) {
        this.router = router;
        this.gaService = gaService;
        this.usmSecurityService = usmSecurityService;
        this.authService = authService;
        this._storeService = _storeService;
        this.modalService = modalService;
        this.isFeedbackClicked = false;
    }
    FooterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.privacyCenterURL = "" + environment.ccpaPrivacyCenter;
        this.dontSellUrl = "" + environment.ccpaDontSell;
        this.mobileView = CommonUtil.findDeviceVIew().isMobileDevice();
        // enable GA then track the page views
        this.gaService.enableGA().then(function (res) {
            if (res) {
                // Tracker.log('Google Analytics enabled ', res);
                // subscribe to the google analytics
                _this.gaService.subscribe();
            }
        }).catch(function (error) {
            Tracker.logerror('AppComponent', 'ngOnInit()', 'Enabling GA Tracking', 'Error Enabling GA Tracking', error);
        });
        this.authService.isUserLoggedIn.subscribe(function (authenticated) {
            _this.isUserLoggedIn = authenticated;
        });
    };
    FooterComponent.prototype.showFeedback = function () {
        // const modalRef = this.modalService.open(FeedbackComponent);
        var _this = this;
        this.modalService.open(FeedbackComponent).result.then(function (result) {
            // console.log(`Closed with: ${result}`);
        }, function (reason) {
            if ("" + reason === 'Submit') {
                _this.isFeedbackClicked = true;
            }
            // console.log(`Dismissed ${reason}`);
        });
    };
    FooterComponent.prototype.addTag = function (linkName) {
        if (linkName === 'aboutUs') {
            this.gaService.trackEvent('About Us', 'About Us', 'AboutUsLinkClick', 200);
        }
        else if (linkName === 'contact') {
            this.gaService.trackEvent('Contact Us', 'Contact Us', 'ContactUsLinkClick', 200);
        }
        else if (linkName === 'terms') {
            this.gaService.trackEvent('Terms and Conditions', 'Terms and Conditions Page', 'TermsAndConditionsLinkClick', 200);
        }
        else if (linkName === 'careers') {
            this.gaService.trackEvent('Careers', 'Careers', 'CareersClick', 200);
        }
        else if (linkName === 'faq') {
            this.gaService.trackEvent('FAQ', 'FAQ', 'FAQLinkClick', 200);
        }
        else if (linkName === 'sitemap') {
            this.gaService.trackEvent('Sitemap', 'Sitemap', 'SitemapLinkClick', 200);
        }
    };
    return FooterComponent;
}());
export { FooterComponent };
