var CheckEachAnswerRequest = /** @class */ (function () {
    function CheckEachAnswerRequest(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return CheckEachAnswerRequest;
}());
export { CheckEachAnswerRequest };
var CheckEachAnswerResponse = /** @class */ (function () {
    function CheckEachAnswerResponse(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return CheckEachAnswerResponse;
}());
export { CheckEachAnswerResponse };
