import { HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { GlobalConstants } from '../../../constants/global.constant';
import { Dairycommentreq } from '../../../shared/model/api/policymgmt/dairycommentreq.model';
import { Policynumber } from '../../../shared/model/policynumber';
import { Tracker } from '../../../shared/utils/tracker';
import { BwappService } from '../../bwapp.service';
import { Policymgmtreq } from '../../../shared/model/api/policymgmt/policymgmtreq.model';
import { Policymgmtexstreamreq } from '../../../shared/model/api/policymgmt/policymgmtexstreamreq.model';
import * as i0 from "@angular/core";
import * as i1 from "../../bwapp.service";
var PolicymgtService = /** @class */ (function () {
    function PolicymgtService(appService) {
        this.appService = appService;
        this.globalConstants = GlobalConstants;
    }
    PolicymgtService.prototype.createRTAForm = function (rtaFormreq) {
        return this.appService.post(GlobalConstants.CREATE_RTAFORM, rtaFormreq);
    };
    PolicymgtService.prototype.getStatePaperDocFee = function (state, mco, ratebook, lob) {
        var params = new HttpParams()
            .set('state', state)
            .set('mco', mco)
            .set('rateBook', ratebook)
            .set('lob', lob);
        return this.appService.get(this.globalConstants.GET_STATEPAPERDOCFEE, params);
    };
    PolicymgtService.prototype.addComment = function (policynumber, mco, eftdairycomment, userId) {
        this.policyNumber = new Policynumber(policynumber);
        this.dairyCommentReq = new Dairycommentreq();
        this.dairyCommentReq.policyID = this.policyNumber.toString();
        this.dairyCommentReq.mco = mco;
        this.dairyCommentReq.appName = this.globalConstants.APPID;
        this.dairyCommentReq.userID = userId;
        this.dairyCommentReq.comment = eftdairycomment;
        this.dairyCommentReq.activityType = '';
        this.dairyCommentReq.reasonCode = 'PC';
        this.dairyCommentReq.destination = 'RECYCLE';
        this.dairyCommentReq.printByte = '';
        this.dairyCommentReq.prodContext = environment.pointContext;
        this.dairyCommentReq.endorseContext = environment.pointContext;
        return this.appService.post(this.globalConstants.PLCY_MGMT_ADD_COMMENT, this.dairyCommentReq);
    };
    PolicymgtService.prototype.sendPolicyMail = function (policyMailReq) {
        Tracker.loginfo('PolicymgtService', 'sendPolicyMail', '', 'Sending policy mail for ' +
            policyMailReq.symbol.concat(policyMailReq.policy).concat(policyMailReq.mod));
        return this.appService.post(this.globalConstants.PLCY_MGMT_POLICY_MAIL, policyMailReq);
    };
    PolicymgtService.prototype.authForIDCard = function (authforidCardreq) {
        Tracker.loginfo('PolicymgtService', 'authForIDCard', '', 'Verifying policy for ' +
            authforidCardreq.policySymbol.concat(authforidCardreq.policyNumber));
        return this.appService.post(this.globalConstants.PLCY_MGMT_AUTH_FOR_IDCARD, authforidCardreq);
    };
    PolicymgtService.prototype.createWebSessionLogRecord = function (webSessLogReq) {
        Tracker.loginfo('PolicymgtService', 'createWebSessionLogRecord', '', 'createWebSessionLogRecord for ' +
            webSessLogReq.policyNumber);
        return this.appService.post(this.globalConstants.PLCY_MGMT_CRT_WEBSSN_LOG, webSessLogReq);
    };
    PolicymgtService.prototype.requestCancel = function (policyID, cancelDate, mco, riskState, isMailingAddressChanged, isShortRateState, addressLine1, city, state, zip) {
        this.policyMgmtReq = new Policymgmtreq();
        this.policyMgmtReq.appName = this.globalConstants.APPID;
        this.policyMgmtReq.policyID = policyID;
        this.policyMgmtReq.cancelDate = cancelDate;
        this.policyMgmtReq.mco = mco;
        this.policyMgmtReq.isMailingAddressChanged = isMailingAddressChanged;
        this.policyMgmtReq.riskState = riskState;
        if (isShortRateState) {
            this.policyMgmtReq.cancelReason = 'SIR';
        }
        else {
            this.policyMgmtReq.cancelReason = 'PIR';
        }
        this.policyMgmtReq.addressLine1 = addressLine1;
        this.policyMgmtReq.city = city;
        this.policyMgmtReq.state = state;
        this.policyMgmtReq.zip = zip;
        return this.appService.post(this.globalConstants.PLCY_MGMT_REQUEST_CANCEL, this.policyMgmtReq);
    };
    PolicymgtService.prototype.createExstreamRecordPostCancellation = function (mco, policynumber, cancellationDttmstmp, cancelAmount, confirmationNumber, eftIndicator, renewalIndicator) {
        this.policyNumber = new Policynumber(policynumber);
        this.policymgmtexstreamreq = new Policymgmtexstreamreq();
        this.policymgmtexstreamreq.appName = this.globalConstants.APPID;
        this.policymgmtexstreamreq.mco = mco;
        this.policymgmtexstreamreq.symbol = this.policyNumber.symbol;
        this.policymgmtexstreamreq.policyNumber = this.policyNumber.policy;
        this.policymgmtexstreamreq.module = this.policyNumber.module;
        this.policymgmtexstreamreq.cancelAmount = cancelAmount;
        this.policymgmtexstreamreq.cancellationDttmstmp = cancellationDttmstmp;
        this.policymgmtexstreamreq.confirmationNumber = confirmationNumber;
        this.policymgmtexstreamreq.eftIndicator = eftIndicator;
        this.policymgmtexstreamreq.renewalIndicator = renewalIndicator;
        return this.appService.post(this.globalConstants.PLCY_MGMT_EXSTREAM_RECORD, this.policymgmtexstreamreq);
    };
    PolicymgtService.prototype.endorsePolicy = function (endorsementReq) {
        Tracker.loginfo('PolicymgtService', 'endorsePolicy', '', 'Endorsing policy for ' +
            endorsementReq.policyID);
        return this.appService.post(this.globalConstants.PLCY_MGMT_ENDORSE_POLICY, endorsementReq);
    };
    PolicymgtService.prototype.endorseVoid = function (endorsementReq) {
        Tracker.loginfo('PolicymgtService', 'endorseVoid', '', 'Endorsing policy for ' +
            endorsementReq.policyID);
        return this.appService.post(this.globalConstants.PLCY_MGMT_ENDORSE_VOID, endorsementReq);
    };
    PolicymgtService.prototype.isEligibleForEndorsement = function (endorsementReq) {
        Tracker.loginfo('PolicymgtService', 'isEligibleForEndorsement', '', 'IsisEligibleForEndorsement for the policy  ' +
            endorsementReq.policyID);
        return this.appService.post(this.globalConstants.PLCY_MGMT_IS_ELIGIBLE_FOR_ENDORSEMENT, endorsementReq);
    };
    PolicymgtService.ngInjectableDef = i0.defineInjectable({ factory: function PolicymgtService_Factory() { return new PolicymgtService(i0.inject(i1.BwappService)); }, token: PolicymgtService, providedIn: "root" });
    return PolicymgtService;
}());
export { PolicymgtService };
