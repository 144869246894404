import { EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FindAgentsService } from '../../../services/api/find-agents/find-agents.service';
import { CommonUtil } from '../../utils/commonutil';
import { Tracker } from '../../utils/tracker';
import { MessageConstants } from '../../../constants/message.constant';
import { NgcCookieConsentService } from "ngx-cookieconsent";
var FindAgentComponent = /** @class */ (function () {
    function FindAgentComponent(agentService, fb, route, router, ccService) {
        var _this = this;
        this.agentService = agentService;
        this.fb = fb;
        this.route = route;
        this.router = router;
        this.ccService = ccService;
        this.errorMessage = '';
        this.zip = '';
        this.usermessage = MessageConstants;
        this.messageEvent2 = new EventEmitter();
        this.isLoading = false;
        // this.route.params.subscribe( params => this.doSearch(params['term']));
        this.route.params.subscribe(function (params) {
            if (params['zip']) {
                _this.zip = params['zip'];
            }
        });
        this.createForm();
        if (this.zip !== '') {
            this.onSubmit(this.agentSearchForm);
        }
    }
    FindAgentComponent.prototype.ngOnInit = function () {
    };
    FindAgentComponent.prototype.onSubmit = function (agentForm) {
        var _this = this;
        this.formSubmitAttempt = true;
        this.messageEvent2.emit(null);
        if (agentForm.zipcode === undefined || agentForm.zipcode === '') {
            agentForm.zipcode = this.zip;
        }
        this.isLoading = true;
        this.errorMessage = '';
        if (this.router.url.indexOf('/find-agent-broker') === -1) {
            this.router.navigate(['/find-agent-broker/', agentForm.zipcode]);
        }
        if (this.agentSearchForm.valid) {
            this.agentService.findAgents(agentForm.zipcode)
                .subscribe(function (res) {
                _this.findagentres = res;
                var cnt = 0;
                if (!CommonUtil.isEmpty(_this.findagentres.AgentList)) {
                    _this.messageEvent2.emit(_this.findagentres.AgentList);
                }
                else {
                    _this.errorMessage = 'Unable to retrieve the Agents near you at this time.';
                }
                _this.isLoading = false;
            }, function (error) {
                _this.errorMessage = 'Unable to retrieve the Agents near you at this time.';
                _this.isLoading = false;
                Tracker.logerror('FindAgentComponent', 'onSubmit', 'findAgents', 'Error searching for agent in zip ' + agentForm.zipcode, error);
            });
        }
        else {
            this.isLoading = false;
            console.log('Not Valid');
        }
    };
    /**
      * Create the form for this component
      */
    FindAgentComponent.prototype.createForm = function () {
        this.agentSearchForm =
            this.fb.group({ 'zipcode': [(this.zip !== '' ? this.zip : ''), Validators.compose([Validators.required])]
            });
    };
    // Form Utilities
    FindAgentComponent.prototype.isFieldValid = function (field) {
        return ((!this.agentSearchForm.get(field).valid && this.agentSearchForm.get(field).touched) ||
            (this.agentSearchForm.get(field).untouched && this.formSubmitAttempt));
    };
    FindAgentComponent.prototype.getZipCodeErrMsg = function (errors) {
        // console.log(errors.minLength);
        if (errors) {
            if (errors.required === true) {
                // console.log(this.usermessage.REQUIRED_ZIPCODE);
                return this.usermessage.REQUIRED_ZIPCODE;
            }
            else if (errors.minLength) {
                // console.log(this.usermessage.LENGTH_ZIPCODE);
                return this.usermessage.LENGTH_ZIPCODE;
            }
        }
    };
    return FindAgentComponent;
}());
export { FindAgentComponent };
