import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { GlobalConstants } from '../../constants/global.constant';
import { EFTInfo, FullPolicyDetails, PolicyInfo } from '../../shared/model/api/bwibic/fullpolicydetails.model';
import { Policyfeeres } from '../../shared/model/api/bwquery/policyfeeres.model';
import { Processcardres } from '../../shared/model/api/dbcardpayment/processcardres.model';
import { Validatecardres } from '../../shared/model/api/ivrpayment/validatecardres.model';
import { Bankdetailsres } from '../../shared/model/api/payment/bankdetailsres.model';
import { Paymentdetailsres } from '../../shared/model/api/payment/paymentdetailsres';
import { Currenteftinfores,Currenteftres } from '../../shared/model/api/policyeft/currenteftres.model';
import { Eftreturnres } from '../../shared/model/api/policyeft/eftreturnres.model';
import { Eftstatus } from '../../shared/model/api/policyeft/eftstatus.model';
import { Policymgmtres } from '../../shared/model/api/policymgmt/policymgmtres.model';
import { BWError } from '../../shared/model/error.model';
import { StateCds } from '../../shared/model/state-cds.model';
import { BwstoreService } from '../../shared/services/bwstore.service';
import { StatecdsService } from '../../shared/services/statecds.service';
import { CommonUtil } from '../../shared/utils/commonutil';
import { Tracker } from '../../shared/utils/tracker';
import { BwqueriesService } from '../api/bwquery/bwqueries.service';
import { DbcardpaymentService } from '../api/dbcardpayment/dbcardpayment.service';
import { IvrpaymentService } from '../api/ivrpayment/ivrpayment.service';
import { PaymentService } from '../api/payment/payment.service';
import { PolicyeftService } from '../api/policyeft/policyeft.service';
import { PolicymgtService } from '../api/policymgmt/policymgt.service';
import { BWErrorhandler } from '../errorhandler';


@Injectable({
  providedIn: 'root'
})
export class AutopayeftService {

  constructor(private ivrpaymentService: IvrpaymentService, private policyEftService: PolicyeftService,
    private paymentMobileService: PaymentService, private dbcardPaymentService: DbcardpaymentService,
    private bwqueriesService: BwqueriesService, private policyMgmgtService: PolicymgtService,
    private stateCdService: StatecdsService,
    private storeService: BwstoreService, private errorHandler: BWErrorhandler) {

    }

    getManageEftDetails(policyInfo: PolicyInfo): Promise<Paymentdetailsres> {
      return new Promise((resolve, reject) => {
        forkJoin(this.policyEftService.getEFTStatus(policyInfo.PolicyNumber, policyInfo.MASTER0CO),
        this.paymentMobileService.validatePolicy(policyInfo.PolicyZip, policyInfo.SYMBOL, policyInfo.POLICY0NUM))
        .subscribe(([eftStatus, paymentDetailsRes]) => {
          Tracker.loginfo('AutopayeftService', 'getManageEftDetails()', 'Fork Joined policyEftService.getEFTStatus() and ' +
                                ' paymentMobileService.validatePolicy()', '' + eftStatus);
          if (eftStatus && paymentDetailsRes) {
            if (eftStatus.eftStatusResult === GlobalConstants.EFT_ENROLLED
                || eftStatus.eftStatusResult === GlobalConstants.EFT_PENDING_ACTIVATION
                || eftStatus.eftStatusResult === GlobalConstants.EFT_PENDING_UPDATE) {
              paymentDetailsRes.eftIndicator = true;
            } else {
              paymentDetailsRes.eftIndicator = false;
            }
            resolve(paymentDetailsRes);
          } else {
            return reject(this.errorHandler.handleApplicationError(new BWError(
              {'errorcode': 'S01', 'errordesc': 'Error reading details from Service', 'source': 'PaymentsMobileService'})));
          }
        },
        (error) => {
          Tracker.logerror('AutopayeftService', 'getManageEftDetails()', 'Fork Joined policyEftService.getEFTStatus() and ' +
          ' paymentMobileService.validatePolicy()', 'Error occured in AutopayeftService', error);
          return reject(error);
        });
      });
    }

    getEftStatus(policyInfo: PolicyInfo): Promise<boolean> {
      return new Promise((resolve, reject) => {
        this.policyEftService.getEFTStatus(policyInfo.PolicyNumber, policyInfo.MASTER0CO).subscribe((eftstatus: Eftstatus) => {
          // Tracker.log('1. Invoked policyEftService.getEFTStatus :::', eftstatus.eftStatusResult);
          if (eftstatus && eftstatus.eftStatusResult === GlobalConstants.EFT_ENROLLED) {
            resolve(true);
          } else {
            resolve(false);
          }
        },
        (error) => {
          // Tracker.log('Error Occurred in getEftStatus', error);
          return reject(error);
        });

      });

    }


    isEFTEligible(basicPolicyInfo: FullPolicyDetails, eftType: string): Promise<boolean> {

      return new Promise((resolve, reject) => {
        const policyEffDate = formatDate(basicPolicyInfo.policyInfo.PolicyEffectiveDate, 'MM/dd/yyyy', 'en-US');
        this.policyEftService.eftIsEligible(basicPolicyInfo.policyInfo.PolicyNumber, basicPolicyInfo.policyInfo.MASTER0CO,
                                                        policyEffDate, eftType)
        .subscribe((res: Eftreturnres) => {
          // Tracker.log('1. Invoked policyEftService.eftIsEligible :::', res);
          const eftInfo = new EFTInfo();
          if (res && res.success) {

            eftInfo.isEligible = true;
            eftInfo.eftType = eftType;
            if(eftType===GlobalConstants.EFT_TYPE_TERMINATE)
            {
              this.policyEftService.getCurrentEFT(basicPolicyInfo.policyInfo.PolicyNumber,basicPolicyInfo.policyInfo.MASTER0CO)
              .subscribe((eftres: Currenteftres) => 
              {              
                const accType = eftres.eftStatusResult.substr(4);
                const accountNumber = eftres.eftStatusResult.substr(0, 4);
                if(accType===GlobalConstants.EFT_ACC_TYPE_CHK || accType===GlobalConstants.EFT_ACC_TYPE_SAV) 
                {
                  this.policyEftService.getCurrentEFTInfo(basicPolicyInfo.policyInfo.PolicyNumber,basicPolicyInfo.policyInfo.MASTER0CO)
                  .subscribe((eftinfores: Currenteftinfores) => 
                  {
                    if(eftinfores.bankBilling && eftinfores.bankBilling.bank)
                        eftInfo.routingNumber=eftinfores.bankBilling.bank.routingNumber;
                    
                    eftInfo.accountType=accType;
                    eftInfo.accountNum=accountNumber;
                
                    basicPolicyInfo.eftInfo = eftInfo;
                    this.storeService.updatePolicyInfo(basicPolicyInfo);
                    resolve(true);
                  });
                }
                else
                { 
                  eftInfo.accountType=accType;
                  eftInfo.accountNum=accountNumber;
                  basicPolicyInfo.eftInfo = eftInfo;
                  this.storeService.updatePolicyInfo(basicPolicyInfo);
                  resolve(true);
                }
              } );
            }
            else
            {
                  basicPolicyInfo.eftInfo = eftInfo;
                  this.storeService.updatePolicyInfo(basicPolicyInfo);
                  resolve(true);
            }
          } else {
            eftInfo.isEligible = false;
            basicPolicyInfo.eftInfo = eftInfo;
            this.storeService.updatePolicyInfo(basicPolicyInfo);
            return reject(this.errorHandler.handleApplicationError(new BWError(
              {'errorcode': 'S01', 'errordesc': res.errorMsg, 'source': 'PolicyEFTService'})));
          }
        },
        (error) => {
          // Tracker.log('Error Occurred in isEFTEligible', error);
          return reject(error);
        });
      });

    }

    authorizeCCorDCTransaction(ccformdata: any, cardtype: string): Promise<boolean> {

      return new Promise((resolve, reject) => {
        this.ivrpaymentService.validateCardNumber(ccformdata.ccNumber).subscribe((validcardres: Validatecardres) => {
          // Tracker.log('1. Invoked ivrpaymentService.validateCardNumber :::', validcardres);
          if (validcardres && validcardres.status.statusCd === '0') {
            const policyInfo = this.storeService.getPolicyInfoFromStore();

            this.dbcardPaymentService.processCardInstlmntPayment(ccformdata, policyInfo, cardtype)
            .subscribe((processRes: Processcardres) => {
              // Tracker.log('2. Invoked dbcardPaymentService.processCardInstlmntPayment :::', processRes.responseCode);
              if (processRes && processRes.eftProcessedAs) {

                if (processRes.eftProcessedAs.toUpperCase() === 'CREDIT') {
                  policyInfo.eftInfo.accountType = GlobalConstants.EFT_ACC_TYPE_CC;
                } else {
                  policyInfo.eftInfo.accountType = GlobalConstants.EFT_ACC_TYPE_DC;
                }

                policyInfo.eftInfo.accountNum = 'XXXX'.concat(CommonUtil.formatCC(ccformdata.ccNumber).substr(12));
                policyInfo.eftInfo.routingNumber = ccformdata.ccExp;
                this.storeService.updatePolicyInfo(policyInfo);

                resolve(true);
              } else {
                // Tracker.log('I came to else bloack and errored');
                return reject(this.errorHandler.handleApplicationError(new BWError(
                  {'errorcode': 'S01', 'errordesc': processRes.responseText, 'source': 'DebitCardPaymentService'})));
              }
           });
          } else {
            return reject(this.errorHandler.handleApplicationError(new BWError(
              {'errorcode': 'S01', 'errordesc': 'Invalid Card', 'source': 'PolicyEFTService'})));
          }
        },
        (error) => {
          // // Tracker.log('Error Occurred in validateCardNumber', error);
          return reject(error);
        });
      });
    }

    bankDetails(rountingnumber: string): Observable<Bankdetailsres> {
      return this.paymentMobileService.getBankDetails(rountingnumber);
    }

    getCurrentEFT(policynumber: string, mco: string): Promise<Currenteftres>  {
      return new Promise((resolve, reject) => {
        this.policyEftService.getCurrentEFT(policynumber, mco).subscribe((eftres: Currenteftres) => {
          if (eftres) {
            resolve(eftres);
          }
        },
        (error) => {
          // Tracker.log('Error Occurred in getCurrentEFT', error);
          return reject(error);
        });
      });
    }

    prepareEFT(fullpolicyInfo: FullPolicyDetails): Promise<Eftreturnres> {
      return new Promise((resolve, reject) => {
        this.policyEftService.prepareEFT(fullpolicyInfo)
          .subscribe((eftreturnres: Eftreturnres) => {
            // Tracker.log('1. Invoked policyEftService.prepareEFT :::', eftreturnres);
            if (eftreturnres && eftreturnres.success === true) {

              if (fullpolicyInfo.eftInfo && fullpolicyInfo.eftInfo.eftType === GlobalConstants.EFT_TYPE_UPDATE) {
                this.bwqueriesService.getPolicyFee(fullpolicyInfo.policyInfo.PolicyNumber).subscribe((polfeeres: Policyfeeres) => {

                  if (polfeeres) {
                    eftreturnres.policyFee = polfeeres.policyFee;
                    resolve(eftreturnres);
                  } else {
                    resolve(eftreturnres);
                  }
                });
              } else {
                resolve(eftreturnres);
              }
            } else {
              return reject(this.errorHandler.handleApplicationError(new BWError(
                {'errorcode': 'S01', 'errordesc': eftreturnres.errorMsg, 'source': 'PolicyEFTService'})));
            }
          },
          (error) => {
            // Tracker.log('Error Occurred in prepareEFT', error);
            return reject(error);
          });
      });

    }

    voidEFT(policyInfo: FullPolicyDetails): Promise<Eftreturnres> {
      return new Promise((resolve, reject) => {
        this.policyEftService.voidEFT(policyInfo)
          .subscribe((eftreturnres: Eftreturnres) => {
            // Tracker.log('1. Invoked policyEftService.voidEFT :::', eftreturnres);
            if (eftreturnres) {
              resolve(eftreturnres);
            }
          },
          (error) => {
            // Tracker.log('Error Occurred in prepareEFT', error);
            return reject(error);
          });
      });
    }


    addEFT(policyInfo: FullPolicyDetails): Promise<Eftreturnres> {

      return new Promise((resolve, reject) => {
        this.policyEftService.addEFT(policyInfo)
          .subscribe((eftreturnres: Eftreturnres) => {
            // Tracker.log('1. Invoked policyEftService.addEFT :::', eftreturnres);
            if (eftreturnres && eftreturnres.success) {
              
              resolve(eftreturnres);
            } else {
              return reject(this.errorHandler.handleApplicationError(new BWError(
                {'errorcode': 'S01', 'errordesc': eftreturnres.errorMsg, 'source': 'PolicyEFTService'})));
            }
          },
          (error) => {
            // Tracker.log('Error Occurred in addEFT', error);
            return reject(error);
          });
      });

    }

    updateEFT(policyInfo: FullPolicyDetails): Promise<Eftreturnres> {

      return new Promise((resolve, reject) => {
        this.policyEftService.updateEFT(policyInfo)
          .subscribe((eftreturnres: Eftreturnres) => {
            // Tracker.log('1. Invoked policyEftService.updateEFT :::', eftreturnres);
            if (eftreturnres && eftreturnres.success) {
              
              resolve(eftreturnres);
            } else {
              return reject(this.errorHandler.handleApplicationError(new BWError(
                {'errorcode': 'S01', 'errordesc': eftreturnres.errorMsg, 'source': 'PolicyEFTService'})));
            }
          },
          (error) => {
            // Tracker.log('Error Occurred in updateEFT', error);
            return reject(error);
          });
      });

    }

    terminateEFT(policyInfo: FullPolicyDetails): Promise<Eftreturnres> {
      return new Promise((resolve, reject) => {
        this.policyEftService.terminateEFT(policyInfo).subscribe((eftreturnres: Eftreturnres) => {
            // Tracker.log('1. Invoked policyEftService.terminateEFT :::', eftreturnres);
            if (eftreturnres && eftreturnres.success) {
              resolve(eftreturnres);
            } else {
              return reject(this.errorHandler.handleApplicationError(new BWError(
                {'errorcode': 'S01', 'errordesc': eftreturnres.errorMsg, 'source': 'PolicyEFTService'})));
            }
          },
          (error) => {
            // Tracker.log('Error Occurred in Terminate EFT', error);
            return reject(error);
          });
      });

    }

    addDairyComment(fullpolicyInfo: FullPolicyDetails): Promise<Policymgmtres> {

      return new Promise((resolve, reject) => {
        const policyInfo = fullpolicyInfo.policyInfo;
        const eftInfo = fullpolicyInfo.eftInfo;

        let comment = '';

        if (eftInfo.eftType === GlobalConstants.EFT_TYPE_ADD) {
          comment = 'BWM: Policy enrolled in EFT ' + eftInfo.accountType + '. Confirmation No. ' + eftInfo.confirmationNumber ;
        } else if (eftInfo.eftType === GlobalConstants.EFT_TYPE_UPDATE) {
          comment = 'BWM: EFT account changed to ' + eftInfo.accountType + '. Confirmation No. ' + eftInfo.confirmationNumber ;
        } else if (eftInfo.eftType === GlobalConstants.EFT_TYPE_TERMINATE) {
          comment = 'BWM: EFT Terminated. Confirmation No. ' + eftInfo.confirmationNumber ;
        } else {
          comment = 'BWM: Policy enrolled in EFT ' + eftInfo.accountType + '. Confirmation No. ' + eftInfo.confirmationNumber ;
        }

        this.policyMgmgtService.addComment(policyInfo.PolicyNumber, policyInfo.MASTER0CO,
          comment, CommonUtil.removewhitespaces(policyInfo.PolicyName)).subscribe((plcymgmtres: Policymgmtres) => {
            if (plcymgmtres) {
              resolve(plcymgmtres);
            }
          },
          (error) => {
            // Tracker.log('Error Occurred in Add Comment', error);
            return reject(error);
          });
      });
    }

    getStateCds(): Observable<StateCds[]> {
        return this.stateCdService.getStateCds();
    }



}
