import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { GlobalConstants } from '../../../constants/global.constant';
import { MessageConstants } from '../../../constants/message.constant';
import { EndorsementService } from '../../../services/api/endorsement/endorsement.service';
import { EndorsementReq } from '../../model/api/endorsement/endorsement-req';
import { DirectsalesParameters } from '../../model/directsales-parameters';
import { GoogleAnalyticsService } from '../../services/analytics/google-analytics.service';
import { DirectsalesService } from '../../services/directsales.service';
import { MessagesService } from '../../services/messages.service';
import { CommonUtil } from '../../utils/commonutil';
import { Tracker } from '../../utils/tracker';
var DirectsalesComponent = /** @class */ (function () {
    function DirectsalesComponent(http, fb, router, route, _directSalesService, msgService, endorsementService, gaService, datepipe) {
        var _this = this;
        this.http = http;
        this.fb = fb;
        this.router = router;
        this.route = route;
        this._directSalesService = _directSalesService;
        this.msgService = msgService;
        this.endorsementService = endorsementService;
        this.gaService = gaService;
        this.datepipe = datepipe;
        this.errorMessage = '';
        this.usermessage = MessageConstants;
        this.auditReq = new EndorsementReq();
        this.genderValues = ['MALE', 'FEMALE', 'F', 'M'];
        this.createForm();
        // Logic to check if browser refresh
        router.events.subscribe(function (event) {
            if (event instanceof NavigationStart) {
                _this.showBdq = event.url.includes('/externalRedirect') || event.url.includes('zip=') ? false : true;
            }
            if (event instanceof NavigationEnd) {
                _this.showBdq = event.url.includes('/externalRedirect') || event.url.includes('zip=') ? false : true;
            }
        });
    }
    DirectsalesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.retrieveQuoteURL = "" + environment.bdqRetrieveQuoteCenter;
        // Read the query parameters into the DirectsalesParameters object
        this.directsalesParameters = new DirectsalesParameters(this.route.snapshot.queryParams);
        Tracker.loginfo('directsalesComponent', 'onInit', '', 'Incoming BDQ parameters'
            + JSON.stringify(this.directsalesParameters));
        // Set any parameters that have non-alphanumneric values to a blank string
        this.cleanRequestParameters();
        if (this.directsalesParameters.zip) {
            this.directSalesForm = this.fb.group({
                'zipcode': [this.directsalesParameters.zip],
                'lob': ['APV']
            });
            this.onSubmit(this.directSalesForm.value, 'startquote');
        }
        else {
            this.showBdq = true;
            this.msgService.showbwcom.next(true);
        }
        this.msgService.showbwcom.subscribe(function (val) {
            _this.showBdq = val;
        });
    };
    DirectsalesComponent.prototype.createForm = function () {
        this.directSalesForm = this.fb.group({
            'zipcode': ['', Validators.compose([Validators.required])],
            'lob': ['APV', Validators.compose([Validators.required])]
        });
    };
    // TO DO - Review this method and modify according to retrievequote and startquote
    DirectsalesComponent.prototype.onSubmit = function (directSalesForm, featureFlag) {
        var _this = this;
        if (this.directsalesParameters.zip === '') {
            this.directsalesParameters.zip = directSalesForm.zipcode;
        }
        this.directsalesParameters.zip = (this.directsalesParameters.zip.length === 4 ?
            '0'.concat(this.directsalesParameters.zip) : this.directsalesParameters.zip);
        console.log('featureFlag-->' + featureFlag);
        // Log to Google analytics
        if (featureFlag === 'startquote') {
            this.gaService.trackEvent('BDQ', 'GetQuickQuoteScreen', 'ZipButtonClick', 200);
        }
        else {
            this.gaService.trackEvent('BDQ', 'RetrieveAQuoteScreen', 'ZipButtonClick', 200);
        }
        if (this.fromPage === "Home") {
            this.gaService.trackEvent('BDQ', 'HomeScreen', 'StartQuoteButtonFromHome', 200);
        }
        else if (this.fromPage === "autoIns101") {
            this.gaService.trackEvent('BDQ', 'AutoInsurance101Screen', 'StartQuoteButtonFromAutoInsurance101', 200);
        }
        this.formSubmitAttempt = true;
        if (this.directSalesForm.valid) {
            // 1. Check if it is a valid zip and get the state
            this._directSalesService.getStateByZipCode(directSalesForm.zipcode)
                .subscribe(function (res) {
                _this.stateByZipRes = res;
                if (_this.stateByZipRes && _this.stateByZipRes.States !== undefined && _this.stateByZipRes.States !== '') {
                    // Get state and look up URL and Phone Number
                    // 2. Look up the URL and SOURCE from the DirectSalesService
                    try {
                        _this._directSalesService.getSourceDtlsByState(directSalesForm.lob, _this.stateByZipRes.States, directSalesForm.zipcode)
                            .subscribe(function (resp) {
                            if (!CommonUtil.isEmpty(resp)) {
                                _this._directSalesService.getSourceByState(directSalesForm.lob, _this.stateByZipRes.States, directSalesForm.zipcode, resp, _this.directsalesParameters.id)
                                    .subscribe(function (response) {
                                    _this.gaService.gtag_report_conversion();
                                    // Handles routing of request
                                    _this.handleDirectSalesRequest(response[0], featureFlag);
                                    if (response[0].source_ind !== 'BDQ' && response[0].source_ind !== 'MDA') {
                                        _this.auditLogging(response[0].state, _this.auditReq.activityResult, response[0].source_ind, directSalesForm.zipcode);
                                    }
                                }, function (error) {
                                    _this.auditLogging(_this.stateByZipRes.States, 'F', 'Error occured- Vendor Table source', directSalesForm.zipcode);
                                    _this.errorMessage = MessageConstants.UNABLE_TO_QUOTE;
                                    Tracker.logerror('DirectsalesComponent', 'onSubmit', 'getSourceByState', 'Error searching for zip ' + directSalesForm.zipcode, error);
                                });
                            }
                            else {
                                _this.auditLogging('XX', 'F', 'Response empty-Directsalesvendor data', directSalesForm.zipcode);
                                _this.router.navigate(['sorry']);
                            }
                        }, function (error) {
                            _this.auditLogging('XX', 'F', 'Error occured-Directsalesvendor data', directSalesForm.zipcode);
                            _this.errorMessage = MessageConstants.UNABLE_TO_QUOTE;
                            Tracker.logerror('DirectsalesComponent', 'onSubmit', 'getSourceByState', 'Error searching for zip ' + directSalesForm.zipcode, error);
                        });
                    }
                    catch (e) {
                        _this.auditLogging('XX', 'F', 'Error occured- after State retrieval', directSalesForm.zipcode);
                        Tracker.logerror('DirectsalesComponent', 'onSubmit', 'getSourceByState', 'Error searching for zip ' + directSalesForm.zipcode, e);
                    }
                }
                else {
                    _this.auditLogging('XX', 'F', 'State is empty', directSalesForm.zipcode);
                    _this.errorMessage = MessageConstants.UNABLE_TO_QUOTE;
                    _this.msgService.showbwcom.next(true);
                }
            }, function (error) {
                _this.auditLogging('XX', 'F', 'Error- Get state by zipcode', directSalesForm.zipcode);
                _this.errorMessage = MessageConstants.UNABLE_TO_QUOTE;
                Tracker.logerror('DirectsalesComponent', 'onSubmit', 'getStateByZipCode', 'Error retrieving state by zip code ' + directSalesForm.zipcode, error);
                _this.msgService.showbwcom.next(true);
            });
        }
        else {
            this.auditLogging('XX', 'F', 'Direct Sales form is empty', directSalesForm.zipcode);
            this.errorMessage = 'Please enter a valid zip code';
            this.msgService.showbwcom.next(true);
        }
        setTimeout(function () {
            document.getElementById("startquotepnl").querySelector('[id=elemID]').focus();
        });
    };
    DirectsalesComponent.prototype.handleDirectSalesRequest = function (direct_sales_vendor, feature_flag) {
        var _this = this;
        if (direct_sales_vendor.source === 'INTERNAL') {
            if (direct_sales_vendor.source_ind === 'ITC') {
                // when ITC , then launch the ITC screen in IFrame
                this.auditReq.activityResult = 'S';
                this.router.navigate(['quote']);
            }
            else if (direct_sales_vendor.source_ind === 'DSA') {
                // When 'DSA' , then show Sorry screen
                this.auditReq.activityResult = 'F';
                this.router.navigate(['directsalescall']);
            }
            else if (direct_sales_vendor.source_ind === 'MDA') {
                // When 'MDA' then launch Sorry page or MediaAlpha with in BW.com
                this.auditReq.activityResult = 'F';
                Tracker.loginfo('directsalesComponent', 'onSubmit', 'getSourceByState', 'Media Alpha Zip Code' + this.directsalesParameters.zip);
                this.auditLogging(direct_sales_vendor.state, this.auditReq.activityResult, direct_sales_vendor.source_ind, this.directsalesParameters.zip);
                window.open('../../../assets/html/media-alpha.html?zip=' + this.directsalesParameters.zip, '_self');
            }
        }
        else if (direct_sales_vendor.source === 'EXTERNAL' && direct_sales_vendor.source_ind === 'BDQ'
            && feature_flag === 'startquote') {
            this.auditReq.activityResult = 'S';
            this.params = new HttpParams().set('zip', this.directsalesParameters.zip).set('link', direct_sales_vendor.url);
            if (this.directsalesParameters.dob) {
                var datePipe = new DatePipe('en-US');
                var matches = this.directsalesParameters.dob.match(/\d+/g);
                var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
                if (!format.test(this.directsalesParameters.dob)) {
                    var year = this.directsalesParameters.dob.substring(4, 8); // 0,4
                    var month = this.directsalesParameters.dob.substring(0, 2); // 4,6
                    var day = this.directsalesParameters.dob.substring(2, 4); // 6,8
                    this.directsalesParameters.dob = month + '/' + day + '/' + year;
                }
                else {
                    // replaces all occurances of "-" with "/"
                    this.directsalesParameters.dob = this.directsalesParameters.dob.replace(/-/g, '/');
                }
                var formattedDOB = void 0;
                try {
                    formattedDOB = datePipe.transform(new Date(this.directsalesParameters.dob), 'MM/dd/yyyy', 'EST', 'en-US');
                }
                catch (e) {
                    formattedDOB = '';
                    Tracker.logerror('DirectsalesComponent', 'onSubmit', 'getSourceByState', 'Error invalid date for date of birth ' + this.directsalesParameters.dob, '');
                }
                finally {
                    this.params = this.params.set('dob', formattedDOB);
                }
            }
            if (this.directsalesParameters) {
                this.params = this.params.set('id', this.directsalesParameters.id);
                this.params = this.params.set('emailID', this.directsalesParameters.emailid);
                this.params = this.params.set('firstName', this.directsalesParameters.firstname);
                this.params = this.params.set('lastName', this.directsalesParameters.lastname);
                this.params = this.params.set('phNo', this.directsalesParameters.phoneno);
                this.params = this.params.set('dsplyPhn', this.directsalesParameters.dsplyPhn);
                this.params = this.params.set('ppcSrc', this.directsalesParameters.ppcsrc);
                this.params = this.params.set('streetAddress', this.directsalesParameters.streetaddress);
                this.params = this.params.set('streetApt', this.directsalesParameters.streetapt);
                this.params = this.params.set('streetCity', this.directsalesParameters.streetcity);
                if (this.directsalesParameters.gender
                    && this.genderValues.indexOf(this.directsalesParameters.gender.toLocaleUpperCase()) !== -1) {
                    this.directsalesParameters.gender = this.directsalesParameters.gender.charAt(0);
                    this.params = this.params.set('gender', this.directsalesParameters.gender);
                }
            }
            this.auditLogging(direct_sales_vendor.state, this.auditReq.activityResult, direct_sales_vendor.source_ind, this.directsalesParameters.zip);
        }
        else if (direct_sales_vendor.source === 'EXTERNAL' && direct_sales_vendor.source_ind === 'BDQ' &&
            feature_flag === 'retrievequote') {
            this.auditReq.activityResult = 'S';
            this.auditLogging(direct_sales_vendor.state, this.auditReq.activityResult, direct_sales_vendor.source_ind + '-' + feature_flag, this.directsalesParameters.zip);
            // To DO - Append zip code here
            var URL_1 = this.retrieveQuoteURL + '?Zip_Code=' + this.directsalesParameters.zip;
            window.open(URL_1, '_self');
        }
        else {
            this.auditReq.activityResult = 'F';
            var url_1 = this.directsalesParameters.zip !== null ? 'home/?zip=' + this.directsalesParameters.zip : 'home';
            this.msgService.showbwcom.next(false);
            this.router.navigateByUrl(url_1, { skipLocationChange: true }).then(function () {
                return _this.router.navigate(['/externalRedirect', { externalUrl: url_1 }]);
            });
        }
    };
    DirectsalesComponent.prototype.cleanRequestParameters = function () {
        if (this.directsalesParameters) {
            for (var prop in this.directsalesParameters) {
                if (this.directsalesParameters.hasOwnProperty(prop)) {
                    // const regexNonAlphaNumeric = '^[/\W/g].*[/\W/g]$';
                    // const regexNonAlphaNumeric = '[^a-zA-Z\d\s:]';
                    if (prop !== 'id' && this.directsalesParameters[prop].match(/[^a-zA-Z\d\s:]/)) {
                        Tracker.logerror('DirectsalesComponent', 'onSubmit', 'cleanRequestParameters', 'Invalid characters in parameter ' + prop + '=' + this.directsalesParameters[prop] +
                            'for ' + this.directsalesParameters.id, '');
                        // alert('Invalid characters in parameter ' + prop + '= \'' + this.directsalesParameters[prop] +
                        // '\' for ' + this.directsalesParameters.id);
                        if (prop === 'dob') {
                            if (this.directsalesParameters[prop].match(/[^a-zA-Z\d\s-/:]/)) {
                                this.directsalesParameters[prop] = '';
                            }
                        }
                        else if (prop === 'emailid') {
                            if (this.directsalesParameters[prop].match(/[^a-zA-Z\d\s.@_:]/)) {
                                this.directsalesParameters[prop] = '';
                            }
                        }
                        else {
                            this.directsalesParameters[prop] = '';
                        }
                    }
                }
            }
        }
    };
    // Form Utilities
    DirectsalesComponent.prototype.isFieldValid = function (field) {
        return ((!this.directSalesForm.get(field).valid && this.directSalesForm.get(field).touched) ||
            (this.directSalesForm.get(field).untouched && this.formSubmitAttempt));
    };
    DirectsalesComponent.prototype.getZipCodeErrMsg = function (errors) {
        if (errors) {
            if (errors.required === true) {
                return this.usermessage.REQUIRED_ZIPCODE;
            }
            else if (!errors.minLength) {
                return this.usermessage.LENGTH_ZIPCODE;
            }
        }
    };
    DirectsalesComponent.prototype.onPhoneClick = function () {
        this.gaService.trackEvent('Clicking ph# on home page', 'HomeScreen', 'PhoneNumClick', 200);
        // window.location.href = 'tel:1-866-228-4622';
    };
    DirectsalesComponent.prototype.auditLogging = function (state, actRslt, actMsg, zip) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            Tracker.loginfo('directsalesComponent', 'auditLogging', 'auditLogging service', 'direct sales audit entry-' + state.concat('-').concat(actRslt).concat('-').concat(actMsg).concat('-').concat(zip));
            if (_this.directsalesParameters.id === null) {
                _this.directsalesParameters.id = 'none';
            }
            if (_this.directsalesParameters.id !== null) {
                // this._directSalesService.addMarketingIdAuditLogForBDQ(zip, state, this.marketingID);
                _this.auditReq.symbol = GlobalConstants.AUDIT_LOG_MARKETING_ID_SYMBOL;
                _this.auditReq.policyNum = GlobalConstants.AUDIT_LOG_MARKETING_ID_POLICY_NUMBER;
                _this.auditReq.mod = GlobalConstants.AUDIT_LOG_MARKETING_ID_MODULE;
                _this.auditReq.mco = GlobalConstants.AUDIT_LOG_MARKETING_ID_MASTER_COMPANY;
                _this.auditReq.state = state;
                _this.auditReq.activityResult = GlobalConstants.AUDIT_LOG_MARKETING_ID_ACTIVITY_RESULT;
                _this.auditReq.activity = GlobalConstants.AUDIT_LOG_MARKETING_ID_ACTIVITY;
                _this.auditReq.activityMessage = _this.directsalesParameters.id;
                _this.auditReq.binderNumber = zip;
                _this.endorsementService.createAuditLogRecord(_this.auditReq).subscribe();
            }
            _this.auditReq.symbol = GlobalConstants.AUDIT_LOG_REQ_SYMBOL;
            _this.auditReq.policyNum = GlobalConstants.AUDIT_LOG_REQ_POLICYNUM;
            _this.auditReq.mod = GlobalConstants.AUDIT_LOG_REQ_MOD;
            _this.auditReq.mco = GlobalConstants.AUDIT_LOG__REQ_MCO;
            _this.auditReq.activity = GlobalConstants.AUDIT_LOG_REQ_SYMBOL;
            _this.auditReq.state = state;
            _this.auditReq.activityResult = actRslt;
            _this.auditReq.activityMessage = actMsg;
            _this.auditReq.binderNumber = zip;
            _this.endorsementService.createAuditLogRecord(_this.auditReq).subscribe(function (res) {
                if (res.status === 0) {
                    if (actMsg === 'BDQ') {
                        var URL_2 = '../../../assets/html/bdq-quote.html' + '?' + _this.params.toString();
                        Tracker.loginfo('directsalesComponent', 'onSubmit', '', 'BDQ URL from BWC:' + URL_2);
                        window.open(URL_2, '_self');
                    }
                    resolve(res);
                }
                else {
                    reject(res);
                }
            }, function (err) {
                reject(err);
            });
        });
    };
    return DirectsalesComponent;
}());
export { DirectsalesComponent };
