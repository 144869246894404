import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DirectsalesService } from '../../shared/services/directsales.service';

@Component({
  selector: 'app-directsaleshome',
  template: `
  <script language="text/javascript">
  function iOS() {
   var iDevices = [
     'iPad Simulator',
     'iPhone Simulator',
     'iPod Simulator',
     'iPad',
     'iPhone',
     'iPod',
     'MacIntel'
   ];
   if (!!navigator.platform) {
     while (iDevices.length) {
       if (navigator.platform === iDevices.pop()) { return true; }
     }
   }
   return false;
 }
if (iOS()) {
     if (!document.cookie.match(/^(.*;)?\s*fixed\s*=\s*[^;]+(.*)?$/)) {
       document.cookie = 'fixed=fixed; expires=Tue, 19 Jan 2038 03:14:07 UTC; path=/';
       window.location.replace("https://26042ec2-97b0-48fd-a94b-01d2927ae74d.quotes.iwantinsurance.com/safarifix.htm");
     }  
   } 
 </script>

  <iframe appIframeResizer
  *ngIf="this.sourceInd === 'ITC'" class="thread-content"
  [src]="this.sourceURL | safe"
  title="Thread Content"
  scrolling="no" frameborder="0" style="height:100%; width:100%; border: none;"></iframe>
 <div class="container text-center" *ngIf="!this.sourceURL">Unable to load quote.</div>
    `,
  styleUrls: ['./directsaleshome.component.scss']
})

export class DirectsaleshomeComponent implements OnInit {

  sourceURL: any;
  stateZipCode: any;
   router: Router ;
   source: string;
   sourceInd: string;

   ngOnInit(): void {
  }

  constructor(private directSalesService: DirectsalesService,   private route: ActivatedRoute) {
    this.sourceURL = this.directSalesService.getSourceURL();
    this.source = this.directSalesService.getSource();
    this.sourceInd = this.directSalesService.getSourceInd();
   }

}
