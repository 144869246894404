import { Action } from '@ngrx/store';
import { EndorseMetainfo } from '../../shared/model/endorsements/endorse-metainfo.model';

export const CREATE_ENDORSEMENTINFO = '[ENDORSEMENTINFO] Add';
export const UPDATE_ENDORSEMENTINFO = '[ENDORSEMENTINFO] Update';
export const REMOVE_ENDORSEMENTINFO = '[ENDORSEMENTINFO] Remove';

// Create Endorsement Info upon User initiates the endorsment
export class CreateEndorsementInfo implements Action {

    readonly type = CREATE_ENDORSEMENTINFO;

    constructor(public payload: EndorseMetainfo) {}
}


// Update Login Info
export class UpdateEndorsementInfo implements Action {

    readonly type = UPDATE_ENDORSEMENTINFO;

    constructor(public payload: EndorseMetainfo) {}
}

// Remove LoginInfo upon User Logout
export class RemoveEndorsementInfo implements Action {

    readonly type = REMOVE_ENDORSEMENTINFO;

    constructor(public payload: EndorseMetainfo) {}
}

export type Actions = CreateEndorsementInfo | UpdateEndorsementInfo | RemoveEndorsementInfo;
