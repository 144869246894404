import { Router } from '@angular/router';
import { BwstoreService } from '../services/bwstore.service';
var RedirectGuard = /** @class */ (function () {
    function RedirectGuard(router, _bwStoreService) {
        this.router = router;
        this._bwStoreService = _bwStoreService;
    }
    RedirectGuard.prototype.canActivate = function (next, state) {
        var currentUserInfo = this._bwStoreService.getLoginInfoFromStore();
        var bwAuthToken = currentUserInfo.jwtToken;
        if (bwAuthToken && bwAuthToken !== null && bwAuthToken !== undefined && bwAuthToken !== '') {
            // Tracker.log('Redirecting to MyPolicy as the User token is still available : ');
            this.router.navigate(['/policy']);
            return false;
        }
        return true;
    };
    return RedirectGuard;
}());
export { RedirectGuard };
