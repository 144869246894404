export class Authforidcardreq {
    policySymbol: string;
    policyNumber: string;
    lastName: string;
    dob: string;
    zipCode: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
