import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalConstants } from '../../../constants/global.constant';
import { DocumentsService } from '../../../services/api/documents/documents.service';
import { GoogleAnalyticsService } from '../../services/analytics/google-analytics.service';
import { Idcardreq } from '../../model/api/document/idcardreq';
import { Idcardres } from '../../model/api/document/idcardres';
import { Policynumber } from '../../model/policynumber';
import { BwstoreService } from '../../services/bwstore.service';
import { CommonUtil } from '../../utils/commonutil';
import { Tracker } from '../../utils/tracker';
import { EndorseMetainfo } from '../../model/endorsements/endorse-metainfo.model';
@Component({
  selector: 'app-idcard',
  template: `
    <div class="modal-body pb-3">
      <div class="text-center"><p>{{ this.idCardMessage }}</p></div>
      <div *ngIf="!this.allowClose" class="app-loading">
        <div class="logoload" style="width:75px;height:75px;"></div>
          <svg class="spinner" style="width:125px;height:125px;" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
          </svg>
        </div>
      </div>
    <div *ngIf="this.allowClose" class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="dismiss()">Close</button>
    </div>
  `,
  styles: []
})
export class IdcardComponent implements OnInit {

  idcardreq: Idcardreq;
  idcardres: Idcardres;
  policyNumber: Policynumber;
  idCardMessage: string;
  allowClose = false;
  endorsementInfo: EndorseMetainfo;

  constructor(public idCardModal: NgbActiveModal, private documentService: DocumentsService,
    private _storeService: BwstoreService,
    private googleAnalytics: GoogleAnalyticsService) { }

  ngOnInit() {
    // test it
    this.idCardMessage = 'Retrieving your ID Card...';
     // endorsement info is usefull to invoke processEndorsementIDCard or ProcessIDCard based on user input
      this.endorsementInfo = this._storeService.getEndorsementInfoFromStore();
    this.viewIDCard();
  }

  navigate() {
    this.idCardModal.close();
  }

  dismiss() {
    this.idCardModal.dismiss();
  }

  viewIDCard() {
    this.policyNumber = new Policynumber(this._storeService.getPolicyInfoFromStore().policyInfo.PolicyNumber);
    const state = this._storeService.getLoginInfoFromStore().riskstate;
    if (state !== 'NJ' &&  state !== 'NY' && state !== 'MA') {
      this.idcardreq = new Idcardreq();
      this.idcardreq.policyID = this.policyNumber.toString();
      this.idcardreq.mco = this._storeService.getPolicyInfoFromStore().policyInfo.MASTER0CO;
      this.idcardreq.futureTerm = 'N'; // TODO: what sets this
      this.idcardreq.appName = GlobalConstants.APPID;
      this.idcardreq.deliveryMode = 'Email';
      if (this.endorsementInfo && this.endorsementInfo.endorseBound === 'true') {
        this.idcardreq.isNewCard = true;
      } else {
        this.idcardreq.isNewCard = false;
      }
      this.documentService.retrieveIDCard(this.idcardreq).subscribe(iddata => {
        this.idcardres = iddata;
        this.resetEndoseBondValue();
          if (!CommonUtil.isEmpty(this.idcardres) && this.idcardres.idCard.length > 0) {
            // download to browser
            this.downloadIdCard(this.idcardres.idCard);
            this.idCardMessage = 'Your ID Card has been downloaded successfully.';
            this.allowClose = true;
          } else {
            this.idCardMessage = 'We are unable to retrieve your ID Card at this time. Please try again.';
            this.allowClose = true;
          }
      },
      error => {
        this.idCardMessage = 'We are unable to retrieve your ID Card at this time. Please try again.';
        this.allowClose = true;
        this.resetEndoseBondValue();
        Tracker.logerror('IdcardComponent', 'viewIDCard', 'documentService.retrieveIDCard', 'Error occurred getting ID Card for ' +
        this.policyNumber.toFormattedString(), error);
      });
    } else {
      // NY or NJ or MA so don't display ID Card. Show message
      this.allowClose = true;
      this.idCardMessage = 'Please contact your agent for assistance with your ID Card.';
    }
  }

  downloadIdCard(pdf: string) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(CommonUtil.base64toBlob(pdf, 'application/pdf'));
      return;
    }

    // All other browsers
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement('a');
    const fileName = 'IDCard.pdf';

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    this.googleAnalytics.trackEvent('IDCard', 'HomeScreen', 'IDCardGenerated', 200);
    downloadLink.click();

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      document.body.removeChild(downloadLink);
      // window.URL.revokeObjectURL(data);
    });

  }

  resetEndoseBondValue() {
    // condtion that resets the endose bond in order to get is card from my policy page
    if (this.endorsementInfo && this.endorsementInfo.endorseType === 'address' && this.endorsementInfo.endorseBound === 'true') {
      this._storeService.deleteBwEndorsementInfo(new EndorseMetainfo());
   }
 }

}
