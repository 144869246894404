import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BwstoreService } from '../../shared/services/bwstore.service';
import { MessagesService } from '../../shared/services/messages.service';
import { BWErrorhandler } from '../../services/errorhandler';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleAnalyticsService } from '../../shared/services/analytics/google-analytics.service';

@Component({
  selector: 'app-phone-edit',
  templateUrl: './phone-edit.component.html',
  styleUrls: ['./phone-edit.component.scss']
})
export class PhoneEditComponent implements OnInit {
  phoneEditForm: FormGroup;
  formSubmitAttempt: boolean;

  // Toggle Indicators
  inProgesss = false;
  phoneUpdated = false;
  continueToTxtAlerts = false;

  constructor(private route: ActivatedRoute, private router: Router,
    private fb: FormBuilder, private _storeService: BwstoreService,
    private _messageService: MessagesService,  private errorHandler: BWErrorhandler,
    private phoneEditModal: NgbActiveModal, private googleAnalytics: GoogleAnalyticsService) {

      this.createPhoneEditForm();
    }

  ngOnInit() {
  }

  submitPhoneEndorsement(formdate: any) {
    this.formSubmitAttempt = true;
    this.inProgesss = true;
    if (this.phoneEditForm.valid) {
      console.log('submission successful..........', formdate.phoneNumber);
      this.inProgesss = false;
      this.phoneUpdated = true;
    }
  }

  navigateToEnrolTxtAlert() {
    this.phoneEditModal.close();
    this.router.navigate(['/profile/txtalert']);
  }

  createPhoneEditForm() {
    this.phoneEditForm = this.fb.group({
      'phoneNumber' : [{value: '', disabled: false}, Validators.compose([Validators.required])],
    });
  }



   // Form Utilities
   isFieldValid(field: string) {
    return (
      (!this.phoneEditForm.get(field).valid && this.phoneEditForm.get(field).touched) ||
      (this.phoneEditForm.get(field).untouched && this.formSubmitAttempt)
    );
  }

  getErrMessage(errors, formCntrlName: string) {
    return this._messageService.getErrorMessages(errors, formCntrlName);
  }

}
