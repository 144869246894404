
export class BWError {
    errorcode: string;
    errordesc: string;
    source: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
