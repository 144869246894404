import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { MessagesService } from '../services/messages.service';

@Injectable({
  providedIn: 'root'
})
export class RouteinterceptGuard implements CanActivate {

  constructor(private messageService: MessagesService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // Placeholder for all future Router Modifications

    // Clear all error messages if any
    this.messageService.clearAll();

    return true;
  }
}
