export var CREATE_VALIDVALUESINFO = '[VALIDVALUESINFO] Add';
export var UPDATE_VALIDVALUESINFO = '[VALIDVALUESINFO] Update';
export var REMOVE_VALIDVALUESINFO = '[VALIDVALUESINFO] Remove';
// Create validValues Info upon User landing on my policy screen
var CreateValidValuesInfo = /** @class */ (function () {
    function CreateValidValuesInfo(payload) {
        this.payload = payload;
        this.type = CREATE_VALIDVALUESINFO;
    }
    return CreateValidValuesInfo;
}());
export { CreateValidValuesInfo };
// Update validValues Info
var UpdateValidValuesInfo = /** @class */ (function () {
    function UpdateValidValuesInfo(payload) {
        this.payload = payload;
        this.type = UPDATE_VALIDVALUESINFO;
    }
    return UpdateValidValuesInfo;
}());
export { UpdateValidValuesInfo };
// Remove validValues info
var RemoveValidValuesInfo = /** @class */ (function () {
    function RemoveValidValuesInfo(payload) {
        this.payload = payload;
        this.type = REMOVE_VALIDVALUESINFO;
    }
    return RemoveValidValuesInfo;
}());
export { RemoveValidValuesInfo };
