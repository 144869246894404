import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { PolicyholderService } from '../../../services/api/policyholder/policyholder.service';
import { BwstoreService } from '../../services/bwstore.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleAnalyticsService } from '../../services/analytics/google-analytics.service';
import { TimeoutService } from '../../../services/timeout.service';
import { CommonUtil } from '../../utils/commonutil';
import { PolicyHolder } from '../../model/authentication/authenticatephres';
import { Tracker } from '../../utils/tracker';
import { environment } from '../../../../environments/environment';
import { Policynumber } from '../../model/policynumber';
import { BWError } from '../../model/error.model';
import { FullPolicyDetails, PolicyInfo } from '../../model/api/bwibic/fullpolicydetails.model';
import { MypolicyService } from '../../../services/mypolicy/mypolicy.service';
import { AddresseditService } from '../../../services/addressedit/addressedit.service';
import { TextAlertsService } from '../../../services/api/text-alerts/text-alerts.service';

@Component({
  selector: 'app-sso-login',
  template:
  `
    <div *ngIf="!isLoaded" class="my-5 text-center">
      <div class="app-loading">
          <div class="logoload"></div>
          <svg class="spinner" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
          </svg>
        </div>
    </div>
  `
})

export class SsoLoginComponent implements OnInit {

  isLoaded = false;
  token: string;
  path: string;
  policyNumber: Policynumber;
  isEligibleForEndorsement = true;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private plcyhlderService: PolicyholderService,
    private storeService: BwstoreService,
    private googleAnalytics: GoogleAnalyticsService,
    private timeoutService: TimeoutService,
    private policyService: MypolicyService,
    private textAlertsService: TextAlertsService,
    private addresseditService: AddresseditService) { }
    bwOktaEnable : boolean = environment.bwOktaEnabled;
    bwOktaSSOEnabled : boolean = false;
  ngOnInit() {
    // load token and path from URL
    this.route.queryParams.subscribe(params => {
      if (params) {
        if (params.token ) {
          this.token = params.token;
          //this.path = params.path;
        }
        if(params.path){
          this.path = params.path;
        }
        if(params.code){
          this.token=params.code;
          this.bwOktaSSOEnabled = true;
        }
        if(params.state){
          this.path = params.state;
          this.bwOktaSSOEnabled = true;
        }
      }
    });

    if (this.token && this.path) {
      console.log("Code = "+this.token + " path = "+this.path);
      this.loginUser(this.token);
    } else {
      return this.router.navigate(['/']);
    }
  }

  loginUser(ssoToken: string) {

    if (ssoToken) {
      this.authService
        .ssologin(this.token,this.bwOktaSSOEnabled)
        .then((phuser: PolicyHolder) => {
          this.googleAnalytics.trackEvent('Login', 'LoginAttemptSuccess', 'LoginAttempt', 200);
          Tracker.loginfo('SsoLoginComponent', 'ngOnInit()', 'Initializing onload functions', 'Component ## Policy Data from Store :::: ');
          if (phuser && phuser.jwtToken && phuser.jwtToken !== null && phuser.jwtToken !== '') {
            this.authService.getPolicyModAndVerifyPendingDocs(phuser).then((plcyHolder: PolicyHolder) => {

            // start timeout service
            this.timeoutService.start();

            this.storeService.updateLoginInfo(plcyHolder);
            this.authService.createWebSessLog(plcyHolder);
            if (plcyHolder && plcyHolder.gopaperlessInd === true) {
              return this.router.navigate(['/gptermsnconditions']);
            } else if (plcyHolder && plcyHolder.esigstatus === true) {
              return this.router.navigate(['/esigmessages']);
            } else {
             this.policyNumber = new Policynumber(plcyHolder.pointHookID.substring(2).concat(plcyHolder.policyMod));

              this.policyService.getAllBasicPolicyInfo(
                plcyHolder.pointHookID.substring(0, 2), this.policyNumber.symbol,
                this.policyNumber.policy, this.policyNumber.module)
              .then((policyres: FullPolicyDetails) => {
                Tracker.loginfo('SsoLoginComponent', 'loginUser()', 'Invoked policyService.getAllBasicPolicyInfo()',
                  'Retrieving data from service... ');

                this.storeService.addPolicyInfo(policyres);

                switch (this.path) {
                  case 'gp': {
                    return this.router.navigate(['/policy'], {skipLocationChange: true}).then(() =>
                     this.router.navigate(['/profile']));
                  }
                  case 'ta': {
                    this.textAlertsService.GetTextAlertEnrollmentStatus(policyres.agentInfo.AgentCode,
                      policyres.agentInfo.AgentType, policyres.policyInfo.PolicyMasterCompany,
                      plcyHolder.riskstate, policyres.policyInfo.PolicyNumber)
                      .subscribe(textAlertRes => {
                        plcyHolder.textAlertsEnrollment = textAlertRes.length === 0 ? 'false' : 'true';
                        // If there are phone store them in store
                        if (textAlertRes.length > 0) {
                          plcyHolder.textAlertEnrolledPhones = textAlertRes;
                        }
                        this.storeService.updateLoginInfo(plcyHolder);

                        // need to go to my-policy then profile
                        return this.router.navigate(['/policy'], {skipLocationChange: true}).then(() =>
                        this.router.navigate(['/profile/txtalert']));
                    });
                   return this.router.navigate(['/policy']);
                  }
                  case 'cu': {
                    return this.router.navigate(['/policy'], {skipLocationChange: true}).then(() =>
                    this.router.navigate(['/profile/updatelogin'], { queryParams: { type: 'userid' } }));
                  }
                  case 'cp': {
                    return this.router.navigate(['/policy'], {skipLocationChange: true}).then(() =>
                    this.router.navigate(['/profile/updatelogin'], { queryParams: { type: 'password' } }));
                  }
                  case 'cs': {
                    // route to home page to login
                    this.storeService.deletePolicyInfo(policyres);
                    this.storeService.deleteLoginInfo(plcyHolder);
                    return this.router.navigate(['/']);
                    // return this.router.navigate(['/profile/updatelogin'], { queryParams: { type: 'question' } });
                  }
                  case 'md': {
                    return this.router.navigate(['/policy'], {skipLocationChange: true}).then(() =>
                    this.router.navigate(['policy/documents']));
                  }
                  case 'ap': {
                    return this.router.navigate(['/policy'], {skipLocationChange: true}).then(() =>
                    this.router.navigate(['policy/autopay']));
                  }
                  case 'pd': {
                    return this.router.navigate(['/policy'], {skipLocationChange: true}).then(() =>
                    this.router.navigate(['policy/missing-document']));
                  }
                  // Add Vehicle
                  case 'av': {
                    return this.router.navigate(['/policy'], {skipLocationChange: true}).then(() =>
                    this.router.navigate(['policy/add-vehicle']));
                  }
                  // Replace Vehicle
                  case 'rv': {
                    return this.router.navigate(['/policy'], {skipLocationChange: true}).then(() =>
                    this.router.navigate(['policy/replace-vehicle']));
                  }
                  // Remove Vehicle
                  case 'dv': {
                    return this.router.navigate(['/policy'], {skipLocationChange: true}).then(() =>
                    this.router.navigate(['policy/remove-vehicle']));
                  }
                  // Edit Address or Phone
                  case 'pd': {
                    return this.router.navigate(['/policy'], {skipLocationChange: true}).then(() =>
                    this.router.navigate(['policy/missing-document']));
                  }
                  default: {
                    return this.router.navigate(['/policy']);
                  }
                }

              }).catch(error => {
                Tracker.logerror('SsoLoginComponent', 'loginUser()', 'Initializing onload functions',
                  'MypolicyService-getAllBasicPolicyInfo() Failed', error);
              });
            }
          }).catch(error => {
            this.storeService.deleteLoginInfo(null);
            Tracker.logerror('SsoLoginComponent', 'loginUser()', 'Initializing onload functions',
                  'MypolicyService-getAllBasicPolicyInfo() Failed', error);
            this.isLoaded = false;
          if (error instanceof BWError) {

          }

          Tracker.logerror('SsoLoginComponent', 'loginUser', 'loginUser',
          'Error during sso login.', error);
          return this.router.navigate(['/']);
        });
        }
      }).catch(error => {
        this.googleAnalytics.trackEvent('SsoLoginComponent', 'LoginAttemptFail', 'LoginAttempt', 500);
        this.storeService.deleteLoginInfo(null);
        return this.router.navigate(['/']);
      });
    }
  }

}
