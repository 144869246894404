import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { GlobalConstants } from '../../constants/global.constant';
import { ChangePHPasswordRequest, ChangePHPasswordResponse } from '../../shared/model/api/policyholder/changepassword.model';
import { CheckAnswersRequest, CheckAnswersResponse } from '../../shared/model/api/policyholder/checkanswers.model';
import { FindPHRequest, FindPHResponse, McoResponse } from '../../shared/model/api/policyholder/findph.model';
import { ForgotPHPasswordRequest, ForgotPHPasswordResponse } from '../../shared/model/api/policyholder/forgotphpassword.model';
import { RemovePHRequest, RemovePHResponse } from '../../shared/model/api/policyholder/removeph.model';
import { ResetPHPasswordRequest, ResetPHPasswordResponse } from '../../shared/model/api/policyholder/resetpassword.model';
import { BWError } from '../../shared/model/error.model';
import { Policynumber } from '../../shared/model/policynumber';
import { PolicyholderService } from '../api/policyholder/policyholder.service';
import { BWErrorhandler } from '../errorhandler';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  findPhReq: FindPHRequest;
  removePhReq: RemovePHRequest;
  policyNumber: Policynumber;

  constructor(private errorHandler: BWErrorhandler, private phService: PolicyholderService) { }

  forgotUserID(bwpolnum: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // invoke API level services here
    });
  }

  forgotPassword(forgotPHPasswordRequest: ForgotPHPasswordRequest): Promise<ForgotPHPasswordResponse> {
    return new Promise((resolve, reject) => {
      this.phService.forgotPHPassword(forgotPHPasswordRequest).
        subscribe((forgotPwdRes: ForgotPHPasswordResponse) => {
          // Tracker.log('forgotPassword response :: ', forgotPwdRes);
          if (forgotPwdRes !== null && forgotPwdRes.responseCode === '-1') {
            // Tracker.log('Customer found....');
          } else {
            // Tracker.log('The services retured something else....', forgotPwdRes);
            return reject(this.errorHandler.handleApplicationError(new BWError(
              { 'errorcode': '100',
              'errordesc': 'Unable to locate your registration If the problem persists,please call Customer Service at ' +
              GlobalConstants.SERVICE_OPS_NUMBER,
               'source': 'LoginService' })));
          }
          resolve(forgotPwdRes);
        },
          (error) => {
            // Tracker.log('Error Occurred in forgotPassword', error);
            return reject(error);
          });
    });
  }

  verifySecurityAnswers(checkAnswersRequest: CheckAnswersRequest): Promise<CheckAnswersResponse> {
    return new Promise((resolve, reject) => {
      this.phService.checkAnswers(checkAnswersRequest).
        subscribe((checkAnsRes: CheckAnswersResponse) => {
          // Tracker.log('verifySecurityAnswers response :: ', checkAnsRes);
          if (checkAnsRes !== null && checkAnsRes.responseCode === '-1') {
            // Tracker.log('Answers verified....');
          } else {
            // Tracker.log('The services retured something else....', checkAnsRes);
          }
          resolve(checkAnsRes);
        },
          (error) => {
            // Tracker.log('Error Occurred in verifySecurityAnswers', error);
            return reject(error);
          });
    });
  }

  changePassword(changePHPasswordRequest: ChangePHPasswordRequest): Promise<ChangePHPasswordResponse> {
    return new Promise((resolve, reject) => {
      this.phService.changePassword(changePHPasswordRequest).
        subscribe((changePwdRes: ChangePHPasswordResponse) => {
          // Tracker.log('changePassword response :: ', changePwdRes);
          if (changePwdRes !== null && (changePwdRes.responseCode === '-1' || changePwdRes.responseCode === 'S')) {
            // Tracker.log('Password Updated Successfully....');
          } else {
            // Tracker.log('The services retured something else....', changePwdRes);
            return reject(this.errorHandler.handleApplicationError(new BWError(
              { 'errorcode': '100', 'errordesc': 'Unable to Update the password', 'source': 'LoginService' })));
          }
          resolve(changePwdRes);
        },
          (error) => {
            // Tracker.log('Error Occurred in changePassword', error);
            return reject(error);
          });
    });
  }

  resetPassword(resetPHPasswordRequest: ResetPHPasswordRequest): Promise<ResetPHPasswordResponse> {
    return new Promise((resolve, reject) => {
      this.phService.resetPassword(resetPHPasswordRequest).
        subscribe((resetPwdRes: ResetPHPasswordResponse) => {
          // Tracker.log('changePassword response :: ', resetPwdRes);
          if (resetPwdRes !== null && resetPwdRes.responseCode === '-1') {
            // Tracker.log('Password Updated Successfully....');
          } else {
            // Tracker.log('The services retured something else....', resetPwdRes);
            return reject(this.errorHandler.handleApplicationError(new BWError(
              { 'errorcode': '100', 'errordesc': 'Unable to Update the password', 'source': 'LoginService' })));
          }
          resolve(resetPwdRes);
        },
          (error) => {
            // Tracker.log('Error Occurred in resetPassword', error);
            return reject(error);
          });
    });
  }
  processForgotUserIdWf(policyNum: string): Promise<any> {
    // Tracker.log('IN Policy Holder Service processForgotUserIdWf()');
    return new Promise((resolve, reject) => {
      policyNum = policyNum.toLocaleUpperCase();
      // Tracker.log('start calling service operation getPolicyMco');
      // service call for getPolicyMCO
      this.phService.getPolicyMco(policyNum).subscribe((mco: McoResponse) => {
        if (mco != null) {
          this.policyNumber = new Policynumber(policyNum.toLocaleUpperCase());
          this.findPhReq = new FindPHRequest();
          this.findPhReq.pointHookID = mco.mco + this.policyNumber.symbol + this.policyNumber.policy;

          // Tracker.log('start calling service operation findPH for ' + this.findPhReq.pointHookID);
          // service call for findPh operation
          this.phService.findPH(this.findPhReq).subscribe((phResponse: FindPHResponse) => {
            if (phResponse && (phResponse.responseCode === '-1' || phResponse.responseCode=='S')) {
              if(environment.bwOktaEnabled){
                this.removePhReq = new RemovePHRequest();
                this.removePhReq.email = btoa(phResponse.email);// encoding it with Base64
                //this.removePhReq.adminPassword = environment.removePhAdminPassword;--> not required to pass

              }else{
                this.removePhReq = new RemovePHRequest();
                this.removePhReq.email = phResponse.email;
                this.removePhReq.adminPassword = environment.removePhAdminPassword;

              }

              // Tracker.log('start calling service operation removePH for ' + phResponse.email);
              // service call for removePH
              this.phService.removePH(this.removePhReq).subscribe((removePhRes: RemovePHResponse) => {
                if (removePhRes && (removePhRes.removeStatusCode === '-1' || removePhRes.removeStatusCode === '26'|| phResponse.responseCode=='S')) {
                  // Tracker.log('Successfully removed policy holder', removePhRes);
                  return resolve('Successfully removedPh');
                } else {
                  // Tracker.log('removePh failed...');
                  return reject('Policy Number ' + policyNum +
                  ' is not registered.  Please re-enter your Policy Number.  If the problem persists, please call' +
                  ' Customer Service at 1-888-888-0080');
                }
              });
            } else {
              // Tracker.log('findPh failed...');
              return reject('Your policy in not registered. Please select New User to register your policy.');
            }
          },
            error => {
              if (error.error.responseCode === 'E') {
                return reject('Your policy in not registered. Please select New User to register your policy.');
              }
            }
          );
        } else {
          // Tracker.log('getPolicyMco failed...');
          return reject('Your policy in not registered. Please select New User to register your policy.');
        }
      },
        error => {
          return reject(error);
        }
      );
    });
  }

}
