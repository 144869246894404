
export class RecipientsToSignReq {

  id: string;
  fullName: String;
  emailAddress: string;
  recieptType; string;
  accessCode: string;
  constructor() {}
}
