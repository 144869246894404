import { OnInit } from '@angular/core';
import { MessagesService } from '../../services/messages.service';
import { Router } from '@angular/router';
var AppErrorsComponent = /** @class */ (function () {
    function AppErrorsComponent(messageService, router) {
        this.messageService = messageService;
        this.router = router;
        this.appMessages = [];
    }
    AppErrorsComponent.prototype.ngOnInit = function () {
        this.appMessages = this.messageService.getAppMessages();
    };
    AppErrorsComponent.prototype.fetchRoutelink = function (event) {
        var routeUrl = event.target.getAttribute('data-link');
        if (routeUrl) {
            this.router.navigate([routeUrl]);
        }
    };
    return AppErrorsComponent;
}());
export { AppErrorsComponent };
