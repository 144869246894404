import { HttpParams } from '@angular/common/http';
import { GlobalConstants } from '../../../constants/global.constant';
import { Policynumber } from '../../../shared/model/policynumber';
import { BwstoreService } from '../../../shared/services/bwstore.service';
import { BwappService } from '../../bwapp.service';
import { BWErrorhandler } from '../../errorhandler';
import * as i0 from "@angular/core";
import * as i1 from "../../bwapp.service";
import * as i2 from "../../../shared/services/bwstore.service";
import * as i3 from "../../errorhandler";
var BwqueriesService = /** @class */ (function () {
    function BwqueriesService(appService, storeService, errorHandler) {
        this.appService = appService;
        this.storeService = storeService;
        this.errorHandler = errorHandler;
        this.globlalConstants = GlobalConstants;
        this._baseURL = GlobalConstants.baseURL;
    }
    BwqueriesService.prototype.isCancelledPolicy = function () {
        this.policyNumber = new Policynumber(this.storeService.newUser.policynumber);
        var params = new HttpParams()
            .set('polSym', this.policyNumber.symbol)
            .set('polNum', this.policyNumber.policy)
            .set('polMod', this.policyNumber.module)
            .set('mco', this.storeService.newUser.mco);
        return this.appService.get(this.globlalConstants.BWQRY_IS_CANCELLED_URL, params);
    };
    BwqueriesService.prototype.getPolicyBasicContract = function (policynumber) {
        this.policyNumber = new Policynumber(policynumber);
        // Tracker.log('BWQueryService: getPolicyBasicContract() is inkovked for policy:: ', policynumber, this.policyNumber);
        var params = new HttpParams()
            .set('polSym', this.policyNumber.symbol)
            .set('polNum', this.policyNumber.policy)
            .set('polMod', this.policyNumber.module);
        return this.appService.get(this.globlalConstants.BWQRY_GETPOLICYBASICCONTRACT, params);
    };
    BwqueriesService.prototype.getPolicyCurrentMod = function (policySymbol, policyNumber) {
        // Tracker.log('BWQueryService: getPolicyCurrentMod() is invoked for policy:: ', policyNumber);
        var params = new HttpParams()
            .set('polSym', policySymbol)
            .set('polNum', policyNumber);
        return this.appService.get(this.globlalConstants.BWQRY_GETPOLICYCURRENTMOD, params);
    };
    BwqueriesService.prototype.getPolicyLocationStatusAndMaxMod = function (policySymbol, policyNumber) {
        // Tracker.log('BWQueryService: getPolicyLocationStatusAndMaxMod() is invoked for policy:: ', policyNumber);
        var params = new HttpParams()
            .set('polSym', policySymbol)
            .set('polNum', policyNumber);
        return this.appService.get(this.globlalConstants.BWQRY_GETPOLICYLOCATIONSTATUSANDMAXMOD, params);
    };
    BwqueriesService.prototype.getPolicyFee = function (policynumber) {
        this.policyNumber = new Policynumber(policynumber);
        var params = new HttpParams()
            .set('polSym', this.policyNumber.symbol)
            .set('polNum', this.policyNumber.policy)
            .set('polMod', this.policyNumber.module);
        return this.appService.get(this.globlalConstants.BWQRY_GETPOLICYFEE, params);
    };
    BwqueriesService.prototype.checkIf21CPolicyRenewalOffer = function (mco, policySymbol, policyNumber, policyMod) {
        var params = new HttpParams()
            .set('mco', mco)
            .set('polSym', policySymbol)
            .set('polNum', policyNumber)
            .set('polMod', policyMod);
        // Tracker.log('BWQueryService: checkIf21stPolicyRenewalOffer() is invoked for policy:: ', params);
        return this.appService.get(this.globlalConstants.BWQRY_IS_21CRENEWALOFFER, params);
    };
    BwqueriesService.prototype.checkIf21CPolicyEsigEligible = function (mco, policySymbol, policyNumber, policyMod) {
        // Tracker.log('BWQueryService: checkIf21CPolicyEsigEligible() is invoked for policy:: ', policyNumber, mco);
        var params = new HttpParams()
            .set('mco', mco)
            .set('polSym', policySymbol)
            .set('polNum', policyNumber)
            .set('polMod', policyMod);
        return this.appService.get(this.globlalConstants.BWQRY_IS_21CESIGELIGIBLE, params);
    };
    BwqueriesService.prototype.checkIfPolicyWetSigned = function (mco, policySymbol, policyNumber, policyMod) {
        // Tracker.log('BWQueryService: checkIfPolicyWetSigned() is invoked for policy:: ', policyNumber, mco);
        var params = new HttpParams()
            .set('mco', mco)
            .set('polSym', policySymbol)
            .set('polNum', policyNumber)
            .set('polMod', policyMod);
        return this.appService.get(this.globlalConstants.BWQRY_IS_WETSIGNED, params);
    };
    BwqueriesService.prototype.checkIfPPaymentHasMadeOrEFT = function (mco, policySymbol, policyNumber, policyMod) {
        // Tracker.log('BWQueryService: checkIfPPaymentHasMadeOrEFT() is invoked for policy:: ', policyNumber, mco);
        var params = new HttpParams()
            .set('mco', mco)
            .set('polSym', policySymbol)
            .set('polNum', policyNumber)
            .set('polMod', policyMod);
        return this.appService.get(this.globlalConstants.BWQRY_HAS_PAYMENTMADEOREFT, params);
    };
    BwqueriesService.prototype.saveFeedback = function (feedback) {
        return this.appService.post(this.globlalConstants.BWUTIL_SAVEFEEDBACK, feedback);
    };
    BwqueriesService.ngInjectableDef = i0.defineInjectable({ factory: function BwqueriesService_Factory() { return new BwqueriesService(i0.inject(i1.BwappService), i0.inject(i2.BwstoreService), i0.inject(i3.BWErrorhandler)); }, token: BwqueriesService, providedIn: "root" });
    return BwqueriesService;
}());
export { BwqueriesService };
