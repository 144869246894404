import { BehaviorSubject, forkJoin, ReplaySubject } from 'rxjs';
import { GlobalConstants } from '../../constants/global.constant';
import { MessageConstants } from '../../constants/message.constant';
import { BwqueriesService } from '../../services/api/bwquery/bwqueries.service';
import { EdmrService } from '../../services/api/edmr/edmr.service';
import { EsigService } from '../../services/api/esig/esig.service';
import { GoPaperlessService } from '../../services/api/gopaperless/go-paperless.service';
import { PolicydetailsrestService } from '../../services/api/policy/policydetailsrest.service';
import { UsmsecurityService } from '../../services/api/usmsecurity/usmsecurity.service';
import { BWErrorhandler } from '../../services/errorhandler';
import { FullPolicyDetails } from '../model/api/bwibic/fullpolicydetails.model';
import { Authenticatephreq } from '../model/authentication/authenticatephreq';
import { PolicyHolder } from '../model/authentication/authenticatephres';
import { BWError } from '../model/error.model';
import { CommonUtil } from '../utils/commonutil';
import { BwstoreService } from './bwstore.service';
import { Tracker } from '../utils/tracker';
import { Websessionlogreq } from '../model/api/policymgmt/websessionlogreq.model';
import { PolicymgtService } from '../../services/api/policymgmt/policymgt.service';
import { RequesttocancelStoreInfo } from '../model/requesttocancelinfo.model';
import { VehicleEndtInfoStore } from '../model/endorsements/vehicle-endtinfo.model';
import { SSOLoginRequest } from '../model/api/policyholder/findph.model';
import { PolicyholderService } from '../../services/api/policyholder/policyholder.service';
import { Router, ActivatedRoute } from '@angular/router';
import { StateworkflowService } from '../../services/stateworkflow/stateworkflow.service';
import { BwibicService } from '../../services/api/bwibic/bwibic.service';
import { formatDate } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "../../services/api/usmsecurity/usmsecurity.service";
import * as i2 from "../../services/api/bwquery/bwqueries.service";
import * as i3 from "../../services/api/esig/esig.service";
import * as i4 from "../../services/api/edmr/edmr.service";
import * as i5 from "../../services/api/policy/policydetailsrest.service";
import * as i6 from "../../services/api/gopaperless/go-paperless.service";
import * as i7 from "./bwstore.service";
import * as i8 from "../../services/api/policymgmt/policymgt.service";
import * as i9 from "../../services/api/policyholder/policyholder.service";
import * as i10 from "../../services/errorhandler";
import * as i11 from "../../services/stateworkflow/stateworkflow.service";
import * as i12 from "@angular/router";
import * as i13 from "../../services/api/bwibic/bwibic.service";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(usmSecurityService, bwQueryService, esigService, edmrService, plcyDetailsService, paperlessService, _storeService, _plcyMngmntService, policyHolderService, errorHandler, stateFlowService, router, route, bwibicService) {
        this.usmSecurityService = usmSecurityService;
        this.bwQueryService = bwQueryService;
        this.esigService = esigService;
        this.edmrService = edmrService;
        this.plcyDetailsService = plcyDetailsService;
        this.paperlessService = paperlessService;
        this._storeService = _storeService;
        this._plcyMngmntService = _plcyMngmntService;
        this.policyHolderService = policyHolderService;
        this.errorHandler = errorHandler;
        this.stateFlowService = stateFlowService;
        this.router = router;
        this.route = route;
        this.bwibicService = bwibicService;
        this.globlalConstants = GlobalConstants;
        // Authentication Subject
        this.isAuthenticatedSubject = new ReplaySubject(1);
        this.isUserLoggedIn = this.isAuthenticatedSubject.asObservable();
        // GetEnvelopes Subject
        this.envelopesSubject = new BehaviorSubject([]);
        this.getEnvelopesSub = this.envelopesSubject.asObservable();
        this.userLoggedOut = true;
        // phUser Subject
        this.pHUserSubject = new ReplaySubject(1);
        this.phUserObject = this.pHUserSubject.asObservable();
        this.isLogoutDisabled = new BehaviorSubject(false);
        // set token if saved in local storage
        // var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        // this._token = currentUser && currentUser.token;
        this._baseURL = GlobalConstants.baseURL;
    }
    AuthenticationService.prototype.ssologin = function (token, bwOktaSSOEnabled) {
        var _this = this;
        this.ssoreq = new SSOLoginRequest();
        this.ssoreq.ssot = token;
        // Tracker.log('Authenticate Service ', this.authreq);
        return new Promise(function (resolve, reject) {
            _this.policyHolderService.tokenLogin(_this.ssoreq, bwOktaSSOEnabled).subscribe(function (phres) {
                Tracker.loginfo('1. Invoked policyHolderService.tokenLogin():::', 'login()', 'This is the Authentication PH service:  SSO LOGIN', 'Response Payload' + JSON.stringify(phres));
                if (phres !== null && phres.authenticatePolicyHolderResult === false) {
                    return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'S01', 'errordesc': phres.errorMsg, 'source': 'USMSecurityService' })));
                }
                else {
                    // putting the data into subject
                    _this.pHUserSubject.next(phres.phUser);
                    // this.saveUserLogin(phres.phUser);
                    resolve(phres.phUser);
                }
            }, function (error) {
                Tracker.logerror('AuthenticationService', 'login', 'authenticatePH', 'Error authenticating BW policy holder', error);
                return reject(error);
            });
        });
    };
    // TODO: REMOVE This function
    /**
     * 1. Authenitacte user using PolicyHolder Service.
     * 2. Verify successful login.
     * 3. Return error message if login fails.
     * 4. Receive the JWT and save JWT in local storage.
     *
     * @param email
     * @param password
     */
    AuthenticationService.prototype.login_with21C = function (userid, password) {
        var _this = this;
        this.authreq = new Authenticatephreq();
        this.authreq.userName = userid;
        this.authreq.password = password;
        this.authreq.appID = GlobalConstants.APPID;
        // Tracker.log('Authenticate Service ', this.authreq);
        return new Promise(function (resolve, reject) {
            _this.usmSecurityService.authenticatePH(_this.authreq).subscribe(function (phres) {
                // Tracker.log('1. Invoked usmSecurityService.authenticatePH():::', phres);
                Tracker.loginfo('1. AuthenticationService', 'login_with21C()', 'This is the Authentication PH service: LOGIN', 'Response Payload' + JSON.stringify(phres));
                if (phres !== null && phres.authenticatePolicyHolderResult === false) {
                    return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'S01', 'errordesc': phres.errorMsg, 'source': 'USMSecurityService' })));
                    /*return reject(new BWError(
                      {'errorcode': 'S01', 'errordesc': phres.errorMsg, 'source': 'USMSecurityService'}));*/
                }
                else {
                    _this.saveUserLogin(phres.phUser);
                    var mco_1 = phres.phUser.pointHookID.substr(0, 2);
                    var symbol_1 = phres.phUser.pointHookID.substr(2, 3);
                    var policynum_1 = phres.phUser.pointHookID.substr(5);
                    // // Tracker.log('Policy Symbol :: ', symbol, ' Policy Number :: ', policynum);
                    _this.bwQueryService.getPolicyLocationStatusAndMaxMod(symbol_1, policynum_1)
                        .subscribe(function (modres) {
                        Tracker.loginfo('2. Invoked bwQueryService.getPolicyLocationStatusAndMaxMod():::', 'login_with21C()', 'This is the getPolicyLocationStatusAndMaxMod service: LOGIN', 'Response Payload' + JSON.stringify(modres));
                        phres.phUser.policyMod = modres.strPolicyMod;
                        var library = modres.location;
                        _this.updateUserLogin(phres.phUser);
                        var policyNumber = symbol_1.concat(policynum_1).concat(modres.strPolicyMod);
                        _this.bwQueryService.getPolicyBasicContract(policyNumber).subscribe(function (basicPolicyContract) {
                            Tracker.loginfo('3. Invoked bwQueryService.getPolicyBasicContract()::::', 'login_with21C()', 'This is the getPolicyBasicContract service: LOGIN', 'Response Payload' + JSON.stringify(basicPolicyContract));
                            if (basicPolicyContract) {
                                var stateCd = '04'; // basicPolicyContract.basicPlcyContractInfo.RISK0STATE;
                                mco_1 = '91';
                                var phName = basicPolicyContract.basicPlcyContractInfo.ADD0LINE01;
                                // Check for CA44, CA91, CA92, HI90
                                if (GlobalConstants.STATECD_04 === stateCd && GlobalConstants.MCO_44 === mco_1) {
                                    return reject(new BWError({ 'errorcode': '44', 'errordesc': MessageConstants.CA44_ERROR, 'source': 'LoginService' }));
                                }
                                else if ((GlobalConstants.STATECD_04 === stateCd && GlobalConstants.MCO_91 === mco_1) ||
                                    (GlobalConstants.STATECD_04 === stateCd && GlobalConstants.MCO_92 === mco_1) ||
                                    (GlobalConstants.STATECD_52 === stateCd && GlobalConstants.MCO_90 === mco_1)) {
                                    return reject(new BWError({ 'errorcode': '21C', 'errordesc': MessageConstants.CA44_ERROR, 'source': 'LoginService' }));
                                }
                                else {
                                    // Tracker.log('Executing 21C Logic ######################');
                                    // Verify 21C
                                    forkJoin(_this.bwQueryService.checkIf21CPolicyRenewalOffer(mco_1, symbol_1, policynum_1, modres.strPolicyMod), _this.esigService.getEnvelops('sss', 'hhh', policyNumber))
                                        .subscribe(function (_a) {
                                        var tfcRenewalRes = _a[0], envelopesRes = _a[1];
                                        Tracker.loginfo('4. Invoked Async bwQueryService.checkIf21CPolicyRenewalOffer() and esigService.getEnvelops():::', 'login_with21C()', 'This is the checkIf21CPolicyRenewalOffer and getEnvelops service: LOGIN', 'Response Payload' + JSON.stringify(tfcRenewalRes) + ' ' + JSON.stringify(envelopesRes));
                                        // Verify if (21c and hasPendingDccs) or (21C and hasPendingPayment) return to 21st.com
                                        // else return to my policy
                                        _this.verifyIf21cPolicy(tfcRenewalRes, envelopesRes, mco_1, symbol_1, policynum_1, modres.strPolicyMod, library, phres)
                                            .then(function (tfcstatus) {
                                            if (tfcstatus === true) {
                                                _this.verifyIfPolicyHasPendingPayment(mco_1, symbol_1, policynum_1, modres.strPolicyMod)
                                                    .then(function (payres) {
                                                    if (payres === true || (envelopesRes && CommonUtil.checkPlcyHasEnvelopes(envelopesRes.envelopes))) {
                                                        return reject(new BWError({ 'errorcode': 'ESIG', 'errordesc': MessageConstants.CA44_ERROR, 'source': 'LoginService' }));
                                                    }
                                                    else {
                                                        _this.isAuthenticatedSubject.next(true);
                                                        resolve(phres.phUser);
                                                    }
                                                });
                                            }
                                            else if (envelopesRes && CommonUtil.checkPlcyHasEnvelopes(envelopesRes.envelopes) === true) {
                                                return reject(new BWError({ 'errorcode': 'ESIG', 'errordesc': MessageConstants.CA44_ERROR, 'source': 'LoginService' }));
                                            }
                                            else {
                                                _this.isAuthenticatedSubject.next(true);
                                                resolve(phres.phUser);
                                            }
                                        });
                                    }, function (error) { return console.error('Major Error in the Fork Join ###############', error); });
                                    // // Tracker.log('Executing 21C Logic Completed ######################');
                                }
                            }
                        });
                    });
                }
            }, function (error) {
                Tracker.logerror('AuthenticationService', 'login_with21C', 'authenticatePH', 'Error authenticating 21C policy holder', error);
                return reject(error);
            });
        });
    };
    /**
     * 1. Authenitacte user using PolicyHolder Service.
     * 2. Verify successful login.
     * 3. Return error message if login fails.
     * 4. Receive the JWT and save JWT in local storage.
     *
     * @param email
     * @param password
     */
    AuthenticationService.prototype.login = function (userid, password) {
        var _this = this;
        this.authreq = new Authenticatephreq();
        this.authreq.userName = userid;
        this.authreq.password = password;
        this.authreq.appID = GlobalConstants.APPID;
        // Tracker.log('Authenticate Service ', this.authreq);
        return new Promise(function (resolve, reject) {
            _this.usmSecurityService.authenticatePH(_this.authreq).subscribe(function (phres) {
                Tracker.loginfo('1. Invoked usmSecurityService.authenticatePH():::', 'login()', 'This is the Authentication PH service: LOGIN', 'Response Payload' + JSON.stringify(phres));
                if (phres !== null && phres.authenticatePolicyHolderResult === false) {
                    if (phres.errorMsg === '90') {
                        return reject(new BWError({ 'errorcode': 'S01', 'errordesc': phres.errorMsg, 'source': 'USMSecurityService' }));
                    }
                    return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'S01', 'errordesc': phres.errorMsg, 'source': 'USMSecurityService' })));
                }
                else {
                    // putting the data into subject
                    _this.pHUserSubject.next(phres.phUser);
                    // this.saveUserLogin(phres.phUser);
                    resolve(phres.phUser);
                }
            }, function (error) {
                if (error.error.code === 'E0000004' || error.error.code === 'E0000007') {
                    return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'S01', 'errordesc': 'Invalid Credentials', 'source': 'USMSecurityService' })));
                }
                else {
                    Tracker.logerror('AuthenticationService', 'login', 'authenticatePH', 'Error authenticating BW policy holder', error);
                    return reject(error);
                }
            });
        });
    };
    AuthenticationService.prototype.getPolicyModAndVerifyPendingDocs = function (phUser) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var mco = phUser.pointHookID.substr(0, 2);
            var symbol = phUser.pointHookID.substr(2, 3);
            var policynum = phUser.pointHookID.substr(5);
            _this.bwQueryService.getPolicyCurrentMod(symbol, policynum)
                .subscribe(function (modres) {
                // Tracker.log('2. Invoked bwQueryService.getPolicyCurrentMod :::', JSON.stringify(modres));
                Tracker.loginfo('2. Invoked bwQueryService.getPolicyCurrentMod :::', 'getPolicyModAndVerifyPendingDocs()', 'This is the getPolicyModAndVerifyPendingDocs service: LOGIN', 'Response Payload' + JSON.stringify(modres));
                if (modres) {
                    phUser.policyMod = modres.module;
                    var policyNumber_1 = symbol.concat(policynum).concat(modres.module);
                    _this.plcyDetailsService.getPolicyHolderInfo(policyNumber_1, mco).subscribe(function (pniInfores) {
                        if (pniInfores && pniInfores.Result === 'success') {
                            Tracker.loginfo('3. Invoked plcyDetailsService.getPolicyHolderInfo :::', 'getPolicyHolderInfo()', 'This is the getPolicyHolderInfo service: LOGIN', 'Response Payload' + JSON.stringify(pniInfores));
                            var phInfo_1 = pniInfores.PolicyHolderInfo[0];
                            phUser.firstName = phInfo_1.FirstName;
                            phUser.lastName = phInfo_1.LastName;
                            phUser.fullName = phInfo_1.FullName;
                            phUser.dob = phInfo_1.DOB;
                            phUser.phone = phInfo_1.HomePhoneNumber;
                            phUser.riskstate = phInfo_1.RiskState;
                            phUser.IsGaragingAddressDifferent = phInfo_1.IsGaragingAddressDifferent;
                            phUser.IsAgentEligibleForEndorsement = phInfo_1.IsAgentEligibleForEndorsement;
                            phUser.IsNNOPolicy = phInfo_1.IsNNOPolicy;
                            phUser.SR22FR44Status = phInfo_1.SR22FR44Status;
                            phUser.hasRenewal = phInfo_1.HasRenewal;
                            phUser.IsUpdateAddressEligible = phInfo_1.IsUpdateAddressEligible;
                            phUser.IsAddVehicleEligible = phInfo_1.IsAddVehicleEligible;
                            phUser.IsReplaceVehicleEligible = phInfo_1.IsReplaceVehicleEligible;
                            phUser.IsRemoveVehicleEligible = phInfo_1.IsRemoveVehicleEligible;
                            phUser.HasUnderwritingAlerts = phInfo_1.HasUnderwritingAlerts;
                            phUser.HasMissingDocuments = phInfo_1.HasMissingDocuments;
                            phUser.HasCDWCoverage = phInfo_1.HasCDWCoverage;
                            phUser.HasUMPDCoverage = phInfo_1.HasUMPDCoverage;
                            // phUser.SR22FR44Status = 'None';
                            phUser.HasAutoLoanLeaseCoverage = phInfo_1.HasAutoLoanLeaseCoverage; // phUser.SR22FR44Status = 'None';
                            phUser.HasUIMPDCoverage = phInfo_1.HasUIMPDCoverage;
                            // set PolicyHolder in the PH subject for CHAT button visibility logic in App component
                            _this.pHUserSubject.next(phUser);
                            phUser.HasAutoLoanLeaseCoverage = phInfo_1.HasAutoLoanLeaseCoverage; // phUser.SR22FR44Status = 'None';
                            phUser.HasUIMPDCoverage = phInfo_1.HasUIMPDCoverage;
                            // set PolicyHolder in the PH subject for CHAT button visibility logic in App component
                            if (phInfo_1 && phInfo_1.NewAmountDue && phInfo_1.NewAmountDue.DueDate) {
                                phUser.reInstateDueDate = phInfo_1.NewAmountDue.DueDate;
                                phUser.reInstateDueamount = phInfo_1.NewAmountDue.ReinstLapseAmt;
                                phUser.reInstatePolicyStatus = phInfo_1.NewAmountDue.PolicyStatus;
                            }
                            _this.pHUserSubject.next(phUser);
                            // phUser.IsGaragingAddressDifferent = false;
                            if (GlobalConstants.STATE_CA === phUser.riskstate && GlobalConstants.MCO_44 === mco) {
                                return reject(new BWError({ 'errorcode': '44', 'errordesc': MessageConstants.CA44_ERROR, 'source': 'LoginService' }));
                            }
                            else if ((GlobalConstants.STATE_CA === phUser.riskstate && GlobalConstants.MCO_91 === mco) ||
                                (GlobalConstants.STATE_CA === phUser.riskstate && GlobalConstants.MCO_92 === mco) ||
                                (GlobalConstants.STATE_HI === phUser.riskstate && GlobalConstants.MCO_90 === mco)) {
                                return reject(new BWError({ 'errorcode': '21C', 'errordesc': MessageConstants.CA44_ERROR, 'source': 'LoginService' }));
                            }
                            else {
                                forkJoin(_this.esigService.isESigImplemented(mco, phInfo_1.RiskState), _this.paperlessService.getAutoAuthValues(policyNumber_1, mco), _this.esigService.getEnvelops(phInfo_1.FirstName, phInfo_1.LastName, policyNumber_1))
                                    .subscribe(function (_a) {
                                    var esigimplresp = _a[0], gopaperlessresp = _a[1], envelopsres = _a[2];
                                    // Retrieving Esig Status
                                    if (esigimplresp && esigimplresp.status !== 'FAILURE' && esigimplresp.isImplemented === true) {
                                        // During Login flow - MI Reform Bill starts //keerthana
                                        // display state specific text on pending doc screen
                                        if (GlobalConstants.STATE_MI === phInfo_1.RiskState && phUser.esigstatus) {
                                            phUser.stateMIHasEnvelope = true;
                                            _this.pHUserSubject.next(phUser);
                                        }
                                        if (GlobalConstants.STATE_MI === phInfo_1.RiskState && !phUser.esigstatus) {
                                            // Get the library & maxMod of the policy resides
                                            _this.bwQueryService.getPolicyLocationStatusAndMaxMod(symbol, policynum)
                                                .subscribe(function (polLocMaxMod) {
                                                Tracker.loginfo('4.a.bwQueryService.getPolicyLocationStatusAndMaxMo/policyd():::', 'getPolicyModAndVerifyPendingDocs()', 'This is the getPolicyLocationStatusAndMaxMod service: LOGIN', 'Response Payload' + JSON.stringify(modres));
                                                var pollib = polLocMaxMod.location;
                                                var maxMod = polLocMaxMod.strPolicyMod;
                                                // Get policy effective date & policy expirt date
                                                _this.bwibicService.getAllPolicyInfo(mco, symbol, policynum, maxMod).
                                                    subscribe(function (policyresp) {
                                                    if (policyresp) {
                                                        var policyEffDate_1 = formatDate(policyresp.policyInfo.PolicyEffectiveDate, 'yyyy-MM-dd', 'en-US');
                                                        var policyExpDate = formatDate(policyresp.policyInfo.PolicyExpireDate, 'yyyy-MM-dd', 'en-US');
                                                        _this.todayDate = CommonUtil.getDateNowEST();
                                                        var formattedCurrentDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
                                                        var adding30Days = new Date();
                                                        adding30Days.setDate(adding30Days.getDate() + 30);
                                                        /// const formattedAdd30Days =  new Date(formatDate(adding30Days, 'yyyy-MM-dd', 'en-US'));
                                                        // this.effectDtCheck =   policyEffDate >= '2020-06-02' &&
                                                        // policyEffDate <= '2020-08-02'; // TODO: only for QA testing.Remove for prod
                                                        var formattedAdd30Days = formatDate(adding30Days, 'yyyy-MM-dd', 'en-US');
                                                        _this.effectDtCheck = policyEffDate_1 >= _this.todayDate &&
                                                            policyEffDate_1 <= formattedAdd30Days;
                                                        Tracker.loginfo('Call MI logic:::', 'getPolicyModAndVerifyPendingDocs()', 'LOGIN', 'for policy:' + policyNumber_1 + _this.effectDtCheck + phInfo_1.HasRenewal);
                                                        if ((phInfo_1.HasRenewal && _this.effectDtCheck) || !phInfo_1.HasRenewal) {
                                                            // ***************************************************
                                                            _this.stateFlowService.stateMIWorkflow(mco, phInfo_1.RiskState, symbol, policynum, maxMod, phInfo_1.HasRenewal, pollib, phInfo_1.FirstName, phInfo_1.LastName, 'login', phUser.userId).
                                                                then(function (stateWorkflowresp) {
                                                                if (stateWorkflowresp && stateWorkflowresp.envelopeCreated) {
                                                                    phUser.esigstatus = true;
                                                                    _this.isAuthenticatedSubject.next(true);
                                                                    Tracker.loginfo('4. Invoked esigService.getEnvelops():::', 'getEnvelops()', 'This is the esigService.getEnvelops() service: LOGIN', 'Response Payload' +
                                                                        JSON.stringify(stateWorkflowresp.envelopes));
                                                                    phUser.esigstatus = true;
                                                                    phUser.stateMIHasEnvelope = true;
                                                                    phUser.renewalPolEffDate = policyEffDate_1;
                                                                    // PERFORMANCE: Added this Subject to avoid calling get Envelopes again in Login Flow
                                                                    _this.envelopesSubject.next(stateWorkflowresp.envelopes);
                                                                    _this.isAuthenticatedSubject.next(true);
                                                                    _this.pHUserSubject.next(phUser);
                                                                }
                                                                else {
                                                                    // QA defect 17577 fix starts
                                                                    var hasPendingEnvs = false;
                                                                    if (envelopsres && envelopsres.status.toUpperCase() === 'SUCCESS') {
                                                                        hasPendingEnvs = CommonUtil.checkPlcyHasEnvelopes(envelopsres.envelopes);
                                                                    }
                                                                    if (hasPendingEnvs) {
                                                                        phUser.esigstatus = true;
                                                                        phUser.stateMIHasEnvelope = true;
                                                                        // PERFORMANCE: Added this Subject to avoid calling get Envelopes again in Login Flow
                                                                        _this.envelopesSubject.next(envelopsres.envelopes);
                                                                        _this.isAuthenticatedSubject.next(true);
                                                                        _this.pHUserSubject.next(phUser);
                                                                    }
                                                                    else {
                                                                        // QA defect fix ends
                                                                        phUser.esigstatus = false;
                                                                        phUser.stateMIHasEnvelope = false;
                                                                        // this.updateUserLogin(phUser);
                                                                        _this.isAuthenticatedSubject.next(true);
                                                                        _this.pHUserSubject.next(phUser);
                                                                        resolve(phUser);
                                                                    }
                                                                }
                                                                // Retrieving Go Paperless Status
                                                                if (gopaperlessresp.getAutoAuthValuesResult === 'P' ||
                                                                    gopaperlessresp.getAutoAuthValuesResult === 'B') {
                                                                    phUser.gopaperlessInd = true;
                                                                }
                                                                else {
                                                                    phUser.gopaperlessInd = false;
                                                                }
                                                                _this.isAuthenticatedSubject.next(true);
                                                                resolve(phUser);
                                                            }); // Stateworkflow service call
                                                        }
                                                        else {
                                                            Tracker.loginfo('4. Invoked esigService.getEnvelops():::', 'getEnvelops()', 'This is the esigService.getEnvelops() service: LOGIN', 'Response Payload' + JSON.stringify(envelopsres));
                                                            var hasPendingEnvelops = false;
                                                            if (envelopsres && envelopsres.status.toUpperCase() === 'SUCCESS') {
                                                                hasPendingEnvelops = CommonUtil.checkPlcyHasEnvelopes(envelopsres.envelopes);
                                                            }
                                                            if (hasPendingEnvelops) {
                                                                phUser.esigstatus = true;
                                                                _this.envelopesSubject.next(envelopsres.envelopes);
                                                                _this.isAuthenticatedSubject.next(true);
                                                                _this.pHUserSubject.next(phUser);
                                                            }
                                                            else {
                                                                phUser.esigstatus = false;
                                                                _this.isAuthenticatedSubject.next(true);
                                                                _this.pHUserSubject.next(phUser);
                                                                resolve(phUser);
                                                            }
                                                            if (gopaperlessresp.getAutoAuthValuesResult === 'P' ||
                                                                gopaperlessresp.getAutoAuthValuesResult === 'B') {
                                                                phUser.gopaperlessInd = true;
                                                            }
                                                            else {
                                                                phUser.gopaperlessInd = false;
                                                            }
                                                            _this.isAuthenticatedSubject.next(true);
                                                            resolve(phUser);
                                                        } // effect Dt not passed
                                                    } // gttAllPolicy resp
                                                }); // getAllPoicyservice call
                                            }); // getPolicylocationAndmaxmode service call
                                        }
                                        else {
                                            // During Login/Registration flow - MI Reform Bill ends //keerthana
                                            Tracker.loginfo('4. Invoked esigService.getEnvelops():::', 'getEnvelops()', 'This is the esigService.getEnvelops() service: LOGIN', 'Response Payload' + JSON.stringify(envelopsres));
                                            var hasPendingEnvelops = false;
                                            if (envelopsres && envelopsres.status.toUpperCase() === 'SUCCESS') {
                                                hasPendingEnvelops = CommonUtil.checkPlcyHasEnvelopes(envelopsres.envelopes);
                                            }
                                            if (hasPendingEnvelops) {
                                                phUser.esigstatus = true;
                                                // PERFORMANCE: Added this Subject to avoid calling get Envelopes again in Login Flow
                                                _this.envelopesSubject.next(envelopsres.envelopes);
                                                _this.isAuthenticatedSubject.next(true);
                                                _this.pHUserSubject.next(phUser);
                                            }
                                            else {
                                                phUser.esigstatus = false;
                                                // this.updateUserLogin(phUser);
                                                _this.isAuthenticatedSubject.next(true);
                                                _this.pHUserSubject.next(phUser);
                                                resolve(phUser);
                                            }
                                            // Retrieving Go Paperless Status
                                            // console.log('Esig Status: ', phUser.esigstatus, '
                                            // Go Paperless status: ', gopaperlessresp.getAutoAuthValuesResult);
                                            if (gopaperlessresp.getAutoAuthValuesResult === 'P' || gopaperlessresp.getAutoAuthValuesResult === 'B') {
                                                // && gopaperlessresp.getAutoAuthValuesResult !== 'V'
                                                //      && gopaperlessresp.getAutoAuthValuesResult !== 'B'
                                                phUser.gopaperlessInd = true;
                                            }
                                            else {
                                                phUser.gopaperlessInd = false;
                                            }
                                            // create webSessionlogRecord for successfull login
                                            // this.updateUserLogin(phUser);
                                            _this.isAuthenticatedSubject.next(true);
                                            // this.createWebSessLog(phUser);
                                            resolve(phUser);
                                        } // state check ends
                                    }
                                    else {
                                        // Retrieving Go Paperless Status
                                        if (gopaperlessresp.getAutoAuthValuesResult === 'P' || gopaperlessresp.getAutoAuthValuesResult === 'B') {
                                            // && gopaperlessresp.getAutoAuthValuesResult !== 'V'
                                            //      && gopaperlessresp.getAutoAuthValuesResult !== 'B'
                                            phUser.gopaperlessInd = true;
                                        }
                                        else {
                                            phUser.gopaperlessInd = false;
                                        }
                                        // create webSessionlogRecord for successfull login
                                        // this.updateUserLogin(phUser);
                                        _this.isAuthenticatedSubject.next(true);
                                        // this.createWebSessLog(phUser);
                                        resolve(phUser);
                                    }
                                    /// QC:210147 ends
                                });
                            }
                        }
                        else {
                            // this.updateUserLogin(phUser);
                            _this.isAuthenticatedSubject.next(true);
                            resolve(phUser);
                        }
                    });
                }
                else {
                    return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'ERROR_MOD', 'errordesc': 'Error retrieving error', 'source': 'BWQueryService' })));
                }
            }, function (error) {
                // Tracker.log('Error Occurred in login Getting Mod Error', error);
                return reject(error);
            });
        });
    };
    // TODO: REMOVE This function
    AuthenticationService.prototype.verifyIf21cPolicy = function (tfcRenewalRes, envelopesRes, mco, symbol, policynum, mod, library, phres) {
        var _this = this;
        var policyNumber = symbol.concat(policynum).concat(mod);
        return new Promise(function (resolve, reject) {
            if (tfcRenewalRes && tfcRenewalRes.result === 'SUCCESS' && tfcRenewalRes.tfcPolicyRenewOffer === true) {
                _this.bwQueryService.checkIf21CPolicyEsigEligible(mco, symbol, policynum, mod)
                    .subscribe(function (tfcEsigEligibleRes) {
                    Tracker.loginfo('5. Invoked bwQueryService.checkIf21CPolicyEsigEligible()::::', 'checkIf21CPolicyEsigEligible()', 'This is the checkIf21CPolicyEsigEligible service: LOGIN', 'Response Payload'
                        + JSON.stringify(tfcEsigEligibleRes));
                    if (tfcEsigEligibleRes && tfcEsigEligibleRes.result === 'SUCCESS'
                        && tfcEsigEligibleRes.tfcStPolicyESigEligibleResult === true) {
                        _this.bwQueryService.checkIfPolicyWetSigned(mco, symbol, policynum, mod)
                            .subscribe(function (policyWetSignRes) {
                            Tracker.loginfo('6. Invoked bwQueryService.checkIfPolicyWetSigned()::::', 'checkIfPolicyWetSigned()', 'This is the checkIfPolicyWetSigned service: LOGIN', 'Response Payload'
                                + JSON.stringify(policyWetSignRes));
                            if (policyWetSignRes && policyWetSignRes.result === 'SUCCESS'
                                && policyWetSignRes.tfcPolicyWetSignedResult === false) {
                                // Check if Policy has Pending Docs or Envelop is created for 21c Policy
                                _this.verifyPendingDocsOrCreateEnvFor21CPlcy(envelopesRes, mco, policyNumber, library)
                                    .then(function (status) {
                                    var plcyHasEnvelops = CommonUtil.checkPlcyHasEnvelopes(envelopesRes.envelopes);
                                    if (status === true || plcyHasEnvelops === true) {
                                        resolve(true);
                                    }
                                    else {
                                        resolve(false);
                                    }
                                });
                            }
                            else {
                                resolve(false);
                            }
                        });
                    }
                    else {
                        resolve(false);
                    }
                });
            }
        });
    };
    // TODO: REMOVE This function
    AuthenticationService.prototype.verifyPendingDocsOrCreateEnvFor21CPlcy = function (envelops, mco, policynumber, library) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            // Tracker.log('verifyPendingDocsOrCreateEnvFor21CPlcy envelops length:: ', envelops.envelopes.length);
            if (envelops && envelops.envelopes && envelops.envelopes.length <= 0) {
                _this.edmrService.invokeEDMRDocuments(policynumber, GlobalConstants.TRANSCODE, GlobalConstants.EDMR_SRC_SYSTEM)
                    .subscribe(function (invokeDocsRes) {
                    // Tracker.log('7. Invoked edmrService.invokeEDMRDocuments():::: ', invokeDocsRes);
                    if (invokeDocsRes && invokeDocsRes.responseStatus === '1') {
                        _this.esigService.createEnvelopeAndWaitForVendor(mco, policynumber, library)
                            .subscribe(function (createEnvRes) {
                            // Tracker.log('8. Invoked esigService.createEnvelopeAndWaitForVendor():::: ', createEnvRes);
                            if (createEnvRes && createEnvRes.status === 'SUCCESS' && createEnvRes.envelope) {
                                resolve(true);
                            }
                        });
                    }
                    else {
                        resolve(false);
                    }
                }, function (error) {
                    resolve(false);
                });
            }
            else {
                resolve(false);
            }
        });
    };
    // TODO: REMOVE This function
    AuthenticationService.prototype.verifyIfPolicyHasPendingPayment = function (mco, plcysym, plcynum, plcymod) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.bwQueryService.checkIfPPaymentHasMadeOrEFT(mco, plcysym, plcynum, plcymod)
                .subscribe(function (paymentmaderes) {
                // Tracker.log('9. Invoked bwQueryService.checkIfPPaymentHasMadeOrEFT():::: ', paymentmaderes);
                if (paymentmaderes && paymentmaderes.result === 'SUCCESS' && paymentmaderes.madePaymentorEFTResult === true) {
                    resolve(true);
                }
                else {
                    resolve(false);
                }
            });
        });
    };
    /**
     * Clear token and remove user from local storage to log user out.
     */
    AuthenticationService.prototype.logout = function () {
        // Tracker.log('I am logging out');
        this._token = null;
        if (this.policyHolder === undefined || this.policyHolder === null) {
            this.policyHolder = new PolicyHolder();
        }
        this._storeService.deleteLoginInfo(this.policyHolder);
        this._storeService.deletePolicyInfo(new FullPolicyDetails());
        this._storeService.deleteRequestToCancelPolicyInfo(new RequesttocancelStoreInfo());
        this._storeService.deleteVehicleEndtDetail(new VehicleEndtInfoStore());
        this._storeService.removeBWCoverages();
        // this._storeService.deleteBwEndorsementInfo(new EndorseMetainfo());
        this.isAuthenticatedSubject.next(false);
    };
    /*
     * Save the Token to Store for future references
    */
    AuthenticationService.prototype.saveUserLogin = function (phuser) {
        this._storeService.createLoginInfo(phuser);
        // localStorage.setItem('phUserToken', phuser.jwtToken);
    };
    AuthenticationService.prototype.updateUserLogin = function (phuser) {
        this._storeService.updateLoginInfo(phuser);
    };
    /**
     * Verify the user token is valid
     * @param token
     */
    AuthenticationService.prototype.verifyToken = function (token) {
    };
    /*
    * Utility Functions
    */
    AuthenticationService.prototype._isUserLoggedIn = function () {
        // Tracker.log('I am hitted every time::::');
        var customerLoginToken = this._bwToken();
        var loginStatusFromService = false;
        this.isUserLoggedIn.subscribe(function (authenticated) {
            loginStatusFromService = authenticated;
        });
        // Tracker.log('I am hitted every time 2 :::: ', customerLoginToken, loginStatusFromService);
        if (customerLoginToken && customerLoginToken !== undefined && customerLoginToken !== null && loginStatusFromService) {
            return true;
        }
        else {
            // loginStatusFromService;
        }
    };
    AuthenticationService.prototype._isUserLoggedOut = function () {
        var token = localStorage.getItem('phUserToken');
        if (token) {
            this.userLoggedOut = false;
        }
    };
    AuthenticationService.prototype._bwToken = function () {
        // Read it from the Store and pass it back
        var currentUserInfoFromStore = this._storeService.getLoginInfoFromStore();
        return currentUserInfoFromStore.jwtToken;
    };
    AuthenticationService.prototype._setLoginStatus = function (status) {
        this.isAuthenticatedSubject.next(status);
    };
    /**
     * Look up user by their email to get security questions
     * @param userId
     */
    AuthenticationService.prototype.forgotPassword = function (userId) {
        // check by user id
        return this.user;
    };
    AuthenticationService.prototype.createWebSessLog = function (plcyHolder) {
        // build the request to log record.
        this.websesssionlogreq = new Websessionlogreq();
        this.websesssionlogreq.state = plcyHolder.riskstate;
        this.websesssionlogreq.appName = GlobalConstants.APPID;
        this.websesssionlogreq.mco = CommonUtil.getMCO(plcyHolder);
        // this.websesssionlogreq.miscellaneousUse1
        this.websesssionlogreq.module = CommonUtil.getPolicyMod(plcyHolder);
        this.websesssionlogreq.policyNumber = CommonUtil.getPolicyNumber(plcyHolder);
        this.websesssionlogreq.symbol = CommonUtil.getPolicySymbol(plcyHolder);
        this.websesssionlogreq.statusCode = '1';
        this.websesssionlogreq.taskType = 'L'; // for login it is L
        // console.log('this._isUserLoggedIn()'+this._isUserLoggedIn());
        this.websesssionlogreq.regStatus = (this._isUserLoggedIn() ? 'Y' : 'N');
        this.websesssionlogreq.userID = plcyHolder.userId;
        if (plcyHolder && plcyHolder.esigstatus === true) {
            this.websesssionlogreq.eSigStatus = 'Y';
        }
        else {
            this.websesssionlogreq.eSigStatus = 'N';
        }
        this._plcyMngmntService.createWebSessionLogRecord(this.websesssionlogreq).subscribe(function (status) {
            Tracker.loginfo('Invoked plcyMngmntService :::', 'createWebSessionLogRecord', 'ResponseStatus', '' + status);
        }, function (error) {
            // this.googleAnalytics.trackEvent('Registration', 'UserRegistrationScreen', 'UserRegistrationContinueClick', 500);
            Tracker.logerror('Invoked plcyMngmntService', 'createWebSessionLogRecord', 'createWebSessionLogRecord', 'Error during WebsessionLog Record.', error);
        });
    };
    AuthenticationService.prototype.createWebSessLogMFA = function (plcyHolder, ismfaSuccessful) {
        // build the request to log record.
        this.websesssionlogreq = new Websessionlogreq();
        this.websesssionlogreq.state = plcyHolder.riskstate;
        this.websesssionlogreq.appName = GlobalConstants.APPID;
        this.websesssionlogreq.mco = CommonUtil.getMCO(plcyHolder);
        // this.websesssionlogreq.miscellaneousUse1
        this.websesssionlogreq.module = CommonUtil.getPolicyMod(plcyHolder);
        this.websesssionlogreq.policyNumber = CommonUtil.getPolicyNumber(plcyHolder);
        this.websesssionlogreq.symbol = CommonUtil.getPolicySymbol(plcyHolder);
        this.websesssionlogreq.statusCode = '1';
        this.websesssionlogreq.taskType = 'M'; // for MFA it is M
        this.websesssionlogreq.regStatus = (ismfaSuccessful ? 'Y' : 'N');
        this.websesssionlogreq.userID = plcyHolder.userId;
        if (plcyHolder && plcyHolder.esigstatus === true) {
            this.websesssionlogreq.eSigStatus = 'Y';
        }
        else {
            this.websesssionlogreq.eSigStatus = 'N';
        }
        this._plcyMngmntService.createWebSessionLogRecord(this.websesssionlogreq).subscribe(function (status) {
            Tracker.loginfo('Invoked plcyMngmntService :::', 'createWebSessionLogRecord', 'ResponseStatus', '' + status);
        }, function (error) {
            // this.googleAnalytics.trackEvent('Registration', 'UserRegistrationScreen', 'UserRegistrationContinueClick', 500);
            Tracker.logerror('Invoked plcyMngmntService', 'createWebSessionLogRecord', 'createWebSessionLogRecord', 'Error during WebsessionLog Record.', error);
        });
    };
    /*
   * Utility Functions
   */
    AuthenticationService.prototype.createphUserObject = function () {
        // Tracker.log('I am hitted every time::::');
        // const phUserObject: PolicyHolder = this.storeService.getLoginInfoFromStore();
        var _this = this;
        this.phUserObject.subscribe(function (data) {
            _this.pHUserSubject.next(_this._storeService.getLoginInfoFromStore());
        });
        // Tracker.log('I am hitted every time 2 :::: ', customerLoginToken, loginStatusFromService);
        /*if (phUserObject && phUserObject !== undefined && phUserObject !== null) {
          return phUserObject;
        } else {
        }*/
    };
    AuthenticationService.prototype.updatePHHolder = function (data) {
        this.pHUserSubject.next(data);
    };
    AuthenticationService.ngInjectableDef = i0.defineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.inject(i1.UsmsecurityService), i0.inject(i2.BwqueriesService), i0.inject(i3.EsigService), i0.inject(i4.EdmrService), i0.inject(i5.PolicydetailsrestService), i0.inject(i6.GoPaperlessService), i0.inject(i7.BwstoreService), i0.inject(i8.PolicymgtService), i0.inject(i9.PolicyholderService), i0.inject(i10.BWErrorhandler), i0.inject(i11.StateworkflowService), i0.inject(i12.Router), i0.inject(i12.ActivatedRoute), i0.inject(i13.BwibicService)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
