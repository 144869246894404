import { OnInit, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
var ConfirmChangesComponent = /** @class */ (function () {
    function ConfirmChangesComponent(confirmChangesModal) {
        this.confirmChangesModal = confirmChangesModal;
        this.emitService = new EventEmitter();
    }
    ConfirmChangesComponent.prototype.ngOnInit = function () {
    };
    ConfirmChangesComponent.prototype.confirmChanges = function (decision) {
        if (decision && decision === 'accept') {
            this.emitService.emit({ status: 'Accepted' });
        }
        else {
            this.emitService.emit({ status: 'Cancelled' });
        }
        this.confirmChangesModal.close();
    };
    return ConfirmChangesComponent;
}());
export { ConfirmChangesComponent };
