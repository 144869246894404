import { ElementRef } from '@angular/core';
var NumbersonlyinputDirective = /** @class */ (function () {
    function NumbersonlyinputDirective(el) {
        this.el = el;
        this.regexStr = '^[0-9]*$';
    }
    NumbersonlyinputDirective.prototype.onKeyDown = function (event) {
        var e = event;
        if (this.appNumbersonlyinput) {
            // Tracker.log(event, this.OnlyNumber);
            if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1) {
                return;
            }
            var ch = (e.key);
            var regEx = new RegExp(this.regexStr);
            if (regEx.test(ch)) {
                return;
            }
            else {
                e.preventDefault();
            }
        }
    };
    return NumbersonlyinputDirective;
}());
export { NumbersonlyinputDirective };
