<a (click)="skipLink()" href onclick="return false;" class="sr-only sr-only-focusable"
   accesskey="k">Skip to content</a> <!-- removed btn btn-link -->
<app-mobile-banner *ngIf="mobileView && bannerFlag"></app-mobile-banner>
<app-header *ngIf="showPage"></app-header>
<!-- Chat -->
<button *ngIf="(this.isUserLoggedIn && this.displayChat)" #chatbutton id="chatbutton"  [disabled]="disableChatButton"
class="btn btn-primary chatbutton_top_right d-md-block btn-responsive"
(click)="showChat()"><i class="fas fa-comments fa-lg"></i>&nbsp;</button>
<div  *ngIf="disableChatButton" class="d-flex justify-content-center">
   <div class="alert alert-warning" role="alert">
      <div class="error-icon"><i class="fa fa-2x fa-info-circle"></i></div>
      &nbsp;
      <div class="text-danger inline-icon padding-left" >
         <p class="alert-text-header" >Your Chat window is open.</p>
         <p class="alert-text-body" >Please close the Chat window before starting a new Chat session.</p>
      </div>
   </div>
</div>
<app-system-errors></app-system-errors>
<!--<main [@routerTransition]="getPage(appOutlet)">
   <router-outlet (activate)="onActivate($event)" #appOutlet="outlet"></router-outlet>
   </main>-->
<router-outlet #main tabindex="-1" (activate)="onActivate($event)"></router-outlet>
<!--<button id="popup" class="feedback-button d-none d-sm-none d-md-block" (click)="showFeedback()">Feedback</button>-->
<!--Commenting below for CES Start-->
<!--<div tabindex="0" *ngIf="!this.isFeedbackClicked && this.routerUrl !== '/quote'  && this.routerUrl !== '/directsalescall'&& this.routerUrl !== '/sorry' && showPage  " #feedback_tab id="feedback_tab" class="feedback_right d-none d-sm-none d-md-block" 
accesskey="w" (click)="showFeedback()">Website Feedback</div>-->
<!--Commenting below for CES End-->
<app-footer *ngIf="showPage"></app-footer>
