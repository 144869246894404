import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalConstants } from '../../../constants/global.constant';
import { CreateEPolicyRequest } from '../../../shared/model/api/gopaperless/createepolicyreq.model';
import { CreateEPolicyResponse } from '../../../shared/model/api/gopaperless/createepolicyres.model';
import { IsGplRolledOutRes } from '../../../shared/model/api/gopaperless/isgplrolledoutres.model';
import { Setepolicyflagreq } from '../../../shared/model/api/gopaperless/setepolicyflagreq';
import { UpdateEpolicyEmailResponse } from '../../../shared/model/api/gopaperless/updateemailres.model';
import { Policynumber } from '../../../shared/model/policynumber';
import { Changeuseremailreq } from '../../../shared/model/registration/changeuseremailreq';
import { Changeuseremailres } from '../../../shared/model/registration/changeuseremailres';
import { Gopaperlessauthres } from '../../../shared/model/registration/gopaperlessauthres';
import { BwstoreService } from '../../../shared/services/bwstore.service';
import { BwappService } from '../../bwapp.service';
import { BWErrorhandler } from '../../errorhandler';

@Injectable({
  providedIn: 'root'
})
export class GoPaperlessService {
  private globlalConstants = GlobalConstants;
  gopaperlessauthres: Gopaperlessauthres;
  _baseURL: string;
  policyNumber: Policynumber;

  changeuseremailreq: Changeuseremailreq;
  changeuseremailres: Changeuseremailres;

  constructor(private appService: BwappService, private storeService: BwstoreService, private errorHandler: BWErrorhandler) {
    this._baseURL = GlobalConstants.baseURL;
   }

   getAutoAuthValues(policynumber: string, mco: string ): Observable<any> {

    // Tracker.log('PolicyNumber: ' + policynumber, 'MCO: ' + mco);
    const params = new HttpParams()
      .set('szPolicyNumber', policynumber)
      .set('szMCO', mco);

    return this.appService.get(this.globlalConstants.GP_AUTO_AUTH_URL, params);
  }

  changeUserEmail() {
    return this.appService
    .post(this.globlalConstants.GP_UPDATE_EMAIL_URL, {phresponse: {body: this.changeuseremailreq}})
    .pipe(
      map(
        data => {
          this.changeuseremailres = data.phresponse;
          // Tracker.log('Gopaperless Service changeUserEmail Response: ', this.changeuseremailres);
        }
      )
    );
  }

  updateEPolicyEmail(email: string, policynumber: string, mco: string): Observable<UpdateEpolicyEmailResponse> {
    this.policyNumber = new Policynumber(policynumber);
    const params = new HttpParams()
    .set('szEmailAddress', email)
    .set('szPolicyNumber', this.policyNumber.toString())
    .set('szMCO', mco)
    .set('bnlResendAuthEmail', 'false')
    .set('szSource', 'BWM');
    return this.appService.get(this.globlalConstants.GP_UPDATE_EMAIL_URL, params);
  }

  unenrollGP(policynumber: string, mco: string): Observable<any> {

    // Tracker.log('PolicyNumber: ' + policynumber, 'MCO: ' + mco);
    const params = new HttpParams()
      .set('szPolicyNumber', policynumber)
      .set('szMCO', mco)
      .set('szSource', this.globlalConstants.APPID);

    return this.appService.get(this.globlalConstants.GP_UNENROLL_URL, params);
  }

  isGPLRolledOut(state: string, ratebook: string, mco: string, pco: string): Observable<IsGplRolledOutRes> {

    // Tracker.log('State: ', state, 'MCO: ', mco, 'Ratebook: ', ratebook, 'PCO:', pco);
    const params = new HttpParams()
      .set('strState', state)
      .set('strRatebook', ratebook)
      .set('strMCO', mco)
      .set('strPCO', pco);

    return this.appService.get(this.globlalConstants.IS_GPL_ROLLED_OUT_URL, params);
  }

  enrollGP(createepolicyrequest: CreateEPolicyRequest): Observable<CreateEPolicyResponse> {

    // Tracker.log('PolicyNumber: ' + createepolicyrequest.szPolicyNbr, 'MCO: ' + createepolicyrequest.szMCO);
    return this.appService.post(this.globlalConstants.GP_ENROLL_URL, createepolicyrequest);
  }

  setEPolicyFlag(ePolicyreq: Setepolicyflagreq): Observable<string> {
    return this.appService.post(this.globlalConstants.GP_SET_EPOLICY_FLAG, ePolicyreq);
  }
}
