import { OnInit } from '@angular/core';
import { MessageConstants } from '../../../constants/message.constant';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
var RequestcancelPendingEndorsementComponent = /** @class */ (function () {
    function RequestcancelPendingEndorsementComponent(reqCancelPendEDModal) {
        this.reqCancelPendEDModal = reqCancelPendEDModal;
        this.pendingMessage = MessageConstants.CANCEL_PENDING_ENDORSEMENT;
        this.cancelFormLocation = '../../../../assets/pdf/PolicyholderRequestToCancel.pdf';
    }
    RequestcancelPendingEndorsementComponent.prototype.ngOnInit = function () { };
    RequestcancelPendingEndorsementComponent.prototype.close = function () {
        this.reqCancelPendEDModal.close();
    };
    RequestcancelPendingEndorsementComponent.prototype.dismiss = function () {
        this.reqCancelPendEDModal.dismiss();
    };
    return RequestcancelPendingEndorsementComponent;
}());
export { RequestcancelPendingEndorsementComponent };
