var Validatecardreq = /** @class */ (function () {
    function Validatecardreq(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return Validatecardreq;
}());
export { Validatecardreq };
var SignOn = /** @class */ (function () {
    function SignOn(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return SignOn;
}());
export { SignOn };
