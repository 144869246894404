import { OnInit, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleAnalyticsService } from '../../../services/analytics/google-analytics.service';
import { EndorsementService } from '../../../../services/api/endorsement/endorsement.service';
import { EndorseMetainfo } from '../../../model/endorsements/endorse-metainfo.model';
import { BwstoreService } from '../../../services/bwstore.service';
import { BWErrorhandler } from '../../../../services/errorhandler';
import { BWError } from '../../../model/error.model';
import { MessageConstants } from '../../../../constants/message.constant';
var CancelChangeReqComponent = /** @class */ (function () {
    function CancelChangeReqComponent(cancelChangeRequestedModal, ga, _storeService, errorHandler, endorsementService) {
        this.cancelChangeRequestedModal = cancelChangeRequestedModal;
        this.ga = ga;
        this._storeService = _storeService;
        this.errorHandler = errorHandler;
        this.endorsementService = endorsementService;
        this.responseEmit = new EventEmitter();
        this.inProgress = false;
        this.bwEndorsementInfo = this._storeService.getEndorsementInfoFromStore();
        this.basicPolicyDetails = this._storeService.getPolicyInfoFromStore();
    }
    CancelChangeReqComponent.prototype.ngOnInit = function () { };
    CancelChangeReqComponent.prototype.confirmChanges = function () {
        var _this = this;
        this.ga.trackEvent('CancelEndorsement', 'CancelChange', 'ContinueClick');
        this.inProgress = true;
        // console.log('BWLog: CancelChangeReqComponent -> confirmChanges -> this.bwEndorsementInfo.endorseBinderNum',
        // this.bwEndorsementInfo.endorseBinderNum);
        if (this.bwEndorsementInfo && this.bwEndorsementInfo.endorseBinderNum !== undefined
            && this.bwEndorsementInfo.endorseBinderNum !== ''
            && this.bwEndorsementInfo.endorseBound === 'false') {
            var msg = 'Customer abandoned the endorsement';
            this.endorsementService.processEndorseVoid(this.bwEndorsementInfo, msg).subscribe(function (endorseVoidRes) {
                if (endorseVoidRes && endorseVoidRes.status === '0') {
                    _this._storeService.deleteBwEndorsementInfo(new EndorseMetainfo());
                    _this.responseEmit.emit(true);
                    _this.cancelChangeRequestedModal.close();
                }
                else {
                    _this.errorHandler.handleApplicationError(new BWError({
                        'errorcode': 'Endorsement', 'errordesc': MessageConstants.AGENT_ERROR_MSG + '<br>' +
                            'Agent Name :' + _this.basicPolicyDetails.agentInfo.AgentName1 + '<br>'
                            + 'Agent Phone Number :' + _this.basicPolicyDetails.agentInfo.ACNM_TELE,
                        'source': 'EndorseBind'
                    }));
                    _this._storeService.deleteBwEndorsementInfo(new EndorseMetainfo());
                    _this.responseEmit.emit(false);
                    _this.cancelChangeRequestedModal.close();
                }
            });
        }
        else {
            console.log(' No changes to void hence returning to my policy');
            this._storeService.deleteBwEndorsementInfo(new EndorseMetainfo());
            this.responseEmit.emit(true);
            this.cancelChangeRequestedModal.close();
        }
    };
    CancelChangeReqComponent.prototype.close = function () {
        this.ga.trackEvent('ReplaceVehicle', 'CancelChange', 'CancelClick');
        this.responseEmit.emit(false);
        this.cancelChangeRequestedModal.close();
    };
    return CancelChangeReqComponent;
}());
export { CancelChangeReqComponent };
