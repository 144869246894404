var EndorseVehicleInfo = /** @class */ (function () {
    function EndorseVehicleInfo(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return EndorseVehicleInfo;
}());
export { EndorseVehicleInfo };
var GaragingAddress = /** @class */ (function () {
    function GaragingAddress(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return GaragingAddress;
}());
export { GaragingAddress };
