import { PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
var SafeHtmlPipe = /** @class */ (function () {
    function SafeHtmlPipe(sanitizer) {
        this.sanitizer = sanitizer;
    }
    SafeHtmlPipe.prototype.transform = function (html) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    };
    return SafeHtmlPipe;
}());
export { SafeHtmlPipe };
