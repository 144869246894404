<div class="modal-body">


  <div *ngIf="!this.isLoaded" class="my-5 text-center">
    <app-bw-spinner [smallSpinner]="false"></app-bw-spinner>
    </div>
  <div *ngIf="this.isLoaded" class="col-10">
    <label>You have requested to update your:</label>
      <ul>
        <li *ngIf="showAddress"><label  for="ph-address" >Mailing Address</label></li>
        <div *ngIf="showAddress" class="col-sm-12">
          <div class="col-sm-6 custom-file noPadding">
             <div> Old :</div>
             
             <address id="ph-address" class="form-control-sm form-control-plaintext pt-0">
              {{policyInfo.PolicyAddress | titlecase }}
              <br/> {{policyInfo.PolicyCity | titlecase}}
              <br/> {{policyInfo.PolicyState}} {{this.policyInfo.PolicyZip.substr(0, 5)}}
            </address>
          </div>
          <div class="col-sm-6 custom-file noPadding">
              <div>New :</div>
              <address id="ph-address" class="form-control-sm form-control-plaintext pt-0">
                  {{mailingAddress.street | titlecase }}
                  <br/> {{mailingAddress.city | titlecase}}
                  <br/> {{mailingAddress.state}} {{mailingAddress.zip}}
                </address>
          </div>
        </div>
        <li *ngIf="showAddress"><label  for="ph-address" >Garaging Address</label></li>
        <div *ngIf="showAddress" class="col-sm-12">
            <div class="col-sm-6 custom-file noPadding">
               <div> Old :</div>
               
               <address id="ph-address" class="form-control-sm form-control-plaintext pt-0">
                  {{policyInfo.PolicyAddress | titlecase }}
                  <br/> {{policyInfo.PolicyCity | titlecase}}
                  <br/> {{policyInfo.PolicyState}} {{this.policyInfo.PolicyZip.substr(0, 5)}}
                </address>
            </div>
            <div class="col-sm-6 custom-file noPadding">
                <div>New :</div>
                <address id="ph-address" class="form-control-sm form-control-plaintext pt-0">
                  {{mailingAddress.street | titlecase }}
                  <br/> {{mailingAddress.city | titlecase}}
                  <br/> {{mailingAddress.state}} {{mailingAddress.zip}}
                </address>
            </div>
          </div>
          <li *ngIf="showPhoneNumber"><label  for="ph-address" >Phone Number</label></li>
          <div *ngIf="showPhoneNumber" class="col-sm-12">
              <div class="col-sm-6 custom-file noPadding">
                 <div> Old :</div>
                  <input id="ph-phone" type="text" readonly class="form-control-plaintext"
                   value="{{authenticatedPhDetails.phone}}" />
              </div>
              <div class="col-sm-6 custom-file noPadding">
                  <div>New :</div>
                    <input id="ph-phone" type="text" readonly class="form-control-plaintext"
                     value="{{mailingAddress.phone}}" />
              </div>
            </div>
      </ul>
<p class="small">Please be advised that this change may cause a change in your total premium.</p>
  </div>


</div>

<div class="modal-footer mt-1"> 
  <button *ngIf="!submitted" type="button" class="btn btn-secondary" (click)="cancelEndorsement()" [disabled] = "!this.isLoaded">Cancel</button>
  <button *ngIf="submitted" type="button" class="btn btn-secondary" (click)="close(addressEditModal)">Close</button>
  <button *ngIf="!submitted" id="acceptAddresschange" type="submit" class="btn btn-primary" (click)="acceptAddressChange()" [disabled] = "!this.isLoaded">Accept</button>
</div>
