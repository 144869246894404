import { GlobalConstants } from '../../constants/global.constant';
import { BwappService } from '../../services/bwapp.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/bwapp.service";
var CesService = /** @class */ (function () {
    function CesService(appService) {
        this.appService = appService;
        this.globlalConstants = GlobalConstants;
    }
    CesService.prototype.getSurveyToken = function (tokenRequest) {
        return this.appService.post(this.globlalConstants.GET_TOKEN, tokenRequest);
    };
    CesService.prototype.closeSurvey = function (tokenRequest) {
        return this.appService.post(this.globlalConstants.UPDATE_NOTHANKS, tokenRequest);
    };
    CesService.ngInjectableDef = i0.defineInjectable({ factory: function CesService_Factory() { return new CesService(i0.inject(i1.BwappService)); }, token: CesService, providedIn: "root" });
    return CesService;
}());
export { CesService };
