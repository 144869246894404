import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, ReplaySubject } from 'rxjs';
import { GlobalConstants } from '../../constants/global.constant';
import { MessageConstants } from '../../constants/message.constant';
import { BwqueriesService } from '../../services/api/bwquery/bwqueries.service';
import { EdmrService } from '../../services/api/edmr/edmr.service';
import { EsigService } from '../../services/api/esig/esig.service';
import { GoPaperlessService } from '../../services/api/gopaperless/go-paperless.service';
import { PolicydetailsrestService } from '../../services/api/policy/policydetailsrest.service';
import { UsmsecurityService } from '../../services/api/usmsecurity/usmsecurity.service';
import { BWErrorhandler } from '../../services/errorhandler';
import { FullPolicyDetails } from '../model/api/bwibic/fullpolicydetails.model';
import { BasicPolicyContract } from '../model/api/bwquery/basiccontract.model';
import { HasBeenWetSignedRes } from '../model/api/bwquery/hasbeenwetsignedres.model';
import { HasMadePaymentorEFTRes } from '../model/api/bwquery/hasmadepaymentoreftres.model';
import { PlcyLocationModStatusAndMaxModRes } from '../model/api/bwquery/plcylocationandmodres.model';
import { PolicyCurrentModRes } from '../model/api/bwquery/policymodres.model';
import { TfcPolicyESigEligibleRes } from '../model/api/bwquery/tfcpolicyesigeligibleres.model';
import { TfcRenewalOfferRes } from '../model/api/bwquery/tfcrenewalofferres.model';
import { InvokeEDMRDocsRes } from '../model/api/edmr/invokedocsres.model';
import { CreateEnvelopeRes } from '../model/api/esig/createenveloperes.model';
import { EnvelopesResp } from '../model/api/esig/esigenvelopres.model';
import { PNIInfoRes } from '../model/api/policyinfo/policyholderinfo.model';
import { Authenticatephreq } from '../model/authentication/authenticatephreq';
import { Authenticatephres, PolicyHolder } from '../model/authentication/authenticatephres';
import { BWError } from '../model/error.model';
import { NewUser } from '../model/newuser.model';
import { CommonUtil } from '../utils/commonutil';
import { BwstoreService } from './bwstore.service';
import { Tracker } from '../utils/tracker';
import { Websessionlogreq } from '../model/api/policymgmt/websessionlogreq.model';
import { PolicymgtService } from '../../services/api/policymgmt/policymgt.service';
import { RequesttocancelStoreInfo } from '../model/requesttocancelinfo.model';
import { VehicleEndtInfoStore } from '../model/endorsements/vehicle-endtinfo.model';
import { EndorseMetainfo } from '../model/endorsements/endorse-metainfo.model';
import { SSOLoginRequest } from '../model/api/policyholder/findph.model';
import { PolicyholderService } from '../../services/api/policyholder/policyholder.service';
import { Router, ActivatedRoute } from '@angular/router';
import { StateworkflowService } from '../../services/stateworkflow/stateworkflow.service';
import { BwibicService } from '../../services/api/bwibic/bwibic.service';
import { formatDate } from '@angular/common';
import { Docusignres } from '../model/api/esig/docusignres';
import { OktaRequest } from '../model/api/okta/okta-model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private globlalConstants = GlobalConstants;
  user: NewUser;
  authreq: Authenticatephreq;
  ssoreq: SSOLoginRequest;
  policyHolder: PolicyHolder;
  _token: any;
  _baseURL: string;
  websesssionlogreq: Websessionlogreq;
  effectDtCheck: boolean;
  todayDate: string;
  docusignres: Docusignres;
  docusignMessage: string;
  // Authentication Subject
  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isUserLoggedIn = this.isAuthenticatedSubject.asObservable();

  // GetEnvelopes Subject
  public envelopesSubject = new BehaviorSubject([]);
  public getEnvelopesSub = this.envelopesSubject.asObservable();

  userLoggedOut = true;
  // phUser Subject
  private pHUserSubject = new ReplaySubject<PolicyHolder>(1);
  public phUserObject = this.pHUserSubject.asObservable();

  isLogoutDisabled = new BehaviorSubject<boolean>(false);

  oktaRequest: OktaRequest;

  constructor(private usmSecurityService: UsmsecurityService, private bwQueryService: BwqueriesService,
    private esigService: EsigService,
    private edmrService: EdmrService,
    private plcyDetailsService: PolicydetailsrestService,
    private paperlessService: GoPaperlessService,
    private _storeService: BwstoreService,
    private _plcyMngmntService: PolicymgtService,
    private policyHolderService: PolicyholderService,
    private errorHandler: BWErrorhandler,
    private stateFlowService: StateworkflowService,
    private router: Router,
    private route: ActivatedRoute,
    private bwibicService: BwibicService) {

    // set token if saved in local storage
    // var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // this._token = currentUser && currentUser.token;
    this._baseURL = GlobalConstants.baseURL;
   }

  ssologin(token: string,bwOktaSSOEnabled : boolean): Promise<PolicyHolder> {
    this.ssoreq = new SSOLoginRequest();
    this.ssoreq.ssot = token;

    // Tracker.log('Authenticate Service ', this.authreq);

    return new Promise((resolve, reject) => {
      this.policyHolderService.tokenLogin(this.ssoreq,bwOktaSSOEnabled).subscribe(
        (phres: Authenticatephres) => {
          Tracker.loginfo('1. Invoked policyHolderService.tokenLogin():::', 'login()', 'This is the Authentication PH service:  SSO LOGIN',
          'Response Payload' + JSON.stringify(phres));
        if (phres !== null && phres.authenticatePolicyHolderResult === false) {
          return reject(this.errorHandler.handleApplicationError(new BWError(
            {'errorcode': 'S01', 'errordesc': phres.errorMsg, 'source': 'USMSecurityService'})));
        } else {
          // putting the data into subject
          this.pHUserSubject.next(phres.phUser);
          // this.saveUserLogin(phres.phUser);
          resolve(phres.phUser);
        }
      },
      (error) => {
        Tracker.logerror('AuthenticationService', 'login', 'authenticatePH',
              'Error authenticating BW policy holder', error);
        return reject(error);
      });
    });
  }
  // TODO: REMOVE This function
  /**
   * 1. Authenitacte user using PolicyHolder Service.
   * 2. Verify successful login.
   * 3. Return error message if login fails.
   * 4. Receive the JWT and save JWT in local storage.
   *
   * @param email
   * @param password
   */
  login_with21C(userid: string, password: string ): Promise<PolicyHolder> {
    this.authreq = new Authenticatephreq();
    this.authreq.userName = userid;
    this.authreq.password = password;
    this.authreq.appID = GlobalConstants.APPID;

    // Tracker.log('Authenticate Service ', this.authreq);

    return new Promise((resolve, reject) => {
      this.usmSecurityService.authenticatePH(this.authreq).subscribe(
        (phres: Authenticatephres) => {
          // Tracker.log('1. Invoked usmSecurityService.authenticatePH():::', phres);
          Tracker.loginfo('1. AuthenticationService', 'login_with21C()', 'This is the Authentication PH service: LOGIN',
                      'Response Payload' + JSON.stringify(phres));
        if (phres !== null && phres.authenticatePolicyHolderResult === false) {
          return reject(this.errorHandler.handleApplicationError(new BWError(
            {'errorcode': 'S01', 'errordesc': phres.errorMsg, 'source': 'USMSecurityService'})));
          /*return reject(new BWError(
            {'errorcode': 'S01', 'errordesc': phres.errorMsg, 'source': 'USMSecurityService'}));*/
        } else {
            this.saveUserLogin(phres.phUser);
            let mco = phres.phUser.pointHookID.substr(0, 2);
            const symbol = phres.phUser.pointHookID.substr(2, 3);
            const policynum = phres.phUser.pointHookID.substr(5);

            // // Tracker.log('Policy Symbol :: ', symbol, ' Policy Number :: ', policynum);
            this.bwQueryService.getPolicyLocationStatusAndMaxMod(symbol, policynum)
              .subscribe((modres: PlcyLocationModStatusAndMaxModRes) => {
              Tracker.loginfo('2. Invoked bwQueryService.getPolicyLocationStatusAndMaxMod():::'
              , 'login_with21C()', 'This is the getPolicyLocationStatusAndMaxMod service: LOGIN',
              'Response Payload' + JSON.stringify(modres));
              phres.phUser.policyMod = modres.strPolicyMod;
              const library = modres.location;
              this.updateUserLogin(phres.phUser);

              const policyNumber = symbol.concat(policynum).concat(modres.strPolicyMod);

              this.bwQueryService.getPolicyBasicContract(policyNumber).subscribe((basicPolicyContract: BasicPolicyContract) => {
               Tracker.loginfo('3. Invoked bwQueryService.getPolicyBasicContract()::::'
                , 'login_with21C()', 'This is the getPolicyBasicContract service: LOGIN',
                'Response Payload' + JSON.stringify(basicPolicyContract));
                if (basicPolicyContract) {
                  const stateCd = '04'; // basicPolicyContract.basicPlcyContractInfo.RISK0STATE;
                  mco = '91';
                  const phName = basicPolicyContract.basicPlcyContractInfo.ADD0LINE01;
                  // Check for CA44, CA91, CA92, HI90
                  if (GlobalConstants.STATECD_04 === stateCd && GlobalConstants.MCO_44 === mco) {
                    return reject(new BWError(
                      {'errorcode': '44', 'errordesc': MessageConstants.CA44_ERROR, 'source': 'LoginService'}));
                  } else if ((GlobalConstants.STATECD_04 === stateCd && GlobalConstants.MCO_91 === mco) ||
                      (GlobalConstants.STATECD_04 === stateCd && GlobalConstants.MCO_92 === mco) ||
                      (GlobalConstants.STATECD_52 === stateCd && GlobalConstants.MCO_90 === mco))  {
                    return reject(new BWError(
                      {'errorcode': '21C', 'errordesc': MessageConstants.CA44_ERROR, 'source': 'LoginService'}));

                  } else {
                    // Tracker.log('Executing 21C Logic ######################');
                    // Verify 21C
                    forkJoin(this.bwQueryService.checkIf21CPolicyRenewalOffer(mco, symbol, policynum, modres.strPolicyMod),
                    this.esigService.getEnvelops('sss', 'hhh', policyNumber))
                      .subscribe(([tfcRenewalRes, envelopesRes])  => {
                        Tracker.loginfo('4. Invoked Async bwQueryService.checkIf21CPolicyRenewalOffer() and esigService.getEnvelops():::'
                        , 'login_with21C()', 'This is the checkIf21CPolicyRenewalOffer and getEnvelops service: LOGIN',
                        'Response Payload' + JSON.stringify(tfcRenewalRes) + ' ' + JSON.stringify(envelopesRes));
                          // Verify if (21c and hasPendingDccs) or (21C and hasPendingPayment) return to 21st.com
                          // else return to my policy
                        this.verifyIf21cPolicy(tfcRenewalRes, envelopesRes, mco, symbol, policynum, modres.strPolicyMod, library, phres)
                          .then(tfcstatus => {
                            if (tfcstatus === true) {
                              this.verifyIfPolicyHasPendingPayment(mco, symbol, policynum, modres.strPolicyMod)
                                .then(payres => {
                                  if (payres === true || (envelopesRes && CommonUtil.checkPlcyHasEnvelopes(envelopesRes.envelopes))) {
                                    return reject(new BWError(
                                      {'errorcode': 'ESIG', 'errordesc': MessageConstants.CA44_ERROR, 'source': 'LoginService'}));
                                  } else {
                                    this.isAuthenticatedSubject.next(true);
                                    resolve(phres.phUser);
                                  }
                                });

                            } else if (envelopesRes && CommonUtil.checkPlcyHasEnvelopes(envelopesRes.envelopes) === true) {
                              return reject(new BWError(
                                {'errorcode': 'ESIG', 'errordesc': MessageConstants.CA44_ERROR, 'source': 'LoginService'}));
                            } else {
                                this.isAuthenticatedSubject.next(true);
                                resolve(phres.phUser);
                            }
                        });

                    },
                    error => console.error('Major Error in the Fork Join ###############', error));
                    // // Tracker.log('Executing 21C Logic Completed ######################');
                  }
                }
              });
            });
        }
      },
      (error) => {
        Tracker.logerror('AuthenticationService', 'login_with21C', 'authenticatePH',
              'Error authenticating 21C policy holder', error);
        return reject(error);
      }
      );
    });
  }

  /**
   * 1. Authenitacte user using PolicyHolder Service.
   * 2. Verify successful login.
   * 3. Return error message if login fails.
   * 4. Receive the JWT and save JWT in local storage.
   *
   * @param email
   * @param password
   */
  login(userid: string, password: string ): Promise<PolicyHolder> {
    this.authreq = new Authenticatephreq();
    this.authreq.userName = userid;
    this.authreq.password = password;
    this.authreq.appID = GlobalConstants.APPID;

    // Tracker.log('Authenticate Service ', this.authreq);

    return new Promise((resolve, reject) => {
      this.usmSecurityService.authenticatePH(this.authreq).subscribe(
        (phres: Authenticatephres) => {
          Tracker.loginfo('1. Invoked usmSecurityService.authenticatePH():::', 'login()', 'This is the Authentication PH service: LOGIN',
            'Response Payload' + JSON.stringify(phres));
          if (phres !== null && phres.authenticatePolicyHolderResult === false) {
            if (phres.errorMsg === '90') {
              return reject(new BWError({ 'errorcode': 'S01', 'errordesc': phres.errorMsg, 'source': 'USMSecurityService' }));
            }
            return reject(this.errorHandler.handleApplicationError(new BWError(
              { 'errorcode': 'S01', 'errordesc': phres.errorMsg, 'source': 'USMSecurityService' })));
          } else {
            // putting the data into subject
            this.pHUserSubject.next(phres.phUser);
            // this.saveUserLogin(phres.phUser);
            resolve(phres.phUser);
          }
        },
        (error) => {
          if (error.error.code === 'E0000004' || error.error.code === 'E0000007') {
            return reject(this.errorHandler.handleApplicationError(new BWError(
              { 'errorcode': 'S01', 'errordesc': 'Invalid Credentials', 'source': 'USMSecurityService' })));
          }            
          else { 
          Tracker.logerror('AuthenticationService', 'login', 'authenticatePH',
            'Error authenticating BW policy holder', error);
          return reject(error);
          }
        });
    });
  }

  getPolicyModAndVerifyPendingDocs(phUser: PolicyHolder): Promise<PolicyHolder> {
    return new Promise((resolve, reject) => {
      const mco = phUser.pointHookID.substr(0, 2);
      const symbol = phUser.pointHookID.substr(2, 3);
      const policynum = phUser.pointHookID.substr(5);

      this.bwQueryService.getPolicyCurrentMod(symbol, policynum)
        .subscribe((modres: PolicyCurrentModRes) => {
        // Tracker.log('2. Invoked bwQueryService.getPolicyCurrentMod :::', JSON.stringify(modres));
        Tracker.loginfo('2. Invoked bwQueryService.getPolicyCurrentMod :::', 'getPolicyModAndVerifyPendingDocs()',
         'This is the getPolicyModAndVerifyPendingDocs service: LOGIN', 'Response Payload' + JSON.stringify(modres));
        if (modres) {
          phUser.policyMod = modres.module;

          const policyNumber: string = symbol.concat(policynum).concat(modres.module);

          this.plcyDetailsService.getPolicyHolderInfo(policyNumber, mco).subscribe((pniInfores: PNIInfoRes) => {
            if (pniInfores && pniInfores.Result === 'success') {
              Tracker.loginfo('3. Invoked plcyDetailsService.getPolicyHolderInfo :::', 'getPolicyHolderInfo()',
              'This is the getPolicyHolderInfo service: LOGIN', 'Response Payload' + JSON.stringify(pniInfores));
              const phInfo = pniInfores.PolicyHolderInfo[0];

              phUser.firstName = phInfo.FirstName;
              phUser.lastName = phInfo.LastName;
              phUser.fullName = phInfo.FullName;
              phUser.dob = phInfo.DOB;
              phUser.phone = phInfo.HomePhoneNumber;
              phUser.riskstate = phInfo.RiskState;
              phUser.IsGaragingAddressDifferent = phInfo.IsGaragingAddressDifferent;
              phUser.IsAgentEligibleForEndorsement = phInfo.IsAgentEligibleForEndorsement;
              phUser.IsNNOPolicy = phInfo.IsNNOPolicy;
              phUser.SR22FR44Status = phInfo.SR22FR44Status;
              phUser.hasRenewal = phInfo.HasRenewal;
              phUser.IsUpdateAddressEligible = phInfo.IsUpdateAddressEligible;
              phUser.IsAddVehicleEligible = phInfo.IsAddVehicleEligible;
              phUser.IsReplaceVehicleEligible = phInfo.IsReplaceVehicleEligible;
              phUser.IsRemoveVehicleEligible = phInfo.IsRemoveVehicleEligible;
              phUser.HasUnderwritingAlerts = phInfo.HasUnderwritingAlerts;
              phUser.HasMissingDocuments = phInfo.HasMissingDocuments;
              phUser.HasCDWCoverage  = phInfo.HasCDWCoverage;
              phUser.HasUMPDCoverage  = phInfo.HasUMPDCoverage;
              // phUser.SR22FR44Status = 'None';
	      phUser.HasAutoLoanLeaseCoverage = phInfo.HasAutoLoanLeaseCoverage;              // phUser.SR22FR44Status = 'None';
                phUser.HasUIMPDCoverage = phInfo.HasUIMPDCoverage;
                // set PolicyHolder in the PH subject for CHAT button visibility logic in App component
                this.pHUserSubject.next(phUser);

                phUser.HasAutoLoanLeaseCoverage = phInfo.HasAutoLoanLeaseCoverage;              // phUser.SR22FR44Status = 'None';
                phUser.HasUIMPDCoverage = phInfo.HasUIMPDCoverage;
                // set PolicyHolder in the PH subject for CHAT button visibility logic in App component
                if (phInfo && phInfo.NewAmountDue && phInfo.NewAmountDue.DueDate) {
                  phUser.reInstateDueDate = phInfo.NewAmountDue.DueDate;
                  phUser.reInstateDueamount = phInfo.NewAmountDue.ReinstLapseAmt;
                  phUser.reInstatePolicyStatus = phInfo.NewAmountDue.PolicyStatus;
                }
                this.pHUserSubject.next(phUser);

               // phUser.IsGaragingAddressDifferent = false;
              if (GlobalConstants.STATE_CA === phUser.riskstate && GlobalConstants.MCO_44 === mco) {
                return reject(new BWError(
                  {'errorcode': '44', 'errordesc': MessageConstants.CA44_ERROR, 'source': 'LoginService'}));
              } else if ((GlobalConstants.STATE_CA === phUser.riskstate && GlobalConstants.MCO_91 === mco) ||
                  (GlobalConstants.STATE_CA === phUser.riskstate && GlobalConstants.MCO_92 === mco) ||
                  (GlobalConstants.STATE_HI === phUser.riskstate && GlobalConstants.MCO_90 === mco))  {
                return reject(new BWError(
                  {'errorcode': '21C', 'errordesc': MessageConstants.CA44_ERROR, 'source': 'LoginService'}));

              } else {

                forkJoin(this.esigService.isESigImplemented(mco, phInfo.RiskState),
                    this.paperlessService.getAutoAuthValues(policyNumber, mco),
                    this.esigService.getEnvelops(phInfo.FirstName, phInfo.LastName, policyNumber))
                    .subscribe(([esigimplresp, gopaperlessresp, envelopsres]) => {

                      // Retrieving Esig Status
                      if (esigimplresp && esigimplresp.status !== 'FAILURE' && esigimplresp.isImplemented === true) {
                           // During Login flow - MI Reform Bill starts //keerthana
                                // display state specific text on pending doc screen
                                if (GlobalConstants.STATE_MI === phInfo.RiskState && phUser.esigstatus) {
                                phUser.stateMIHasEnvelope = true;
                                this.pHUserSubject.next(phUser);
                                }
                                if (GlobalConstants.STATE_MI === phInfo.RiskState && !phUser.esigstatus) {
                                                  // Get the library & maxMod of the policy resides
                                                  this.bwQueryService.getPolicyLocationStatusAndMaxMod(symbol, policynum)
                                                  .subscribe((polLocMaxMod: PlcyLocationModStatusAndMaxModRes) => {
                                                    Tracker.loginfo('4.a.bwQueryService.getPolicyLocationStatusAndMaxMo/policyd():::'
                                                      , 'getPolicyModAndVerifyPendingDocs()',
                                                      'This is the getPolicyLocationStatusAndMaxMod service: LOGIN',
                                                      'Response Payload' + JSON.stringify(modres));
                                                    const pollib = polLocMaxMod.location;
                                                    const maxMod = polLocMaxMod.strPolicyMod;
// Get policy effective date & policy expirt date
this.bwibicService.getAllPolicyInfo(mco, symbol, policynum, maxMod).
subscribe((policyresp: FullPolicyDetails) => {
  if (policyresp) {
  const policyEffDate = formatDate(policyresp.policyInfo.PolicyEffectiveDate,
    'yyyy-MM-dd', 'en-US');
  const policyExpDate = formatDate(policyresp.policyInfo.PolicyExpireDate,
    'yyyy-MM-dd', 'en-US');
    this.todayDate = CommonUtil.getDateNowEST();
    const formattedCurrentDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'));
    const adding30Days = new Date();
    adding30Days.setDate(adding30Days.getDate() + 30);
   /// const formattedAdd30Days =  new Date(formatDate(adding30Days, 'yyyy-MM-dd', 'en-US'));
   // this.effectDtCheck =   policyEffDate >= '2020-06-02' &&
    // policyEffDate <= '2020-08-02'; // TODO: only for QA testing.Remove for prod
    const formattedAdd30Days = formatDate(adding30Days, 'yyyy-MM-dd', 'en-US');

    this.effectDtCheck =   policyEffDate >= this.todayDate &&
   policyEffDate <= formattedAdd30Days;

   Tracker.loginfo('Call MI logic:::'
   , 'getPolicyModAndVerifyPendingDocs()',
   'LOGIN',
   'for policy:' + policyNumber + this.effectDtCheck + phInfo.HasRenewal);

  if ((phInfo.HasRenewal && this.effectDtCheck) ||  !phInfo.HasRenewal) {

// ***************************************************


                                                  this.stateFlowService.stateMIWorkflow(mco, phInfo.RiskState, symbol,
                                                    policynum, maxMod, phInfo.HasRenewal, pollib, phInfo.FirstName,
                                                    phInfo.LastName, 'login', phUser.userId).
                                                    then(stateWorkflowresp => {
                                          if (stateWorkflowresp && stateWorkflowresp.envelopeCreated) { // if envelope is created
                                                phUser.esigstatus = true;
                                                 this.isAuthenticatedSubject.next(true);

                                                   Tracker.loginfo('4. Invoked esigService.getEnvelops():::', 'getEnvelops()',
                                                   'This is the esigService.getEnvelops() service: LOGIN', 'Response Payload' +
                                                   JSON.stringify(stateWorkflowresp.envelopes));

                                                     phUser.esigstatus = true;
                                                     phUser.stateMIHasEnvelope = true;
                                                     phUser.renewalPolEffDate = policyEffDate;
                                                     // PERFORMANCE: Added this Subject to avoid calling get Envelopes again in Login Flow
                                                     this.envelopesSubject.next(stateWorkflowresp.envelopes);
                                                     this.isAuthenticatedSubject.next(true);
                                                     this.pHUserSubject.next(phUser);

                                                      } else { // if envelope is NOT cerated
                                                        // QA defect 17577 fix starts

                                                        let hasPendingEnvs = false;
                          if (envelopsres && envelopsres.status.toUpperCase() === 'SUCCESS') {
                            hasPendingEnvs = CommonUtil.checkPlcyHasEnvelopes(envelopsres.envelopes);
                          }

                          if (hasPendingEnvs) {
                            phUser.esigstatus = true;
                            phUser.stateMIHasEnvelope = true;
                            // PERFORMANCE: Added this Subject to avoid calling get Envelopes again in Login Flow
                            this.envelopesSubject.next(envelopsres.envelopes);
                            this.isAuthenticatedSubject.next(true);
                            this.pHUserSubject.next(phUser);
                          } else {
                          // QA defect fix ends

                                              phUser.esigstatus = false;
                                              phUser.stateMIHasEnvelope = false;
                                              // this.updateUserLogin(phUser);
                                              this.isAuthenticatedSubject.next(true);
                                              this.pHUserSubject.next(phUser);
                                              resolve(phUser);
                                            }
                                          }
                                          // Retrieving Go Paperless Status
                                          if (gopaperlessresp.getAutoAuthValuesResult === 'P' ||
                                            gopaperlessresp.getAutoAuthValuesResult === 'B') {
                                            phUser.gopaperlessInd = true;
                                          } else {
                                            phUser.gopaperlessInd = false;
                                          }
                                          this.isAuthenticatedSubject.next(true);
                                          resolve(phUser);

                                        }); // Stateworkflow service call
                                    } else { // effect Dt check
                                      Tracker.loginfo('4. Invoked esigService.getEnvelops():::', 'getEnvelops()',
                                        'This is the esigService.getEnvelops() service: LOGIN',
                                        'Response Payload' + JSON.stringify(envelopsres));
                                      let hasPendingEnvelops = false;
                                      if (envelopsres && envelopsres.status.toUpperCase() === 'SUCCESS') {
                                        hasPendingEnvelops = CommonUtil.checkPlcyHasEnvelopes(envelopsres.envelopes);
                                      }

                                      if (hasPendingEnvelops) {
                                        phUser.esigstatus = true;
                                        this.envelopesSubject.next(envelopsres.envelopes);
                                        this.isAuthenticatedSubject.next(true);
                                        this.pHUserSubject.next(phUser);
                                      } else {
                                        phUser.esigstatus = false;
                                        this.isAuthenticatedSubject.next(true);
                                        this.pHUserSubject.next(phUser);
                                        resolve(phUser);
                                      }
                                      if (gopaperlessresp.getAutoAuthValuesResult === 'P' ||
                                        gopaperlessresp.getAutoAuthValuesResult === 'B') {
                                        phUser.gopaperlessInd = true;
                                      } else {
                                        phUser.gopaperlessInd = false;
                                      }
                                      this.isAuthenticatedSubject.next(true);
                                      resolve(phUser);
                                    } // effect Dt not passed
                                  } // gttAllPolicy resp
                                }); // getAllPoicyservice call
                            }); // getPolicylocationAndmaxmode service call
                        }
                        else { // statecheck
                          // During Login/Registration flow - MI Reform Bill ends //keerthana
                          Tracker.loginfo('4. Invoked esigService.getEnvelops():::', 'getEnvelops()',
                          'This is the esigService.getEnvelops() service: LOGIN', 'Response Payload' + JSON.stringify(envelopsres));
                          let hasPendingEnvelops = false;
                          if (envelopsres && envelopsres.status.toUpperCase() === 'SUCCESS') {
                            hasPendingEnvelops = CommonUtil.checkPlcyHasEnvelopes(envelopsres.envelopes);
                          }

                          if (hasPendingEnvelops) {
                            phUser.esigstatus = true;
                            // PERFORMANCE: Added this Subject to avoid calling get Envelopes again in Login Flow
                            this.envelopesSubject.next(envelopsres.envelopes);
                            this.isAuthenticatedSubject.next(true);
                            this.pHUserSubject.next(phUser);
                          } else {
                            phUser.esigstatus = false;
                           // this.updateUserLogin(phUser);
                            this.isAuthenticatedSubject.next(true);
                            this.pHUserSubject.next(phUser);
                            resolve(phUser);
                          }
                          // Retrieving Go Paperless Status
                          // console.log('Esig Status: ', phUser.esigstatus, '
                          // Go Paperless status: ', gopaperlessresp.getAutoAuthValuesResult);
                          if (gopaperlessresp.getAutoAuthValuesResult === 'P' || gopaperlessresp.getAutoAuthValuesResult === 'B') {
                            // && gopaperlessresp.getAutoAuthValuesResult !== 'V'
                            //      && gopaperlessresp.getAutoAuthValuesResult !== 'B'
                            phUser.gopaperlessInd = true;
                          } else {
                            phUser.gopaperlessInd = false;
                          }
                          // create webSessionlogRecord for successfull login
                          // this.updateUserLogin(phUser);
                          this.isAuthenticatedSubject.next(true);
                          // this.createWebSessLog(phUser);
                          resolve(phUser);
                        } // state check ends
                      } else { // envelopseresp is sent successfully // QC:210147 starts
                        // Retrieving Go Paperless Status
                        if (gopaperlessresp.getAutoAuthValuesResult === 'P' || gopaperlessresp.getAutoAuthValuesResult === 'B') {
                          // && gopaperlessresp.getAutoAuthValuesResult !== 'V'
                          //      && gopaperlessresp.getAutoAuthValuesResult !== 'B'
                          phUser.gopaperlessInd = true;
                        } else {
                          phUser.gopaperlessInd = false;
                        }
                        // create webSessionlogRecord for successfull login
                        // this.updateUserLogin(phUser);
                        this.isAuthenticatedSubject.next(true);
                        // this.createWebSessLog(phUser);
                        resolve(phUser);
                      }
                      /// QC:210147 ends

                    });
              }
            } else {
              // this.updateUserLogin(phUser);
              this.isAuthenticatedSubject.next(true);
              resolve(phUser);
            }

          });
        } else {
          return reject(this.errorHandler.handleApplicationError(new BWError(
            {'errorcode': 'ERROR_MOD', 'errordesc': 'Error retrieving error', 'source': 'BWQueryService'})));
        }
      },
      (error) => {
        // Tracker.log('Error Occurred in login Getting Mod Error', error);
        return reject(error);
      });
    });
  }


  // TODO: REMOVE This function
  verifyIf21cPolicy(tfcRenewalRes: TfcRenewalOfferRes, envelopesRes: EnvelopesResp, mco: string, symbol: string,
    policynum: string, mod: string, library: string, phres: Authenticatephres): Promise<boolean> {
      const policyNumber = symbol.concat(policynum).concat(mod);
      return new Promise((resolve, reject) => {
      if (tfcRenewalRes && tfcRenewalRes.result === 'SUCCESS' && tfcRenewalRes.tfcPolicyRenewOffer === true) {
        this.bwQueryService.checkIf21CPolicyEsigEligible(mco, symbol, policynum, mod)
          .subscribe((tfcEsigEligibleRes: TfcPolicyESigEligibleRes) => {
           Tracker.loginfo('5. Invoked bwQueryService.checkIf21CPolicyEsigEligible()::::', 'checkIf21CPolicyEsigEligible()',
                          'This is the checkIf21CPolicyEsigEligible service: LOGIN', 'Response Payload'
                           + JSON.stringify(tfcEsigEligibleRes));
            if (tfcEsigEligibleRes && tfcEsigEligibleRes.result === 'SUCCESS'
              && tfcEsigEligibleRes.tfcStPolicyESigEligibleResult === true) {
                this.bwQueryService.checkIfPolicyWetSigned(mco, symbol, policynum, mod)
                  .subscribe((policyWetSignRes: HasBeenWetSignedRes) => {
                    Tracker.loginfo('6. Invoked bwQueryService.checkIfPolicyWetSigned()::::', 'checkIfPolicyWetSigned()',
                    'This is the checkIfPolicyWetSigned service: LOGIN', 'Response Payload'
                     + JSON.stringify(policyWetSignRes));
                    if (policyWetSignRes && policyWetSignRes.result === 'SUCCESS'
                          && policyWetSignRes.tfcPolicyWetSignedResult === false) {

                          // Check if Policy has Pending Docs or Envelop is created for 21c Policy
                          this.verifyPendingDocsOrCreateEnvFor21CPlcy(envelopesRes, mco, policyNumber, library)
                            .then(status => {
                              const plcyHasEnvelops = CommonUtil.checkPlcyHasEnvelopes(envelopesRes.envelopes);

                              if (status === true || plcyHasEnvelops === true) {
                                resolve(true);
                              } else {
                                resolve(false);
                              }
                            });
                      } else {
                        resolve(false);
                      }
                  });
            } else {
              resolve(false);
            }
          });
      }
    });
  }

  // TODO: REMOVE This function
  verifyPendingDocsOrCreateEnvFor21CPlcy(envelops: EnvelopesResp, mco: string, policynumber: string, library: string): Promise<boolean>  {
    return new Promise((resolve, reject) => {
      // Tracker.log('verifyPendingDocsOrCreateEnvFor21CPlcy envelops length:: ', envelops.envelopes.length);
      if (envelops && envelops.envelopes && envelops.envelopes.length <= 0) {
        this.edmrService.invokeEDMRDocuments(policynumber, GlobalConstants.TRANSCODE, GlobalConstants.EDMR_SRC_SYSTEM)
          .subscribe((invokeDocsRes: InvokeEDMRDocsRes) => {
            // Tracker.log('7. Invoked edmrService.invokeEDMRDocuments():::: ', invokeDocsRes);
            if (invokeDocsRes && invokeDocsRes.responseStatus === '1') {
              this.esigService.createEnvelopeAndWaitForVendor(mco, policynumber, library)
                .subscribe((createEnvRes: CreateEnvelopeRes) => {
                  // Tracker.log('8. Invoked esigService.createEnvelopeAndWaitForVendor():::: ', createEnvRes);
                  if (createEnvRes && createEnvRes.status === 'SUCCESS' && createEnvRes.envelope) {
                    resolve(true);
                  }
                });
            } else {
              resolve(false);
            }
          },
          error => {
            resolve(false);
          });
      } else {
        resolve(false);
      }
    });
  }

  // TODO: REMOVE This function
  verifyIfPolicyHasPendingPayment(mco: string, plcysym: string, plcynum: string, plcymod: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.bwQueryService.checkIfPPaymentHasMadeOrEFT(mco, plcysym, plcynum, plcymod)
        .subscribe((paymentmaderes: HasMadePaymentorEFTRes) => {
          // Tracker.log('9. Invoked bwQueryService.checkIfPPaymentHasMadeOrEFT():::: ', paymentmaderes);
          if (paymentmaderes && paymentmaderes.result === 'SUCCESS' && paymentmaderes.madePaymentorEFTResult === true) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
    });
  }

  /**
   * Clear token and remove user from local storage to log user out.
   */
  logout(): void {
    // Tracker.log('I am logging out');
    this._token = null;
    if (this.policyHolder === undefined || this.policyHolder === null) {
      this.policyHolder = new PolicyHolder();
    }

    this._storeService.deleteLoginInfo(this.policyHolder);
    this._storeService.deletePolicyInfo(new FullPolicyDetails());
    this._storeService.deleteRequestToCancelPolicyInfo(new RequesttocancelStoreInfo());
    this._storeService.deleteVehicleEndtDetail(new VehicleEndtInfoStore());
    this._storeService.removeBWCoverages();
   // this._storeService.deleteBwEndorsementInfo(new EndorseMetainfo());
    this.isAuthenticatedSubject.next(false);
  }

  /*
   * Save the Token to Store for future references
  */

 saveUserLogin(phuser: PolicyHolder) {
  this._storeService.createLoginInfo(phuser);
  // localStorage.setItem('phUserToken', phuser.jwtToken);
  }

  updateUserLogin(phuser: PolicyHolder) {
    this._storeService.updateLoginInfo(phuser);
  }

  /**
   * Verify the user token is valid
   * @param token
   */
  verifyToken(token: string) {

  }

  /*
  * Utility Functions
  */
  _isUserLoggedIn(): boolean {
    // Tracker.log('I am hitted every time::::');
    const customerLoginToken: string = this._bwToken();

    let loginStatusFromService = false;
    this.isUserLoggedIn.subscribe((authenticated) => {
        loginStatusFromService = authenticated;
     });

     // Tracker.log('I am hitted every time 2 :::: ', customerLoginToken, loginStatusFromService);
    if (customerLoginToken && customerLoginToken !== undefined && customerLoginToken !== null && loginStatusFromService) {
      return true;
    } else {
      // loginStatusFromService;
    }
  }

  _isUserLoggedOut() {
    const token = localStorage.getItem('phUserToken');
    if (token) {
      this.userLoggedOut = false;
    }
  }

  _bwToken() {
    // Read it from the Store and pass it back
    const currentUserInfoFromStore = this._storeService.getLoginInfoFromStore();
    return currentUserInfoFromStore.jwtToken;
  }

  _setLoginStatus(status: boolean) {
    this.isAuthenticatedSubject.next(status);
  }

  /**
   * Look up user by their email to get security questions
   * @param userId
   */
  forgotPassword(userId: string) {
    // check by user id
    return this.user;
  }

  createWebSessLog(plcyHolder: PolicyHolder) {
    // build the request to log record.
    this.websesssionlogreq = new Websessionlogreq();
    this.websesssionlogreq.state = plcyHolder.riskstate;
    this.websesssionlogreq.appName = GlobalConstants.APPID;
    this.websesssionlogreq.mco = CommonUtil.getMCO(plcyHolder);
    // this.websesssionlogreq.miscellaneousUse1
    this.websesssionlogreq.module = CommonUtil.getPolicyMod(plcyHolder);
    this.websesssionlogreq.policyNumber = CommonUtil.getPolicyNumber(plcyHolder);
    this.websesssionlogreq.symbol = CommonUtil.getPolicySymbol(plcyHolder);
    this.websesssionlogreq.statusCode = '1';
    this.websesssionlogreq.taskType = 'L';  // for login it is L
    // console.log('this._isUserLoggedIn()'+this._isUserLoggedIn());
    this.websesssionlogreq.regStatus = (this._isUserLoggedIn() ? 'Y' : 'N');
    this.websesssionlogreq.userID = plcyHolder.userId;
    if (plcyHolder && plcyHolder.esigstatus === true) {
      this.websesssionlogreq.eSigStatus = 'Y';
    } else {
      this.websesssionlogreq.eSigStatus = 'N';
    }

    this._plcyMngmntService.createWebSessionLogRecord(this.websesssionlogreq).subscribe(
      (status: String) => {
        Tracker.loginfo('Invoked plcyMngmntService :::', 'createWebSessionLogRecord', 'ResponseStatus', '' + status);
      }, error => {
        // this.googleAnalytics.trackEvent('Registration', 'UserRegistrationScreen', 'UserRegistrationContinueClick', 500);
        Tracker.logerror('Invoked plcyMngmntService', 'createWebSessionLogRecord', 'createWebSessionLogRecord',
        'Error during WebsessionLog Record.', error);
      });
  }

  createWebSessLogMFA(plcyHolder: PolicyHolder, ismfaSuccessful: boolean) {
    // build the request to log record.
    this.websesssionlogreq = new Websessionlogreq();
    this.websesssionlogreq.state = plcyHolder.riskstate;
    this.websesssionlogreq.appName = GlobalConstants.APPID;
    this.websesssionlogreq.mco = CommonUtil.getMCO(plcyHolder);
    // this.websesssionlogreq.miscellaneousUse1
    this.websesssionlogreq.module = CommonUtil.getPolicyMod(plcyHolder);
    this.websesssionlogreq.policyNumber = CommonUtil.getPolicyNumber(plcyHolder);
    this.websesssionlogreq.symbol = CommonUtil.getPolicySymbol(plcyHolder);
    this.websesssionlogreq.statusCode = '1';
    this.websesssionlogreq.taskType = 'M';  // for MFA it is M
    this.websesssionlogreq.regStatus = (ismfaSuccessful ? 'Y' : 'N');
    this.websesssionlogreq.userID = plcyHolder.userId;
    if (plcyHolder && plcyHolder.esigstatus === true) {
      this.websesssionlogreq.eSigStatus = 'Y';
    } else {
      this.websesssionlogreq.eSigStatus = 'N';
    }

    this._plcyMngmntService.createWebSessionLogRecord(this.websesssionlogreq).subscribe(
      (status: String) => {
        Tracker.loginfo('Invoked plcyMngmntService :::', 'createWebSessionLogRecord', 'ResponseStatus', '' + status);
      }, error => {
        // this.googleAnalytics.trackEvent('Registration', 'UserRegistrationScreen', 'UserRegistrationContinueClick', 500);
        Tracker.logerror('Invoked plcyMngmntService', 'createWebSessionLogRecord', 'createWebSessionLogRecord',
        'Error during WebsessionLog Record.', error);
      });
  }

   /*
  * Utility Functions
  */
 createphUserObject() {
  // Tracker.log('I am hitted every time::::');
  // const phUserObject: PolicyHolder = this.storeService.getLoginInfoFromStore();

  this.phUserObject.subscribe((data) => {
    this.pHUserSubject.next(this._storeService.getLoginInfoFromStore());
   });

   // Tracker.log('I am hitted every time 2 :::: ', customerLoginToken, loginStatusFromService);
  /*if (phUserObject && phUserObject !== undefined && phUserObject !== null) {
    return phUserObject;
  } else {
  }*/
  }
  updatePHHolder(data) {
    this.pHUserSubject.next(data);
  }

}