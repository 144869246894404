export class EndorsementVoidReq {

    policyID: string;
    mco: string;
    state: string;

    binderNumber: string;
    appName: string ;
    userID: string;
    reason: string ;
    notes: string ;
    databaseContext: string;
    endorseContext: string ;

    changeTypeCd: string;


    constructor(values: Object = {}) {
        Object.assign(this, values);
    }

}
