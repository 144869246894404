import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../../../shared/services/analytics/google-analytics.service';
import { UsmsecurityService } from '../../../services/api/usmsecurity/usmsecurity.service';
import { BwstoreService } from '../../services/bwstore.service';
import { PolicyHolder } from '../../model/authentication/authenticatephres';
import { Tracker } from '../../utils/tracker';
import { SSOTokenResponse } from '../../model/api/policyholder/findph.model';
import { environment } from '../../../../environments/environment';
import { CommonUtil } from '../../../shared/utils/commonutil';
import { AuthenticationService } from '../../services/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FeedbackComponent } from '../../../components/feedback/feedback.component'; 
@Component({
selector: 'app-footer',
template: `
<footer class="footer">
  <div class="container-fluid">

    <!-- Footer links -->
    <div class="row d-flex text-center">
      <ul class="nav list-inline mx-auto justify-content-md-center">
      <div><li class="nav-item">
          <a routerLink="/home/about" routerLinkActive="active" class="nav-link" (click)="addTag('aboutUs')">About
            Us</a>
            <svg width="93" height="2" class="d-md-none d-sm-block">
              <rect width="93" height="2" style="fill:#308ee3;" />
            </svg>
          </li>
        <li class="nav-item">
          <a routerLink="/home/contact" routerLinkActive="active" class="nav-link" (click)="addTag('contact')">Contact
            Us</a>
            <svg width="93" height="2" class="d-md-none d-sm-block">
              <rect width="93" height="2" style="fill:#308ee3;" />
            </svg>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/home/terms" routerLinkActive="active" (click)="addTag('terms')">Terms 
            of Use</a>
            <svg width="93" height="2" class="d-md-none d-sm-block">
              <rect width="93" height="2" style="fill:#308ee3;" />
            </svg>
        </li>
        <li class="nav-item">
          <a href="https://www.farmers.com/careers/" target="_blank"
            rel="noreferrer" class="nav-link " (click)="addTag('careers')">
            Careers</a>
            <svg width="93" height="2" class="d-md-none d-sm-block">
              <rect width="93" height="2" style="fill:#308ee3;" />
            </svg>
        </li>
        <li class="nav-item">
          <a href="{{this.privacyCenterURL}}" target="_blank" routerLinkActive="active" (click)="addTag('privacy')"
            class="nav-link">Privacy Center</a>
            <svg width="93" height="2" class="d-md-none d-sm-block">
              <rect width="93" height="2" style="fill:#308ee3;" />
            </svg>
        </li>
        <li class="nav-item " *ngIf="!isUserLoggedIn">
          <a class="nav-link" href="{{dontSellUrl}}" target="_blank">Do Not Sell My Personal Information</a>
          <svg width="93" height="2" class="d-md-none d-sm-block">
              <rect width="93" height="2" style="fill:#308ee3;" />
            </svg>
        </li>
        <li class="nav-item">
          <a routerLink="/home/faq" routerLinkActive="active" (click)="addTag('faq')" class="nav-link">FAQ</a>
          <svg width="93" height="2" class="d-md-none d-sm-block">
              <rect width="93" height="2" style="fill:#308ee3;" />
            </svg>
        </li>
        <!--Commenting below for CES Start-->
        <!--<li *ngIf="!this.isFeedbackClicked" class="nav-item d-md-none d-sm-block">
          <a href="javascript:void(0)" (click)="showFeedback()" routerLinkActive="active" class="nav-link">Website
            Feedback</a>
            <svg width="93" height="2" class="d-md-none d-sm-block">
              <rect width="93" height="2" style="fill:#308ee3;" />
            </svg>
        </li>-->
        <!--Commenting below for CES End-->
        <li class="nav-item">
          <a routerLink="/find-agent-broker/" routerLinkActive="active" class="nav-link"
            (click)="addTag('sitemap')">Find an Agent or Broker</a>
            <svg width="93" height="2" class="d-md-none d-sm-block">
              <rect width="93" height="2" style="fill:#308ee3;" />
            </svg>
        </li>
        <li class="nav-item">
          <a routerLink="/home/sitemap" routerLinkActive="active" class="nav-link"
            (click)="addTag('sitemap')">Sitemap</a>
        </li>
        </div>
      </ul>
    </div>

    <!-- BW Logo and Farmers affliate text -->
    <div class="row mt-4 justify-content-center">
					<div class="col-md-auto mb-4">
						<a class="logo" routerLink="/" routerLinkActive="active">
							<img class="logo" src="assets/img/bw-logo-white.PNG" alt="Bristol West logo" />
						</a>
						<a class="logo ml-4" href="http://www.farmers.com">
							<img class="logo" src="assets/img/Farmers_logo.svg" alt="Farmers logo" />
						</a>
          </div>
					<div class="col-lg-6 col-md-8">
            <p class="small text-left label-white-small">
            Bristol West is a proud member of the Farmers Insurance Group of Companies, one of the nation’s largest insurer
            groups that offers a wide variety of home, life, specialty, commercial and auto insurance products and services
            across the <span class="text-nowrap">United States.</span></p>
          </div>
    </div>

    <!-- App Store Download -->
    <div class="text-center mt-sm-2 mt-xs-2">
      <!-- <p class="mb-1 label-white-small">Download BW App</p> -->
      <div class="row justify-content-center">
        <a class="logo" href="https://apps.apple.com/us/app/bristol-west/id1473647523" target="_blank">
          <img class="logo mob-app-link m-0" src="assets/img/favicons/ios.png" alt="App Store" />
        </a>
        <a class="logo ml-4" href="https://play.google.com/store/apps/details?id=com.bristolwest.app" target="_blank">
          <img class="logo mob-app-link m-0" src="assets/img/favicons/android.png" alt="Play Store" />
        </a>
      </div>
    </div>

    <!-- Copy Right -->
    <div class="pt-2">
      <p class="small p-small">&copy; 2019 Bristol West. All rights reserved</p>
    </div>

  </div>
</footer>
  `,
 styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

isUserLoggedIn: boolean;
isFeedbackClicked = false;
 authenticatedPhDetails: PolicyHolder;
androindLink: string;
mobileView: boolean;
privacyCenterURL: string;
dontSellUrl: string;
 constructor(private router: Router, private gaService: GoogleAnalyticsService,
  private usmSecurityService: UsmsecurityService, private authService: AuthenticationService,
  private _storeService: BwstoreService, private modalService: NgbModal) { }
  ngOnInit() {
    this.privacyCenterURL = `${environment.ccpaPrivacyCenter}`;
    this.dontSellUrl = `${environment.ccpaDontSell}`;
    this.mobileView = CommonUtil.findDeviceVIew().isMobileDevice();
  // enable GA then track the page views
   this.gaService.enableGA().then(res => {
    if (res) {
     // Tracker.log('Google Analytics enabled ', res);
     // subscribe to the google analytics
     this.gaService.subscribe();
    }
    }).catch(error => {
    Tracker.logerror('AppComponent', 'ngOnInit()', 'Enabling GA Tracking', 'Error Enabling GA Tracking', error);
   });

   this.authService.isUserLoggedIn.subscribe((authenticated) => {
     this.isUserLoggedIn = authenticated;
  });

  }


 showFeedback() {
  // const modalRef = this.modalService.open(FeedbackComponent);

  this.modalService.open(FeedbackComponent).result.then((result) => {
    // console.log(`Closed with: ${result}`);
  }, (reason) => {
    if (`${reason}` === 'Submit') {
      this.isFeedbackClicked = true;
    }
    // console.log(`Dismissed ${reason}`);
  });
}

 addTag(linkName: string) {
  if (linkName === 'aboutUs') {
   this.gaService.trackEvent('About Us', 'About Us', 'AboutUsLinkClick', 200);
   } else if (linkName === 'contact') {
    this.gaService.trackEvent('Contact Us', 'Contact Us', 'ContactUsLinkClick', 200);
   } else if (linkName === 'terms') {
    this.gaService.trackEvent('Terms and Conditions', 'Terms and Conditions Page', 'TermsAndConditionsLinkClick', 200);
   } else if (linkName === 'careers') {
    this.gaService.trackEvent('Careers', 'Careers', 'CareersClick', 200);
   } else if (linkName === 'faq') {
    this.gaService.trackEvent('FAQ', 'FAQ', 'FAQLinkClick', 200);
   } else if (linkName === 'sitemap') {
    this.gaService.trackEvent('Sitemap', 'Sitemap', 'SitemapLinkClick', 200);
   }
 }
}
