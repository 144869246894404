var DirectsalesParameters = /** @class */ (function () {
    function DirectsalesParameters(values) {
        if (values === void 0) { values = {}; }
        this.id = '';
        this.zip = '';
        this.ppcsrc = '';
        this.firstname = '';
        this.lastname = '';
        this.streetaddress = '';
        this.streetapt = '';
        this.streetcity = '';
        this.emailid = '';
        this.phoneno = '';
        this.dob = '';
        this.gender = '';
        this.dsplyPhn = '';
        Object.assign(this, values);
    }
    return DirectsalesParameters;
}());
export { DirectsalesParameters };
