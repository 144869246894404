import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { GlobalConstants } from '../../constants/global.constant';
import { AgentInfo, FullPolicyDetails, PaymentHistoryInfo } from '../../shared/model/api/bwibic/fullpolicydetails.model';
import { BwstoreService } from '../../shared/services/bwstore.service';
import { BwibicService } from '../api/bwibic/bwibic.service';
import { PaymentService } from '../api/payment/payment.service';
import { BWErrorhandler } from '../errorhandler';

@Injectable({
  providedIn: 'root'
})
export class MypolicyService {
  private globlalConstants = GlobalConstants;

  private paymentHistorySubject = new ReplaySubject<PaymentHistoryInfo[]>(1);
  public paymentHistory = this.paymentHistorySubject.asObservable();

  private agentInfoSubject = new ReplaySubject<AgentInfo>(1);
  public agentInfo = this.agentInfoSubject.asObservable();

  constructor(private bwibicService: BwibicService, private storeService: BwstoreService, 
    private errorHandler: BWErrorhandler,private paymentService: PaymentService) {}


  getAllBasicPolicyInfo(mco: string, polsym: string, polnum: string, polmod: string): Promise<FullPolicyDetails> {
    return new Promise((resolve, reject) => {
      this.bwibicService.getAllPolicyInfo(mco, polsym, polnum, polmod).subscribe((policyres: FullPolicyDetails) => {
        this.paymentHistorySubject.next(policyres.paymentHistoryInfo);
        this.agentInfoSubject.next(policyres.agentInfo);
        //this.storeService.addPolicyInfo(policyres);
        if(policyres &&  policyres.policyInfo && policyres.policyInfo.PolicyZip){
        this.paymentService.validatePolicy(
          policyres.policyInfo.PolicyZip, polsym, polnum)
          .subscribe(data => {
            if(data){
              policyres.paymentInfo = data
              this.storeService.addPolicyInfo(policyres);
            resolve(policyres);
            }

          },(error) => {

            return reject(error);
          });
        }
        
      },
      (error) => {
        // Tracker.log('Error Occurred in getAllBasicPolicyInfo', error);
        return reject(error);
      });
    });
  }


  retrieveAgentInfoFromStore(): AgentInfo {
    const policyDetails = this.storeService.getPolicyInfoFromStore();
    this.agentInfoSubject.next(policyDetails.agentInfo);
    return policyDetails.agentInfo;
  }

  retrievePaymentHistoryInfoFromStore(): PaymentHistoryInfo[] {
    const policyDetails = this.storeService.getPolicyInfoFromStore();
    this.paymentHistorySubject.next(policyDetails.paymentHistoryInfo);
    return policyDetails.paymentHistoryInfo;
  }
}
