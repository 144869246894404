import { OnInit } from '@angular/core';
import { MessagesService } from '../../services/messages.service';
var SystemErrorsComponent = /** @class */ (function () {
    function SystemErrorsComponent(messageService) {
        this.messageService = messageService;
        this.systemMessages = [];
        this.closed = false;
    }
    SystemErrorsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.closed = false;
        this.messageService.systemMsgs.subscribe(function (messages) {
            // console.error("Messages from System Errors ..... ", messages);
            _this.systemMessages = messages;
        });
        // Tracker.log(this.systemMessages[0]);
        // Tracker.log(this.closed);
        // this.messageService.getSystemMessages().then((message) => this.systemMessages = message[0]);
    };
    return SystemErrorsComponent;
}());
export { SystemErrorsComponent };
