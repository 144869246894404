import { PipeTransform } from '@angular/core';
import { CommonUtil } from '../utils/commonutil';
var CenturydatePipe = /** @class */ (function () {
    function CenturydatePipe() {
    }
    CenturydatePipe.prototype.transform = function (convertDate) {
        var date = new Date(convertDate);
        var centuryDate = '1';
        if (date.getFullYear() < 2000) {
            centuryDate = '0';
        }
        var day = CommonUtil.padNumber(date.getDate());
        var month = CommonUtil.padNumber((date.getMonth() + 1));
        var year = date.getFullYear().toString().substring(2);
        return centuryDate.concat(year).concat(month).concat(day);
    };
    return CenturydatePipe;
}());
export { CenturydatePipe };
