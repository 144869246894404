import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { GlobalConstants } from '../../../constants/global.constant';
import { ChangeuseremailreqInStaging } from '../../../shared/model/api/bwsecurity/changeuseremailreqinstaging';
import { ChangeuseremailresInStaging } from '../../../shared/model/api/bwsecurity/changeuseremailresinstaging';
import { Getpolicybyemailres } from '../../../shared/model/api/bwsecurity/getpolicybyemailres';
import { GetPolicyLocationStatusandMaxModRes } from '../../../shared/model/api/bwsecurity/getpolicylocationstatusandmaxmodres';
import { CheckRegistrationStatusResponse } from '../../../shared/model/api/bwsecurity/registrationstatusres.model';
import { BWSecurityValidateUserRes } from '../../../shared/model/api/bwsecurity/validatenewuserres.model';
import { Policynumber } from '../../../shared/model/policynumber';
import { Changeuseremailreq } from '../../../shared/model/registration/changeuseremailreq';
import { Changeuseremailres } from '../../../shared/model/registration/changeuseremailres';
import { Newuserssoreq } from '../../../shared/model/registration/newuserssoreq';
import { Newuserssores } from '../../../shared/model/registration/newuserssores';
import { BwstoreService } from '../../../shared/services/bwstore.service';
import { BwappService } from '../../bwapp.service';
import { BWErrorhandler } from '../../errorhandler';

@Injectable({
  providedIn: 'root'
})
export class BwSecurityService {

  private globlalConstants = GlobalConstants;

  policyNumber: Policynumber;
  _baseURL: string;
  szResponseCode: string;
  private changeuseremailreq: Changeuseremailreq;
  private changeuseremailres: Changeuseremailres;
  private newuserssoreq: Newuserssoreq;
  private newuserssores: Newuserssores;
  private changeuseremailreqinstaging: ChangeuseremailreqInStaging;
  private changeuseremailresinstaging: ChangeuseremailresInStaging;

  bwOktaEnabled: boolean = environment.bwOktaEnabled;

  constructor(private appService: BwappService, private storeService: BwstoreService, private errorHandler: BWErrorhandler) {
    this._baseURL = GlobalConstants.baseURL;
   }

   /**
    *
    * @param changeuseremailreq
    */
   changeUserEmail_POINT(optout: string): Observable<Changeuseremailres> {

    this.policyNumber = new Policynumber(this.storeService.newUser.policynumber);

    this.changeuseremailreq = new Changeuseremailreq();
    this.changeuseremailreq.szEmail = this.storeService.newUser.userid;
    this.changeuseremailreq.szEmailOptOut = optout;
    this.changeuseremailreq.szMCO = this.storeService.newUser.mco;
    this.changeuseremailreq.szPolicyNum = this.policyNumber.policy;
    this.changeuseremailreq.szSymbol = this.policyNumber.symbol;

    return this.appService.post(this.globlalConstants.BWSEC_CHANGE_EMAIL_URL, this.changeuseremailreq);
   }

   /**
    *
    * @param changeuseremailreq
    */
   changeUserEmail_POINT_GP(optout: string, email: string, bwPolNum: string, mco: string): Observable<Changeuseremailres> {

    this.policyNumber = new Policynumber(bwPolNum);

    this.changeuseremailreq = new Changeuseremailreq();
    this.changeuseremailreq.szEmail = email;
    this.changeuseremailreq.szEmailOptOut = optout;
    this.changeuseremailreq.szMCO = mco;
    this.changeuseremailreq.szPolicyNum = this.policyNumber.policy;
    this.changeuseremailreq.szSymbol = this.policyNumber.symbol;

    return this.appService.post(this.globlalConstants.BWSEC_CHANGE_EMAIL_URL, this.changeuseremailreq);
   }

    /**
    *
    * @param changeuseremailreqinstaging
    */
   changeUserEmail_POINT_IN_STAGING(optout: string, email: string, bwPolNum: string, mco: string): Observable<ChangeuseremailresInStaging> {

    this.policyNumber = new Policynumber(bwPolNum);

    this.changeuseremailreqinstaging = new ChangeuseremailreqInStaging();
    this.changeuseremailreqinstaging.szEmail = email;
    this.changeuseremailreqinstaging.szEmailOptOut = optout;
    this.changeuseremailreqinstaging.szMCO = mco;
    this.changeuseremailreqinstaging.szPolicyNum = this.policyNumber.policy;
    this.changeuseremailreqinstaging.module = this.policyNumber.module;
    this.changeuseremailreqinstaging.szSymbol = this.policyNumber.symbol;

    return this.appService.post(this.globlalConstants.BWSEC_CHANGE_EMAIL_IN_STAGING_URL, this.changeuseremailreqinstaging);
   }

   /**
    *
    * @param policynum
    */

   getPolicyLocationStatusandMaxMod(policynum: string): Observable<GetPolicyLocationStatusandMaxModRes> {
    this.policyNumber = new Policynumber(policynum);

    // Tracker.log('BWSecurity Service - getPolicyLocationStatusandMaxMod() invoked with policy :::', policynum, this.policyNumber);
    const params = new HttpParams()
        .set('strPolicySym', this.policyNumber.symbol)
        .set('strPolicyNum', this.policyNumber.policy);

      return this.appService.get(this.globlalConstants.BWSEC_GETPOLICYLOCATIONSTATUSANDMAXMOD, params);
   }


   addNewUserSSO(): Observable<any> {
     let bwOktaEnabled : boolean = environment.bwOktaEnabled;
    this.policyNumber = new Policynumber(this.storeService.newUser.policynumber);
    this.newuserssoreq = new Newuserssoreq();
    this.newuserssoreq.szAnswer1 = this.storeService.newUser.secans1;
    this.newuserssoreq.szAnswer2 = this.storeService.newUser.secans2;
    this.newuserssoreq.szAnswer3 = this.storeService.newUser.secans3;
    this.newuserssoreq.szDOB = this.storeService.newUser.phdob;
    this.newuserssoreq.szFirstName = this.storeService.newUser.phfirstname;
    this.newuserssoreq.szLastName = this.storeService.newUser.phlastname;
    this.newuserssoreq.szMCO = this.storeService.newUser.mco;
    this.newuserssoreq.szMOD = this.policyNumber.module;
    this.newuserssoreq.szPassword = this.storeService.newUser.password;
    this.newuserssoreq.szPIN = '';
    this.newuserssoreq.szPolicy = this.policyNumber.policy;
    this.newuserssoreq.szQuestion1 = this.storeService.newUser.secq1;
    this.newuserssoreq.szQuestion2 = this.storeService.newUser.secq2;
    this.newuserssoreq.szQuestion3 = this.storeService.newUser.secq3;
    this.newuserssoreq.szSymbol = this.policyNumber.symbol;
    this.newuserssoreq.szUsername = this.storeService.newUser.userid;
    this.newuserssoreq.szZIP = this.storeService.newUser.phzipcode;

    // add new user and insert ldap record
    return this.appService
    .post(bwOktaEnabled?this.globlalConstants.BWSEC_ADD_USER_SSO_URL_OKTA:this.globlalConstants.BWSEC_ADD_USER_SSO_URL, this.newuserssoreq)
    .pipe(
      map(
        data => {
          // Tracker.log('BwSecurityService Service Response for addNewUserSSO: ', data);
          return data;
        }
      )
    );
   }

   /**
    *
    * @param policynum
    * @param dob
    * @param zip
    */
   validateNewUserPOINT(policynum: string, dob: string, zip: string): Observable<BWSecurityValidateUserRes> {
    this.policyNumber = new Policynumber(policynum);

    // Tracker.log('BWSecurity Service - validateNewUserPOINT() invoked with policy :::', policynum, this.policyNumber);
    const params = new HttpParams()
        .set('szSymbol', this.policyNumber.symbol)
        .set('szPolicy', this.policyNumber.policy)
        .set('szDOB', dob)
        .set('szZIP', zip);

      return this.appService.get(this.globlalConstants.BWSEC_VALIDATENEWUSERPOINT, params);
   }

   getpolicyByEmail(email: string): Observable<Getpolicybyemailres> {
     // Tracker.log('BWSecurity Service - getpolicyByEmail() invoked with email :::', this.storeService.newUser.userid);
     const params = new HttpParams().set('szEmail', email);
     return this.appService.get(this.globlalConstants.BWSEC_GET_POLICY_BY_EMAIL_URL, params);
   }

   checkRegistrationStatus(mco: string, policynum: string): Observable<CheckRegistrationStatusResponse> {
      this.policyNumber = new Policynumber(policynum);
      const pointHookId = mco.concat(this.policyNumber.symbol).concat(this.policyNumber.policy);
      let isBWOktaEnabled : boolean = environment.bwOktaEnabled;
      const params = new HttpParams()
      .set('szPointHookID', pointHookId)
      .set(this.globlalConstants.BW_OKTA_ENABLED_PARAM_KEY, isBWOktaEnabled ? this.globlalConstants.BW_OKTA_ENABLED_PARAM_VALUE : 'false');

      return this.appService.get(this.globlalConstants.BWSEC_CHECK_REGISTRATION_STATUS, params);
   }
}
