export class Uploadfilereq {
  policyNumber: string;
  producerCode: string;
  state: string;
  mailingName: string;
  documentType: string;
  sourceSystem: string;
  effectiveDate: string; // YYYY-MM-DD
  // documentContent: File;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
