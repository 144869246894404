<div class="info-container d-flex align-items-center">
  <ng-container *ngIf="isBankAccount">
      <span class="bank-account-icon" [attr.aria-label]="imgAriaLabel"><mat-icon>account_balance</mat-icon></span>
      <span [attr.aria-label]="accountInfoAriaLabel">&nbsp;&bull;&bull;&bull;&bull;&nbsp;{{ paymentMethodInfo.accountNumber | slice: -4 }}</span>
      <span class="autoPay" *ngIf="paymentMethodInfo.isDefault">Auto Pay!</span>
  </ng-container>
  <ng-container *ngIf="isCard">
      <img [src]="imgSrcAttr" alt="{{imgAriaLabel}}" />
      <span class="redacted-info" aria-label="ending with">&nbsp;&bull;&bull;&bull;&bull;&nbsp;</span>
      <span>{{ paymentMethodInfo.accountNumber | slice: -4 }}</span>
      <span *ngIf="showExpDate" [attr.aria-label]="cardInfoAriaLabel">&nbsp;| Exp {{ paymentMethodInfo.expiryMonth }}/{{paymentMethodInfo.expiryYear}}</span>
      <span class="autoPay" *ngIf="paymentMethodInfo.isDefault">Auto Pay!</span>
  </ng-container>
</div>
