import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { PolicyholderService } from '../../../services/api/policyholder/policyholder.service';
import { BwstoreService } from '../../services/bwstore.service';
import { GoogleAnalyticsService } from '../../services/analytics/google-analytics.service';
import { TimeoutService } from '../../../services/timeout.service';
import { Tracker } from '../../utils/tracker';
import { environment } from '../../../../environments/environment';
import { Policynumber } from '../../model/policynumber';
import { BWError } from '../../model/error.model';
import { FullPolicyDetails } from '../../model/api/bwibic/fullpolicydetails.model';
import { MypolicyService } from '../../../services/mypolicy/mypolicy.service';
import { AddresseditService } from '../../../services/addressedit/addressedit.service';
import { TextAlertsService } from '../../../services/api/text-alerts/text-alerts.service';
var SsoLoginComponent = /** @class */ (function () {
    function SsoLoginComponent(route, router, authService, plcyhlderService, storeService, googleAnalytics, timeoutService, policyService, textAlertsService, addresseditService) {
        this.route = route;
        this.router = router;
        this.authService = authService;
        this.plcyhlderService = plcyhlderService;
        this.storeService = storeService;
        this.googleAnalytics = googleAnalytics;
        this.timeoutService = timeoutService;
        this.policyService = policyService;
        this.textAlertsService = textAlertsService;
        this.addresseditService = addresseditService;
        this.isLoaded = false;
        this.isEligibleForEndorsement = true;
        this.bwOktaEnable = environment.bwOktaEnabled;
        this.bwOktaSSOEnabled = false;
    }
    SsoLoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        // load token and path from URL
        this.route.queryParams.subscribe(function (params) {
            if (params) {
                if (params.token) {
                    _this.token = params.token;
                    //this.path = params.path;
                }
                if (params.path) {
                    _this.path = params.path;
                }
                if (params.code) {
                    _this.token = params.code;
                    _this.bwOktaSSOEnabled = true;
                }
                if (params.state) {
                    _this.path = params.state;
                    _this.bwOktaSSOEnabled = true;
                }
            }
        });
        if (this.token && this.path) {
            console.log("Code = " + this.token + " path = " + this.path);
            this.loginUser(this.token);
        }
        else {
            return this.router.navigate(['/']);
        }
    };
    SsoLoginComponent.prototype.loginUser = function (ssoToken) {
        var _this = this;
        if (ssoToken) {
            this.authService
                .ssologin(this.token, this.bwOktaSSOEnabled)
                .then(function (phuser) {
                _this.googleAnalytics.trackEvent('Login', 'LoginAttemptSuccess', 'LoginAttempt', 200);
                Tracker.loginfo('SsoLoginComponent', 'ngOnInit()', 'Initializing onload functions', 'Component ## Policy Data from Store :::: ');
                if (phuser && phuser.jwtToken && phuser.jwtToken !== null && phuser.jwtToken !== '') {
                    _this.authService.getPolicyModAndVerifyPendingDocs(phuser).then(function (plcyHolder) {
                        // start timeout service
                        _this.timeoutService.start();
                        _this.storeService.updateLoginInfo(plcyHolder);
                        _this.authService.createWebSessLog(plcyHolder);
                        if (plcyHolder && plcyHolder.gopaperlessInd === true) {
                            return _this.router.navigate(['/gptermsnconditions']);
                        }
                        else if (plcyHolder && plcyHolder.esigstatus === true) {
                            return _this.router.navigate(['/esigmessages']);
                        }
                        else {
                            _this.policyNumber = new Policynumber(plcyHolder.pointHookID.substring(2).concat(plcyHolder.policyMod));
                            _this.policyService.getAllBasicPolicyInfo(plcyHolder.pointHookID.substring(0, 2), _this.policyNumber.symbol, _this.policyNumber.policy, _this.policyNumber.module)
                                .then(function (policyres) {
                                Tracker.loginfo('SsoLoginComponent', 'loginUser()', 'Invoked policyService.getAllBasicPolicyInfo()', 'Retrieving data from service... ');
                                _this.storeService.addPolicyInfo(policyres);
                                switch (_this.path) {
                                    case 'gp': {
                                        return _this.router.navigate(['/policy'], { skipLocationChange: true }).then(function () {
                                            return _this.router.navigate(['/profile']);
                                        });
                                    }
                                    case 'ta': {
                                        _this.textAlertsService.GetTextAlertEnrollmentStatus(policyres.agentInfo.AgentCode, policyres.agentInfo.AgentType, policyres.policyInfo.PolicyMasterCompany, plcyHolder.riskstate, policyres.policyInfo.PolicyNumber)
                                            .subscribe(function (textAlertRes) {
                                            plcyHolder.textAlertsEnrollment = textAlertRes.length === 0 ? 'false' : 'true';
                                            // If there are phone store them in store
                                            if (textAlertRes.length > 0) {
                                                plcyHolder.textAlertEnrolledPhones = textAlertRes;
                                            }
                                            _this.storeService.updateLoginInfo(plcyHolder);
                                            // need to go to my-policy then profile
                                            return _this.router.navigate(['/policy'], { skipLocationChange: true }).then(function () {
                                                return _this.router.navigate(['/profile/txtalert']);
                                            });
                                        });
                                        return _this.router.navigate(['/policy']);
                                    }
                                    case 'cu': {
                                        return _this.router.navigate(['/policy'], { skipLocationChange: true }).then(function () {
                                            return _this.router.navigate(['/profile/updatelogin'], { queryParams: { type: 'userid' } });
                                        });
                                    }
                                    case 'cp': {
                                        return _this.router.navigate(['/policy'], { skipLocationChange: true }).then(function () {
                                            return _this.router.navigate(['/profile/updatelogin'], { queryParams: { type: 'password' } });
                                        });
                                    }
                                    case 'cs': {
                                        // route to home page to login
                                        _this.storeService.deletePolicyInfo(policyres);
                                        _this.storeService.deleteLoginInfo(plcyHolder);
                                        return _this.router.navigate(['/']);
                                        // return this.router.navigate(['/profile/updatelogin'], { queryParams: { type: 'question' } });
                                    }
                                    case 'md': {
                                        return _this.router.navigate(['/policy'], { skipLocationChange: true }).then(function () {
                                            return _this.router.navigate(['policy/documents']);
                                        });
                                    }
                                    case 'ap': {
                                        return _this.router.navigate(['/policy'], { skipLocationChange: true }).then(function () {
                                            return _this.router.navigate(['policy/autopay']);
                                        });
                                    }
                                    case 'pd': {
                                        return _this.router.navigate(['/policy'], { skipLocationChange: true }).then(function () {
                                            return _this.router.navigate(['policy/missing-document']);
                                        });
                                    }
                                    // Add Vehicle
                                    case 'av': {
                                        return _this.router.navigate(['/policy'], { skipLocationChange: true }).then(function () {
                                            return _this.router.navigate(['policy/add-vehicle']);
                                        });
                                    }
                                    // Replace Vehicle
                                    case 'rv': {
                                        return _this.router.navigate(['/policy'], { skipLocationChange: true }).then(function () {
                                            return _this.router.navigate(['policy/replace-vehicle']);
                                        });
                                    }
                                    // Remove Vehicle
                                    case 'dv': {
                                        return _this.router.navigate(['/policy'], { skipLocationChange: true }).then(function () {
                                            return _this.router.navigate(['policy/remove-vehicle']);
                                        });
                                    }
                                    // Edit Address or Phone
                                    case 'pd': {
                                        return _this.router.navigate(['/policy'], { skipLocationChange: true }).then(function () {
                                            return _this.router.navigate(['policy/missing-document']);
                                        });
                                    }
                                    default: {
                                        return _this.router.navigate(['/policy']);
                                    }
                                }
                            }).catch(function (error) {
                                Tracker.logerror('SsoLoginComponent', 'loginUser()', 'Initializing onload functions', 'MypolicyService-getAllBasicPolicyInfo() Failed', error);
                            });
                        }
                    }).catch(function (error) {
                        _this.storeService.deleteLoginInfo(null);
                        Tracker.logerror('SsoLoginComponent', 'loginUser()', 'Initializing onload functions', 'MypolicyService-getAllBasicPolicyInfo() Failed', error);
                        _this.isLoaded = false;
                        if (error instanceof BWError) {
                        }
                        Tracker.logerror('SsoLoginComponent', 'loginUser', 'loginUser', 'Error during sso login.', error);
                        return _this.router.navigate(['/']);
                    });
                }
            }).catch(function (error) {
                _this.googleAnalytics.trackEvent('SsoLoginComponent', 'LoginAttemptFail', 'LoginAttempt', 500);
                _this.storeService.deleteLoginInfo(null);
                return _this.router.navigate(['/']);
            });
        }
    };
    return SsoLoginComponent;
}());
export { SsoLoginComponent };
