import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalConstants } from '../../../constants/global.constant';
import { CreateEnvelopeReq } from '../../../shared/model/api/esig/createenvelopereq.model';
import { CreateEnvelopeRes } from '../../../shared/model/api/esig/createenveloperes.model';
import { EnvelopesResp } from '../../../shared/model/api/esig/esigenvelopres.model';
import { IsESigImplementedResp } from '../../../shared/model/api/esig/esigimplres.model';
import { PolicyHolder } from '../../../shared/model/authentication/authenticatephres';
import { Policynumber } from '../../../shared/model/policynumber';
import { BwstoreService } from '../../../shared/services/bwstore.service';
import { BwappService } from '../../bwapp.service';
import { BWErrorhandler } from '../../errorhandler';
import { CreateEmbeddedEnvelopeReq } from '../../../shared/model/api/esig/createembeddedenvelopereq.model';
import { GetEsignEligibilityReq } from '../../../shared/model/api/esig/getesigneligibility.req.model';
import { RecipientsToSignReq } from '../../../shared/model/api/esig/recipientstosignreq.model';
import { CreateEmbeddedEnvelopeRes } from '../../../shared/model/api/esig/createembeddedenveloperes.model';

@Injectable({
  providedIn: 'root'
})
export class EsigService {

  private globlalConstants = GlobalConstants;
  private createEnvelopeReq: CreateEnvelopeReq;
  private phUser: PolicyHolder;

  policyNumber: Policynumber;
  private createEmbeddedEnvelopeReq: CreateEmbeddedEnvelopeReq;
  constructor(private appService: BwappService, private storeService: BwstoreService, private errorHandler: BWErrorhandler) { }

  isESigImplemented(mco: string, stateCd: string): Observable<IsESigImplementedResp> {
    const params = new HttpParams()
      .set('mco', mco)
      .set('stateCd', stateCd);

    return this.appService.get(this.globlalConstants.ESIG_ISESIGIMPLEMENTED, params);
  }


  getEnvelops(firstName: string, lastName: string, policynumber: string): Observable<EnvelopesResp> {

    this.policyNumber = new Policynumber(policynumber);
    // Tracker.log('EsigService - getEnvelops() with policy number: ', policynumber, this.policyNumber );

    const params = new HttpParams()
      .set('firstName', firstName)
      .set('lastName', lastName)
      .set('policySym', this.policyNumber.symbol)
      .set('policyNum', this.policyNumber.policy)
      .set('policyMod', this.policyNumber.module);

    return this.appService.get(this.globlalConstants.ESIG_GETENVELOPES, params);
  }

  createEnvelopeAndWaitForVendor(mco: string, policynumber: string, library: string): Observable<CreateEnvelopeRes> {

    this.policyNumber = new Policynumber(policynumber);
    // Tracker.log('EsigService - getEnvelops() with policy number: ', policynumber, this.policyNumber );

    this.phUser = this.storeService.getLoginInfoFromStore();
    this.createEnvelopeReq = new CreateEnvelopeReq();
    this.createEnvelopeReq.policycontext = library;
    this.createEnvelopeReq.mco =  mco;
    this.createEnvelopeReq.symbol = this.policyNumber.symbol,
    this.createEnvelopeReq.policynum = this.policyNumber.policy;
    this.createEnvelopeReq.module = this.policyNumber.module;
    this.createEnvelopeReq.email = this.phUser.email;
    this.createEnvelopeReq.fullname = '';
    this.createEnvelopeReq.accesscode = '';
    this.createEnvelopeReq.srcsystem = this.globlalConstants.TFC_SRC_SYSTEM;


    return this.appService.post(this.globlalConstants.ESIG_CREATEENVELOPEANDWAITFORVENDOR, this.createEnvelopeReq);
  }
// keerthana starts for MI project
getEsignEligibility(getEligibilityreq: GetEsignEligibilityReq) {
  return this.appService.post(this.globlalConstants.ESIG_GET_ELIGIBLE_STATUS, getEligibilityreq);
  }

createEmbeddedEnvelope(mco: string, policynumber: string, library: string,
    hasRenewal: boolean, docSeqNoArry: Array<string>,
    recipientsToSignReqArry: Array<RecipientsToSignReq>): Observable<CreateEmbeddedEnvelopeRes> {
      let activityType = '';
    this.policyNumber = new Policynumber(policynumber);
    if (hasRenewal) {
      activityType = 'RB';
    } else {
      activityType = 'NB';
    }

    // Tracker.log('EsigService - getEnvelops() with policy number: ', policynumber, this.policyNumber );
   //  this.phUser = this.storeService.getLoginInfoFromStore();
    this.createEmbeddedEnvelopeReq = new CreateEmbeddedEnvelopeReq();
    this.createEmbeddedEnvelopeReq.polContext = library;
    this.createEmbeddedEnvelopeReq.polMco =  mco;
    this.createEmbeddedEnvelopeReq.polSym = this.policyNumber.symbol,
    this.createEmbeddedEnvelopeReq.polNum = this.policyNumber.policy;
    this.createEmbeddedEnvelopeReq.policyMod = this.policyNumber.module;
    this.createEmbeddedEnvelopeReq.sourceSystem = this.globlalConstants.APPID;
    this.createEmbeddedEnvelopeReq.documentSeqNums = docSeqNoArry;
    this.createEmbeddedEnvelopeReq.activityType = activityType;
    this.createEmbeddedEnvelopeReq.recipientsToSignRequests = recipientsToSignReqArry;
    return this.appService.post(this.globlalConstants.ESIG_CREATEEMBEDDEDENVELOPE, this.createEmbeddedEnvelopeReq);
  }
// keerthana ends for MI project
}
