export class Processcardreq {

  policyKey: PolicyKey;
  eftCard: EFTCard;
  eftCardHolder: CardHolder;

  eCommerceIndicator: string; // ECOMMERCE
  amount: string; // 0
  browserType: string; // Chrome69
  eftEnrollment: boolean; // true
  transactionSource: string; // POS
  transactionSourceSystem: string; // BWM
  applyPayment: boolean; // false

  constructor() {}
}

export class PolicyKey {
  masterCompany: string;
  policyCompany: string;
  symbol: string;
  policyNumber: string;
  module: string;

  constructor() {}
}

export class EFTCard {

  eftCardNumber: string;
  eftCardType: string;
  eftCardExpirationDate:  string;
  eftCardCVV: string;

  constructor() {}
}

export class CardHolder {

  eftFirstName: string;
  eftLastName: string;
  eftStreetAddress: string;
  eftCity: string;
  eftState: string;
  eftCountry: string;
  eftZipCode: string;

  constructor() {}
}



