import { OnInit, ChangeDetectorRef } from "@angular/core";
import { FormBuilder, Validators, } from "@angular/forms";
import { Router } from "@angular/router";
import { BwstoreService } from "../../shared/services/bwstore.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Ccpaymentreq } from "../../shared/model/api/payment/ccpaymentreq";
import { FullPolicyDetails, PolicyInfo, } from "../../shared/model/api/bwibic/fullpolicydetails.model";
import { Paymentsummary, } from "../../shared/model/api/bwibic/fullpolicydetails.model";
import { Keyvalue } from "../../shared/model/keyvalue";
import { GlobalConstants } from "../../constants/global.constant";
import { Policykey } from "../../shared/model/api/payment/policykey";
import { Paymentdetailsres } from "../../shared/model/api/payment/paymentdetailsres";
import { Previouspaymentreq } from "../../shared/model/api/payment/previouspaymentreq";
import { PaymentOptionType } from "../../shared/model/api/payment/payment-option-type";
import { Achpaymentreq } from "../../shared/model/api/payment/achpaymentreq";
import { MessagesService } from "../../shared/services/messages.service";
import { AutopayeftService } from "../../services/mypolicy/autopayeft.service";
import { GoogleAnalyticsService } from "../../shared/services/analytics/google-analytics.service";
import { MessageConstants } from "../../constants/message.constant";
import { Tracker } from "../../shared/utils/tracker";
import { Meta, Title } from "@angular/platform-browser";
import { PaymentService } from "../../services/api/payment/payment.service";
import { NewPaymentService } from "../../services/api/payment/new-payment.service";
import { MatDialog } from "@angular/material/dialog";
import { PaymentusPaymentMethodDialogComponent } from "./paymentus-payment-method-dialog/paymentus-payment-method-dialog.component";
import { MatSnackBar } from "@angular/material";
import { filter } from "rxjs/operators";
import { fromEvent } from "rxjs";
var PaymentComponent = /** @class */ (function () {
    function PaymentComponent(ref, newPaymentService, paymentService, router, fb, _storeService, _messageService, modalService, eftService, googleAnalytics, title, dialog, snackbar, meta) {
        var _this = this;
        this.ref = ref;
        this.newPaymentService = newPaymentService;
        this.paymentService = paymentService;
        this.router = router;
        this.fb = fb;
        this._storeService = _storeService;
        this._messageService = _messageService;
        this.modalService = modalService;
        this.eftService = eftService;
        this.googleAnalytics = googleAnalytics;
        this.title = title;
        this.dialog = dialog;
        this.snackbar = snackbar;
        this.meta = meta;
        // NG Store
        this.paymentError = false;
        this.selectedPaymentMethod = {};
        this.billingDetails = {};
        this.paymentAmountDetails = {};
        this.selectedPayment = {};
        this.newPaymentResponse = {};
        this.paymentErrorMsg = "";
        this.serviceUnavailableMsg = MessageConstants.PAY_SERVICE_DOWN;
        this.serviceOpsNumber = GlobalConstants.SERVICE_OPS_NUMBER;
        this.noPaymentsLeftMsg = MessageConstants.PAY_NO_PAYMENTS;
        this.bankError = "";
        this.bankName = "";
        this.isOtherAmount = false;
        this.isCreditCard = false;
        this.isNew = false;
        this.isNewCC = false;
        this.isNewACH = false;
        this.isNewACHS = false;
        this.hasPayments = true;
        this.isServiceAvaiable = true;
        this.isNoLogin = false;
        this.showModal = false;
        this.selectedRadioButton = false;
        this.updatePaymentToken = '';
        // For ACH
        this.checkDate = Date.now();
        // For CC
        this.cvcLength = "3";
        this.ccLength = "18";
        this.selectedCard = "none";
        this.cvcSVG = "visa-mc-cvc-preview";
        // UI
        this.isLoaded = false;
        this.otherAmount = "";
        this.errorType = "";
        this.isPaymentMethodExits = false;
        this.DEFAULT_DIALOG_MIN_HEIGHT = "50vh";
        this.DEFAULT_DIALOG_WIDTH = "400px";
        this.DEFAULT_MAX_DIALOG_WIDTH = "98vw";
        this.EVENT_NAME = "message";
        this.PAYMENTUS_IO = "paymentus";
        this.PM_DETAILS = "pmDetails";
        this.paymentusEventNewPaymentMethod$ = fromEvent(window, this.EVENT_NAME).pipe(filter(function (event) {
            return event.origin.includes(_this.PAYMENTUS_IO);
        }));
        title.setTitle("Bristol West Policy - Payments");
        this.googleAnalytics.trackEvent("Billing", "BillingScreen", "BillingButtonClick", 200);
    }
    PaymentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadingMessage = "Retrieving Payment Details...";
        this.basicPolicyDetails = this._storeService.getPolicyInfoFromStore();
        this.authenticatedPhDetails = this._storeService.getLoginInfoFromStore();
        this.selectedPayment.amount = "";
        this.selectedPayment.type = "";
        this.selectedRadioButton = false;
        // Load policy holder info from store
        this.policyInfo = this.basicPolicyDetails.policyInfo;
        this.billingDetails.basicPolicyDetails = this.basicPolicyDetails;
        this.billingDetails.policyInfo = this.policyInfo;
        this.paymentHistoryInfo = this.basicPolicyDetails.paymentHistoryInfo;
        this.paymentAmountDetails.basicPolicyDetails = this.basicPolicyDetails;
        this.paymentAmountDetails.policyInfo = this.policyInfo;
        this.accNumber =
            this.policyInfo.Location +
                this.policyInfo.MASTER0CO +
                this.policyInfo.PolicyCompany +
                this.policyInfo.SYMBOL +
                this.policyInfo.POLICY0NUM;
        this.paymentService
            .setPaymentMethods(this.accNumber)
            .subscribe(function (paymentMethods) {
            if (paymentMethods)
                _this.paymentMethods = paymentMethods;
        });
        this.addNewPaymentMethod();
        // first check to see if there is a PaymentInfo Object
        this.paymentinfo = this.basicPolicyDetails.paymentInfo;
        if (this.paymentinfo && this.basicPolicyDetails.isNoLogin) {
            this.isNoLogin = true;
            this.paymentDetailsRes = new Paymentdetailsres();
            this.paymentDetailsRes.address = this.paymentinfo.address;
            this.paymentDetailsRes.city = this.paymentinfo.city;
            this.paymentDetailsRes.firstName = this.paymentinfo.firstName;
            this.paymentDetailsRes.homePhoneNumber = this.paymentinfo.homePhoneNumber;
            this.paymentDetailsRes.lastName = this.paymentinfo.lastName;
            this.paymentDetailsRes.lastPayAmount = this.paymentinfo.lastPayAmount;
            this.paymentDetailsRes.lastPayDate = this.paymentinfo.lastPayDate;
            this.paymentDetailsRes.maximumPayment = this.paymentinfo.maximumPayment;
            this.paymentDetailsRes.minimumPayment = this.paymentinfo.minimumPayment;
            this.paymentDetailsRes.paymentMoniker = null; // this.paymentinfo.paymentMoniker;
            this.paymentDetailsRes.paymentType = this.paymentinfo.paymentType;
            this.paymentDetailsRes.paymentVendor = this.paymentinfo.paymentVendor;
            this.paymentDetailsRes.policyBalance = this.paymentinfo.policyBalance;
            this.paymentDetailsRes.state = this.paymentinfo.state;
            this.paymentDetailsRes.totalPremium = this.paymentinfo.totalPremium;
            this.paymentDetailsRes.zip = this.paymentinfo.zip;
            this.paymentDetailsRes.eftIndicator = this.paymentinfo.eftIndicator;
            this.paymentDetailsRes.policyCompany = this.policyInfo.PolicyCompany;
            if (this.vaildateServiceResponse(this.paymentDetailsRes, this.policyInfo, this.paymentHistoryInfo)) {
                // Reactive form builder for Form Validations
                this.createPaymentForm();
                Tracker.loginfo("PaymentComponent", "ngOnInit", "vaildateServiceResponse", "Loaded payment details " + this.paymentDetailsRes.responseText);
                // Remove session
                this._storeService.deletePolicyInfo(new FullPolicyDetails());
                this.isLoaded = true;
            }
            else {
                return false;
            }
        }
        else {
            if (this.policyInfo !== null) {
                // need to load the payment moniker from the service call
                this.paymentService
                    .validatePolicy(this.policyInfo.PolicyZip, this.policyInfo.SYMBOL, this.policyInfo.POLICY0NUM)
                    .subscribe(function (data) {
                    _this.paymentDetailsRes = data;
                    if (_this.paymentDetailsRes !== null &&
                        _this.vaildateServiceResponse(_this.paymentDetailsRes, _this.policyInfo, _this.paymentHistoryInfo)) {
                        Tracker.loginfo("PaymentComponent", "ngOnInit", "vaildateServiceResponse", "Loaded payment details " +
                            _this.paymentDetailsRes.responseText);
                        // Reactive form builder for Form Validations
                        _this.createPaymentForm();
                        _this.isLoaded = true;
                    }
                    else {
                        _this.isLoaded = true;
                        return false;
                    }
                }, function (error) {
                    _this.isServiceAvaiable = false;
                    Tracker.logerror("PaymentComponent", "ngOnInit", "", "Error loading payment screen.", error);
                    _this.isLoaded = true;
                });
            }
            else {
                this.isServiceAvaiable = false;
                this.isLoaded = true;
            }
        }
    };
    PaymentComponent.prototype.vaildateServiceResponse = function (paymentdetialsres, policyinfo, paymentHistoryInfo) {
        if (paymentdetialsres != null &&
            paymentdetialsres.policyBalance !== undefined) {
            // Tracker.log(paymentdetialsres.policyBalance);
            if (+paymentdetialsres.minimumPayment <= 0) {
                this.hasPayments = false;
                return false;
            }
        }
        else {
            this.isServiceAvaiable = false;
            return false;
        }
        this.policyKey = new Policykey();
        this.policyKey.masterCompany = policyinfo.PolicyMasterCompany;
        this.policyKey.module =
            paymentdetialsres.mod !== undefined
                ? paymentdetialsres.mod
                : policyinfo.MODULE;
        this.policyKey.policyCompany = policyinfo.PolicyCompany;
        this.policyKey.policyNumber = policyinfo.POLICY0NUM;
        this.policyKey.symbol = policyinfo.SYMBOL;
        if (paymentdetialsres.paymentMoniker !== null &&
            paymentdetialsres.paymentMoniker !== undefined) {
            this.isPaymentMethodExits = true;
            this.paymentMoniker = paymentdetialsres.paymentMoniker;
            this.newMoniker = this.paymentMoniker;
        }
        else {
            this.paymentMoniker = "Please select a payment method";
        }
        // For Payment Header
        if (!this.isNoLogin) {
            //console.log("in ");
            this.premium = paymentdetialsres.totalPremium;
            this.policystatus = this.policyInfo.PolicyStatus;
            this.policystatusepc = this.policyInfo.PolicyStatusEPC;
            if (paymentdetialsres.lastPayAmount && paymentdetialsres.lastPayAmount !== '' && paymentdetialsres.lastPayDate &&
                paymentdetialsres.lastPayDate !== null && paymentdetialsres.lastPayDate !== 'null' && paymentdetialsres.lastPayDate !== '' && paymentdetialsres.lastPayDate !== 'undefined') {
                // console.log("in2");
                this.lastPaymentDate = paymentdetialsres.lastPayDate;
                this.paymentAmount = paymentdetialsres.lastPayAmount;
            }
            else {
                //console.log("in3");
                if (paymentHistoryInfo && paymentHistoryInfo.length > 0) {
                    //console.log("in4");
                    for (var index = paymentHistoryInfo.length - 1; index >= 0; index--) {
                        var currentPaymentHistory = paymentHistoryInfo[index];
                        if (currentPaymentHistory.TransactionType === "Payment") {
                            this.lastPaymentDate =
                                paymentHistoryInfo[index].PaymentActivityDate;
                            this.paymentAmount = paymentHistoryInfo[index].PaymentAmount.replace("-", "");
                            break;
                        }
                    }
                }
            }
        }
        // For Amount Due
        this.keyvalueAmount = new Keyvalue();
        this.keyvalueAmount.key = "Amount Due $" + paymentdetialsres.minimumPayment;
        this.keyvalueAmount.value = paymentdetialsres.minimumPayment;
        this.keyvalueBalance = new Keyvalue();
        this.keyvalueBalance.key =
            "Remaining Balance $" + paymentdetialsres.maximumPayment;
        this.keyvalueBalance.value = paymentdetialsres.maximumPayment;
        this.amountOptions = [this.keyvalueAmount, this.keyvalueBalance];
        this.selectedAmount = paymentdetialsres.minimumPayment;
        if (paymentdetialsres.paymentType === null ||
            paymentdetialsres.paymentType === "") {
            this.isNew = true;
        }
        else if (paymentdetialsres.paymentType === "Credit Card") {
            this.isCreditCard = true;
        }
        return true;
    };
    PaymentComponent.prototype.onSubmitNew = function (formData, modalsubmitpayment) {
        var _this = this;
        this.modalService
            .open(modalsubmitpayment, { size: "sm", centered: false })
            .result.then(function (result) {
            _this.isLoaded = false;
            _this.loadingMessage = "Online Payment In Progress";
            _this.googleAnalytics.trackEvent("PayMyBill", "PaymentDetailsScreen", "PayMyBillButtonFromHomeScreen", 200);
            var newPaymentRequest = {
                payment: {
                    id: "",
                    duplicateCheck: false,
                    paymentAmount: 0.0,
                    paymentMethodToken: "",
                    agentFieldOfficeId: "",
                    walletId: "",
                    transactionSourceSystem: "",
                    exixtingCustomerInd: "",
                    uwCompanyCode: "",
                    policy: {
                        uwCompanyCode: "",
                    },
                    customer: {
                        firstName: "",
                        lastName: "",
                        phoneNumber: "",
                        email: "",
                        address: {
                            line1: "",
                            city: "",
                            state: "",
                            zipCode: "",
                            country: "",
                        },
                    },
                },
            };
            //console.log(result);
            //this.newPaymentRequest.payment.id = this.selectedPaymentMethod.data.id;
            newPaymentRequest.payment.id = _this.accNumber;
            //newPaymentRequest.payment.id = "002800G011429321";
            newPaymentRequest.payment.paymentAmount = parseFloat(_this.selectedPayment.amount);
            newPaymentRequest.payment.paymentMethodToken =
                _this.selectedPaymentMethodNew.method.paymentToken;
            //newPaymentRequest.payment.paymentMethodToken ="9501B4C659A28AECD5ECC39EAB572D3AB8A4B342"
            //newPaymentRequest.payment.agentFieldOfficeId = this.basicPolicyDetails.agentInfo.AgentCode;
            newPaymentRequest.payment.agentFieldOfficeId = "5006337";
            newPaymentRequest.payment.walletId = newPaymentRequest.payment.id;
            //newPaymentRequest.payment.stateCode =
            newPaymentRequest.payment.transactionSourceSystem =
                GlobalConstants.APPID;
            newPaymentRequest.payment.exixtingCustomerInd = "N";
            newPaymentRequest.payment.uwCompanyCode =
                _this.policyKey.masterCompany.concat(_this.paymentDetailsRes.policyCompany);
            newPaymentRequest.payment.policy.uwCompanyCode =
                _this.policyKey.masterCompany.concat(_this.paymentDetailsRes.policyCompany);
            //newPaymentRequest.payment.uwCompanyCode = "2800";
            //newPaymentRequest.payment.policy.uwCompanyCode = "2800";
            //----customer details----
            newPaymentRequest.payment.customer.firstName = formData.fname;
            newPaymentRequest.payment.customer.lastName = formData.lname;
            //this.newPaymentRequest.payment.customer.phoneNumber =
            //this.newPaymentRequest.payment.customer.email =
            //----address----
            newPaymentRequest.payment.customer.address.city = formData.city;
            //this.newPaymentRequest.payment.customer.address.country=
            newPaymentRequest.payment.customer.address.line1 = formData.address;
            newPaymentRequest.payment.customer.address.state = formData.state;
            newPaymentRequest.payment.customer.address.zipCode = formData.zip;
            newPaymentRequest.payment.duplicateCheck = true;
            //console.log(newPaymentRequest);
            _this.newPaymentService.makeNewPayment(newPaymentRequest).subscribe(function (data) {
                //console.log(data);
                _this.newPaymentResponse = data;
                // this.newPaymentResponse.transactionNotification = null;
                // this.newPaymentResponse.payment.status = 'DECLINED';
                // this.newPaymentResponse.payment.statusDescription='Credit Floor';
                if (_this.newPaymentResponse.transactionNotification ||
                    _this.newPaymentResponse.payment.status !== "ACCEPTED") {
                    //console.log(this.newPaymentResponse);
                    _this.isLoaded = true;
                    _this.paymentError = true;
                    _this.selectedPayment.validated = false;
                    _this.selectedPaymentMethodNew = null;
                    //this.paymentErrorService.transactionNotification.next(this.newPaymentResponse.transactionNotification);
                    //this.router.navigate(['payment-failed']);
                }
                else {
                    _this.paymentError = false;
                    _this.updatePaymentSummaryNew(formData, _this.newPaymentResponse);
                    _this.router.navigate(["payment-success"]);
                    _this.isLoaded = true;
                    _this.loadingMessage = "";
                }
            }, function (error) {
                _this.isLoaded = true;
                _this.loadingMessage = "";
                _this.paymentError = true;
                _this.selectedPayment.validated = false;
                _this.selectedPaymentMethodNew = null;
                _this.snackbar.open(MessageConstants.API_ERROR, "", {
                    duration: 3000,
                });
            });
        }, function (reason) {
            _this.isLoaded = true;
            _this.loadingMessage = "";
            //part of Defect DE84306
            //this.selectedPayment.validated = false;
            //this.selectedPaymentMethodNew = null;
            return;
        })
            .catch(function (error) {
            _this.isLoaded = true;
            _this.loadingMessage = "";
            _this.selectedPayment.validated = false;
            _this.selectedPaymentMethodNew = null;
            Tracker.logerror("PaymentComponent", "onSubmit", "this.modalService.open", "Error occurred with payment modal.", error);
            _this.snackbar.open(MessageConstants.API_ERROR, "", { duration: 3000 });
        });
    };
    PaymentComponent.prototype.addEditPaymentMethod = function (paymentType, token, addOrEdit) {
        var _this = this;
        //console.log("In here");
        var temp = { method: {
                paymentToken: ''
            } };
        var name = this.policyInfo.ADD0LINE01.split(" ");
        this.dialogRef = this.dialog.open(PaymentusPaymentMethodDialogComponent, {
            disableClose: true,
            closeOnNavigation: true,
            minHeight: this.DEFAULT_DIALOG_MIN_HEIGHT,
            width: this.DEFAULT_DIALOG_WIDTH,
            maxWidth: this.DEFAULT_MAX_DIALOG_WIDTH,
            data: {
                paymentType: paymentType,
                token: token,
                addOrEdit: addOrEdit,
                pageName: "Pay Bill",
                firstName: name[1],
                lastName: name[0],
                ownerId: this.accNumber.toLowerCase(),
                postMessagePmDetailsOrigin: window.location.origin,
                paymentToken: token ? token : "",
            },
        });
        this.dialogRef.afterClosed().subscribe(function () {
            //console.log("In Here");
            temp.method.paymentToken = token;
            _this.selectedPaymentMethodNew = temp;
            //console.log(this.selectedPaymentMethodNew);
            //console.log(this.selectedPayment);
            _this.meta.updateTag({ content: "width=device-width, initial-scale=1" }, "name='viewport'");
        });
        this.showModal = true;
    };
    PaymentComponent.prototype.onSubmit = function (formData, modalsubmitpayment) {
        var _this = this;
        this.formSubmitAttempt = true;
        // Clear previous error
        this.paymentErrorMsg = "";
        // Tracker.log('form submitted');
        this.loadingMessage = "Processing Payment...";
        try {
            if (this.paymentForm.valid) {
                this.isLoaded = false;
                if (this.isOtherAmount) {
                    this.selectedAmount = formData.otherAmount;
                }
                if (this.isValidAmount(this.selectedAmount)) {
                    // Set moniker if new payment method before displaying the modal
                    if (this.isNew) {
                        if (this.isNewCC) {
                            this.newMoniker = this.selectedCard
                                .toUpperCase()
                                .concat(" - ")
                                .concat(formData.ccNumber.substring(formData.ccNumber.length - 4));
                        }
                        else if (this.isNewACH) {
                            this.newMoniker = "Checking - ".concat(formData.accountNumber.substring(formData.accountNumber.length - 4));
                        }
                        else if (this.isNewACHS) {
                            this.newMoniker = "Saving - ".concat(formData.accountNumber.substring(formData.accountNumber.length - 4));
                        }
                    }
                    // Prompt user to confirm payment
                    this.modalService
                        .open(modalsubmitpayment, { size: "sm", centered: false })
                        .result.then(function (result) {
                        _this.googleAnalytics.trackEvent("PayMyBill", "PaymentDetailsScreen", "PayMyBillButtonFromHomeScreen", 200);
                        if (_this.isNew) {
                            // Paying with new payment method
                            if (_this.isNewCC) {
                                // populate credit card payment request
                                _this.ccpaymentreq = new Ccpaymentreq();
                                _this.ccpaymentreq.accountHolderFirstName = formData.fname;
                                _this.ccpaymentreq.accountHolderLastName = formData.lname;
                                _this.ccpaymentreq.address = formData.address;
                                _this.ccpaymentreq.city = formData.city;
                                _this.ccpaymentreq.postalCode = formData.zip;
                                _this.ccpaymentreq.state = formData.state;
                                _this.ccpaymentreq.policyKey = _this.policyKey;
                                _this.ccpaymentreq.eftEnrollment =
                                    _this.paymentDetailsRes.eftIndicator;
                                _this.ccpaymentreq.referenceCode = "12";
                                _this.ccpaymentreq.cardNumber = _this.formatCC(formData.ccNumber);
                                _this.ccpaymentreq.expirationMonth =
                                    formData.ccExp.substring(0, 2);
                                _this.ccpaymentreq.expirationYear =
                                    "20" + formData.ccExp.substring(3);
                                _this.ccpaymentreq.transactionSource = GlobalConstants.APPID;
                                _this.ccpaymentreq.transactionSourceSystem =
                                    GlobalConstants.APPID;
                                _this.ccpaymentreq.cvv2 = formData.ccCvc;
                                // this.ccpaymentreq.browserType = 'IE';
                                _this.ccpaymentreq.amount = _this.selectedAmount;
                                _this.ccpaymentreq.symbol = _this.policyKey.symbol;
                                _this.ccpaymentreq.policy = _this.policyKey.policyNumber;
                                _this.ccpaymentreq.mod = _this.policyKey.module;
                                _this.ccpaymentreq.mco = _this.policyKey.masterCompany;
                                _this.ccpaymentreq.pco =
                                    _this.paymentDetailsRes.policyCompany;
                                _this.paymentService.ccPayment(_this.ccpaymentreq).subscribe(function (data) {
                                    _this.paymentres = data;
                                    if (_this.paymentres != null) {
                                        if (_this.paymentres.success) {
                                            _this.googleAnalytics.trackEvent("PayMyBill", "PaymentDetailsScreen", "PaymentSuccessfull", 200);
                                            // add the confirmation number and last 4 of CC to store
                                            // or use service to pass to payment success page
                                            // Tracker.log('Successful Payment:' + this.paymentres.confirmationNumber);
                                            if (_this.isNoLogin) {
                                                var paymentSummary = new Paymentsummary();
                                                _this.policyInfo = new PolicyInfo();
                                                _this.policyInfo.SYMBOL = _this.policyKey.symbol;
                                                _this.policyInfo.POLICY0NUM =
                                                    _this.policyKey.policyNumber;
                                                _this.policyInfo.MODULE = _this.policyKey.module;
                                                _this.paymentDetailsRes.policyCompany =
                                                    _this.policyInfo.PolicyCompany;
                                                var basicPolicyInfo = _this._storeService.getPolicyInfoFromStore();
                                                basicPolicyInfo.paymentSummary = paymentSummary;
                                                basicPolicyInfo.policyInfo = _this.policyInfo;
                                                _this._storeService.updatePolicyInfo(basicPolicyInfo);
                                            }
                                            _this.updatePaymentSummary(formData);
                                            _this.router.navigate(["payment-success"]);
                                        }
                                        else {
                                            // payment issue. Handle and display appropriate message
                                            // return to payment screen
                                            if (_this.paymentres.responseText !== null &&
                                                _this.paymentres.responseCode !== null) {
                                                Tracker.loginfo("PaymentComponent", "onSubmit", "", _this.paymentres.responseText);
                                                _this.paymentErrorMsg =
                                                    _this.paymentres.responseText;
                                                _this.isLoaded = true;
                                                _this.googleAnalytics.trackEvent("PaymentSubmission Error Messages", _this.paymentErrorMsg, "Proceed button click", 200);
                                            }
                                        }
                                    }
                                    else {
                                        // display canned message
                                        _this.paymentErrorMsg = "Error submitting payment";
                                        // Tracker.log(this.paymentErrorMsg);
                                        _this.isLoaded = true;
                                        _this.googleAnalytics.trackEvent("MobilePay Service Error", _this.paymentErrorMsg, "MethodofPayment", 200);
                                    }
                                }, function (error) {
                                    Tracker.logerror("PaymentComponent", "onSubmit", "this.paymentService.ccPayment", "Error occurred during cc payment submit.", error);
                                    _this.isLoaded = true;
                                });
                            }
                            else {
                                // ACH
                                _this.achpaymentreq = new Achpaymentreq();
                                _this.achpaymentreq.accountHolderFirstName = formData.fname;
                                _this.achpaymentreq.accountHolderLastName = formData.lname;
                                _this.achpaymentreq.address = formData.address;
                                _this.achpaymentreq.city = formData.city;
                                _this.achpaymentreq.postalCode = formData.zip;
                                _this.achpaymentreq.homePhoneNumber = "";
                                _this.achpaymentreq.state = formData.state;
                                _this.achpaymentreq.eftEnrollment =
                                    _this.paymentDetailsRes.eftIndicator;
                                _this.achpaymentreq.transactionSource =
                                    GlobalConstants.APPID;
                                _this.achpaymentreq.transactionSourceSystem =
                                    GlobalConstants.APPID;
                                _this.achpaymentreq.referenceCode = "12";
                                _this.achpaymentreq.abanumber = formData.routingNumber;
                                _this.achpaymentreq.accountNumber = formData.accountNumber;
                                _this.achpaymentreq.amount = _this.selectedAmount;
                                _this.achpaymentreq.mco = _this.policyKey.masterCompany;
                                _this.achpaymentreq.symbol = _this.policyKey.symbol;
                                _this.achpaymentreq.policyNumber =
                                    _this.policyKey.policyNumber;
                                _this.achpaymentreq.module = _this.policyKey.module;
                                _this.achpaymentreq.pco =
                                    _this.paymentDetailsRes.policyCompany;
                                //ACH Checking and Savings
                                if (_this.isNewACH) {
                                    _this.achpaymentreq.checkType = "CHQ";
                                }
                                else if (_this.isNewACHS) {
                                    _this.achpaymentreq.checkType = "SAV";
                                }
                                _this.paymentService
                                    .achPayment(_this.achpaymentreq)
                                    .subscribe(function (data) {
                                    _this.paymentres = data;
                                    if (_this.paymentres != null) {
                                        if (_this.paymentres.success) {
                                            // add the confirmation number and last 4 of CC to store
                                            // or use service to pass to payment success page
                                            if (_this.isNoLogin) {
                                                var paymentSummary = new Paymentsummary();
                                                _this.policyInfo = new PolicyInfo();
                                                _this.policyInfo.SYMBOL = _this.policyKey.symbol;
                                                _this.policyInfo.POLICY0NUM =
                                                    _this.policyKey.policyNumber;
                                                _this.policyInfo.MODULE = _this.policyKey.module;
                                                _this.policyInfo.PolicyCompany =
                                                    _this.paymentDetailsRes.policyCompany;
                                                var basicPolicyInfo = _this._storeService.getPolicyInfoFromStore();
                                                basicPolicyInfo.paymentSummary = paymentSummary;
                                                basicPolicyInfo.policyInfo = _this.policyInfo;
                                                _this._storeService.updatePolicyInfo(basicPolicyInfo);
                                            }
                                            _this.updatePaymentSummary(formData);
                                            _this.router.navigate(["payment-success"]);
                                        }
                                        else {
                                            // payment issue. Handle and display appropriate message
                                            // return to payment screen
                                            // Tracker.log('ERROR *****= ' + this.paymentres.responseText);
                                            _this.paymentErrorMsg =
                                                _this.paymentres.responseText;
                                            _this.isLoaded = true;
                                        }
                                    }
                                }, function (error) {
                                    Tracker.logerror("PaymentComponent", "onSubmit", "this.paymentService.achPayment", "Error occurred during ach payment submit.", error);
                                    _this.isLoaded = true;
                                });
                            }
                        }
                        else {
                            // Populate req obj for previous payment
                            _this.previouspaymentreq = new Previouspaymentreq();
                            var paymentOptionType = new PaymentOptionType();
                            // Paying with previous payment method
                            paymentOptionType.paymentMoniker =
                                _this.paymentDetailsRes.paymentMoniker;
                            paymentOptionType.paymentType =
                                _this.paymentDetailsRes.paymentType;
                            paymentOptionType.paymentVendor =
                                _this.paymentDetailsRes.paymentVendor;
                            _this.previouspaymentreq.paymentOption = paymentOptionType;
                            _this.previouspaymentreq.policyKey = _this.policyKey;
                            _this.previouspaymentreq.accountHolderFirstName =
                                formData.fname;
                            _this.previouspaymentreq.accountHolderLastName =
                                formData.lname;
                            _this.previouspaymentreq.address = formData.address;
                            _this.previouspaymentreq.city = formData.city;
                            _this.previouspaymentreq.postalCode = formData.zip;
                            _this.previouspaymentreq.state = formData.state;
                            _this.previouspaymentreq.homePhoneNumber =
                                _this.paymentDetailsRes.homePhoneNumber;
                            _this.previouspaymentreq.amount = _this.selectedAmount;
                            _this.previouspaymentreq.transactionSource =
                                GlobalConstants.APPID;
                            _this.previouspaymentreq.transactionSourceSystem =
                                GlobalConstants.APPID;
                            _this.paymentService
                                .previousPayment(_this.previouspaymentreq)
                                .subscribe(function (data) {
                                _this.paymentres = data;
                                if (_this.paymentres != null) {
                                    if (_this.paymentres.success) {
                                        // add the confirmation number and last 4 of CC to store
                                        // or use service to pass to payment success page
                                        if (_this.isNoLogin) {
                                            var paymentSummary = new Paymentsummary();
                                            _this.policyInfo = new PolicyInfo();
                                            _this.policyInfo.SYMBOL = _this.policyKey.symbol;
                                            _this.policyInfo.POLICY0NUM =
                                                _this.policyKey.policyNumber;
                                            _this.policyInfo.MODULE = _this.policyKey.module;
                                            _this.basicPolicyDetails.paymentSummary =
                                                paymentSummary;
                                            _this.basicPolicyDetails.policyInfo =
                                                _this.policyInfo;
                                            _this._storeService.updatePolicyInfo(_this.basicPolicyDetails);
                                        }
                                        // Set payment response data in the store
                                        _this.updatePaymentSummary(formData);
                                        _this.router.navigate(["payment-success"]);
                                    }
                                    else {
                                        // payment issue. Handle and display appropriate message
                                        // return to payment screen
                                        // Tracker.log('ERROR *****= ' + this.paymentres.responseText);
                                        /*
                                   if (this.basicPolicyDetails.agentInfo.AgentType === GlobalConstants.EA_AGENT) {
                                     this.paymentErrorMsg = MessageConstants.EA_PAYMENT_ERROR;
                                   } else {
                                     this.paymentErrorMsg = MessageConstants.IA_PAYMENT_ERROR;
                                   }
                                   */
                                        if (_this.paymentres &&
                                            _this.paymentres.responseCode !== undefined) {
                                            _this.paymentErrorMsg =
                                                _this.paymentres.responseText;
                                        }
                                        _this.sendToTop();
                                        _this.isLoaded = true;
                                    }
                                }
                            }, function (error) {
                                Tracker.logerror("PaymentComponent", "onSubmit", "this.paymentService.previousPayment", "Error occurred during previous payment submit.", error);
                            });
                        }
                    }, function (reason) {
                        _this.isLoaded = true;
                        return;
                    })
                        .catch(function (error) {
                        _this.isLoaded = true;
                        Tracker.logerror("PaymentComponent", "onSubmit", "this.modalService.open", "Error occurred with payment modal.", error);
                    });
                }
                else {
                    this.sendToTop();
                    this.isLoaded = true;
                }
            }
        }
        catch (error) {
            Tracker.logerror("PaymentComponent", "onSubmit", "main call", "Error occurred.", error);
            this.isLoaded = true;
        }
        finally {
            // Clean up needed?
        }
    };
    PaymentComponent.prototype.formatCC = function (ccNum) {
        if (ccNum !== null) {
            var re = /\ /gi;
            return ccNum.replace(re, "");
        }
    };
    PaymentComponent.prototype.updatePaymentSummary = function (formData) {
        var paymentSummary = new Paymentsummary();
        paymentSummary.amount = this.selectedAmount;
        paymentSummary.firstName = formData.fname;
        paymentSummary.lastName = formData.lname;
        paymentSummary.cardLastFour = this.newMoniker;
        paymentSummary.confirmation = this.paymentres.confirmationNumber;
        if (this.isCreditCard) {
            paymentSummary.isCreditCard = true;
        }
        else {
            paymentSummary.isCreditCard = false;
        }
        this.basicPolicyDetails.paymentSummary = paymentSummary;
        this._storeService.updatePolicyInfo(this.basicPolicyDetails);
    };
    PaymentComponent.prototype.updatePaymentSummaryNew = function (formData, data) {
        var paymentSummary = new Paymentsummary();
        paymentSummary.amount = this.selectedPayment.amount;
        paymentSummary.firstName = formData.fname;
        paymentSummary.lastName = formData.lname;
        paymentSummary.cardLastFour = this.newMoniker;
        paymentSummary.confirmation = data.payment.referenceNumber;
        if (this.isCreditCard) {
            paymentSummary.isCreditCard = true;
        }
        else {
            paymentSummary.isCreditCard = false;
        }
        this.basicPolicyDetails.paymentSummary = paymentSummary;
        this._storeService.updatePolicyInfo(this.basicPolicyDetails);
    };
    PaymentComponent.prototype.createPaymentForm = function () {
        this.paymentForm = this.fb.group({
            amount: [
                this.paymentDetailsRes.minimumPayment,
                Validators.compose([Validators.required]),
            ],
            otherAmount: [
                "",
                Validators.compose([Validators.pattern("^\\d+\\.\\d{2}$")]),
            ],
            selectedPaymentType: [
                this.paymentMoniker,
                Validators.compose([
                    Validators.required,
                    this.selectPaymentTypeValidator,
                ]),
            ],
            fname: [
                this.paymentDetailsRes.firstName,
                Validators.compose([Validators.required]),
            ],
            lname: [
                this.paymentDetailsRes.lastName,
                Validators.compose([Validators.required]),
            ],
            address: [
                this.paymentDetailsRes.address,
                Validators.compose([Validators.required]),
            ],
            city: [
                this.paymentDetailsRes.city,
                Validators.compose([Validators.required]),
            ],
            state: [
                this.paymentDetailsRes.state,
                Validators.compose([Validators.required]),
            ],
            zip: [
                this.paymentDetailsRes.zip,
                Validators.compose([Validators.required, Validators.maxLength(5)]),
            ],
            routingNumber: [""],
            accountNumber: [""],
            type: [""],
            ccNumber: [""],
            ccExp: [""],
            ccCvc: [""],
        });
    };
    // Custom validator to check the payment mentod type default value
    PaymentComponent.prototype.selectPaymentTypeValidator = function (control) {
        if (control.value === "Please select a payment method") {
            return { required: true };
        }
        return null;
    };
    // check the first two entered digit of the cc and set the appropriate svg
    PaymentComponent.prototype.onKey = function (event) {
        // Tracker.log('Event Value: ' +  '\'' + event.target.value + '\'');
        // Default
        this.cvcLength = "3";
        this.ccLength = "19";
        if (/^5[1-5]/.test(event.target.value)) {
            this.selectedCard = "mastercard";
        }
        else if (/^4/.test(event.target.value)) {
            // then check for Visa
            this.selectedCard = "visa";
        }
        else if (/^3[47]/.test(event.target.value)) {
            // then check for Amex
            this.selectedCard = "amex";
            this.cvcLength = "4";
            this.ccLength = "17";
        }
        else if (/^6(?:011|5)/.test(event.target.value)) {
            // then check for Discover
            this.selectedCard = "discover";
        }
        else {
            if (event.target.value.length === 0) {
                this.selectedCard = "none";
            }
        }
    };
    PaymentComponent.prototype.onKeyPress = function (event) {
        // Default
        this.selectedAmount = event.target.value;
    };
    PaymentComponent.prototype.showOtherAmount = function (formData) {
        formData.otherAmount = "";
        if (formData.amount === "other") {
            this.isOtherAmount = true;
            this.selectedAmount = formData.otherAmount;
        }
        else {
            this.isOtherAmount = false;
            this.selectedAmount = formData.amount;
        }
    };
    PaymentComponent.prototype.showNewPayment = function (formData) {
        this.isCreditCard = false;
        if (formData.selectedPaymentType === "cc") {
            this.isNew = true;
            this.isNewCC = true;
            this.isNewACH = false;
            this.isNewACHS = false;
            this.isCreditCard = true;
            // need to toggle which controls are required
            this.updateControlValidation(this.paymentForm.controls.ccNumber, true);
            this.updateControlValidation(this.paymentForm.controls.ccExp, true);
            this.updateControlValidation(this.paymentForm.controls.ccCvc, true);
            this.updateControlValidation(this.paymentForm.controls.routingNumber, false);
            this.updateControlValidation(this.paymentForm.controls.accountNumber, false);
        }
        else if (formData.selectedPaymentType === "ach") {
            this.isNew = true;
            this.isNewCC = false;
            this.isNewACH = true;
            this.isNewACHS = false;
            this.isCreditCard = false;
            this.updateControlValidation(this.paymentForm.controls.routingNumber, true);
            this.updateControlValidation(this.paymentForm.controls.accountNumber, true);
            this.updateControlValidation(this.paymentForm.controls.ccNumber, false);
            this.updateControlValidation(this.paymentForm.controls.ccExp, false);
            this.updateControlValidation(this.paymentForm.controls.ccCvc, false);
        }
        else if (formData.selectedPaymentType === "achs") {
            this.isNew = true;
            this.isNewCC = false;
            this.isNewACH = false;
            this.isNewACHS = true;
            this.isCreditCard = false;
            this.updateControlValidation(this.paymentForm.controls.routingNumber, true);
            this.updateControlValidation(this.paymentForm.controls.accountNumber, true);
            this.updateControlValidation(this.paymentForm.controls.ccNumber, false);
            this.updateControlValidation(this.paymentForm.controls.ccExp, false);
            this.updateControlValidation(this.paymentForm.controls.ccCvc, false);
        }
        else {
            // Previous payment
            if (this.paymentDetailsRes.paymentType === "Credit Card") {
                this.isCreditCard = true;
            }
            else {
                this.isCreditCard = false;
            }
            this.newMoniker = this.paymentMoniker;
            this.isNew = false;
            this.isNewCC = false;
            this.isNewACH = false;
            this.isNewACHS = false;
            this.updateControlValidation(this.paymentForm.controls.routingNumber, false);
            this.updateControlValidation(this.paymentForm.controls.accountNumber, false);
            this.updateControlValidation(this.paymentForm.controls.ccNumber, false);
            this.updateControlValidation(this.paymentForm.controls.ccExp, false);
            this.updateControlValidation(this.paymentForm.controls.ccCvc, false);
        }
    };
    PaymentComponent.prototype.updateControlValidation = function (fControl, isRequired) {
        if (isRequired) {
            fControl.setValidators([Validators.compose([Validators.required])]);
        }
        else {
            fControl.setValidators([]);
        }
        fControl.updateValueAndValidity();
    };
    // Form validations
    PaymentComponent.prototype.isFieldValid = function (field) {
        return ((!this.paymentForm.get(field).valid &&
            this.paymentForm.get(field).touched) ||
            (this.paymentForm.get(field).untouched && this.formSubmitAttempt));
    };
    PaymentComponent.prototype.getErrMessage = function (errors, formCntrlName) {
        return this._messageService.getErrorMessages(errors, formCntrlName);
    };
    PaymentComponent.prototype.populateBankDetails = function (event) {
        var _this = this;
        var routingnumber = event.target.value;
        if (routingnumber) {
            // this.loadingBankDetails = true;
            this.eftService.bankDetails(routingnumber).subscribe(function (res) {
                if (+res.responseCode === 0) {
                    _this.bankName = res.bankName.trim();
                    _this.bankError = "";
                }
                else {
                    _this.bankName = "";
                    _this.bankError = res.responseText;
                }
            }, function (error) {
                Tracker.logerror("PaymentComponent", "populateBankDetails", "this.eftService.bankDetails", "Error occurred validating Bank Details.", error);
            });
        }
        else {
            this.bankName = "";
            this.bankError = "";
        }
    };
    PaymentComponent.prototype.clearBankDetails = function (event) {
        var value = event.target.value;
        if (value === "") {
            this.bankName = "";
            this.bankError = "";
        }
    };
    PaymentComponent.prototype.isValidAmount = function (paymentAmount) {
        // Check if the entered ammount is greater than the outstanding balance
        // or less than the minimum payment
        var otherAmountValue = "";
        var minimumPaymentAmount = "";
        var policyBalanceValue = "";
        var isValid = false;
        this.paymentErrorMsg = "";
        if (paymentAmount !== "" && paymentAmount !== undefined) {
            if (paymentAmount.indexOf(".") > -1) {
                // Has Decimal
                otherAmountValue = paymentAmount.replace(".", "");
                minimumPaymentAmount = this.paymentDetailsRes.minimumPayment.replace(".", "");
                policyBalanceValue = this.paymentDetailsRes.maximumPayment.replace(".", "");
            }
            else {
                // No Decimal
                otherAmountValue = paymentAmount;
                minimumPaymentAmount = this.paymentDetailsRes.minimumPayment.substring(0, this.paymentDetailsRes.minimumPayment.indexOf("."));
                policyBalanceValue = this.paymentDetailsRes.maximumPayment.substring(0, this.paymentDetailsRes.maximumPayment.indexOf("."));
            }
            if (+otherAmountValue > +policyBalanceValue) {
                // return message
                this.paymentErrorMsg =
                    "Other Amount can not be greater then the remaining balance.";
            }
            else if (+otherAmountValue < +minimumPaymentAmount) {
                // return message
                this.paymentErrorMsg =
                    "Other Amount can not be less then the minimum amount due.";
            }
            else {
                isValid = true;
            }
        }
        return isValid;
    };
    PaymentComponent.prototype.formatAmount = function (formData) {
        if (formData) {
            if (formData.otherAmount.indexOf(".")) {
                formData.otherAmount = formData.otherAmount.concat(".00");
                this.updateControlValidation(this.paymentForm.controls.otherAmount, false);
            }
        }
    };
    PaymentComponent.prototype.sendToTop = function () {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };
    PaymentComponent.prototype.selectedPaymentItem = function (data) {
        this.selectedPayment = data;
    };
    PaymentComponent.prototype.isDisable = function () {
        return this.selectedPayment.amount && this.selectedPayment.amount !== ""
            ? false
            : true;
    };
    PaymentComponent.prototype.getSelectedPaymentMethod = function (selectedPaymentMethod) {
        this.selectedPaymentMethodNew = selectedPaymentMethod;
        //console.log(this.selectedPaymentMethodNew);
        if (this.selectedPayment && this.selectedPayment.validated) {
            this.ref.detectChanges();
            document.getElementById("finalButton").focus();
            //document.getElementById("finalButton1").focus();
        }
        else {
            this.ref.detectChanges();
            document.getElementById("finalButton").focus();
        }
    };
    // enablePayment():boolean{
    //   return (this.basicPolicyDetails.policyInfo.PolicyStatusEPC!=='Cancelled' && this.basicPolicyDetails.policyInfo.PolicyStatusEPC!='Expired'  ) && this.selectedPayment.amount!=='' && this.selectedPaymentMethodNew && this.selectedPaymentMethodNew.method.paymentToken;
    // }
    // disablePayment():boolean{
    //   return this.selectedPayment.amount==='' || !this.selectedPaymentMethodNew || !this.selectedPaymentMethodNew.method.paymentToken || this.olicyInfo.PolicyStatusEPC==='Cancelled' || this.policyInfo.PolicyStatusEPC==='Expired';
    // }
    PaymentComponent.prototype.editPaymentMethod = function (event) {
        if (event) {
            //console.log(event);
            if (event.selected == true) {
                this.selectedRadioButton = true;
            }
            else {
                this.selectedRadioButton = false;
            }
            //console.log(this.updatePaymentToken)
            this.updatePaymentToken = event.paymentToken;
            //console.log(event.paymentToken);
            this.addEditPaymentMethod(event.paymentusIframePaymentType, event.paymentToken, event.mode);
        }
    };
    PaymentComponent.prototype.addNewPaymentMethod = function () {
        var _this = this;
        this.newPaymentMethodSubscription = this.paymentusEventNewPaymentMethod$
            .pipe(filter(function (event) {
            var eventData = JSON.stringify(event.data);
            return eventData.includes(_this.PM_DETAILS);
        }))
            .subscribe(function (event) {
            try {
                var pmDetailsObject = _this.paymentService.parsePMDetailsMessageFromIframe(event.data);
                var paymentMethodForm = _this.paymentService.buildPaymentMethodInfo(pmDetailsObject);
                _this.paymentMethods =
                    _this.paymentService.resetPaymentMethodsIsDefaultAndAddNew(_this.paymentMethods, paymentMethodForm);
                _this.paymentMethods.forEach(function (paymentMethod) {
                    paymentMethod.isUpdated = paymentMethod.paymentToken === _this.updatePaymentToken;
                });
            }
            catch (e) {
                // show error or alert?
            }
        });
    };
    PaymentComponent.prototype.routeToMyPolicy = function () {
        this.router.navigate(["policy"]);
    };
    // isReinstatementAvailable():boolean{
    //   return this.policyInfo.PolicyStatusEPC === GlobalConstants.status.reinstatementAvailable;
    // }
    PaymentComponent.prototype.isReinstatementAvailable = function () {
        if ((this.paymentAmountDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC === GlobalConstants.status.reinstatementAvailable &&
            this.authenticatedPhDetails.reInstateDueamount && this.authenticatedPhDetails.reInstateDueamount !== '0' && this.authenticatedPhDetails.reInstateDueamount !== '0.00')
            || this.authenticatedPhDetails.isReinstate) {
            //this.paymentAmountDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC = GlobalConstants.status.reinstatementAvailable;
            return this.getStatus();
        }
        else if (this.paymentAmountDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC === GlobalConstants.status.reinstatementAvailable &&
            (!this.authenticatedPhDetails.reInstateDueamount || this.authenticatedPhDetails.reInstateDueamount === '0'
                || this.authenticatedPhDetails.reInstateDueamount === '0.00')) {
            this.paymentAmountDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC = GlobalConstants.status.cancelled;
            return false;
        }
        else {
            return false;
        }
        //return this.policyInfo.PolicyStatusEPC=== GlobalConstants.status.reinstatementAvailable || this.authenticatedPhDetails.isReinstate;
    };
    PaymentComponent.prototype.getStatus = function () {
        if (!(this.authenticatedPhDetails.reInstateDueamount && this.authenticatedPhDetails.reInstateDueamount !== '0' && this.authenticatedPhDetails.reInstateDueamount !== '0.00')) {
            this.policyInfo.PolicyStatusEPC = GlobalConstants.status.cancelled;
            return false;
        }
        this.policyInfo.PolicyStatusEPC = GlobalConstants.status.reinstatementAvailable;
        return true;
    };
    PaymentComponent.prototype.showMakePayment = function () {
        if (this.isReinstatementAvailable()) {
            //console.log("1")
            if ((this.authenticatedPhDetails.reInstateDueamount && this.authenticatedPhDetails.reInstateDueamount !== '0' && this.authenticatedPhDetails.reInstateDueamount !== '0.00') ||
                this.policyInfo.Balance && this.policyInfo.Balance !== '0' && this.policyInfo.Balance !== '0.00' && !this.policyInfo.Balance.includes('-')) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            //console.log("2")
            if ((this.basicPolicyDetails.paymentInfo.minimumPayment && this.basicPolicyDetails.paymentInfo.minimumPayment !== '0' && this.basicPolicyDetails.paymentInfo.minimumPayment !== '0.00') ||
                (this.basicPolicyDetails.paymentInfo.maximumPayment && this.basicPolicyDetails.paymentInfo.maximumPayment !== '0' && this.basicPolicyDetails.paymentInfo.maximumPayment !== '0.00')) {
                return true;
            }
            else {
                return false;
            }
        }
    };
    return PaymentComponent;
}());
export { PaymentComponent };
