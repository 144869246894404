import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalConstants } from '../../../constants/global.constant';
import { DocumentsService } from '../../../services/api/documents/documents.service';
import { GoogleAnalyticsService } from '../../services/analytics/google-analytics.service';
import { Idcardreq } from '../../model/api/document/idcardreq';
import { Policynumber } from '../../model/policynumber';
import { BwstoreService } from '../../services/bwstore.service';
import { CommonUtil } from '../../utils/commonutil';
import { Tracker } from '../../utils/tracker';
import { EndorseMetainfo } from '../../model/endorsements/endorse-metainfo.model';
var IdcardComponent = /** @class */ (function () {
    function IdcardComponent(idCardModal, documentService, _storeService, googleAnalytics) {
        this.idCardModal = idCardModal;
        this.documentService = documentService;
        this._storeService = _storeService;
        this.googleAnalytics = googleAnalytics;
        this.allowClose = false;
    }
    IdcardComponent.prototype.ngOnInit = function () {
        // test it
        this.idCardMessage = 'Retrieving your ID Card...';
        // endorsement info is usefull to invoke processEndorsementIDCard or ProcessIDCard based on user input
        this.endorsementInfo = this._storeService.getEndorsementInfoFromStore();
        this.viewIDCard();
    };
    IdcardComponent.prototype.navigate = function () {
        this.idCardModal.close();
    };
    IdcardComponent.prototype.dismiss = function () {
        this.idCardModal.dismiss();
    };
    IdcardComponent.prototype.viewIDCard = function () {
        var _this = this;
        this.policyNumber = new Policynumber(this._storeService.getPolicyInfoFromStore().policyInfo.PolicyNumber);
        var state = this._storeService.getLoginInfoFromStore().riskstate;
        if (state !== 'NJ' && state !== 'NY' && state !== 'MA') {
            this.idcardreq = new Idcardreq();
            this.idcardreq.policyID = this.policyNumber.toString();
            this.idcardreq.mco = this._storeService.getPolicyInfoFromStore().policyInfo.MASTER0CO;
            this.idcardreq.futureTerm = 'N'; // TODO: what sets this
            this.idcardreq.appName = GlobalConstants.APPID;
            this.idcardreq.deliveryMode = 'Email';
            if (this.endorsementInfo && this.endorsementInfo.endorseBound === 'true') {
                this.idcardreq.isNewCard = true;
            }
            else {
                this.idcardreq.isNewCard = false;
            }
            this.documentService.retrieveIDCard(this.idcardreq).subscribe(function (iddata) {
                _this.idcardres = iddata;
                _this.resetEndoseBondValue();
                if (!CommonUtil.isEmpty(_this.idcardres) && _this.idcardres.idCard.length > 0) {
                    // download to browser
                    _this.downloadIdCard(_this.idcardres.idCard);
                    _this.idCardMessage = 'Your ID Card has been downloaded successfully.';
                    _this.allowClose = true;
                }
                else {
                    _this.idCardMessage = 'We are unable to retrieve your ID Card at this time. Please try again.';
                    _this.allowClose = true;
                }
            }, function (error) {
                _this.idCardMessage = 'We are unable to retrieve your ID Card at this time. Please try again.';
                _this.allowClose = true;
                _this.resetEndoseBondValue();
                Tracker.logerror('IdcardComponent', 'viewIDCard', 'documentService.retrieveIDCard', 'Error occurred getting ID Card for ' +
                    _this.policyNumber.toFormattedString(), error);
            });
        }
        else {
            // NY or NJ or MA so don't display ID Card. Show message
            this.allowClose = true;
            this.idCardMessage = 'Please contact your agent for assistance with your ID Card.';
        }
    };
    IdcardComponent.prototype.downloadIdCard = function (pdf) {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(CommonUtil.base64toBlob(pdf, 'application/pdf'));
            return;
        }
        // All other browsers
        var linkSource = "data:application/pdf;base64," + pdf;
        var downloadLink = document.createElement('a');
        var fileName = 'IDCard.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        this.googleAnalytics.trackEvent('IDCard', 'HomeScreen', 'IDCardGenerated', 200);
        downloadLink.click();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            document.body.removeChild(downloadLink);
            // window.URL.revokeObjectURL(data);
        });
    };
    IdcardComponent.prototype.resetEndoseBondValue = function () {
        // condtion that resets the endose bond in order to get is card from my policy page
        if (this.endorsementInfo && this.endorsementInfo.endorseType === 'address' && this.endorsementInfo.endorseBound === 'true') {
            this._storeService.deleteBwEndorsementInfo(new EndorseMetainfo());
        }
    };
    return IdcardComponent;
}());
export { IdcardComponent };
