import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SecurityqnsService = /** @class */ (function () {
    function SecurityqnsService(_http) {
        this._http = _http;
        this.securityQsnsPath = 'assets/data/security-qsns.json';
    }
    SecurityqnsService.prototype.getSecurityQns = function () {
        return (this._http.get(this.securityQsnsPath));
    };
    SecurityqnsService.ngInjectableDef = i0.defineInjectable({ factory: function SecurityqnsService_Factory() { return new SecurityqnsService(i0.inject(i1.HttpClient)); }, token: SecurityqnsService, providedIn: "root" });
    return SecurityqnsService;
}());
export { SecurityqnsService };
