import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal , NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-changes',
  template: `
  <div class="modal-header" >
    <h4 class="modal-title" id="request-to-cancel-confirmation">Confirmation</h4>
    <button type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button> -->
  </div>
  <div class="modal-body">
    <p>Confirm these changes</p>
  </div>

<div class="modal-footer mt-1">
<button type="button" class="btn btn-secondary" (click)="confirmChanges('cancel')">Cancel</button>
  <button type="submit" class="btn btn-primary" (click)="confirmChanges('accept')">Accept</button>
  

</div>
  `,
  styles: []
})
export class ConfirmChangesComponent implements OnInit {

  @Output() emitService = new EventEmitter();

  constructor(public confirmChangesModal: NgbActiveModal) { }

  ngOnInit() {
  }

  confirmChanges(decision: string) {
    if (decision && decision === 'accept') {
      this.emitService.emit({status: 'Accepted'});
    } else {
      this.emitService.emit({status: 'Cancelled'});
    }

     this.confirmChangesModal.close();

  }
}
