
  <div class="modal-header" id="headerAdd">
    <h4 class="modal-title heading-2 mb-1">{{headerMsg}}</h4>
  </div>

  <app-app-errors></app-app-errors>

  <div [ngSwitch]="currentStep">
    <div *ngSwitchCase="'STEP1'">
      <app-mailingaddr-change [addressEditModal]="addressEditModal" (updatedMailingAddress)="getMailingAddress($event)"></app-mailingaddr-change>
    </div>
    <div *ngSwitchCase="'STEP2'">
      <app-mailingaddr-accept [mailingAddress]="mailingAddress" [addressEditModal]="addressEditModal"  (updateFuturePayments)="getfuturePayments($event)" (updateStatus)="getUpdateStatus($event)"></app-mailingaddr-accept>
    </div>
    <div *ngSwitchCase="'STEP3'">
      <app-mailingaddr-confirmation [mailingAddress]="mailingAddress"  [futurePayments]="futurePayments" [addressEditModal]="addressEditModal"></app-mailingaddr-confirmation>
    </div>
  </div>
