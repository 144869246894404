import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-banner',
  template: `
  <main id="mypolicycomponent" class="submain" *ngIf="closeBanner">
  <div class="container-fluid">
  <div class="mobile-app-banner row">
    <div class="pl-4 banner col-10 pr-0" (click)="FindMobileversion()">
      <img class="logo" src="assets/img/favicons/banner img.jpg" alt="Mobile Banner" />
      <span> View in the Bristol West app</span>
    </div>
    <div class="banner-close col-2 pl-0 pr-3">
      <span class="close-icon" (click)="closeBanner = !closeBanner"><i class="fa fa-times" aria-hidden="true"></i></span>
    </div>
  </div>
  </div>
  </main>
  `,
  styleUrls: ['./mobile-app-banner.component.scss']
})
export class MobileAppBannerComponent implements OnInit {
  closeBanner = true;
  constructor() { }

  ngOnInit() {
  }

  FindMobileversion() {
    if (typeof navigator !== 'undefined') {
      if (!!navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent)) {
        window.open('https://apps.apple.com/us/app/bristol-west/id1473647523', '_blank');
      } else {// if (!!navigator.userAgent && /(android)/i.test(navigator.userAgent)) {
        window.open('https://play.google.com/store/apps/details?id=com.bristolwest.app', '_blank');
      }
    }
  }
}
