var FullPolicyDetails = /** @class */ (function () {
    function FullPolicyDetails(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return FullPolicyDetails;
}());
export { FullPolicyDetails };
var PaymentInfo = /** @class */ (function () {
    function PaymentInfo(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return PaymentInfo;
}());
export { PaymentInfo };
var Paymentsummary = /** @class */ (function () {
    function Paymentsummary(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return Paymentsummary;
}());
export { Paymentsummary };
var PaymentHistoryInfo = /** @class */ (function () {
    function PaymentHistoryInfo(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return PaymentHistoryInfo;
}());
export { PaymentHistoryInfo };
var EFTInfo = /** @class */ (function () {
    function EFTInfo(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return EFTInfo;
}());
export { EFTInfo };
var PolicyInfo = /** @class */ (function () {
    function PolicyInfo(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return PolicyInfo;
}());
export { PolicyInfo };
var AgentInfo = /** @class */ (function () {
    function AgentInfo(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return AgentInfo;
}());
export { AgentInfo };
