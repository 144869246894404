import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerRegistrationService } from '../../../services/registration/customer-registration.service';
import { CommonUtil } from '../../utils/commonutil';
var BwLandingComponent = /** @class */ (function () {
    function BwLandingComponent(route, router, custRegService) {
        this.route = route;
        this.router = router;
        this.custRegService = custRegService;
    }
    BwLandingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            // Tracker.log('Params from externa service::', params);
            if (params) {
                var src = params.src;
                _this.landingsrc = params.src;
                _this.policynumber = params.PolicyNum;
                _this.mco = params.MCO;
                _this.lastname = params.LastName;
                _this.custRegService.checkRegistrationStatus(_this.mco, _this.policynumber).then(function (regstatus) {
                    /*if (regstatus) {
                      this.message = CommonUtil.getLandingMessage(regstatus.checkRegistrationStatusResult, src);
                      this.continueAction = CommonUtil.getContinueAction(regstatus.checkRegistrationStatusResult);
                      // console.error('I have to take the user to ', this.continueAction, ' & Message:: ', this.message);
                    } else {
                      this.message = '';
                      this.continueAction = 'LOGIN';
                    }*/
                    var queryParams = {};
                    if (regstatus && !regstatus.checkRegistrationStatusResult) {
                        _this.continueAction = CommonUtil.getContinueAction(regstatus.checkRegistrationStatusResult);
                        if (_this.continueAction === 'REGISTER' && _this.landingsrc === 'WELCOME') {
                            queryParams = { policyNumber: _this.policynumber, LastName: _this.lastname, mco: _this.mco };
                        }
                        else {
                            queryParams = { policyNumber: _this.policynumber, mco: _this.mco };
                        }
                        _this.router.navigate(['/register'], { queryParams: queryParams });
                    }
                    else {
                        queryParams = { src: _this.landingsrc };
                        _this.router.navigate(['/'], { queryParams: queryParams });
                    }
                });
            }
        });
    };
    return BwLandingComponent;
}());
export { BwLandingComponent };
