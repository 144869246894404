import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-esiglanding',
  templateUrl: './esiglanding.component.html',
  styleUrls: ['./esiglanding.component.scss']
})
export class EsiglandingComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      // Tracker.log('Params from externa service::', params);
      if (params) {
        // Call a service to verify if the user is registered or not. If registered take them to Login screen
        // Else take them to Registration screen and populate the values
      }
    });
  }

}
