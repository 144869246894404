
  <div class="modal-header">
    <h4 class="modal-title">Unenroll From Go Paperless</h4>
    <button type="button" class="close" aria-label="Close" (click)="errorModal.dismiss('Cross click')">
  <span aria-hidden="true">&times;</span></button>
</div>
<form class="my-4">
<div class="d-flex justify-content-center border-0">

  <div *ngIf="this.returnUnenrollMsg !== '' && this.returnUnenrollMsg ==='success'"
  class="alert alert-success alert-dismissible fade show ng-star-inserted">
  You are no longer enrolled in Go Paperless. </div>
  <app-app-errors *ngIf="this.returnUnenrollMsg !== '' &&
  this.returnUnenrollMsg ==='failure'"></app-app-errors>

</div>
<div class="modal-body">
<div class="form-row">
  <div class="col">
    <div class="form-group">
      {{this.unenrollMsg}}
      </div>
  </div>
</div>
</div>
<div class="modal-footer">
  <button class="btn btn-secondary" (click)="errorModal.dismiss('Close click')">
    Cancel
  </button>
  <div *ngIf="returnUnenrollMsg === '' || (this.returnUnenrollMsg !== '' && this.returnUnenrollMsg ==='failure')">
    <button *ngIf="loaded" type="submit" class="btn btn-primary" (click)='unenrollGP()' data-dismiss="modal">Unenroll</button>
      <button *ngIf="!loaded" class="btn btn-primary" disabled="true"><i class="fas fa-circle-notch fa-spin">
      </i>&nbsp;Unenroll</button>
  </div>
</div>
</form>
  