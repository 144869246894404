import { formatDate } from '@angular/common';
import { Subject } from 'rxjs';
import { GlobalConstants } from '../../../constants/global.constant';
import { AutoPayCancelResponse, AutoPayDetailsResponse, AutoPayEnrollResponse } from '../../../shared/model/api/autoeft/autopayenrollment.model';
import { PolicyEftRequest } from '../../../shared/model/api/policyeft/policyeftreq.model';
import { Policynumber } from '../../../shared/model/policynumber';
import { BwappService } from '../../bwapp.service';
import { BWErrorhandler } from '../../errorhandler';
import * as i0 from "@angular/core";
import * as i1 from "../../bwapp.service";
import * as i2 from "../../errorhandler";
var PolicyeftService = /** @class */ (function () {
    function PolicyeftService(appService, errorHandler) {
        this.appService = appService;
        this.errorHandler = errorHandler;
        this.globlalConstants = GlobalConstants;
        this.paymentMethod = new Subject();
    }
    PolicyeftService.prototype.setPaymentMethod = function (paymentMehtod) {
        this.paymentMethodSelected = paymentMehtod;
    };
    PolicyeftService.prototype.getPaymentMethod = function () {
        return this.paymentMethodSelected;
    };
    /**
      * @param policyNumber
      * @param mco
      */
    PolicyeftService.prototype.getEFTStatus = function (policynumber, mco) {
        // Tracker.log('Policy Number passed :', policynumber, mco);
        this.policyNumber = new Policynumber(policynumber);
        this.policyEftReq = new PolicyEftRequest();
        this.policyEftReq.policySym = this.policyNumber.symbol;
        this.policyEftReq.policyNum = this.policyNumber.policy;
        this.policyEftReq.policyMod = this.policyNumber.module;
        this.policyEftReq.mco = mco;
        return this.appService.post(this.globlalConstants.EFT_GET_STATUS, this.policyEftReq);
    };
    /**
     * @param policyNumber
     * @param mco
     */
    PolicyeftService.prototype.eftIsEligible = function (policynumber, mco, plcyeffdate, eftType) {
        this.policyNumber = new Policynumber(policynumber);
        this.policyEftReq = new PolicyEftRequest();
        this.policyEftReq.policySym = this.policyNumber.symbol;
        this.policyEftReq.policyNum = this.policyNumber.policy;
        this.policyEftReq.policyMod = this.policyNumber.module;
        this.policyEftReq.mco = mco;
        this.policyEftReq.eftEffDate = plcyeffdate;
        this.policyEftReq.polEffectiveDate = plcyeffdate;
        this.policyEftReq.eftType = eftType;
        return this.appService.post(this.globlalConstants.EFT_IS_ELIGIBLE, this.policyEftReq);
    };
    /**
      * @param policyNumber
      * @param mco
      */
    PolicyeftService.prototype.prepareEFT = function (fullpolicyInfo) {
        var policyInfo = fullpolicyInfo.policyInfo;
        var eftInfo = fullpolicyInfo.eftInfo;
        var policyEffDate = formatDate(policyInfo.PolicyEffectiveDate, 'MM/dd/yyyy', 'en-US');
        this.policyNumber = new Policynumber(policyInfo.PolicyNumber);
        this.policyEftReq = new PolicyEftRequest();
        this.policyEftReq.policySym = this.policyNumber.symbol;
        this.policyEftReq.policyNum = this.policyNumber.policy;
        this.policyEftReq.policyMod = this.policyNumber.module;
        this.policyEftReq.mco = policyInfo.MASTER0CO;
        this.policyEftReq.eftEffDate = policyEffDate;
        this.policyEftReq.polEffectiveDate = policyEffDate;
        if (eftInfo) {
            this.policyEftReq.eftType = eftInfo.eftType;
            this.policyEftReq.accountType = eftInfo.accountType;
            this.policyEftReq.routingNumber = eftInfo.routingNumber;
            this.policyEftReq.accountNumber = eftInfo.accountNum;
        }
        this.policyEftReq.source = GlobalConstants.APPID;
        this.policyEftReq.termReason = '';
        this.policyEftReq.pspAZCorp = '';
        this.policyEftReq.eftProcessedAs = '';
        this.policyEftReq.eftProcessedVendor = '';
        this.policyEftReq.eMove = false;
        // Tracker.log('Prepare EFT Request before passing to backend service ', this.policyEftReq);
        return this.appService.post(this.globlalConstants.EFT_PREPARE, this.policyEftReq);
    };
    /**
      * @param policyNumber
      * @param mco
      */
    PolicyeftService.prototype.voidEFT = function (fullpolicyInfo) {
        var policyInfo = fullpolicyInfo.policyInfo;
        var eftInfo = fullpolicyInfo.eftInfo;
        var policyEffDate = formatDate(policyInfo.PolicyEffectiveDate, 'MM/dd/yyyy', 'en-US');
        this.policyNumber = new Policynumber(policyInfo.PolicyNumber);
        this.policyEftReq = new PolicyEftRequest();
        this.policyEftReq.policySym = this.policyNumber.symbol;
        this.policyEftReq.policyNum = this.policyNumber.policy;
        this.policyEftReq.policyMod = this.policyNumber.module;
        this.policyEftReq.mco = policyInfo.MASTER0CO;
        this.policyEftReq.eftEffDate = policyEffDate;
        this.policyEftReq.polEffectiveDate = policyEffDate;
        this.policyEftReq.eftType = eftInfo.eftType;
        // this.policyEftReq.accountType =  eftInfo.accountType;
        // this.policyEftReq.routingNumber = eftInfo.routingNumber;
        // this.policyEftReq.accountNumber = eftInfo.accountNum;
        this.policyEftReq.source = GlobalConstants.APPID;
        this.policyEftReq.termReason = '';
        this.policyEftReq.pspAZCorp = '';
        this.policyEftReq.eftProcessedAs = '';
        this.policyEftReq.eftProcessedVendor = '';
        this.policyEftReq.eMove = false;
        // Tracker.log('VOID EFT Request before passing to backend service ', this.policyEftReq);
        return this.appService.post(this.globlalConstants.EFT_VOID, this.policyEftReq);
    };
    /**
      * @param policyNumber
      * @param mco
      */
    PolicyeftService.prototype.addEFT = function (fullpolicyInfo) {
        var policyInfo = fullpolicyInfo.policyInfo;
        var eftInfo = fullpolicyInfo.eftInfo;
        var policyEffDate = formatDate(policyInfo.PolicyEffectiveDate, 'MM/dd/yyyy', 'en-US');
        this.policyNumber = new Policynumber(policyInfo.PolicyNumber);
        this.policyEftReq = new PolicyEftRequest();
        this.policyEftReq.policySym = this.policyNumber.symbol;
        this.policyEftReq.policyNum = this.policyNumber.policy;
        this.policyEftReq.policyMod = this.policyNumber.module;
        this.policyEftReq.mco = policyInfo.MASTER0CO;
        this.policyEftReq.eftEffDate = policyEffDate;
        this.policyEftReq.polEffectiveDate = policyEffDate;
        this.policyEftReq.eftType = eftInfo.eftType;
        this.policyEftReq.accountType = eftInfo.accountType;
        this.policyEftReq.routingNumber = eftInfo.routingNumber;
        this.policyEftReq.accountNumber = eftInfo.accountNum;
        this.policyEftReq.source = GlobalConstants.APPID;
        this.policyEftReq.termReason = '';
        this.policyEftReq.pspAZCorp = '';
        this.policyEftReq.eftProcessedAs = '';
        this.policyEftReq.eftProcessedVendor = '';
        this.policyEftReq.eMove = false;
        // Tracker.log('ADD EFT Request before passing to backend service ', this.policyEftReq);
        return this.appService.post(this.globlalConstants.EFT_ADD, this.policyEftReq);
    };
    /**
      * @param policyNumber
      * @param mco
      */
    PolicyeftService.prototype.updateEFT = function (fullpolicyInfo) {
        var policyInfo = fullpolicyInfo.policyInfo;
        var eftInfo = fullpolicyInfo.eftInfo;
        var policyEffDate = formatDate(policyInfo.PolicyEffectiveDate, 'MM/dd/yyyy', 'en-US');
        this.policyNumber = new Policynumber(policyInfo.PolicyNumber);
        this.policyEftReq = new PolicyEftRequest();
        this.policyEftReq.policySym = this.policyNumber.symbol;
        this.policyEftReq.policyNum = this.policyNumber.policy;
        this.policyEftReq.policyMod = this.policyNumber.module;
        this.policyEftReq.mco = policyInfo.MASTER0CO;
        this.policyEftReq.eftEffDate = policyEffDate;
        this.policyEftReq.polEffectiveDate = policyEffDate;
        this.policyEftReq.eftType = eftInfo.eftType;
        this.policyEftReq.accountType = eftInfo.accountType;
        this.policyEftReq.routingNumber = eftInfo.routingNumber;
        this.policyEftReq.accountNumber = eftInfo.accountNum;
        this.policyEftReq.source = GlobalConstants.APPID;
        this.policyEftReq.termReason = '';
        this.policyEftReq.pspAZCorp = '';
        this.policyEftReq.eftProcessedAs = '';
        this.policyEftReq.eftProcessedVendor = '';
        this.policyEftReq.eMove = false;
        // Tracker.log('UPDATE EFT Request before passing to backend service ', this.policyEftReq);
        return this.appService.post(this.globlalConstants.EFT_UPDATE, this.policyEftReq);
    };
    /**
      * @param policyNumber
      * @param mco
      */
    PolicyeftService.prototype.terminateEFT = function (fullpolicyInfo) {
        var policyInfo = fullpolicyInfo.policyInfo;
        var eftInfo = fullpolicyInfo.eftInfo;
        var policyEffDate = formatDate(policyInfo.PolicyEffectiveDate, 'MM/dd/yyyy', 'en-US');
        this.policyNumber = new Policynumber(policyInfo.PolicyNumber);
        this.policyEftReq = new PolicyEftRequest();
        this.policyEftReq.policySym = this.policyNumber.symbol;
        this.policyEftReq.policyNum = this.policyNumber.policy;
        this.policyEftReq.policyMod = this.policyNumber.module;
        this.policyEftReq.mco = policyInfo.MASTER0CO;
        this.policyEftReq.eftEffDate = policyEffDate;
        this.policyEftReq.polEffectiveDate = policyEffDate;
        this.policyEftReq.eftType = eftInfo.eftType;
        this.policyEftReq.accountType = eftInfo.accountType;
        this.policyEftReq.routingNumber = eftInfo.routingNumber;
        this.policyEftReq.accountNumber = eftInfo.accountNum;
        this.policyEftReq.source = GlobalConstants.APPID;
        this.policyEftReq.termReason = '';
        this.policyEftReq.pspAZCorp = '';
        this.policyEftReq.eftProcessedAs = '';
        this.policyEftReq.eftProcessedVendor = '';
        this.policyEftReq.eMove = false;
        return this.appService.post(this.globlalConstants.EFT_TERMINATE, this.policyEftReq);
    };
    /**
      * @param policyNumber
      * @param mco
      */
    PolicyeftService.prototype.getCurrentEFT = function (policynumber, mco) {
        this.policyNumber = new Policynumber(policynumber);
        this.policyEftReq = new PolicyEftRequest();
        this.policyEftReq.policySym = this.policyNumber.symbol;
        this.policyEftReq.policyNum = this.policyNumber.policy;
        this.policyEftReq.policyMod = this.policyNumber.module;
        this.policyEftReq.mco = mco;
        return this.appService.post(this.globlalConstants.EFT_GET_CURRENTEFT, this.policyEftReq);
    };
    PolicyeftService.prototype.getCurrentEFTInfo = function (policynumber, mco) {
        this.policyNumber = new Policynumber(policynumber);
        this.policyEftReq = new PolicyEftRequest();
        this.policyEftReq.policySym = this.policyNumber.symbol;
        this.policyEftReq.policyNum = this.policyNumber.policy;
        this.policyEftReq.policyMod = this.policyNumber.module;
        this.policyEftReq.mco = mco;
        return this.appService.post(this.globlalConstants.EFT_GET_CURRENTEFT_INFO, this.policyEftReq);
    };
    PolicyeftService.prototype.enrollEFTEIS = function (autoPaymentEnrollmentRequest) {
        return this.appService.postNew(this.globlalConstants.ENROLL_EFT, autoPaymentEnrollmentRequest);
    };
    PolicyeftService.prototype.updateEFTEIS = function (autoPaymentEnrollmentRequest) {
        return this.appService.postNew(this.globlalConstants.UPDATE_EFT, autoPaymentEnrollmentRequest);
    };
    PolicyeftService.prototype.terminateEFTEIS = function (autoPaymentEnrollmentRequest) {
        return this.appService.postNew(this.globlalConstants.TERMINATE_EFT, autoPaymentEnrollmentRequest);
    };
    PolicyeftService.prototype.getAutoPayDetails = function (customerId) {
        return this.appService.get(this.globlalConstants.GET_EFT + customerId, null);
    };
    PolicyeftService.ngInjectableDef = i0.defineInjectable({ factory: function PolicyeftService_Factory() { return new PolicyeftService(i0.inject(i1.BwappService), i0.inject(i2.BWErrorhandler)); }, token: PolicyeftService, providedIn: "root" });
    return PolicyeftService;
}());
export { PolicyeftService };
