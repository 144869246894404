import { HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalConstants } from '../../constants/global.constant';
import { MessageConstants } from '../../constants/message.constant';
import { BWError } from '../../shared/model/error.model';
import { FindPhResponse } from '../../shared/model/registration/findphres.model';
import { InsertPhResponse } from '../../shared/model/registration/insertphres.model';
import { NewuserResponse } from '../../shared/model/registration/newuserres.model';
import { RegistrationStatus } from '../../shared/model/registration/registrationstatus.model';
import { GoogleAnalyticsService } from '../../shared/services/analytics/google-analytics.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { BwqueriesService } from '../api/bwquery/bwqueries.service';
import { BwSecurityService } from '../api/bwsecurity/bw-security.service';
import { EmailvalidateService } from '../api/emailvalidate/emailvalidate.service';
import { EsigService } from '../api/esig/esig.service';
import { GoPaperlessService } from '../api/gopaperless/go-paperless.service';
import { BwappService } from '../bwapp.service';
import { BWErrorhandler } from '../errorhandler';
import { BwstoreService } from '../../shared/services/bwstore.service';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../bwapp.service";
import * as i2 from "../api/bwquery/bwqueries.service";
import * as i3 from "../api/bwsecurity/bw-security.service";
import * as i4 from "../api/esig/esig.service";
import * as i5 from "../api/gopaperless/go-paperless.service";
import * as i6 from "../api/emailvalidate/emailvalidate.service";
import * as i7 from "../../shared/services/authentication.service";
import * as i8 from "../../shared/services/bwstore.service";
import * as i9 from "../errorhandler";
import * as i10 from "../../shared/services/analytics/google-analytics.service";
import * as i11 from "@angular/platform-browser";
var CustomerRegistrationService = /** @class */ (function () {
    function CustomerRegistrationService(appService, bwqueryService, bwsecurityService, esigService, gopaperlessService, emailValidateService, authService, storeService, errorHandler, googleAnalytics, title) {
        this.appService = appService;
        this.bwqueryService = bwqueryService;
        this.bwsecurityService = bwsecurityService;
        this.esigService = esigService;
        this.gopaperlessService = gopaperlessService;
        this.emailValidateService = emailValidateService;
        this.authService = authService;
        this.storeService = storeService;
        this.errorHandler = errorHandler;
        this.googleAnalytics = googleAnalytics;
        this.title = title;
        this.globlalConstants = GlobalConstants;
        this.findPhResponse = new FindPhResponse();
        this.newUserResponse = new NewuserResponse();
        this.insertPhResponse = new InsertPhResponse();
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'my-auth-token'
            })
        };
        title.setTitle('Bristol West Policy - Register');
    }
    // TODO: Unused function, can be removed
    CustomerRegistrationService.prototype.validateNewUser = function (newUser) {
        var _this = this;
        return this.appService.post(this.globlalConstants.PH_VALIDATE_URL, newUser)
            .pipe(map(function (data) {
            _this.newUserResponse = data;
            // Tracker.log('Response form the BWQuery Service ::: ', this.newUserResponse);
            return _this.newUserResponse;
        }));
    };
    CustomerRegistrationService.prototype.validateNewUser_POINT = function (newUserReq) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.bwqueryService.getPolicyBasicContract(newUserReq.policynumber).subscribe(function (basicPolicyContract) {
                // Tracker.log('1. REGISTRATION STEP 1 : Invoking bwqueryService.getPolicyBasicContract :::: ', basicPolicyContract);
                if (basicPolicyContract) {
                    // const policyHolder =  new PolicyHolder();
                    // policyHolder.jwtToken = basicPolicyContract.jwtToken;
                    // this.storeService.createLoginInfo(policyHolder);
                    var mco_1 = basicPolicyContract.basicPlcyContractInfo.MASTER0CO;
                    var stateCd_1 = basicPolicyContract.basicPlcyContractInfo.RISK0STATE;
                    _this.newUserResponse.stateCd = stateCd_1; // keerthana
                    _this.newUserResponse.hasRenewal = basicPolicyContract.basicPlcyContractInfo.ISSUE0CODE; // keerthana
                    // Check for CA44
                    /*if (GlobalConstants.STATECD_04 === stateCd && GlobalConstants.MCO_44 === mco) {
                      this.newUserResponse.ca44 = true;
                      return reject(this.errorHandler.handleApplicationError(new BWError(
                        {'errorcode': '44', 'errordesc': MessageConstants.CA44_ERROR, 'source': 'RegistrationService'})));
                    } else*/
                    if (GlobalConstants.STATECD_04 === stateCd_1 && GlobalConstants.MCO_44 === mco_1) {
                        _this.newUserResponse.ca44 = true;
                        return reject(new BWError({ 'errorcode': '44', 'errordesc': MessageConstants.FARMERS_SPECILATY_URL, 'source': 'LoginService' }));
                    }
                    else if ((GlobalConstants.STATECD_04 === stateCd_1 && GlobalConstants.MCO_91 === mco_1) ||
                        (GlobalConstants.STATECD_04 === stateCd_1 && GlobalConstants.MCO_92 === mco_1) ||
                        (GlobalConstants.STATECD_52 === stateCd_1 && GlobalConstants.MCO_90 === mco_1)) {
                        return reject(new BWError({ 'errorcode': '21C', 'errordesc': MessageConstants.BRISTOLWEST_URL, 'source': 'LoginService' }));
                    }
                    if (typeof basicPolicyContract.basicPlcyContractInfo.FILLR1 !== 'undefined') {
                        _this.newUserResponse.producercode = basicPolicyContract.basicPlcyContractInfo.FILLR1
                            .concat(basicPolicyContract.basicPlcyContractInfo.RPT0AGT0NR)
                            .concat(basicPolicyContract.basicPlcyContractInfo.FILLR2);
                    }
                    _this.bwsecurityService.validateNewUserPOINT(newUserReq.policynumber, newUserReq.phdob, newUserReq.phzipcode)
                        .subscribe(function (validateUserRes) {
                        // Tracker.log('2. REGISTRATION STEP 1 : Invoking bwsecurityService.validateNewUserPOINT :::: ', validateUserRes);
                        // Check for missed data
                        if (validateUserRes && (validateUserRes.returnCode === null || validateUserRes.returnCode === '')) {
                            _this.googleAnalytics.trackEvent('Registration', 'UserRegistrationScreen', 'WrongPolicyNumberRegisterAttempt', 500);
                            return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '98', 'errordesc': MessageConstants.RESPCODE_90, 'source': 'RegistrationService' })));
                        }
                        else if (validateUserRes && validateUserRes.returnCode === '98') {
                            _this.googleAnalytics.trackEvent('Registration', 'UserRegistrationScreen', 'WrongDetailsRegisterAttempt', 500);
                            return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '98', 'errordesc': MessageConstants.RESPCODE_98, 'source': 'RegistrationService' })));
                        }
                        else if (validateUserRes && validateUserRes.returnCode === '11') {
                            _this.googleAnalytics.trackEvent('Registration', 'UserRegistrationScreen', 'WrongDOBRegisterAttempt', 500);
                            return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '98', 'errordesc': MessageConstants.RESPCODE_11, 'source': 'RegistrationService' })));
                        }
                        else if (validateUserRes && validateUserRes.returnCode === '10') {
                            _this.googleAnalytics.trackEvent('Registration', 'UserRegistrationScreen', 'WrongZipRegisterAttempt', 500);
                            return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '98', 'errordesc': MessageConstants.RESPCODE_10, 'source': 'RegistrationService' })));
                        }
                        else if (validateUserRes && validateUserRes.returnCode === '90') {
                            _this.googleAnalytics.trackEvent('Registration', 'UserRegistrationScreen', 'WrongPolicyNumberRegisterAttempt', 500);
                            return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '98', 'errordesc': MessageConstants.RESPCODE_90, 'source': 'RegistrationService' })));
                        }
                        else if (validateUserRes
                            && validateUserRes.returnCode === '00'
                            && validateUserRes.lastName.toLocaleUpperCase() !== newUserReq.phlastname.toLocaleUpperCase()) {
                            _this.googleAnalytics.trackEvent('Registration', 'UserRegistrationScreen', 'WrongLastNameRegisterAttempt,', 500);
                            return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '98', 'errordesc': MessageConstants.RESPCODE_91, 'source': 'RegistrationService' })));
                        }
                        else if (validateUserRes
                            && validateUserRes.returnCode !== null
                            && validateUserRes.returnCode !== undefined
                            && validateUserRes.returnCode !== ''
                            && validateUserRes.returnCode !== '00') {
                            _this.googleAnalytics.trackEvent('Registration', 'UserRegistrationScreen', 'WrongDetailsRegisterAttempt', 500);
                            return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '98', 'errordesc': MessageConstants.RESPCODE_98, 'source': 'RegistrationService' })));
                        }
                        if (validateUserRes && validateUserRes.returnCode === '00') {
                            _this.bwsecurityService.checkRegistrationStatus(mco_1, newUserReq.policynumber)
                                .subscribe(function (regstatusres) {
                                // Tracker.log('3. REGISTRATION STEP 1 : Invoking bwsecurityService.checkRegistrationStatus :::: ', regstatusres);
                                if (regstatusres && regstatusres.checkRegistrationStatusResult) {
                                    return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '98', 'errordesc': MessageConstants.RESPCODE_97, 'source': 'RegistrationService' })));
                                }
                                else {
                                    _this.newUserResponse.firstName = validateUserRes.firstName;
                                    _this.newUserResponse.lastName = validateUserRes.lastName;
                                    _this.newUserResponse.mco = validateUserRes.mco;
                                    _this.newUserResponse.phemail = validateUserRes.email;
                                    // Call Esig Service
                                    _this.esigService.isESigImplemented(mco_1, stateCd_1).subscribe(function (esigimplresp) {
                                        // Tracker.log('4. REGISTRATION STEP 1 : Invoking esigService.isESigImplemented :::: ', esigimplresp);
                                        if (esigimplresp && esigimplresp.status !== 'FAILURE' && esigimplresp.isImplemented === true) {
                                            _this.esigService.getEnvelops(validateUserRes.firstName, validateUserRes.lastName, newUserReq.policynumber)
                                                .subscribe(function (envelopsres) {
                                                // Tracker.log('5. REGISTRATION STEP 1 : Invoking esigService.getEnvelops :::: ', envelopsres);
                                                var hasEnvelops = false;
                                                if (envelopsres && envelopsres.status.toUpperCase() === 'SUCCESS') {
                                                    _this.authService.envelopesSubject.next(envelopsres.envelopes);
                                                    envelopsres.envelopes.forEach(function (env) {
                                                        // Tracker.log('Looping Envelop elements::: ', env);
                                                        env.recipients.forEach(function (rec) {
                                                            // Tracker.log('Looping Recipient elements::: ', rec);
                                                            if (rec.recipType === 'PrimaryNamedInsured') {
                                                                if (rec.status === 'Completed' || rec.status === 'Declined' || rec.status === 'Signed') {
                                                                    hasEnvelops = false;
                                                                }
                                                                else {
                                                                    hasEnvelops = true;
                                                                    _this.newUserResponse.envelopId = env.envelopeID;
                                                                    _this.newUserResponse.recipientId = rec.recipientID;
                                                                    return hasEnvelops;
                                                                }
                                                            }
                                                        });
                                                    });
                                                }
                                                _this.newUserResponse.esigstatus = hasEnvelops;
                                                // Tracker.log('Verified Envelops ', hasEnvelops);
                                                resolve(_this.newUserResponse);
                                            });
                                        }
                                        else {
                                            _this.newUserResponse.esigstatus = false;
                                            resolve(_this.newUserResponse);
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            }, function (error) {
                // Tracker.log('Error Occurred in ValidateNewUser_POINT', error);
                return reject(error);
            });
        });
    };
    CustomerRegistrationService.prototype.updateEpolicyEmail = function (email, policynum, mco, producercode) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var isBwOktaEnabled = environment.bwOktaEnabled;
            _this.bwsecurityService.getpolicyByEmail(email).subscribe(function (emailres) {
                // Tracker.log('1. REGISTRATION STEP 2 : Invoking bwsecurityService.getpolicyByEmail :::: ', emailres);
                if (emailres && emailres.polNum !== undefined && emailres.polNum !== null && emailres.polNum !== '' && !isBwOktaEnabled) {
                    return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '100', 'errordesc': MessageConstants.EMAIL_REGISTERED, 'source': 'BWSecurityService' })));
                }
                else {
                    _this.emailValidateService.validateEmail(email, policynum, mco, producercode).subscribe(function (validateres) {
                        // Tracker.log('2. REGISTRATION STEP 2 : Invoking emailValidateService.validateEmail :::: ', validateres);
                        console.log("updateEppolicyEmail ::" + validateres);
                        if (validateres && (validateres.status === 'VALID'
                            || validateres.status === 'ERROR_SERVICE'
                            || validateres.status === 'ERROR_VENDOR'
                            || validateres.responseCode === 'E0000007'
                            || validateres.responseCode === 'E')) {
                            console.log(validateres);
                            _this.gopaperlessService.updateEPolicyEmail(email, policynum, mco).subscribe(function (res) {
                                // Tracker.log('3. REGISTRATION STEP 2 : Invoking gopaperlessService.updateEPolicyEmail :::: ', res);
                                return resolve(res);
                            });
                        }
                        else {
                            if (environment.bwOktaEnabled) {
                                return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '100', 'errordesc': (validateres.responseText === 'invalid' ? MessageConstants.INVALID_EMAIL : MessageConstants.EMAIL_REGISTERED), 'source': 'EmailValidateService' })));
                            }
                            else {
                                return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '100', 'errordesc': MessageConstants.INVALID_EMAIL, 'source': 'EmailValidateService' })));
                            }
                        }
                    });
                }
            }, function (error) {
                // Tracker.log('Error Occurred in updateEpolicyEmail', error);
                return reject(error);
            });
        });
    };
    CustomerRegistrationService.prototype.validateEmail = function (email, policynum, mco, producercode) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.bwsecurityService.getpolicyByEmail(email).subscribe(function (emailres) {
                // Tracker.log('1. REGISTRATION STEP 2 : Invoking bwsecurityService.getpolicyByEmail :::: ', emailres);
                var isBwOktaEnabled = environment.bwOktaEnabled;
                if (emailres && emailres.polNum !== undefined && emailres.polNum !== null && emailres.polNum !== '' && !isBwOktaEnabled) {
                    return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '100', 'errordesc': MessageConstants.EMAIL_REGISTERED, 'source': 'BWSecurityService' })));
                }
                else {
                    _this.emailValidateService.validateEmail(email, policynum, mco, producercode).subscribe(function (validateres) {
                        // Tracker.log('2. REGISTRATION STEP 2 : Invoking emailValidateService.validateEmail :::: ', validateres);
                        if (validateres && (validateres.status === 'VALID'
                            || validateres.status === 'ERROR_SERVICE'
                            || validateres.status === 'ERROR_VENDOR'
                            || validateres.responseCode === 'E0000007'
                            || validateres.responseCode === 'E')) {
                            console.log("validateEmail :: customer-registration");
                            return resolve(validateres);
                        }
                        else {
                            if (environment.bwOktaEnabled) {
                                return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '100', 'errordesc': (validateres.responseText === 'invalid' ? MessageConstants.INVALID_EMAIL : MessageConstants.EMAIL_REGISTERED), 'source': 'EmailValidateService' })));
                            }
                            else {
                                return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '100', 'errordesc': MessageConstants.INVALID_EMAIL, 'source': 'EmailValidateService' })));
                            }
                            // return reject(this.errorHandler.handleApplicationError(new BWError(
                            //   {'errorcode': '100', 'errordesc': MessageConstants.INVALID_EMAIL, 'source': 'EmailValidateService'})));
                        }
                    });
                }
            }, function (error) {
                // Tracker.log('Error Occurred in updateEpolicyEmail', error);
                return reject(error);
            });
        });
    };
    CustomerRegistrationService.prototype.lookupPolicyHolder = function (phrequest) {
        // Tracker.log('Passed Value to Service : ' + phrequest.pointHookID);
        if (phrequest != null && phrequest.pointHookID === 'nadella') {
            this.findPhResponse.policyMatch = true;
            this.findPhResponse.responseCode = '100';
        }
        else {
            this.findPhResponse.policyMatch = false;
            this.findPhResponse.responseCode = '101';
        }
        return of(this.findPhResponse);
    };
    CustomerRegistrationService.prototype.updateRegistrationState = function (newUser) {
    };
    CustomerRegistrationService.prototype.registerNewUser = function (page, ecstatus, esignstatus) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var registrationStatus = new RegistrationStatus();
            _this.bwsecurityService.addNewUserSSO().subscribe(function (data) {
                if (data.szResponseCode !== '-1') {
                    registrationStatus.status = false;
                    registrationStatus.navigateTo = 'MODAL';
                    if (page && page === 'EC' && ecstatus === 'true') {
                        _this.bwsecurityService.changeUserEmail_POINT(ecstatus).subscribe(function (emailchangeres) {
                            // Tracker.log('Email Optout Updated in POINT');
                            if (emailchangeres) {
                                resolve(registrationStatus);
                            }
                            else {
                                return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'EMAIL', 'errordesc': MessageConstants.EMAIL_UPDATE_ERROR, 'source': 'BWSecurityService' })));
                            }
                        });
                    }
                    else {
                        resolve(registrationStatus);
                    }
                }
                else if (esignstatus) {
                    registrationStatus.status = false;
                    registrationStatus.navigateTo = 'ESIG';
                    resolve(registrationStatus);
                }
                else {
                    registrationStatus.status = true;
                    registrationStatus.navigateTo = 'REGSUCCESS';
                    resolve(registrationStatus);
                }
            }, function (error) {
                return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '100', 'errordesc': MessageConstants.RESPCODE_100, 'source': 'BWSecurityService' })));
            });
        });
    };
    CustomerRegistrationService.prototype.checkRegistrationStatus = function (mco, policynumber) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.bwsecurityService.checkRegistrationStatus(mco, policynumber)
                .subscribe(function (regstatusres) {
                // Tracker.log('1. Invoking bwsecurityService.checkRegistrationStatus :::: ', regstatusres);
                resolve(regstatusres);
            }, function (error) {
                return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '100', 'errordesc': MessageConstants.RESPCODE_100, 'source': 'BWSecurityService' })));
            });
        });
    };
    CustomerRegistrationService.ngInjectableDef = i0.defineInjectable({ factory: function CustomerRegistrationService_Factory() { return new CustomerRegistrationService(i0.inject(i1.BwappService), i0.inject(i2.BwqueriesService), i0.inject(i3.BwSecurityService), i0.inject(i4.EsigService), i0.inject(i5.GoPaperlessService), i0.inject(i6.EmailvalidateService), i0.inject(i7.AuthenticationService), i0.inject(i8.BwstoreService), i0.inject(i9.BWErrorhandler), i0.inject(i10.GoogleAnalyticsService), i0.inject(i11.Title)); }, token: CustomerRegistrationService, providedIn: "root" });
    return CustomerRegistrationService;
}());
export { CustomerRegistrationService };
