import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GooglemapComponent } from '../../shared/components/modals/googlemap/googlemap.component';
import { Agent } from '../../shared/model/agent';

@Component({
  selector: 'app-find-agent-broker-list',
  template: `
    <main id="findAgent" class="main">
      <div class="container border border-success">
      <app-breadcrumb></app-breadcrumb>
        <div class="row d-flex justify-content-center">
          <div class="col-md-8 ">
            <app-find-agent (messageEvent2)="receiveMessage($event)"></app-find-agent>
          </div>
        </div>

        <!-- agent-list component -->
        <div *ngIf="loading" class="col-12 mr-auto ml-auto text-center">
          <i class="fas fa-spinner fa-3x fa-spin text-color-info"></i>
        </div>

    <div class="card-columns mt-3">
      <div *ngFor="let agent of this.agents" class="card agent-broker-card">
        <div class="card-header text-left pb-1 strong"><h2 class="heading-5 text-truncate">{{ agent.Agency }}</h2></div>
          <div class="card-body">

            <div class="row">
              <div class="col-lg-12">
                <div class="border-bottom">
                <i class="fas fa-phone fa-sm icon-align color-bristol-try mr-1"></i>
                  <a class="card-link" href="tel:1-{{ agent.AgtPhone }}">1-{{ agent.AgtPhone }}</a>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12">
                <div class="border-bottom">
                <i class="fas fa-at fa-sm icon-align color-bristol-try mr-1"></i>
                  <a class="card-link"
                  href="mailto:{{ agent.Email }}?subject=bristolwest.com Locate an Agent or Broker">{{ agent.Email | lowercase }}</a>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12 bottom-border">
                <i class="fas fa-envelope fa-sm icon-align color-bristol-try mr-1"></i> <label>Address</label>
                <address class="small">
                  {{ agent.Address}}<br/>
                  {{ agent.City}}, {{ agent.State}} {{ agent.Zip }}
                </address>
              </div>
            </div>

            <div class="row">
              <div class="col-12 ml-auto mr-auto">
              <div class="small"><i class="fas fa-map-marker fa-lg icon-align color-bristol-try"></i> {{agent.Distance}} Miles Away</div>
              <!--
              <button (click)="viewGoogleMap()" class="btn-link small" data-toggle="modal" data-target="#modal-gmaps">
                <i class="fas fa-map-marker fa-lg icon-align color-bristol-try"></i> {{agent.Distance}} Miles Away
              </button>
              -->
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="h-100"></div>
      </div>
    </main>
  `,
  styleUrls: ['./find-agent-broker-list.component.scss']
})
export class FindAgentBrokerListComponent {

  agents: Agent[];
  loading = false;

  constructor(private modalService: NgbModal) {}

  receiveMessage($event) {
    this.agents = $event;
  }

  viewGoogleMap() {
    // this.googleAnalytics.trackEvent('IDCard', 'CallingExtream', 'IDCardButtonClick', 200);
    this.modalService.open(GooglemapComponent);
  }

}
