import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { Bwappenum } from '../../constants/bwappenum.enum';
import { MessagesService } from '../services/messages.service';
import { Tracker } from '../utils/tracker';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {

    private appenum = Bwappenum;

    constructor(private messageService: MessagesService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const started = Date.now();
        let ok: string;

        const logreqs = '/log/';

        // extend server response observable with logging
        return next.handle(req)
            .pipe(
                tap(
                    event => ok = event instanceof HttpResponse ? 'SUCCESS ' + this.appenum.SUCCESS : '',
                    error => ok = 'FAILED ' + Bwappenum.FAILURE
                ),
                finalize(() => {
                    const elapsed = Date.now() - started;
                    /*const msg = `${req.method} '${req.urlWithParams}'
                       ${ok} in ${elapsed} ms.`;*/
                    const msg = `${ok} in ${elapsed} ms.`;

                    // console.log('Logging Interceptor', req.urlWithParams, `${elapsed}`);

                    Tracker.logtime(`${req.urlWithParams}`, msg);
                })
            );
    }
}
