/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/@ng-bootstrap/ng-bootstrap/progressbar/progressbar.ngfactory";
import * as i2 from "@ng-bootstrap/ng-bootstrap/progressbar/progressbar";
import * as i3 from "@ng-bootstrap/ng-bootstrap/progressbar/progressbar-config";
import * as i4 from "./session-timeout.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
var styles_SessionTimeoutComponent = [];
var RenderType_SessionTimeoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SessionTimeoutComponent, data: {} });
export { RenderType_SessionTimeoutComponent as RenderType_SessionTimeoutComponent };
export function View_SessionTimeoutComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-header"], ["id", "modelHeader"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"], ["id", "modalTitle"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Session Timeout warning"])), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "modal-body"], ["id", "modalBody"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" You will be timed out in ", " "])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "ngb-progressbar", [["animate", "false"], ["class", "progress-striped active"], ["id", "timeoutprogress"], ["type", "danger"]], null, null, null, i1.View_NgbProgressbar_0, i1.RenderType_NgbProgressbar)), i0.ɵdid(7, 49152, null, 0, i2.NgbProgressbar, [i3.NgbProgressbarConfig], { max: [0, "max"], type: [1, "type"], value: [2, "value"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 4, "div", [["class", "modal-footer"], ["id", "modalFooter"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["id", "ac90819bu1"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.continue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Continue"])), (_l()(), i0.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["id", "ac90819bu2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Logout"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.timeout; var currVal_2 = "danger"; var currVal_3 = _co.countSeconds; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.countMinutes !== 0) ? (((_co.countMinutes - 0) + " Minute") + ((_co.countMinutes > 1) ? "s " : " ")) : "") + _co.countSeconds) + " Seconds"); _ck(_v, 4, 0, currVal_0); }); }
export function View_SessionTimeoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-session-timeout", [], null, null, null, View_SessionTimeoutComponent_0, RenderType_SessionTimeoutComponent)), i0.ɵdid(1, 114688, null, 0, i4.SessionTimeoutComponent, [i5.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SessionTimeoutComponentNgFactory = i0.ɵccf("app-session-timeout", i4.SessionTimeoutComponent, View_SessionTimeoutComponent_Host_0, { countMinutes: "countMinutes", countSeconds: "countSeconds", progressCount: "progressCount" }, {}, []);
export { SessionTimeoutComponentNgFactory as SessionTimeoutComponentNgFactory };
