<main id="main" class="main">

  <div class="container">

    <!-- Page Loader -->
    <div *ngIf="!this.isLoaded" class="small text-center">Getting Reviews...</div>
    <div *ngIf="!this.isLoaded" class="my-5 text-center">
      <app-bw-spinner [smallSpinner]="false"></app-bw-spinner>
    </div>

    <div class="row" *ngIf="this.isLoaded">
      <div class="col-lg-8">
        <h1 class="heading-4" tabindex="0">Customer Reviews</h1>
        <hr>
        <div class="my-3" *ngFor="let item of reviewsList | paginate: config">
          <span class="heading-6">{{item.FirstName}} &nbsp; {{item.Date | date: 'mediumDate'}}</span>
          <span *ngIf="item.Title" class="heading-6">"{{item.Title}}"
            <ngb-rating [(rate)]="item.Stars" [readonly]="true" [max]="5"></ngb-rating>
          </span>

          <span *ngIf="!item.Title" class="heading-6">
            <ngb-rating [(rate)]="item.Stars" [readonly]="true" [max]="5"></ngb-rating>
          </span>

          <span [innerHTML]="item.Body"></span>
          <hr>
        </div>

        <div class="my-2" style="text-align: center">
          <pagination-controls class="my-pagination" (pageChange)="pageChanged($event)"></pagination-controls>
        </div>

      </div>

      <div class="col-lg-4">
        <app-directsales fromPage="customerreview" *ngIf="this.showQuickQuote === 'Y'"></app-directsales>
      </div>

    </div>

  </div>

</main>