/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-iframe-frms.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/components/bw-spinner/bw-spinner.component.ngfactory";
import * as i3 from "../../shared/components/bw-spinner/bw-spinner.component";
import * as i4 from "../../app.component";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/common";
import * as i7 from "./payment-iframe-frms.component";
var styles_PaymentIframeFrmsComponent = [i0.styles];
var RenderType_PaymentIframeFrmsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentIframeFrmsComponent, data: {} });
export { RenderType_PaymentIframeFrmsComponent as RenderType_PaymentIframeFrmsComponent };
function View_PaymentIframeFrmsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "my-5 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-bw-spinner", [], null, null, null, i2.View_BwSpinnerComponent_0, i2.RenderType_BwSpinnerComponent)), i1.ɵdid(2, 114688, null, 0, i3.BwSpinnerComponent, [], { smallSpinner: [0, "smallSpinner"] }, null)], function (_ck, _v) { var currVal_0 = false; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PaymentIframeFrmsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[2, 0], ["qckRemind", 1]], null, 13, "div", [["class", "col-sm-4  quickReminders"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "card pl-md-2 pr-md-2 px-md-2 py-md-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "card-body bg-color-gray-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "card-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [["class", "heading-4 mt-2 mb-2 pl-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Quick reminders "])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "pl-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "ul", [["class", "list-unstyled mb-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Look on the most recent bill for the billing account or policy number."])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Use the same ZIP code in the mailing address associated with the account/policy."]))], null, null); }
export function View_PaymentIframeFrmsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.SafePipe, [i5.DomSanitizer]), i1.ɵqud(402653184, 1, { iframe: 0 }), i1.ɵqud(671088640, 2, { quickReminder: 0 }), i1.ɵqud(402653184, 3, { iframeDiv: 0 }), i1.ɵqud(402653184, 4, { mainElnt: 0 }), (_l()(), i1.ɵeld(5, 0, [[4, 0], ["mainElnt", 1]], null, 9, "main", [["class", " pt-0 maxheight_width min-ht-mobile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "container maxheight_width"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentIframeFrmsComponent_1)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "row col-sm-12 p-0 m-0 justify-content-center payment-iframe"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, [[3, 0], ["iframeParent", 1]], null, 2, "div", [["class", "col-sm-8 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, [[1, 0], ["iframe", 1]], null, 1, "iframe", [["class", "payment-iframe"], ["height", "100%"], ["scrolling", "no"], ["title", "Thread Content"]], [[8, "src", 5]], null, null, null, null)), i1.ɵppd(12, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentIframeFrmsComponent_2)), i1.ɵdid(14, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoaded; _ck(_v, 8, 0, currVal_0); var currVal_2 = _co.isLoaded; _ck(_v, 14, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v, 0), _co.sourceURL)); _ck(_v, 11, 0, currVal_1); }); }
export function View_PaymentIframeFrmsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payment-iframe-frms", [], null, [["window", "message"]], function (_v, en, $event) { var ad = true; if (("window:message" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onMessage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_PaymentIframeFrmsComponent_0, RenderType_PaymentIframeFrmsComponent)), i1.ɵdid(1, 114688, null, 0, i7.PaymentIframeFrmsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentIframeFrmsComponentNgFactory = i1.ɵccf("app-payment-iframe-frms", i7.PaymentIframeFrmsComponent, View_PaymentIframeFrmsComponent_Host_0, {}, {}, []);
export { PaymentIframeFrmsComponentNgFactory as PaymentIframeFrmsComponentNgFactory };
