export var CREATE_CANCELPOLICYINFO = '[CANCELPOLICYINFO] Add';
export var UPDATE_CANCELPOLICYINFO = '[CANCELPOLICYINFO] Update';
export var REMOVE_CANCELPOLICYINFO = '[CANCELPOLICYINFO] Remove';
// Create PolicyCancellation Info upon User landing on  MyProfile Screen
var CreatePolicyCancelInfo = /** @class */ (function () {
    function CreatePolicyCancelInfo(payload) {
        this.payload = payload;
        this.type = CREATE_CANCELPOLICYINFO;
    }
    return CreatePolicyCancelInfo;
}());
export { CreatePolicyCancelInfo };
// Update PolicyCancellation Info
var UpdatePolicyCancelInfo = /** @class */ (function () {
    function UpdatePolicyCancelInfo(payload) {
        this.payload = payload;
        this.type = UPDATE_CANCELPOLICYINFO;
    }
    return UpdatePolicyCancelInfo;
}());
export { UpdatePolicyCancelInfo };
// Remove PolicyCancellation upon User finishes the Cancel Process and while voiding the cancellation changes
var RemovePolicyCancelInfo = /** @class */ (function () {
    function RemovePolicyCancelInfo(payload) {
        this.payload = payload;
        this.type = REMOVE_CANCELPOLICYINFO;
    }
    return RemovePolicyCancelInfo;
}());
export { RemovePolicyCancelInfo };
