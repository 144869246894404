
   <form class="mt-2 col-md-12 col-sm-12" [formGroup]="feedbackForm">
    <!--Commenting below for CES Start-->
    <!--<div class="modal-header">
      <h4 class="modal-title heading-2 mb-1" tabindex="0">Website Feedback</h4>
    </div>-->
    <!--Commenting below for CES End-->
    <div class="modal-body">
      <div class="form-group" *ngIf="!this.submitted">
        <label class="text-muted mb-1" for="feedbackrate" tabindex="0" #ratingLabel>Rate your experience on this site:</label>
        <ngb-rating (keydown)="eventHandler($event)" [(rate)]="currentRate" max="5" class="border-0 no-outline" id="currentRate" name="currentRate" formControlName="currentRate">
          <ng-template let-fill="fill" let-index="index">
            <span class="star" (keypress)="eventHandler($event, 'span')" [class.filled]="fill === 100">&#9733;</span>
          </ng-template>
        </ngb-rating>
        <div class="small color-rating-error font-weight-bold" id="currentRate_desc"  role="alert" *ngIf="this.ratingError != ''">
          {{ this.ratingError }}
        </div>
        <!--<pre>Rate: <b>{{currentRate}}</b></pre>-->
        <div class="row d-flex">
          <div class="col-md-6 col-sm-12">
            <label #firstname for="firstname" tabindex="0">First Name <small>(Optional)</small></label>
            <input type="text" id="firstname" name="firstname" class="form-control form-control-sm mb-2" [readonly]="this.isReadOnly" formControlName="firstname" maxlength="50"/>      
          </div>

          <div class="col-md-6 col-sm-12">
            <label for="lastname"  tabindex="0">Last Name <small>(Optional)</small></label>
            <input type="text" id="lastname" name="lastname" class="form-control form-control-sm mb-2" [readonly]="this.isReadOnly" formControlName="lastname" maxlength="50"/>      
          </div>

          <div class="col-md-6 col-sm-12">  
            <label for="phonenumber"  tabindex="0">Phone Number <small>(Optional)</small></label>
            <input type="tel" class="form-control form-control-sm mb-2" formControlName="phonenumber" placeholder="XXX XXX-XXXX"/>
          </div>

          <div class="col-md-6 col-sm-12 form-group">
              <app-policy-number-input [policynum]="policyNumber" [formSubmitAttempt]="submitted" [form]="feedbackForm" [validate]="false" [isReadOnly]="this.isReadOnly">
              </app-policy-number-input>
            <!--
            <label for="policynumber">Policy Number <small>(Optional)</small></label>
            <input id="policynumber" name="policynumber" class="form-control form-control-sm mb-2" 
            formControlName="policynumber" [readonly]="this.isReadOnly"/>
          -->
          </div>
          <div class="col-12">
            <label for="feedback" tabindex="0">Feedback <small>(Optional) </small><small>(1000 character limit)</small></label>
            <textarea id="feedback" name="feedback" class="form-control form-control-sm" rows="5" maxlength="1000" formControlName="feedback" placeholder="Enter feedback here"></textarea>
          </div>
        </div>
        <div class="row d-flex mb-1 mt-1">
          <div class="col-md-12 col-sm-12 small d-none">
            Epsum Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eius consectetur reiciendis veritatis tempore quo, eligendi blanditiis, quisquam adipisci libero 
            culpa laboriosam quas, similique inventore cum cumque natus quae! Quas, corporis?
          </div>
        </div>
      </div>
      <div class="" *ngIf="submitted">
          <div class="text-center">
              <i class="far fa-thumbs-up fa-3x icon-align color-bristol-blue"></i>
            <h2 class="heading-4">Thank You!</h2></div>
            Your opinion is important to us and helps us to improve our website. 
      </div>
  </div>
  <div class="modal-footer mt-1">    
    <button *ngIf="!submitted" type="button" class="btn btn-secondary" (click)="activeModal.dismiss('Close')">Cancel</button>
    <button *ngIf="submitted" type="button" class="btn btn-secondary" (click)="activeModal.dismiss('Submit')">Cancel</button>
    <button *ngIf="!submitted" type="submit" class="btn btn-primary" (click)="onSubmit(feedbackForm.value)">Submit</button>
  </div>
</form>