import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
var EmailExistsComponent = /** @class */ (function () {
    function EmailExistsComponent(route, router, modal) {
        this.route = route;
        this.router = router;
        this.modal = modal;
    }
    EmailExistsComponent.prototype.ngOnInit = function () { };
    EmailExistsComponent.prototype.navigate = function () {
        this.modal.close();
        this.router.navigate(['/register/register-two']);
    };
    EmailExistsComponent.prototype.dismiss = function () {
        this.modal.dismiss();
    };
    return EmailExistsComponent;
}());
export { EmailExistsComponent };
