
    <div class="modal-header">
      <h4 class="modal-title">{{errorType}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="errorModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <!--<p [innerHTML]="message"></p>-->
      <div [innerHTML]="errormsg">Login Error</div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-warning" (click)="errorModal.dismiss('Close clicked')">
        Close
      </button>
    </div>
  