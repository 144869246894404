import { OnInit } from '@angular/core';
var MobileAppBannerComponent = /** @class */ (function () {
    function MobileAppBannerComponent() {
        this.closeBanner = true;
    }
    MobileAppBannerComponent.prototype.ngOnInit = function () {
    };
    MobileAppBannerComponent.prototype.FindMobileversion = function () {
        if (typeof navigator !== 'undefined') {
            if (!!navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent)) {
                window.open('https://apps.apple.com/us/app/bristol-west/id1473647523', '_blank');
            }
            else {
                window.open('https://play.google.com/store/apps/details?id=com.bristolwest.app', '_blank');
            }
        }
    };
    return MobileAppBannerComponent;
}());
export { MobileAppBannerComponent };
