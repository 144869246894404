import { HttpParams } from '../../../../../node_modules/@angular/common/http';
import { BwappService } from '../../bwapp.service';
import { GlobalConstants } from '../../../constants/global.constant';
import * as i0 from "@angular/core";
import * as i1 from "../../bwapp.service";
var ReviewsService = /** @class */ (function () {
    function ReviewsService(appService) {
        this.appService = appService;
        this.globalConstants = GlobalConstants;
    }
    ReviewsService.prototype.getReviews = function () {
        var params = new HttpParams();
        return this.appService.get(this.globalConstants.GET_CUSTOMER_REVIEWS, params);
    };
    ReviewsService.ngInjectableDef = i0.defineInjectable({ factory: function ReviewsService_Factory() { return new ReviewsService(i0.inject(i1.BwappService)); }, token: ReviewsService, providedIn: "root" });
    return ReviewsService;
}());
export { ReviewsService };
