import { BwappService } from '../../services/bwapp.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/bwapp.service";
var StatecdsService = /** @class */ (function () {
    function StatecdsService(bwappService) {
        this.bwappService = bwappService;
        this.stateCdsPath = 'assets/data/states.json';
    }
    StatecdsService.prototype.getStateCds = function () {
        return this.bwappService.getlocaldata(this.stateCdsPath);
    };
    StatecdsService.ngInjectableDef = i0.defineInjectable({ factory: function StatecdsService_Factory() { return new StatecdsService(i0.inject(i1.BwappService)); }, token: StatecdsService, providedIn: "root" });
    return StatecdsService;
}());
export { StatecdsService };
