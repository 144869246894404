import { OnInit } from '@angular/core';
import { DirectsalesService } from '../../shared/services/directsales.service';
var DirectsalescallComponent = /** @class */ (function () {
    function DirectsalescallComponent(directSalesService) {
        var _this = this;
        this.directSalesService = directSalesService;
        this.directSalesService.phoneNumberObject.subscribe(function (phone) { return _this.directSalesPhone = phone; });
    }
    DirectsalescallComponent.prototype.ngOnInit = function () {
    };
    return DirectsalescallComponent;
}());
export { DirectsalescallComponent };
