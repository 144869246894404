import * as BwCoveragesActions from '../actions/bwcoverages.actions';
import { ValidValuesResponse } from '../../shared/model/api/validvalues/valid-values-res.model';
var defaultCoveragesState = new ValidValuesResponse();
// Helper to create new state object as the state is immutable
var newState = function (state, newDataObj) {
    return Object.assign({}, state, newDataObj);
};
var ɵ0 = newState;
export function BwCoveragesReduer(state, action) {
    if (state === void 0) { state = defaultCoveragesState; }
    switch (action.type) {
        case BwCoveragesActions.CREATE_VALIDVALUESINFO:
            return action.payload;
        case BwCoveragesActions.UPDATE_VALIDVALUESINFO:
            return action.payload;
        case BwCoveragesActions.REMOVE_VALIDVALUESINFO:
            return newState(state, defaultCoveragesState);
        default:
            return state;
    }
}
export { ɵ0 };
