/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./directsaleshome.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/directives/directsales.directive";
import * as i3 from "../../app.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./directsaleshome.component";
import * as i7 from "../../shared/services/directsales.service";
import * as i8 from "@angular/router";
var styles_DirectsaleshomeComponent = [i0.styles];
var RenderType_DirectsaleshomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DirectsaleshomeComponent, data: {} });
export { RenderType_DirectsaleshomeComponent as RenderType_DirectsaleshomeComponent };
function View_DirectsaleshomeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "iframe", [["appIframeResizer", ""], ["class", "thread-content"], ["frameborder", "0"], ["scrolling", "no"], ["style", "height:100%; width:100%; border: none;"], ["title", "Thread Content"]], [[8, "src", 5]], null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.DirectsalesDirective, [i1.ElementRef], null, null), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.sourceURL)); _ck(_v, 0, 0, currVal_0); }); }
function View_DirectsaleshomeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "container text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Unable to load quote."]))], null, null); }
export function View_DirectsaleshomeComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.SafePipe, [i4.DomSanitizer]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DirectsaleshomeComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DirectsaleshomeComponent_2)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.sourceInd === "ITC"); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.sourceURL; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_DirectsaleshomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-directsaleshome", [], null, null, null, View_DirectsaleshomeComponent_0, RenderType_DirectsaleshomeComponent)), i1.ɵdid(1, 114688, null, 0, i6.DirectsaleshomeComponent, [i7.DirectsalesService, i8.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DirectsaleshomeComponentNgFactory = i1.ɵccf("app-directsaleshome", i6.DirectsaleshomeComponent, View_DirectsaleshomeComponent_Host_0, {}, {}, []);
export { DirectsaleshomeComponentNgFactory as DirectsaleshomeComponentNgFactory };
