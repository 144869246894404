import { OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddresseditService } from '../../../services/addressedit/addressedit.service';
import { Tracker } from '../../../shared/utils/tracker';
import { EndorsementReq } from '../../../shared/model/api/endorsement/endorsement-req';
import { EndorseAddressReq } from '../../../shared/model/api/endorsement/endorse-address-req';
import { GlobalConstants } from '../../../constants/global.constant';
import { BwstoreService } from '../../../shared/services/bwstore.service';
import { Mailingaddress } from '../../../shared/model/endorsements/addressedit/mailingaddress.model';
import { environment } from '../../../../environments/environment';
import { BWErrorhandler } from '../../../services/errorhandler';
import { AutopayeftService } from '../../../services/mypolicy/autopayeft.service';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { Policymailreq } from '../../../shared/model/registration/policymailreq';
import { Dairycommentreq } from '../../../shared/model/api/policymgmt/dairycommentreq.model';
import { MessageConstants } from '../../../constants/message.constant';
import { IdcardComponent } from '../../../shared/components/modals/idcard.component';
import { GoogleAnalyticsService } from '../../../shared/services/analytics/google-analytics.service';
import { CommonUtil } from '../../../shared/utils/commonutil';
import { EndorseMetainfo } from '../../../shared/model/endorsements/endorse-metainfo.model';
var MailingaddrConfirmationComponent = /** @class */ (function () {
    function MailingaddrConfirmationComponent(addressEditService, _storeService, errorHandler, eftService, router, modalService, googleAnalytics) {
        this.addressEditService = addressEditService;
        this._storeService = _storeService;
        this.errorHandler = errorHandler;
        this.eftService = eftService;
        this.router = router;
        this.modalService = modalService;
        this.googleAnalytics = googleAnalytics;
        this.endorseAddressReq = new EndorseAddressReq();
        this.endorsementReq = new EndorsementReq();
        this.globlalConstants = GlobalConstants;
        this.isLoaded = true;
        this.showAddress = false;
        this.showPhoneNumber = false;
        this.policymailreq = new Policymailreq();
        this.dairyCommentReqs = new Array();
        this.basicPolicyDetails = this._storeService.getPolicyInfoFromStore();
        this.policyInfo = this.basicPolicyDetails.policyInfo;
        this.authenticatedPhDetails = this._storeService.getLoginInfoFromStore();
        this.bwEndorsementInfo = this._storeService.getEndorsementInfoFromStore();
    }
    MailingaddrConfirmationComponent.prototype.ngOnInit = function () {
        this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressConfirmationScreen', 'MailingAddressConfirmationScreen', 200);
        this.showAddress = this.mailingAddress.isAddressChanged;
        this.showPhoneNumber = this.mailingAddress.isPhoneNumberChanged;
        this.datetime = CommonUtil.getDateTimeNowDateFormat('MM/dd/yyyy HH:mm:ss');
        this.bwEndorsementInfo = new EndorseMetainfo();
        this.bwEndorsementInfo.endorseBound = 'true';
        this.bwEndorsementInfo.endorseType = 'address';
        this._storeService.updateBwEndorsementInfo(this.bwEndorsementInfo);
        this.premiumChangeDisplayMessage();
        this.prepareEndorseReq();
        this.prepareAddressendorseReq();
        this.preparePolicyMailReq();
        this.premiumChangeDisplayMessage();
        this.prepareDairyCommentReqArray(this.endorsementReq.policyID);
        if (this.authenticatedPhDetails.hasRenewal) {
            var nextMod = (Number(this.basicPolicyDetails.policyInfo.MODULE) + 1).toLocaleString(undefined, { minimumIntegerDigits: 2 });
            this.prepareDairyCommentReqArray(this.basicPolicyDetails.policyInfo.SYMBOL + this.basicPolicyDetails.policyInfo.POLICY0NUM + nextMod);
        }
        this.addressEditService.endorseAsyncCalls(this.endorsementReq).then(function () {
        }, function (error) {
            Tracker.logerror('MailingaddrConfirmationComponent', 'Endorse Address', 'acceptAddressChange', 'Endorse ASYNC call service  Error.', error);
        });
    };
    MailingaddrConfirmationComponent.prototype.closeModal = function () {
        this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressConfirmationScreen', 'MailingAddressConfirmationScreenCloseClick', 200);
        this.errorHandler.ClearApplicationError();
        this.addressEditModal.close();
    };
    MailingaddrConfirmationComponent.prototype.navigateToTextAlert = function () {
        this.closeModal();
        this.googleAnalytics.trackEvent('Text Alerts', 'TextAlertPrefernces', 'TextAlertPreferencesClickFromAddressChangeScreen', 200);
        this.router.navigate(['/profile/txtalert']);
    };
    MailingaddrConfirmationComponent.prototype.viewIDCard = function () {
        this.closeModal();
        this.googleAnalytics.trackEvent('IDCard', 'CallingExtream', 'IDCardButtonClickFromAddressChangeScreen', 200);
        this.modalService.open(IdcardComponent);
    };
    // preaare the request for diary comment for both phone and address
    MailingaddrConfirmationComponent.prototype.prepareDairyCommentReqArray = function (policyID) {
        if (this.mailingAddress.isAddressChanged) {
            var dairyAddressCommentReq = new Dairycommentreq();
            this.addressChangeComment = MessageConstants.ADDR_COMMENT_REQ_MSG
                + this.policyInfo.ADD0LINE03 + ', ' + this.policyInfo.PolicyCity + ', ' + this.policyInfo.PolicyState +
                ', ' + this.policyInfo.PolicyZip.substr(0, 5) + ' to ' +
                this.mailingAddress.street + ', ' + this.mailingAddress.city + ', ' + this.mailingAddress.state +
                ', ' + this.mailingAddress.zip + ' on ' + formatDate(new Date(), 'MM/dd/yyyy', 'en-US').split('.')[0]
                + '. Confirmation #' + this.mailingAddress.bindingNumber;
            dairyAddressCommentReq.policyID = policyID;
            dairyAddressCommentReq.mco = this.endorsementReq.mco;
            dairyAddressCommentReq.appName = this.globlalConstants.APPID;
            dairyAddressCommentReq.userID = this.globlalConstants.APPID;
            dairyAddressCommentReq.activityType = this.globlalConstants.COMMENT_REQ_ACTIVITY_TYPE;
            dairyAddressCommentReq.reasonCode = this.globlalConstants.COMMENT_REQ_REASON_CODE;
            dairyAddressCommentReq.destination = this.globlalConstants.COMMENT_REQ_RECYCLE;
            dairyAddressCommentReq.printByte = this.globlalConstants.COMMENT_REQ_PRINT_BYTE;
            dairyAddressCommentReq.prodContext = environment.endorseContext;
            dairyAddressCommentReq.endorseContext = environment.endorseContext;
            // address change comment;
            dairyAddressCommentReq.comment = this.addressChangeComment;
            this.dairyCommentReqs.push(dairyAddressCommentReq);
        }
        // phone number change comment;
        if (this.mailingAddress.isPhoneNumberChanged) {
            var phoneCommentReq = new Dairycommentreq();
            phoneCommentReq.policyID = policyID;
            phoneCommentReq.mco = this.endorsementReq.mco;
            phoneCommentReq.appName = this.globlalConstants.APPID;
            phoneCommentReq.userID = this.globlalConstants.APPID;
            phoneCommentReq.activityType = this.globlalConstants.COMMENT_REQ_ACTIVITY_TYPE;
            phoneCommentReq.reasonCode = this.globlalConstants.COMMENT_REQ_REASON_CODE;
            phoneCommentReq.destination = this.globlalConstants.COMMENT_REQ_RECYCLE;
            phoneCommentReq.printByte = this.globlalConstants.COMMENT_REQ_PRINT_BYTE;
            phoneCommentReq.prodContext = environment.endorseContext;
            phoneCommentReq.endorseContext = environment.endorseContext;
            phoneCommentReq.comment = MessageConstants.PH_COMMENT_REQ_MSG + this.authenticatedPhDetails.phone + ' to ' +
                this.mailingAddress.phone + ' on ' +
                formatDate(new Date(), 'MM/dd/yyyy', 'en-US').split('.')[0] + '. Confirmation #' + this.mailingAddress.bindingNumber;
            this.dairyCommentReqs.push(phoneCommentReq);
        }
        this.endorsementReq.commentRequestArray = this.dairyCommentReqs;
    };
    // this method prepares the policy mail request
    MailingaddrConfirmationComponent.prototype.preparePolicyMailReq = function () {
        this.policymailreq.symbol = this.basicPolicyDetails.policyInfo.SYMBOL;
        this.policymailreq.policy = this.basicPolicyDetails.policyInfo.POLICY0NUM;
        this.policymailreq.mod = this.basicPolicyDetails.policyInfo.MODULE;
        this.policymailreq.mco = this.basicPolicyDetails.policyInfo.MASTER0CO;
        this.policymailreq.midterm = this.globlalConstants.POLICY_EMAIL_TYPE_REG_EMAIL;
        this.policymailreq.state = this.mailingAddress.state;
        this.policymailreq.email = this.authenticatedPhDetails.email;
        this.policymailreq.miscellaneousUse1 = this.mailingAddress.bindingNumber;
        this.policymailreq.miscellaneousUse2 = '';
        this.policymailreq.miscellaneousUse3 = '';
        this.policymailreq.type = this.globlalConstants.POLICY_EMAIL_REQ_TYPE;
        this.policymailreq.status = this.globlalConstants.GP_STATUS_P;
        this.policymailreq.sentCount = 0;
        this.policymailreq.frequency = 1;
        this.policymailreq.firstName = this.authenticatedPhDetails.firstName;
        this.policymailreq.lastName = this.authenticatedPhDetails.lastName;
    };
    // this method prepares the endorse policy request
    MailingaddrConfirmationComponent.prototype.prepareEndorseReq = function () {
        this.endorsementReq.policyID = this.basicPolicyDetails.policyInfo.SYMBOL + this.basicPolicyDetails.policyInfo.POLICY0NUM +
            this.basicPolicyDetails.policyInfo.MODULE;
        this.endorsementReq.mco = this.basicPolicyDetails.policyInfo.PolicyMasterCompany;
        this.endorsementReq.pco = this.basicPolicyDetails.policyInfo.PolicyCompany;
        this.endorsementReq.lob = this.basicPolicyDetails.policyInfo.LINE0BUS;
        this.endorsementReq.appName = this.globlalConstants.APPID;
        this.endorsementReq.userID = this.globlalConstants.APPID;
        this.endorsementReq.changeType = '2';
        this.endorsementReq.policyStatus = this.basicPolicyDetails.policyInfo.PolicyStatus;
        this.endorsementReq.databaseContext = "" + environment.pointContext;
        this.endorsementReq.user = this.globlalConstants.APPID;
        this.endorsementReq.policyMailReqType = this.policymailreq;
        this.endorsementReq.mod = this.basicPolicyDetails.policyInfo.MODULE;
        this.endorsementReq.activityResult = this.globlalConstants.AUDIT_LOG_ACITIVITY_RESULT_S;
        this.endorsementReq.activityMessage = this.globlalConstants.AUDIT_LOG_ACITIVITY_MESSAGE_SUCCESS;
        this.endorsementReq.activity = this.globlalConstants.AUDIT_LOG_ACITIVITY_ADDRESS_CHANGE;
        this.endorsementReq.symbol = this.policyInfo.SYMBOL;
        this.endorsementReq.policyNum = this.basicPolicyDetails.policyInfo.POLICY0NUM;
        this.endorsementReq.state = this.mailingAddress.state;
        this.endorsementReq.binderNumber = this.mailingAddress.bindingNumber;
    };
    //  this funtion prepares the endorse address request
    MailingaddrConfirmationComponent.prototype.prepareAddressendorseReq = function () {
        this.endorseAddressReq.addressLine1 = this.mailingAddress.street;
        this.endorseAddressReq.city = this.mailingAddress.city;
        this.endorseAddressReq.state = this.mailingAddress.state;
        this.endorseAddressReq.zipCode = this.mailingAddress.zip;
        this.endorsementReq.endoseAddress = this.endorseAddressReq;
    };
    //  this function create diaplays message based the premium amount change
    MailingaddrConfirmationComponent.prototype.premiumChangeDisplayMessage = function () {
        var proratedPremium = Number(this.mailingAddress.proratedPremium);
        if (proratedPremium > 0) {
            this.premiumChangeMsg = MessageConstants.ADDR_PREMIUM_INC_NOTIFY_MSG +
                Math.abs(proratedPremium) + '. ' + MessageConstants.ADDR_PREMIUM_UPDATED_PREMIUM
                + '$' + this.mailingAddress.premiumAmount + ' .';
        }
        else if (proratedPremium === 0) {
            this.premiumChangeMsg = MessageConstants.ADDR_PREMIUM_NO_CHANGE_NOTIFY_MSG;
        }
        else if (proratedPremium < 0) {
            this.premiumChangeMsg = MessageConstants.ADDR_PREMIUM_DEC_NOTIFY_MSG +
                Math.abs(proratedPremium) + '. ' + MessageConstants.ADDR_PREMIUM_UPDATED_PREMIUM
                + '$' + this.mailingAddress.premiumAmount + ' .';
        }
        /*
        if (Number(this.policyInfo.PolicyPremium )  >  this.mailingAddress.premiumAmount) {
          this.premiumChangeMsg = MessageConstants.ADDR_PREMIUM_DEC_NOTIFY_MSG +
        (Number(this.policyInfo.PolicyPremium ) - this.mailingAddress.premiumAmount) + '. ' + MessageConstants.ADDR_PREMIUM_UPDATED_PREMIUM
        + '$' + this.mailingAddress.premiumAmount + ' .' ;
        } else if (Number(this.policyInfo.PolicyPremium )  <  this.mailingAddress.premiumAmount) {
          this.premiumChangeMsg = MessageConstants.ADDR_PREMIUM_INC_NOTIFY_MSG +
        (this.mailingAddress.premiumAmount - Number(this.policyInfo.PolicyPremium ) )  + '. ' + MessageConstants.ADDR_PREMIUM_UPDATED_PREMIUM
          + '$' + this.mailingAddress.premiumAmount + ' .';
        } else {
          this.premiumChangeMsg = MessageConstants.ADDR_PREMIUM_NO_CHANGE_NOTIFY_MSG;
        } */
    };
    return MailingaddrConfirmationComponent;
}());
export { MailingaddrConfirmationComponent };
