
<div *ngIf="appMessages && appMessages.length" class="d-flex justify-content-center">
  <!--<button class="clear" (click)="messageService.clear()">clear</button>-->
  <div *ngFor='let message of appMessages; index as i'>
    <ngb-alert *ngIf="i === appMessages.length-1"  [type]="'danger'" role="alert" [dismissible]="false">
        <div class="text-center">
        <div class="error-icon"><i class="fas fa-exclamation-triangle"></i></div>&nbsp;
        <div class="multi_lines_text text-danger inline-icon padding-left" [innerHTML]="message.errordesc | safeHtml" (click)="fetchRoutelink($event)"></div>
      </div>
    </ngb-alert>
  </div>
</div>
