import { Component, OnInit } from '@angular/core';
import { BwstoreService } from '../../shared/services/bwstore.service';
import { FullPolicyDetails, PolicyInfo } from '../../shared/model/api/bwibic/fullpolicydetails.model';

@Component({
  selector: 'app-covid-msg',
  templateUrl: './covid-msg.component.html',
  styleUrls: ['./covid-msg.component.scss']
})
export class CovidMsgComponent implements OnInit {

  basicPolicyDetails: FullPolicyDetails;
  policyInfo: PolicyInfo;
  constructor(private _storeService: BwstoreService) {

    this.basicPolicyDetails = this._storeService.getPolicyInfoFromStore();
    this.policyInfo = this.basicPolicyDetails.policyInfo;

  }

  ngOnInit() {
  }

}
