import { throwError } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { catchError } from 'rxjs/operators';
var BWTokenInterceptor = /** @class */ (function () {
    function BWTokenInterceptor(_authService) {
        this._authService = _authService;
    }
    BWTokenInterceptor.prototype.intercept = function (req, next) {
        // Prepare Header config
        var headerConfig = {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json; charset=utf-8'
        };
        // Get the Token
        var bwtoken = this._authService._bwToken();
        if (bwtoken !== undefined && bwtoken !== null && bwtoken !== '') {
            req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + bwtoken) });
        }
        if (!req.headers.has('Content-Type') && req.url.indexOf('saveDocument') === -1) {
            req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
        }
        req = req.clone({ headers: req.headers.set('Accept', 'application/json'), withCredentials: true });
        // withCredentials added to pass the Cookie to server part of Tealeaf Integration
        return next.handle(req)
            .pipe(catchError(function (error) {
            return throwError(error);
        }));
    };
    return BWTokenInterceptor;
}());
export { BWTokenInterceptor };
