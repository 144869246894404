/// <reference path="../../../../../../node_modules/@types/googlemaps/index.d.ts" />
import { OnInit } from '@angular/core';
var GooglemapComponent = /** @class */ (function () {
    function GooglemapComponent() {
    }
    GooglemapComponent.prototype.ngOnInit = function () {
        this.geocoder = new google.maps.Geocoder();
        var address = '103 South Street, 44024';
        this.geocoder.geocode({ 'address': address }, function (results, status) {
            if (this.status === 'OK') {
                this.map.setCenter(results[0].geometry.location);
                var marker = new google.maps.Marker({
                    map: this.map,
                    position: results[0].geometry.location
                });
            }
            else {
                alert('Geocode was not successful for the following reason: ' + status);
            }
        });
    };
    GooglemapComponent.prototype.viewGoogleMap = function () {
        var mapProp = {
            center: new google.maps.LatLng(18.5793, 73.8143),
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    };
    GooglemapComponent.prototype.initialize = function () {
        this.geocoder = new google.maps.Geocoder();
        var latlng = new google.maps.LatLng(-34.397, 150.644);
        var mapOptions = {
            zoom: 8,
            center: latlng
        };
        this.map = new google.maps.Map(document.getElementById('map'), mapOptions);
    };
    return GooglemapComponent;
}());
export { GooglemapComponent };
