import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BwstoreService } from '../../shared/services/bwstore.service';
import { BwqueriesService } from '../../services/api/bwquery/bwqueries.service';
import { Feedbackreq } from '../../shared/model/api/bwquery/feedbackreq';
import { MessagesService } from '../../shared/services/messages.service';
import { TitleCasePipe } from '@angular/common';
import { Policynumber } from '../../shared/model/policynumber';
import { CommonUtil } from '../../shared/utils/commonutil';
import { MobileNumberValidator } from '../../shared/validators/mobilenumber.validator';
import { GlobalConstants } from '../../constants/global.constant';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  currentRate = 0;
  submitted: Boolean;
  // Reactive Form
  feedbackForm: FormGroup;

  feedbackreq: Feedbackreq;
  response: string;
  polnum: Policynumber;
  policyNumber = '';
  email = '';
  mco = '';
  firstname = '';
  lastname = '';
  useragent: string;
  version: string;
  ratingError = '';
  isReadOnly = false;
  @ViewChild('firstname') firstnameField: ElementRef;
  @ViewChild('ratingLabel') ratingLabelField: ElementRef;
  private globlalConstants = GlobalConstants;

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder,
    private   _storeService: BwstoreService, private _bwQueryService: BwqueriesService,
    private _messageService: MessagesService,
    private titlecasePipe: TitleCasePipe) { }

  ngOnInit() {

    if (this._storeService.getPolicyInfoFromStore().policyInfo !== undefined
    && this._storeService.getPolicyInfoFromStore().policyInfo.PolicyNumber !== undefined) {
      this.polnum = new Policynumber(this._storeService.getPolicyInfoFromStore().policyInfo.PolicyNumber);
      this.policyNumber = this.polnum.toFormattedString();
      this.email = this._storeService.getLoginInfoFromStore().email;
      this.mco = this._storeService.getPolicyInfoFromStore().policyInfo.MASTER0CO;

      this.firstname = this._storeService.currentUserLoginInfo.firstName;
      this.lastname = this._storeService.currentUserLoginInfo.lastName;
      this.isReadOnly = true;
    } else {
      this.policyNumber = '';
      this.email = '';
      this.mco = '';
      this.isReadOnly = false;
    }

    this.createFeedbackForm();
    this.useragent = window.navigator.userAgent;

    if (this.useragent.length > 200) {
      this.useragent = this.useragent.substring(0, 199);
    }
  }
  eventHandler(event: KeyboardEvent) {
    if (event.keyCode === this.globlalConstants.TAB_KEY_CODE) {
      if (event.shiftKey) {
        this.ratingLabelField.nativeElement.focus();
        return;
      }
      this.firstnameField.nativeElement.focus();
    }
  }
  onSubmit(formData: any) {
    this.submitted = true;

    if (this.feedbackForm.valid) {
      const datetime = CommonUtil.getDateTimeNowEST();

      const polnum = new Policynumber(formData.phpolicynum);
      this.feedbackreq = new Feedbackreq();
      this.feedbackreq.emailAddress = this.email;
      this.feedbackreq.enterdate = datetime;
      this.feedbackreq.feedbackComments = formData.feedback;
      this.feedbackreq.feedbackType = 'BWCSS';
      this.feedbackreq.firstName = formData.firstname;
      this.feedbackreq.lastName = formData.lastname;
      this.feedbackreq.mco = this.mco;
      this.feedbackreq.phoneNum = (formData.phonenumber !== null ? formData.phonenumber : '');
      this.feedbackreq.policy = polnum.toString();
      this.feedbackreq.rating = formData.currentRate;
      this.feedbackreq.userAgent = this.useragent;
      this.feedbackreq.version = '1.0.0.0';

      this._bwQueryService.saveFeedback(this.feedbackreq).subscribe(iddata => {
        this.response = iddata;
      },
      error => {
        console.error('payment-ngOnInit', error);
      });
    } else {
      this.ratingError = this._messageService.getErrorMessages(this.feedbackForm.controls['currentRate'].errors, 'currentRate');
      this.submitted = false;
    }
  }

  createFeedbackForm() {
    this.feedbackForm = this.fb.group({
      'currentRate' : [null, Validators.compose([Validators.required])],
      'firstname' : [this.titlecasePipe.transform(this.firstname)],
      'lastname' : [this.titlecasePipe.transform(this.lastname)],
      'phonenumber' : [null],
      'feedback' : ['']
    });
  }

  // Form validations
  isFieldValid(field: string) {
    return (
      (!this.feedbackForm.get(field).valid && this.feedbackForm.get(field).touched) ||
      (this.feedbackForm.get(field).untouched && this.submitted)
    );
  }

  getErrMessage(errors, formCntrlName: string) {
    return this._messageService.getErrorMessages(errors, formCntrlName);
  }
}
