import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalConstants } from '../../../constants/global.constant';
import { SignOn, Validatecardreq } from '../../../shared/model/api/ivrpayment/validatecardreq.model';

import { Policynumber } from '../../../shared/model/policynumber';
import { CommonUtil } from '../../../shared/utils/commonutil';
import { BwappService } from '../../bwapp.service';
import { BWErrorhandler } from '../../errorhandler';
import { EndorsementReq } from '../../../shared/model/api/endorsement/endorsement-req';
import { EndorsementRes } from '../../../shared/model/api/endorsement/endorsement-res';
import { Dairycommentreq } from '../../../shared/model/api/policymgmt/dairycommentreq.model';

import { validateStyleParams } from '@angular/animations/browser/src/util';
import { Tracker } from '../../../shared/utils/tracker';
import { KonckoutRulesReq } from '../../../shared/model/api/knockout/konckout-rules-req';
import { EndorsementSaveReq } from '../../../shared/model/api/endorsement/endorsement-save-req.model';
import { EndorsementBindReq } from '../../../shared/model/api/endorsement/endorsement-bind-req.model';
import { EndorsementVoidReq } from '../../../shared/model/api/endorsement/endorsement-void-req.model';
import { EndorseMetainfo } from '../../../shared/model/endorsements/endorse-metainfo.model';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EndorsementService {

  private globlalConstants = GlobalConstants;
  policyNumber: Policynumber;

  constructor(private appService: BwappService, private errorHandler: BWErrorhandler) { }

   endosePolicy(endorsementReq: EndorsementReq): Observable<EndorsementRes> {
    return this.appService.post(this.globlalConstants.ENDORSE_POLICY, endorsementReq);
   }
   saveChangesToPolicy (endorsementReq: EndorsementReq): Observable<EndorsementRes> {
    return this.appService.post(this.globlalConstants.ENDORSE_SAVE_CHANGES_TO_POLICY, endorsementReq);
   }

   endorseBind (endorsementReq: EndorsementReq): Observable<EndorsementRes> {
    return this.appService.post(this.globlalConstants.ENDORSE_BIND, endorsementReq);
   }

   endorseVoid (endorsementReq: EndorsementReq): Observable<EndorsementRes> {
    return this.appService.post(this.globlalConstants.ENDORSE_VOID, endorsementReq);
   }

   endorseAsyncCalls (endorsementReq: EndorsementReq): Observable<EndorsementRes> {
    return this.appService.post(this.globlalConstants.ENDORSE_ASYNC, endorsementReq);
   }
   isEligibleForEndorsement (endorsementReq: EndorsementReq): Observable<EndorsementRes> {
    return this.appService.post(this.globlalConstants.PLCY_MGMT_IS_ELIGIBLE_FOR_ENDORSEMENT, endorsementReq);
   }
   createAuditLogRecord (endorsementReq: EndorsementReq): Observable<any> {
    return this.appService.post(this.globlalConstants.AUDIT_LOG_SERVICE, endorsementReq);
   }

   auditLogRecord(logReq: EndorsementReq): Observable<string> {
    Tracker.loginfo('PolicymgtService', 'auditLogRecord', '', 'auditLogRecord for the policy  ' +
    logReq.policyID);
    return this.appService.post(this.globlalConstants.AUDIT_LOG, logReq);
  }

  // Replace Vehicle, Add Vehicle, Add Coverage, Update Coverage, Remove Coverage, Add Driver, Edit Driver, Remove Driver Endorsement
  processEndorsePolicy(endorsementSaveReq: EndorsementSaveReq): Observable<EndorsementRes> {
    return this.appService.post(this.globlalConstants.BW_ENDORSE_SAVE, endorsementSaveReq);
  }

  processEndorseBind(endorsementBindReq: EndorsementBindReq): Observable<EndorsementRes> {
    return this.appService.post(this.globlalConstants.BW_ENDORSE_BIND, endorsementBindReq);
  }

  processEndorseVoid(bwEndorsementInfo: EndorseMetainfo, msg: string) {
    const voidReq: EndorsementVoidReq = this.prepareEndorseVoidReq(bwEndorsementInfo, msg);
    console.log('Void Req : ', JSON.stringify(voidReq));
    return this.appService.post(this.globlalConstants.BW_ENDORSE_VOID, voidReq);
 }


 prepareEndorseVoidReq(bwEndorsementInfo: EndorseMetainfo, msg: string): EndorsementVoidReq {
  const endorsevoidReq = new EndorsementVoidReq();

  endorsevoidReq.policyID = bwEndorsementInfo.endorsePolicyNum;
  endorsevoidReq.mco = bwEndorsementInfo.endorseMco;
  endorsevoidReq.state = bwEndorsementInfo.endorseState;

  endorsevoidReq.binderNumber = bwEndorsementInfo.endorseBinderNum;
  endorsevoidReq.changeTypeCd = bwEndorsementInfo.endorseTypeCd;

  endorsevoidReq.appName = GlobalConstants.APPID;
  endorsevoidReq.userID = GlobalConstants.APPID;

  endorsevoidReq.databaseContext =  environment.pointContext;
  endorsevoidReq.endorseContext = bwEndorsementInfo.endorseContext;

  endorsevoidReq.notes = '';
  endorsevoidReq.reason = msg;

  return endorsevoidReq;
}

  // will move to knock out service in next commit
   applyknockoutRules(konckoutRulesReq: KonckoutRulesReq): Observable<any> {
    return this.appService.post(this.globlalConstants.APPLY_KNOCKOUT_RULES, konckoutRulesReq);
   }
  }
