import { formatDate } from '@angular/common';
import { MessageConstants } from '../../constants/message.constant';
import { DocusignService } from '../../services/api/esig/docusign.service';
import { EsigService } from '../../services/api/esig/esig.service';
import { BWErrorhandler } from '../../services/errorhandler';
import { BWError } from '../model/error.model';
import { EsigMessages } from '../model/esignmessages.model';
import { CommonUtil } from '../utils/commonutil';
import { AuthenticationService } from './authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "./authentication.service";
import * as i2 from "../../services/api/esig/esig.service";
import * as i3 from "../../services/api/esig/docusign.service";
import * as i4 from "../../services/errorhandler";
var EsigmessagesService = /** @class */ (function () {
    function EsigmessagesService(authService, esigService, docuSignService, errorHandler) {
        this.authService = authService;
        this.esigService = esigService;
        this.docuSignService = docuSignService;
        this.errorHandler = errorHandler;
    }
    EsigmessagesService.prototype.verifyEsigStatus = function (phuser) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var esignMessages = new EsigMessages();
            _this.getEnvelopes(phuser).then(function (envelopes) {
                // Tracker.log('ESIG MESSAGES Envelopes from SubMethod ', envelopes);
                if (envelopes && envelopes.length > 0) {
                    esignMessages = CommonUtil.getPendingEnvelopeMessage(envelopes);
                    var docId = esignMessages.envelopeId.concat(esignMessages.recepientId);
                    if (esignMessages && esignMessages.hasPendingEnvelops === true && docId !== phuser.processedDocId) {
                        if (esignMessages.recepientType === 'PrimaryNamedInsured') {
                            _this.docuSignService.getCeremonyURL(esignMessages.envelopeId, esignMessages.recepientId)
                                .subscribe(function (urlData) {
                                // Tracker.log('2. ESIG MESSAGES : Invoked docuSignService.getCeremonyURL :::', urlData);
                                if (urlData) {
                                    if (phuser.stateMIHasEnvelope && phuser.hasRenewal) {
                                        esignMessages.envMessage = MessageConstants.ESIG_PENDING_STATE_MESSAGE.replace('{0}', formatDate(phuser.renewalPolEffDate, 'MM/dd/yyyy', 'en-US'));
                                    }
                                    else {
                                        esignMessages.envMessage = MessageConstants.ESIG_PENDING_PNI_MESSAGE.replace('{0}', formatDate(esignMessages.envelopeExpiryDate, 'MM/dd/yyyy', 'en-US'));
                                    }
                                    // const docsignpath = 'docusign?p=' + docId; // TEMP
                                    esignMessages.ceremonyURL = urlData.url.value; // `${environment.apiServiceUrl}${docsignpath}` ;
                                    // Tracker.log('Docusign URL ....................... ', urlData.url.value);
                                    esignMessages.envMessageId = 'DOCUSIGN';
                                    resolve(esignMessages);
                                }
                                else {
                                    return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '100', 'errordesc': MessageConstants.RESPCODE_100, 'source': 'DocuSignService' })));
                                }
                            });
                        }
                        else {
                            esignMessages.envMessageId = 'LOGIN';
                            esignMessages.envMessage = MessageConstants.ESIG_PENDING_SEC_MESSAGE.replace('{0}', formatDate(esignMessages.envelopeExpiryDate, 'dd/MM/yyyy', 'en-US'));
                            resolve(esignMessages);
                        }
                    }
                    else {
                        esignMessages.envMessageId = 'LOGIN';
                        esignMessages.hasPendingEnvelops = false;
                        esignMessages.envMessage = 'Congratulations! There are no more pending documents. Please proceed to Login';
                        resolve(esignMessages);
                    }
                }
                else {
                    esignMessages.envMessageId = 'LOGIN';
                    esignMessages.hasPendingEnvelops = false;
                    esignMessages.envMessage = 'Congratulations! There are no more pending documents. Please proceed to Login';
                    resolve(esignMessages);
                }
            });
        });
    };
    EsigmessagesService.prototype.getEnvelopes = function (phuser) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.authService.getEnvelopesSub.subscribe(function (envelopes) {
                // Tracker.log('1. ESIG MESSAGES : Invoked authService.getEnvelopesSub :::', envelopes);
                if (envelopes && envelopes.length > 0) {
                    resolve(envelopes);
                }
                else {
                    var mco = phuser.pointHookID.substr(0, 2);
                    var symbol = phuser.pointHookID.substr(2, 3);
                    var policynum = phuser.pointHookID.substr(5);
                    var policymod = phuser.policyMod;
                    var policyNumber = symbol.concat(policynum).concat(policymod);
                    if (phuser.esigstatus === true) {
                        _this.esigService.getEnvelops(phuser.firstName, phuser.lastName, policyNumber).subscribe(function (envelopsres) {
                            // Tracker.log('1. ESIG MESSAGES : Invoked esigService.getEnvelops :::', envelopsres);
                            if (envelopsres && envelopsres.status.toUpperCase() === 'SUCCESS' && envelopsres.envelopes.length > 0) {
                                resolve(envelopsres.envelopes);
                            }
                            else {
                                resolve([]);
                            }
                        });
                    }
                    else {
                        resolve([]);
                    }
                }
            }, function (error) {
                // Tracker.log('Error occured reading the Envelopes Subject ', error);
            });
        });
    };
    EsigmessagesService.ngInjectableDef = i0.defineInjectable({ factory: function EsigmessagesService_Factory() { return new EsigmessagesService(i0.inject(i1.AuthenticationService), i0.inject(i2.EsigService), i0.inject(i3.DocusignService), i0.inject(i4.BWErrorhandler)); }, token: EsigmessagesService, providedIn: "root" });
    return EsigmessagesService;
}());
export { EsigmessagesService };
