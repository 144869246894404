import { ReviewsService } from '../api/reviews/reviews.service';
import * as i0 from "@angular/core";
import * as i1 from "../api/reviews/reviews.service";
var CustomerReviewsService = /** @class */ (function () {
    function CustomerReviewsService(reviewsService) {
        this.reviewsService = reviewsService;
    }
    CustomerReviewsService.prototype.getCustomerReviews = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.reviewsService.getReviews().subscribe(function (res) {
                resolve(res);
            }, function (error) {
                reject(error);
            });
        });
    };
    CustomerReviewsService.ngInjectableDef = i0.defineInjectable({ factory: function CustomerReviewsService_Factory() { return new CustomerReviewsService(i0.inject(i1.ReviewsService)); }, token: CustomerReviewsService, providedIn: "root" });
    return CustomerReviewsService;
}());
export { CustomerReviewsService };
