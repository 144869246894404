import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalConstants } from '../../../constants/global.constant';
import { Uploadfiletranstyperes } from '../../../shared/model/api/document/uploadfiletranstyperes.model';
import { MessagesService } from '../../../shared/services/messages.service';
import { BwappService } from '../../bwapp.service';
import { HttpParams } from '@angular/common/http';
import { Ezltranscodereq } from '../../../shared/model/api/document/ezltranscodereq.model';
import { Ezltranscoderes } from '../../../shared/model/api/document/ezltranscoderes.model';
import { Taskinjrecreq } from '../../../shared/model/api/document/taskinjrecreq.model';
import { Taskinjrecres } from '../../../shared/model/api/document/taskinjrecres.model';
import { Docuploadauditreq } from '../../../shared/model/api/document/docuploadauditreq.model';
import { Docuploadauditres } from '../../../shared/model/api/document/docuploadauditres.model';

@Injectable({
  providedIn: 'root'
})
export class UploadfileService {

  private globlalConstants = GlobalConstants;

  constructor(private appService: BwappService) { }

  getTranasctionTypes(mco: string, statecd: string): Observable<Uploadfiletranstyperes> {

    const params = new HttpParams()
    .set('reqMCO', mco)
    .set('reqState', statecd)
    .set('reqUserType', 'INS');

    return this.appService.get(this.globlalConstants.GET_DOCS_TYPES, params);
  }

  getEasyLinkTransactionCode(transCodeReq: Ezltranscodereq): Observable<Ezltranscoderes> {
    return this.appService.post(this.globlalConstants.GET_EZL_TRAN_CODE, transCodeReq);
  }

  createTaskInjectorRecord(taskInjReq: Taskinjrecreq): Observable<Taskinjrecres> {
    return this.appService.post(this.globlalConstants.CREATE_TASKINJ_REC, taskInjReq);
  }

  createUploadAuditRecord(auditReq: Docuploadauditreq): Observable<Docuploadauditres> {
    return this.appService.post(this.globlalConstants.CREATE_UPLOAD_AUDIT, auditReq);
  }



}
