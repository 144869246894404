import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GlobalConstants } from '../../../constants/global.constant';
import { NewPaymentRequest, NewPaymentResponse } from '../../../shared/model/new-payment.model';

@Injectable({
  providedIn: 'root'
})
export class NewPaymentService {

  
  constructor(private http:HttpClient) { }

  makeNewPayment(paymentRequest : any):Observable<any>{
    let url:string = `${environment.apiServiceUrl}${GlobalConstants.MAKE_NEW_PAYMENT_URL}`;
    return  this.http.post(url,paymentRequest); 
  }
}
