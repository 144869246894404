import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalConstants } from '../../constants/global.constant';
import { MessageConstants } from '../../constants/message.constant';
import { BasicPolicyContract } from '../../shared/model/api/bwquery/basiccontract.model';
import { Getpolicybyemailres } from '../../shared/model/api/bwsecurity/getpolicybyemailres';
import { CheckRegistrationStatusResponse } from '../../shared/model/api/bwsecurity/registrationstatusres.model';
import { BWSecurityValidateUserRes } from '../../shared/model/api/bwsecurity/validatenewuserres.model';
import { EmailValidateResponse } from '../../shared/model/api/emailvalidate/emailvalres.model';
import { EnvelopesResp } from '../../shared/model/api/esig/esigenvelopres.model';
import { IsESigImplementedResp } from '../../shared/model/api/esig/esigimplres.model';
import { UpdateEpolicyEmailResponse } from '../../shared/model/api/gopaperless/updateemailres.model';
import { BWError } from '../../shared/model/error.model';
import { NewUser } from '../../shared/model/newuser.model';
import { Changeuseremailres } from '../../shared/model/registration/changeuseremailres';
import { FindPhRequest } from '../../shared/model/registration/findphreq.model';
import { FindPhResponse } from '../../shared/model/registration/findphres.model';
import { InsertPhResponse } from '../../shared/model/registration/insertphres.model';
import { NewUserRequest } from '../../shared/model/registration/newuserreq.model';
import { NewuserResponse } from '../../shared/model/registration/newuserres.model';
import { Newuserssores } from '../../shared/model/registration/newuserssores';
import { RegistrationStatus } from '../../shared/model/registration/registrationstatus.model';
import { GoogleAnalyticsService } from '../../shared/services/analytics/google-analytics.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { BwqueriesService } from '../api/bwquery/bwqueries.service';
import { BwSecurityService } from '../api/bwsecurity/bw-security.service';
import { EmailvalidateService } from '../api/emailvalidate/emailvalidate.service';
import { EsigService } from '../api/esig/esig.service';
import { GoPaperlessService } from '../api/gopaperless/go-paperless.service';
import { BwappService } from '../bwapp.service';
import { BWErrorhandler } from '../errorhandler';
import { PolicyHolder } from '../../shared/model/authentication/authenticatephres';
import { BwstoreService } from '../../shared/services/bwstore.service';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerRegistrationService {

  private globlalConstants = GlobalConstants;

  findPhResponse: FindPhResponse = new FindPhResponse();

  newUserResponse: NewuserResponse = new NewuserResponse();

  insertPhResponse: InsertPhResponse = new InsertPhResponse();

  private newuserssores: Newuserssores;
  private changeuseremailres: Changeuseremailres;
  private getpolicybyemailres: Getpolicybyemailres;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'my-auth-token'
    })
  };

  constructor(private appService: BwappService, private bwqueryService: BwqueriesService,
    private bwsecurityService: BwSecurityService, private esigService: EsigService,
    private gopaperlessService: GoPaperlessService, private emailValidateService: EmailvalidateService,
    private authService: AuthenticationService,  private storeService: BwstoreService,
    private errorHandler: BWErrorhandler,
    private googleAnalytics: GoogleAnalyticsService, private title: Title) {
      title.setTitle('Bristol West Policy - Register');
    }

    // TODO: Unused function, can be removed
    validateNewUser(newUser: NewUserRequest): Observable<NewuserResponse> {
    return this.appService.post(this.globlalConstants.PH_VALIDATE_URL, newUser)
      .pipe(
        map(data => {
          this.newUserResponse = data;
          // Tracker.log('Response form the BWQuery Service ::: ', this.newUserResponse);
          return this.newUserResponse;
        })
      );
  }

  validateNewUser_POINT(newUserReq: NewUserRequest): Promise<NewuserResponse> {

    return new Promise((resolve, reject) => {
    this.bwqueryService.getPolicyBasicContract(newUserReq.policynumber).subscribe((basicPolicyContract: BasicPolicyContract) => {
      // Tracker.log('1. REGISTRATION STEP 1 : Invoking bwqueryService.getPolicyBasicContract :::: ', basicPolicyContract);
      if (basicPolicyContract) {
        // const policyHolder =  new PolicyHolder();
        // policyHolder.jwtToken = basicPolicyContract.jwtToken;
        // this.storeService.createLoginInfo(policyHolder);

        const mco = basicPolicyContract.basicPlcyContractInfo.MASTER0CO;
        const stateCd = basicPolicyContract.basicPlcyContractInfo.RISK0STATE;
        this.newUserResponse.stateCd = stateCd; // keerthana
        this.newUserResponse.hasRenewal = basicPolicyContract.basicPlcyContractInfo.ISSUE0CODE; // keerthana
      
        // Check for CA44
        /*if (GlobalConstants.STATECD_04 === stateCd && GlobalConstants.MCO_44 === mco) {
          this.newUserResponse.ca44 = true;
          return reject(this.errorHandler.handleApplicationError(new BWError(
            {'errorcode': '44', 'errordesc': MessageConstants.CA44_ERROR, 'source': 'RegistrationService'})));
        } else*/
        if (GlobalConstants.STATECD_04 === stateCd && GlobalConstants.MCO_44 === mco) {
          this.newUserResponse.ca44 = true;
          return reject(new BWError(
            {'errorcode': '44', 'errordesc': MessageConstants.FARMERS_SPECILATY_URL, 'source': 'LoginService'}));
        } else if ((GlobalConstants.STATECD_04 === stateCd && GlobalConstants.MCO_91 === mco) ||
            (GlobalConstants.STATECD_04 === stateCd && GlobalConstants.MCO_92 === mco) ||
            (GlobalConstants.STATECD_52 === stateCd && GlobalConstants.MCO_90 === mco))  {
          return reject(new BWError(
            {'errorcode': '21C', 'errordesc': MessageConstants.BRISTOLWEST_URL, 'source': 'LoginService'}));
        }
        if (typeof basicPolicyContract.basicPlcyContractInfo.FILLR1 !== 'undefined' ) {
        this.newUserResponse.producercode = basicPolicyContract.basicPlcyContractInfo.FILLR1
        .concat(basicPolicyContract.basicPlcyContractInfo.RPT0AGT0NR)
        .concat(basicPolicyContract.basicPlcyContractInfo.FILLR2);
        }

        this.bwsecurityService.validateNewUserPOINT(newUserReq.policynumber, newUserReq.phdob, newUserReq.phzipcode)
          .subscribe((validateUserRes: BWSecurityValidateUserRes) => {
            // Tracker.log('2. REGISTRATION STEP 1 : Invoking bwsecurityService.validateNewUserPOINT :::: ', validateUserRes);

            // Check for missed data
            if (validateUserRes && (validateUserRes.returnCode === null || validateUserRes.returnCode === '')) {
              this.googleAnalytics.trackEvent('Registration', 'UserRegistrationScreen', 'WrongPolicyNumberRegisterAttempt', 500);
              return reject(this.errorHandler.handleApplicationError(new BWError(
                {'errorcode': '98', 'errordesc': MessageConstants.RESPCODE_90, 'source': 'RegistrationService'})));
            } else if (validateUserRes && validateUserRes.returnCode === '98') {
              this.googleAnalytics.trackEvent('Registration', 'UserRegistrationScreen', 'WrongDetailsRegisterAttempt', 500);
              return reject(this.errorHandler.handleApplicationError(new BWError(
                {'errorcode': '98', 'errordesc': MessageConstants.RESPCODE_98, 'source': 'RegistrationService'})));
            } else if (validateUserRes && validateUserRes.returnCode === '11') { // DOB Mismatch
              this.googleAnalytics.trackEvent('Registration', 'UserRegistrationScreen', 'WrongDOBRegisterAttempt', 500);
              return reject(this.errorHandler.handleApplicationError(new BWError(
                {'errorcode': '98', 'errordesc': MessageConstants.RESPCODE_11, 'source': 'RegistrationService'})));
            } else if (validateUserRes && validateUserRes.returnCode === '10') { // ZIPCODE Mismatch
              this.googleAnalytics.trackEvent('Registration', 'UserRegistrationScreen', 'WrongZipRegisterAttempt', 500);
              return reject(this.errorHandler.handleApplicationError(new BWError(
                {'errorcode': '98', 'errordesc': MessageConstants.RESPCODE_10, 'source': 'RegistrationService'})));
            } else if (validateUserRes && validateUserRes.returnCode === '90') { // POLICYNUMBER Mismatch
              this.googleAnalytics.trackEvent('Registration', 'UserRegistrationScreen', 'WrongPolicyNumberRegisterAttempt', 500);
              return reject(this.errorHandler.handleApplicationError(new BWError(
                {'errorcode': '98', 'errordesc': MessageConstants.RESPCODE_90, 'source': 'RegistrationService'})));
            } else if (validateUserRes
              && validateUserRes.returnCode === '00'
              && validateUserRes.lastName.toLocaleUpperCase() !== newUserReq.phlastname.toLocaleUpperCase()) { // LASTNAME Mismatch
              this.googleAnalytics.trackEvent('Registration', 'UserRegistrationScreen', 'WrongLastNameRegisterAttempt,', 500);
              return reject(this.errorHandler.handleApplicationError(new BWError(
                {'errorcode': '98', 'errordesc': MessageConstants.RESPCODE_91, 'source': 'RegistrationService'})));
            } else if (validateUserRes
              && validateUserRes.returnCode !== null
              && validateUserRes.returnCode !== undefined
              && validateUserRes.returnCode !== ''
              && validateUserRes.returnCode !== '00') {
                this.googleAnalytics.trackEvent('Registration', 'UserRegistrationScreen', 'WrongDetailsRegisterAttempt', 500);
              return reject(this.errorHandler.handleApplicationError(new BWError(
                {'errorcode': '98', 'errordesc': MessageConstants.RESPCODE_98, 'source': 'RegistrationService'})));
            }

            if (validateUserRes && validateUserRes.returnCode === '00') {
              this.bwsecurityService.checkRegistrationStatus(mco, newUserReq.policynumber)
                .subscribe((regstatusres: CheckRegistrationStatusResponse) => {
                  // Tracker.log('3. REGISTRATION STEP 1 : Invoking bwsecurityService.checkRegistrationStatus :::: ', regstatusres);
                  if (regstatusres && regstatusres.checkRegistrationStatusResult ) {
                    return reject(this.errorHandler.handleApplicationError(new BWError(
                      {'errorcode': '98', 'errordesc': MessageConstants.RESPCODE_97, 'source': 'RegistrationService'})));
                  } else {
                    this.newUserResponse.firstName = validateUserRes.firstName;
                    this.newUserResponse.lastName = validateUserRes.lastName;
                    this.newUserResponse.mco = validateUserRes.mco;
                    this.newUserResponse.phemail = validateUserRes.email;

                    // Call Esig Service
                    this.esigService.isESigImplemented(mco, stateCd).subscribe((esigimplresp: IsESigImplementedResp) => {
                      // Tracker.log('4. REGISTRATION STEP 1 : Invoking esigService.isESigImplemented :::: ', esigimplresp);

                      if (esigimplresp && esigimplresp.status !== 'FAILURE' && esigimplresp.isImplemented === true) {
                        this.esigService.getEnvelops(validateUserRes.firstName, validateUserRes.lastName, newUserReq.policynumber)
                        .subscribe((envelopsres: EnvelopesResp) => {
                          // Tracker.log('5. REGISTRATION STEP 1 : Invoking esigService.getEnvelops :::: ', envelopsres);

                          let hasEnvelops = false;
                          if (envelopsres && envelopsres.status.toUpperCase() === 'SUCCESS') {
                            this.authService.envelopesSubject.next(envelopsres.envelopes);
                            envelopsres.envelopes.forEach(env => {
                              // Tracker.log('Looping Envelop elements::: ', env);
                              env.recipients.forEach(rec => {
                                // Tracker.log('Looping Recipient elements::: ', rec);
                                if (rec.recipType === 'PrimaryNamedInsured') {
                                  if (rec.status === 'Completed' || rec.status === 'Declined' || rec.status === 'Signed') {
                                    hasEnvelops = false;
                                  } else {
                                    hasEnvelops = true;
                                    this.newUserResponse.envelopId = env.envelopeID;
                                    this.newUserResponse.recipientId = rec.recipientID;
                                    return hasEnvelops;
                                  }
                                }
                              });
                            });
                          }

                          this.newUserResponse.esigstatus = hasEnvelops;
                          // Tracker.log('Verified Envelops ', hasEnvelops);

                          resolve(this.newUserResponse);
                        });
                      } else {
                        this.newUserResponse.esigstatus = false;
                        resolve(this.newUserResponse);
                      }
                    });
                  }
              });
            }
          });

      }
    },
    error => {
        // Tracker.log('Error Occurred in ValidateNewUser_POINT', error);
        return reject(error);
   });
  });
  }

  updateEpolicyEmail(email: string, policynum: string, mco: string, producercode: string): Promise<UpdateEpolicyEmailResponse> {
    return new Promise((resolve, reject) => {

      let isBwOktaEnabled : boolean = environment.bwOktaEnabled;
      this.bwsecurityService.getpolicyByEmail(email).subscribe((emailres: Getpolicybyemailres) => {
        // Tracker.log('1. REGISTRATION STEP 2 : Invoking bwsecurityService.getpolicyByEmail :::: ', emailres);
        if (emailres && emailres.polNum !== undefined && emailres.polNum !== null && emailres.polNum !== '' && !isBwOktaEnabled) {
          
          return reject(this.errorHandler.handleApplicationError(new BWError(
            {'errorcode': '100', 'errordesc': MessageConstants.EMAIL_REGISTERED, 'source': 'BWSecurityService'})));
        } else {
          this.emailValidateService.validateEmail(email, policynum, mco, producercode).subscribe((validateres: EmailValidateResponse) => {
            // Tracker.log('2. REGISTRATION STEP 2 : Invoking emailValidateService.validateEmail :::: ', validateres);
            console.log("updateEppolicyEmail ::"+validateres);
            if (validateres && (validateres.status === 'VALID'
            || validateres.status === 'ERROR_SERVICE'
            || validateres.status === 'ERROR_VENDOR'
            || validateres.responseCode === 'E0000007'
            || validateres.responseCode === 'E')
            ) {
              console.log(validateres);
              this.gopaperlessService.updateEPolicyEmail(email, policynum, mco).subscribe((res: UpdateEpolicyEmailResponse) => {
                // Tracker.log('3. REGISTRATION STEP 2 : Invoking gopaperlessService.updateEPolicyEmail :::: ', res);
                return resolve(res);
              });
            } else {
              if(environment.bwOktaEnabled){

                return reject(this.errorHandler.handleApplicationError(new BWError(
                  {'errorcode': '100', 'errordesc': (validateres.responseText === 'invalid' ? MessageConstants.INVALID_EMAIL :MessageConstants.EMAIL_REGISTERED), 'source': 'EmailValidateService'})));
              }else{
                return reject(this.errorHandler.handleApplicationError(new BWError(
                  {'errorcode': '100', 'errordesc': MessageConstants.INVALID_EMAIL, 'source': 'EmailValidateService'})));
                
              }
            }
          });
        }
      },
      error => {
        // Tracker.log('Error Occurred in updateEpolicyEmail', error);
        return reject(error);
      }
     );
    });
  }

  validateEmail(email: string, policynum: string, mco: string, producercode: string): Promise<EmailValidateResponse> {
    return new Promise((resolve, reject) => {

      this.bwsecurityService.getpolicyByEmail(email).subscribe((emailres: Getpolicybyemailres) => {
        // Tracker.log('1. REGISTRATION STEP 2 : Invoking bwsecurityService.getpolicyByEmail :::: ', emailres);
        let isBwOktaEnabled : boolean = environment.bwOktaEnabled;
        if (emailres && emailres.polNum !== undefined && emailres.polNum !== null && emailres.polNum !== '' && !isBwOktaEnabled) {
          return reject(this.errorHandler.handleApplicationError(new BWError(
            {'errorcode': '100', 'errordesc': MessageConstants.EMAIL_REGISTERED, 'source': 'BWSecurityService'})));
        } else {
          this.emailValidateService.validateEmail(email, policynum, mco, producercode).subscribe((validateres: EmailValidateResponse) => {
            // Tracker.log('2. REGISTRATION STEP 2 : Invoking emailValidateService.validateEmail :::: ', validateres);
            if (validateres && (validateres.status === 'VALID'
            || validateres.status === 'ERROR_SERVICE'
            || validateres.status === 'ERROR_VENDOR'
            || validateres.responseCode === 'E0000007'
            || validateres.responseCode === 'E')
            ) {
              console.log("validateEmail :: customer-registration");
              return resolve(validateres);
            } else {
              if(environment.bwOktaEnabled){

                return reject(this.errorHandler.handleApplicationError(new BWError(
                  {'errorcode': '100', 'errordesc': (validateres.responseText === 'invalid' ? MessageConstants.INVALID_EMAIL :MessageConstants.EMAIL_REGISTERED), 'source': 'EmailValidateService'})));
              }else{
                return reject(this.errorHandler.handleApplicationError(new BWError(
                  {'errorcode': '100', 'errordesc': MessageConstants.INVALID_EMAIL, 'source': 'EmailValidateService'})));
                
              }
              // return reject(this.errorHandler.handleApplicationError(new BWError(
              //   {'errorcode': '100', 'errordesc': MessageConstants.INVALID_EMAIL, 'source': 'EmailValidateService'})));
            }
          });
        }
      },
      error => {
        // Tracker.log('Error Occurred in updateEpolicyEmail', error);
        return reject(error);
      }
     );
    });
  }

  lookupPolicyHolder(phrequest: FindPhRequest): Observable<FindPhResponse> {

    // Tracker.log('Passed Value to Service : ' + phrequest.pointHookID);
    if (phrequest != null && phrequest.pointHookID === 'nadella') {
      this.findPhResponse.policyMatch = true;
      this.findPhResponse.responseCode = '100';

    } else {
      this.findPhResponse.policyMatch = false;
      this.findPhResponse.responseCode = '101';

    }
    return of(this.findPhResponse);
  }

  updateRegistrationState(newUser: NewUser) {

  }

  registerNewUser(page: string, ecstatus: string, esignstatus: boolean): Promise<RegistrationStatus> {
    return new Promise((resolve, reject) => {
      const registrationStatus = new RegistrationStatus();

      this.bwsecurityService.addNewUserSSO().subscribe((data: Newuserssores) => {

        if (data.szResponseCode !== '-1') {
          registrationStatus.status = false;
          registrationStatus.navigateTo = 'MODAL';
          if (page && page === 'EC' && ecstatus === 'true') {
            this.bwsecurityService.changeUserEmail_POINT(ecstatus).subscribe((emailchangeres: Changeuseremailres) => {
              // Tracker.log('Email Optout Updated in POINT');
              if (emailchangeres) {
                resolve(registrationStatus);
              } else {
                  return reject(this.errorHandler.handleApplicationError(new BWError(
                    {'errorcode': 'EMAIL', 'errordesc': MessageConstants.EMAIL_UPDATE_ERROR, 'source': 'BWSecurityService'})));
              }
            });
          } else {
            resolve(registrationStatus);
          }

        } else if (esignstatus) {
          registrationStatus.status = false;
          registrationStatus.navigateTo = 'ESIG';
          resolve(registrationStatus);
        } else {
          registrationStatus.status = true;
          registrationStatus.navigateTo = 'REGSUCCESS';
          resolve(registrationStatus);
        }
      },
      error => {
        return reject(this.errorHandler.handleApplicationError(new BWError(
          {'errorcode': '100', 'errordesc': MessageConstants.RESPCODE_100, 'source': 'BWSecurityService'})));
      });
    });
  }


  checkRegistrationStatus(mco: string, policynumber: string): Promise<CheckRegistrationStatusResponse> {
    return new Promise((resolve, reject) => {
      this.bwsecurityService.checkRegistrationStatus(mco, policynumber)
      .subscribe((regstatusres: CheckRegistrationStatusResponse) => {
        // Tracker.log('1. Invoking bwsecurityService.checkRegistrationStatus :::: ', regstatusres);
        resolve(regstatusres);
      },
      error => {
        return reject(this.errorHandler.handleApplicationError(new BWError(
          {'errorcode': '100', 'errordesc': MessageConstants.RESPCODE_100, 'source': 'BWSecurityService'})));
      });
    });
  }

}
