export class Policynumber {

    symbol: string;
    policy: string;
    module: string;

    constructor(policynumber: string) {
        // Unformatted policy number
        if (policynumber.length > 12) {
            policynumber = policynumber.replace(/-/g, '');
        }

        // formatted policynumber
        this.symbol = policynumber.substring(0, 3);
        this.policy = policynumber.substring(3, 10);
        this.module = policynumber.substring(10);
    }

    toString() {
        return this.symbol.concat(this.policy).concat(this.module);
    }

    toFormattedString() {
        return this.symbol.concat('-').concat(this.policy).concat('-').concat(this.module);
    }
}
