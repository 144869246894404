import { Paymentdetails } from './paymentdetails';

export class Paymentdetailsres {
    responseCode: number;
    responseText: string;
    paymentMoniker: string;
    paymentType: string;
    paymentVendor: string;
    webpayStatus: number;
    allowCCPayment: boolean;
    eftIndicator: boolean;
    installmentInfo: Paymentdetails[];
    firstName: string;
    lastName: string;
    minimumPayment: string;
    maximumPayment: string;
    totalPremium: string;
    lastPayAmount: string;
    policyBalance: string;
    lastPayDate: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    expirationYear: string;
    expirationMonth: string;
    cvc: string;
    homePhoneNumber: string;
    masterCompany: string;
    symbol: string;
    policyNumber: string;
    mod: string;
    paymentDueDate: string;
    jwtToken: string;
    userRole: string;
    policyCompany: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
      }
}
