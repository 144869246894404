import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal , NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddresseditService } from '../../../services/addressedit/addressedit.service';
import { Tracker } from '../../../shared/utils/tracker';
import { EndorsementReq } from '../../../shared/model/api/endorsement/endorsement-req';
import { EndorsementRes } from '../../../shared/model/api/endorsement/endorsement-res';
import { EndorseAddressReq } from '../../../shared/model/api/endorsement/endorse-address-req';
import { FullPolicyDetails, PolicyInfo } from '../../../shared/model/api/bwibic/fullpolicydetails.model';
import { GlobalConstants } from '../../../constants/global.constant';
import { BwstoreService } from '../../../shared/services/bwstore.service';
import { Mailingaddress } from '../../../shared/model/endorsements/addressedit/mailingaddress.model';
import { environment } from '../../../../environments/environment';
import { BWErrorhandler } from '../../../services/errorhandler';
import { Eftreturnres, FuturePayment } from '../../../shared/model/api/policyeft/eftreturnres.model';
import { AutopayeftService } from '../../../services/mypolicy/autopayeft.service';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { Policymailreq } from '../../../shared/model/registration/policymailreq';
import { PolicyHolder } from '../../../shared/model/authentication/authenticatephres';
import { Dairycommentreq } from '../../../shared/model/api/policymgmt/dairycommentreq.model';
import { MessageConstants } from '../../../constants/message.constant';
import { IdcardComponent } from '../../../shared/components/modals/idcard.component';
import { GoogleAnalyticsService } from '../../../shared/services/analytics/google-analytics.service';
import { CommonUtil } from '../../../shared/utils/commonutil';
import { EndorseMetainfo } from '../../../shared/model/endorsements/endorse-metainfo.model';

@Component({
  selector: 'app-mailingaddr-confirmation',
  templateUrl: './mailingaddr-confirmation.component.html',
  styleUrls: ['./mailingaddr-confirmation.component.scss']
})
export class MailingaddrConfirmationComponent implements OnInit {

  @Input() addressEditModal: NgbActiveModal;
  @Input() mailingAddress: Mailingaddress;
  @Input()  futurePayments: FuturePayment [];
  endorseAddressReq: EndorseAddressReq = new EndorseAddressReq();
  basicPolicyDetails: FullPolicyDetails;
  endorsementReq: EndorsementReq =  new EndorsementReq();
  private globlalConstants = GlobalConstants;
  isLoaded = true;
  showAddress = false;
  showPhoneNumber = false;
  policyInfo: PolicyInfo;
  response: Eftreturnres;
  policymailreq: Policymailreq = new Policymailreq();
  dairyCommentReqs: Dairycommentreq[]  = new Array<Dairycommentreq>();
  bwEndorsementInfo: EndorseMetainfo;
  authenticatedPhDetails: PolicyHolder;
  addressChangeComment: string;
  premiumChangeMsg: string ;
  datetime: string;
  constructor(private addressEditService: AddresseditService,  private _storeService: BwstoreService ,
    private errorHandler: BWErrorhandler, private eftService:  AutopayeftService,
    private router: Router , private modalService: NgbModal, private googleAnalytics: GoogleAnalyticsService) {
    this.basicPolicyDetails = this._storeService.getPolicyInfoFromStore();
    this.policyInfo = this.basicPolicyDetails.policyInfo;
    this.authenticatedPhDetails = this._storeService.getLoginInfoFromStore();
    this.bwEndorsementInfo = this._storeService.getEndorsementInfoFromStore();
  }

  ngOnInit() {
    this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressConfirmationScreen', 'MailingAddressConfirmationScreen', 200);
    this.showAddress = this.mailingAddress.isAddressChanged;
    this.showPhoneNumber = this.mailingAddress.isPhoneNumberChanged;
    this.datetime = CommonUtil.getDateTimeNowDateFormat('MM/dd/yyyy HH:mm:ss');
    this.bwEndorsementInfo = new EndorseMetainfo();
    this.bwEndorsementInfo.endorseBound = 'true';
    this.bwEndorsementInfo.endorseType = 'address';
    this._storeService.updateBwEndorsementInfo(this.bwEndorsementInfo);
    this.premiumChangeDisplayMessage();
    this.prepareEndorseReq();
    this.prepareAddressendorseReq();
    this.preparePolicyMailReq();
    this.premiumChangeDisplayMessage();
    this.prepareDairyCommentReqArray(this.endorsementReq.policyID);
    if (this.authenticatedPhDetails.hasRenewal) {
      const nextMod = (Number(this.basicPolicyDetails.policyInfo.MODULE) + 1).toLocaleString(undefined, {minimumIntegerDigits: 2});
      this.prepareDairyCommentReqArray(
        this.basicPolicyDetails.policyInfo.SYMBOL + this.basicPolicyDetails.policyInfo.POLICY0NUM + nextMod
      );
    }
    this.addressEditService.endorseAsyncCalls(this.endorsementReq).then(() => {
    }, (error) => {
      Tracker.logerror('MailingaddrConfirmationComponent', 'Endorse Address', 'acceptAddressChange',
      'Endorse ASYNC call service  Error.', error);
     });
  }

  closeModal() {
    this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressConfirmationScreen', 'MailingAddressConfirmationScreenCloseClick'
    , 200);
    this.errorHandler.ClearApplicationError();
    this.addressEditModal.close();

  }

  navigateToTextAlert() {
    this.closeModal();
    this.googleAnalytics.trackEvent('Text Alerts', 'TextAlertPrefernces', 'TextAlertPreferencesClickFromAddressChangeScreen', 200);
    this.router.navigate(['/profile/txtalert']);
  }

  viewIDCard() {
      this.closeModal();
      this.googleAnalytics.trackEvent('IDCard', 'CallingExtream', 'IDCardButtonClickFromAddressChangeScreen', 200);
      this.modalService.open(IdcardComponent);
  }

// preaare the request for diary comment for both phone and address
  prepareDairyCommentReqArray (policyID: string) {
    if (this.mailingAddress.isAddressChanged) {
      const  dairyAddressCommentReq: Dairycommentreq  = new Dairycommentreq();
      this.addressChangeComment = MessageConstants.ADDR_COMMENT_REQ_MSG
      + this.policyInfo.ADD0LINE03 + ', ' +  this.policyInfo.PolicyCity + ', ' + this.policyInfo.PolicyState +
      ', ' + this.policyInfo.PolicyZip.substr(0, 5) + ' to ' +
      this.mailingAddress.street + ', ' +  this.mailingAddress.city  + ', ' + this.mailingAddress.state +
      ', ' + this.mailingAddress.zip +  ' on ' + formatDate(new Date(), 'MM/dd/yyyy', 'en-US').split('.')[0]
      + '. Confirmation #' + this.mailingAddress.bindingNumber ;
      dairyAddressCommentReq.policyID = policyID;
      dairyAddressCommentReq.mco = this.endorsementReq.mco;
      dairyAddressCommentReq.appName = this.globlalConstants.APPID;
      dairyAddressCommentReq.userID = this.globlalConstants.APPID;
      dairyAddressCommentReq.activityType = this.globlalConstants.COMMENT_REQ_ACTIVITY_TYPE;
      dairyAddressCommentReq.reasonCode = this.globlalConstants.COMMENT_REQ_REASON_CODE;
      dairyAddressCommentReq.destination = this.globlalConstants.COMMENT_REQ_RECYCLE;
      dairyAddressCommentReq.printByte = this.globlalConstants.COMMENT_REQ_PRINT_BYTE;
      dairyAddressCommentReq.prodContext = environment.endorseContext;
      dairyAddressCommentReq.endorseContext = environment.endorseContext;
      // address change comment;
      dairyAddressCommentReq.comment = this.addressChangeComment;
      this.dairyCommentReqs.push(dairyAddressCommentReq);
    }
     // phone number change comment;
    if (this.mailingAddress.isPhoneNumberChanged) {
      const phoneCommentReq: Dairycommentreq  = new Dairycommentreq();
      phoneCommentReq.policyID = policyID;
      phoneCommentReq.mco = this.endorsementReq.mco;
      phoneCommentReq.appName = this.globlalConstants.APPID;
      phoneCommentReq.userID = this.globlalConstants.APPID;
      phoneCommentReq.activityType = this.globlalConstants.COMMENT_REQ_ACTIVITY_TYPE;
      phoneCommentReq.reasonCode = this.globlalConstants.COMMENT_REQ_REASON_CODE;
      phoneCommentReq.destination = this.globlalConstants.COMMENT_REQ_RECYCLE;
      phoneCommentReq.printByte = this.globlalConstants.COMMENT_REQ_PRINT_BYTE;
      phoneCommentReq.prodContext = environment.endorseContext;
      phoneCommentReq.endorseContext = environment.endorseContext;
      phoneCommentReq.comment = MessageConstants.PH_COMMENT_REQ_MSG + this.authenticatedPhDetails.phone  + ' to ' +
      this.mailingAddress.phone +  ' on ' +
       formatDate(new Date(), 'MM/dd/yyyy', 'en-US').split('.')[0]  + '. Confirmation #' + this.mailingAddress.bindingNumber ;
      this.dairyCommentReqs.push(phoneCommentReq);
    }
    this.endorsementReq.commentRequestArray =  this.dairyCommentReqs;
  }

  // this method prepares the policy mail request
  preparePolicyMailReq() {
    this.policymailreq.symbol = this.basicPolicyDetails.policyInfo.SYMBOL;
    this.policymailreq.policy =  this.basicPolicyDetails.policyInfo.POLICY0NUM;
    this.policymailreq.mod =  this.basicPolicyDetails.policyInfo.MODULE;
    this.policymailreq.mco = this.basicPolicyDetails.policyInfo.MASTER0CO;
    this.policymailreq.midterm = this.globlalConstants.POLICY_EMAIL_TYPE_REG_EMAIL;
    this.policymailreq.state = this.mailingAddress.state;
    this.policymailreq.email = this.authenticatedPhDetails.email;
    this.policymailreq.miscellaneousUse1 = this.mailingAddress.bindingNumber;
    this.policymailreq.miscellaneousUse2 = '';
    this.policymailreq.miscellaneousUse3 = '';
    this.policymailreq.type = this.globlalConstants.POLICY_EMAIL_REQ_TYPE;
    this.policymailreq.status = this.globlalConstants.GP_STATUS_P;
    this.policymailreq.sentCount = 0;
    this.policymailreq.frequency = 1;
    this.policymailreq.firstName = this.authenticatedPhDetails.firstName;
    this.policymailreq.lastName = this.authenticatedPhDetails.lastName;
  }
  // this method prepares the endorse policy request
  prepareEndorseReq() {
    this.endorsementReq.policyID = this.basicPolicyDetails.policyInfo.SYMBOL + this.basicPolicyDetails.policyInfo.POLICY0NUM +
    this.basicPolicyDetails.policyInfo.MODULE;
    this.endorsementReq.mco = this.basicPolicyDetails.policyInfo.PolicyMasterCompany;
    this.endorsementReq.pco =  this.basicPolicyDetails.policyInfo.PolicyCompany;
    this.endorsementReq.lob = this.basicPolicyDetails.policyInfo.LINE0BUS;
    this.endorsementReq.appName = this.globlalConstants.APPID;
    this.endorsementReq.userID =  this.globlalConstants.APPID;
    this.endorsementReq.changeType = '2';
    this.endorsementReq.policyStatus =  this.basicPolicyDetails.policyInfo.PolicyStatus;
    this.endorsementReq.databaseContext = `${environment.pointContext}`;
    this.endorsementReq.user = this.globlalConstants.APPID;
    this.endorsementReq.policyMailReqType = this.policymailreq;
    this.endorsementReq.mod =   this.basicPolicyDetails.policyInfo.MODULE;
    this.endorsementReq.activityResult = this.globlalConstants.AUDIT_LOG_ACITIVITY_RESULT_S;
    this.endorsementReq.activityMessage = this.globlalConstants.AUDIT_LOG_ACITIVITY_MESSAGE_SUCCESS;
    this.endorsementReq.activity = this.globlalConstants.AUDIT_LOG_ACITIVITY_ADDRESS_CHANGE;
    this.endorsementReq.symbol = this.policyInfo.SYMBOL;
    this.endorsementReq.policyNum =  this.basicPolicyDetails.policyInfo.POLICY0NUM;
    this.endorsementReq.state =  this.mailingAddress.state;
    this.endorsementReq.binderNumber =  this.mailingAddress.bindingNumber;
  }
  //  this funtion prepares the endorse address request
  prepareAddressendorseReq () {
    this.endorseAddressReq.addressLine1 = this.mailingAddress.street;
    this.endorseAddressReq.city = this.mailingAddress.city;
    this.endorseAddressReq.state = this.mailingAddress.state;
    this.endorseAddressReq.zipCode = this.mailingAddress.zip;
    this.endorsementReq.endoseAddress = this.endorseAddressReq;
  }
//  this function create diaplays message based the premium amount change
  premiumChangeDisplayMessage () {
const proratedPremium = Number(this.mailingAddress.proratedPremium);
    if (proratedPremium > 0 ) {
      this.premiumChangeMsg = MessageConstants.ADDR_PREMIUM_INC_NOTIFY_MSG +
      Math.abs(proratedPremium)  + '. ' + MessageConstants.ADDR_PREMIUM_UPDATED_PREMIUM
      + '$' +  this.mailingAddress.premiumAmount + ' .';
    } else if (proratedPremium === 0 ) {
      this.premiumChangeMsg = MessageConstants.ADDR_PREMIUM_NO_CHANGE_NOTIFY_MSG;
    } else if (proratedPremium < 0 ) {
      this.premiumChangeMsg = MessageConstants.ADDR_PREMIUM_DEC_NOTIFY_MSG +
      Math.abs(proratedPremium) + '. ' + MessageConstants.ADDR_PREMIUM_UPDATED_PREMIUM
      + '$' +  this.mailingAddress.premiumAmount + ' .' ;
    }
    /*
    if (Number(this.policyInfo.PolicyPremium )  >  this.mailingAddress.premiumAmount) {
      this.premiumChangeMsg = MessageConstants.ADDR_PREMIUM_DEC_NOTIFY_MSG +
    (Number(this.policyInfo.PolicyPremium ) - this.mailingAddress.premiumAmount) + '. ' + MessageConstants.ADDR_PREMIUM_UPDATED_PREMIUM
    + '$' + this.mailingAddress.premiumAmount + ' .' ;
    } else if (Number(this.policyInfo.PolicyPremium )  <  this.mailingAddress.premiumAmount) {
      this.premiumChangeMsg = MessageConstants.ADDR_PREMIUM_INC_NOTIFY_MSG +
    (this.mailingAddress.premiumAmount - Number(this.policyInfo.PolicyPremium ) )  + '. ' + MessageConstants.ADDR_PREMIUM_UPDATED_PREMIUM
      + '$' + this.mailingAddress.premiumAmount + ' .';
    } else {
      this.premiumChangeMsg = MessageConstants.ADDR_PREMIUM_NO_CHANGE_NOTIFY_MSG;
    } */
  }
}
