export class Policykey {
    masterCompany: string;
    policyCompany: string;
    symbol: string;
    policyNumber: string;
    module: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
