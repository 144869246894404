import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BwstoreService } from '../../shared/services/bwstore.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleAnalyticsService } from '../../shared/services/analytics/google-analytics.service';
import { StatecdsService } from '../../shared/services/statecds.service';
import { StateCds } from '../../shared/model/state-cds.model';
import { PolicyInfo, FullPolicyDetails } from '../../shared/model/api/bwibic/fullpolicydetails.model';
import { MessagesService } from '../../shared/services/messages.service';
import { BWErrorhandler } from '../../services/errorhandler';
import { BWError } from '../../shared/model/error.model';
import { MessageConstants } from '../../constants/message.constant';
import { ConfirmChangesComponent } from '../../shared/components/modals/confirm-changes.component';
import { Bwappenum } from '../../constants/bwappenum.enum';
import { Mailingaddress } from '../../shared/model/endorsements/addressedit/mailingaddress.model';
import { Eftreturnres, FuturePayment } from '../../shared/model/api/policyeft/eftreturnres.model';

@Component({
  selector: 'app-address-edit',
  templateUrl: './address-edit.component.html',
  styleUrls: ['./address-edit.component.scss']
})

export class AddressEditComponent implements OnInit {

 currentStep = Bwappenum.MAILING_ADDR_STEP1;

 public mailingAddress: Mailingaddress;

 public futurePayments: FuturePayment[];

 public headerMsg: string;



  constructor(private addressEditModal: NgbActiveModal, private googleAnalytics: GoogleAnalyticsService) { }

  ngOnInit() {
    this.headerMsg = 'Edit Mailing Address';
  }


  getMailingAddress(addr: Mailingaddress) {
    this.mailingAddress = addr;

    this.currentStep = Bwappenum.MAILING_ADDR_STEP2;
  }

  getUpdateStatus(status: string) {
    if (status === 'success') {
    if (this.mailingAddress.isPhoneNumberChanged && this.mailingAddress.isAddressChanged) {
        this.headerMsg = 'YOUR ADDRESS AND PHONE NUMBER CHANGE IS COMPLETE.';
      } else  if (this.mailingAddress.isPhoneNumberChanged) {
        this.headerMsg = 'YOUR PHONE NUMBER CHANGE IS COMPLETE.';
      } else if (this.mailingAddress.isAddressChanged) {
        this.headerMsg = 'YOUR ADDRESS CHANGE IS COMPLETE.';
      }

      this.currentStep = Bwappenum.MAILING_ADDR_STEP3;
    }
  }
  getfuturePayments(futurePayments: FuturePayment[] ) {
      this.futurePayments = futurePayments;
    }

}
