import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TimeoutService } from '../../../services/timeout.service';
import { GoogleAnalyticsService } from '../../services/analytics/google-analytics.service';
import { AuthenticationService } from '../../services/authentication.service';
import { MessagesService } from '../../services/messages.service';
import { BwstoreService } from '../../services/bwstore.service';
import { EndorseMetainfo } from '../../model/endorsements/endorse-metainfo.model';
import { AppComponent } from '../../../app.component';
import { DirectsalesService } from '../../services/directsales.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { PolicyHolder } from '../../model/authentication/authenticatephres';
import { CesService } from '../../services/ces.service';
import { TokenRequest } from '../../model/api/cessurvey/token-request';
import { FullPolicyDetails } from '../../model/api/bwibic/fullpolicydetails.model';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isUserLoggedIn: boolean;
  bwTokenFromSession: string;
  navbarOpen = false;
  directSalesPhone: any;
  routerUrl = '';
  isLogoutDisabled: boolean;
  //CES start
  tokenRequest : TokenRequest;
  surveyToken = '';
  displayStyle = "none";
  //CES End
  constructor(private router: Router, private authService: AuthenticationService, private timeoutService: TimeoutService,
    private messagesService: MessagesService, private googleAnalytics: GoogleAnalyticsService,
    private appComponent: AppComponent, private directSalesService: DirectsalesService,
    private modalService: NgbModal,private _storeService: BwstoreService, private cesService : CesService) {
      // this.directSalesService.phoneNumber.subscribe(phone => this.directSalesPhone = phone);
      this.directSalesService.phoneNumberObject.subscribe(phone => this.directSalesPhone = phone);
      // console.log('phone no in header--' + this.directSalesPhone);
    }

  ngOnInit() {

   this.authService.isUserLoggedIn.subscribe((authenticated) => {
        this.isUserLoggedIn = authenticated;
      });

    // Fallback Scenario when browser is refreshed
    if (this.isUserLoggedIn === undefined) {
      this.bwTokenFromSession = this.authService._bwToken();
      if (this.bwTokenFromSession !== undefined && this.bwTokenFromSession !== null && this.bwTokenFromSession !== '') {
        this.isUserLoggedIn = true;
        this.authService._setLoginStatus(true);
      }
    }
    this.authService.isLogoutDisabled.asObservable().subscribe((data) => {
      this.isLogoutDisabled = data;
    });
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.routerUrl = this.router.url;
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });
    // Tracker.log('User Logged In status ::: ', this.isUserLoggedIn);
 }

  logoutUser() {
    console.log('Loggin out the user..............');
    //CES changes Start
    this.tokenRequest = new TokenRequest();
    if(this._storeService.currentUserLoginInfo){
      this.tokenRequest.email = this._storeService.currentUserLoginInfo.email;
      this.tokenRequest.recipientFirstName = this._storeService.currentUserLoginInfo.firstName;
      this.tokenRequest.recipientLastName = this._storeService.currentUserLoginInfo.lastName;
    }
    if(this._storeService.fullPolicydetails){
      if(this._storeService.fullPolicydetails.policyInfo){
        this.tokenRequest.policyNumber = this._storeService.fullPolicydetails.policyInfo.PolicyNumber;
        this.tokenRequest.policy = this._storeService.fullPolicydetails.policyInfo.POLICY0NUM;
        this.tokenRequest.companyCode = this._storeService.fullPolicydetails.policyInfo.MASTER0CO;
        this.tokenRequest.symbol = this._storeService.fullPolicydetails.policyInfo.SYMBOL;
        this.tokenRequest.policyMod = this._storeService.fullPolicydetails.policyInfo.MODULE;
        this.tokenRequest.stateCode = this._storeService.fullPolicydetails.policyInfo.RISK0STATE;
        this.tokenRequest.state = this._storeService.fullPolicydetails.policyInfo.PolicyState;
        this.tokenRequest.lob = this._storeService.fullPolicydetails.policyInfo.LINE0BUS;
        this.tokenRequest.policyStatus = this._storeService.fullPolicydetails.policyInfo.PolicyStatus;
        this.tokenRequest.policyRoleType = this._storeService.fullPolicydetails.policyInfo.PolicyType;
        if(this._storeService.fullPolicydetails.policyInfo.PolicyExpireDate){
          this.tokenRequest.policyExpirationDate = this._storeService.fullPolicydetails.policyInfo.PolicyExpireDate.split('T')[0];
        }
        if(this._storeService.fullPolicydetails.policyInfo.PolicyEffectiveDate){
          this.tokenRequest.policyEffectiveDate =this._storeService.fullPolicydetails.policyInfo.PolicyEffectiveDate.split('T')[0];
        }
        if(this._storeService.fullPolicydetails.policyInfo.PolicyCancelDate && this._storeService.fullPolicydetails.policyInfo.PolicyCancelDate.split('T')[0] !=='0001-01-01'){
          this.tokenRequest.policyCancellationDate = this._storeService.fullPolicydetails.policyInfo.PolicyCancelDate.split('T')[0];
        }else{
          this.tokenRequest.policyCancellationDate = "";
        }
      }
      if(this._storeService.fullPolicydetails.paymentInfo){
        this.tokenRequest.paymentDueAmount = this._storeService.fullPolicydetails.paymentInfo.minimumPayment;
        this.tokenRequest.nextPaymentDueDate = this._storeService.fullPolicydetails.paymentInfo.paymentDueDate;
      }
      if(this._storeService.fullPolicydetails.agentInfo){
        this.tokenRequest.agentOfRecordId = this._storeService.fullPolicydetails.agentInfo.AgentCode;
      }
    }
    
    this.cesService.getSurveyToken(this.tokenRequest).subscribe((response) => {
      if (response != null) {
        if(response.token){
          this.surveyToken = response.token;
          this.displayStyle = "block";
          //this.modalService.open(feedbackModel, {size: 'sm', centered: false}).result.then();
        }
      }
    }, error => {
      return null;
    });
    //CES changes End
    this.authService.logout();
    this.timeoutService.logout();
    this.messagesService.clearAll();
    this.router.navigate(['']);
    // clear the setInterval for CHAT button
    if (this.appComponent.win) {
        this.appComponent.win.close('Chat_Window1');
    }

    return;
  }
  //CES changes Start
  openSurvey(){
     window.open(`${environment.cesSurveyUrl}`+"?"+this.surveyToken, "_self");
  }
  closeSurveyPopup(){
     this.displayStyle = "none";
     this.cesService.closeSurvey(this.tokenRequest).subscribe((response) => {
      if (response != null) {
        console.log(response.surveyNoThanksUpdated);
      }
    }, error => {
      return null;
    });
  }
  //CES changes End
  toggleNavbar(navbar: any) {
    this.navbarOpen = !this.navbarOpen;
    // Tracker.log(this.navbarOpen);
    if (navbar === 'policy') {
      this.googleAnalytics.trackEvent('MyPolicy', 'MyPolicyDetailsScreen', 'MyPolicyDetailsButtonClick', 200);
    } else if (navbar === 'profile') {
      this.googleAnalytics.trackEvent('MyProfile', 'MyProfileScreen', 'MyProfileButtonFromHomeScreen', 200);
    } else if (navbar === 'login') {
      this.googleAnalytics.trackEvent('Login', 'LoginScreen', 'LoginFromHomeScreen', 200);
      document.getElementById('user_email').focus();
    } else if (navbar === 'startQuote') {
      this.googleAnalytics.trackEvent('StartQuote', 'HomeScreen', 'StartQuoteButtonFromHomeScreen', 200);
      document.getElementById('zipCode').focus();
    } else if (navbar === 'claimsService') {
      this.googleAnalytics.trackEvent('ClaimsServices', 'ClaimsServicesScreen', 'ClaimsServicesButtonFromHomeScreen', 200);
    } else if (navbar === 'findAgent') {
      // this.googleAnalytics.trackEvent('Registration', 'UserRegistrationScreen', 'UserRegistrationContinueClick', 200)
    } else if (navbar === 'getaQuote') {
      this.googleAnalytics.trackEvent('GetaQuote', 'GetAQuoteScreen', 'GetAQuoteButtonFromHomeScreen', 200);
    } else if (navbar === 'aboutUs') {
      this.googleAnalytics.trackEvent('AboutUs', 'AboutUsScreen', 'AboutUsButtonFromHomeScreen', 200);
    } else if (navbar === 'autoInsurance101') {
      this.googleAnalytics.trackEvent('AutoInsurance', 'AutoInsuranceScreen', 'AutoInsuranceButtonFromHomeScreen', 200);
    } else if (navbar === 'why-bristol') {
      this.googleAnalytics.trackEvent('WhyBristol', 'WhyBristolScreen', 'WhyBristolButtonFromHomeScreen', 200);
    }
  }
}
