var Authenticatephres = /** @class */ (function () {
    function Authenticatephres(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return Authenticatephres;
}());
export { Authenticatephres };
var PolicyHolder = /** @class */ (function () {
    function PolicyHolder(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return PolicyHolder;
}());
export { PolicyHolder };
