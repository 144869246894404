import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MessageConstants } from '../../../constants/message.constant';
import { BWError } from '../../../shared/model/error.model';
import { BWErrorhandler } from '../../../services/errorhandler';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessagesService } from '../../../shared/services/messages.service';
import { GoogleAnalyticsService } from '../../../shared/services/analytics/google-analytics.service';
import { StatecdsService } from '../../../shared/services/statecds.service';
import { BwstoreService } from '../../../shared/services/bwstore.service';
import { StateCds } from '../../../shared/model/state-cds.model';
import { FullPolicyDetails, PolicyInfo } from '../../../shared/model/api/bwibic/fullpolicydetails.model';
import { Mailingaddress } from '../../../shared/model/endorsements/addressedit/mailingaddress.model';
import { Bwappenum } from '../../../constants/bwappenum.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RequesttocancelService } from '../../../services/myprofile/requesttocancel.service';
import { Tracker } from '../../../shared/utils/tracker';
import { PolicyHolder } from '../../../shared/model/authentication/authenticatephres';
import { MobileNumberValidator } from '../../../shared/validators/mobilenumber.validator';
import { EndorsementReq } from '../../../shared/model/api/endorsement/endorsement-req';
import { GlobalConstants } from '../../../constants/global.constant';
import { environment } from '../../../../environments/environment';
import { EndorsementService } from '../../../services/api/endorsement/endorsement.service';
import { CommonUtil } from '../../../shared/utils/commonutil';
import { HelptextService } from '../../../services/api/helptext/helptext.service';
import { HelpTextMap } from '../../../shared/maps/helptext-map';

@Component({
  selector: 'app-mailingaddr-change',
  templateUrl: './mailingaddr-change.component.html',
  styleUrls: ['./mailingaddr-change.component.scss']
})
export class MailingaddrChangeComponent implements OnInit {

  addressEditForm: FormGroup;
  formSubmitAttempt: boolean;
  stateCds: StateCds[] = [];
  isAddrEditCancelled = false;
  isSubmitButtonDisable = false;
  submitted = false;

  mailingAddress: Mailingaddress;
  isLoaded = true;

  // Store
  basicPolicyDetails: FullPolicyDetails;
  policyInfo: PolicyInfo;
  authenticatedPhDetails: PolicyHolder;
  count1 = '0';
  phoneNumber = 'Phone Number';
  @Input() addressEditModal: NgbActiveModal;
  @Output() updatedMailingAddress = new EventEmitter<Mailingaddress>();
  endorsementReq: EndorsementReq = new EndorsementReq();
  private globlalConstants = GlobalConstants;
  helptextMap: HelpTextMap;

  constructor(private route: ActivatedRoute, private router: Router,
    private fb: FormBuilder, private _storeService: BwstoreService,
    private _messageService: MessagesService, private errorHandler: BWErrorhandler,
    private googleAnalytics: GoogleAnalyticsService,
    private stateCdService: StatecdsService,
    private cancelService: RequesttocancelService,
    private endorsementService: EndorsementService,
    public helptextService: HelptextService) {
    this.authenticatedPhDetails = this._storeService.getLoginInfoFromStore();
    this.basicPolicyDetails = this._storeService.getPolicyInfoFromStore();
    this.policyInfo = this.basicPolicyDetails.policyInfo;
    this.createAddressEditForm();
    // ToDo need to add the help text id
    this.helptextService.getHelpTextByIds(['612']).subscribe((helptextres: HelpTextMap) => {
     this.helptextMap = helptextres;
   });
  }

  ngOnInit() {
    this.stateCdService.getStateCds().subscribe((stateCds: StateCds[]) => {
      this.stateCds = stateCds;
    });
  }

  addressEditModalClose(modal: any, formComponenetName: string) {
    this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressChangeCancel', 'MailingAddressChangeCancelClick', 200);
    modal.dismiss('formComponenetName');
    this.errorHandler.ClearApplicationError();
  }

  onChange() {
    // this.addressEditForm.get('isVehicleInThisLocation').valueChanges.subscribe(value => {
    this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressChangeScreen', 'MailingAddressChangeKnockOut', 200);
    if (this.addressEditForm.get('isVehicleInThisLocation').value === 'no') {
      this.addressEditForm.get('address').disable();
      this.addressEditForm.get('city').disable();
      this.addressEditForm.get('state').disable();
      this.addressEditForm.get('zip').disable();
      this.addressEditForm.get('isVehicleInThisLocation').disable();
      this.addressEditForm.get('phoneNumber').disable();
      this.isSubmitButtonDisable = true;

      // Audit Log entry for knock out
      this.endorsementReq.symbol = this.policyInfo.SYMBOL;
      this.endorsementReq.policyNum = this.basicPolicyDetails.policyInfo.POLICY0NUM;
      this.endorsementReq.mod = this.basicPolicyDetails.policyInfo.MODULE;
      this.endorsementReq.mco = this.basicPolicyDetails.policyInfo.PolicyMasterCompany;
      this.endorsementReq.activityResult = this.globlalConstants.AUDIT_LOG_ACITIVITY_RESULT_K;
      this.endorsementReq.activity = this.globlalConstants.AUDIT_LOG_ACITIVITY_ADDRESS_CHANGE;
      this.endorsementReq.state = this.policyInfo.PolicyState;
      this.endorsementReq.activityMessage = MessageConstants.VEHICLE_DIFFERENT_GARAGGING_ADDRESS;
      this.endorsementService.createAuditLogRecord(this.endorsementReq).subscribe(
        auditLogRes => {
          // CommonUtil.scrollToTop();
          document.getElementById('headerAdd').scrollTop = 0;
          this.errorHandler.handleApplicationError(new BWError(
            {
              'errorcode': 'MailingAddressEdit', 'errordesc': MessageConstants.ADDR_AGENT_MSG + '<br>' +
                'Agent Name : ' + this.basicPolicyDetails.agentInfo.AgentName1 + '<br>'
                + 'Agent Phone Number : ' + this.basicPolicyDetails.agentInfo.ACNM_TELE, 'source': 'isVehicleInThisLocation'
            }));
        });
    }
  }

  submitAddressEndorsement(formdata: any) {
    this.formSubmitAttempt = true;
    if (this.addressEditForm.valid) {
      if (this.policyInfo.PolicyAddress.toUpperCase() === formdata.address.toUpperCase()
        && this.policyInfo.PolicyCity.toUpperCase() === formdata.city.toUpperCase()
        && this.policyInfo.PolicyZip.substr(0, 5) === formdata.zip
        && this.authenticatedPhDetails.phone === formdata.phoneNumber) {
        this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressChangeScreen', 'MailingAddressComparisonWithExistingOne'
          , 200);
          CommonUtil.scrollToTop();
          this.errorHandler.handleApplicationError(new BWError(
          {
            'errorcode': 'MailingAddressEdit', 'errordesc': MessageConstants.ADDR_NO_CHANGE,
            'source': 'MailingAddressNotChanged'
          }));
      } else {
        this.mailingAddress = new Mailingaddress();
        if (this.policyInfo.PolicyAddress.toUpperCase() !== formdata.address.toUpperCase()
          || this.policyInfo.PolicyCity.toUpperCase() !== formdata.city.toUpperCase()
           || this.policyInfo.PolicyZip.substr(0, 5) !== formdata.zip) {
          this.mailingAddress.isAddressChanged = true;
        }
        if (this.authenticatedPhDetails.phone !== formdata.phoneNumber) {
          this.mailingAddress.isPhoneNumberChanged = true;
        }
        this.errorHandler.ClearApplicationError();
        this.mailingAddress.street = formdata.address.toUpperCase();
        this.mailingAddress.city = formdata.city.toUpperCase();
        this.mailingAddress.state = this.policyInfo.PolicyState;
        this.mailingAddress.zip = formdata.zip;
        this.mailingAddress.garagingAddress = formdata.isVehicleInThisLocation;
        this.mailingAddress.phone = formdata.phoneNumber;
        this.mailingAddress.stage = Bwappenum.MAILING_ADDR_STEP2;
        // this.updatedMailingAddress.emit(this.mailingAddress);

        if (formdata.isVehicleInThisLocation === 'yes') {
          // add address Scrubing
          this.isLoaded = false;
          this.cancelService.scrubAddress(formdata.address, formdata.city, this.policyInfo.PolicyState, formdata.zip)
            .then(scrubAddressRes => {
              if (scrubAddressRes) {
                this.isLoaded = true;
                this.updatedMailingAddress.emit(this.mailingAddress);
              }
            }, (scrubAddressError) => {
              this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressChangeScreen', 'ScrubAddressFailure'
                , 200);
              this.isLoaded = true;
            });
        }
      }
    }
  }

  voidEndorsement() {
    this.isAddrEditCancelled = true;
  }

  cancelEndormentProcess() {
    this.isAddrEditCancelled = true;
  }

   // Tooltips
   getToolTip(id: string) {
    if (this.helptextMap) {
      const helptextid = this.helptextMap.get(id);
      return helptextid !== null && helptextid !== undefined ? helptextid.producerText : '';
    }
}

toggle(tooltip) {
  if (tooltip.isOpen()) {
    tooltip.close();
  } else {
    tooltip.open();
  }
}

  createAddressEditForm() {
    this.addressEditForm = this.fb.group({
      'address': [{ value: this.policyInfo.PolicyAddress, disabled: this.isAddrEditCancelled }, Validators.compose([Validators.required])],
      'city': [{ value: this.policyInfo.PolicyCity, disabled: this.isAddrEditCancelled }, Validators.compose([Validators.required])],
      'state': [{ value: this.policyInfo.PolicyState, disabled: true }, Validators.compose([Validators.required])],
      'zip': [{ value: this.policyInfo.PolicyZip.substr(0, 5), disabled: this.isAddrEditCancelled },
      Validators.compose([Validators.required, Validators.maxLength(5)])],
      'isVehicleInThisLocation': [{ value: '', disabled: this.isAddrEditCancelled },
      Validators.compose([Validators.required])],
      'phoneNumber': [{ value: this.authenticatedPhDetails.phone, disabled: false },
      Validators.compose([Validators.required, MobileNumberValidator.validateMobileNumber])],
    });
  }



  // Form Utilities
  isFieldValid(field: string) {
    return (
      (!this.addressEditForm.get(field).valid && this.addressEditForm.get(field).touched) ||
      (this.addressEditForm.get(field).untouched && this.formSubmitAttempt)
    );
  }

  getErrMessage(errors, formCntrlName: string) {
    return this._messageService.getErrorMessages(errors, formCntrlName);
  }

  getMobileNumErrMsg(errors) {
    if (errors) {
      if (errors.required === true) {
        return MessageConstants.REQUIRED_PHONENUMBER;
      } else if ((errors.mobilenum === true)) {
        return MessageConstants.MOBILE_NUMBER_FORMAT;
      }
    }
  }
}
