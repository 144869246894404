import { Component, OnInit } from '@angular/core';
import { MessagesService } from '../../services/messages.service';

@Component({
  selector: 'app-system-errors',
  templateUrl: './system-errors.component.html',
  styleUrls: ['./system-errors.component.scss']
})
export class SystemErrorsComponent implements OnInit {

  systemMessages: string[] = [];
  closed = false;
  constructor(private messageService: MessagesService) { }

  ngOnInit() {
    this.closed = false;
    this.messageService.systemMsgs.subscribe((messages) => {
      // console.error("Messages from System Errors ..... ", messages);
      this.systemMessages = messages;
    });
    // Tracker.log(this.systemMessages[0]);
    // Tracker.log(this.closed);
    // this.messageService.getSystemMessages().then((message) => this.systemMessages = message[0]);
  }

}
