import { OnInit, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MyprofileService } from '../../../services/myprofile/myprofile.service';
import { PolicyInfo, FullPolicyDetails } from '../../model/api/bwibic/fullpolicydetails.model';
import { BwstoreService } from '../../services/bwstore.service';
import { Router } from '@angular/router';
import { BWErrorhandler } from '../../../services/errorhandler';
import { BWError } from '../../model/error.model';
import { Tracker } from '../../utils/tracker';
import { MessageConstants } from '../../../constants/message.constant';
import { UsmsecurityService } from '../../../services/api/usmsecurity/usmsecurity.service';
import { Authenticatephreq } from '../../model/authentication/authenticatephreq';
import { GlobalConstants } from '../../../constants/global.constant';
var ConfirmPasswordComponent = /** @class */ (function () {
    function ConfirmPasswordComponent(errorModal, _storeService, myProfileService, errorHandler, router, fb, usmSecurityService) {
        this.errorModal = errorModal;
        this._storeService = _storeService;
        this.myProfileService = myProfileService;
        this.errorHandler = errorHandler;
        this.router = router;
        this.fb = fb;
        this.usmSecurityService = usmSecurityService;
        this.loaded = true;
        this.usermessage = MessageConstants;
        this.emitService = new EventEmitter();
        this.errorFlag = false;
        this.showPassword = false;
        this.createConfirmPasswordFormGroup();
    }
    ConfirmPasswordComponent.prototype.ngOnInit = function () {
        this.authenticatedPhDetails = this._storeService.getLoginInfoFromStore();
    };
    ConfirmPasswordComponent.prototype.createConfirmPasswordFormGroup = function () {
        this.passwordForm = this.fb.group({
            password: ['', Validators.compose([Validators.required])],
        });
    };
    ConfirmPasswordComponent.prototype.onSubmit = function (formData) {
        var _this = this;
        if (this.passwordForm.valid) {
            this.showPassword = false;
            this.formSubmitAttempt = true;
            this.loaded = false;
            var authPHRequest = new Authenticatephreq();
            authPHRequest.appID = GlobalConstants.APPID;
            authPHRequest.userName = this.authenticatedPhDetails.email;
            authPHRequest.password = formData.password;
            this.usmSecurityService.authenticatePH(authPHRequest).
                subscribe(function (data) {
                Tracker.loginfo('ConfirmPasswordComponent', 'onSubmit', '', 'Response from authenticatePH()' + data.authenticatePolicyHolderResult);
                _this.loaded = true;
                if (data.authenticatePolicyHolderResult === true) {
                    _this.errorFlag = false;
                    _this.emitService.emit({ passwordValue: formData.password, authResponse: 'success' });
                    _this.errorModal.close();
                }
                else {
                    _this.errorFlag = true;
                    _this.emitService.next({ passwordValue: formData.password, authResponse: 'failure' });
                    _this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '100', 'errordesc': data.errorMsg, 'source': 'USMSecurityService' }));
                }
            }, function (error) {
                _this.errorFlag = true;
                _this.loaded = true;
                _this.errorHandler.handleApplicationError(new BWError({ 'errorcode': error.error.code, 'errordesc': MessageConstants.INVALID_CURRENT_PASSWORD, 'source': 'USMSecurityService' }));
            });
        }
    };
    // Form Validation Utilities
    ConfirmPasswordComponent.prototype.isFieldValid = function (field) {
        return ((!this.passwordForm.get(field).valid && this.passwordForm.get(field).touched) ||
            (this.passwordForm.get(field).untouched &&
                this.passwordForm.get(field).value === '' &&
                this.formSubmitAttempt));
    };
    ConfirmPasswordComponent.prototype.togglePassword = function () {
        this.showPassword = !this.showPassword;
    };
    return ConfirmPasswordComponent;
}());
export { ConfirmPasswordComponent };
