/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-error.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./payment-error.component";
var styles_PaymentErrorComponent = [i0.styles];
var RenderType_PaymentErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentErrorComponent, data: {} });
export { RenderType_PaymentErrorComponent as RenderType_PaymentErrorComponent };
function View_PaymentErrorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
export function View_PaymentErrorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["style", "padding:1%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Important!"])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentErrorComponent_1)), i1.ɵdid(7, 802816, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.finalErrorMessages; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_PaymentErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payment-error", [], null, null, null, View_PaymentErrorComponent_0, RenderType_PaymentErrorComponent)), i1.ɵdid(1, 114688, null, 0, i3.PaymentErrorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentErrorComponentNgFactory = i1.ɵccf("app-payment-error", i3.PaymentErrorComponent, View_PaymentErrorComponent_Host_0, { transactionNotification: "transactionNotification", amount: "amount", paymentResponse: "paymentResponse" }, {}, []);
export { PaymentErrorComponentNgFactory as PaymentErrorComponentNgFactory };
