import { EndorseAddressReq } from './endorse-address-req';
import { EndorsePhoneReq } from './endorse-phone-req';
import { Policymailreq } from '../../registration/policymailreq';
import { Dairycommentreq } from '../policymgmt/dairycommentreq.model';
import {EndorseVehicleInfo } from './endorse-vehicle-info';
import {EndorseCoverageInfo } from './endorse-coverage-info';
import { Lienholdercmpy } from '../policyholder/lienholdercmpy.model';

export class EndorsementReq {
     policyID: string;
     mco: string;
     pco: string;
     lob: string;
     policyStatus: string;
     state: string;
     producerCode: string;

     user: string;  // probable duplicate data with userID
     userID: string;
     userType: string;

     appName: string;
     appSource: number;

     databaseContext: string;
     endorseContext: string;

     changeType: string; // Global change type. ADD, EDIT, REPLACE, REMOVE
     endorseDate: string;

     // Mailing Address & Phone Number Endorsement Reqs
     endoseAddress: EndorseAddressReq;
     phoneNumber: EndorsePhoneReq;

     // Vehicle Endorsement - Add, Replace
     endorseVehicleInfo: EndorseVehicleInfo;

     // Coverage Endorsement - Add, Edit
     endorseCoverageInfo: EndorseCoverageInfo[];

     // - Once Address endorsement is converted to new design this variable need to be deleted
     emailDiscount: boolean;
     applyToRenewal: boolean;

     // Email Request - Once Address endorsement is converted to new design this variable need to be deleted
     policyMailReqType: Policymailreq;

     // Dairy Comment Reuqest - Once Address endorsement is converted to new design this variable need to be deleted
     commentRequestArray: Dairycommentreq [];  // -- can be multiple comments

     // Audit log - Once Address endorsement is converted to new design this variable need to be deleted
     activity: string;
     activityResult: string;
     activityMessage: string;
     symbol: string;
     policyNum: string;
     mod: string;


    // Upon successfull endorsePolicy this field will be populated
    // Once Address endorsement is converted to new design this variable need to be deleted
    binderNumber: string;

     constructor(values: Object = {}) {
      Object.assign(this, values);
    }
}





