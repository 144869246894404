import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BwstoreService } from '../../../services/bwstore.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Policynumber } from '../../../model/policynumber';
import { MessageConstants } from '../../../../constants/message.constant';
import { StatecdsService } from '../../../services/statecds.service';
import { BWErrorhandler } from '../../../../services/errorhandler';
import { CenturydatePipe } from '../../../pipes/centurydate.pipe';
import { PrivacypolicyService } from '../../../../services/privacypolicy/privacypolicy.service';
import { BWError } from '../../../model/error.model';
import { CommonUtil } from '../../../utils/commonutil';
var PrivacyOptOutComponent = /** @class */ (function () {
    function PrivacyOptOutComponent(fb, stateCdService, _storeService, privacyPolicyService, errorHandler, datePipe, privacyOptOutModal) {
        this.fb = fb;
        this.stateCdService = stateCdService;
        this._storeService = _storeService;
        this.privacyPolicyService = privacyPolicyService;
        this.errorHandler = errorHandler;
        this.datePipe = datePipe;
        this.privacyOptOutModal = privacyOptOutModal;
        this.stateCds = [];
        this.policynum = '';
        this.usermessage = MessageConstants;
        this.loaded = true;
        this.dateobj = {
            'day': '',
            'month': '',
            'year': ''
        };
        // Get the current state of NewUser
        this.currentUserLoginInfo = this._storeService.getLoginInfoFromStore();
        this.fullPolicydetails = this._storeService.getPolicyInfoFromStore();
        if (this.fullPolicydetails && this.fullPolicydetails.policyInfo) {
            this.policynum = this.fullPolicydetails.policyInfo.PolicyNumber;
            this.policyState = this.fullPolicydetails.policyInfo.PolicyState;
        }
    }
    PrivacyOptOutComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateCdService.getStateCds().subscribe(function (stateCds) {
            _this.stateCds = stateCds;
        });
        this.optForm = this.fb.group({
            'firstName': [this.currentUserLoginInfo.firstName, Validators.compose([Validators.required])],
            'lastName': [this.currentUserLoginInfo.lastName, Validators.compose([Validators.required])],
            'state': [this.policyState, Validators.compose([Validators.required])]
        });
    };
    PrivacyOptOutComponent.prototype.navigate = function () {
        this.privacyOptOutModal.close();
    };
    PrivacyOptOutComponent.prototype.dismiss = function () {
        this.privacyOptOutModal.dismiss();
    };
    // privacy opt form submit
    PrivacyOptOutComponent.prototype.submitOptForm = function (values) {
        var _this = this;
        this.formSubmitAttempt = true;
        if (this.optForm.valid) {
            this.policyNumber = new Policynumber(values.phpolicynum.toUpperCase());
            this.loaded = false;
            var date = new Date();
            this.dateobj.day = date.getDate().toString();
            this.dateobj.month = (date.getMonth() + 1).toString();
            this.dateobj.year = date.getFullYear().toString();
            // this.enrollDate = this.datePipe.transform(CommonUtil.formatDate(this.dateobj));
            // console.log(CommonUtil.getDateNowEST());
            this.enrollDate = this.datePipe.transform(CommonUtil.getDateTimeNowEST());
            // this.enrollTime = date.toTimeString().slice(0, 8);
            this.enrollTime = CommonUtil.getTimeNowEST();
            // console.log('enrolldate-----------' + this.enrollDate);
            // console.log('enrolltime-----------' + this.enrollTime);
            // call's HasOptedOutPrivacyPolicy to check wheather the policy is opted or not
            this.privacyPolicyService.policyOpt(this.policyNumber.symbol, this.policyNumber.policy, values.state.toUpperCase(), values.lastName.toUpperCase(), values.firstName.toUpperCase()).then(function (res) {
                if (!res) {
                    // calling validatepolicyOpt to validate and creates the privacy policy opt
                    _this.privacyPolicyService.validatepolicyOpt(_this.policyNumber.symbol, _this.policyNumber.policy, values.state.toUpperCase(), values.lastName.toUpperCase(), values.firstName.toUpperCase()).then(function (validatePolicyres) {
                        if (validatePolicyres) {
                            // after the success we are loging using service logPrivacyOpt.
                            _this.privacyPolicyService.logPrivacyOpt(_this.policyNumber.symbol, _this.policyNumber.policy, values.state.toUpperCase(), values.lastName.toUpperCase(), values.firstName.toUpperCase(), _this.policyNumber.module, _this.enrollDate, _this.enrollTime).then(function (logOptOutRes) {
                                _this.loaded = true;
                                _this.returnMessage = logOptOutRes ? 'success' : 'fail';
                            }).catch(function (error) {
                                _this.loaded = true;
                            });
                        }
                        else {
                            _this.loaded = true;
                            _this.returnMessage = 'fail';
                            _this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '100', 'errordesc': 'There was no record found for the policy information entered.' +
                                    'Please check your entries and try again.If you have any questions,' +
                                    'please contact customer service at 1-888-888-0080.', 'source': 'privacyOptOutService' }));
                        }
                    }).catch(function (error) {
                        _this.loaded = true;
                    });
                }
                else {
                    _this.loaded = true;
                    _this.returnMessage = 'fail';
                    _this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '100', 'errordesc': 'Already Opted Out,Our records indicate that you have already opted out.' +
                            'This form should only be submitted once.', 'source': 'privacyOptOutService' }));
                }
            }).catch(function (error) {
                _this.loaded = true;
            });
        }
    };
    // Form Utilities
    PrivacyOptOutComponent.prototype.isFieldValid = function (field) {
        return ((!this.optForm.get(field).valid && this.optForm.get(field).touched) ||
            (this.optForm.get(field).untouched && this.formSubmitAttempt));
    };
    // to display error messages
    PrivacyOptOutComponent.prototype.getErrorMessageMsg = function (errors, field) {
        if (errors) {
            if (errors.required === true) {
                if (field === 'firstName') {
                    return this.usermessage.REQUIRED_FIRSTNAME;
                }
                else if (field === 'lastName') {
                    return this.usermessage.REQUIRED_LASTNAME;
                }
                else if (field === 'state') {
                    return 'state is required';
                }
            }
        }
    };
    return PrivacyOptOutComponent;
}());
export { PrivacyOptOutComponent };
