import { Component, OnInit } from '@angular/core';
import { MessageConstants } from '../../../constants/message.constant';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-requestcancel-pending-endorsement',
  template: `
    <div class="modal-header">
      <h4 class="modal-title" id="request-to-cancel-nogo">Pending Transaction</h4>
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ pendingMessage }}  or fill out the
      <a href="{{ cancelFormLocation }}" target="_blank" (click)="dismiss()">Cancel Request Form</a>
                                  &nbsp;and use Submit Documents to upload for processing.</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="close()">
        Ok
      </button>
    </div>
  `,
  styles: []
})
export class RequestcancelPendingEndorsementComponent implements OnInit {

  messageConstants: MessageConstants;
  pendingMessage: string;
  cancelFormLocation: string;

  constructor(public reqCancelPendEDModal: NgbActiveModal) {
    this.pendingMessage = MessageConstants.CANCEL_PENDING_ENDORSEMENT;
    this.cancelFormLocation = '../../../../assets/pdf/PolicyholderRequestToCancel.pdf';
  }

  ngOnInit() {}

  close() {
    this.reqCancelPendEDModal.close();
  }

  dismiss() {
    this.reqCancelPendEDModal.dismiss();
  }
}
