import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../environments/environment';
import { GlobalConstants } from '../../../../constants/global.constant';
import { EdmrService } from '../../../../services/api/edmr/edmr.service';
import { PolicymgtService } from '../../../../services/api/policymgmt/policymgt.service';
import { BWErrorhandler } from '../../../../services/errorhandler';
import { VehicleService } from '../../../../services/policydetails/vehicle.service';
import { Keyvalue } from '../../../../shared/model/keyvalue';
import { RTAFormreq } from '../../../model/api/edmr/rtaformreq';
import { BWError } from '../../../model/error.model';
import { Policynumber } from '../../../model/policynumber';
import { GoogleAnalyticsService } from '../../../services/analytics/google-analytics.service';
import { BwstoreService } from '../../../services/bwstore.service';
import { MessagesService } from '../../../services/messages.service';
import { CommonUtil } from '../../../utils/commonutil';
import { Tracker } from '../../../utils/tracker';
var VehicleSelectComponent = /** @class */ (function () {
    function VehicleSelectComponent(fb, vehicleSelectModal, policyManagementService, edmrService, vehicleService, _messageService, errorHandler, _storeService, googleAnalytics) {
        this.fb = fb;
        this.vehicleSelectModal = vehicleSelectModal;
        this.policyManagementService = policyManagementService;
        this.edmrService = edmrService;
        this.vehicleService = vehicleService;
        this._messageService = _messageService;
        this.errorHandler = errorHandler;
        this._storeService = _storeService;
        this.googleAnalytics = googleAnalytics;
        this.showForm = false;
        this.allowClose = false;
        this.hasError = false;
        this.showLoadingMessage = false;
        this.errorMsg = 'Unable to retrieve your RTA Form at this time.';
        this.loadingMessage = 'Loading your RTA Form';
        this.rtaForm = '';
        this.vehicleSelectMessage = '';
        this.mco = '';
    }
    VehicleSelectComponent.prototype.ngOnInit = function () {
        this.vehicleSelectMessage = 'Request RTA Form';
        this.basicPolicyInfo = this._storeService.getPolicyInfoFromStore();
        this.vehicleSelectForm = this.fb.group({
            'vehicleId': ['', Validators.compose([Validators.required])]
        });
        this.loadVehicles();
    };
    VehicleSelectComponent.prototype.loadVehicles = function () {
        var _this = this;
        this.policyNumber = new Policynumber(this.basicPolicyInfo.policyInfo.PolicyNumber);
        this.mco = this.basicPolicyInfo.policyInfo.MASTER0CO;
        this.vehicleService.getVehicleFullDetails(this.policyNumber.toString(), this.mco)
            .subscribe(function (vehiclesRes) {
            _this.vehiclesResponse = vehiclesRes;
            if (vehiclesRes.Result && vehiclesRes.Result.toLocaleLowerCase() !== 'success') {
                _this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '100', 'errordesc': vehiclesRes.Message, 'source': 'VehicleService' }));
                _this.hasError = true;
                _this.showLoadingMessage = false;
                _this.allowClose = true;
            }
            else {
                // check which vehicle the form should be generated for
                if (vehiclesRes.Vehicles.length === 1) {
                    _this.showLoadingMessage = true;
                    _this.allowClose = false;
                    _this.generateRTAForm(0);
                }
                else {
                    _this.showForm = true;
                    _this.allowClose = true;
                    _this.vehicleOptions = [];
                    for (var _i = 0, _a = vehiclesRes.Vehicles; _i < _a.length; _i++) {
                        var vehicle = _a[_i];
                        _this.keyvalue = new Keyvalue();
                        _this.keyvalue.key = vehicle.Year.concat(' ').concat(vehicle.VehicleName);
                        _this.keyvalue.value = vehicle.VehicleID.toString();
                        _this.vehicleOptions.push(_this.keyvalue);
                    }
                }
            }
        }, function (error) {
            _this.showLoadingMessage = false;
            _this.allowClose = true;
            _this.hasError = true;
            Tracker.logerror('VehicleSelectComponent', 'loadVehicles', 'getVehicleFullDetails', '', error);
        });
    };
    VehicleSelectComponent.prototype.onSubmit = function (formData) {
        this.formSubmitAttempt = true;
        if (this.vehicleSelectForm.valid) {
            this.showLoadingMessage = true;
            this.allowClose = false;
            this.showForm = false;
            this.generateRTAForm(formData.vehicleId - 1);
        }
    };
    VehicleSelectComponent.prototype.isFieldValid = function (field) {
        return ((!this.vehicleSelectForm.get(field).valid && this.vehicleSelectForm.get(field).touched) ||
            (this.vehicleSelectForm.get(field).untouched && this.formSubmitAttempt));
    };
    VehicleSelectComponent.prototype.getErrMessage = function (errors, formCntrlName) {
        return this._messageService.getErrorMessages(errors, formCntrlName);
    };
    VehicleSelectComponent.prototype.generateRTAForm = function (index) {
        var _this = this;
        // then generate the RT Form through the EDMR Forms Service
        this.rtaFormReq = new RTAFormreq();
        this.rtaFormReq.sym = this.policyNumber.symbol;
        this.rtaFormReq.polnum = this.policyNumber.policy;
        this.rtaFormReq.mod = this.policyNumber.module;
        this.rtaFormReq.mco = this.mco;
        this.rtaFormReq.appName = GlobalConstants.APPID;
        this.rtaFormReq.vin = this.vehiclesResponse.Vehicles[index].VIN;
        this.rtaFormReq.libraryContext = environment.pointContext;
        this.policyManagementService.createRTAForm(this.rtaFormReq).subscribe(function (rtaFormResData) {
            _this.rtaFormRes = rtaFormResData;
            if (!CommonUtil.isEmpty(_this.rtaFormRes) && _this.rtaFormRes.status === '0') {
                Tracker.loginfo('VehicleSelectComponent', 'generateRTAForm', 'generateRTAForm', 'Generated RTA Form for ' + _this.policyNumber.toFormattedString());
                _this.rtaForm = _this.rtaFormRes.byteArray;
                _this.downloadRTAForm(_this.rtaForm);
                _this.dismiss();
            }
            else {
                _this.hasError = true;
                _this.showLoadingMessage = false;
                _this.allowClose = true;
            }
        }, function (error) {
            _this.allowClose = true;
            _this.hasError = true;
            _this.googleAnalytics.trackEvent('Error', 'RTAForm Error', 'policyManagementServiceError', 200);
            Tracker.logerror('VehicleSelectComponent', 'generateRTAForm', '', 'Error generating RTA Form for policy '
                + _this.policyNumber.toString(), error);
        });
    };
    VehicleSelectComponent.prototype.downloadRTAForm = function (pdf) {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(CommonUtil.base64toBlob(pdf, 'application/pdf'));
            return;
        }
        // All other browsers
        var linkSource = "data:application/pdf;base64," + pdf;
        var downloadLink = document.createElement('a');
        var fileName = 'RTAForm.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        this.googleAnalytics.trackEvent('RTAForm', 'MyPolicy', 'RTAForm Generated', 200);
        downloadLink.click();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            document.body.removeChild(downloadLink);
            // window.URL.revokeObjectURL(data);
        });
    };
    VehicleSelectComponent.prototype.navigate = function () {
        this.vehicleSelectModal.close();
    };
    VehicleSelectComponent.prototype.dismiss = function () {
        this.vehicleSelectModal.dismiss();
    };
    return VehicleSelectComponent;
}());
export { VehicleSelectComponent };
