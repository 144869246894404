import { GlobalConstants } from '../../../constants/global.constant';
import { CardHolder, EFTCard, PolicyKey, Processcardreq } from '../../../shared/model/api/dbcardpayment/processcardreq.model';
import { Policynumber } from '../../../shared/model/policynumber';
import { CommonUtil } from '../../../shared/utils/commonutil';
import { BwappService } from '../../bwapp.service';
import { BWErrorhandler } from '../../errorhandler';
import * as i0 from "@angular/core";
import * as i1 from "../../bwapp.service";
import * as i2 from "../../errorhandler";
var DbcardpaymentService = /** @class */ (function () {
    function DbcardpaymentService(appService, errorHandler) {
        this.appService = appService;
        this.errorHandler = errorHandler;
        this.globlalConstants = GlobalConstants;
    }
    DbcardpaymentService.prototype.processCardInstlmntPayment = function (ccformdata, fullpolicyInfo, cardtype) {
        this.policyNumber = new Policynumber(fullpolicyInfo.policyInfo.PolicyNumber);
        this.processCardReq = new Processcardreq();
        this.processCardReq.amount = '0';
        this.processCardReq.eCommerceIndicator = 'ECOMMERCE';
        this.processCardReq.browserType = 'Chrome69';
        this.processCardReq.eftEnrollment = true;
        this.processCardReq.transactionSource = 'POS';
        this.processCardReq.transactionSourceSystem = 'BWM';
        this.processCardReq.applyPayment = false;
        var policyKey = new PolicyKey();
        policyKey.masterCompany = fullpolicyInfo.policyInfo.MASTER0CO;
        policyKey.policyCompany = '00';
        policyKey.symbol = this.policyNumber.symbol;
        policyKey.policyNumber = this.policyNumber.policy;
        policyKey.module = this.policyNumber.module;
        this.processCardReq.policyKey = policyKey;
        var eftCard = new EFTCard();
        eftCard.eftCardNumber = CommonUtil.formatCC(ccformdata.ccNumber);
        eftCard.eftCardType = cardtype;
        eftCard.eftCardExpirationDate = CommonUtil.formatCCExpDt(ccformdata.ccExp);
        eftCard.eftCardCVV = ccformdata.ccCvc;
        this.processCardReq.eftCard = eftCard;
        var cardHolder = new CardHolder();
        cardHolder.eftFirstName = ccformdata.fname;
        cardHolder.eftLastName = ccformdata.lname;
        cardHolder.eftStreetAddress = ccformdata.address;
        cardHolder.eftCity = ccformdata.city;
        cardHolder.eftState = ccformdata.state;
        cardHolder.eftCountry = 'US';
        cardHolder.eftZipCode = ccformdata.zip;
        this.processCardReq.eftCardHolder = cardHolder;
        // Tracker.log('Biggest Request of new BW.com ', this.processCardReq);
        return this.appService.post(this.globlalConstants.DBCARD_PROCESS_INSTALLEMENT, this.processCardReq);
    };
    DbcardpaymentService.ngInjectableDef = i0.defineInjectable({ factory: function DbcardpaymentService_Factory() { return new DbcardpaymentService(i0.inject(i1.BwappService), i0.inject(i2.BWErrorhandler)); }, token: DbcardpaymentService, providedIn: "root" });
    return DbcardpaymentService;
}());
export { DbcardpaymentService };
