export class Requesttocancelchecksres {

  RequestToCancelInfo: RequestToCancelInfo;
  Result: string;
  Message: string;


  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class  RequestToCancelInfo {
  StateAccess: boolean;
  AgentAccess: boolean;
  IsNBPolicy: boolean;
  IsCancelEligible: boolean;
  SR22FR44Status: string;
  IsShortRateState: boolean;
  HasRenewal: boolean;
  SameDayState: boolean;
  HasPendingEndorsement: boolean;
  HasPendingCancelEndorsement: boolean;


  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

}
