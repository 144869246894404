import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { GlobalConstants } from '../../../constants/global.constant';
import { Policynumber } from '../../../shared/model/policynumber';
import { BwstoreService } from '../../../shared/services/bwstore.service';
import { BwappService } from '../../bwapp.service';
import { BWErrorhandler } from '../../errorhandler';
import * as i0 from "@angular/core";
import * as i1 from "../../bwapp.service";
import * as i2 from "../../../shared/services/bwstore.service";
import * as i3 from "../../errorhandler";
var GoPaperlessService = /** @class */ (function () {
    function GoPaperlessService(appService, storeService, errorHandler) {
        this.appService = appService;
        this.storeService = storeService;
        this.errorHandler = errorHandler;
        this.globlalConstants = GlobalConstants;
        this._baseURL = GlobalConstants.baseURL;
    }
    GoPaperlessService.prototype.getAutoAuthValues = function (policynumber, mco) {
        // Tracker.log('PolicyNumber: ' + policynumber, 'MCO: ' + mco);
        var params = new HttpParams()
            .set('szPolicyNumber', policynumber)
            .set('szMCO', mco);
        return this.appService.get(this.globlalConstants.GP_AUTO_AUTH_URL, params);
    };
    GoPaperlessService.prototype.changeUserEmail = function () {
        var _this = this;
        return this.appService
            .post(this.globlalConstants.GP_UPDATE_EMAIL_URL, { phresponse: { body: this.changeuseremailreq } })
            .pipe(map(function (data) {
            _this.changeuseremailres = data.phresponse;
            // Tracker.log('Gopaperless Service changeUserEmail Response: ', this.changeuseremailres);
        }));
    };
    GoPaperlessService.prototype.updateEPolicyEmail = function (email, policynumber, mco) {
        this.policyNumber = new Policynumber(policynumber);
        var params = new HttpParams()
            .set('szEmailAddress', email)
            .set('szPolicyNumber', this.policyNumber.toString())
            .set('szMCO', mco)
            .set('bnlResendAuthEmail', 'false')
            .set('szSource', 'BWM');
        return this.appService.get(this.globlalConstants.GP_UPDATE_EMAIL_URL, params);
    };
    GoPaperlessService.prototype.unenrollGP = function (policynumber, mco) {
        // Tracker.log('PolicyNumber: ' + policynumber, 'MCO: ' + mco);
        var params = new HttpParams()
            .set('szPolicyNumber', policynumber)
            .set('szMCO', mco)
            .set('szSource', this.globlalConstants.APPID);
        return this.appService.get(this.globlalConstants.GP_UNENROLL_URL, params);
    };
    GoPaperlessService.prototype.isGPLRolledOut = function (state, ratebook, mco, pco) {
        // Tracker.log('State: ', state, 'MCO: ', mco, 'Ratebook: ', ratebook, 'PCO:', pco);
        var params = new HttpParams()
            .set('strState', state)
            .set('strRatebook', ratebook)
            .set('strMCO', mco)
            .set('strPCO', pco);
        return this.appService.get(this.globlalConstants.IS_GPL_ROLLED_OUT_URL, params);
    };
    GoPaperlessService.prototype.enrollGP = function (createepolicyrequest) {
        // Tracker.log('PolicyNumber: ' + createepolicyrequest.szPolicyNbr, 'MCO: ' + createepolicyrequest.szMCO);
        return this.appService.post(this.globlalConstants.GP_ENROLL_URL, createepolicyrequest);
    };
    GoPaperlessService.prototype.setEPolicyFlag = function (ePolicyreq) {
        return this.appService.post(this.globlalConstants.GP_SET_EPOLICY_FLAG, ePolicyreq);
    };
    GoPaperlessService.ngInjectableDef = i0.defineInjectable({ factory: function GoPaperlessService_Factory() { return new GoPaperlessService(i0.inject(i1.BwappService), i0.inject(i2.BwstoreService), i0.inject(i3.BWErrorhandler)); }, token: GoPaperlessService, providedIn: "root" });
    return GoPaperlessService;
}());
export { GoPaperlessService };
