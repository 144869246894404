
    <div class="modal-header">
      <h4 class="modal-title"><i class="fas fa-info-circle text-danger"></i> <strong>Email Address Conflict</strong></h4>
     <!--
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
      -->
    </div>
    <div class="modal-body">
      <p>There was a conflict with the currently entered email address. Please go back to Step 2 and provide a different email address.</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="navigate()">OK</button>
    </div>
  