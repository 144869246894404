import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConstants } from '../../../constants/global.constant';
import { Idcardreq } from '../../../shared/model/api/document/idcardreq';
import { BwappService } from '../../bwapp.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  private globlalConstants = GlobalConstants;
  _baseURL: string;

  constructor(private appService: BwappService) { }

  retrieveIDCard(idcardrequest: Idcardreq) {
    return this.appService.post(this.globlalConstants.PROCESS_IDCARD, idcardrequest);
  }

  getPolicyDocuments(policyNumber: string, mco: string) {
    const params = new HttpParams()
    .set('policyNumber', policyNumber)
    .set('mco', mco);

    return this.appService.get(this.globlalConstants.GET_POLICYDOCUMENTS, params);
  }

  getPolicyContract(policyNumber: string, mco: string) {
    const params = new HttpParams()
    .set('policyNumber', policyNumber)
    .set('mco', mco);

    return this.appService.get(this.globlalConstants.GET_POLICY_CONTRACT, params);
  }
}
