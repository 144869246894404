var UpdatePHRequest = /** @class */ (function () {
    function UpdatePHRequest(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return UpdatePHRequest;
}());
export { UpdatePHRequest };
var UpdatePHResponse = /** @class */ (function () {
    function UpdatePHResponse(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return UpdatePHResponse;
}());
export { UpdatePHResponse };
