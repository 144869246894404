/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../app-errors/app-errors.component.ngfactory";
import * as i2 from "../app-errors/app-errors.component";
import * as i3 from "../../services/messages.service";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "@angular/forms";
import * as i7 from "./unenroll-gopaperless.component";
import * as i8 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import * as i9 from "../../services/bwstore.service";
import * as i10 from "../../../services/myprofile/myprofile.service";
import * as i11 from "../../../services/errorhandler";
var styles_UnEnrollGoPaperlessComponent = [];
var RenderType_UnEnrollGoPaperlessComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UnEnrollGoPaperlessComponent, data: {} });
export { RenderType_UnEnrollGoPaperlessComponent as RenderType_UnEnrollGoPaperlessComponent };
function View_UnEnrollGoPaperlessComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "alert alert-success alert-dismissible fade show ng-star-inserted"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" You are no longer enrolled in Go Paperless. "]))], null, null); }
function View_UnEnrollGoPaperlessComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-app-errors", [], null, null, null, i1.View_AppErrorsComponent_0, i1.RenderType_AppErrorsComponent)), i0.ɵdid(1, 114688, null, 0, i2.AppErrorsComponent, [i3.MessagesService, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_UnEnrollGoPaperlessComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["data-dismiss", "modal"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.unenrollGP() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Unenroll"]))], null, null); }
function View_UnEnrollGoPaperlessComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["disabled", "true"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-circle-notch fa-spin"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0Unenroll"]))], null, null); }
function View_UnEnrollGoPaperlessComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UnEnrollGoPaperlessComponent_4)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UnEnrollGoPaperlessComponent_5)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loaded; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.loaded; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_UnEnrollGoPaperlessComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Unenroll From Go Paperless"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.errorModal.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(6, 0, null, null, 19, "form", [["class", "my-4"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 8).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 16384, null, 0, i6.ɵangular_packages_forms_forms_bg, [], null, null), i0.ɵdid(8, 4210688, null, 0, i6.NgForm, [[8, null], [8, null]], null, null), i0.ɵprd(2048, null, i6.ControlContainer, null, [i6.NgForm]), i0.ɵdid(10, 16384, null, 0, i6.NgControlStatusGroup, [[4, i6.ControlContainer]], null, null), (_l()(), i0.ɵeld(11, 0, null, null, 4, "div", [["class", "d-flex justify-content-center border-0"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UnEnrollGoPaperlessComponent_1)), i0.ɵdid(13, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UnEnrollGoPaperlessComponent_2)), i0.ɵdid(15, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(16, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 3, "div", [["class", "form-row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 2, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 1, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵted(20, null, [" ", " "])), (_l()(), i0.ɵeld(21, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 1, "button", [["class", "btn btn-secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.errorModal.dismiss("Close click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Cancel "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UnEnrollGoPaperlessComponent_3)), i0.ɵdid(25, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = ((_co.returnUnenrollMsg !== "") && (_co.returnUnenrollMsg === "success")); _ck(_v, 13, 0, currVal_7); var currVal_8 = ((_co.returnUnenrollMsg !== "") && (_co.returnUnenrollMsg === "failure")); _ck(_v, 15, 0, currVal_8); var currVal_10 = ((_co.returnUnenrollMsg === "") || ((_co.returnUnenrollMsg !== "") && (_co.returnUnenrollMsg === "failure"))); _ck(_v, 25, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 10).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 10).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 10).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 10).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 10).ngClassValid; var currVal_5 = i0.ɵnov(_v, 10).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 10).ngClassPending; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = _co.unenrollMsg; _ck(_v, 20, 0, currVal_9); }); }
export function View_UnEnrollGoPaperlessComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-unenroll-gopaperless", [], null, null, null, View_UnEnrollGoPaperlessComponent_0, RenderType_UnEnrollGoPaperlessComponent)), i0.ɵdid(1, 114688, null, 0, i7.UnEnrollGoPaperlessComponent, [i8.NgbActiveModal, i9.BwstoreService, i10.MyprofileService, i11.BWErrorhandler, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnEnrollGoPaperlessComponentNgFactory = i0.ɵccf("app-unenroll-gopaperless", i7.UnEnrollGoPaperlessComponent, View_UnEnrollGoPaperlessComponent_Host_0, {}, { emitService: "emitService" }, []);
export { UnEnrollGoPaperlessComponentNgFactory as UnEnrollGoPaperlessComponentNgFactory };
