import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as BwLoginActions from '../../store/actions/bwlogin.actions';
import * as BwPolicyInfoActions from '../../store/actions/bwpolicy.actions';
import * as NewUserActions from '../../store/actions/custregistration.actions';
import * as BwPolicyCancelActions from '../../store/actions/bwpolicycancel.actions';
import * as BwCoveragesActions from '../../store/actions/bwcoverages.actions';
import * as BwEndorseInfoActions from '../../store/actions/bwendorsement.action';

import { BwLoginState } from '../../store/state/bwlogin.state';
import { BwPolicyInfoState } from '../../store/state/bwpolicy.state';
import { RegistrationState } from '../../store/state/custregistration.state';
import { FullPolicyDetails } from '../model/api/bwibic/fullpolicydetails.model';
import { PolicyHolder } from '../model/authentication/authenticatephres';
import { NewUser } from '../model/newuser.model';

import { BwpolicycancelState } from '../../store/state/bwpolicycancel.state';
import { RequesttocancelStoreInfo } from '../model/requesttocancelinfo.model';
import { VehicleEndtInfoStore } from '../model/endorsements/vehicle-endtinfo.model';
import { ValidValuesResponse } from '../model/api/validvalues/valid-values-res.model';
import { BwCoveragesState } from '../../store/state/bwcoverages.state';
import { EndorseMetainfo } from '../model/endorsements/endorse-metainfo.model';
import { BwEndorsementState } from '../../store/state/bwendorsement.state';
import { EndorseVehicleInfo } from '../model/api/endorsement/endorse-vehicle-info';

@Injectable({
  providedIn: 'root'
})
export class BwstoreService {

  // Declare NewUser to retrieve from Store
  newUser$: Observable<NewUser>;
  newUser: NewUser;

  // Declare Observable Login to retrieve from Store
  loggedInUser$: Observable<PolicyHolder>;
  currentUserLoginInfo: PolicyHolder;

  // Declare Observable FullPolicyDetails to retrieve from Store
  fullPolicydetails$: Observable<FullPolicyDetails>;
  fullPolicydetails: FullPolicyDetails;

  // Declare Observable RequestToCancelInfo to retrieve from Store
  requestToCancelInfo$: Observable<RequesttocancelStoreInfo>;
  requestToCancelInfo: RequesttocancelStoreInfo;

  // Declare Observable VehicleEndtInfo to retrieve from Store -- TODO: Delete these after design
  vehicleEndtInfo$: Observable<VehicleEndtInfoStore>;
  vehicleEndtInfo: VehicleEndtInfoStore;

  bwEndorsementInfo$: Observable<EndorseMetainfo>;
  bwEndorsementInfo: EndorseMetainfo;

  // Declare Observable VehicleEndtInfo to retrieve from Store
  bwCoverages$: Observable<ValidValuesResponse>;
  bwCoverages: ValidValuesResponse = new ValidValuesResponse();

  constructor(private bwLoginStore: Store<BwLoginState>, private newUserStore: Store<RegistrationState>,
    private bwPolicyInfoStore: Store<BwPolicyInfoState>, private bwPolicyCancelInfoStore: Store<BwpolicycancelState>,
    private bwEndorsementInfoStore: Store<BwEndorsementState>, private bwCoveragesStore: Store<BwCoveragesState>) { }

  // LOGGEDIN USER STORE
  createLoginInfo(payload: PolicyHolder) {
    // Tracker.log('Storing login info to store');
    this.bwLoginStore.dispatch(new BwLoginActions.CreateLoginInfo(payload));
    this.syncUserSessionToBrowserSession(payload);
  }

  updateLoginInfo(payload: PolicyHolder) {
    this.bwLoginStore.dispatch(new BwLoginActions.UpdateLoginInfo(payload));
    this.syncUserSessionToBrowserSession(payload);
  }

  deleteLoginInfo(payload: PolicyHolder) {
    this.bwLoginStore.dispatch(new BwLoginActions.RemoveLoginInfo(payload));
    this.removeUserSessionFromBrowserSession();
  }

  getLoginInfoFromStore(): PolicyHolder {
    this.loggedInUser$ = this.bwLoginStore.select('policyHolder');
    this.loggedInUser$.subscribe((loginInfo: PolicyHolder) => { this.currentUserLoginInfo = loginInfo; });

    // Check if User Info not found in Store then check in Browser session - This is a backfill logic
    if (this.currentUserLoginInfo.jwtToken === undefined || this.currentUserLoginInfo.jwtToken === null) {
      const customerLoginInfo: PolicyHolder = JSON.parse(sessionStorage.getItem('BWUserSession'));
      if (customerLoginInfo && customerLoginInfo !== undefined &&
        customerLoginInfo.jwtToken !== undefined && customerLoginInfo.jwtToken != null && customerLoginInfo.jwtToken !== '') {
        this.bwLoginStore.dispatch(new BwLoginActions.CreateLoginInfo(customerLoginInfo));
        this.currentUserLoginInfo = customerLoginInfo;
      }
      // Tracker.log('Retrieving the Login Info ::: ', this.currentUserLoginInfo);
    }


    return this.currentUserLoginInfo;
  }

  syncUserSessionToBrowserSession(payload: PolicyHolder) {
    sessionStorage.setItem('BWUserSession', JSON.stringify(payload));
  }

  removeUserSessionFromBrowserSession() {
    sessionStorage.removeItem('BWUserSession');
  }

  getBWUserTokenFromBrowser(): string {
    const customerLoginInfo: PolicyHolder = JSON.parse(sessionStorage.getItem('BWUserSession'));
    if (customerLoginInfo && customerLoginInfo !== undefined &&
      customerLoginInfo.jwtToken !== undefined && customerLoginInfo.jwtToken != null && customerLoginInfo.jwtToken !== '') {
      return customerLoginInfo.jwtToken;
    }
  }

  updateStoreFromSession() {
    // const currentStore = this.getBWStoreFromSession();
    // this._store.merge(currentStore);
  }

  // REGISTRATION USER STORE
  updateRegistrationState(payload: NewUser) {
    this.newUserStore.dispatch(new NewUserActions.NewUserUpdate(payload));
  }

  removeRegistrationState() {
    this.newUserStore.dispatch(new NewUserActions.NewUserRemove(1));
  }

  getCurrentregistrationState(): NewUser {
    this.newUser$ = this.newUserStore.select('newUser');
    this.newUser$.subscribe((newuserobj: NewUser) => { this.newUser = newuserobj; });

    return this.newUser;
  }

  // POLICYINFO STORE FUNCTIONS

  addPolicyInfo(payload: FullPolicyDetails) {
    // Tracker.log('Storing Policy info to store');
    this.bwPolicyInfoStore.dispatch(new BwPolicyInfoActions.CreatePolicyInfo(payload));
    this.syncPolicyInfoToBrowserSession(payload);
  }

  updatePolicyInfo(payload: FullPolicyDetails) {
    this.bwPolicyInfoStore.dispatch(new BwPolicyInfoActions.UpdatePolicyInfo(payload));
    this.syncPolicyInfoToBrowserSession(payload);
  }

  deletePolicyInfo(payload: FullPolicyDetails) {
    this.bwPolicyInfoStore.dispatch(new BwPolicyInfoActions.RemovePolicyInfo(payload));
    this.removePolicyFromBrowserSession();
  }

  getPolicyInfoFromStore(): FullPolicyDetails {
    this.fullPolicydetails$ = this.bwPolicyInfoStore.select('bwPolicyDetails');

    this.fullPolicydetails$.subscribe((polInfoStore: FullPolicyDetails) => {
      this.fullPolicydetails = polInfoStore;
      // Tracker.log('Retrieving Policy Details from Store:::', this.fullPolicydetails);
    });

    // Check if Policy Info not found in Store then check in Browser session - This is a backfill logic
    if (!this.fullPolicydetails || (this.fullPolicydetails && !this.fullPolicydetails.policyInfo)) {
      const policyDetails = JSON.parse(sessionStorage.getItem('BWPolicyInfo'));
      // Tracker.log('Policy Details in Session 1::: ', policyDetails);
      if (policyDetails && policyDetails.policyInfo && policyDetails.policyInfo.POLICY0NUM !== '') {
        this.fullPolicydetails = policyDetails;
        // Tracker.log('Retrieving Policy Details from Session :::', this.fullPolicydetails);
        this.bwPolicyInfoStore.dispatch(new BwPolicyInfoActions.CreatePolicyInfo(policyDetails));
      }
    }

    // Tracker.log('Retrieving Policy Details from Store/Session 2:::', this.fullPolicydetails);
    return this.fullPolicydetails;
  }

  syncPolicyInfoToBrowserSession(payload: FullPolicyDetails) {
    sessionStorage.setItem('BWPolicyInfo', JSON.stringify(payload));
  }

  removePolicyFromBrowserSession() {
    sessionStorage.removeItem('BWPolicyInfo');
  }

  // REQUEST TO CANCEL STORE INFO

  addRequestToCancelPolicyInfo(payload: RequesttocancelStoreInfo) {
    this.bwPolicyCancelInfoStore.dispatch(new BwPolicyCancelActions.CreatePolicyCancelInfo(payload));
    this.syncRequestToCancelPolicyInfoToBrowserSession(payload);
  }

  updateRequestToCancelPolicyInfo(payload: RequesttocancelStoreInfo) {
    this.bwPolicyCancelInfoStore.dispatch(new BwPolicyCancelActions.UpdatePolicyCancelInfo(payload));
    this.syncRequestToCancelPolicyInfoToBrowserSession(payload);
  }

  deleteRequestToCancelPolicyInfo(payload: RequesttocancelStoreInfo) {
    this.bwPolicyCancelInfoStore.dispatch(new BwPolicyCancelActions.RemovePolicyCancelInfo(payload));
    this.removeRequestToCancelPolicyInfoFromBrowserSession();
  }

  geRequestToCancelPolicyInfoFromStore(): RequesttocancelStoreInfo {
    this.requestToCancelInfo$ = this.bwPolicyCancelInfoStore.select('requestToCancelInfo');

    this.requestToCancelInfo$.subscribe((cancelInfoStore: RequesttocancelStoreInfo) => {
      this.requestToCancelInfo = cancelInfoStore;
    });

    console.log('Retrieving the Cancel Info from Store :::: ');
    // Check if Request To Cancel Policy Info not found in Store then check in Browser session - This is a backfill logic
    if (!this.requestToCancelInfo || !this.requestToCancelInfo.cancelChecks) {
      const reqToCanceDetails = JSON.parse(sessionStorage.getItem('BWRequestToCancelPolicyInfo'));
      if (reqToCanceDetails) {
        this.requestToCancelInfo = reqToCanceDetails;
        this.bwPolicyCancelInfoStore.dispatch(new BwPolicyCancelActions.CreatePolicyCancelInfo(reqToCanceDetails));
      }
    }
    return this.requestToCancelInfo;
  }

  syncRequestToCancelPolicyInfoToBrowserSession(payload: RequesttocancelStoreInfo) {
    sessionStorage.setItem('BWRequestToCancelPolicyInfo', JSON.stringify(payload));
  }

  removeRequestToCancelPolicyInfoFromBrowserSession() {
    sessionStorage.removeItem('BWRequestToCancelPolicyInfo');
  }

  // ENDPORSEMENTS STORE FUNCTIONS
  addBwEndorsementInfo(payload: EndorseMetainfo) {
    this.bwEndorsementInfoStore.dispatch(new BwEndorseInfoActions.CreateEndorsementInfo(payload));
    this.syncPayloadToBrowserSession(payload, 'BWEndorseInfo');
  }

  updateBwEndorsementInfo(payload: EndorseMetainfo) {
    this.bwEndorsementInfoStore.dispatch(new BwEndorseInfoActions.UpdateEndorsementInfo(payload));
    this.syncPayloadToBrowserSession(payload, 'BWEndorseInfo');
  }

  deleteBwEndorsementInfo(payload: EndorseMetainfo) {
    this.bwEndorsementInfoStore.dispatch(new BwEndorseInfoActions.RemoveEndorsementInfo(payload));
    this.removePayloadFromBrowserSession('BWEndorseInfo');
  }

  getEndorsementInfoFromStore(): EndorseMetainfo {
    this.bwEndorsementInfo$ = this.bwEndorsementInfoStore.select('bwEndorsementInfo');

    this.bwEndorsementInfo$.subscribe((bwEndorsementInfo: EndorseMetainfo) => {
      this.bwEndorsementInfo = bwEndorsementInfo;
    });

    // Check if Endorsement Info not found in Store then check in Browser session - This is a backfill logic
    if (!this.bwEndorsementInfo || (this.bwEndorsementInfo && !this.bwEndorsementInfo.endorseType)) {
      const endorsementInfo = JSON.parse(sessionStorage.getItem('BWEndorseInfo'));

      if (endorsementInfo && endorsementInfo.endorseType !== '') {
        this.bwEndorsementInfo = endorsementInfo;
        this.bwEndorsementInfoStore.dispatch(new BwEndorseInfoActions.CreateEndorsementInfo(endorsementInfo));
      }
    }

    return this.bwEndorsementInfo;
  }

  getEndorsVehicleInfoFromStore(): EndorseVehicleInfo {
    this.bwEndorsementInfo = this.getEndorsementInfoFromStore();
    const endorsementReq = this.bwEndorsementInfo.endorsementReqInfo;

    if (endorsementReq.endorseVehicleInfo) {
      return endorsementReq.endorseVehicleInfo;
    } else {
      return new EndorseVehicleInfo();
    }

  }


  /*** TODO: Need to delete all the below  VehicleEndtInfoStore functions - Starts  */
  // VEHICLE ENDORSMENT STORE INFO
  addVehicleEndtDetail(payload: VehicleEndtInfoStore) {
    this.syncVehicleEndtDetailToBrowserSession(payload);
  }

  updateVehicleEndtDetail(payload: VehicleEndtInfoStore) {
    // this.bwPolicyInfoStore.dispatch(new VehicleEndtInfoActions.UpdateVehicleEndtInfo(payload));
    this.syncVehicleEndtDetailToBrowserSession(payload);
  }

  deleteVehicleEndtDetail(payload: VehicleEndtInfoStore) {
    // this.bwPolicyInfoStore.dispatch(new VehicleEndtInfoActions.RemoveVehicleEndtInfo(payload));
    this.removeVehicleEndtDetailFromBrowserSession();
  }

  getVehicleEndtDetailFromStore(): VehicleEndtInfoStore {
    this.vehicleEndtInfo$ = this.bwPolicyInfoStore.select('vehicleEndtInfo');

    this.vehicleEndtInfo$.subscribe((vehicleEndtInfoStore: VehicleEndtInfoStore) => {
      this.vehicleEndtInfo = vehicleEndtInfoStore;
    });

    console.log('Retrieving the VehicleEndt Info from Store :::: ');
    // Check if VehicleEndtInfo not found in Store then check in Browser session - This is a backfill logic
    if (!this.vehicleEndtInfo || !this.vehicleEndtInfo) {
      const vehEndtDetail = JSON.parse(sessionStorage.getItem('VehicleEndtInfo'));
      if (vehEndtDetail) {
        this.vehicleEndtInfo = vehEndtDetail;
        // this.bwPolicyInfoStore.dispatch(new VehicleEndtInfoActions.CreateVehicleEndtInfo(vehEndtDetail));
      }
    }
    return this.vehicleEndtInfo;
  }

  syncVehicleEndtDetailToBrowserSession(payload: VehicleEndtInfoStore) {
    sessionStorage.setItem('VehicleEndtInfo', JSON.stringify(payload));
  }


  removeVehicleEndtDetailFromBrowserSession() {
    sessionStorage.removeItem('VehicleEndtInfo');
  }

  /*** TODO: Need to delete all the below  VehicleEndtInfoStore functions - Ends */

  // Delete all the Store Entities
  deleteStore() {
    this.deleteLoginInfo(new PolicyHolder());
    this.deletePolicyInfo(new FullPolicyDetails());
    this.deleteRequestToCancelPolicyInfo(new RequesttocancelStoreInfo());
    this.removeBWCoverages();
  }

  // Valid Values STORE INFO
  addBWCoverages(payload: ValidValuesResponse) {
    this.bwCoveragesStore.dispatch(new BwCoveragesActions.CreateValidValuesInfo(payload));
    sessionStorage.setItem('BWCoverages', JSON.stringify(payload));
  }

  removeBWCoverages() {
    this.bwCoveragesStore.dispatch(new BwCoveragesActions.RemoveValidValuesInfo(new ValidValuesResponse()));
    sessionStorage.removeItem('BWCoverages');
  }

  getBWCoveragesFromStore(): ValidValuesResponse {
    // sessionStorage.getItem('BWCoverages');
    this.bwCoverages$ = this.bwCoveragesStore.select('bwCoverages');

    this.bwCoverages$.subscribe((vvRes: ValidValuesResponse) => {
      this.bwCoverages = vvRes;
    });

    // Check if bwCoverages not found in Store then check in Browser session - This is a backfill logic
    if (!this.bwCoverages || this.bwCoverages !== undefined) {
      const bwCovgs = JSON.parse(sessionStorage.getItem('BWCoverages'));
      if (bwCovgs) {
        this.bwCoverages = bwCovgs;
      }
    }
    return this.bwCoverages;
  }


  // Global functions

  syncPayloadToBrowserSession(payload: any, storageItemId: string) {
    sessionStorage.setItem(storageItemId, JSON.stringify(payload));
  }

  removePayloadFromBrowserSession(storageItemId: string) {
    sessionStorage.removeItem(storageItemId);
  }

}
