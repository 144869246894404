
<main id="main" class="main">
    <div class="container">
        
  <div  class="d-flex justify-content-center">
      <div class="alert">  
            <div class="text-danger inline-icon padding-left" >
                <div class="text-center">
                <p>Sorry, we cannot currently offer an online quote in your area.
                </p>
                <!-- <a href="../../../assets/html/test.html?zip={{mediaAlphazipCode}}" class="heading-6" target="_blank">Click here for more options</a> -->
                </div>
                
            </div>
            
      </div>
      
    </div>
    
  
      </div>
      </main>
