/// <reference path="../../../../../../node_modules/@types/googlemaps/index.d.ts" />

import { Component, OnInit, ViewChild, Input } from '@angular/core';

@Component({
  selector: 'app-googlemap',
  template: `
      <div #gmap style=”width:100%;height:400px”>GMAP</div>
  `,
  styles: []
})
export class GooglemapComponent implements OnInit {

  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;
  geocoder: google.maps.Geocoder;

  @Input() address: string;

  constructor() { }

  ngOnInit() {
    this.geocoder = new google.maps.Geocoder();
    const address = '103 South Street, 44024';
    this.geocoder.geocode( { 'address': address}, function(results, status) {
      if (this.status === 'OK') {
        this.map.setCenter(results[0].geometry.location);
        const marker = new google.maps.Marker({
            map: this.map,
            position: results[0].geometry.location
        });
      } else {
        alert('Geocode was not successful for the following reason: ' + status);
      }
    });

  }

  viewGoogleMap() {

    const mapProp = {
      center: new google.maps.LatLng(18.5793, 73.8143),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

  }


  initialize() {
    this.geocoder = new google.maps.Geocoder();
    const latlng = new google.maps.LatLng(-34.397, 150.644);
    const mapOptions = {
      zoom: 8,
      center: latlng
    };
    this.map = new google.maps.Map(document.getElementById('map'), mapOptions);
  }

}
