import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { MessageConstants } from '../../constants/message.constant';
import { DocusignService } from '../../services/api/esig/docusign.service';
import { EsigService } from '../../services/api/esig/esig.service';
import { BWErrorhandler } from '../../services/errorhandler';
import { Docusignres } from '../model/api/esig/docusignres';
import { Envelope } from '../model/api/esig/envelop.model';
import { EnvelopesResp } from '../model/api/esig/esigenvelopres.model';
import { PolicyHolder } from '../model/authentication/authenticatephres';
import { BWError } from '../model/error.model';
import { EsigMessages } from '../model/esignmessages.model';
import { CommonUtil } from '../utils/commonutil';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class EsigmessagesService {

  constructor(private authService: AuthenticationService, private esigService: EsigService,
    private docuSignService: DocusignService, private errorHandler: BWErrorhandler, ) { }


  verifyEsigStatus(phuser: PolicyHolder): Promise<EsigMessages> {
    return new Promise((resolve, reject) => {

      let esignMessages = new EsigMessages();

      this.getEnvelopes(phuser).then((envelopes: Envelope[]) => {
        // Tracker.log('ESIG MESSAGES Envelopes from SubMethod ', envelopes);
        if (envelopes && envelopes.length > 0) {
          esignMessages = CommonUtil.getPendingEnvelopeMessage(envelopes);
          const docId = esignMessages.envelopeId.concat(esignMessages.recepientId);
          if (esignMessages &&  esignMessages.hasPendingEnvelops === true && docId !== phuser.processedDocId) {
            if (esignMessages.recepientType === 'PrimaryNamedInsured') {

              this.docuSignService.getCeremonyURL(esignMessages.envelopeId, esignMessages.recepientId)
                .subscribe((urlData: Docusignres) => {
                  // Tracker.log('2. ESIG MESSAGES : Invoked docuSignService.getCeremonyURL :::', urlData);
                  if (urlData) {
                    if (phuser.stateMIHasEnvelope && phuser.hasRenewal) {  // keerthana starts
                      esignMessages.envMessage = MessageConstants.ESIG_PENDING_STATE_MESSAGE.replace('{0}',
                      formatDate(phuser.renewalPolEffDate, 'MM/dd/yyyy', 'en-US'));

                    } else {
                    esignMessages.envMessage = MessageConstants.ESIG_PENDING_PNI_MESSAGE.replace('{0}',
                          formatDate(esignMessages.envelopeExpiryDate, 'MM/dd/yyyy', 'en-US'));
                        }
                          // const docsignpath = 'docusign?p=' + docId; // TEMP
                    esignMessages.ceremonyURL =  urlData.url.value; // `${environment.apiServiceUrl}${docsignpath}` ;
                    // Tracker.log('Docusign URL ....................... ', urlData.url.value);
                    esignMessages.envMessageId = 'DOCUSIGN';
                    resolve(esignMessages);
                  } else {
                    return reject(this.errorHandler.handleApplicationError(new BWError(
                      {'errorcode': '100', 'errordesc': MessageConstants.RESPCODE_100, 'source': 'DocuSignService'})));
                  }
                });
            } else {
              esignMessages.envMessageId = 'LOGIN';
              esignMessages.envMessage = MessageConstants.ESIG_PENDING_SEC_MESSAGE.replace('{0}',
                      formatDate(esignMessages.envelopeExpiryDate, 'dd/MM/yyyy', 'en-US'));
              resolve(esignMessages);
            }
          } else {
            esignMessages.envMessageId = 'LOGIN';
            esignMessages.hasPendingEnvelops = false;
            esignMessages.envMessage = 'Congratulations! There are no more pending documents. Please proceed to Login';
            resolve(esignMessages);
          }
        } else {
          esignMessages.envMessageId = 'LOGIN';
          esignMessages.hasPendingEnvelops = false;
          esignMessages.envMessage = 'Congratulations! There are no more pending documents. Please proceed to Login';
          resolve(esignMessages);
        }
      });
    });
  }

  getEnvelopes(phuser: PolicyHolder): Promise<Envelope[]> {
    return new Promise((resolve, reject) => {

      this.authService.getEnvelopesSub.subscribe((envelopes: Envelope[]) => {
        // Tracker.log('1. ESIG MESSAGES : Invoked authService.getEnvelopesSub :::', envelopes);
        if (envelopes && envelopes.length > 0) {
           resolve(envelopes);
        } else {
          const mco = phuser.pointHookID.substr(0, 2);
          const symbol = phuser.pointHookID.substr(2, 3);
          const policynum = phuser.pointHookID.substr(5);
          const policymod = phuser.policyMod;

          const policyNumber = symbol.concat(policynum).concat(policymod);

          if (phuser.esigstatus === true) {
            this.esigService.getEnvelops(phuser.firstName, phuser.lastName, policyNumber).subscribe((envelopsres: EnvelopesResp) => {
              // Tracker.log('1. ESIG MESSAGES : Invoked esigService.getEnvelops :::', envelopsres);
              if (envelopsres && envelopsres.status.toUpperCase() === 'SUCCESS' && envelopsres.envelopes.length > 0) {
                resolve(envelopsres.envelopes);
              } else {
                resolve([]);
              }
            });
          } else {
            resolve([]);
          }
        }
      }, error => {
        // Tracker.log('Error occured reading the Envelopes Subject ', error);
      });
    });
  }
}
