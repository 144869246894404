import { HttpParams, HttpClient } from '@angular/common/http';
import { Observable, Observer, ReplaySubject } from 'rxjs';
import { GlobalConstants } from '../../constants/global.constant';
import { BwappService } from '../../services/bwapp.service';
import { Tracker } from '../utils/tracker';
import { ActivatedRoute } from '@angular/router';
import { EndorsementReq } from '../model/api/endorsement/endorsement-req';
import { EndorsementService } from '../../services/api/endorsement/endorsement.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/bwapp.service";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common/http";
import * as i4 from "../../services/api/endorsement/endorsement.service";
var DirectsalesService = /** @class */ (function () {
    function DirectsalesService(appService, route, httpClient, endorsementService) {
        this.appService = appService;
        this.route = route;
        this.httpClient = httpClient;
        this.endorsementService = endorsementService;
        // public phoneNumber = new Subject();
        this.phoneNumberSubject = new ReplaySubject(1);
        this.phoneNumberObject = this.phoneNumberSubject.asObservable();
        this.endorsementReq = new EndorsementReq();
        this.globlalConstants = GlobalConstants;
        this.directSalesVendorsJSON = 'assets/data/directsalesvendors.json';
    }
    // keerthana
    DirectsalesService.prototype.getStateByZipCode = function (zipcode) {
        try {
            var params = new HttpParams().set('zipCode', zipcode);
            return this.appService.get(GlobalConstants.GET_STATE_BY_ZIPCODE, params);
        }
        catch (e) {
            console.error(e);
        }
    };
    DirectsalesService.prototype.getSourceDtlsByState = function (lob, state, zipcode) {
        try {
            var params = new HttpParams()
                .set('lob', lob)
                .set('state', state)
                .set('zipCode', zipcode);
            return this.appService.get(GlobalConstants.GET_DIRECTSALES_DTLS, params);
            //  .subscribe((res) => {
            // console.log('res--' + res)
            // this.directSalesVendors = res;
            // console.log('this.directSalesVendors--'+ this.directSalesVendors);
            // });
        }
        catch (e) {
            console.error(e);
        }
    };
    DirectsalesService.prototype.getSourceByState = function (lob, state, zipcode, directSalesVendors, marketingID) {
        var _this = this;
        return Observable.create(function (observer) {
            _this.zipCode = zipcode;
            if (directSalesVendors !== undefined) {
                /*
                      if (directSalesVendors.length !== 0) {
                        for (let i = 0; i < directSalesVendors.length; i++) {
                          console.log(directSalesVendors[i] );
                            if (directSalesVendors[i] !== undefined) {
                              const phoneNumber = directSalesVendors[i].phonenumber;
                              const line = directSalesVendors[i].lob;
                              const src = directSalesVendors[i].source;
                              const src_ind = directSalesVendors[i].source_ind;
                              const stateId = directSalesVendors[i].state;
                              const url = directSalesVendors[i].url;
                              const zip = directSalesVendors[i].zipcode;
        
                              console.log(phoneNumber + line + src + src_ind + stateId + url + zip);
        
                            }
                          }
                      }
        
                */
                // console.log('Params=[lob=' + lob + ', state=' + state + ', zip=' + zipcode + ']');
                var rest = directSalesVendors.filter(function (direct) {
                    return direct.zipcode === zipcode;
                });
                // let rest = directSalesVendors.filter(
                //  value => value. lob === lob && value.state === state && value.zipcode === zipcode);
                // console.log(directSalesVendors.length);
                // console.log(rest);
                // By lob, state, zip code
                // let rest = directSalesVendors.filter(
                //  value => value. lob === lob && value.state === state && value.zipcode === zipcode);
                // console.log('rest.length--' + rest.length);
                // By lob and state
                if (rest === undefined || rest.length === 0) {
                    rest = directSalesVendors.filter(function (value) { return value.lob === lob && value.state === state; });
                }
                // By lob
                if (rest === undefined || rest.length === 0) {
                    // console.log('lob');
                    rest = directSalesVendors.filter(function (value) { return value.lob === lob; });
                }
                // More than 1 result found
                if (rest.length > 1) {
                    // console.log('>1');
                    rest = directSalesVendors.filter(function (value) { return value.zipcode === 'ALL'; });
                }
                // set url / phone number
                // console.log('1) Set SourceURL in Service to ' + rest[0].url);
                //  this.marketingID = this.route.snapshot.queryParamMap.get('id');
                if (marketingID === null) {
                    marketingID = 'none';
                }
                Tracker.loginfo('DirectsalesService', 'getSourceByState', '', 'Marketing ID in Directsales: ' + marketingID);
                /* if (marketingID !== null) {
                  // this.addMarketingIdAuditLog(zipcode, state[0], marketingID);
                 } */
                var params = new HttpParams()
                    .set('zip', zipcode)
                    .set('m', marketingID);
                var URL_1 = rest[0].url + '?' + params.toString();
                _this.sourceURL = URL_1;
                _this.sourceInd = rest[0].source_ind;
                // putting the data into subject
                _this.phoneNumberSubject.next(rest[0].phonenumber);
                observer.next(rest);
            }
        });
    };
    /*
    addMarketingIdAuditLog(zip: string, state: string, marketingID: string) {
      Tracker.loginfo('directsalesService', 'addMarketingIdAuditLog', 'addMarketingIdAuditLog service',
      'direct sales MarketingID audit entry-' + state.concat('-').concat(marketingID).concat('-').concat(zip));
  
     this.endorsementReq.symbol = this.globlalConstants.AUDIT_LOG_MARKETING_ID_SYMBOL;
     this.endorsementReq.policyNum = this.globlalConstants.AUDIT_LOG_MARKETING_ID_POLICY_NUMBER;
     this.endorsementReq.mod = this.globlalConstants.AUDIT_LOG_MARKETING_ID_MODULE;
     this.endorsementReq.mco = this.globlalConstants.AUDIT_LOG_MARKETING_ID_MASTER_COMPANY;
     this.endorsementReq.state = state;
     this.endorsementReq.activityResult = this.globlalConstants.AUDIT_LOG_MARKETING_ID_ACTIVITY_RESULT;
     this.endorsementReq.activity = this.globlalConstants.AUDIT_LOG_MARKETING_ID_ACTIVITY;
     this.endorsementReq.activityMessage = marketingID;
     this.endorsementReq.binderNumber = zip;
     this.endorsementService.createAuditLogRecord(this.endorsementReq).subscribe();
  }
  */
    DirectsalesService.prototype.getSourceURL = function () {
        return this.sourceURL;
    };
    DirectsalesService.prototype.getZipCode = function () {
        return this.zipCode;
    };
    DirectsalesService.prototype.getSource = function () {
        return this.source;
    };
    DirectsalesService.prototype.getSourceInd = function () {
        return this.sourceInd;
    };
    DirectsalesService.ngInjectableDef = i0.defineInjectable({ factory: function DirectsalesService_Factory() { return new DirectsalesService(i0.inject(i1.BwappService), i0.inject(i2.ActivatedRoute), i0.inject(i3.HttpClient), i0.inject(i4.EndorsementService)); }, token: DirectsalesService, providedIn: "root" });
    return DirectsalesService;
}());
export { DirectsalesService };
