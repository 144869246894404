import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FindAgentsService } from '../../../services/api/find-agents/find-agents.service';
import { Agent } from '../../model/agent';
import { Findagentres } from '../../model/api/findagent/findagentres';
import { CommonUtil } from '../../utils/commonutil';
import { Tracker } from '../../utils/tracker';
import { MessageConstants } from '../../../constants/message.constant';
import { NgcCookieConsentService } from "ngx-cookieconsent";

@Component({
  selector: 'app-find-agent',
  template: `
  <main id="main">
    <div class="container">

      <div class="row d-flex">
        <div class="col-md-12 col-sm-12 text-left" >
          <h1 class="heading-4 mt-3" aria-level="1">Find an Agent or Broker</h1>
          <div *ngIf="isLoading" class="col-12 small text-center justify-content-center">Retrieving Agents...</div>
          <div *ngIf="isLoading" class=" col-12 my-5 text-center justify-content-center">
            <app-bw-spinner [smallSpinner]="false"></app-bw-spinner>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-center mb-1">
        <div class="col-md-12 text-center justify-content-center">
          <div *ngIf="this.errorMessage !== ''" class="alert alert-danger alert-dismissible fade show" role="alert">
          {{ this.errorMessage }}
          </div>
        </div>
      </div>
      <div class="row d-flex ">
        <div class="col-md-10 col-sm-12">
          <form [formGroup]="agentSearchForm" *ngIf="!isLoading">
            <div class="input-group mb-3 m-md-0">
              <input [appNumbersonlyinput]="true" type="tel" formControlName="zipcode" class="form-control" maxlength="5" minlength="5"
              placeholder="Enter Zip Code" aria-label="Zip Code">
              <div class="input-group-append">
                <button class="btn btn-primary px-3 btn-more-focus" aria-label="search" type="submit" (click)="onSubmit(agentSearchForm.value)">
                  <span class="icon icon-svg icon-color-white">
                  <i class="fas fa-search fa-stack-1x fa-lg icon-align color-white"></i>
                  </span>
                </button>
              </div>
            </div>
            <div class="float-left">
            <app-field-error-display [displayError]="isFieldValid('zipcode')"
            [errorMsg]="getZipCodeErrMsg(agentSearchForm.controls['zipcode'].errors)"></app-field-error-display>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
  `,
  styleUrls: ['./find-agent.component.scss']
})
export class FindAgentComponent  implements OnInit {

  findagentres: Findagentres;
  agents: Agent[];
  agentSearchForm: FormGroup;
  errorMessage = '';
  isLoading: boolean;
  zip = '';
  formSubmitAttempt: boolean;
  usermessage = MessageConstants;

  @Output() messageEvent2 = new EventEmitter<Agent[]>();

  constructor(private agentService: FindAgentsService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private ccService: NgcCookieConsentService) {
    this.isLoading = false;

     // this.route.params.subscribe( params => this.doSearch(params['term']));
     this.route.params.subscribe(params => {
      if (params['zip']) {
        this.zip = params['zip'];
      }
    });
    this.createForm();

    if (this.zip !== '') {
      this.onSubmit(this.agentSearchForm);
    }
   }

   ngOnInit() {

  }

   onSubmit(agentForm: any) {
     this.formSubmitAttempt = true;
     this.messageEvent2.emit(null);
    if (agentForm.zipcode === undefined || agentForm.zipcode === '') {
      agentForm.zipcode = this.zip;
    }
    this.isLoading = true;
    this.errorMessage = '';
    if (this.router.url.indexOf('/find-agent-broker') === -1) {
      this.router.navigate(['/find-agent-broker/', agentForm.zipcode]);
    }

    if (this.agentSearchForm.valid) {
      this.agentService.findAgents(agentForm.zipcode)
      .subscribe((res: Findagentres) => {
        this.findagentres = res;
        const cnt = 0;
        if (!CommonUtil.isEmpty(this.findagentres.AgentList)) {
          this.messageEvent2.emit(this.findagentres.AgentList);
        } else {
          this.errorMessage = 'Unable to retrieve the Agents near you at this time.';
        }

        this.isLoading = false;
      }, error => {
        this.errorMessage = 'Unable to retrieve the Agents near you at this time.';
        this.isLoading = false;
        Tracker.logerror('FindAgentComponent', 'onSubmit', 'findAgents', 'Error searching for agent in zip ' + agentForm.zipcode , error);
      });
    } else {
      this.isLoading = false;
      console.log('Not Valid');
    }

  }

  /**
    * Create the form for this component
    */
   createForm() {
    this.agentSearchForm =
    this.fb.group({'zipcode' : [(this.zip !== '' ? this.zip : ''), Validators.compose([Validators.required])]
   });
  }

  // Form Utilities
  isFieldValid(field: string) {
    return (
      (!this.agentSearchForm.get(field).valid && this.agentSearchForm.get(field).touched) ||
      (this.agentSearchForm.get(field).untouched && this.formSubmitAttempt)
    );
  }

  getZipCodeErrMsg(errors: any) {
    // console.log(errors.minLength);
    if (errors) {
      if (errors.required === true) {
        // console.log(this.usermessage.REQUIRED_ZIPCODE);
        return this.usermessage.REQUIRED_ZIPCODE;
      } else if (errors.minLength) {
        // console.log(this.usermessage.LENGTH_ZIPCODE);
        return this.usermessage.LENGTH_ZIPCODE;
      }
    }
  }

}

