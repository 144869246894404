import { Component, OnInit } from '@angular/core';
import { BwstoreService } from '../../services/bwstore.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FullPolicyDetails } from '../../model/api/bwibic/fullpolicydetails.model';
import { AuthenticationService } from '../../services/authentication.service';
import { PolicymgtService } from '../../../services/api/policymgmt/policymgt.service';
import { Policynumber } from '../../model/policynumber';
import { Mfares } from '../../model/api/policymgmt/mfares';
import { Tracker } from '../../utils/tracker';
import { CommonUtil } from '../../utils/commonutil';
import { GlobalConstants } from '../../../constants/global.constant';
import { PolicyholderService } from '../../../services/api/policyholder/policyholder.service';
import { CheckEachAnswerRequest, CheckEachAnswerResponse } from '../../model/api/policyholder/checkeachanswer.model';
import { NewUser } from '../../model/newuser.model';
import { PolicyHolder } from '../../model/authentication/authenticatephres';
import { RemovePHRequest, RemovePHResponse } from '../../model/api/policyholder/removeph.model';
import { environment } from '../../../../environments/environment';
import { GoogleAnalyticsService } from '../../services/analytics/google-analytics.service';

@Component({
  selector: 'app-login-mfa',
  templateUrl: './login-mfa.component.html',
  styleUrls: ['./login-mfa.component.scss']
})
export class LoginMfaComponent implements OnInit {
  mfaForm: FormGroup;
  errorMessage = '';
  answerTryCount = 1;
  questionNumber = 0;
  currentsecquestion: string;
  formSubmitAttempt: boolean;
  showRegistrationMessage = false;
  mfaRes: Mfares;
  errorFlag: boolean;
  userQuestions;
  phUserObject: PolicyHolder;
  loaded = true;

  constructor(private authService: AuthenticationService,
    private plcyhlderService: PolicyholderService,
    private storeService: BwstoreService,
    private fb: FormBuilder, private router: Router,
    private googleAnalytics: GoogleAnalyticsService) {
    this.createMFAForm();
  }

  ngOnInit() {
    this.authService.phUserObject.subscribe((data) => {
      this.phUserObject = data;
      this.userQuestions = [
        { 'id': 1, 'question': this.phUserObject.question1, 'tries': 1 },
        { 'id': 2, 'question': this.phUserObject.question2, 'tries': 1 },
        { 'id': 3, 'question': this.phUserObject.question3, 'tries': 1 }
    ];
    });
    if (this.phUserObject === undefined) {
      return this.router.navigate(['/policy']);
    }
    this.currentsecquestion = this.userQuestions[0].question;
  }


  onSubmit(formData: any) {
    this.formSubmitAttempt = true;
    const testVar = false;
    if (this.mfaForm.valid) {
      this.loaded = false;
      // Verify the answer is correct
      this.googleAnalytics.trackEvent('MFA', 'ChageSecurityQuestion', 'clickonAnswerSubmit', 200);
      const policyNumber = new Policynumber(this.phUserObject.pointHookID.substring(2).
      concat(this.phUserObject.policyMod));
      const checkEachAnswerRequest = new CheckEachAnswerRequest();
      checkEachAnswerRequest.userName = this.phUserObject.email;
      checkEachAnswerRequest.answer = formData.answer;
      // PolicyHolderLDAP checkAnswer
      this.plcyhlderService.checkEachAnswer(checkEachAnswerRequest)
      .subscribe((checkAnswerRes: CheckEachAnswerResponse) => {
        if (checkAnswerRes.responseCode === '-1') {
        this.loaded = true;
        // correctly answered
        this.authService._setLoginStatus(true);
        // web session log for mfa
        this.authService.createWebSessLogMFA(this.phUserObject, true);

        const newMFADate = CommonUtil.addDateNowEST(GlobalConstants.MFA_DAYS);
        // Update MFA Date - Fire and Forget
        this.plcyhlderService.updateMFADate(policyNumber.symbol, policyNumber.policy,
          policyNumber.module, this.phUserObject.pointHookID.substring(0, 2), newMFADate)
        .subscribe(mfaDateData => {
          this.mfaRes = mfaDateData;
          this.storeService.createLoginInfo(this.phUserObject);
          if (this.phUserObject.gopaperlessInd
            && this.phUserObject.gopaperlessInd === true) {
            return this.router.navigate(['/gptermsnconditions']);
          } else if (this.phUserObject
            && this.phUserObject.esigstatus === true) {
            return this.router.navigate(['/esigmessages']);
          } else {
            this.storeService.deletePolicyInfo(new FullPolicyDetails());
            return this.router.navigate(['/policy']);
          }

        },
        error => {
this.loaded = true;
          this.googleAnalytics.trackEvent('Login', 'MFA', 'LoginAttempt', 500);          Tracker.logerror('LoginMfaComponent', 'onSubmit', 'updateMFADate', 'Error updating MFA Date.', error);
        });

      } else {
          this.loaded = true;
          if (this.userQuestions[this.questionNumber].tries < 3) {
          switch (this.userQuestions[this.questionNumber].tries) {
            case 1 :
            case 2 : {
              this.errorMessage = '';
              this.formSubmitAttempt = false;
              this.mfaForm.reset();
              this.userQuestions[this.questionNumber].tries++;
              this.errorMessage = 'Incorrect answer provided';
              this.googleAnalytics.trackEvent('Login', 'MFA', 'LoginAttempt', 200);
              break;
            }
            case 3 : {
              this.errorMessage = '';
              this.showRegistrationMessage = true;
              // remove all session info
              this.storeService.removeUserSessionFromBrowserSession();
              this.storeService.deletePolicyInfo(new FullPolicyDetails());
              this.authService.logout();
              this.googleAnalytics.trackEvent('Login', 'MFA', 'LoginAttempt', 200);
              break;
            }
          }
          this.answerTryCount++;
        } else {
          if (this.questionNumber < 2) {
            this.mfaForm.reset();
            this.errorMessage = '';
            this.currentsecquestion = this.userQuestions[++this.questionNumber].question;
          } else {
            // over 3 tries show message for registration
            // web session log for mfa
            this.googleAnalytics.trackEvent('Login', 'MFA', 'LoginAttempt', 200);
            this.errorMessage = '';
            this.authService.createWebSessLogMFA(this.phUserObject, false);
           this.showRegistrationMessage = true;
          }
        }
      }},
      error => {
  this.loaded = true;
        this.googleAnalytics.trackEvent('Login', 'MFA', 'LoginAttempt', 500);        Tracker.logerror('LoginMfaComponent', 'onSubmit', 'checkEachAnswer', 'Error verifying the answer.', error);
      });
    }
  }

  showNextQuestion(mfaForm: any) {
    if (this.questionNumber < 2) {
      this.googleAnalytics.trackEvent('Login', 'MFA', 'LoginAttempt', 200);
      this.mfaForm.reset();
      this.errorMessage = '';
      this.userQuestions[this.questionNumber].tries = 3;
      this.currentsecquestion = this.userQuestions[++this.questionNumber].question;
    }
  }

  createMFAForm() {
    this.mfaForm = this.fb.group({
      answer: ['', Validators.compose([Validators.required])
      ],
    });
  }

  // Form Utilities
  isFieldValid(field: string) {
    return (
      (!this.mfaForm.get(field).valid && this.mfaForm.get(field).touched) ||
      (this.mfaForm.get(field).untouched && this.formSubmitAttempt)
    );
  }

  getErrMsg(errors) {
    if (errors && errors != null) {
      if (errors.required === true) {
        return 'Please answer the security question above.';
      }
    }
  }

  removePolicyHolder() {
    const removePhReq = new RemovePHRequest();
    removePhReq.email = this.phUserObject.email;
    removePhReq.adminPassword = environment.removePhAdminPassword;
    Tracker.loginfo('LoginMfaComponent', 'removePolicyHolder',
     '', 'start calling service operation removePH for ' + this.phUserObject.email);
     this.googleAnalytics.trackEvent('Login', 'MFA', 'LoginAttempt', 200);
    // service call for removePH
    this.plcyhlderService.removePH(removePhReq).subscribe((removePhRes: RemovePHResponse) => {
      if (removePhRes && removePhRes.removeStatusCode === '-1') {
        Tracker.loginfo('LoginMfaComponent', 'removePolicyHolder',
        '', 'response from removePH' + JSON.stringify(removePhRes));
        this.googleAnalytics.trackEvent('Login', 'MFA', 'LoginAttempt', 200);
        this.router.navigate(['/register']);
      } else {
        Tracker.logerror('AppComponent', 'ngOnInit()', 'Removing the policy holder',
         'Error Removing the policy holder', '');
         this.googleAnalytics.trackEvent('Login', 'MFA', 'LoginAttempt', 200);
        // return ('There is some error while removing the policy');
      }
    });

}

}
