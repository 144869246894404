import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EsigmessagesService } from '../../services/esigmessages.service';
import { CommonUtil } from '../../utils/commonutil';
import { BwstoreService } from '../../services/bwstore.service';
var EsigmessagesComponent = /** @class */ (function () {
    function EsigmessagesComponent(route, router, esigMessageservice, storeService) {
        this.route = route;
        this.router = router;
        this.esigMessageservice = esigMessageservice;
        this.storeService = storeService;
        this.continueAction = 'LOGIN';
        this.phUser = this.storeService.getLoginInfoFromStore();
    }
    EsigmessagesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            // Tracker.log('Params from Route ::', params);
            var action = params.eSigAction;
            if (params) {
                // Tracker.log('Docusign landed me here....', action);
                // this.message = CommonUtil.getEsigMessage(params.eSigAction);
            }
            else {
                // Tracker.log('Internal application landed me here...');
            }
            if (action && (action === 'Exception' || action === 'IDCheckFailed'
                || action === 'SessionTimedOut' || action === 'TTLExpired' || action === 'ViewingComplete')) {
                _this.message = CommonUtil.getEsigMessage(params.eSigAction);
                _this.continueAction = 'DOCUSIGN';
                _this.docusignUrl = _this.phUser.docuSignURL;
            }
            else {
                // Add the logic to add the processedDocId(envelopId + recipientId) to Session upon on successful return to EsignMessags
                // from the DocuSign and exclude that processDocId while checking for Pending Docs in the below step
                _this.phUser.processedDocId = params.p;
                _this.storeService.updateLoginInfo(_this.phUser);
                _this.esigMessageservice.verifyEsigStatus(_this.phUser).then(function (envmessage) {
                    _this.message = 'You are all good to proceed to next level';
                    // Tracker.log('Esig Messages prepared ==> ', envmessage);
                    _this.message = envmessage.envMessage;
                    _this.docusignUrl = envmessage.ceremonyURL;
                    _this.continueAction = envmessage.envMessageId;
                    _this.phUser.esigstatus = envmessage.hasPendingEnvelops;
                    _this.phUser.docuSignURL = envmessage.ceremonyURL;
                    _this.storeService.updateLoginInfo(_this.phUser);
                });
            }
        });
    };
    EsigmessagesComponent.prototype.continueToMyPolicy = function () {
    };
    EsigmessagesComponent.prototype.continueToEsig = function () {
    };
    EsigmessagesComponent.prototype.continueToLogin = function () {
        return this.router.navigate(['/']);
    };
    return EsigmessagesComponent;
}());
export { EsigmessagesComponent };
