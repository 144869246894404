/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./pagenotfound.component";
var styles_PagenotfoundComponent = [];
var RenderType_PagenotfoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PagenotfoundComponent, data: {} });
export { RenderType_PagenotfoundComponent as RenderType_PagenotfoundComponent };
export function View_PagenotfoundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "main", [["class", "main"], ["id", "main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "containter text-center mt-0"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 7, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 6, "a", [["class", "btn btn-icon flex-column"], ["href", "#"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "span", [["class", "btn-icon-circle m-auto"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "icon icon-svg mr-2 pb-5 icon-color-white"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "i", [["class", "fa fa-file fa-stack-1x fa-3x icon-align color-white"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "span", [["class", "btn-icon-label text-center mt-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "h1", [["class", "heading-1 mt-4"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["404 Page Not Found"]))], null, null); }
export function View_PagenotfoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-pagenotfound", [], null, null, null, View_PagenotfoundComponent_0, RenderType_PagenotfoundComponent)), i0.ɵdid(1, 114688, null, 0, i1.PagenotfoundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PagenotfoundComponentNgFactory = i0.ɵccf("app-pagenotfound", i1.PagenotfoundComponent, View_PagenotfoundComponent_Host_0, {}, {}, []);
export { PagenotfoundComponentNgFactory as PagenotfoundComponentNgFactory };
