import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { GlobalConstants } from '../../constants/global.constant';
import { MessageConstants } from '../../constants/message.constant';
import { Envelope } from '../model/api/esig/envelop.model';
import { PolicyHolder } from '../model/authentication/authenticatephres';
import { EsigMessages } from '../model/esignmessages.model';
import { AbstractControl, Validators } from '@angular/forms';

@Injectable()
export class CommonUtil {

    constructor(private datePipe: DatePipe) {}

    public static isEmpty(obj) {
      // Tracker.log('OBJ= ' + obj);
      if (null !== obj) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              return false;
            }
        }
      }
      return true;
  }

  public static createActiveIds(idPrefix: string, arrayLength: number) {
    const activeIds = [];
    if (arrayLength > 0) {
      for (let i = 0; i < arrayLength; i++) {
        activeIds[i] = idPrefix + '-' + (i + 1);
      }
    }
    // Tracker.log('Active IDs for ' + idPrefix + ' =' + activeIds);
    return activeIds;
  }

  public static topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  public static findDeviceVIew() {
    return {
      isIOS() {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent) ? true : false;
      },
      isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i
          .test(navigator.userAgent) ? true : false;
      }
    };
  }

  public static base64toBlob (base64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
        for (let offset = begin, i = 0 ; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
}

    public static padNumber(value: number): string {
        if (this.isNumber(value)) {
            return `0${value}`.slice(-2);
        } else {
            return '';
        }
    }

    public static isNumber(value: any): boolean {
        return !isNaN(this.toInteger(value));
    }

    public static toInteger(value: any): number {
        return parseInt(`${value}`, 10);
    }

    public static formatDate(phdob: any): string {
      let formattedDate = phdob;
      if (formattedDate instanceof Object) {
        formattedDate = this.padNumber(phdob.month) + '/' + this.padNumber(phdob.day) + '/' + phdob.year;
      }

      return formattedDate;
    }

    public static getDateTimeNowEST() {
        const datePipe = new DatePipe('en-US');
        return datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss.sss', 'EST', 'en-US');
    }

    public static getDateTimeNowDateFormat(dateFormat: string) {
      const datePipe = new DatePipe('en-US');
      return datePipe.transform(new Date(), dateFormat, 'en-US');
  }

    public static getDateNowEST() {
      const datePipe = new DatePipe('en-US');
      return datePipe.transform(new Date(), 'yyyy-MM-dd', 'EST');
    }

  public static getTimeNowEST() {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(new Date(), 'HH:mm:ss', 'EST', 'en-US');
  }

  public static addDateNowEST(days: number) {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(new Date().getTime() + (1000 * 60 * 60 * (24 * days)), 'yyyy-MM-dd', 'EST');
}
  public static getDateTimeNow() {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss.sss', 'en-US');
  }

    // this will remove only beginning and end of string whitespace.
    public static lrtrim(x): string {
      return x ? x.replace(/^\s+|\s+$/gm, '') : '';
    }

    // this will remove whole whitespace from the string
    public static trim_all_spaces(x): string {
      return x ? x.replace(/\s/g, '') : '';
    }


    public static getMCO(authenticatedPhDetails: PolicyHolder): string {
     return  authenticatedPhDetails.pointHookID.substr(0, 2);
    }

    public static getPolicySymbol(authenticatedPhDetails: PolicyHolder): string {
      return  authenticatedPhDetails.pointHookID.substr(2, 3);
     }

     public static getPolicyNumber(authenticatedPhDetails: PolicyHolder): string {
      return  authenticatedPhDetails.pointHookID.substr(5);
     }

     public static getPolicyMod(authenticatedPhDetails: PolicyHolder): string {
      return  authenticatedPhDetails.policyMod;
     }

     public static getBWPolicyNumber(authenticatedPhDetails: PolicyHolder): string {
      return this.getPolicySymbol(authenticatedPhDetails)
        .concat(this.getPolicyNumber(authenticatedPhDetails))
        .concat(this.getPolicyMod(authenticatedPhDetails));
     }

     public static checkPlcyHasEnvelopes(envelopes: Envelope[]): boolean {
      let hasEnvelops = false;
      envelopes.forEach(env => {
        // Tracker.log('Looping Envelop elements::: ', env);
        env.recipients.forEach(rec => {
          // Tracker.log('Looping Recipient elements::: ', rec);
          // if (rec.recipType === 'PrimaryNamedInsured') {
            if (rec.status === 'Completed' || rec.status === 'Declined' || rec.status === 'Signed') {
              hasEnvelops = false;
            } else {
              hasEnvelops = true;
              return hasEnvelops;
            }
          // }
        });
      });

      return hasEnvelops;
     }

     public static getPendingEnvelopeMessage(envelopes: Envelope[]): EsigMessages {
      const esignMessages = new EsigMessages();
      envelopes.forEach(env => {
        // // Tracker.log('Looping Envelop elements::: ', env);
        env.recipients.forEach(rec => {
          // Tracker.log('Looping Recipient elements::: ', rec);
          if (rec.status === 'Completed' || rec.status === 'Declined' || rec.status === 'Signed') {
            esignMessages.hasPendingEnvelops = false;
          } else {
            esignMessages.recepientType = rec.recipType;
            esignMessages.hasPendingEnvelops = true;
            esignMessages.envelopeId = env.envelopeID;
            esignMessages.envelopeExpiryDate = env.expireDate;
            esignMessages.recepientId = rec.recipientID;
            return esignMessages;
          }
        });
      });

      return esignMessages;
     }


     public static getEsigMessage(messageType: string) {
      let message;
      switch (messageType) {
        case GlobalConstants.DOCUSIGN_TYPE_CANCELLED: {
          message = MessageConstants.DOCUSIGN_CANCEL_MSG;
          break;
        }
        case GlobalConstants.DOCUSIGN_TYPE_DECLINED: {
          message = MessageConstants.DOCUSIGN_DECLINE_MSG;
          break;
        }
        case GlobalConstants.DOCUSIGN_TYPE_EXCEPTION: {
          message = MessageConstants.DOCUSIGN_EXCEPTION_MSG;
          break;
        }
        case GlobalConstants.DOCUSIGN_TYPE_IDCHECK: {
          message = MessageConstants.DOCUSIGN_IDCHECK_FAILED_MSG;
          break;
        }
        case GlobalConstants.DOCUSIGN_TYPE_SESSION_TIMEOUT: {
          message = MessageConstants.DOCUSIGN_SESSION_TIMEOUT_MSG;
          break;
        }
        case GlobalConstants.DOCUSIGN_TYPE_COMPLETE: {
          message = MessageConstants.DOCUSIGN_COMPLETE_MSG;
          break;
        }
        case GlobalConstants.DOCUSIGN_TYPE_TTL_EXPIRED: {
          message = MessageConstants.DOCUSIGN_TTL_EXPIRED_MSG;
          break;
        }
        case GlobalConstants.DOCUSIGN_TYPE_VIEWING: {
          message = MessageConstants.DOCUSIGN_VIEWING_COMPLETE_MSG;
          break;
        }
      }
      return message;
    }

    public static getLandingMessage(registatus: string, src: string): string {
      let landingMsg = '';
      if (registatus) {
        if (src === 'GP') {
          landingMsg = MessageConstants.GP_LOGIN_MSG;
        } else if (src === 'ESIG') {
          landingMsg = MessageConstants.ESIG_LOGIN_MSG;
        } else if (src === 'WELCOME') {
          landingMsg = MessageConstants.WELCOME_LOGIN_MSG;
        }
      } else {
        if (src === 'GP') {
          landingMsg = MessageConstants.GP_REG_MSG;
        } else if (src === 'ESIG') {
          landingMsg = MessageConstants.ESIG_REG_MSG;
        } else if (src === 'WELCOME') {
          landingMsg = MessageConstants.WELCOME_REG_MSG;
        }
      }

      return landingMsg;
    }

    public static getContinueAction(registatus: string): string {
      let action = 'LOGIN';
      if (registatus) {
        action = 'LOGIN';
      } else {
        action = 'REGISTER';
      }

      return action;
    }

    public static formatCC(ccNum: string): string {
      if (ccNum !== null) {
        const re = /\ /gi;
        return ccNum.replace(re, '');
      }
    }

    public static formatCCExpDt(expDt: string): string {
      if (expDt !== null) {
        const re = /\//gi;
        return expDt.replace(re, '');
      }
    }

    public static removewhitespaces(name: string): string {
      if (name !== null) {
        const re = /\ /gi;
        return name.replace(re, '');
      }
    }

    public static updateControlValidation(fControl: AbstractControl, isRequired: boolean) {
      if (isRequired) {
        fControl.setValidators([Validators.compose([Validators.required])]);
      } else {
        fControl.setValidators([]);
      }
      fControl.updateValueAndValidity();
    }

    public static scrollToTop() {
      console.log('Scrolling to the top');
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
}
