import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable()@Injectable({
  providedIn: 'root'
})
export class SecurityqnsService {

  private securityQsnsPath = 'assets/data/security-qsns.json';

  constructor(private _http: HttpClient) { }


  getSecurityQns(): Observable<any>  {
    return (this._http.get(this.securityQsnsPath));
  }
}
