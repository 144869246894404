import { InjectionToken } from '@angular/core';
import { Routes } from '@angular/router';
import { FindAgentBrokerListComponent } from '../components/find-agent-broker-list/find-agent-broker-list.component';
import { HomeComponent } from '../components/home/home.component';
import { PaymentSuccessComponent } from '../components/payment/payment-success/payment-success.component';
import { PaymentComponent } from '../components/payment/payment.component';
import { GopaperlessTermsComponent } from '../components/registration/gopaperless-terms/gopaperless-terms.component';
import { BwLandingComponent } from '../shared/components/bw-landing/bw-landing.component';
import { EsiglandingComponent } from '../shared/components/esiglanding/esiglanding.component';
import { EsigmessagesComponent } from '../shared/components/esigmessages/esigmessages.component';
import { LoginGpTermsComponent } from '../shared/components/login-gp-terms/login-gp-terms.component';
import { PagenotfoundComponent } from '../shared/components/pagenotfound/pagenotfound.component';
import { AuthGuard } from '../shared/guards/auth.guard';
import { RedirectGuard } from '../shared/guards/redirect.guard';
import { RouteinterceptGuard } from '../shared/guards/routeintercept.guard';
import { LoginMfaComponent } from '../shared/components/login-mfa/login-mfa.component';
import { DirectsaleshomeComponent } from '../components/directsaleshome/directsaleshome.component';
import { SorryComponent } from '../components/sorry/sorry.component';
import { DirectsalescallComponent } from '../components/directsalescall/directsalescall.component';
import { CustomerReviewsComponent } from '../components/customer-reviews/customer-reviews.component';
import { GlobalConstants } from '../constants/global.constant';
import { SsoLoginComponent } from '../shared/components/sso-login/sso-login.component';
import { CovidMsgComponent } from '../components/covid-msg/covid-msg.component';
import { WithoutLoginComponent } from '../shared/components/without-login/without-login.component';
import { PaymentIframeFrmsComponent } from '../components/payment-iframe-frms/payment-iframe-frms.component';
import { AutoInsurance101Component } from '../components/static/auto-insurance101/auto-insurance101.component';
var externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');
var ɵ0 = { breadcrumb: 'Home' }, ɵ1 = { breadcrumb: 'Home' }, ɵ2 = { breadcrumb: 'Home' }, ɵ3 = { breadcrumb: 'My Policy' }, ɵ4 = { breadcrumb: 'My Profile' }, ɵ5 = { breadcrumb: 'Make a Payment' }, ɵ6 = { breadcrumb: 'Find Agent or Broker' }, ɵ7 = { breadcrumb: 'Find Agent or Broker' }, ɵ8 = { breadcrumb: 'Customer Reviews', title: GlobalConstants.CUSTOMER_REVIEWS_PAGE_TITLE,
    metaDescription: GlobalConstants.CUSTOMER_REVIEWS_META_DESCRIPTION, metaKeywords: GlobalConstants.CUSTOMER_REVIEWS_META_KEYWORDS }, ɵ9 = { breadcrumb: 'Auto Insurance 101', title: GlobalConstants.AUTO_INS_101_PAGE_TITLE };
var appRoutes = [
    { path: '', data: ɵ0, component: HomeComponent, canActivate: [RedirectGuard, RouteinterceptGuard] },
    { path: 'bdq', data: ɵ1, component: HomeComponent, canActivate: [RedirectGuard, RouteinterceptGuard] },
    // Lazy Loaded
    { path: 'home', data: ɵ2, loadChildren: '../modules/static/static.module#StaticModule' },
    { path: 'register', loadChildren: '../modules/registration/registration.module#RegistrationModule' },
    { path: 'policy', data: ɵ3,
        loadChildren: '../modules/mypolicy/my-policy.module#MyPolicyModule', canActivate: [AuthGuard, RouteinterceptGuard] },
    { path: 'profile', data: ɵ4,
        loadChildren: '../modules/myprofile/myprofile.module#MyprofileModule',
        canActivate: [AuthGuard, RouteinterceptGuard], runGuardsAndResolvers: 'always' },
    { path: 'nologin', component: WithoutLoginComponent, canActivate: [RouteinterceptGuard] },
    { path: 'payment', data: ɵ5, component: PaymentComponent, canActivate: [RouteinterceptGuard] },
    { path: 'payment-success', component: PaymentSuccessComponent, canActivate: [RouteinterceptGuard] },
    { path: 'find-agent-broker', data: ɵ6,
        component: FindAgentBrokerListComponent, canActivate: [RouteinterceptGuard] },
    { path: 'find-agent-broker/:zip', data: ɵ7,
        component: FindAgentBrokerListComponent, canActivate: [RouteinterceptGuard] },
    { path: 'gopaperless-terms', component: GopaperlessTermsComponent },
    // Landing and login
    { path: 'gptermsnconditions', component: LoginGpTermsComponent },
    { path: 'esiglanding', component: EsiglandingComponent },
    { path: 'bw', component: BwLandingComponent },
    { path: 'esigmessages', component: EsigmessagesComponent, canActivate: [RouteinterceptGuard] },
    { path: 'mfa', component: LoginMfaComponent },
    { path: 'quote', component: DirectsaleshomeComponent },
    { path: 'sorry', component: SorryComponent },
    { path: 'directsalescall', component: DirectsalescallComponent },
    { path: 'externalRedirect', resolve: { url: externalUrlProvider, }, component: HomeComponent },
    { path: 'reviews', data: ɵ8,
        component: CustomerReviewsComponent },
    { path: 'sso', component: SsoLoginComponent },
    { path: 'covid', component: CovidMsgComponent, canActivate: [AuthGuard] },
    { path: 'payments', component: PaymentIframeFrmsComponent, canActivate: [RouteinterceptGuard] },
    { path: 'auto-insurance101', data: ɵ9,
        component: AutoInsurance101Component },
    // 404
    { path: '**', component: PagenotfoundComponent },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9 };
