import { OktaResponse } from "../api/okta/okta-model";
import { NewAmountDue } from "../api/policyinfo/newamountdue";
import { Phonenumberdetails } from '../api/text-alerts/textalertenrollment.model';

export class Authenticatephres {

    authenticatePolicyHolderResult?: boolean;
    errorMsg?: string;

    phUser: PolicyHolder;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}


export class PolicyHolder {
    email: string;
    pin: string;
    pointHookID: string;
    firstName: string;
    lastName: string;
    fullName: string;
    dob: string;
    phone: string;
    question1: string;
    question2: string;
    question3: string;
    sessionTicket: string;
    userId: string;
    policyMod: string;
    riskstate: string;
    esigstatus: boolean;
    processedDocId: string;
    docuSignURL: string;
    gopaperlessInd: boolean;
    jwtToken: string;

    gopaperlessEnrollment: string;
    textAlertsEnrollment: string;
    requestToCanceEligible: string;
    textAlertStateEligible: string;
    eftStatus: string;
    textAlertEnrolledPhones: Phonenumberdetails[];
    IsGaragingAddressDifferent: string;
    IsAgentEligibleForEndorsement: string;
    isRatebookActive: boolean;
    IsNNOPolicy: boolean;
    SR22FR44Status: string;
    hasRenewal: boolean;
    IsUpdateAddressEligible:  string;
    IsReplaceVehicleEligible: string;
    IsAddVehicleEligible: string;
    IsRemoveVehicleEligible: string;
    IsAddDriverEligible: string;
    HasUnderwritingAlerts: boolean;
    HasMissingDocuments: boolean;
    HasCDWCoverage: boolean;
    HasUMPDCoverage: boolean;
    stateMIHasEnvelope: boolean;
    renewalPolEffDate: string;
    HasAutoLoanLeaseCoverage: boolean;
    HasUIMPDCoverage: boolean;
    isReinstate:boolean;
    reInstateDueDate: string;
    reInstateDueamount:string;
    reInstatePolicyStatus: string;
    okta?: OktaResponse; 
    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
