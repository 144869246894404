import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { MessageConstants } from '../../../constants/message.constant';
import { PolicyholderService } from '../../../services/api/policyholder/policyholder.service';
import { TimeoutService } from '../../../services/timeout.service';
import { GoogleAnalyticsService } from '../../services/analytics/google-analytics.service';
import { FullPolicyDetails } from '../../model/api/bwibic/fullpolicydetails.model';
import { FindPHRequest } from '../../model/api/policyholder/findph.model';
import { RemovePHRequest } from '../../model/api/policyholder/removeph.model';
import { Mfareq } from '../../model/api/policymgmt/mfareq';
import { Mfares } from '../../model/api/policymgmt/mfares';
import { PolicyHolder } from '../../model/authentication/authenticatephres';
import { BWError } from '../../model/error.model';
import { AuthenticationService } from '../../services/authentication.service';
import { BwstoreService } from '../../services/bwstore.service';
import { CommonUtil } from '../../utils/commonutil';
import { Tracker } from '../../utils/tracker';
import { EmailValidator } from '../../validators/email.validator';
import { ErrorRedirectsComponent } from '../modals/error-redirects.component';
import { ForgotUseridComponent } from '../modals/forgot-userid.component';
import { Policynumber } from '../../model/policynumber';
import { GlobalConstants } from '../../../constants/global.constant';
import { NewUser } from '../../model/newuser.model';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { MypolicyService } from '../../../services/mypolicy/mypolicy.service';
import { OktaMapperService } from '../../services/okta-mapper.service';

@Component({
  selector: 'app-login',
  template: `
    <!--<div *ngIf="loginErrMsg != null" class="alert alert-danger" role="alert">
      {{loginErrMsg }}
    </div> -->
    <div class="container">
    <div class="row d-flex mb-1">
      <div class="col-md-10 col-sm-10">
          <app-app-errors></app-app-errors>
          <div class="d-inline-flex btn-more-focus">
            <span class="look-feel-heading-1">
              Access Your Policy
            </span>
          </div>
          <form [formGroup]="loginForm" id="loginForm">
            <div class="row">
              <div class="col-md-9 col-sm-9">
                <div class="form-group margin-no-bottom">
                  <label for="user_email" class="lbl-heading">Email address <span class="clr-red">&nbsp;*</span></label>
                  <input id="user_email" type="email" placeholder="Enter your email" class="form-control"
                  formControlName="userid" aria-label="Enter your email address">
                  <div class="col-md-9 col-sm-9">
                    <app-field-error-display [displayError]="isFieldValid('userid')"
                      [errorMsg]="getUserIDErrMsg(loginForm.controls['userid'].errors)"></app-field-error-display>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">

            <div class="col-md-9 col-sm-9">
                <div class="form-group margin-no-bottom">
                  <label for="user_pwd" class="lbl-heading">Password <span class="clr-red">&nbsp;*</span></label>
                  <div class="input-group">
                  <input id="user_pwd" [type]="showPassword ? 'text' : 'password'" placeholder="Enter your password" class="form-control"
                    formControlName="password" aria-label="Enter your password">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" (click)="togglePassword()" aria-label="Show password">
                      <span *ngIf="showPassword === true"><i class="far fa-eye-slash"></i></span>
                      <span *ngIf="showPassword === false"><i class="far fa-eye"></i></span>
                    </button>
                  </div>
                </div>
                <div class="col-md-9 col-sm-9">
                <app-field-error-display [displayError]="isFieldValid('password')"
                [errorMsg]="usermessage.REQUIRED_PASSWORD"></app-field-error-display>
              </div>
                </div>
             
            </div>
           
          </div>
          
            <div class="row mt-3">
              <div class="checkbox mt-2 col-md-5 col-sm-4 col-5">
                <label class="label-text">
                  <input type="checkbox" name="rememberMe" class="mr-2" formControlName="rememberMe"/> Remember me</label>
                </div>
                <div class="col-md-4 col-sm-5 text-right col-7">
                <a  tabindex="0" class="label-text" (click)="newUser()">
                New User</a> <label tabindex="0" class="btn p-0" placement="top" container="body"
                ngbTooltip="Please register for access to your policy. By registering you can make a payment,
                    view policy details, report a claim and more.">
                  <img src="assets/img/icon-tooltip.svg" class="icon icon-svg position-static" 
                  alt="Help Icon"/>
                  </label>
                
                </div>
              </div>
            <!-- <div class="text-md-left">
              <a href="javascript:void(0);" (click)="showForgotEmail()">Forgot Email?</a>
              <br/>
              <a routerLink="/home/forgot-password" routerLinkActive="active">Forgot Your Password?</a>
            </div> -->
            <div class="alert alert-privacy-banner row p-0 mb-0" role="alert">
              <div class="col-md-4 col-sm-4 mt-2 col-5">
                <a class="label-text" href="javascript:void(0);" (click)="showForgotEmail()">Forgot Email?</a>
              </div>
              <div class="col-md-5 col-sm-5 mt-2 text-right col-7 d-sm-block d-md-none d-lg-block">
                <a class="a-blue-link ml-2 label-text" href="{{cookieBannerUrl}}" target="_blank"
                title="Personal Information Use">Personal Information Use</a>
              </div>
              <div class="d-none d-sm-none d-md-block d-lg-none mt-2 text-right col-md-7">
                <a class="a-blue-link ml-2 label-text" href="{{cookieBannerUrl}}" target="_blank"
                title="Personal Information Use">Personal Information Use</a>
              </div>
            </div>
            <div class="row p-0 mt-0">
              <div class="col-md-5 col-sm-4 col-6">
                <a class="label-text" routerLink="/home/forgot-password" routerLinkActive="active">Forgot Password?</a>
              </div>
            </div>
            <div class="row mt-3 mb-3">
              <div class="col-md-10 col-sm-10 text-center">
              <button *ngIf="loaded" type="submit" class="btn orange-login-button" (click)="loginUser(loginForm.value)">
              Login</button>
              <button  *ngIf="!loaded" type="submit" class="btn orange-login-button" disabled="true"><i class="fas fa-circle-notch fa-spin">
              </i>&nbsp;Login</button>
              </div>
            </div>
          </form>
      </div>
    </div>
  </div>
  `,
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  usermessage = MessageConstants;

  policyNumber = '';
  rememberMeId = '';
  isChecked = false;
  showPassword: boolean;
  loaded = true;

  // Model
  mfaReq: Mfareq;
  mfaRes: Mfares;
  findPhReq: FindPHRequest;
  removePhReq: RemovePHRequest;

  formSubmitAttempt: boolean;

  loginForm: FormGroup;
  policynum = '';
  newuser: NewUser;
  cookieBannerUrl = `${environment.ccpaCookieBanner}`;
  bwOktaEnabled : boolean = environment.bwOktaEnabled;
  @ViewChild('closemodal')
  closemodal: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private plcyhlderService: PolicyholderService,
    private storeService: BwstoreService,
    private modalService: NgbModal,
    private googleAnalytics: GoogleAnalyticsService,
    private timeoutService: TimeoutService,
    private policyService: MypolicyService,
    private oktaMapperService: OktaMapperService
  ) {
    // Retrieve Remember my UserIDfrom Local
    const rememberMeLocal = localStorage.getItem(
      `${environment.rememberMeId}` + '_userId'
    );
    if (
      rememberMeLocal !== null &&
      rememberMeLocal !== undefined &&
      rememberMeLocal !== ''
    ) {
      this.rememberMeId = rememberMeLocal;
      this.isChecked = true;
    }
    this.showPassword = false;
    this.createLoginFormGroup();
    this.newuser = this.storeService.getCurrentregistrationState();
  }
  
  ngOnInit() { }

  createLoginFormGroup() {
    this.loginForm = this.fb.group({
      userid: [
        this.rememberMeId,
        Validators.compose([Validators.required, EmailValidator.validateEmail])
      ],
      password: ['', Validators.compose([Validators.required])],
      rememberMe: [this.isChecked]
    });
  }

  loginUser(logindata: any) {
    this.formSubmitAttempt = true;
    this.showPassword = false;

    let pendingDocsExists = this.route.snapshot.queryParamMap.get('Pendingdocs') === 'true';
    let pendingDocsExistsUrl = this.router.url.includes('Pendingdocs=true');
    let rrPaymentExists = this.route.snapshot.queryParamMap.get('rrPayment') === 'true';
    let rrPaymentExistsUrl = this.router.url.includes('rrPayment=true');
    if (this.loginForm.valid) {
      // Tracker.log('Remember me check: ', logindata.rememberMe);
      this.loaded = false;
      this.authService
        .login(CommonUtil.lrtrim(logindata.userid), logindata.password)
        .then((phuser: PolicyHolder) => {
          this.googleAnalytics.trackEvent('Login', 'LoginAttemptSuccess', 'LoginAttempt', 200);
          Tracker.loginfo('MyPolicyComponent', 'ngOnInit()', 'Initializing onload functions', 'Component ## Policy Data from Store :::: ');
          if (phuser && phuser.jwtToken && phuser.jwtToken !== null && phuser.jwtToken !== '') {
            // this.authService.saveUserLogin(phuser);
            this.authService.getPolicyModAndVerifyPendingDocs(phuser).then((plcyHolder: PolicyHolder) => {
              // Check for Remember Me
              if (logindata.rememberMe) {
                localStorage.setItem(`${environment.rememberMeId}` + '_userId', logindata.userid);
              } else {
                localStorage.removeItem(`${environment.rememberMeId}` + '_userId');
              }
              this.loaded = true;

              this.timeoutService.start();

              const policyNumber = new Policynumber(plcyHolder.pointHookID.substring(2).concat(plcyHolder.policyMod));

                  // Check if MFA Date is expired
                  
                 if(!this.bwOktaEnabled){
                    this.plcyhlderService.getMFADate(policyNumber.symbol, policyNumber.policy,
                      policyNumber.module, plcyHolder.pointHookID.substring(0, 2))
                    .subscribe(mfaDateData => {
                      this.mfaRes = mfaDateData;
                      console.log('1=' + this.mfaRes.Result);
                      if (this.mfaRes &&  this.mfaRes.Result === '0') {
                        console.log('2=' + this.mfaRes.Date);
                        // check if set
                        if (this.mfaRes.Date === undefined || this.mfaRes.Date === '' || this.mfaRes.Date === 'null') {

                          // update the date and continue if  not okta login
                          
                          this.plcyhlderService.updateMFADate(policyNumber.symbol, policyNumber.policy,
                            policyNumber.module, plcyHolder.pointHookID.substring(0, 2), CommonUtil.addDateNowEST(GlobalConstants.MFA_DAYS))
                          .subscribe(mfaUpdateDate => {
                            this.mfaRes = mfaDateData;
                          },
                          error => {
                            Tracker.logerror('LoginComponent', 'onSubmit', 'updateMFADate',
                          'Error updating MFA Date.', error);
                          this.loaded = true;
                          });
                         
                          
                          this.storeService.updateLoginInfo(plcyHolder);
                          this.authService.createWebSessLog(plcyHolder);

                          if(this.router.url !== undefined && this.router.url !== null){
                            
                            if(plcyHolder.HasMissingDocuments && (pendingDocsExists || pendingDocsExistsUrl))
                            {
                              return this.navigateByQuery(plcyHolder,this.router,'Pendingdocs');
                            }
                            
                            if(rrPaymentExists || rrPaymentExistsUrl)
                            {
                              return this.navigateByQuery(plcyHolder,this.router,'rrPayment');
                            }
                          }

                          if (plcyHolder && plcyHolder.gopaperlessInd === true) {
                            return this.router.navigate(['/gptermsnconditions']);
                          } else if (plcyHolder && plcyHolder.esigstatus === true) {
                            return this.router.navigate(['/esigmessages']);
                          } else {
                            this.storeService.deletePolicyInfo(new FullPolicyDetails());
                            return this.router.navigate(['/policy']);
                          }
                        } else {
                          if (new Date().getTime() >= new Date(this.mfaRes.Date).getTime() ) {
                            // Show MFA Question Page
                            /*this.newuser.policynumber = policyNumber.symbol + policyNumber.policy + policyNumber.module;
                            this.newuser.mco = plcyHolder.pointHookID.substring(0, 2);
                            this.newuser.userid = plcyHolder.email;
                            this.newuser.password = logindata.password;
                            this.newuser.secq1 = plcyHolder.question1;
                            this.newuser.secq2 = plcyHolder.question2;
                            this.newuser.secq3 = plcyHolder.question3;
                            this.storeService.updateRegistrationState(this.newuser);*/
                            // this.createphUserObject();
                            this.authService._setLoginStatus(false);
                           // this.storeService.deleteLoginInfo(phuser);
                            return this.router.navigate(['/mfa']);
                          } else {
                            this.storeService.updateLoginInfo(plcyHolder);
                            this.authService.createWebSessLog(plcyHolder);

                            if(this.router.url !== undefined && this.router.url !== null){
                            
                              if(plcyHolder.HasMissingDocuments && (pendingDocsExists || pendingDocsExistsUrl))
                              {
                                return this.navigateByQuery(plcyHolder,this.router,'Pendingdocs');
                              }
                              
                              if(rrPaymentExists || rrPaymentExistsUrl)
                              {
                                return this.navigateByQuery(plcyHolder,this.router,'rrPayment');
                              }
                            }

                            if (plcyHolder && plcyHolder.gopaperlessInd === true) {
                              return this.router.navigate(['/gptermsnconditions']);
                            } else if (plcyHolder && plcyHolder.esigstatus === true) {
                              return this.router.navigate(['/esigmessages']);
                            } else {
                              this.storeService.deletePolicyInfo(new FullPolicyDetails());
                              return this.router.navigate(['/policy']);
                            }
                          }
                        }
                      }
                    },
                    error => {
                      Tracker.logerror('LoginComponent', 'onSubmit', 'getMFADate',
                    'Error getting MFA Date.', error);
                    this.loaded = true;
                    });
                   }else{
                     console.log("getMFADate call skipped due to okta flow");
                     this.storeService.updateLoginInfo(plcyHolder);
                            this.authService.createWebSessLog(plcyHolder);

                            if(this.router.url !== undefined && this.router.url !== null){
                            
                              if(plcyHolder.HasMissingDocuments && (pendingDocsExists || pendingDocsExistsUrl))
                              {
                                return this.navigateByQuery(plcyHolder,this.router,'Pendingdocs');
                              }
                              
                              if(rrPaymentExists || rrPaymentExistsUrl)
                              {
                                return this.navigateByQuery(plcyHolder,this.router,'rrPayment');
                              }
                            }

                            if (plcyHolder && plcyHolder.gopaperlessInd === true) {
                              
                              this.router.navigate(['/gptermsnconditions']);
                            } else if (plcyHolder && plcyHolder.esigstatus === true) {
                              this.router.navigate(['/esigmessages']);
                            } else {
                              this.storeService.deletePolicyInfo(new FullPolicyDetails());
                              this.router.navigate(['/policy']);
                            }
                   }

                    
              // return;
            }).catch(error => {
              this.loaded = true;
              this.storeService.deleteLoginInfo(null);
              if (error instanceof BWError) {
                // Tracker.log('Error Happened in main login service', error.errorcode, error.errordesc);
                /* Fallback logic to handle opening Child Modals from Parent - ExpressionChangedAfterItHasBeenCheckedError
                Object.keys(this.loginForm.controls).forEach(key => {
                  this.loginForm.controls[key].markAsTouched();
               });
                this.openModal(error.errordesc);*/
              }

              Tracker.logerror('LoginComponent', 'loginUser', 'getPolicyModAndVerifyPendingDocs', 'Error during login.', error);
            });
          }
        }).catch(error => {
          this.googleAnalytics.trackEvent('Login', 'LoginAttemptFail', 'LoginAttempt', 500);
          this.loaded = true;
          this.storeService.deleteLoginInfo(null);
          if (error instanceof BWError) {
            if (error && error.errordesc === '90') {
              Tracker.logerror('LoginComponent', 'loginUser', 'login', 'Error during login.', error.errordesc);

              return this.router.navigate(['/home/reset-password'], { queryParams: { reset: '1'}});
          }
        }
      });
    }else{
      setTimeout(() => {
        (document.getElementById("loginForm").querySelector('[id=elemID]') as HTMLElement).focus();
      });
   
    }
  }

  openModal(errormsg: string) {
    const modalRef = this.modalService.open(ErrorRedirectsComponent);
    modalRef.componentInstance.errormsg = errormsg;
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  showForgotEmail() {
    this.modalService.open(ForgotUseridComponent);
  }

  // Form Validation Utilities
  isFieldValid(field: string) {
    return (
      (!this.loginForm.get(field).valid && this.loginForm.get(field).touched) ||
      (this.loginForm.get(field).untouched &&
        this.loginForm.get(field).value === '' &&
        this.formSubmitAttempt)
    );
  }

  getUserIDErrMsg(errors) {
    if (errors && errors !== null) {
      if (errors.required === true) {
        return this.usermessage.REQUIRED_USEREMAIL;
      } else if (errors.email === true) {
        return this.usermessage.FORMAT_USEREMAIL;
      }
    }
  }

  // this functio s called when user clicked on the new User button.It will navigate to registration page
  newUser() {
    this.router.navigate(['/register']);
    this.googleAnalytics.trackEvent('UserRegistration', 'UserRegistrationScreen', 'UserRegistrationContinueClick', 200);
  }

  navigateByQuery(plcyHolder: PolicyHolder,router: Router, linType:string){
    const mco = CommonUtil.getMCO(plcyHolder);
    const polsym = CommonUtil.getPolicySymbol(plcyHolder);
    const polNum = CommonUtil.getPolicyNumber(plcyHolder);
    const polMod = plcyHolder.policyMod;
    this.policyService.getAllBasicPolicyInfo(mco, polsym, polNum, polMod)
    .then((policyres: FullPolicyDetails) => {

      if(linType === 'rrPayment') {
        return router.navigate(['/payment']); 
      }
      else if(linType === 'Pendingdocs') {
        // commented code for pending docs
        //return router.navigate(['/policy/missing-document']);
      } 
    } ).catch(error => {
      Tracker.logerror('MyPolicyComponent', 'ngOnInit()', 'Initializing onload functions',
      'MypolicyService-getAllBasicPolicyInfo() Failed', error);
      return router.navigate(['/policy']);
  });
}


}
