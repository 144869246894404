import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MyprofileService } from '../../../services/myprofile/myprofile.service';
import { PolicyHolder } from '../../model/authentication/authenticatephres';
import { PolicyInfo, FullPolicyDetails, AgentInfo } from '../../model/api/bwibic/fullpolicydetails.model';
import { BwstoreService } from '../../services/bwstore.service';
import { Router } from '@angular/router';
import { MessageConstants } from '../../../constants/message.constant';
import { EmailValidator } from '../../validators/email.validator';
import { CommonUtil } from '../../utils/commonutil';
import { BWErrorhandler } from '../../../services/errorhandler';
import { BWError } from '../../model/error.model';
import { Tracker } from '../../utils/tracker';
import { routerNgProbeToken } from '@angular/router/src/router_module';
import { GlobalConstants } from '../../../constants/global.constant';

@Component({
  selector: 'app-unenroll-gopaperless',
  template: `
  <div class="modal-header">
    <h4 class="modal-title">Unenroll From Go Paperless</h4>
    <button type="button" class="close" aria-label="Close" (click)="errorModal.dismiss('Cross click')">
  <span aria-hidden="true">&times;</span></button>
</div>
<form class="my-4">
<div class="d-flex justify-content-center border-0">

  <div *ngIf="this.returnUnenrollMsg !== '' && this.returnUnenrollMsg ==='success'"
  class="alert alert-success alert-dismissible fade show ng-star-inserted">
  You are no longer enrolled in Go Paperless. </div>
  <app-app-errors *ngIf="this.returnUnenrollMsg !== '' &&
  this.returnUnenrollMsg ==='failure'"></app-app-errors>

</div>
<div class="modal-body">
<div class="form-row">
  <div class="col">
    <div class="form-group">
      {{this.unenrollMsg}}
      </div>
  </div>
</div>
</div>
<div class="modal-footer">
  <button class="btn btn-secondary" (click)="errorModal.dismiss('Close click')">
    Cancel
  </button>
  <div *ngIf="returnUnenrollMsg === '' || (this.returnUnenrollMsg !== '' && this.returnUnenrollMsg ==='failure')">
    <button *ngIf="loaded" type="submit" class="btn btn-primary" (click)='unenrollGP()' data-dismiss="modal">Unenroll</button>
      <button *ngIf="!loaded" class="btn btn-primary" disabled="true"><i class="fas fa-circle-notch fa-spin">
      </i>&nbsp;Unenroll</button>
  </div>
</div>
</form>
  `,
  styles: []
})

export class UnEnrollGoPaperlessComponent implements OnInit {
  private authenticatedPhDetails: PolicyHolder;
  returnUnenrollMsg = '';
  basicPolicyDetails: FullPolicyDetails;
  policyInfo: PolicyInfo;
  unenrollMsg: string;
  loaded = true;
  @Output() emitService = new EventEmitter();
  constructor(public errorModal: NgbActiveModal, private _storeService: BwstoreService, private myProfileService: MyprofileService,
    private errorHandler: BWErrorhandler, private router: Router) {
  }

  ngOnInit() {
    this.basicPolicyDetails = this._storeService.getPolicyInfoFromStore();
    this.policyInfo = this.basicPolicyDetails.policyInfo;
    this.authenticatedPhDetails = this._storeService.getLoginInfoFromStore();
    // this.getStatePaperDocFee();
    this.unenrollMsg = 'You are currently enrolled in Go Paperless,'
    + 'if you wish to discontinue your enrollment and receive policy documents in the mail then select \'Unenroll\'';
  }

  unenrollGP() {
    const mco = CommonUtil.getMCO(this.authenticatedPhDetails);
    this.returnUnenrollMsg = '';
    const bwPolNum = CommonUtil.getBWPolicyNumber(this.authenticatedPhDetails);
    this.loaded = false;
    this.myProfileService.unenrollGP(bwPolNum, mco).
        then(
          data => {
            Tracker.loginfo('UnEnrollGoPaperlessComponent', 'unenrollGP', '', 'Response from unenrollGP()' + data.unEnrollEpolicyResult);
            this.loaded = true;
            if (data.unEnrollEpolicyResult === true) {
              this.emitService.emit({gpstatus: 'Not Enrolled'});
              this.returnUnenrollMsg = 'success';

              // Update the GoPaperless Status to store
              this.authenticatedPhDetails.gopaperlessEnrollment = GlobalConstants.GP_STATUS_R;
              this._storeService.updateLoginInfo( this.authenticatedPhDetails);

            } else {
              this.returnUnenrollMsg = 'failure';
              this.errorHandler.handleApplicationError(new BWError(
                {'errorcode': '100', 'errordesc': 'Your request could not be processed,' +
                +'please contact your agent for assistance.', 'source': 'GoPaperlessService'}));
            }
          }).catch(error => {
            Tracker.logerror('UnEnrollGoPaperlessComponent', 'unenrollGP()', '',
            'Your request could not be processed, please contact your agent for assistance.', error);
          });
  }

  getStatePaperDocFee() {
    const mco = CommonUtil.getMCO(this.authenticatedPhDetails);
    const state = this.authenticatedPhDetails.riskstate;
    const lob = this.policyInfo.LINE0BUS;
    const rateBook = this.policyInfo.PolicyRateBook;

    this.myProfileService.getStatePaperDocFee(state, mco, rateBook, lob).
        then(
          data => {
            Tracker.loginfo('UnEnrollGoPaperlessComponent', 'getStatePaperDocFee', '',
            'response from getStatePaperDocFee()' + data);
            // this.unenrollMsg = 'Are you sure you want to unenroll in Go Paperless?';
            if (data !== 0 ) {
              this.unenrollMsg = 'Do you really want to Unenroll with state document fee?';
            } else {
              this.unenrollMsg = 'Do you really want to Unenroll?';
            }
          })
          .catch(error => {
            Tracker.logerror('UnEnrollGoPaperlessComponent', 'getStatePaperDocFee()', '',
            'Error getting the paper document fee', error);
          });
  }

  reloadCurrentPage() {
    this.errorModal.dismiss('Cross clicked');

    // Need to hit another route and then the profile page again to get it to reload
    this.router.navigateByUrl('/policy', {skipLocationChange: true}).then(() =>
      this.router.navigate(['/profile']));
  }
}
