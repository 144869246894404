import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerRegistrationService } from '../../../services/registration/customer-registration.service';
import { CheckRegistrationStatusResponse } from '../../model/api/bwsecurity/registrationstatusres.model';
import { MessageConstants } from '../../../constants/message.constant';
import { CommonUtil } from '../../utils/commonutil';

@Component({
  selector: 'app-bw-landing',
  templateUrl: './bw-landing.component.html',
  styleUrls: ['./bw-landing.component.scss']
})
export class BwLandingComponent implements OnInit {

  message: string;
  policynumber: string;
  lastname: string;
  mco: string;
  continueAction: string;
  landingsrc: string;

  constructor(private route: ActivatedRoute,  private router: Router, private custRegService: CustomerRegistrationService) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      // Tracker.log('Params from externa service::', params);
      if (params) {
        const src = params.src;
        this.landingsrc = params.src;
        this.policynumber = params.PolicyNum;
        this.mco = params.MCO;
        this.lastname = params.LastName;


        this.custRegService.checkRegistrationStatus(this.mco, this.policynumber).then((regstatus: CheckRegistrationStatusResponse) => {
          /*if (regstatus) {
            this.message = CommonUtil.getLandingMessage(regstatus.checkRegistrationStatusResult, src);
            this.continueAction = CommonUtil.getContinueAction(regstatus.checkRegistrationStatusResult);
            // console.error('I have to take the user to ', this.continueAction, ' & Message:: ', this.message);
          } else {
            this.message = '';
            this.continueAction = 'LOGIN';
          }*/

          let queryParams = {};
          if (regstatus && !regstatus.checkRegistrationStatusResult) {
            this.continueAction = CommonUtil.getContinueAction(regstatus.checkRegistrationStatusResult);

            if (this.continueAction === 'REGISTER' && this.landingsrc === 'WELCOME') {
              queryParams = { policyNumber: this.policynumber, LastName: this.lastname, mco: this.mco};
            } else {
              queryParams = { policyNumber: this.policynumber, mco: this.mco};
            }

            this.router.navigate(['/register'], { queryParams: queryParams });

          } else {
            queryParams = { src: this.landingsrc};
            this.router.navigate(['/'], { queryParams: queryParams });
          }

        });

      }
    });
  }

}
