
  <main id="main">
    <div class="container">

      <div class="row d-flex">
        <div class="col-md-12 col-sm-12 text-left" >
          <h1 class="heading-4 mt-3" aria-level="1">Find an Agent or Broker</h1>
          <div *ngIf="isLoading" class="col-12 small text-center justify-content-center">Retrieving Agents...</div>
          <div *ngIf="isLoading" class=" col-12 my-5 text-center justify-content-center">
            <app-bw-spinner [smallSpinner]="false"></app-bw-spinner>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-center mb-1">
        <div class="col-md-12 text-center justify-content-center">
          <div *ngIf="this.errorMessage !== ''" class="alert alert-danger alert-dismissible fade show" role="alert">
          {{ this.errorMessage }}
          </div>
        </div>
      </div>
      <div class="row d-flex ">
        <div class="col-md-10 col-sm-12">
          <form [formGroup]="agentSearchForm" *ngIf="!isLoading">
            <div class="input-group mb-3 m-md-0">
              <input [appNumbersonlyinput]="true" type="tel" formControlName="zipcode" class="form-control" maxlength="5" minlength="5"
              placeholder="Enter Zip Code" aria-label="Zip Code">
              <div class="input-group-append">
                <button class="btn btn-primary px-3 btn-more-focus" aria-label="search" type="submit" (click)="onSubmit(agentSearchForm.value)">
                  <span class="icon icon-svg icon-color-white">
                  <i class="fas fa-search fa-stack-1x fa-lg icon-align color-white"></i>
                  </span>
                </button>
              </div>
            </div>
            <div class="float-left">
            <app-field-error-display [displayError]="isFieldValid('zipcode')"
            [errorMsg]="getZipCodeErrMsg(agentSearchForm.controls['zipcode'].errors)"></app-field-error-display>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
  