export enum PaymentusIframePaymentMethodType {
    CREDIT_CARD = 'CC',
    DEBIT_CARD = 'DC',
    DIRECT_DEBIT = 'DD',
}

export const PaymentMethodAssetImgUrl = {
    VISA: { url: 'assets/img/payment/visa.svg', adaLabel: 'Visa card' },
    MC: { url: 'assets/img/payment/mastercard.svg', adaLabel: 'Master card' },
    AMEX: { url: 'assets/img/payment/amex.svg', adaLabel: 'American express card' },
    DISC: { url: 'assets/img/payment/discover.svg', adaLabel: 'Discovery card' },
    DISC_DEBIT: { url: 'assets/img/payment/discover.svg', adaLabel: 'Discovery debit card' },
    VISA_DEBIT: { url: 'assets/img/payment/visa.svg', adaLabel: 'Visa debit card' },
    MC_DEBIT: { url: 'assets/img/payment/mastercard.svg', adaLabel: 'Master debit card' },
    CHQ: { adaLabel: 'Checkings account' },
    SAV: { adaLabel: 'Savings account' },
    BUS_CHQ: { adaLabel: 'Buisness Checkings account' },
};

export const PaymentMethodTypeMapping = {
    VISA: PaymentusIframePaymentMethodType.CREDIT_CARD,
    MC: PaymentusIframePaymentMethodType.CREDIT_CARD,
    AMEX: PaymentusIframePaymentMethodType.CREDIT_CARD,
    DISC: PaymentusIframePaymentMethodType.CREDIT_CARD,
    DISC_DEBIT: PaymentusIframePaymentMethodType.DEBIT_CARD,
    VISA_DEBIT: PaymentusIframePaymentMethodType.DEBIT_CARD,
    MC_DEBIT: PaymentusIframePaymentMethodType.DEBIT_CARD,
    CHQ: PaymentusIframePaymentMethodType.DIRECT_DEBIT,
    SAV: PaymentusIframePaymentMethodType.DIRECT_DEBIT,
    BUS_CHQ: PaymentusIframePaymentMethodType.DIRECT_DEBIT,
};

export type PaymentMethodInfoType =
    | PaymentusIframePaymentMethodType.CREDIT_CARD
    | PaymentusIframePaymentMethodType.DEBIT_CARD
    | PaymentusIframePaymentMethodType.DIRECT_DEBIT;
