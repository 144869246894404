var Processcardreq = /** @class */ (function () {
    function Processcardreq() {
    }
    return Processcardreq;
}());
export { Processcardreq };
var PolicyKey = /** @class */ (function () {
    function PolicyKey() {
    }
    return PolicyKey;
}());
export { PolicyKey };
var EFTCard = /** @class */ (function () {
    function EFTCard() {
    }
    return EFTCard;
}());
export { EFTCard };
var CardHolder = /** @class */ (function () {
    function CardHolder() {
    }
    return CardHolder;
}());
export { CardHolder };
