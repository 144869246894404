/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./landing-tabs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../directsales/directsales.component.ngfactory";
import * as i3 from "../directsales/directsales.component";
import * as i4 from "@angular/common/http";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/router";
import * as i7 from "../../services/directsales.service";
import * as i8 from "../../services/messages.service";
import * as i9 from "../../../services/api/endorsement/endorsement.service";
import * as i10 from "../../services/analytics/google-analytics.service";
import * as i11 from "@angular/common";
import * as i12 from "./landing-tabs.component";
var styles_LandingTabsComponent = [i0.styles];
var RenderType_LandingTabsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LandingTabsComponent, data: {} });
export { RenderType_LandingTabsComponent as RenderType_LandingTabsComponent };
function View_LandingTabsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-directsales", [["fromPage", "Home"]], null, null, null, i2.View_DirectsalesComponent_0, i2.RenderType_DirectsalesComponent)), i1.ɵdid(1, 114688, null, 0, i3.DirectsalesComponent, [i4.HttpClient, i5.FormBuilder, i6.Router, i6.ActivatedRoute, i7.DirectsalesService, i8.MessagesService, i9.EndorsementService, i10.GoogleAnalyticsService, i11.DatePipe], { fromPage: [0, "fromPage"] }, null)], function (_ck, _v) { var currVal_0 = "Home"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LandingTabsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "row text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-md-12 col-sm-12 direct-sales-form"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LandingTabsComponent_1)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showQuickQuote === "Y"); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_LandingTabsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-landing-tabs", [], null, null, null, View_LandingTabsComponent_0, RenderType_LandingTabsComponent)), i1.ɵdid(1, 114688, null, 0, i12.LandingTabsComponent, [i10.GoogleAnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LandingTabsComponentNgFactory = i1.ɵccf("app-landing-tabs", i12.LandingTabsComponent, View_LandingTabsComponent_Host_0, {}, {}, []);
export { LandingTabsComponentNgFactory as LandingTabsComponentNgFactory };
