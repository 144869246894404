import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Tracker } from '../../shared/utils/tracker';

@Component({
  selector: 'app-payment-iframe-frms',
  templateUrl: './payment-iframe-frms.component.html',
  styleUrls: ['./payment-iframe-frms.component.scss']
})
export class PaymentIframeFrmsComponent implements OnInit {
  sourceURL: string;


  @ViewChild('iframe') iframe: ElementRef;
  @ViewChild('qckRemind') quickReminder: ElementRef;
  @ViewChild('iframeParent') iframeDiv: ElementRef;
  @ViewChild('mainElnt') mainElnt: ElementRef;


  isLoaded: boolean = false;
  hideReminders: boolean = false;
  divHeight: number = null;
  constructor() { }

  ngOnInit() {
    this.sourceURL = `${environment.paymentUsIframeURL}`;
  }

  @HostListener('window:message', ['$event'])
  onMessage(e) {
    if (e && e.data) {
      if (typeof (e.data) !== 'object' && typeof (e.data) !=='boolean') {
        var message = e.data;
        if (message.includes('height:')) {
          if (message.split(':').length > 0) {
            this.divHeight = Number(message.split(':')[1]) +30;
            Tracker.loginfo('PaymentIframeFrmsComponent','iframe','height', this.divHeight.toString());
            this.mainElnt.nativeElement.style.height = this.divHeight +'px';
          }

        }
        const step1 = message.match(/^(?=.*"step")(?=.*"1")/);
        if (step1) {
          this.isLoaded = true;
          if (this.quickReminder && this.quickReminder.nativeElement && this.quickReminder.nativeElement) {
            this.quickReminder.nativeElement.style.display = 'block';
            this.iframeDiv.nativeElement.classList.add('col-sm-8');
            this.iframeDiv.nativeElement.classList.remove('col-sm-12');
          }
        }
        const step2 = message.match(/^(?=.*"step")(?=.*"2")/);
        if (step2) {
          this.quickReminder.nativeElement.style.display = 'none';
          this.iframeDiv.nativeElement.classList.remove('col-sm-8');
          this.iframeDiv.nativeElement.classList.add('col-sm-12');
        }
      }
    }
  }
}
