import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbRoute } from '../../model/breadcrumb-route';
import { AuthenticationService } from '../../services/authentication.service';
var BreadcrumbComponent = /** @class */ (function () {
    function BreadcrumbComponent(route, authService) {
        var _this = this;
        this.route = route;
        this.authService = authService;
        this.authService.isUserLoggedIn.subscribe(function (authenticated) {
            _this.isUserLoggedIn = authenticated;
        });
    }
    BreadcrumbComponent.prototype.ngOnInit = function () {
        this.breadCrumbRoutes = new Array();
        var hasRoot = false;
        var prevPath;
        var count = 0;
        var builtPath = '';
        for (var _i = 0, _a = this.route.pathFromRoot; _i < _a.length; _i++) {
            var pfr = _a[_i];
            if (pfr.routeConfig !== null && pfr.routeConfig.data !== undefined) {
                this.currentBreadCrumb = (this.route.routeConfig !== null &&
                    this.route.routeConfig.data !== undefined ? this.route.routeConfig.data['breadcrumb'] : '');
                builtPath += pfr.routeConfig.path.concat('/');
                if (!hasRoot) {
                    if (this.route.pathFromRoot.length === 2) {
                        this.breadCrumbRoutes[0] = this.isUserLoggedIn ? new BreadcrumbRoute('My Policy', '/') : new BreadcrumbRoute('Home', '/');
                        this.breadCrumbRoutes[1] = new BreadcrumbRoute(pfr.routeConfig.data['breadcrumb'], pfr.routeConfig.path);
                    }
                    else {
                        this.breadCrumbRoutes[count] = new BreadcrumbRoute(pfr.routeConfig.data['breadcrumb'], pfr.routeConfig.path);
                    }
                    if (pfr.routeConfig.path === 'home') {
                        this.breadCrumbRoutes[count].routePath = '/';
                    }
                    hasRoot = true;
                }
                else {
                    this.breadCrumbRoutes[count] =
                        new BreadcrumbRoute(pfr.routeConfig.data['breadcrumb'], builtPath);
                }
                prevPath = pfr.routeConfig.path;
                ++count;
            }
        }
    };
    return BreadcrumbComponent;
}());
export { BreadcrumbComponent };
