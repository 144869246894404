import { ElementRef } from '@angular/core';
var PolicynumberformatDirective = /** @class */ (function () {
    function PolicynumberformatDirective(elementRef) {
        this.elementRef = elementRef;
        this.POLICY_SEPARATOR = '-';
        this.onlyCharactersRegEx = '^[a-zA-Z]*$';
        this.symbolRegex = '^[a-zA-Z]{1}[0-9]{2}$';
        this.policyNumRegex = '^[0-9]{7}$';
        this.modRegex = '^[0-9]{2}$';
        this.el = this.elementRef.nativeElement;
    }
    PolicynumberformatDirective.prototype.onKeyUp = function (event) {
        var e = event;
        if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1) {
            return;
        }
        var fvalue = event.target.value;
        // Tracker.log("User Input: ", fvalue, fvalue.length);
        if (fvalue) {
            if (fvalue.length === 3) {
                fvalue += '-';
                this.el.value = fvalue;
            }
            else if (fvalue.length === 11) {
                fvalue += '-';
                this.el.value = fvalue;
            }
            else if (fvalue && fvalue.indexOf(this.POLICY_SEPARATOR) === -1 && fvalue.length === 12) {
                // Tracker.log("User Input Change : ", value, value.length);
                var fotmattedValue = fvalue.substr(0, 3) + this.POLICY_SEPARATOR + fvalue.substr(3, 7) +
                    this.POLICY_SEPARATOR + fvalue.substr(10, 2);
                this.el.value = fotmattedValue;
            }
        }
    };
    // TODO: Revisit this logic to handle paste event
    /*@HostListener('paste', ['$event']) on(event) {
      let e = <KeyboardEvent> event;
  
      if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1) {
        return;
      }
      let value = event.target.value;
  
      if(value && value.indexOf(this.POLICY_SEPARATOR) === -1 && value.length === 12){
        // Tracker.log("User Input Blur : ", value, value.length);
        let fotmattedValue = value.substr(0, 3) + this.POLICY_SEPARATOR + value.substr(3, 7) + this.POLICY_SEPARATOR + value.substr(10,2);
        this.el.value = fotmattedValue;
        this.el.form.noValidate;
      }
    }*/
    PolicynumberformatDirective.prototype.onchange = function (event, e, m) {
        var value = event.target.value;
        // Tracker.log("User Input change : ", value);
        if (value && value.indexOf(this.POLICY_SEPARATOR) === -1 && value.length === 12) {
            // Tracker.log("User Input Change : ", value, value.length);
            var fotmattedValue = value.substr(0, 3) + this.POLICY_SEPARATOR + value.substr(3, 7) +
                this.POLICY_SEPARATOR + value.substr(10, 2);
            event.target.value = fotmattedValue;
        }
    };
    return PolicynumberformatDirective;
}());
export { PolicynumberformatDirective };
