import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { GlobalConstants } from '../../../constants/global.constant';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var NewPaymentService = /** @class */ (function () {
    function NewPaymentService(http) {
        this.http = http;
    }
    NewPaymentService.prototype.makeNewPayment = function (paymentRequest) {
        var url = "" + environment.apiServiceUrl + GlobalConstants.MAKE_NEW_PAYMENT_URL;
        return this.http.post(url, paymentRequest);
    };
    NewPaymentService.ngInjectableDef = i0.defineInjectable({ factory: function NewPaymentService_Factory() { return new NewPaymentService(i0.inject(i1.HttpClient)); }, token: NewPaymentService, providedIn: "root" });
    return NewPaymentService;
}());
export { NewPaymentService };
