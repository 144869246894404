import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Policynumber } from '../../../shared/model/policynumber';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { BwstoreService } from '../../../shared/services/bwstore.service';
import { Tracker } from '../../../shared/utils/tracker';
var PaymentSuccessComponent = /** @class */ (function () {
    function PaymentSuccessComponent(route, router, _storeService, authService) {
        this.route = route;
        this.router = router;
        this._storeService = _storeService;
        this.authService = authService;
    }
    PaymentSuccessComponent.prototype.ngOnInit = function () {
        this.message = '';
        if (this._storeService.getPolicyInfoFromStore().paymentSummary !== null
            && this._storeService.getPolicyInfoFromStore().paymentSummary.amount !== undefined) {
            var policyNum = new Policynumber(this._storeService.getPolicyInfoFromStore().policyInfo.SYMBOL.concat(this._storeService.getPolicyInfoFromStore().policyInfo.POLICY0NUM.concat(this._storeService.getPolicyInfoFromStore().policyInfo.MODULE)));
            this.policyNumber = policyNum.toFormattedString();
            this.paidAmount = this._storeService.getPolicyInfoFromStore().paymentSummary.amount;
            this.paymentDate = Date.now();
            this.cardHolder = this._storeService.getPolicyInfoFromStore().paymentSummary.firstName.concat(' ').concat(this._storeService.getPolicyInfoFromStore().paymentSummary.lastName);
            this.cardNumber = this._storeService.getPolicyInfoFromStore().paymentSummary.cardLastFour;
            this.confirmationNumber = this._storeService.getPolicyInfoFromStore().paymentSummary.confirmation;
            this.isCreditCard = this._storeService.getPolicyInfoFromStore().paymentSummary.isCreditCard;
            if (this._storeService.getPolicyInfoFromStore().isNoLogin) {
                // Log out user and clean up session
                Tracker.loginfo('PaymentSuccessComponent', 'ngOnInit', '', 'Removing Without Login for: ' + this.policyNumber);
                this.authService.logout();
            }
        }
        else {
            this.message = 'Unable to retrieve payment response';
            // Log out user and clean up session
            this.authService.logout();
        }
    };
    return PaymentSuccessComponent;
}());
export { PaymentSuccessComponent };
