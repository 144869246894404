import { BehaviorSubject, Observable, of } from 'rxjs';
import { MessageConstants } from '../../constants/message.constant';
import { ErrMsgMap } from '../maps/err-msg-map';
import * as i0 from "@angular/core";
var MessagesService = /** @class */ (function () {
    function MessagesService() {
        this.appMessages = [];
        this.systemMessages = [];
        this.loggingMessages = [];
        this.elapsedTimes = [];
        this.uploadDocumentMsgs = [];
        // System Messages
        this.systemMsgsSubject = new BehaviorSubject([]);
        this.systemMsgs = this.systemMsgsSubject.asObservable();
        this.showbwcom = new BehaviorSubject(true);
        this.errMsgMap = new ErrMsgMap();
    }
    MessagesService.prototype.addAppMessage = function (error) {
        this.appMessages.push(error);
    };
    MessagesService.prototype.addSystemMessages = function (message) {
        // Tracker.log('Adding System Error', message);
        this.systemMessages.push(message);
        this.systemMsgsSubject.next(this.systemMessages);
        // Tracker.log('Length of System Error', this.elapsedTimes.length);
    };
    MessagesService.prototype.getSystemMessages = function () {
        /*let promise = new Promise((resolve, reject) => {
          resolve(this.systemMessages);
        });
        return promise;*/
        return of(this.systemMessages);
    };
    MessagesService.prototype.getAppMessages = function () {
        return this.appMessages;
    };
    MessagesService.prototype.getErrorMessages = function (errors, key) {
        if (errors) {
            if (errors.required === true) {
                return this.errMsgMap.get(key);
            }
            else if (errors.maxLength === true) {
                return ''; // this.usermessage.REQUIRED_ACCOUNT_NUMBER;
            }
            else if (errors.usDate === true) {
                return MessageConstants.FORMAT_DOB;
            }
        }
    };
    MessagesService.prototype.clearAppMessages = function () {
        // Object.assign({}, this.appMessages, []);
        this.appMessages.pop();
    };
    MessagesService.prototype.resetApplicationMsgs = function () {
        if (this.appMessages.length > 0) {
            this.appMessages.splice(0, this.appMessages.length);
        }
    };
    MessagesService.prototype.clearSystemMessages = function () {
        // Tracker.log('Clear System Messages Called..........');
        Object.assign({}, this.systemMessages, []);
        this.systemMessages = [];
    };
    MessagesService.prototype.clearAll = function () {
        // Tracker.log('Clear All Messages Called..........');
        this.appMessages = [];
        this.systemMessages = [];
        this.systemMsgsSubject.next([]);
    };
    MessagesService.prototype.addLog = function (url, message) {
        this.loggingMessages.push(message);
        // console.log('Timing Message in MessageService: ', url, message);
    };
    MessagesService.prototype.clearLog = function () {
        // Object.assign({}, this.loggingMessages, []);
        this.loggingMessages = [];
    };
    MessagesService.prototype.getLog = function () {
        return this.loggingMessages;
    };
    MessagesService.ngInjectableDef = i0.defineInjectable({ factory: function MessagesService_Factory() { return new MessagesService(); }, token: MessagesService, providedIn: "root" });
    return MessagesService;
}());
export { MessagesService };
