import { FullPolicyDetails, PolicyInfo, AgentInfo, EFTInfo } from '../../shared/model/api/bwibic/fullpolicydetails.model';
import {Paymentsummary, PaymentInfo } from '../../shared/model/api/bwibic/fullpolicydetails.model';

import * as BwPolicyInfoActions from '../actions/bwpolicy.actions';
import { Tracker } from '../../shared/utils/tracker';
import { MissingDocsRes } from '../../shared/model/api/missingDocs/missing-docs-res';
import { MissingDocument } from '../../shared/model/api/missingDocs/missing-document';

const defaultPolicyInfoState: FullPolicyDetails = new FullPolicyDetails();
const removePolicyInfoState: FullPolicyDetails = {
  result: '',
  message: '',
  isNoLogin: false,
  policyInfo: new PolicyInfo(''),
  agentInfo: new AgentInfo(''),
  paymentHistoryInfo: [],
  paymentInfo: new PaymentInfo(''),
  paymentSummary: new Paymentsummary(''),
  eftInfo: new EFTInfo(''),
  isDefaultdocumentType: '',
  missingDocsRes : new MissingDocument()
};

// Helper to create new state object as the state is immutable
const newState = (state, newDataObj) => {
  return Object.assign({}, state, newDataObj);
};

export function BwPolicyInfoReduer(state: FullPolicyDetails = defaultPolicyInfoState,
                        action: BwPolicyInfoActions.Actions) {
    switch (action.type) {
      case BwPolicyInfoActions.CREATE_POLICYINFO:
      //  Tracker.loginfo('BwPolicyInfoReduer', 'BwPolicyInfoReduer()', 'This is the POLICYINFO STORE: Adding to Store',
       //   'ActionType: ' + action.type + ' Payload:' + action.payload);
        return newState(state, action.payload);

      case BwPolicyInfoActions.UPDATE_POLICYINFO:
        // Tracker.loginfo('BwPolicyInfoReduer', 'BwPolicyInfoReduer()', 'This is the POLICYINFO STORE: Updating state to Store',
          // 'ActionType: ' + action.type + ' Payload:' + action.payload);
        return newState(state, action.payload);

      case BwPolicyInfoActions.REMOVE_POLICYINFO:
        // Tracker.loginfo('BwPolicyInfoReduer', 'BwPolicyInfoReduer()', 'This is the POLICYINFO STORE: Removing from Store',
         // 'ActionType: ' + action.type + ' Payload:' + action.payload);
        return newState(state, removePolicyInfoState);

      default:
        return state;
    }
}
