export class RemovePHRequest {
    email: string;
    adminPassword: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}

export class RemovePHResponse {
    removeStatusCode: string;
    removeMessage: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
