
export class PolicyEftRequest {
  policySym: string;
  policyNum: string;
  policyMod: string;
  mco: string;
  eftType: string;
  eftEffDate: string;
  accountType: string;
  routingNumber: string; // This will be card expiry date if CC or DC
  accountNumber: string;
  eMove: boolean;
  source: string;
  termReason: string;
  pspAZCorp: string;
  eftProcessedAs: string;
  eftProcessedVendor: string;
  polEffectiveDate: string;

  constructor() {}
}
