import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BwstoreService } from '../services/bwstore.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private _bwStoreService: BwstoreService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const currentUserInfo  = this._bwStoreService.getLoginInfoFromStore();

    const bwAuthToken = currentUserInfo.jwtToken;

    if (bwAuthToken && bwAuthToken !== null && bwAuthToken !== undefined && bwAuthToken !== '') {
      return true;
    }

    this.router.navigate(['']);
    return false;
  }
}
