import { CommonUtil } from '../utils/commonutil';
var EmailValidator = /** @class */ (function () {
    function EmailValidator() {
    }
    EmailValidator.validateEmail = function (control) {
        // tslint:disable-next-line:max-line-length
        var emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // Tracker.log('Validator Input Value: ', control.value);
        if (!CommonUtil.lrtrim(control.value).match(emailPattern)) {
            return { 'email': true };
        }
        return null;
    };
    EmailValidator.MatchUserId = function (AC) {
        var group = AC.parent;
        if (group) {
            var userId = group.controls['phuserid'].value;
            var confirmUserId = group.controls['phconfirmeduserid'].value;
            // Tracker.log("EmailValidator Validator : "+userId+"  and "+confirmUserId);
            if (userId && confirmUserId && userId.toUpperCase() !== confirmUserId.toUpperCase()) {
                // Tracker.log('false');
                return { 'confirmemail': true };
                // group.controls['phconfirmeduserid'].setErrors( {MatchUserId: true} )
            }
            else {
                // Tracker.log('true');
                return null;
            }
        }
        return null;
    };
    return EmailValidator;
}());
export { EmailValidator };
