import * as BwPolicyCancelActions from '../actions/bwpolicycancel.actions';
import { CancelAmount } from '../../shared/model/api/policyinfo/requesttocancelamount.model';
import { RequestToCancelInfo } from '../../shared/model/api/policyinfo/requesttocancelchecksres.model';
import { RequesttocancelStoreInfo } from '../../shared/model/requesttocancelinfo.model';
var defaultPolicyCancelState = new RequesttocancelStoreInfo();
var removePolicyCancelState = {
    cancelDate: '',
    cancelTime: '',
    confirmationNumber: '',
    cancelChecks: new RequestToCancelInfo(''),
    cancelAmount: new CancelAmount('')
};
// Helper to create new state object as the state is immutable
var newState = function (state, newDataObj) {
    return Object.assign({}, state, newDataObj);
};
var ɵ0 = newState;
export function BwpolicycancelReducer(state, action) {
    if (state === void 0) { state = defaultPolicyCancelState; }
    switch (action.type) {
        case BwPolicyCancelActions.CREATE_CANCELPOLICYINFO:
            return newState(state, action.payload);
        case BwPolicyCancelActions.UPDATE_CANCELPOLICYINFO:
            return newState(state, action.payload);
        case BwPolicyCancelActions.REMOVE_CANCELPOLICYINFO:
            return newState(state, removePolicyCancelState);
        default:
            return state;
    }
}
export { ɵ0 };
