
import * as BwCoveragesActions from '../actions/bwcoverages.actions';
import { ValidValuesResponse } from '../../shared/model/api/validvalues/valid-values-res.model';

const defaultCoveragesState: ValidValuesResponse = new ValidValuesResponse();

// Helper to create new state object as the state is immutable
const newState = (state, newDataObj) => {
    return Object.assign({}, state, newDataObj);
};

export function BwCoveragesReduer(state: ValidValuesResponse = defaultCoveragesState, action: BwCoveragesActions.Actions) {


    switch (action.type) {
        case BwCoveragesActions.CREATE_VALIDVALUESINFO:
            return action.payload;

        case BwCoveragesActions.UPDATE_VALIDVALUESINFO:
            return action.payload;

        case BwCoveragesActions.REMOVE_VALIDVALUESINFO:
            return newState(state, defaultCoveragesState);

        default:
            return state;
}
}
