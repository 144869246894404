/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./system-errors.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ng-bootstrap/ng-bootstrap/alert/alert.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap/alert/alert";
import * as i4 from "@ng-bootstrap/ng-bootstrap/alert/alert-config";
import * as i5 from "@angular/common";
import * as i6 from "./system-errors.component";
import * as i7 from "../../services/messages.service";
var styles_SystemErrorsComponent = [i0.styles];
var RenderType_SystemErrorsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SystemErrorsComponent, data: {} });
export { RenderType_SystemErrorsComponent as RenderType_SystemErrorsComponent };
function View_SystemErrorsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ngb-alert", [["role", "alert"]], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = ((_co.closed = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NgbAlert_0, i2.RenderType_NgbAlert)), i1.ɵdid(1, 49152, null, 0, i3.NgbAlert, [i4.NgbAlertConfig], { type: [0, "type"] }, { close: "close" }), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "i", [["class", "fas fa-exclamation-triangle color-red"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", ""]))], function (_ck, _v) { var currVal_0 = "danger"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 4, 0, currVal_1); }); }
function View_SystemErrorsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SystemErrorsComponent_3)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.index === (_co.systemMessages.length - 1)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_SystemErrorsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SystemErrorsComponent_2)), i1.ɵdid(2, 802816, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.systemMessages; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SystemErrorsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SystemErrorsComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.systemMessages && _co.systemMessages.length) && !_co.closed); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SystemErrorsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-system-errors", [], null, null, null, View_SystemErrorsComponent_0, RenderType_SystemErrorsComponent)), i1.ɵdid(1, 114688, null, 0, i6.SystemErrorsComponent, [i7.MessagesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SystemErrorsComponentNgFactory = i1.ɵccf("app-system-errors", i6.SystemErrorsComponent, View_SystemErrorsComponent_Host_0, {}, {}, []);
export { SystemErrorsComponentNgFactory as SystemErrorsComponentNgFactory };
