import { Policykey } from './policykey';

export class Ccpaymentreq {
    accountHolderFirstName: string;
    accountHolderMiddleInitial: string;
    accountHolderLastName: string;
    address: string;
    city: string;
    postalCode: string;
    homePhoneNumber: string;
    state: string;
    eftEnrollment: boolean;
    referenceCode: string;
    cardNumber: string;
    expirationMonth: string;
    expirationYear: string;
    browserType: string;
    cvv2: string;
    policyKey: Policykey;
    amount: string;
    transactionSource: string;
    transactionSourceSystem: string;
    symbol: string;
    policy: string;
    mod: string;
    mco: string;
    pco: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
