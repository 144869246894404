import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PaymentErrorService } from '../../../services/payment/payment-error.service';
import { NewPaymentResponse, TransactionNotification } from '../../../shared/model/new-payment.model';


const errorKeys :string [] = [
  'xmlPayment.invalidOperation',
  'accountNumber.invalid',
  'pmCreditCardNumber.invalid',
  'xmlPayment.error',
  'pmCreditCardVerification.length',
  'pmExpiryDate.past',
  'pmExpiryDate.invalid',
  'paymentAmount.invalidAmount',
  'paymentDate.afterMaxDate',
  'paymentDate.invalid',
  'paymentDate.past',
  'paymentTypeCode.empty',
  'invalidPaymentType.empty',
  'paymentMethodCategory.empty',
  'paymentAmount.noRecord',
  'pm.unableToDecrypt',
  'xmlPayment.duplicate',
  'xmlPayment.exception',
  'xmlPayment.declined',
  '[pmCreditCardNumber|pmBankAccount].tooManyPerPaymentMethodType',
  '[pmCreditCardNumber|pmBankAccount].tooManyPerPaymentMethodCategory',
  'accountNumber.tooManyPerAccount',
  'accountNumber.tooManyPerChannel',
  'paymentSearchArgumentRequired',
  'accountNumber.tooManyPerChannel',
  'paymentSearchArgumentRequired',
  'paymentTypeCode.invalid',
  'accountNumber.tooManyPerPaymentType',
  'BWServer.Exception',
  'Credit Floor',
'Call Voice Center',
'Account Closed',
'Invalid Credit Card Number',
'Card is expired',
'Do Not Honor',
'Processor Decline',
'Restraint',
'Invalid Transaction Type',
'Lost / Stolen',
'Invalid Institution Code',
'No Answer / Unable to send',
'Over Freq Limit',
'CVV2/CVC2 Failure',
'Invalid CC Number',
'Issuer Unavailable',
'No Account',
'Pickup'
];
const errorUserMessages :string[]  = [
  'Unable to process or store payment method. Please enter another payment method (DC/CC, or CHK/SAV). ',
  'Please enter valid account number. ',
  'Please enter valid credit card number.',
  'Please enter valid payment method (DC/CC or CHK/SAV).',
  'Please enter valid security code (CVV) number. ',
  'Please enter valid EXP date.',
  'Please enter valid EXP date. ',
  'Please enter the numeric amount $xxxx.',
  '',
  'Please enter valid payment date.',
  '',
  'Please select payment method. ',
  'Please select valid payment method.',
  'Please select payment method. ',
  "We're sorry, there is a problem with your payment method. Please review the details entered and try again.",
  "We're sorry, there is a problem with your payment method. Please review the details entered and try again.",
  'A payment from this method has already been made in the amount of $xxxx.',
  'Unable to store payment method. Please review the details entered and try again.',
  'Payment declined. Please enter another payment method (DC/CC, or CHK/SAV).',
  'Unable to process or store payment method. Please enter another payment method (DC/CC, or CHK/SAV). ',
  'Unable to process or store payment method. Please enter another payment method (DC/CC, or CHK/SAV). ',
  'Unable to process or store payment method. Please enter another payment method (DC/CC, or CHK/SAV). ',
  'Unable to process or store payment method. Please enter another payment method (DC/CC, or CHK/SAV). ',
  'Unable to process or store payment method. Please enter another payment method (DC/CC, or CHK/SAV). ',
  'Unable to process or store payment method. Please enter another payment method (DC/CC, or CHK/SAV). ',
  'Unable to process or store payment method. Please enter another payment method (DC/CC, or CHK/SAV). ',
  'Please enter valid payment type (Debit/Credit, or CHK/SAV).',
  'Unable to process or store payment method. Please enter another payment method (DC/CC, or CHK/SAV). ',
  'Something went wrong! Please try after sometime.',
  'Payment not authorized. Please enter another payment method (DC/CC or CHK/SAV).',
'Payment not authorized. Please enter another payment method (DC/CC or CHK/SAV).',
'Payment declined/account closed. Please enter another payment method (DC/CC or CHK/SAV).',
'Please enter valid credit card number.',
'Please enter valid expiration date.',
'Payment declined. Please enter another payment method (DC/CC or CHK/SAV).',
'Payment declined. Please enter another payment method (DC/CC or CHK/SAV).',
"We're sorry, there is a problem with your payment method and you will need to contact your financial institution. Please enter another payment method (DC/CC or CHK/SAV).",
"We're sorry but something went wrong. Please change your payment method and try again.",
'Payment declined lost/stolen. Please enter another payment method (DC/CC or CHK/SAV). ',
"We're sorry but something went wrong. Please change your payment method and try again.",
"We're sorry but something went wrong. Please change your payment method and try again.",
"We're sorry, there is a problem with your payment method you will need to contact your financial institution. Please enter another payment method (DC/CC or CHK/SAV).",
'Please update the security code (CVV).',
'Please enter valid credit card number. ',
"We're sorry but something went wrong. Please change your payment method and try again.",
"We're sorry, there is a problem with your payment method you will need to contact your financial institution. Please enter another payment method (DC/CC or CHK/SAV).",
"We're sorry, there is a problem with your payment method you will need to contact your financial institution. Please enter another payment method (DC/CC or CHK/SAV).",

];
const GENERIC_ERROR_MESSAGE : string ="Something went wrong. Please try after sometime";

@Component({
  selector: 'app-payment-error',
  templateUrl: './payment-error.component.html',
  styleUrls: ['./payment-error.component.scss']
})

export class PaymentErrorComponent implements OnInit {
  
  @Input('transactionNotification') transactionNotification : TransactionNotification = {} as TransactionNotification;
  @Input('amount') amount : string = '';
  @Input('paymentResponse') paymentResponse = {} as NewPaymentResponse;
  

  constructor() {}

  errorCode : string = '';
  finalErrorMessages : string[] = [];
  

  getErrors(errors : string[]):string{
     let data : string = "";
     let i = 0;
     for(i=1;i<errors.length;i++){
       data += errors[i];
     }
     return data;
  }
  ngOnInit() {
    if(this.transactionNotification && this.transactionNotification.remark){
      
      for(let i=0;i<this.transactionNotification.remark.length;i++){
        if(this.transactionNotification.remark[i].messageText.indexOf("ErrorDescription")>-1 || this.transactionNotification.remark[i].messageText.indexOf("BWServer.Exception")>-1){

          let errorMessage:string = this.transactionNotification.remark[i].messageText;
          let wordsInerror : string[] =  errorMessage.split(",");
          let totalWords = wordsInerror.length;
          let errorKey : string = errorMessage.split(",")[0].trim();
          let index:number = errorKeys.indexOf(errorKey);
          if(index<0){
            let finalErrorMessage : string = this.getErrors(wordsInerror);
            this.finalErrorMessages.push(finalErrorMessage);
  
          }else{
  
            let finalErrorMessage : string = errorUserMessages[index];
            if(this.amount) finalErrorMessage = finalErrorMessage.replace('xxxx',this.amount);
            this.finalErrorMessages.push(finalErrorMessage);
          }
        }else{

          this.finalErrorMessages.push(GENERIC_ERROR_MESSAGE);
        }
      }
    }else{
      if(this.paymentResponse.payment && this.paymentResponse.payment.status !== 'ACCEPTED'){

        
        let errorKey  = this.paymentResponse.payment.statusDescription;
        let index:number = errorKeys.indexOf(errorKey);
        if(index<0){
          this.finalErrorMessages.push(errorKey);
        }else{

          let finalErrorMessage : string = errorUserMessages[index];
          if(this.amount) finalErrorMessage = finalErrorMessage.replace('xxxx',this.amount);
          this.finalErrorMessages.push(finalErrorMessage);
        }

      }
    }
  }
}
