import { Tracker } from '../utils/tracker';
var DateValidator = /** @class */ (function () {
    function DateValidator() {
    }
    // DD/MM/YYYY
    DateValidator.ptDate = function (control) {
        // tslint:disable-next-line:max-line-length
        var ptDatePattern = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g;
        var selectedDateFormat = control.value;
        if (control.value instanceof Object) {
            selectedDateFormat = control.value.month + '/' + control.value.day + '/' + control.value.year;
        }
        Tracker.loginfo('DateValidator', 'ptDate', 'DD/MM/YYYY format validator', 'Date Format Entered : ' + selectedDateFormat);
        if (!selectedDateFormat.match(ptDatePattern)) {
            return { 'ptDate': true };
        }
        return null;
    };
    // MM/DD/YYYY
    DateValidator.usDate = function (control) {
        // tslint:disable-next-line:max-line-length
        var usDatePattern = /^02\/(?:[01]\d|2\d)\/(?:19|20)(?:0[048]|[13579][26]|[2468][048])|(?:0[13578]|10|12)\/(?:[0-2]\d|3[01])\/(?:19|20)\d{2}|(?:0[469]|11)\/(?:[0-2]\d|30)\/(?:19|20)\d{2}|02\/(?:[0-1]\d|2[0-8])\/(?:19|20)\d{2}$/g;
        var selectedDateFormat = control.value;
        if (control.value instanceof Object) {
            selectedDateFormat = padNumber(control.value.month) + '/' + padNumber(control.value.day) + '/' + control.value.year;
        }
        if (selectedDateFormat && !selectedDateFormat.match(usDatePattern)) {
            return { 'usDate': true };
        }
        /* if (!control.value.match(usDatePattern)) {
             return { 'usDate': true };
         }*/
        return null;
    };
    // YYYY-MM-DD
    DateValidator.yyyymmddDate = function (control) {
        var yyyymmddDatePattern = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])*$/g;
        if (!control.value.match(yyyymmddDatePattern)) {
            return { 'ymdDate': true };
        }
        return null;
    };
    return DateValidator;
}());
export { DateValidator };
function padNumber(value) {
    if (isNumber(value)) {
        return ("0" + value).slice(-2);
    }
    else {
        return '';
    }
}
function isNumber(value) {
    return !isNaN(toInteger(value));
}
function toInteger(value) {
    return parseInt("" + value, 10);
}
