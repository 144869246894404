import { OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Title, Meta } from '../../../../node_modules/@angular/platform-browser';
import { CustomerReviewsService } from '../../services/customer-reviews/customer-reviews.service';
import { BWErrorhandler } from '../../services/errorhandler';
import { BWError } from '../../shared/model/error.model';
import { MessageConstants } from '../../constants/message.constant';
import { Tracker } from '../../shared/utils/tracker';
import { GoogleAnalyticsService } from '../../shared/services/analytics/google-analytics.service';
var CustomerReviewsComponent = /** @class */ (function () {
    function CustomerReviewsComponent(title, customerReviews, errorHandler, googleAnalytics, meta) {
        var _this = this;
        this.title = title;
        this.customerReviews = customerReviews;
        this.errorHandler = errorHandler;
        this.googleAnalytics = googleAnalytics;
        this.meta = meta;
        this.reviewsList = [];
        this.isLoaded = false;
        this.googleAnalytics.trackEvent('Reviews', 'CustomerReviewsNavigation', 'CustomerReviewsListing');
        this.showQuickQuote = "" + environment.showQuickQuote;
        this.customerReviews.getCustomerReviews().then(function (res) {
            if (res && res['CustomerReviews']) {
                _this.reviewsList = res['CustomerReviews'];
                _this.isLoaded = true;
            }
            else {
                _this.isLoaded = true;
                _this.errorHandler.handleApplicationError(new BWError({
                    'errorcode': '404',
                    'errordesc': MessageConstants.API_ERROR
                }));
            }
        }).catch(function (error) {
            _this.isLoaded = true;
            _this.errorHandler.handleApplicationError(new BWError({
                'errorcode': '404',
                'errordesc': MessageConstants.API_ERROR
            }));
            Tracker.logerror('CustomerReviewsComponent', 'constructor()', 'Fetching the customer reviews', 'Error Fetching the customer reviews', error);
        });
        this.config = {
            itemsPerPage: 10,
            currentPage: 1,
            totalItems: this.reviewsList.length
        };
    }
    CustomerReviewsComponent.prototype.ngOnInit = function () { };
    CustomerReviewsComponent.prototype.pageChanged = function (event) {
        this.config.currentPage = event;
    };
    return CustomerReviewsComponent;
}());
export { CustomerReviewsComponent };
