
    <!--<div *ngIf="loginErrMsg != null" class="alert alert-danger" role="alert">
      {{loginErrMsg }}
    </div> -->
    <div class="container">
    <div class="row d-flex mb-1">
      <div class="col-md-10 col-sm-10">
          <app-app-errors></app-app-errors>
          <div class="d-inline-flex btn-more-focus">
            <span class="look-feel-heading-1">
              Access Your Policy
            </span>
          </div>
          <form [formGroup]="loginForm" id="loginForm">
            <div class="row">
              <div class="col-md-9 col-sm-9">
                <div class="form-group margin-no-bottom">
                  <label for="user_email" class="lbl-heading">Email address <span class="clr-red">&nbsp;*</span></label>
                  <input id="user_email" type="email" placeholder="Enter your email" class="form-control"
                  formControlName="userid" aria-label="Enter your email address">
                  <div class="col-md-9 col-sm-9">
                    <app-field-error-display [displayError]="isFieldValid('userid')"
                      [errorMsg]="getUserIDErrMsg(loginForm.controls['userid'].errors)"></app-field-error-display>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">

            <div class="col-md-9 col-sm-9">
                <div class="form-group margin-no-bottom">
                  <label for="user_pwd" class="lbl-heading">Password <span class="clr-red">&nbsp;*</span></label>
                  <div class="input-group">
                  <input id="user_pwd" [type]="showPassword ? 'text' : 'password'" placeholder="Enter your password" class="form-control"
                    formControlName="password" aria-label="Enter your password">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" (click)="togglePassword()" aria-label="Show password">
                      <span *ngIf="showPassword === true"><i class="far fa-eye-slash"></i></span>
                      <span *ngIf="showPassword === false"><i class="far fa-eye"></i></span>
                    </button>
                  </div>
                </div>
                <div class="col-md-9 col-sm-9">
                <app-field-error-display [displayError]="isFieldValid('password')"
                [errorMsg]="usermessage.REQUIRED_PASSWORD"></app-field-error-display>
              </div>
                </div>
             
            </div>
           
          </div>
          
            <div class="row mt-3">
              <div class="checkbox mt-2 col-md-5 col-sm-4 col-5">
                <label class="label-text">
                  <input type="checkbox" name="rememberMe" class="mr-2" formControlName="rememberMe"/> Remember me</label>
                </div>
                <div class="col-md-4 col-sm-5 text-right col-7">
                <a  tabindex="0" class="label-text" (click)="newUser()">
                New User</a> <label tabindex="0" class="btn p-0" placement="top" container="body"
                ngbTooltip="Please register for access to your policy. By registering you can make a payment,
                    view policy details, report a claim and more.">
                  <img src="assets/img/icon-tooltip.svg" class="icon icon-svg position-static" 
                  alt="Help Icon"/>
                  </label>
                
                </div>
              </div>
            <!-- <div class="text-md-left">
              <a href="javascript:void(0);" (click)="showForgotEmail()">Forgot Email?</a>
              <br/>
              <a routerLink="/home/forgot-password" routerLinkActive="active">Forgot Your Password?</a>
            </div> -->
            <div class="alert alert-privacy-banner row p-0 mb-0" role="alert">
              <div class="col-md-4 col-sm-4 mt-2 col-5">
                <a class="label-text" href="javascript:void(0);" (click)="showForgotEmail()">Forgot Email?</a>
              </div>
              <div class="col-md-5 col-sm-5 mt-2 text-right col-7 d-sm-block d-md-none d-lg-block">
                <a class="a-blue-link ml-2 label-text" href="{{cookieBannerUrl}}" target="_blank"
                title="Personal Information Use">Personal Information Use</a>
              </div>
              <div class="d-none d-sm-none d-md-block d-lg-none mt-2 text-right col-md-7">
                <a class="a-blue-link ml-2 label-text" href="{{cookieBannerUrl}}" target="_blank"
                title="Personal Information Use">Personal Information Use</a>
              </div>
            </div>
            <div class="row p-0 mt-0">
              <div class="col-md-5 col-sm-4 col-6">
                <a class="label-text" routerLink="/home/forgot-password" routerLinkActive="active">Forgot Password?</a>
              </div>
            </div>
            <div class="row mt-3 mb-3">
              <div class="col-md-10 col-sm-10 text-center">
              <button *ngIf="loaded" type="submit" class="btn orange-login-button" (click)="loginUser(loginForm.value)">
              Login</button>
              <button  *ngIf="!loaded" type="submit" class="btn orange-login-button" disabled="true"><i class="fas fa-circle-notch fa-spin">
              </i>&nbsp;Login</button>
              </div>
            </div>
          </form>
      </div>
    </div>
  </div>
  