import { environment } from '../../../environments/environment';
import { Authenticatephres } from '../model/authentication/authenticatephres';
import * as i0 from "@angular/core";
var OktaMapperService = /** @class */ (function () {
    function OktaMapperService() {
    }
    OktaMapperService.prototype.oktaToAuthenticatePhres = function (oktaResponse) {
        if (environment.bwOktaEnabled == true && oktaResponse && oktaResponse !== null) {
            this.phres = new Authenticatephres({
                authenticatePolicyHolderResult: true,
                phUser: ({
                    email: oktaResponse.users.profile.email,
                    userId: oktaResponse.users.profile.email,
                    jwtToken: oktaResponse.okta.sessionToken,
                    pointHookID: oktaResponse.users.profile.policyHookId,
                    okta: oktaResponse,
                })
            });
            return this.phres;
        }
    };
    OktaMapperService.ngInjectableDef = i0.defineInjectable({ factory: function OktaMapperService_Factory() { return new OktaMapperService(); }, token: OktaMapperService, providedIn: "root" });
    return OktaMapperService;
}());
export { OktaMapperService };
