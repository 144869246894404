import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SortByPipe } from '../../shared/pipes/sort-by.pipe';
import { FilterPipe } from '../../shared/pipes/filter.pipe';
import { FiltersecquestionsPipe } from '../../shared/pipes/filtersecquestions.pipe';
import { CenturydatePipe } from '../../shared/pipes/centurydate.pipe';
import { AppErrorsComponent } from '../../shared/components/app-errors/app-errors.component';
import { BwPersonalInfoComponent } from '../../shared/components/bw-personal-info/bw-personal-info.component';
import { SystemErrorsComponent } from '../../shared/components/system-errors/system-errors.component';
import { InputmaskPipe } from '../../shared/pipes/inputmask.pipe';
import { PolicyQuicklinksComponent } from '../../shared/components/policy-quicklinks/policy-quicklinks.component';
import { PolicyAgentComponent } from '../../shared/components/policy-agent/policy-agent.component';
import { FieldErrorDisplayComponent } from '../../shared/components/field-error-display/field-error-display.component';
import { NumbersonlyinputDirective } from '../../shared/directives/numbersonlyinput.directive';
import { MobileNumberInputComponent } from '../../shared/components/mobile-number-input/mobile-number-input.component';
import { CcexpDirective } from '../../shared/directives/ccexp.directive';
import { CcnumDirective } from '../../shared/directives/ccnum.directive';
import { RoutingnumberDirective } from '../../shared/directives/routingnumber.directive';
import { AccountnumberDirective } from '../../shared/directives/accountnumber.directive';
import { TooltipCloseDirective } from '../../shared/directives/tooltip-close.directive';
import { CurrencyDirective } from '../../shared/directives/currency.directive';
import { BlockcutcopypasteDirective } from '../../shared/directives/blockcutcopypaste.directive';
import { RouterModule } from '@angular/router';
import { BwSpinnerComponent } from '../../shared/components/bw-spinner/bw-spinner.component';
import { PolicyNumberInputComponent } from '../../shared/components/policy-number-input/policy-number-input.component';
import { LoginResetPasswordComponent } from '../../shared/components/login-reset-password/login-reset-password.component';
import { LoginComponent } from '../../shared/components/login/login.component';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb/breadcrumb.component';
import { FindAgentComponent } from '../../shared/components/find-agent/find-agent.component';
import { FindAgentBrokerListComponent } from '../../components/find-agent-broker-list/find-agent-broker-list.component';
import { PolicynumberformatDirective } from '../../shared/directives/policynumberformat.directive';
import { InputmaskDirective } from '../../shared/directives/inputmask.directive';
import { MobilenumberinputDirective } from '../../shared/directives/mobilenumberinput.directive';
import { GopaperlessTermsComponent } from '../../components/registration/gopaperless-terms/gopaperless-terms.component';
import { PaymentheaderComponent } from '../../shared/components/paymentheader/paymentheader.component';
import { LoginSecurityQuestionsComponent } from '../../shared/components/login-security-questions/login-security-questions.component';
import { ForgotResetPasswordComponent } from '../../components/forgot-reset-password/forgot-reset-password.component';
import { DirectsalesDirective } from '../../shared/directives/directsales.directive';
import { SafeHtmlPipe } from '../../shared/pipes/safe-html.pipe';
import { ChatComponent } from '../../shared/components/chat/chat.component';
import { PaymentMethodFormComponent } from '../../components/payment/payment-method-form/payment-method-form.component';
import { CreditCardInfoComponent } from '../../components/payment/credit-card-info/credit-card-info.component';
import { MaterialModule } from '../../components/material/material.module';
import { PaymentErrorComponent } from '../../components/payment/payment-error/payment-error.component';
import { ForgotPasswordOktaComponent } from '../../components/forgot-password-okta/forgot-password-okta.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
  ],
  declarations: [
    // Components
    FieldErrorDisplayComponent,
    AppErrorsComponent,
    BwPersonalInfoComponent,
    SystemErrorsComponent,
    PolicyAgentComponent,
    PolicyQuicklinksComponent,
    BwSpinnerComponent,
    PolicyNumberInputComponent,
    LoginResetPasswordComponent,
    LoginComponent,
    MobileNumberInputComponent,
    BreadcrumbComponent,
    FindAgentComponent,
    FindAgentBrokerListComponent,
    LoginSecurityQuestionsComponent,
    ForgotResetPasswordComponent,
    ChatComponent,
    PaymentMethodFormComponent,
    CreditCardInfoComponent,
    PaymentErrorComponent,
    ForgotPasswordOktaComponent,

    // Pipes
    SortByPipe,
    FilterPipe,
    FiltersecquestionsPipe,
    CenturydatePipe,
    InputmaskPipe,

    // Directives
    CcnumDirective,
    CcexpDirective,
    RoutingnumberDirective,
    AccountnumberDirective,
    TooltipCloseDirective,
    CurrencyDirective,
    BlockcutcopypasteDirective,
    NumbersonlyinputDirective,
    PolicynumberformatDirective,
    InputmaskDirective,
    MobilenumberinputDirective,
    GopaperlessTermsComponent,
    PaymentheaderComponent,
    DirectsalesDirective,
    SafeHtmlPipe

  ],
  exports: [
    FieldErrorDisplayComponent,
    AppErrorsComponent,
    BwPersonalInfoComponent,
    SystemErrorsComponent,
    PolicyQuicklinksComponent,
    PolicyAgentComponent,
    BwSpinnerComponent,
    PolicyNumberInputComponent,
    LoginResetPasswordComponent,
    LoginComponent,
    MobileNumberInputComponent,
    BreadcrumbComponent,
    FindAgentComponent,
    FindAgentBrokerListComponent,
    PaymentheaderComponent,
    ChatComponent,
    PaymentMethodFormComponent,
    CreditCardInfoComponent,
    PaymentErrorComponent,

    SortByPipe,
    FilterPipe,
    FiltersecquestionsPipe,
    CenturydatePipe,
    InputmaskPipe,

    CcnumDirective,
    CcexpDirective,
    RoutingnumberDirective,
    AccountnumberDirective,
    TooltipCloseDirective,
    CurrencyDirective,
    BlockcutcopypasteDirective,
    NumbersonlyinputDirective,
    PolicynumberformatDirective,
    InputmaskDirective,
    MobilenumberinputDirective,
    GopaperlessTermsComponent,
    LoginSecurityQuestionsComponent,
    ForgotResetPasswordComponent,
    ForgotPasswordOktaComponent,

    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    DirectsalesDirective
  ]
})
export class SharedModule { }
