import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalConstants } from '../../../constants/global.constant';
import { CoveragesResponse } from '../../../shared/model/api/policyinfo/coveragesres.model';
import { DiscountsInfoRes } from '../../../shared/model/api/policyinfo/discountsinfores.model';
import { DriversInfoRes } from '../../../shared/model/api/policyinfo/driversinfores.model';
import { LeinholderInfoRes } from '../../../shared/model/api/policyinfo/leinholderinfores.model';
import { PNIInfoRes } from '../../../shared/model/api/policyinfo/policyholderinfo.model';
import { VehiclesResponse } from '../../../shared/model/api/policyinfo/vehicledetailsres.model';
import { ViolationInfoRes } from '../../../shared/model/api/policyinfo/violationifores.model';
import { BwappService } from '../../bwapp.service';
import { Requesttocancelchecksres } from '../../../shared/model/api/policyinfo/requesttocancelchecksres.model';
import { Requesttocancelamount } from '../../../shared/model/api/policyinfo/requesttocancelamount.model';

@Injectable({
  providedIn: 'root'
})
export class PolicydetailsrestService {

  private globlalConstants = GlobalConstants;

  constructor(private appService: BwappService) { }


  getViolations(policynumber: string, mco: string): Observable<ViolationInfoRes> {
    // return this.appService.getlocaldata('assets/data/temp.json');

    const params = new HttpParams()
    .set('policyNumber', policynumber)
    .set('mco', mco);
    return this.appService.get(this.globlalConstants.GET_VIOLATIONDETAILS, params);
  }

  getDrivers(policynumber: string, mco: string): Observable<DriversInfoRes> {
    // return this.appService.getlocaldata('assets/data/temp2.json');
    const params = new HttpParams()
    .set('policyNumber', policynumber)
    .set('mco', mco);

    return this.appService.get(this.globlalConstants.GET_DRIVERDETAILS, params);
  }

  getVehicleFullDetails(policynumber: string, mco: string): Observable<VehiclesResponse> {
    const params = new HttpParams()
    .set('policyNumber', policynumber)
    .set('mco', mco);

    return this.appService.get(this.globlalConstants.GET_VEHICLEDETAILS, params);
  }

  getDiscounts(policynumber: string, mco: string): Observable<DiscountsInfoRes> {
    const params = new HttpParams()
    .set('policyNumber', policynumber)
    .set('mco', mco);

    return this.appService.get(this.globlalConstants.GET_DISCOUNTDETAILS, params);
  }

  getPolicyCoverages(policynumber: string, mco: string): Observable<CoveragesResponse> {
    // const coveragesPath = 'assets/data/coverages.json';
    // return this.appService.getlocaldata(coveragesPath);

    const params = new HttpParams()
    .set('policyNumber', policynumber)
    .set('mco', mco);

    return this.appService.get(this.globlalConstants.GET_POLICYCOVERAGES, params);
  }

  getLeinholderInfo(policynumber: string, mco: string): Observable<LeinholderInfoRes> {
    const params = new HttpParams()
    .set('policyNumber', policynumber)
    .set('mco', mco);

    return this.appService.get(this.globlalConstants.GET_LEINHOLDERDETAILS, params);
  }

  getPolicyHolderInfo(policynumber: string, mco: string): Observable<PNIInfoRes> {
    const params = new HttpParams()
    .set('policyNumber', policynumber)
    .set('mco', mco);

    return this.appService.get(this.globlalConstants.GET_POLICYHOLDERINFO, params);
  }

  getRequestToCancelChecks(policynumber: string, mco: string): Observable<Requesttocancelchecksres> {
    const params = new HttpParams()
    .set('policyNumber', policynumber)
    .set('mco', mco);

    return this.appService.get(this.globlalConstants.GET_CANCELPOLICYCHECKS, params);
  }

  getRequestToCancelAmount(policynumber: string, mco: string, canceldate: string): Observable<Requesttocancelamount> {
    const params = new HttpParams()
    .set('policyNumber', policynumber)
    .set('mco', mco)
    .set('cancelDate', canceldate);

    return this.appService.get(this.globlalConstants.GET_CANCELPOLICYAMOUNT, params);
  }

  createDocRequestInEDMR(policynumber: string, mco: string): Observable<any> {
    const params = new HttpParams()
    .set('policyNumber', policynumber)
    .set('mco', mco);

    return this.appService.get(this.globlalConstants.GET_CANCEL_CREATE_EDMR_RECORD, params);
  }

  getVinDupCheckStatus(agentCode: string, vin: string): Observable<VehiclesResponse> {
    const params = new HttpParams()
    .set('agentCode', agentCode)
    .set('vin', vin);

    return this.appService.get(this.globlalConstants.GET_VIN_DUP_CHECK_STATUS, params);
  }
}
