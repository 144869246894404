import { FullPolicyDetails } from '../../shared/model/api/bwibic/fullpolicydetails.model';
import { Action } from '@ngrx/store';


export const CREATE_POLICYINFO = '[POLICYINFO] Add';
export const UPDATE_POLICYINFO = '[POLICYINFO] Update';
export const REMOVE_POLICYINFO = '[POLICYINFO] DELETE';

export class CreatePolicyInfo implements Action {

  readonly type = UPDATE_POLICYINFO;

  constructor(public payload: FullPolicyDetails) {}
}

export class UpdatePolicyInfo implements Action {

  readonly type = CREATE_POLICYINFO;

  constructor(public payload: FullPolicyDetails) {}
}

export class RemovePolicyInfo implements Action {

  readonly type = REMOVE_POLICYINFO;

  constructor(public payload: FullPolicyDetails) {}
}

export type Actions = CreatePolicyInfo | UpdatePolicyInfo | RemovePolicyInfo;
