import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalConstants } from '../../../constants/global.constant';
import { FullPolicyDetails } from '../../../shared/model/api/bwibic/fullpolicydetails.model';
import { BwstoreService } from '../../../shared/services/bwstore.service';
import { BwappService } from '../../bwapp.service';

@Injectable({
  providedIn: 'root'
})
export class BwibicService {

  private globlalConstants = GlobalConstants;

  constructor(private appService: BwappService, private storeService: BwstoreService) { }

  getAllPolicyInfo(strMCO: string, strPolicySym: string, strPolicyNum: string, strPolicyMod: string): Observable<FullPolicyDetails> {

    // Tracker.log('globlalConstants: getAllPolicyInfo() is inkovked for policy:: ', strPolicySym, strPolicyNum, strPolicyMod, strMCO);
    const params = new HttpParams()
    .set('strMCO', strMCO)
    .set('strPolicySym', strPolicySym)
    .set('strPolicyNum', strPolicyNum)
    .set('strPolicyMod', strPolicyMod);

    return this.appService.get(this.globlalConstants.BWIBIC_GETALLPOLICYINFO, params);
  }
}
