import { EndorsementSaveReq } from '../api/endorsement/endorsement-save-req.model';
import { FuturePayment } from '../api/policyeft/eftreturnres.model';
import { Coverages } from '../api/policyinfo/coverages.model';
export class EndorseMetainfo {
    endorseType: string;
    endorseTypeCd: string;
    endorseTypeDesc: string;
    endorseDate: string;
    endorseUser: string;
    endorsePolicyNum: string;
    endorseMco: string;
    endorseRatebook: string;
    endorseState: string;
    endorseBinderNum: string;
    endorseContext: string;
    endorseBound: string;

    // for premium change info
    endorsePremiumChange: number;
    endorsePremium: number;
    futurePayments: FuturePayment[];
    // vehicle liability coverage
    PolicyCoverages: Coverages[];
     // umpdArray
     umpdValue: string;
    // endorsement request
    endorsementReqInfo: EndorsementSaveReq;

}
