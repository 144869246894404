import { ElementRef, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Tracker } from '../../shared/utils/tracker';
var PaymentIframeFrmsComponent = /** @class */ (function () {
    function PaymentIframeFrmsComponent() {
        this.isLoaded = false;
        this.hideReminders = false;
        this.divHeight = null;
    }
    PaymentIframeFrmsComponent.prototype.ngOnInit = function () {
        this.sourceURL = "" + environment.paymentUsIframeURL;
    };
    PaymentIframeFrmsComponent.prototype.onMessage = function (e) {
        if (e && e.data) {
            if (typeof (e.data) !== 'object' && typeof (e.data) !== 'boolean') {
                var message = e.data;
                if (message.includes('height:')) {
                    if (message.split(':').length > 0) {
                        this.divHeight = Number(message.split(':')[1]) + 30;
                        Tracker.loginfo('PaymentIframeFrmsComponent', 'iframe', 'height', this.divHeight.toString());
                        this.mainElnt.nativeElement.style.height = this.divHeight + 'px';
                    }
                }
                var step1 = message.match(/^(?=.*"step")(?=.*"1")/);
                if (step1) {
                    this.isLoaded = true;
                    if (this.quickReminder && this.quickReminder.nativeElement && this.quickReminder.nativeElement) {
                        this.quickReminder.nativeElement.style.display = 'block';
                        this.iframeDiv.nativeElement.classList.add('col-sm-8');
                        this.iframeDiv.nativeElement.classList.remove('col-sm-12');
                    }
                }
                var step2 = message.match(/^(?=.*"step")(?=.*"2")/);
                if (step2) {
                    this.quickReminder.nativeElement.style.display = 'none';
                    this.iframeDiv.nativeElement.classList.remove('col-sm-8');
                    this.iframeDiv.nativeElement.classList.add('col-sm-12');
                }
            }
        }
    };
    return PaymentIframeFrmsComponent;
}());
export { PaymentIframeFrmsComponent };
