import { EventEmitter, OnInit } from '@angular/core';
import { GlobalConstants } from '../../../constants/global.constant';
import { BwstoreService } from '../../../shared/services/bwstore.service';
var PaymentAmountSectionComponent = /** @class */ (function () {
    function PaymentAmountSectionComponent(_storeService) {
        this._storeService = _storeService;
        this.paymentAmountDetails = {};
        this.selectItemEvent = new EventEmitter();
        this.inValidAmount = false;
        this.otherAmount = '0';
        this.count = 0;
        this.otherAmountNumber = 0.00;
        this.selectedType = '';
        this.inputFieldDisabled = true;
        this.selectedAmountOption = {};
        this.message = 'Other amount should be ';
        this.finalMessage = '';
        this.authenticatedPhDetails = this._storeService.getLoginInfoFromStore();
    }
    PaymentAmountSectionComponent.prototype.ngOnInit = function () {
        this.status = GlobalConstants.status;
    };
    PaymentAmountSectionComponent.prototype.validate = function (otherAmountInput) {
        //console.log(otherAmountInput);
        if (otherAmountInput) {
            try {
                var amount = parseFloat(otherAmountInput);
                if (parseFloat(this.paymentAmountDetails.basicPolicyDetails.paymentInfo.minimumPayment) <= amount && amount <= parseFloat(this.paymentAmountDetails.basicPolicyDetails.paymentInfo.maximumPayment)) {
                    this.inValidAmount = false;
                    this.otherAmount = '' + amount;
                    this.selectedAmountOption.amount = this.otherAmount;
                    this.selectedAmountOption.validated = true;
                    this.selectItem();
                }
                else {
                    //console.log("less greater");
                    this.inValidAmount = true;
                    this.finalMessage = this.message + 'between ' + this.isReinstatementAvailable() ? ('$' + this.getReinstatementAmount() + ' to $' + this.paymentAmountDetails.policyInfo.Balance) : ('$' + this.paymentAmountDetails.basicPolicyDetails.paymentInfo.minimumPayment + ' to $' + this.paymentAmountDetails.basicPolicyDetails.paymentInfo.maximumPayment);
                    this.selectedAmountOption.amount = '';
                    this.selectedAmountOption.validated = false;
                    this.selectItem();
                }
            }
            catch (err) {
                //console.log("err");
                this.inValidAmount = true;
                this.finalMessage = this.message + ' numbers only';
                this.selectedAmountOption.amount = '0.00';
                this.selectedAmountOption.validated = false;
                this.selectItem();
            }
        }
        else {
            console.log("invalid");
            this.inValidAmount = true;
            this.selectedAmountOption.amount = '';
            this.selectedAmountOption.validated = false;
            this.selectItem();
        }
    };
    PaymentAmountSectionComponent.prototype.validateNew = function (amount) {
        if (amount && !isNaN(parseFloat(amount))) {
            if (this.isReinstatementAvailable()) {
                try {
                    var inputAmount = parseFloat(amount);
                    if (this.withinRangeForRA(inputAmount)) {
                        this.inValidAmount = false;
                        this.otherAmount = '' + amount;
                        this.selectedAmountOption.amount = this.otherAmount;
                        this.selectedAmountOption.validated = true;
                        this.finalMessage = '';
                        this.selectItem();
                    }
                    else {
                        this.inValidAmount = true;
                        this.finalMessage = this.message + 'between ' + ('$' + this.getReinstatementAmount() + ' to $' + this.paymentAmountDetails.policyInfo.Balance);
                        this.selectedAmountOption.amount = '';
                        this.selectedAmountOption.validated = false;
                        this.selectItem();
                    }
                }
                catch (err) {
                    this.inValidAmount = true;
                    this.finalMessage = this.message + ' numbers only';
                    this.selectedAmountOption.amount = '0.00';
                    this.selectedAmountOption.validated = false;
                    this.selectItem();
                }
            }
            else {
                try {
                    var inputAmount = parseFloat(amount);
                    if (this.withinRangeForNonRA(inputAmount)) {
                        this.inValidAmount = false;
                        this.otherAmount = '' + amount;
                        this.selectedAmountOption.amount = this.otherAmount;
                        this.selectedAmountOption.validated = true;
                        this.finalMessage = '';
                        this.selectItem();
                    }
                    else {
                        this.inValidAmount = true;
                        this.finalMessage = this.message + 'between ' + ('$' + this.paymentAmountDetails.basicPolicyDetails.paymentInfo.minimumPayment + ' to $' + this.paymentAmountDetails.basicPolicyDetails.paymentInfo.maximumPayment);
                        this.selectedAmountOption.amount = '';
                        this.selectedAmountOption.validated = false;
                        this.selectItem();
                    }
                }
                catch (err) {
                    this.inValidAmount = true;
                    this.finalMessage = this.message + ' numbers only';
                    this.selectedAmountOption.amount = '0.00';
                    this.selectedAmountOption.validated = false;
                    this.selectItem();
                }
            }
        }
        else {
            this.inValidAmount = true;
            this.finalMessage = this.message + ' numbers only';
            this.selectedAmountOption.amount = '0.00';
            this.selectedAmountOption.validated = false;
            this.selectItem();
        }
    };
    PaymentAmountSectionComponent.prototype.resetData = function () {
        this.otherAmount = '';
        //part of defect DE84945
        this.selectedAmountOption.amount = "";
        this.selectItem();
    };
    PaymentAmountSectionComponent.prototype.withinRangeForRA = function (amount) {
        return amount >= parseFloat(this.getReinstatementAmount()) && amount <= parseFloat(this.paymentAmountDetails.policyInfo.Balance);
    };
    PaymentAmountSectionComponent.prototype.withinRangeForNonRA = function (amount) {
        return parseFloat(this.paymentAmountDetails.basicPolicyDetails.paymentInfo.minimumPayment) <= amount && amount <= parseFloat(this.paymentAmountDetails.basicPolicyDetails.paymentInfo.maximumPayment);
    };
    PaymentAmountSectionComponent.prototype.validateOtherAmount = function (otherAmountInput) {
        //console.log(otherAmountInput);
        if (otherAmountInput) {
            var amount = otherAmountInput;
            if (parseFloat(this.paymentAmountDetails.basicPolicyDetails.paymentInfo.minimumPayment) <= amount && amount <= parseFloat(this.paymentAmountDetails.basicPolicyDetails.paymentInfo.maximumPayment)) {
                this.inValidAmount = false;
                this.otherAmount = '' + amount;
                this.selectedAmountOption.amount = this.otherAmount;
                this.selectItem();
            }
            else {
                //console.log("less greater");
                this.inValidAmount = true;
                this.selectedAmountOption.amount = '';
                this.selectedAmountOption.validated = false;
                this.selectItem();
            }
        }
        else {
            //console.log("invalid number ");
            this.inValidAmount = true;
            this.selectedAmountOption.amount = '';
            this.selectedAmountOption.validated = false;
            this.selectItem();
        }
    };
    PaymentAmountSectionComponent.prototype.onTypeChange = function (type) {
        this.selectedType = type;
        if (type === 'PD') {
            this.selectedAmountOption.amount = this.paymentAmountDetails.basicPolicyDetails.paymentInfo.minimumPayment;
            this.selectedAmountOption.validated = true;
            this.inputFieldDisabled = true;
            this.otherAmount = '0';
        }
        if (type === 'RA') {
            this.selectedAmountOption.amount = this.getReinstatementAmount();
            this.selectedAmountOption.validated = true;
            this.inputFieldDisabled = true;
            this.otherAmount = '0';
        }
        if (type === 'TOB') {
            if (!this.isReinstatementAvailable()) {
                this.selectedAmountOption.amount = this.paymentAmountDetails.basicPolicyDetails.paymentInfo.maximumPayment;
            }
            else {
                this.selectedAmountOption.amount = this.paymentAmountDetails.basicPolicyDetails.policyInfo.Balance;
            }
            this.selectedAmountOption.validated = true;
            this.inputFieldDisabled = true;
            this.otherAmount = '0';
        }
        if (type === 'MB') {
            this.selectedAmountOption.amount = this.paymentAmountDetails.basicPolicyDetails.paymentInfo.minimumPayment;
            this.selectedAmountOption.validated = true;
            this.inputFieldDisabled = true;
            this.otherAmount = '0';
        }
        if (type === 'OA') {
            this.selectedAmountOption.amount = "" + this.otherAmount;
            this.inputFieldDisabled = false;
            this.validateNew(this.otherAmount);
        }
        //console.log(this.selectedAmountOption);
        this.selectItem();
    };
    PaymentAmountSectionComponent.prototype.selectItem = function () {
        this.selectItemEvent.emit(this.selectedAmountOption);
        return;
    };
    // isReinstatementAvailable():boolean{
    //   return this.paymentAmountDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC=== GlobalConstants.status.reinstatementAvailable;
    // }
    PaymentAmountSectionComponent.prototype.isReinstatementAvailable = function () {
        if ((this.paymentAmountDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC === GlobalConstants.status.reinstatementAvailable &&
            this.authenticatedPhDetails.reInstateDueamount && this.authenticatedPhDetails.reInstateDueamount !== '0'
            && this.authenticatedPhDetails.reInstateDueamount !== '0.00') || this.authenticatedPhDetails.isReinstate) {
            //this.paymentAmountDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC = GlobalConstants.status.reinstatementAvailable;
            return this.getStatus();
        }
        else if (this.paymentAmountDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC === GlobalConstants.status.reinstatementAvailable &&
            (!this.authenticatedPhDetails.reInstateDueamount || this.authenticatedPhDetails.reInstateDueamount === '0'
                || this.authenticatedPhDetails.reInstateDueamount === '0.00')) {
            this.paymentAmountDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC = GlobalConstants.status.cancelled;
            return false;
        }
        else {
            return false;
        }
        //return this.policyInfo.PolicyStatusEPC=== GlobalConstants.status.reinstatementAvailable || this.authenticatedPhDetails.isReinstate;
    };
    PaymentAmountSectionComponent.prototype.getStatus = function () {
        if (!(this.authenticatedPhDetails.reInstateDueamount && this.authenticatedPhDetails.reInstateDueamount !== '0' && this.authenticatedPhDetails.reInstateDueamount !== '0.00')) {
            this.paymentAmountDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC = GlobalConstants.status.cancelled;
            return false;
        }
        this.paymentAmountDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC = GlobalConstants.status.reinstatementAvailable;
        return true;
    };
    PaymentAmountSectionComponent.prototype.getDueDate = function () {
        return this.paymentAmountDetails.basicPolicyDetails.paymentInfo.paymentDueDate && (this.paymentAmountDetails.basicPolicyDetails.paymentInfo.paymentDueDate.includes("0001") || this.paymentAmountDetails.basicPolicyDetails.paymentInfo.paymentDueDate.includes("1899")) ? "" : this.paymentAmountDetails.basicPolicyDetails.paymentInfo.paymentDueDate;
    };
    PaymentAmountSectionComponent.prototype.getLastDayToPayDate = function () {
        return this.authenticatedPhDetails.reInstateDueDate && (this.authenticatedPhDetails.reInstateDueDate.includes("0001") || this.authenticatedPhDetails.reInstateDueDate.includes("1899")) ? "" : this.authenticatedPhDetails.reInstateDueDate;
    };
    PaymentAmountSectionComponent.prototype.getCancelledDueDate = function () {
        return this.paymentAmountDetails.basicPolicyDetails.policyInfo.DueDate && (this.paymentAmountDetails.basicPolicyDetails.policyInfo.DueDate.includes("0001") || this.paymentAmountDetails.basicPolicyDetails.policyInfo.DueDate.includes("1899")) ? "" : this.paymentAmountDetails.basicPolicyDetails.policyInfo.DueDate;
    };
    PaymentAmountSectionComponent.prototype.getReinstatementAmount = function () {
        //return this.paymentAmountDetails.policyInfo.D0GQVA === '0.00' || this.paymentAmountDetails.policyInfo.D0GQVA === '0'? this.paymentAmountDetails.policyInfo.D0GSVA : this.paymentAmountDetails.policyInfo.D0GQVA;
        return "" + this.authenticatedPhDetails.reInstateDueamount;
    };
    return PaymentAmountSectionComponent;
}());
export { PaymentAmountSectionComponent };
