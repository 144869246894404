import { GlobalConstants } from '../../constants/global.constant';
import { CreateEPolicyRequest } from '../../shared/model/api/gopaperless/createepolicyreq.model';
import { BWError } from '../../shared/model/error.model';
import { CommonUtil } from '../../shared/utils/commonutil';
import { BwSecurityService } from '../api/bwsecurity/bw-security.service';
import { EmailvalidateService } from '../api/emailvalidate/emailvalidate.service';
import { GoPaperlessService } from '../api/gopaperless/go-paperless.service';
import { PolicymgtService } from '../api/policymgmt/policymgt.service';
import { TextAlertsService } from '../api/text-alerts/text-alerts.service';
import { BWErrorhandler } from '../errorhandler';
import { Tracker } from '../../shared/utils/tracker';
import { Setepolicyflagreq } from '../../shared/model/api/gopaperless/setepolicyflagreq';
import { Policynumber } from '../../shared/model/policynumber';
import { PolicyholderService } from '../api/policyholder/policyholder.service';
import { UpdatePHRequest } from '../../shared/model/api/policyholder/updateph.model';
import { UserInfo } from '../../shared/model/registration/userinfo.model';
import { Authenticatephreq } from '../../shared/model/authentication/authenticatephreq';
import { UsmsecurityService } from '../api/usmsecurity/usmsecurity.service';
import { forkJoin } from 'rxjs';
import { PolicydetailsrestService } from '../api/policy/policydetailsrest.service';
import { MessageConstants } from '../../constants/message.constant';
import { RequesttocancelStoreInfo } from '../../shared/model/requesttocancelinfo.model';
import { RequestToCancelInfo } from '../../shared/model/api/policyinfo/requesttocancelchecksres.model';
import { BwstoreService } from '../../shared/services/bwstore.service';
import { Userpreferencesstatus } from '../../shared/model/userpreferencesstatus.model';
import { PolicyeftService } from '../api/policyeft/policyeft.service';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../api/gopaperless/go-paperless.service";
import * as i2 from "../api/policymgmt/policymgt.service";
import * as i3 from "../api/emailvalidate/emailvalidate.service";
import * as i4 from "../errorhandler";
import * as i5 from "../api/bwsecurity/bw-security.service";
import * as i6 from "../api/text-alerts/text-alerts.service";
import * as i7 from "../api/policyholder/policyholder.service";
import * as i8 from "../api/usmsecurity/usmsecurity.service";
import * as i9 from "../api/policy/policydetailsrest.service";
import * as i10 from "../api/policyeft/policyeft.service";
import * as i11 from "../../shared/services/bwstore.service";
var MyprofileService = /** @class */ (function () {
    function MyprofileService(goPaperlessService, policymgtService, emailValidatorService, errorHandler, bwSecurityService, textAlertsService, policyHolderService, usmSecurityService, policyDetailsService, policyEftService, bwStoreService) {
        this.goPaperlessService = goPaperlessService;
        this.policymgtService = policymgtService;
        this.emailValidatorService = emailValidatorService;
        this.errorHandler = errorHandler;
        this.bwSecurityService = bwSecurityService;
        this.textAlertsService = textAlertsService;
        this.policyHolderService = policyHolderService;
        this.usmSecurityService = usmSecurityService;
        this.policyDetailsService = policyDetailsService;
        this.policyEftService = policyEftService;
        this.bwStoreService = bwStoreService;
        this.globalConstants = GlobalConstants;
        this.usermessage = MessageConstants;
    }
    // TextAlerts Enrollments Status, GoPaperless Enrollment status, Cancel Policy Eligibility, EFT Status
    MyprofileService.prototype.getPreferencesStatus = function (policyDetails, policyHolderInfo) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var mco = policyDetails.policyInfo.MASTER0CO;
            var bwPolNum = policyDetails.policyInfo.PolicyNumber;
            var policyState = policyHolderInfo.riskstate;
            var rateBook = policyDetails.policyInfo.PolicyRateBook;
            var agentInfo = policyDetails.agentInfo;
            _this.policyHolder = policyHolderInfo;
            _this.userpreferencesStatus = new Userpreferencesstatus();
            forkJoin(_this.textAlertsService.checkStateEnabled(policyState, mco, rateBook), _this.goPaperlessService.getAutoAuthValues(new Policynumber(bwPolNum).toString(), mco), _this.policyDetailsService.getRequestToCancelChecks(new Policynumber(bwPolNum).toString(), mco), _this.policyEftService.getEFTStatus(new Policynumber(bwPolNum).toString(), mco))
                .subscribe(function (_a) {
                var checkstateenabledres = _a[0], gopaperlessauthres = _a[1], requesttocancelchecksres = _a[2], eftStatusRes = _a[3];
                console.log('GoPapaperless : ', gopaperlessauthres, '   Request To Cancel : ', requesttocancelchecksres, ' Text Alerts State Enabled : ', checkstateenabledres);
                if (checkstateenabledres.checkStateEnabledResult) {
                    _this.policyHolder.textAlertStateEligible = '' + checkstateenabledres.checkStateEnabledResult;
                    _this.userpreferencesStatus.textAlertStateEligible = '' + checkstateenabledres.checkStateEnabledResult;
                    _this.textAlertsService.
                        GetTextAlertEnrollmentStatus(agentInfo.AgentCode, agentInfo.AgentType, mco, policyState, bwPolNum)
                        .subscribe(function (textAlertRes) {
                        _this.policyHolder.textAlertsEnrollment = textAlertRes.length === 0 ? 'false' : 'true';
                        _this.userpreferencesStatus.textAlertsEnrollment = textAlertRes.length === 0 ? 'false' : 'true';
                        // If there are phone store them in store
                        if (textAlertRes.length > 0) {
                            _this.policyHolder.textAlertEnrolledPhones = textAlertRes;
                        }
                        if (gopaperlessauthres) {
                            // Setting Gopaperless Response
                            _this.policyHolder.gopaperlessEnrollment = gopaperlessauthres.getAutoAuthValuesResult;
                            _this.userpreferencesStatus.gopaperlessEnrollment = gopaperlessauthres.getAutoAuthValuesResult;
                        }
                        if (eftStatusRes && eftStatusRes.eftStatusResult === GlobalConstants.EFT_ENROLLED) {
                            _this.userpreferencesStatus.eftStatus = GlobalConstants.EFT_ENROLLED;
                            _this.policyHolder.eftStatus = GlobalConstants.EFT_ENROLLED;
                        }
                        else {
                            _this.userpreferencesStatus.eftStatus = GlobalConstants.EFT_NOT_ENROLLED;
                            _this.policyHolder.eftStatus = GlobalConstants.EFT_NOT_ENROLLED;
                        }
                        console.log('Request To Cancel Res: : ', requesttocancelchecksres.Result);
                        // Setting Request To Cancel Reponse
                        if (requesttocancelchecksres && requesttocancelchecksres.Result === 'success') {
                            var isEligibleToCancel = requesttocancelchecksres.RequestToCancelInfo.IsCancelEligible
                                && !requesttocancelchecksres.RequestToCancelInfo.HasPendingCancelEndorsement
                                ? true : false;
                            _this.policyHolder.requestToCanceEligible = '' + isEligibleToCancel;
                            _this.userpreferencesStatus.requestToCanceEligible = '' + isEligibleToCancel;
                            _this.requestToCancelPolicyStoreInfo = new RequesttocancelStoreInfo();
                            _this.requesToCancelInfo = new RequestToCancelInfo();
                            _this.requesToCancelInfo = requesttocancelchecksres.RequestToCancelInfo;
                            _this.requestToCancelPolicyStoreInfo.cancelChecks = _this.requesToCancelInfo;
                            _this.bwStoreService.addRequestToCancelPolicyInfo(_this.requestToCancelPolicyStoreInfo);
                            _this.bwStoreService.updateLoginInfo(policyHolderInfo);
                        }
                        else {
                            return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'RequestToCancel', 'errordesc': requesttocancelchecksres.Message,
                                'source': 'PolicyDetailsService' })));
                        }
                        resolve(_this.userpreferencesStatus);
                    }, function (error) {
                        return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'TextAlertsService',
                            'errordesc': 'Error occured while retrieving Text Alerts Enrollment Status. Please try again',
                            'source': 'TextAlertsService' })));
                    });
                }
                else {
                    // Setting  the Text Alerts State Eligibility
                    _this.policyHolder.textAlertStateEligible = 'false';
                    _this.userpreferencesStatus.textAlertStateEligible = 'false';
                    // Setting the Text Alerts Enrollment Status
                    _this.policyHolder.textAlertsEnrollment = 'false';
                    _this.userpreferencesStatus.textAlertsEnrollment = 'false';
                    // Setting Gopaperless Response
                    if (gopaperlessauthres) {
                        _this.policyHolder.gopaperlessEnrollment = gopaperlessauthres.getAutoAuthValuesResult;
                        _this.userpreferencesStatus.gopaperlessEnrollment = gopaperlessauthres.getAutoAuthValuesResult;
                    }
                    if (eftStatusRes && eftStatusRes.eftStatusResult === GlobalConstants.EFT_ENROLLED) {
                        _this.userpreferencesStatus.eftStatus = eftStatusRes.eftStatusResult;
                        _this.policyHolder.eftStatus = eftStatusRes.eftStatusResult;
                    }
                    else {
                        _this.userpreferencesStatus.eftStatus = GlobalConstants.EFT_NOT_ENROLLED;
                        _this.policyHolder.eftStatus = eftStatusRes.eftStatusResult;
                    }
                    // Setting Request To Cancel Reponse
                    if (requesttocancelchecksres && requesttocancelchecksres.Result === 'success') {
                        _this.policyHolder.requestToCanceEligible = '' + requesttocancelchecksres.RequestToCancelInfo.IsCancelEligible;
                        _this.userpreferencesStatus.requestToCanceEligible = '' +
                            requesttocancelchecksres.RequestToCancelInfo.IsCancelEligible;
                        _this.requestToCancelPolicyStoreInfo = new RequesttocancelStoreInfo();
                        _this.requesToCancelInfo = new RequestToCancelInfo();
                        _this.requesToCancelInfo = requesttocancelchecksres.RequestToCancelInfo;
                        _this.requestToCancelPolicyStoreInfo.cancelChecks = _this.requesToCancelInfo;
                        _this.bwStoreService.addRequestToCancelPolicyInfo(_this.requestToCancelPolicyStoreInfo);
                        _this.bwStoreService.updateLoginInfo(policyHolderInfo);
                    }
                    else {
                        return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'RequestToCancel', 'errordesc': requesttocancelchecksres.Message,
                            'source': 'PolicyDetailsService' })));
                    }
                    resolve(_this.userpreferencesStatus);
                }
            }, function (error) {
                return reject(error);
            });
        });
    };
    MyprofileService.prototype.getAutoAuthValues = function (policynumber, mco) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.goPaperlessService.getAutoAuthValues(policynumber, mco).subscribe(function (gopaperlessauthres) {
                if (gopaperlessauthres.getAutoAuthValuesResult === 'null') {
                    return reject(_this.errorHandler.handleApplicationError(new BWError({
                        errorcode: '100',
                        errordesc: gopaperlessauthres.getAutoAuthValuesResult,
                        source: 'MyprofileService'
                    })));
                }
                else {
                    resolve(gopaperlessauthres);
                }
            });
        });
    };
    MyprofileService.prototype.getStatePaperDocFee = function (state, mco, rateBook, lob) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.policymgtService
                .getStatePaperDocFee(state, mco, rateBook, lob)
                .subscribe(function (data) {
                if (data === 'null') {
                    return reject(_this.errorHandler.handleApplicationError(new BWError({
                        errorcode: '100',
                        errordesc: data,
                        source: 'MyprofileService'
                    })));
                }
                else {
                    resolve(data);
                }
            });
        });
    };
    MyprofileService.prototype.unenrollGP = function (policynumber, mco) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.goPaperlessService.unenrollGP(policynumber, mco).subscribe(function (data) {
                // Tracker.log('UnenrollGP response :: ', data);
                if (data === 'null') {
                    // Tracker.log('The services retured something else....', data);
                    return reject(_this.errorHandler.handleApplicationError(new BWError({
                        errorcode: '100',
                        errordesc: data,
                        source: 'MyprofileService'
                    })));
                }
                else {
                    resolve(data);
                }
            });
        });
    };
    MyprofileService.prototype.enrollGP = function (email, authenticatedPhDetails, policyInfo, agentInfo) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var mco = CommonUtil.getMCO(authenticatedPhDetails);
            var bwPolNum = CommonUtil.getBWPolicyNumber(authenticatedPhDetails);
            var producercode = policyInfo.PolicyAgentCode;
            var state = authenticatedPhDetails.riskstate;
            var ratebook = policyInfo.PolicyRateBook;
            var pco = policyInfo.PolicyCompany;
            var createEPolicyRequest = new CreateEPolicyRequest();
            _this.emailValidatorService.validateEmail(email, bwPolNum, mco, producercode).subscribe(function (validateres) {
                Tracker.loginfo('MyprofileService', 'enrollGP', 'Brite Verify Email Validation service call', 'Response from validateEmail()' + JSON.stringify(validateres));
                if (validateres && (validateres.status === 'VALID' || validateres.status === 'ERROR_SERVICE' ||
                    validateres.status === 'ERROR_VENDOR' || validateres.responseCode === 'E0000007' ||
                    validateres.responseCode === 'E' || validateres.responseCode === 'S')) {
                    _this.goPaperlessService.isGPLRolledOut(state, ratebook, mco, pco).subscribe(function (isGplRolledOutRes) {
                        Tracker.loginfo('MyprofileService', 'enrollGP', 'isGPLRolledOut service call', 'Response from isGPLRolledOut()' + isGplRolledOutRes);
                        if (isGplRolledOutRes) {
                            createEPolicyRequest.szSource = _this.globalConstants.APPID;
                            createEPolicyRequest.szLocation = policyInfo.Location;
                            createEPolicyRequest.szPolicyCo = policyInfo.PolicyCompany;
                            createEPolicyRequest.szPolicyNbr = bwPolNum;
                            createEPolicyRequest.szMCO = mco;
                            createEPolicyRequest.szEmail = email;
                            createEPolicyRequest.szAgency = policyInfo.PolicyAgentCode;
                            createEPolicyRequest.szFirstName = authenticatedPhDetails.firstName;
                            createEPolicyRequest.szLastName = authenticatedPhDetails.lastName;
                            createEPolicyRequest.blnMidTermENR = 'true';
                            createEPolicyRequest.szDiscountFlag = isGplRolledOutRes.strDiscount;
                            createEPolicyRequest.szAgentType = agentInfo.AgentType;
                            _this.goPaperlessService.enrollGP(createEPolicyRequest).subscribe(function (data) {
                                Tracker.loginfo('MyprofileService', 'enrollGP', 'enrollGP service call', 'Response from enrollGP()' +
                                    JSON.stringify(data));
                                if (data.createEpolicyCustomerResult === true) {
                                    Tracker.loginfo('MyprofileService', 'enrollGP', 'enrollGP service call', 'Enrollment Successful' + data.strResponse);
                                    _this.setepolicyflagreq = new Setepolicyflagreq();
                                    var policynum = new Policynumber(policyInfo.PolicyNumber);
                                    _this.setepolicyflagreq.blnMidTermENR = 'false';
                                    _this.setepolicyflagreq.szEmail = email;
                                    _this.setepolicyflagreq.szMCO = mco;
                                    _this.setepolicyflagreq.szPolicyNbr = policynum.toString();
                                    _this.setepolicyflagreq.szSource = GlobalConstants.APPID;
                                    _this.goPaperlessService.setEPolicyFlag(_this.setepolicyflagreq).subscribe(function (setepolres) {
                                        Tracker.loginfo('MyprofileService', 'enrollGP', 'setEPolicyFlag service call', 'setEPolicyFlag service response' + JSON.stringify(setepolres));
                                        if (setepolres) {
                                            _this.goPaperlessService.updateEPolicyEmail(email, bwPolNum, mco)
                                                .subscribe(function (res) {
                                                Tracker.loginfo('MyprofileService', 'enrollGP', 'updateEPolicyEmail service call', 'updateEPolicyEmail service response' + JSON.stringify(res));
                                                if (res.updateEpolicyEmailResult === true) {
                                                    _this.bwSecurityService.getPolicyLocationStatusandMaxMod(bwPolNum).subscribe(function (datap) {
                                                        Tracker.loginfo('MyprofileService', 'enrollGP', 'getPolicyLocationStatusandMaxMod service call', 'getPolicyLocationStatusandMaxMod service response' + JSON.stringify(datap));
                                                        if (datap.getPolicyLocationStatusAndMaxModResult === true && datap.status === 'V') {
                                                            _this.bwSecurityService.changeUserEmail_POINT_GP('N', email, bwPolNum, mco)
                                                                .subscribe(function (datag) {
                                                                Tracker.loginfo('MyprofileService', 'enrollGP', 'changeUserEmail_POINT_GP service call', 'changeUserEmail_POINT_GP service response' + JSON.stringify(datag));
                                                                resolve('You are now enrolled in Go Paperless');
                                                            });
                                                        }
                                                        else if (datap.getPolicyLocationStatusAndMaxModResult === true && datap.status === 'P') {
                                                            _this.bwSecurityService.changeUserEmail_POINT_IN_STAGING('3', email, bwPolNum, mco)
                                                                .subscribe(function (datagp) {
                                                                Tracker.loginfo('MyprofileService', 'enrollGP', 'changeUserEmail_POINT_IN_STAGING service call', 'changeUserEmail_POINT_IN_STAGING service response' + JSON.stringify(datagp));
                                                                resolve('You are now enrolled in Go Paperless');
                                                            });
                                                        }
                                                        else {
                                                            return reject(_this.errorHandler.handleApplicationError(new BWError({
                                                                errorcode: '100',
                                                                errordesc: 'There is issue while retrieving the status of the Policy',
                                                                source: 'bwSecurityService'
                                                            })));
                                                        }
                                                    });
                                                }
                                                else {
                                                    return reject(_this.errorHandler.handleApplicationError(new BWError({
                                                        errorcode: '100',
                                                        errordesc: 'There is technical issue while Updating the Policy',
                                                        source: 'GoPaperlessService'
                                                    })));
                                                }
                                            });
                                        }
                                        else {
                                            return reject(_this.errorHandler.handleApplicationError(new BWError({
                                                errorcode: '100',
                                                errordesc: 'Unable to Update the Go Paperless Policy flag',
                                                source: 'GoPaperlessService'
                                            })));
                                        }
                                    });
                                }
                                else {
                                    return reject(_this.errorHandler.handleApplicationError(new BWError({
                                        errorcode: '100',
                                        errordesc: 'Your request could not be processed, please contact your agent for assistance.',
                                        source: 'MyprofileService'
                                    })));
                                }
                            });
                        }
                        else {
                            return reject(_this.errorHandler.handleApplicationError(new BWError({
                                errorcode: '100',
                                errordesc: 'There is some error while invoking the isGPLRolledOut service',
                                source: 'GoPaperlessService'
                            })));
                        }
                    });
                }
                else {
                    return reject(_this.errorHandler.handleApplicationError(new BWError({
                        errorcode: '100',
                        errordesc: 'Email is Not Valid',
                        source: 'MyprofileService'
                    })));
                }
            }, function (error) {
                return reject(error);
            });
        });
    };
    MyprofileService.prototype.getTextAlertEnrollmentStatus = function (state, mco, rateBook, agentInfo, policyNumber) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            // Tracker.log('Response :: ', data);
            _this.textAlertsService.GetTextAlertEnrollmentStatus(agentInfo.AgentCode, agentInfo.AgentType, mco, state, policyNumber).subscribe(function (textAlertRes) {
                resolve(textAlertRes);
            }, function (error) {
                return reject(error);
            });
        });
    };
    MyprofileService.prototype.manageTextAlertEnrollment = function (state, mco, agentInfo, policyNumber, phoneNumbers) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.textAlertsService.ManageTextAlertEnrollment(agentInfo.AgentCode, agentInfo.AgentType, mco, state, policyNumber, phoneNumbers).subscribe(function (textAlertEnrollmentRes) {
                if (textAlertEnrollmentRes.manageTextAlertEnrollmentResult === true) {
                    _this.textAlertsService.GetTextAlertEnrollmentStatus(agentInfo.AgentCode, agentInfo.AgentType, mco, state, policyNumber).subscribe(function (textAlerStatustRes) {
                        resolve(textAlerStatustRes);
                    });
                }
                else {
                    // Tracker.log('The services retured something else....', data);
                    return reject(_this.errorHandler.handleApplicationError(new BWError({
                        errorcode: '100',
                        errordesc: 'An unexpected error occured.Please try again later!',
                        source: 'MyprofileService'
                    })));
                }
            });
        });
    };
    MyprofileService.prototype.checkStateEnabled = function (state, mco, rateBook) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.textAlertsService
                .checkStateEnabled(state, mco, rateBook)
                .subscribe(function (data) {
                resolve(data.checkStateEnabledResult);
            }, function (error) {
                return reject(error);
            });
        });
    };
    MyprofileService.prototype.updateUserID = function (email, password, authenticatedPhDetails, policyInfo) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var mco = CommonUtil.getMCO(authenticatedPhDetails);
            var bwPolNum = CommonUtil.getBWPolicyNumber(authenticatedPhDetails);
            var updatePHRequest = new UpdatePHRequest();
            var userInfo = new UserInfo();
            userInfo.email = email;
            var producercode = policyInfo.PolicyAgentCode;
            userInfo.pointHookId = authenticatedPhDetails.pointHookID;
            userInfo.question1 = authenticatedPhDetails.question1;
            userInfo.question2 = authenticatedPhDetails.question2;
            userInfo.question3 = authenticatedPhDetails.question3;
            updatePHRequest.currentEmail = authenticatedPhDetails.email;
            updatePHRequest.currentPassword = password;
            updatePHRequest.password = password;
            updatePHRequest.userInfo = userInfo;
            _this.emailValidatorService
                .validateEmail(email, bwPolNum, mco, producercode)
                .subscribe(function (validateres) {
                Tracker.loginfo('MyprofileService', 'updateUserID', 'Brite Verify Email Validation service call', 'Response from validateEmail()' + JSON.stringify(validateres));
                if (validateres &&
                    (validateres.status === 'VALID' ||
                        validateres.status === 'ERROR_SERVICE' ||
                        validateres.status === 'ERROR_VENDOR' ||
                        validateres.responseCode === 'E0000007' ||
                        validateres.responseCode === 'E')) {
                    _this.policyHolderService.updatePH(updatePHRequest)
                        .subscribe(function (updatePHRes) {
                        Tracker.loginfo('MyprofileService', 'updateUserID', 'Update User ID service call', 'Response from updatePH()' + JSON.stringify(updatePHRes));
                        if (updatePHRes && (updatePHRes.responseCode === '-1' || updatePHRes.responseCode === 'S')) {
                            _this.bwSecurityService
                                .changeUserEmail_POINT_GP('N', email, bwPolNum, mco)
                                .subscribe(function (datag) {
                                Tracker.loginfo('MyprofileService', 'updateUserID', 'changeUserEmail_POINT_GP service call', 'changeUserEmail_POINT_GP service response' + JSON.stringify(datag));
                                if (datag.szResponse === '00' || datag.szResponse === '01') {
                                    _this.goPaperlessService
                                        .getAutoAuthValues(bwPolNum, mco)
                                        .subscribe(function (gopaperlessauthres) {
                                        // Tracker.log('Gopaperlessauthres :: ', gopaperlessauthres);
                                        if (gopaperlessauthres.getAutoAuthValuesResult === 'P' ||
                                            gopaperlessauthres.getAutoAuthValuesResult === 'V') {
                                            _this.goPaperlessService
                                                .updateEPolicyEmail(email, bwPolNum, mco)
                                                .subscribe(function (res) {
                                                Tracker.loginfo('MyprofileService', 'updateUserID', 'updateEPolicyEmail service call', 'updateEPolicyEmail service response' + JSON.stringify(res));
                                            });
                                        }
                                    });
                                }
                                else {
                                    return reject(_this.errorHandler.handleApplicationError(new BWError({
                                        errorcode: '100',
                                        errordesc: 'User ID was not updated',
                                        source: 'GoPaperlessService'
                                    })));
                                }
                            });
                            return resolve('User Id was successfully Updated');
                        }
                        else if (updatePHRes && (updatePHRes.responseCode === '21' || updatePHRes.responseCode === 'E')) {
                            return reject(_this.usermessage.EMAIL_REGISTERED);
                        }
                        else {
                            return reject(_this.errorHandler.handleApplicationError(new BWError({
                                errorcode: '100',
                                errordesc: 'No Changes Made',
                                source: 'MyprofileService'
                            })));
                        }
                    });
                }
                else {
                    if (environment.bwOktaEnabled) {
                        var errStatus = validateres.responseText === 'invalid' ? _this.usermessage.INVALID_EMAIL : _this.usermessage.EMAIL_REGISTERED;
                        return reject(errStatus);
                    }
                    else {
                        return reject(_this.usermessage.INVALID_EMAIL);
                    }
                }
            }, function (error) {
                return reject(error);
            });
        });
    };
    // this funtions prepares the post body that is required to update policy holder rest call
    MyprofileService.prototype.modifySecurityQuestions = function (values, authenticatedPhDetails, confirmPassword) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var updatePHRequest = new UpdatePHRequest();
            var userInfo = new UserInfo();
            var answers = { answer1: values.phsecans1, answer2: values.phsecans2, answer3: values.phsecans3 };
            userInfo.email = authenticatedPhDetails.email;
            userInfo.pointHookId = authenticatedPhDetails.pointHookID;
            userInfo.question1 = values.phsecqsn1;
            userInfo.question2 = values.phsecqsn2;
            userInfo.question3 = values.phsecqsn3;
            updatePHRequest.currentEmail = authenticatedPhDetails.email;
            updatePHRequest.currentPassword = confirmPassword;
            updatePHRequest.password = confirmPassword;
            updatePHRequest.securityAnswers = answers;
            updatePHRequest.userInfo = userInfo;
            _this.policyHolderService.updatePH(updatePHRequest).subscribe(function (updateSecurityQuesRes) {
                return resolve(updateSecurityQuesRes);
            }, function (error) {
                return reject(error);
            });
        });
    };
    MyprofileService.prototype.updatePassword = function (newPassword, currPassword, authenticatedPhDetails) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var mco = CommonUtil.getMCO(authenticatedPhDetails);
            var bwPolNum = CommonUtil.getBWPolicyNumber(authenticatedPhDetails);
            var updatePHRequest = new UpdatePHRequest();
            var userInfo = new UserInfo();
            userInfo.email = authenticatedPhDetails.email;
            userInfo.pointHookId = authenticatedPhDetails.pointHookID;
            userInfo.question1 = authenticatedPhDetails.question1;
            userInfo.question2 = authenticatedPhDetails.question2;
            userInfo.question3 = authenticatedPhDetails.question3;
            // Invoke authenticatePH to validate current password
            var authPHRequest = new Authenticatephreq();
            authPHRequest.appID = GlobalConstants.APPID;
            authPHRequest.userName = authenticatedPhDetails.email;
            authPHRequest.password = currPassword;
            _this.usmSecurityService.authenticatePH(authPHRequest).
                subscribe(function (data) {
                Tracker.loginfo('ConfirmPasswordComponent', 'onSubmit', '', 'Response from authenticatePH()' + data.authenticatePolicyHolderResult);
                if (data.authenticatePolicyHolderResult === true) {
                    // After current password successfull validation update the new password.
                    updatePHRequest.currentEmail = authenticatedPhDetails.email;
                    updatePHRequest.currentPassword = currPassword;
                    updatePHRequest.password = newPassword;
                    updatePHRequest.userInfo = userInfo;
                    _this.policyHolderService.updatePH(updatePHRequest)
                        .subscribe(function (updatePHRes) {
                        Tracker.loginfo('MyprofileService', 'updateUserID', 'Update Password service call', 'Response from updatePH()' + JSON.stringify(updatePHRes));
                        if (updatePHRes && (updatePHRes.responseCode === '-1' || updatePHRes.responseCode === 'S')) {
                            return resolve('Password was successfully Updated');
                        }
                        else {
                            return reject(_this.usermessage.USER_CHANGE_GENERIC_ERROR);
                        }
                    }, function (error) {
                        return reject(error);
                    });
                }
                else {
                    return reject(_this.usermessage.INVALID_CURRENT_PASSWORD);
                }
            }, function (error) {
                if (error.error.code === 'E0000004') {
                    return reject(_this.usermessage.INVALID_CURRENT_PASSWORD);
                }
            });
        });
    };
    MyprofileService.ngInjectableDef = i0.defineInjectable({ factory: function MyprofileService_Factory() { return new MyprofileService(i0.inject(i1.GoPaperlessService), i0.inject(i2.PolicymgtService), i0.inject(i3.EmailvalidateService), i0.inject(i4.BWErrorhandler), i0.inject(i5.BwSecurityService), i0.inject(i6.TextAlertsService), i0.inject(i7.PolicyholderService), i0.inject(i8.UsmsecurityService), i0.inject(i9.PolicydetailsrestService), i0.inject(i10.PolicyeftService), i0.inject(i11.BwstoreService)); }, token: MyprofileService, providedIn: "root" });
    return MyprofileService;
}());
export { MyprofileService };
