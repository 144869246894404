
    <div class="modal-body pb-3">
      <div class="text-center"><p>{{ this.idCardMessage }}</p></div>
      <div *ngIf="!this.allowClose" class="app-loading">
        <div class="logoload" style="width:75px;height:75px;"></div>
          <svg class="spinner" style="width:125px;height:125px;" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
          </svg>
        </div>
      </div>
    <div *ngIf="this.allowClose" class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="dismiss()">Close</button>
    </div>
  