import { ElementRef } from '@angular/core';
var MobilenumberinputDirective = /** @class */ (function () {
    function MobilenumberinputDirective(elementRef) {
        this.elementRef = elementRef;
        this.MOBILE_SEPARATOR = '-';
        this.firstNumRegex = '^[0-9]{3}$';
        this.secondNumRegex = '^[0-9]{3}$';
        this.thirdNumRegex = '^[0-9]{4}$';
        this.el = this.elementRef.nativeElement;
    }
    MobilenumberinputDirective.prototype.onKeyUp = function (event) {
        var e = event;
        if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1) {
            return;
        }
        var fvalue = event.target.value;
        // Tracker.log("User Input: ", fvalue, fvalue.length);
        if (fvalue) {
            if (fvalue.length === 3) {
                fvalue += '-';
                this.el.value = fvalue;
            }
            else if (fvalue.length === 7) {
                fvalue += '-';
                this.el.value = fvalue;
            }
            else if (fvalue && fvalue.indexOf(this.MOBILE_SEPARATOR) === -1 && fvalue.length === 10) {
                // Tracker.log("User Input Change : ", value, value.length);
                var fotmattedValue = fvalue.substr(0, 3) + this.MOBILE_SEPARATOR + fvalue.substr(3, 3) +
                    this.MOBILE_SEPARATOR + fvalue.substr(6, 4);
                this.el.value = fotmattedValue;
            }
        }
    };
    MobilenumberinputDirective.prototype.onchange = function (event, e, m) {
        var value = event.target.value;
        // Tracker.log("User Input change : ", value);
        if (value && value.indexOf(this.MOBILE_SEPARATOR) === -1 && value.length === 10) {
            // Tracker.log("User Input Change : ", value, value.length);
            var fotmattedValue = value.substr(0, 3) + this.MOBILE_SEPARATOR + value.substr(3, 3) +
                this.MOBILE_SEPARATOR + value.substr(6, 4);
            event.target.value = fotmattedValue;
        }
    };
    return MobilenumberinputDirective;
}());
export { MobilenumberinputDirective };
