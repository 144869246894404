import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DirectsalesService } from '../../shared/services/directsales.service';
var DirectsaleshomeComponent = /** @class */ (function () {
    function DirectsaleshomeComponent(directSalesService, route) {
        this.directSalesService = directSalesService;
        this.route = route;
        this.sourceURL = this.directSalesService.getSourceURL();
        this.source = this.directSalesService.getSource();
        this.sourceInd = this.directSalesService.getSourceInd();
    }
    DirectsaleshomeComponent.prototype.ngOnInit = function () {
    };
    return DirectsaleshomeComponent;
}());
export { DirectsaleshomeComponent };
