/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./cancel-change-req.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import * as i4 from "../../../services/analytics/google-analytics.service";
import * as i5 from "../../../services/bwstore.service";
import * as i6 from "../../../../services/errorhandler";
import * as i7 from "../../../../services/api/endorsement/endorsement.service";
var styles_CancelChangeReqComponent = [];
var RenderType_CancelChangeReqComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CancelChangeReqComponent, data: {} });
export { RenderType_CancelChangeReqComponent as RenderType_CancelChangeReqComponent };
function View_CancelChangeReqComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmChanges() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Continue"]))], null, null); }
function View_CancelChangeReqComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["disabled", "true"], ["type", "submit"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-circle-notch fa-spin"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0Continue "]))], null, null); }
export function View_CancelChangeReqComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"], ["id", "request-to-cancel-confirmation"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel Change Requested"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["The change being requested will be cancelled and not saved. Do you still want to navigate away from this page?"])), (_l()(), i0.ɵeld(6, 0, null, null, 6, "div", [["class", "modal-footer mt-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "button", [["class", "btn btn-secondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CancelChangeReqComponent_1)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CancelChangeReqComponent_2)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.inProgress; _ck(_v, 10, 0, currVal_0); var currVal_1 = _co.inProgress; _ck(_v, 12, 0, currVal_1); }, null); }
export function View_CancelChangeReqComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-cancel-change-requested", [], null, null, null, View_CancelChangeReqComponent_0, RenderType_CancelChangeReqComponent)), i0.ɵdid(1, 114688, null, 0, i2.CancelChangeReqComponent, [i3.NgbActiveModal, i4.GoogleAnalyticsService, i5.BwstoreService, i6.BWErrorhandler, i7.EndorsementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CancelChangeReqComponentNgFactory = i0.ɵccf("app-cancel-change-requested", i2.CancelChangeReqComponent, View_CancelChangeReqComponent_Host_0, {}, { responseEmit: "responseEmit" }, []);
export { CancelChangeReqComponentNgFactory as CancelChangeReqComponentNgFactory };
