import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-field-error-display',
  template: `
    <div *ngIf="displayError && errorMsg" >
      <!--<span class="far fa-times-circle form-control-feedback fix-error-icon"></span>-->
      <span class="sr-only">(error)</span>

      <div class="error-msg" id="elemID">
      <i class="fas fa-exclamation-triangle"></i> {{ errorMsg }}
      </div>
    </div>
`,
  styles: [`
    .error-msg {
      font-size: 12px;
      color: #a94442;
      font-weight: bold;
    }

    .fix-error-icon {
      top: 27px;
    }
  `]
})
export class FieldErrorDisplayComponent implements OnInit {

  @Input() errorMsg: string;
  @Input() elemID: string;
  @Input() displayError: boolean;

  constructor() { }

  ngOnInit() {
  }

}
