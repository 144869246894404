/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sorry.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./sorry.component";
import * as i3 from "@angular/router";
var styles_SorryComponent = [i0.styles];
var RenderType_SorryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SorryComponent, data: {} });
export { RenderType_SorryComponent as RenderType_SorryComponent };
export function View_SorryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "main", [["class", "main"], ["id", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "text-danger inline-icon padding-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sorry, we cannot currently offer an online quote in your area. "]))], null, null); }
export function View_SorryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sorry", [], null, null, null, View_SorryComponent_0, RenderType_SorryComponent)), i1.ɵdid(1, 114688, null, 0, i2.SorryComponent, [i3.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SorryComponentNgFactory = i1.ɵccf("app-sorry", i2.SorryComponent, View_SorryComponent_Host_0, {}, {}, []);
export { SorryComponentNgFactory as SorryComponentNgFactory };
