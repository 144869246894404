import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { GlobalConstants } from '../../constants/global.constant';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "@angular/router";
var GoogleSEOService = /** @class */ (function () {
    function GoogleSEOService(titleService, meta, router, activatedRoute) {
        this.titleService = titleService;
        this.meta = meta;
        this.router = router;
        this.activatedRoute = activatedRoute;
    }
    GoogleSEOService.prototype.updateMetaInfo = function (description, keywords) {
        this.meta.updateTag({ name: 'description', content: description });
        this.meta.updateTag({ name: 'keywords', content: keywords });
    };
    GoogleSEOService.prototype.updatePageTitle = function (title) {
        this.titleService.setTitle(title);
    };
    GoogleSEOService.prototype.updateSEO = function () {
        var _this = this;
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }), map(function () { return _this.activatedRoute; }), map(function (route) {
            while (route.firstChild) {
                route = route.firstChild;
            }
            return route;
        }), filter(function (route) { return route.outlet === 'primary'; }), mergeMap(function (route) { return route.data; })).subscribe(function (event) {
            if (event['title'] !== undefined) {
                _this.updatePageTitle(event['title']);
            }
            else {
                _this.updatePageTitle(GlobalConstants.ANY_PAGE_TITLE);
            }
            if (event['metaDescription'] !== undefined) {
                _this.updateMetaInfo(event['metaDescription'], event['metaKeywords']);
            }
            else {
                _this.updateMetaInfo(GlobalConstants.ANY_META_DESCRIPTION, GlobalConstants.ANY_META_KEYWORDS);
            }
        });
    };
    GoogleSEOService.ngInjectableDef = i0.defineInjectable({ factory: function GoogleSEOService_Factory() { return new GoogleSEOService(i0.inject(i1.Title), i0.inject(i1.Meta), i0.inject(i2.Router), i0.inject(i2.ActivatedRoute)); }, token: GoogleSEOService, providedIn: "root" });
    return GoogleSEOService;
}());
export { GoogleSEOService };
