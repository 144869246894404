import { MessagesService } from '../services/messages.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/messages.service";
var RouteinterceptGuard = /** @class */ (function () {
    function RouteinterceptGuard(messageService) {
        this.messageService = messageService;
    }
    RouteinterceptGuard.prototype.canActivate = function (next, state) {
        // Placeholder for all future Router Modifications
        // Clear all error messages if any
        this.messageService.clearAll();
        return true;
    };
    RouteinterceptGuard.ngInjectableDef = i0.defineInjectable({ factory: function RouteinterceptGuard_Factory() { return new RouteinterceptGuard(i0.inject(i1.MessagesService)); }, token: RouteinterceptGuard, providedIn: "root" });
    return RouteinterceptGuard;
}());
export { RouteinterceptGuard };
