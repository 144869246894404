import { HelpTextRes } from '../model/api/helptext/helptext.model';

export class HelpTextMap  {
    map: any;

    constructor() {
        if (this.map === undefined || this.map.size === 0) {
            this.map = new Map();
        }
    }

    set(key: any, value: any) {
        this.map.set(key, value);
    }

    get(key: any): HelpTextRes {
        return this.map.get(key);
    }

    clear() {
        this.map = new Map();
  }
}
