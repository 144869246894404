
    <div *ngIf="showBdq" class="container">
      <div class="row d-flex mb-3 mt-2">
        <div class="col-md-12 col-sm-12" id="startquotepnl">
          <!-- <div class="small" *ngIf="this.errorMessage !== ''">{{ this.errorMessage }}</div> -->
            <div class="d-inline-flex btn-more-focus">
              <h1 [ngClass]="fromPage !='autoIns101' ? 'look-feel-heading-1': 'look-feel-heading-3'" class="color-white tab-title mt-3">
                Bristol West works to make Auto Insurance easy
              </h1>
            </div>
            <form [formGroup]="directSalesForm">
              <div class="mb-2 d-none">
                <select class="form-control form-control-sm" formControlName="lob">
                  <option value="APV">Auto</option>
                  <!-- <option value="Home">Home</option> -->
                  <!-- <option value="Fire">Fire</option> -->
                  <!-- <option value="Motorcycle">Motorcycle</option> -->
                </select>
              </div>
              <div class="row mb-2">
                <div class="col-md-12 col-sm-12">
                  <label class="label-white">Get a Car Insurance quote today</label>
                </div>
              </div>
            <div *ngIf="fromPage!='autoIns101'">
              <div class="row word-wrap justify-content-center">
                <div class="col-md-12 col-sm-12">
                    <input style="width:11rem" id="zipCode" [appNumbersonlyinput]="true" type="tel" formControlName="zipcode"
                    class="form-control" maxlength="5" minlength="5"
                    placeholder="Enter your Zip Code" aria-label="Zip Code" />
                </div>
              </div>
              <div class="row d-flex justify-content-center mt-1">
                <div class="col-md-12 col-sm-12"><span>
                  <app-field-error-display [displayError]="isFieldValid('zipcode')"
                  [errorMsg]="getZipCodeErrMsg(directSalesForm.controls['zipcode'].errors)"></app-field-error-display></span>
                </div>
              </div>
              <div class="row mt-4 mb-1">
                <div class="col-md-12 col-sm-12">
                <button class="btn btn-lg orange-startQuote-button" type="submit" (click)="onSubmit(directSalesForm.value, 'startquote')">
                  Start Quote
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="fromPage=='autoIns101'" class="row">
                <div class="col-md-2"></div>
                <div class="col-md-4 aln-right">
                    <input style="width:11rem;margin-bottom: 2px;" id="zipCode" [appNumbersonlyinput]="true" type="tel" formControlName="zipcode"
                    class="form-control zip-code" maxlength="5" minlength="5"
                    placeholder="Enter your Zip Code" aria-label="Zip Code" />
                    <div class="aln-right zip-err">
                      <app-field-error-display [displayError]="isFieldValid('zipcode')"
                      [errorMsg]="getZipCodeErrMsg(directSalesForm.controls['zipcode'].errors)"></app-field-error-display>
                    </div>
                </div>
                <div class="col-md-3">
                    <button class="btn btn-lg orange-startQuote-button" type="submit" (click)="onSubmit(directSalesForm.value, 'startquote')">
                    Start Quote
                    </button>
                </div>
                <div class="col-md-3"></div>
            </div>
            </form>
            <div class="d-inline-flex btn-more-focus">
            <a href="javascript:void(0)"
            (click)="onSubmit(directSalesForm.value, 'retrievequote')" target="_self" class="label-white-medium white-links">
            Retrieve a Saved Quote
            </a>
            </div>
          </div>
        </div>
    </div>
  