import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EsigmessagesService } from '../../services/esigmessages.service';
import { EsigMessages } from '../../model/esignmessages.model';
import { CommonUtil } from '../../utils/commonutil';
import { BwstoreService } from '../../services/bwstore.service';
import { PolicyHolder } from '../../model/authentication/authenticatephres';

@Component({
  selector: 'app-esigmessages',
  templateUrl: './esigmessages.component.html',
  styleUrls: ['./esigmessages.component.scss']
})
export class EsigmessagesComponent implements OnInit {

  message: string;
  buttonText: string;
  continueAction = 'LOGIN';
  docusignUrl: string;
  phUser: PolicyHolder;

  constructor(private route: ActivatedRoute, private router: Router, private esigMessageservice: EsigmessagesService,
      private storeService: BwstoreService) {
        this.phUser = this.storeService.getLoginInfoFromStore();
      }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      // Tracker.log('Params from Route ::', params);
      const action = params.eSigAction;
      if (params) {
        // Tracker.log('Docusign landed me here....', action);
        // this.message = CommonUtil.getEsigMessage(params.eSigAction);

      } else {
        // Tracker.log('Internal application landed me here...');
      }

      if (action && (action === 'Exception' || action === 'IDCheckFailed'
            || action === 'SessionTimedOut' || action === 'TTLExpired' || action === 'ViewingComplete')) {
              this.message = CommonUtil.getEsigMessage(params.eSigAction);
              this.continueAction = 'DOCUSIGN';
              this.docusignUrl = this.phUser.docuSignURL;
        } else {
          // Add the logic to add the processedDocId(envelopId + recipientId) to Session upon on successful return to EsignMessags
          // from the DocuSign and exclude that processDocId while checking for Pending Docs in the below step
          this.phUser.processedDocId = params.p;
          this.storeService.updateLoginInfo(this.phUser);

          this.esigMessageservice.verifyEsigStatus(this.phUser).then((envmessage: EsigMessages) => {
            this.message = 'You are all good to proceed to next level';
            // Tracker.log('Esig Messages prepared ==> ', envmessage);
            this.message = envmessage.envMessage;
            this.docusignUrl = envmessage.ceremonyURL;
            this.continueAction = envmessage.envMessageId;

            this.phUser.esigstatus = envmessage.hasPendingEnvelops;
            this.phUser.docuSignURL = envmessage.ceremonyURL;
            this.storeService.updateLoginInfo(this.phUser);

          });
        }




    });
  }

  continueToMyPolicy() {

  }

  continueToEsig() {

  }

  continueToLogin() {
    return this.router.navigate(['/']);
  }

}
