import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtil } from '../utils/commonutil';

@Pipe({
  name: 'centurydate'
})
export class CenturydatePipe implements PipeTransform {

  transform(convertDate: string): string {
   const date = new Date(convertDate);

    let centuryDate = '1';
    if (date.getFullYear() < 2000) {
      centuryDate = '0';
    }

    const day = CommonUtil.padNumber(date.getDate());
    const month = CommonUtil.padNumber((date.getMonth() + 1));
    const year = date.getFullYear().toString().substring(2);
    return centuryDate.concat(year).concat(month).concat(day);
  }

}
