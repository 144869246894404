import { OnInit, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MyprofileService } from '../../../services/myprofile/myprofile.service';
import { PolicyInfo, FullPolicyDetails } from '../../model/api/bwibic/fullpolicydetails.model';
import { BwstoreService } from '../../services/bwstore.service';
import { Router } from '@angular/router';
import { CommonUtil } from '../../utils/commonutil';
import { BWErrorhandler } from '../../../services/errorhandler';
import { BWError } from '../../model/error.model';
import { Tracker } from '../../utils/tracker';
import { GlobalConstants } from '../../../constants/global.constant';
var UnEnrollGoPaperlessComponent = /** @class */ (function () {
    function UnEnrollGoPaperlessComponent(errorModal, _storeService, myProfileService, errorHandler, router) {
        this.errorModal = errorModal;
        this._storeService = _storeService;
        this.myProfileService = myProfileService;
        this.errorHandler = errorHandler;
        this.router = router;
        this.returnUnenrollMsg = '';
        this.loaded = true;
        this.emitService = new EventEmitter();
    }
    UnEnrollGoPaperlessComponent.prototype.ngOnInit = function () {
        this.basicPolicyDetails = this._storeService.getPolicyInfoFromStore();
        this.policyInfo = this.basicPolicyDetails.policyInfo;
        this.authenticatedPhDetails = this._storeService.getLoginInfoFromStore();
        // this.getStatePaperDocFee();
        this.unenrollMsg = 'You are currently enrolled in Go Paperless,'
            + 'if you wish to discontinue your enrollment and receive policy documents in the mail then select \'Unenroll\'';
    };
    UnEnrollGoPaperlessComponent.prototype.unenrollGP = function () {
        var _this = this;
        var mco = CommonUtil.getMCO(this.authenticatedPhDetails);
        this.returnUnenrollMsg = '';
        var bwPolNum = CommonUtil.getBWPolicyNumber(this.authenticatedPhDetails);
        this.loaded = false;
        this.myProfileService.unenrollGP(bwPolNum, mco).
            then(function (data) {
            Tracker.loginfo('UnEnrollGoPaperlessComponent', 'unenrollGP', '', 'Response from unenrollGP()' + data.unEnrollEpolicyResult);
            _this.loaded = true;
            if (data.unEnrollEpolicyResult === true) {
                _this.emitService.emit({ gpstatus: 'Not Enrolled' });
                _this.returnUnenrollMsg = 'success';
                // Update the GoPaperless Status to store
                _this.authenticatedPhDetails.gopaperlessEnrollment = GlobalConstants.GP_STATUS_R;
                _this._storeService.updateLoginInfo(_this.authenticatedPhDetails);
            }
            else {
                _this.returnUnenrollMsg = 'failure';
                _this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '100', 'errordesc': 'Your request could not be processed,' +
                        +'please contact your agent for assistance.', 'source': 'GoPaperlessService' }));
            }
        }).catch(function (error) {
            Tracker.logerror('UnEnrollGoPaperlessComponent', 'unenrollGP()', '', 'Your request could not be processed, please contact your agent for assistance.', error);
        });
    };
    UnEnrollGoPaperlessComponent.prototype.getStatePaperDocFee = function () {
        var _this = this;
        var mco = CommonUtil.getMCO(this.authenticatedPhDetails);
        var state = this.authenticatedPhDetails.riskstate;
        var lob = this.policyInfo.LINE0BUS;
        var rateBook = this.policyInfo.PolicyRateBook;
        this.myProfileService.getStatePaperDocFee(state, mco, rateBook, lob).
            then(function (data) {
            Tracker.loginfo('UnEnrollGoPaperlessComponent', 'getStatePaperDocFee', '', 'response from getStatePaperDocFee()' + data);
            // this.unenrollMsg = 'Are you sure you want to unenroll in Go Paperless?';
            if (data !== 0) {
                _this.unenrollMsg = 'Do you really want to Unenroll with state document fee?';
            }
            else {
                _this.unenrollMsg = 'Do you really want to Unenroll?';
            }
        })
            .catch(function (error) {
            Tracker.logerror('UnEnrollGoPaperlessComponent', 'getStatePaperDocFee()', '', 'Error getting the paper document fee', error);
        });
    };
    UnEnrollGoPaperlessComponent.prototype.reloadCurrentPage = function () {
        var _this = this;
        this.errorModal.dismiss('Cross clicked');
        // Need to hit another route and then the profile page again to get it to reload
        this.router.navigateByUrl('/policy', { skipLocationChange: true }).then(function () {
            return _this.router.navigate(['/profile']);
        });
    };
    return UnEnrollGoPaperlessComponent;
}());
export { UnEnrollGoPaperlessComponent };
