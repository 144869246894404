import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GooglemapComponent } from '../../shared/components/modals/googlemap/googlemap.component';
var FindAgentBrokerListComponent = /** @class */ (function () {
    function FindAgentBrokerListComponent(modalService) {
        this.modalService = modalService;
        this.loading = false;
    }
    FindAgentBrokerListComponent.prototype.receiveMessage = function ($event) {
        this.agents = $event;
    };
    FindAgentBrokerListComponent.prototype.viewGoogleMap = function () {
        // this.googleAnalytics.trackEvent('IDCard', 'CallingExtream', 'IDCardButtonClick', 200);
        this.modalService.open(GooglemapComponent);
    };
    return FindAgentBrokerListComponent;
}());
export { FindAgentBrokerListComponent };
