/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./sso-login.component";
import * as i3 from "@angular/router";
import * as i4 from "../../services/authentication.service";
import * as i5 from "../../../services/api/policyholder/policyholder.service";
import * as i6 from "../../services/bwstore.service";
import * as i7 from "../../services/analytics/google-analytics.service";
import * as i8 from "../../../services/timeout.service";
import * as i9 from "../../../services/mypolicy/mypolicy.service";
import * as i10 from "../../../services/api/text-alerts/text-alerts.service";
import * as i11 from "../../../services/addressedit/addressedit.service";
var styles_SsoLoginComponent = [];
var RenderType_SsoLoginComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SsoLoginComponent, data: {} });
export { RenderType_SsoLoginComponent as RenderType_SsoLoginComponent };
function View_SsoLoginComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "my-5 text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "app-loading"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "logoload"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, ":svg:svg", [["class", "spinner"], ["viewBox", "25 25 50 50"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, ":svg:circle", [["class", "path"], ["cx", "50"], ["cy", "50"], ["fill", "none"], ["r", "20"], ["stroke-miterlimit", "10"], ["stroke-width", "2"]], null, null, null, null, null))], null, null); }
export function View_SsoLoginComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SsoLoginComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoaded; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SsoLoginComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sso-login", [], null, null, null, View_SsoLoginComponent_0, RenderType_SsoLoginComponent)), i0.ɵdid(1, 114688, null, 0, i2.SsoLoginComponent, [i3.ActivatedRoute, i3.Router, i4.AuthenticationService, i5.PolicyholderService, i6.BwstoreService, i7.GoogleAnalyticsService, i8.TimeoutService, i9.MypolicyService, i10.TextAlertsService, i11.AddresseditService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SsoLoginComponentNgFactory = i0.ɵccf("app-sso-login", i2.SsoLoginComponent, View_SsoLoginComponent_Host_0, {}, {}, []);
export { SsoLoginComponentNgFactory as SsoLoginComponentNgFactory };
