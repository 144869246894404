import { Component, OnInit } from '@angular/core';
import { DirectsalesService } from '../../shared/services/directsales.service';

@Component({
  selector: 'app-directsalescall',
  templateUrl: './directsalescall.component.html',
  styleUrls: ['./directsalescall.component.scss']
})
export class DirectsalescallComponent implements OnInit {

  directSalesPhone: any;

  constructor(private directSalesService: DirectsalesService) {
   this.directSalesService.phoneNumberObject.subscribe(phone => this.directSalesPhone = phone);
  }

  ngOnInit() {
  }

}
