import { AfterViewInit, Directive, OnInit, Renderer, ElementRef, OnDestroy } from '@angular/core';
import {iframeResizer} from 'iframe-resizer';
import * as resizer from 'iframe-resizer';




@Directive({
    selector: '[appIframeResizer]'
})
export class DirectsalesDirective implements AfterViewInit, OnDestroy {
    component: IFrameResizerComponent;

    constructor(public element: ElementRef) {
    }

    ngAfterViewInit() {
        const components = iframeResizer({
            checkOrigin: false,
            heightCalculationMethod: 'documentElementOffset',
            log: false
        }, this.element.nativeElement);

        /* save component reference so we can close it later */
        this.component = components && components.length > 0 ? components[0] : null;
    }

    ngOnDestroy(): void {
        if (this.component && this.component.iFrameResizer) {
            this.component.iFrameResizer.close();
        }
    }
}


