
  <div class="vehicle-select">
    <div class="modal-body pb-3">
      <div *ngIf="(!this.showLoadingMessage)" class="text-center"><p class="font-weight-bold">{{ this.vehicleSelectMessage }}</p></div>
      <div *ngIf="(this.hasError)" class="text-center weight-300"><p>{{ this.errorMsg }}</p></div>
      <div *ngIf="(this.showLoadingMessage)" class="text-center mt-2 mb-3"><p>{{ this.loadingMessage }}</p></div>
      <div *ngIf="(this.showLoadingMessage)" class="app-loading mb-2">
        <div class="logoload" style="width:75px;height:75px;"></div>
        <svg class="spinner" style="width:125px;height:125px;" viewBox="25 25 50 50">
           <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
        </svg>
      </div>

      <form class="my-4" *ngIf="(this.showForm)" [formGroup]="vehicleSelectForm">
        <div class="form-group">
        <label for="amount">Please select a vehicle</label><br/>
        <select class="form-control form-control-sm" formControlName="vehicleId" id="vehicleId">
          <option *ngFor="let keyvalue of this.vehicleOptions" value="{{ keyvalue.value }}">
              {{ keyvalue.key }}
          </option>
        </select>
        <app-field-error-display [displayError]="isFieldValid('vehicleId')"
        [errorMsg]="getErrMessage(vehicleSelectForm.controls['vehicleId'].errors, 'vehicleId')">
        </app-field-error-display>
      </div>
      </form>
      </div>
    <div *ngIf="this.allowClose" class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="dismiss()">Close</button>
      <button *ngIf="(!this.hasError)"
      type="button" class="btn btn-primary" (click)="onSubmit(vehicleSelectForm.value)">Generate Form</button>
    </div>
  </div>
  