import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentAmountDetails, SelectedPayment } from '../../../shared/model/payment-amount-details.model';
import { GlobalConstants } from '../../../constants/global.constant';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { BwstoreService } from '../../../shared/services/bwstore.service';
import { PolicyHolder } from '../../../shared/model/authentication/authenticatephres';
@Component({
  selector: 'app-payment-amount-section',
  templateUrl: './payment-amount-section.component.html',
  styleUrls: ['./payment-amount-section.component.scss']
})
export class PaymentAmountSectionComponent implements OnInit {


  @Input('paymentAmountDetails') paymentAmountDetails = {} as PaymentAmountDetails;
  @Output('data') selectItemEvent = new EventEmitter<SelectedPayment>();
  inValidAmount : boolean = false;
  otherAmount : string = '0';
  count : number = 0;
  otherAmountNumber : number = 0.00;
  selectedType:string='';
  inputFieldDisabled : boolean = true;
  selectedAmountOption = {} as SelectedPayment;
  message:string = 'Other amount should be ';
  finalMessage : string = '';
  status : any;
  authenticatedPhDetails: PolicyHolder;
  constructor(private _storeService: BwstoreService) {
    this.authenticatedPhDetails = this._storeService.getLoginInfoFromStore();
   }

  ngOnInit() {
    this.status = GlobalConstants.status;
  }

  validate(otherAmountInput : string){

    //console.log(otherAmountInput);
    if(otherAmountInput){

      try{

        var amount : number = parseFloat(otherAmountInput);
        if(parseFloat(this.paymentAmountDetails.basicPolicyDetails.paymentInfo.minimumPayment) <= amount && amount<= parseFloat(this.paymentAmountDetails.basicPolicyDetails.paymentInfo.maximumPayment)){
          this.inValidAmount = false;
          this.otherAmount = ''+amount;
          this.selectedAmountOption.amount = this.otherAmount;
          this.selectedAmountOption.validated = true;
          this.selectItem();
          
        }else{
          //console.log("less greater");
          this.inValidAmount = true;
          this.finalMessage = this.message + 'between ' + this.isReinstatementAvailable()?('$'+this.getReinstatementAmount() +' to $'+this.paymentAmountDetails.policyInfo.Balance):('$'+this.paymentAmountDetails.basicPolicyDetails.paymentInfo.minimumPayment+' to $'+this.paymentAmountDetails.basicPolicyDetails.paymentInfo.maximumPayment);
          this.selectedAmountOption.amount = '';
          this.selectedAmountOption.validated = false;
          this.selectItem();
        }
      }catch(err){
        //console.log("err");
        this.inValidAmount = true;
        this.finalMessage =  this.message+ ' numbers only';
        this.selectedAmountOption.amount = '0.00';
        this.selectedAmountOption.validated = false;
        this.selectItem();
      }
    }else{
        console.log("invalid");
        this.inValidAmount = true;
        this.selectedAmountOption.amount = '';
        this.selectedAmountOption.validated = false;
        this.selectItem();
    }
    

  }

  validateNew(amount:string){
    if(amount && !isNaN(parseFloat(amount)) ){
      if(this.isReinstatementAvailable()){
        try{
          let inputAmount = parseFloat(amount);
          if(this.withinRangeForRA(inputAmount)){
            this.inValidAmount = false;
            this.otherAmount = ''+amount;
            this.selectedAmountOption.amount = this.otherAmount;
            this.selectedAmountOption.validated = true;
            this.finalMessage = '';
            this.selectItem();
          }else{
            this.inValidAmount = true;
            this.finalMessage = this.message + 'between ' + ('$'+this.getReinstatementAmount() +' to $'+this.paymentAmountDetails.policyInfo.Balance);
            this.selectedAmountOption.amount = '';
            this.selectedAmountOption.validated = false;
            this.selectItem();
          }
        }catch(err){
          this.inValidAmount = true;
          this.finalMessage =  this.message+ ' numbers only';
          this.selectedAmountOption.amount = '0.00';
          this.selectedAmountOption.validated = false;
          this.selectItem();
        }
      }else{
        try{
          let inputAmount = parseFloat(amount);
          if(this.withinRangeForNonRA(inputAmount)){
            this.inValidAmount = false;
            this.otherAmount = ''+amount;
            this.selectedAmountOption.amount = this.otherAmount;
            this.selectedAmountOption.validated = true;
            this.finalMessage = '';
            this.selectItem();
          }else{  
            this.inValidAmount = true;
            this.finalMessage = this.message + 'between ' + ('$'+this.paymentAmountDetails.basicPolicyDetails.paymentInfo.minimumPayment+' to $'+this.paymentAmountDetails.basicPolicyDetails.paymentInfo.maximumPayment);
            this.selectedAmountOption.amount = '';
            this.selectedAmountOption.validated = false;
            this.selectItem();       
          }
        }catch(err){
          this.inValidAmount = true;
          this.finalMessage =  this.message+ ' numbers only';
          this.selectedAmountOption.amount = '0.00';
          this.selectedAmountOption.validated = false;
          this.selectItem();
        }
      }
    }else{
          this.inValidAmount = true;
          
          this.finalMessage =  this.message+ ' numbers only';

          this.selectedAmountOption.amount = '0.00';
          
          this.selectedAmountOption.validated = false;
          this.selectItem();
    }
  }

  resetData(){
    this.otherAmount = '';
    //part of defect DE84945
    this.selectedAmountOption.amount = ""; 
    this.selectItem();
  }
  
  withinRangeForRA(amount : number):boolean{
    return amount >= parseFloat(this.getReinstatementAmount()) && amount<= parseFloat(this.paymentAmountDetails.policyInfo.Balance);

  }
  withinRangeForNonRA(amount : number):boolean{

    return parseFloat(this.paymentAmountDetails.basicPolicyDetails.paymentInfo.minimumPayment) <= amount && amount<= parseFloat(this.paymentAmountDetails.basicPolicyDetails.paymentInfo.maximumPayment)
  }
  validateOtherAmount(otherAmountInput : number){

    //console.log(otherAmountInput);
    if(otherAmountInput){

      var amount : number = otherAmountInput;
      if(parseFloat(this.paymentAmountDetails.basicPolicyDetails.paymentInfo.minimumPayment) <= amount && amount<= parseFloat(this.paymentAmountDetails.basicPolicyDetails.paymentInfo.maximumPayment)){
        this.inValidAmount = false;
        this.otherAmount = ''+amount;
        this.selectedAmountOption.amount = this.otherAmount;
        this.selectItem();
        
      }else{
        //console.log("less greater");
        this.inValidAmount = true;
        this.selectedAmountOption.amount = '';
        this.selectedAmountOption.validated = false;
        
        this.selectItem();
      }
    }else{
      //console.log("invalid number ");
      this.inValidAmount = true;
      this.selectedAmountOption.amount = '';
        this.selectedAmountOption.validated = false;
      this.selectItem();
    }
    

  }

  onTypeChange(type:string){
    this.selectedType = type;
    if(type === 'PD'){
      this.selectedAmountOption.amount = this.paymentAmountDetails.basicPolicyDetails.paymentInfo.minimumPayment;
      this.selectedAmountOption.validated = true;
      this.inputFieldDisabled = true;
      this.otherAmount = '0';
    }

    if(type === 'RA'){
      this.selectedAmountOption.amount = this.getReinstatementAmount();
      this.selectedAmountOption.validated = true;
      this.inputFieldDisabled = true;
      this.otherAmount = '0';
    }

    if(type === 'TOB'){
      if(!this.isReinstatementAvailable()){

        this.selectedAmountOption.amount = this.paymentAmountDetails.basicPolicyDetails.paymentInfo.maximumPayment;
      }
      else{
        this.selectedAmountOption.amount = this.paymentAmountDetails.basicPolicyDetails.policyInfo.Balance;
      }
      
      this.selectedAmountOption.validated = true;
      this.inputFieldDisabled = true;
      this.otherAmount = '0';
    }

    if(type === 'MB'){
      this.selectedAmountOption.amount = this.paymentAmountDetails.basicPolicyDetails.paymentInfo.minimumPayment;
      this.selectedAmountOption.validated = true;
      this.inputFieldDisabled = true;
      this.otherAmount = '0';
    }

    if(type === 'OA'){
      this.selectedAmountOption.amount = ""+this.otherAmount;
      this.inputFieldDisabled = false;
      this.validateNew(this.otherAmount);
      
    }

    //console.log(this.selectedAmountOption);

    this.selectItem();
  }

  selectItem(){
    this.selectItemEvent.emit(this.selectedAmountOption);
    return;
  }

  // isReinstatementAvailable():boolean{

  //   return this.paymentAmountDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC=== GlobalConstants.status.reinstatementAvailable;
  // }


  isReinstatementAvailable():boolean{
    if( (this.paymentAmountDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC === GlobalConstants.status.reinstatementAvailable &&
      this.authenticatedPhDetails.reInstateDueamount && this.authenticatedPhDetails.reInstateDueamount!=='0' 
   && this.authenticatedPhDetails.reInstateDueamount!=='0.00') || this.authenticatedPhDetails.isReinstate ){
      //this.paymentAmountDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC = GlobalConstants.status.reinstatementAvailable;
      return this.getStatus();
    } else if (this.paymentAmountDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC === GlobalConstants.status.reinstatementAvailable &&
      (!this.authenticatedPhDetails.reInstateDueamount || this.authenticatedPhDetails.reInstateDueamount === '0'
        || this.authenticatedPhDetails.reInstateDueamount === '0.00')) {// if reinstate from backend and amount is zero
          this.paymentAmountDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC = GlobalConstants.status.cancelled;
      return false;
    } else{
      return false;
    }
    //return this.policyInfo.PolicyStatusEPC=== GlobalConstants.status.reinstatementAvailable || this.authenticatedPhDetails.isReinstate;
  }

  getStatus():boolean{
    if(!(this.authenticatedPhDetails.reInstateDueamount && this.authenticatedPhDetails.reInstateDueamount!=='0' && this.authenticatedPhDetails.reInstateDueamount!=='0.00')){
      this.paymentAmountDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC = GlobalConstants.status.cancelled;
      return false;
    }
    this.paymentAmountDetails.basicPolicyDetails.policyInfo.PolicyStatusEPC = GlobalConstants.status.reinstatementAvailable;
    return true;
  }

  getDueDate(){
    return this.paymentAmountDetails.basicPolicyDetails.paymentInfo.paymentDueDate && (this.paymentAmountDetails.basicPolicyDetails.paymentInfo.paymentDueDate.includes("0001") || this.paymentAmountDetails.basicPolicyDetails.paymentInfo.paymentDueDate.includes("1899"))?"":this.paymentAmountDetails.basicPolicyDetails.paymentInfo.paymentDueDate;
  }

  getLastDayToPayDate(){
    return this.authenticatedPhDetails.reInstateDueDate && (this.authenticatedPhDetails.reInstateDueDate.includes("0001") || this.authenticatedPhDetails.reInstateDueDate.includes("1899"))?"":this.authenticatedPhDetails.reInstateDueDate;
  }
  getCancelledDueDate(){
    return this.paymentAmountDetails.basicPolicyDetails.policyInfo.DueDate && (this.paymentAmountDetails.basicPolicyDetails.policyInfo.DueDate.includes("0001") || this.paymentAmountDetails.basicPolicyDetails.policyInfo.DueDate.includes("1899"))?"":this.paymentAmountDetails.basicPolicyDetails.policyInfo.DueDate;
  }


  

  getReinstatementAmount():string{
    //return this.paymentAmountDetails.policyInfo.D0GQVA === '0.00' || this.paymentAmountDetails.policyInfo.D0GQVA === '0'? this.paymentAmountDetails.policyInfo.D0GSVA : this.paymentAmountDetails.policyInfo.D0GQVA;
    return ""+this.authenticatedPhDetails.reInstateDueamount;
  }

}
