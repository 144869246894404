import { OnInit } from '@angular/core';
import { GoogleAnalyticsService } from '../../services/analytics/google-analytics.service';
import { environment } from '../../../../environments/environment';
var LandingTabsComponent = /** @class */ (function () {
    function LandingTabsComponent(googleAnalytics) {
        this.googleAnalytics = googleAnalytics;
    }
    LandingTabsComponent.prototype.ngOnInit = function () {
        this.showQuickQuote = "" + environment.showQuickQuote;
    };
    LandingTabsComponent.prototype.addGATags = function (actionType) {
        if (actionType === 'idCard') {
            this.googleAnalytics.trackEvent(' Unauthenticated View IDCard', 'Home Screen', 'Unauthenticated view ID  Click', 200);
        }
        else if (actionType === 'payment') {
            this.googleAnalytics.trackEvent('Unauthenticated Make a payment', 'Home Screen', 'Unauthenticated Make a Payment Click', 200);
        }
        else if (actionType === 'login') {
            this.googleAnalytics.trackEvent('Login', 'Home Screen', 'Login Tab', 200);
        }
        else if (actionType === 'direct') {
            this.googleAnalytics.trackEvent('Direct', 'Home Screen', 'Direct Tab', 200);
        }
    };
    return LandingTabsComponent;
}());
export { LandingTabsComponent };
