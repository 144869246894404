var Requesttocancelamount = /** @class */ (function () {
    function Requesttocancelamount(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return Requesttocancelamount;
}());
export { Requesttocancelamount };
var CancelAmount = /** @class */ (function () {
    function CancelAmount(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return CancelAmount;
}());
export { CancelAmount };
