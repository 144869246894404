import { GlobalConstants } from '../../../constants/global.constant';
import { SignOn, Validatecardreq } from '../../../shared/model/api/ivrpayment/validatecardreq.model';
import { CommonUtil } from '../../../shared/utils/commonutil';
import { BwappService } from '../../bwapp.service';
import { BWErrorhandler } from '../../errorhandler';
import * as i0 from "@angular/core";
import * as i1 from "../../bwapp.service";
import * as i2 from "../../errorhandler";
var IvrpaymentService = /** @class */ (function () {
    function IvrpaymentService(appService, errorHandler) {
        this.appService = appService;
        this.errorHandler = errorHandler;
        this.globlalConstants = GlobalConstants;
    }
    IvrpaymentService.prototype.validateCardNumber = function (cardnumber) {
        this.validateCardReq = new Validatecardreq();
        this.validateCardReq.paymentGatewayChannel = 'INTERNET_PSS';
        this.validateCardReq.cardNumber = CommonUtil.formatCC(cardnumber);
        var signOn = new SignOn();
        signOn.client = 'MOBILE_APP';
        signOn.requestId = '1233';
        signOn.clientId = 'IVRB';
        this.validateCardReq.signOn = signOn;
        // Tracker.log('Validate Card Request in IvrpaymentService ::: ', this.validateCardReq);
        return this.appService.post(this.globlalConstants.VALIDATE_CARDNUMBER, this.validateCardReq);
    };
    IvrpaymentService.ngInjectableDef = i0.defineInjectable({ factory: function IvrpaymentService_Factory() { return new IvrpaymentService(i0.inject(i1.BwappService), i0.inject(i2.BWErrorhandler)); }, token: IvrpaymentService, providedIn: "root" });
    return IvrpaymentService;
}());
export { IvrpaymentService };
