import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../environments/environment';
var SessionTimeoutComponent = /** @class */ (function () {
    function SessionTimeoutComponent(activeModal) {
        this.activeModal = activeModal;
        this.timeout = 0;
        this.timeout = environment.timeout;
    }
    SessionTimeoutComponent.prototype.ngOnInit = function () {
    };
    SessionTimeoutComponent.prototype.continue = function () {
        this.activeModal.close('continue');
    };
    SessionTimeoutComponent.prototype.logout = function () {
        this.activeModal.close('logout');
    };
    return SessionTimeoutComponent;
}());
export { SessionTimeoutComponent };
