/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./directsalescall.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./directsalescall.component";
import * as i3 from "../../shared/services/directsales.service";
var styles_DirectsalescallComponent = [i0.styles];
var RenderType_DirectsalescallComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DirectsalescallComponent, data: {} });
export { RenderType_DirectsalescallComponent as RenderType_DirectsalescallComponent };
export function View_DirectsalescallComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "main", [["class", "main"], ["id", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "text-danger inline-icon padding-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["Please call ", " and we will be happy to assist you! "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.directSalesPhone; _ck(_v, 7, 0, currVal_0); }); }
export function View_DirectsalescallComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-directsalescall", [], null, null, null, View_DirectsalescallComponent_0, RenderType_DirectsalescallComponent)), i1.ɵdid(1, 114688, null, 0, i2.DirectsalescallComponent, [i3.DirectsalesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DirectsalescallComponentNgFactory = i1.ɵccf("app-directsalescall", i2.DirectsalescallComponent, View_DirectsalescallComponent_Host_0, {}, {}, []);
export { DirectsalescallComponentNgFactory as DirectsalescallComponentNgFactory };
