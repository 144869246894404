
  <div class="modal-header" >
    <h4 class="modal-title" id="request-to-cancel-confirmation">Confirmation</h4>
    <button type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button> -->
  </div>
  <div class="modal-body">
    <p>Confirm these changes</p>
  </div>

<div class="modal-footer mt-1">
<button type="button" class="btn btn-secondary" (click)="confirmChanges('cancel')">Cancel</button>
  <button type="submit" class="btn btn-primary" (click)="confirmChanges('accept')">Accept</button>
  

</div>
  