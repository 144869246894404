import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-session-timeout',
  template: `
      <div id="modelHeader" class="modal-header">
        <h4 id="modalTitle" class="modal-title">Session Timeout warning</h4>
      </div>
      <div id="modalBody" class="modal-body">
        You will be timed out in
          {{(countMinutes !== 0 ? + countMinutes+' Minute'+(countMinutes > 1 ? 's ' : ' ') : '') + countSeconds+' Seconds'}}
        <p>
          <ngb-progressbar type="danger" [value]="countSeconds" [max]="timeout" animate="false" id="timeoutprogress"
              class="progress-striped active"></ngb-progressbar>
        </p>
      </div>
      <div id="modalFooter" class="modal-footer">
        <button type="button" id="ac90819bu1" class="btn btn-primary" (click)="continue()">Continue</button>
        <button type="button" id="ac90819bu2" class="btn btn-primary" (click)="logout()">Logout</button>
      </div>
  `,
  styles: []
})
export class SessionTimeoutComponent implements OnInit {

  @Input() countMinutes: number;
  @Input() countSeconds: number;
  @Input() progressCount: number;

  timeout = 0;

  constructor(public activeModal: NgbActiveModal) {
    this.timeout = environment.timeout;
  }

  ngOnInit() {
  }



  continue() {
    this.activeModal.close('continue');
  }
  logout() {
    this.activeModal.close('logout');
  }

}
