import { HttpParameterCodec, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GlobalConstants } from '../../../constants/global.constant';
import { Authenticatephreq } from '../../../shared/model/authentication/authenticatephreq';
import { Authenticatephres } from '../../../shared/model/authentication/authenticatephres';
import { BwappService } from '../../bwapp.service';
import { OktaService } from '../okta/okta.service';



@Injectable({
  providedIn: 'root'
})
export class UsmsecurityService implements  HttpParameterCodec {

  private globlalConstants = GlobalConstants;

  constructor(private appService: BwappService, private oktaService: OktaService) { }
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }

  authenticatePH(phreq: Authenticatephreq): Observable<Authenticatephres> {
    if (environment.bwOktaEnabled == true) {
      return this.oktaService.oktaLogin(phreq);
    }
    else {
      return this.appService.post(this.globlalConstants.PH_AUTH_URL, phreq);
    }
  }

  // This method get token from USM service required for SSO Request.
  getSecurityToken(paramSessionTicket: string) {
      const params = new HttpParams({encoder: new UsmsecurityService(this.appService, this.oktaService)})
      .set('sessionTicket',  encodeURI(paramSessionTicket));
    return this.appService.get(this.globlalConstants.PH_SECURITY_TOKEN_URL, params);
  }

}
