import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { EndorsementService } from '../api/endorsement/endorsement.service';
import { EndorsementReq } from '../../shared/model/api/endorsement/endorsement-req';
import { EndorsementRes } from '../../shared/model/api/endorsement/endorsement-res';
import { Observable, ReplaySubject } from 'rxjs';
import { GlobalConstants } from '../../constants/global.constant';
import { BwappService } from '../bwapp.service';
import { Dairycommentreq } from '../../shared/model/api/policymgmt/dairycommentreq.model';
import { environment } from '../../../environments/environment';
import { PolicyholderService } from '../api/policyholder/policyholder.service';

@Injectable({
  providedIn: 'root'
})
export class AddresseditService {
  globalConstants = GlobalConstants;
  dairyCommentReq: Dairycommentreq;

  // Behaviour subject for endorsement eligibility
  endorsementEligibilitySubject = new ReplaySubject<boolean>(1);
  endorsementEligibilityObject = this.endorsementEligibilitySubject.asObservable();

  constructor(private endorsementService: EndorsementService, private appService: BwappService ,
    private policyholderService: PolicyholderService) { }

  endosePolicy(endorsementReq: EndorsementReq): Promise<EndorsementRes> {
    return new Promise((resolve, reject) => {
      this.endorsementService.endosePolicy(endorsementReq).subscribe(
        (res) => {
          resolve(res);
        }, (error) => {
          reject(error);
        });
    });
  }


  saveChangesToPolicy(endorsementReq: EndorsementReq): Promise<EndorsementRes> {
    return new Promise((resolve, reject) => {
      this.endorsementService.saveChangesToPolicy(endorsementReq).subscribe(
        (res) => {
          resolve(res);
        }, (error) => {
          reject(error);
        });
    });
  }


  endorseBind(endorsementReq: EndorsementReq): Promise<EndorsementRes> {
    return new Promise((resolve, reject) => {
      this.endorsementService.endorseBind(endorsementReq).subscribe(
        (res) => {
          resolve(res);
        }, (error) => {
          reject(error);
        });
    });
  }

  endorseVoid(endorsementReq: EndorsementReq): Promise<EndorsementRes> {
    return new Promise((resolve, reject) => {
      this.endorsementService.endorseVoid(endorsementReq).subscribe(
        (res) => {
          resolve(res);
        }, (error) => {
          reject(error);
        });
    });
  }

  isEligibleForEndorsement(endorsementReq: EndorsementReq): Promise<EndorsementRes> {
    return new Promise((resolve, reject) => {
      this.endorsementService.isEligibleForEndorsement(endorsementReq).subscribe(
        (res) => {
          // Update the behaviour subject
          if (res.status === '0') {
            console.log('isEligibleForEndorsement Address edit service-');
            this.endorsementEligibilitySubject.next(true);
          } else {
            this.endorsementEligibilitySubject.next(false);
          }
          resolve(res);
        }, (error) => {
          reject(error);
        });
    });
  }

  endorseAsyncCalls(endorsementReq: EndorsementReq): Promise<EndorsementRes> {

    return new Promise((resolve, reject) => {
      this.endorsementService.endorseAsyncCalls(endorsementReq).subscribe(
        (res) => {
          resolve(res);
        }, (error) => {
          reject(error);
        });
    });
  }


  getFuturePayments(symbol: string, num: string, company: string, mco: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.policyholderService.getFuturePayments(symbol, num, company, mco).subscribe(
        (endorseFuturePayments) => {
          resolve(endorseFuturePayments);
        }, (error) => {
          reject(error);
        });
    });
  }
}
