import { Component, OnInit, Input } from '@angular/core';
import { MessageConstants } from '../../../constants/message.constant';

@Component({
  selector: 'app-paymentheader',
  templateUrl: './paymentheader.component.html',
  styleUrls: ['./paymentheader.component.scss']
})
export class PaymentheaderComponent implements OnInit {
//changes
  @Input() premium: string;
  @Input() lastPaymentDate: string;
  @Input() paymentAmount: string;
  @Input() policystatus: string;
  @Input() reInstateDueDate: string;
  @Input() showLastDayToPay: boolean;
  isPrecancel=false;

  constructor() { }

  ngOnInit() {
    if (this.policystatus === MessageConstants.PENDING_CANCEL_STATUS) {
     this.isPrecancel= true;
    }
  }

  getLastDayToPayDate(){
    return this.reInstateDueDate && (this.reInstateDueDate.includes("0001") || this.reInstateDueDate.includes("1899"))?"":this.reInstateDueDate;
  }

  getLastPayDate(){
    return this.lastPaymentDate && (this.lastPaymentDate.includes("0001") || this.lastPaymentDate.includes("1899"))?"":this.lastPaymentDate;
  }

}