import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { GlobalConstants } from '../../constants/global.constant';

@Injectable({
    providedIn: 'root'
})
export class GoogleSEOService {
    constructor(
        private titleService: Title,
        private meta: Meta,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) { }

    updateMetaInfo(description, keywords) {
        this.meta.updateTag({ name: 'description', content: description });
        this.meta.updateTag({ name: 'keywords', content: keywords });
    }

    updatePageTitle(title) {
        this.titleService.setTitle(title);
    }

    updateSEO() {
            this.router.events
                .pipe(
                    filter((event) => event instanceof NavigationEnd),
                    map(() => this.activatedRoute),
                    map((route) => {
                        while (route.firstChild) { route = route.firstChild; }
                        return route;
                    }),
                    filter((route) => route.outlet === 'primary'),
                    mergeMap((route) => route.data)).subscribe((event) => {
                        if (event['title'] !== undefined) {
                            this.updatePageTitle(event['title']);
                        } else {
                            this.updatePageTitle(GlobalConstants.ANY_PAGE_TITLE);
                        }
                        if (event['metaDescription'] !== undefined) {
                            this.updateMetaInfo(event['metaDescription'], event['metaKeywords']);
                        } else {
                            this.updateMetaInfo(GlobalConstants.ANY_META_DESCRIPTION, GlobalConstants.ANY_META_KEYWORDS);
                        }
                    });
    }
}
