export class Idcardreq {
    policyID: string;
    futureTerm: string;
    mco: string;
    deliveryMode: string;
    appName: string;
    isNewCard: boolean;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
