
    <div class="modal-header">
      <h4 class="modal-title" id="request-to-cancel-nogo">Pending Transaction</h4>
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ pendingMessage }}  or fill out the
      <a href="{{ cancelFormLocation }}" target="_blank" (click)="dismiss()">Cancel Request Form</a>
                                  &nbsp;and use Submit Documents to upload for processing.</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="close()">
        Ok
      </button>
    </div>
  