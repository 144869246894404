<main id="main" class="main mt-0 pt-0">
    <div class="container-fluid">

        <div class="row d-only-desktop  px-0">
          
            <img class="col-12 p-0" src="../../../../assets/img/auto-insurance101/auto101_header.jpg">
        </div>
        <div class="row d-only-mobile d-lg-none mt-2 px-0">
          
            <img class="col-12 p-0" src="../../../../assets/img/auto-insurance101/mobile/auto101_header_mobile.jpg">
        </div>
        <div class="row d-only-tablet d-lg-none  px-0">
    
            <img class="col-12 p-0" src="../../../../assets/img/auto-insurance101/tablet/auto101_header.jpg">
        </div>

        <div class="row d-flex  mb-3 neg-mar-100">
            <div class="col-1"></div>
            <div class="col-10 bg-color-white">
                <div class="row d-flex heading-padding">
                <div class="d-only-desktop col-md-2"></div>
                <div class="d-only-tablet col-md-1 p-0"></div>
                    <div class="col-sm-12 col-md-9 col-lg-8 col-xl-8 pr-0">
                        <h1 tabindex="0" class="look-feel-heading-2 md-mt-2 mb-2  md-pb-2">Auto Insurance 101</h1>
                        <p tabindex="0" class="plan-text header-msg-padding">
                            Bristol West® offers quality auto insurance at affordable rates for a wide range of
                            drivers. Whether you have a less-than-perfect driving record, no prior insurance or traffic
                            violations — even a DUI — our coverage options can help you get the coverage you want. We
                            make getting covered as easy as possible.
                        </p>
                        <p class="text-style-1"> You can be confident knowing Bristol West has coverage for you</p>
                    </div>
                </div>

                <!--Laibailty insurance-->
                <div class="row d-flex">
                    <div class="col-2 lib-img pr-0 "><img class="libiality-image" src="../../../../assets/img/auto-insurance101/car_collision.png"></div>
                    <div class="col-10 col-md-8 col-xl-8 col-lg-8 cover-lib-heading">
                        <h3 class="look-feel-heading-3 cov-position">Liability insurance</h3>
                        <p class="plan-text">
                            helps cover the cost of the other driver's property and bodily injury expenses if you're
                            found at fault in an accident.
                        </p>
                    </div>
                </div>

                <!--Comprehensive insurance-->
                <div class="row d-flex ">
                    <div class="col-2 pr-0 lib-img"><img class="comp-image" src="../../../../assets/img/auto-insurance101/car_hail.png"></div>
                    <div class="col-10 col-md-8 col-xl-8 col-lg-8 cover-comp-heading">
                        <h3 class="look-feel-heading-3 comp-postion">Comprehensive insurance</h3>
                        <p class="plan-text">
                            provides coverage for physical damage from events that are out of your control, like theft
                            or vandalism and weather events.
                        </p>
                    </div>
                </div>

                <!--Collision insurance-->
                <div class="row d-flex ">
                    <div class="col-2 pr-0 lib-img "><img class="collision-image" src="../../../../assets/img/auto-insurance101/car_collision2.png"></div>
                    <div class="col-10 col-md-8 col-xl-8 col-lg-8 cover-coll-heading">
                        <h3 class="look-feel-heading-3 coll-position">Collision insurance</h3>
                        <p class="plan-text">
                            helps cover damage when your vehicle collides with another car or object.
                        </p>
                    </div>
                </div>


                <div class="row d-flex justify-content-center">

                    <h3 class="look-feel-heading-3 cov-options-tab mt-4  mb-2">More coverage options:
                    </h3>
                </div>

                <div class="row d-flex justify-content-center">
                    <div class="col-12 d-flex p-0 justify-content-center">
                        <img class="d-only-desktop coverage-image p-2" src="../../../../assets/img/auto-insurance101/auto101_carosel1.png">
                        <img class="d-only-mobile  coverage-image p-2" src="../../../../assets/img/auto-insurance101/mobile/auto101_image_1_mobile.jpg">
                        <img class="d-only-tablet coverage-image p-2" src="../../../../assets/img/auto-insurance101/tablet/auto101_image_1_tablet.jpg">
                    </div>
                </div>

                <!--Carousel heading  only on desktop-->
                <div class="d-only-desktop row d-flex justify-content-center">
                    <!--Carousel  only on desktop-->
                    <div class="col-7 d-flex justify-content-center">
                        <div class="col-1" (click)="previousSlide()">
                            <i class="fa fa-chevron-left arrow-height float-right" aria-hidden="true"></i>
                        </div>
                        <div class=" auto-insurance101-carosel carousel-max-width">
                            <ngb-carousel #carousel>
                                <ng-template ngbSlide>
                                    <div class="carousel-caption text-style-1">
                                        <p class="underinsur">Uninsured/Underinsured Motorists</p>
                                        <p class="additional-features-text">This type of coverage helps protect you if someone else
                                            is
                                            responsible for
                                            an accident you’re in and the other person has no insurance or minimal
                                            insurance.
                                        </p>
                                    </div>
                                </ng-template>
                                <ng-template ngbSlide>

                                    <div class="carousel-caption text-style-1">
                                        <p class="underinsur">Bodily Injury and Property Damage</p>
                                        <p class="additional-features-text">This covers your legal responsibility for an accident
                                            where
                                            someone else is
                                            injured or there is damage to someone else’s property.
                                            The coverage is limited to the amount you select.
                                        </p>
                                    </div>
                                </ng-template>
                                <ng-template ngbSlide>

                                    <div class="carousel-caption text-style-1">
                                        <p class="underinsur">Rental Reimbursement</p>
                                        <p class="additional-features-text">If you rent a car after you have a covered loss, we will
                                            put
                                            up to $50 per
                                            day back in your pocket for up to 30 days.
                                        </p>
                                    </div>
                                </ng-template>
                                <ng-template ngbSlide>

                                    <div class="carousel-caption text-style-1  ">

                                        <p class="underinsur">Medical Payments</p>
                                        <p class="additional-features-text">Hopefully you'll never need it,
                                            but this coverage can pay for your medical care due to a car accident (Up
                                            to $10,000.00).
                                        </p>
                                    </div>
                                </ng-template>


                                <ng-template ngbSlide>

                                    <div class="carousel-caption text-style-1  ">

                                        <p class="underinsur">Towing and Roadside Service</p>
                                        <p class="additional-features-text">If your vehicle needs towing or assistance on the road, we
                                            will take care of
                                            the cost for you. This applies to each disablement on a covered vehicle
                                            subject to a maximum limit per policy.
                                        </p>
                                    </div>
                                </ng-template>
                            </ngb-carousel>
                        </div>

                        <div class="col-1" (click)="nextSlide()">
                            <i class="fa fa-chevron-right arrow-height" aria-hidden="true"></i>
                        </div>
                    </div>

                </div>

                <div class="d-only-desktop row d-flex  justify-content-center">
                    <h3 class="look-feel-heading-3 mb-2">As a Bristol West customer, you’ll enjoy:</h3>
                </div>
                <!-- accordian mobie & tablet view -->
                <div class="row mt-3 justify-content-center d-hide-desktop auto-insurance-accor">
                    <ngb-accordion #acc="ngbAccordion" class="auto-accor">
                        <ngb-panel>
                            <ng-template ngbPanelTitle>
                                <div class=" d-flex justify-content-center">
                                    <div class="pr-2 ">Uninsured/Underinsured Motorists</div>
                                    <div class="down-arrow-div-pos"><i class="fa fa-caret-down down-arrow" aria-hidden="true"></i></div>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p class="plan-text"> This type of coverage helps protect you if someone else is
                                    responsible for
                                    an accident you’re in and the other person has no insurance or minimal
                                    insurance.</p>
                            </ng-template>
                        </ngb-panel>
                        <br>
                        <ngb-panel>
                            <ng-template ngbPanelTitle>
                                <div class=" d-flex justify-content-center">
                                    <div class="pr-2">Bodily Injury and Property Damage</div>
                                    <div class="down-arrow-div-pos"><i class="fa fa-caret-down down-arrow" aria-hidden="true"></i></div>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p class="plan-text">
                                    This covers your legal responsibility for an accident
                                    where
                                    someone else is
                                    injured or there is damage to someone else’s property.
                                    The coverage is limited to the amount you select.</p>
                            </ng-template>
                        </ngb-panel>

                        <ngb-panel>
                            <ng-template ngbPanelTitle>
                                <div class="d-flex justify-content-center">
                                    <div class="">Rental Reimbursement</div>
                                    <div class=" down-arrow-div-pos "><i class="fa fa-caret-down down-arrow"
                                            aria-hidden="true"></i></div>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p class="plan-text">
                                    If you rent a car after you have a covered loss, we will
                                    put
                                    up to $50 per
                                    day back in your pocket for up to 30 days.</p>
                            </ng-template>
                        </ngb-panel>

                        <ngb-panel>

                            <ng-template ngbPanelTitle>
                                <div class="d-flex justify-content-center">
                                    <div>Medical Payments</div>
                                    <div class=" down-arrow-div-pos "><i class="fa fa-caret-down down-arrow"
                                            aria-hidden="true"></i></div>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p class="plan-text">
                                    Hopefully you'll never need it,
                                    but this coverage can pay for your medical care due to a car accident (Up
                                    to $10,000.00).</p>
                            </ng-template>
                        </ngb-panel>

                        <ngb-panel>

                            <ng-template ngbPanelTitle>
                                <div class=" d-flex justify-content-center">
                                    <div>Towing and Roadside Service</div>
                                    <div class="down-arrow-div-pos "><i class="fa fa-caret-down down-arrow" aria-hidden="true"></i></div>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p class="plan-text">
                                    If your vehicle needs towing or assistance on the road, we
                                    will take care of
                                    the cost for you. This applies to each disablement on a covered vehicle
                                    subject to a maximum limit per policy.</p>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
                <br>
            <div class="row  justify-content-center"><h3 class="d-tablet-mobile look-feel-heading-3 cov-options-tab">Additional features*:</h3></div>
                  
  
                <!-- Desktop Benifits-->
                <div class="d-only-desktop row d-flex  justify-content-center pb-2">

                    <div class="pr-md-3 image-bottom-padding">
                        <img class=" tileWidth tile-image-height" src="../../../../assets/img/auto-insurance101/auto101_image_2_desktop.png">
                    </div>
                    <div class="pl-md-3 image-bottom-padding">
                        <img class=" tileWidth tile-image-height" src="../../../../assets/img/auto-insurance101/auto101_image_4_desktop.png">
                    </div>
                </div>
                <!-- Desktop Benifits-->
                <div class="row d-only-desktop d-flex  justify-content-center">
                    <div class=" pb-2 pr-md-3">
                        <div class="pb-2 trailer-physica tileWidth">Permissive user coverage.</div>
                        <div class="plan-text tileWidth">
                            By providing a driver permission to use your vehicle, they are covered
                            under your vehicle’s insurance.
                        </div>
                    </div>
                    <div class=" pb-2 pl-md-3">
                        <div class="pb-2 trailer-physica tileLiabilityWidth">$500 trailer physical damage coverage.
                        </div>
                        <div class="plan-text tileWidth">
                            Liability coverage is provided for a trailer you own that is attached to
                            a covered vehicle.
                        </div>
                    </div>
                </div>

                <!-- Desktop Benifits-->
                <div class="row d-only-desktop d-flex  justify-content-center pb-2">
                    <div class="pr-md-3 image-bottom-padding">
                        <img class="tileWidth tile-image-height" src="../../../../assets/img/auto-insurance101/auto101_image_3_desktop.png">
                    </div>
                    <div class="pl-md-3 image-bottom-padding">
                        <img class="tileWidth  tile-image-height" src="../../../../assets/img/auto-insurance101/auto101_image_5_desktop.png">
                    </div>
                </div>

               <!-- Desktop Benifits-->
                <div class="row d-flex d-only-desktop justify-content-center">

                    <div class="pr-md-3">
                        <div class="pb-2 trailer-physica tileWidth">Rental car coverage.</div>
                        <div class="additional-features-text tileWidth">If you rent a car, you’ll enjoy the same coverage as your
                            personal, Bristol West insured vehicle.

                        </div>
                    </div>

                    <div class="pl-md-3">
                        <div class="pb-2 trailer-physica tileWidth">Rideshare coverage option.</div>
                        <div class="additional-features-text tileWidth">Extends your personal auto policy limits and coverage if you
                            conduct
                            Rideshare activities, including Uber Eats.</div>

                    </div>
                </div>
                <br>
                <div class="pl-1 pr-4 additional-features-text align-center d-only-desktop"><span> <span aria-hidden="true">*</span>Not all policies, discounts, coverages and features are available in all states.</span></div><br>
                <div class="pl-1 pr-4 additional-features-text align-center d-only-desktop"><span> <span aria-hidden="true">*</span>Coverages are not available in all policies. Where available, coverages may be subject to additional terms, conditions, or limitations.</span></div>
            </div>
        </div>
<div class="row d-flex  mb-3 ">
<div class="d-only-tablet col-1"></div>
<div class="d-tablet-mobile col-md-10">
        <!-- first image mobile & tablet-->
        <div class="row d-only-mobile  px-0">
            <img class="w-100" src="../../../../assets/img/auto-insurance101/mobile/auto101_image_2_mobile.jpg">
        </div>
        <div class="row d-only-tablet px-0">
                <img class="w-100" src="../../../../assets/img/auto-insurance101/tablet/auto101_image_2_tablet.jpg">
            </div>
        <br>
        <!-- first image desc mobile & tablet-->

        <div class="row d-tablet-mobile pl-3 pb-2 trailer-physica">Permissive user coverage.</div>
        <div class="row d-tablet-mobile pl-3 pr-3 additional-features-text justify-content-center">
            By providing a driver permission to use your vehicle, they are covered
            under your vehicle’s insurance.
        </div>
        <br>

        <!-- second  image-->
        <div class="row  px-0 d-only-mobile">
            <img class="w-100" src="../../../../assets/img/auto-insurance101/mobile/auto101_image_3_mobile.jpg">
        </div>

        <div class="row  px-0 d-only-tablet">
                <img class="w-100" src="../../../../assets/img/auto-insurance101/tablet/auto101_image_3_tablet.jpg">
            </div>
        <br>
        <!-- image desc-->

        <div class="row d-md-non pb-2 pl-3 d-tablet-mobile trailer-physica tileLiabilityWidth">$500 trailer physical damage
            coverage.
        </div>
        <div class="row pl-3 d-tablet-mobile pr-3 additional-features-text justify-content-center">
            Liability coverage is provided for a trailer you own that is attached to
            a covered vehicle.
        </div>

        <br>


        <!-- third  image-->
        <div class="row d-only-mobile px-0">
            <img class="w-100" src="../../../../assets/img/auto-insurance101/mobile/auto101_image_4_mobile.jpg">
        </div>

        <div class="row d-only-tablet  px-0">
                <img class="w-100" src="../../../../assets/img/auto-insurance101/tablet/auto101_image_4_tablet.jpg">
            </div>
        <br>
        <!-- image desc-->

        <div class="row d-tablet-mobile pb-2 pl-3 trailer-physica">Rental car coverage.</div>
        <div class="row d-tablet-mobile  pl-3 pr-3 additional-features-text">If you rent a car, you’ll enjoy the same coverage as your personal,
            Bristol West insured vehicle.
        </div>

        <br>

        <!-- forth  image-->
        <div class="row d-only-mobile  px-0">
            <img class="w-100" src="../../../../assets/img/auto-insurance101/mobile/auto101_image_5_mobile.jpg">
        </div>

        <div class="row d-only-tablet  px-0">
                <img class="img-ride-share" src="../../../../assets/img/auto-insurance101/tablet/auto101_image_5_tablet.jpg">
            </div>
        <br>
        <!-- image desc-->

        <div class="row d-tablet-mobile pb-2 pl-3 trailer-physica">Rideshare coverage option.</div>
        <div class="row d-tablet-mobile pl-3 pr-3 additional-features-text justify-content-center">Extends your personal auto policy limits and
            coverage if you
            conduct
            Rideshare activities, including Uber Eats.
        </div>

        <br>
        <div class="pl-1 pr-4 additional-features-text align-center d-tablet-mobile"><span> <span aria-hidden="true">*</span>Not all policies, discounts, coverages and features are available in all states.</span></div><br>
        <div class="pl-1 pr-4 additional-features-text align-center d-tablet-mobile"><span> <span aria-hidden="true">*</span>Coverages are not available in all policies. Where available, coverages may be subject to additional terms, conditions, or limitations.</span></div>
    </div>
</div>
    </div>


<div class="container-fluid">
    <div class="row d-only-desktop">
        <div class="col-2"></div>
        <div class="col-8 container-fluid bg-color">
            <div class="container align-center">
                <app-directsales fromPage="autoIns101"></app-directsales>  
            </div>
        </div>
        <div class="col-2"></div>
    </div>
    <div class="row d-tablet-mobile">
        <div class="col-1"></div>
        <div class="col-10 container-fluid bg-color">
            <div class="container align-center">
                <app-directsales fromPage="autoIns101"></app-directsales>  
            </div>
        </div>
        <div class="col-1"></div>
    </div>
</div>

<div class="container-fluid">
<div class="row">
    <div class="col-md-12 col-sm-12 look-feel-heading-3 cov-position align-center">
        <div>Common Car Insurance Needs:</div>
    </div>
    <div class="col-1"></div>
    <div class="col-10 bg-color-white">
        <div class="row d-flex heading-padding">
            <div class="d-only-desktop col-md-2"></div>
            <div class="col-md-11 col-lg-8 col-xl-8 pr-0">
                <div class="row">
                    <div class="acc-nocard-header">
                        <ngb-accordion #acc="ngbAccordion" class="width-full">
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <div class="d-flex plan-text heading-left">
                                        <div tabindex="0" class="pr-2 text-style-1">No Prior Insurance?</div>
                                        <div tabindex="0" class="down-arrow-div-pos text-style-1 arrow-down1"><i class="fa fa-caret-down down-arrow" aria-hidden="true"></i></div>
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <p tabindex="0" class="plan-text"> 
                                        <b>No Prior Insurance? We can help:</b><br/>
                                        If you never had car insurance, or you let your car insurance lapse, Bristol West can help. We accept nearly all customers with, or without, prior car insurance at competitive rates.
                                    <br/>
                                    <br/>
                                    <b>Why choose Bristol West?</b><br/>
                                    Bristol West offers flexible, hassle-free car insurance to fit most budgets. Get an online quote today. We offer competitive rates and payment options, some of which include lower down payments to meet your needs. 
                                    </p>
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                        <hr/>
                        <ngb-accordion #acc="ngbAccordion" class="width-full">
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <div class="d-flex plan-text heading-left">
                                        <div tabindex="0" class="pr-2 text-style-1">Received a DUI / DWI or OWI conviction?</div>
                                        <div tabindex="0" class="down-arrow-div-pos text-style-1 arrow-down2"><i class="fa fa-caret-down down-arrow" aria-hidden="true"></i></div>
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <p tabindex="0" class="plan-text"> 
                                        <b>If you received a DUI / DWI or OWI conviction, we can help:</b><br/>
                                        Bristol West also offers you with reasonably priced coverage if you are convicted of driving under the influence (DUI), driving while intoxicated (DWI), or operating while intoxicated (OWI). We also can provide an SR-22/FR-44 certificate of financial responsibility, if needed. Contact us today by starting a quote below!
                                    </p>
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                        <hr/>
                        <ngb-accordion #acc="ngbAccordion" class="width-full">
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <div class="d-flex plan-text heading-left">
                                        <div tabindex="0" class="pr-2 text-style-1">Need an SR-22 / FR-44?</div>
                                        <div tabindex="0" class="down-arrow-div-pos text-style-1 arrow-down3"><i class="fa fa-caret-down down-arrow" aria-hidden="true"></i></div>
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <p tabindex="0" class="plan-text"> 
                                        <b>If you need an SR-22 / FR-44, we can help:</b><br/>
                                        An SR-22 is a certificate of financial responsibility, required for some drivers by their state or court order. An SR-22 is not a coverage or type of insurance, but a certification form filed with your state. This form serves as proof that your auto insurance policy meets the minimum Liability coverage required by state law. An FR-44 is like an SR-22 form in that it proves that a driver is carrying active Liability insurance that meets or exceeds state-minimum coverage requirements. The FR-44 is used in Florida and Virginia. You can request an SR-22/FR-44 when you buy a policy or have it added to your existing policy. You will need to purchase and maintain Liability insurance coverages to get a filing.
                                    </p>
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                        <hr/>
                        <ngb-accordion #acc="ngbAccordion" class="width-full">
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <div class="d-flex plan-text heading-left">
                                        <div tabindex="0" class="pr-2 text-style-1">Need Rideshare Coverage?</div>
                                        <div tabindex="0" class="down-arrow-div-pos text-style-1 arrow-down4"><i class="fa fa-caret-down down-arrow" aria-hidden="true"></i></div>
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <p tabindex="0" class="plan-text"> 
                                        <b>We provide Rideshare coverage:</b><br/>
                                        Bristol West offers Rideshare coverage as an optional endorsement. Rideshare coverage is a smart choice if you drive under a ridesharing platform like Uber® or Lyft®. Rideshare coverage fills the gaps between your personal insurance and any insurance provided by the ridesharing company, so you’re protected, whether you’re waiting for a ride request or dropping someone off. (Not available in all states.)
                                    </p>
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                        <hr/>
                        <ngb-accordion #acc="ngbAccordion" class="width-full" ng-reflect-active-ids="{'panel-5-header','panel-5'}">
                            <ngb-panel id="panel-5">
                                <ng-template ngbPanelTitle>
                                    <div class="d-flex plan-text heading-left">
                                        <div tabindex="0" class="pr-2 text-style-1">Don't own a car, but need Affordable Car Insurance?</div>
                                        <div tabindex="0" class="down-arrow-div-pos text-style-1 arrow-down5"><i class="fa fa-caret-down down-arrow" aria-hidden="true"></i></div>
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <p tabindex="0" class="plan-text"> 
                                        <b>Need Car Insurance, but don't own a car, we can help:</b><br/>
                                        If you don’t own a car, or don’t have regular access to a vehicle, a Non-Owner (also known as Named Non-owner or Named Operator) Auto insurance policy might be right for you. If you cause an accident while borrowing a vehicle, a Non-Owner policy provides Liability coverage for bodily injury and property damage. It doesn't cover damages to the vehicle or your own injuries after an accident. However, this policy does insure you if you're liable for damages or injuries in an accident. An SR-22/FR-44 certificate of financial responsibility can be added and other Liability coverage may be available. (Not available in all states.)  
                                    </p>
                                </ng-template>
                            </ngb-panel>
                            <br>
                        </ngb-accordion>
                        <hr/>
                    </div>
                </div>

                
            </div>
        </div>
    </div>
</div>

</div>

<div class="container-fluid">
    <div class="row d-flex mb-3">
        <div class="col-1 d-only-desktop"></div>
        <div class="col-10 bg-color-white d-only-desktop">
            <div class="pl-1 pr-4 additional-features-text align-center"><span> <span aria-hidden="true">*</span>Not all policies, discounts, coverages and features are available in all states.</span></div><br>
            <div class="pl-1 pr-4 additional-features-text align-center"><span> <span aria-hidden="true">*</span>Coverages are not available in all policies. Where available, coverages may be subject to additional terms, conditions, or limitations.</span></div>
        </div>
        <div class="d-only-tablet col-1"></div>
        <div class="d-tablet-mobile col-md-10">
            <div class="pl-1 pr-4 additional-features-text align-center"><span> <span aria-hidden="true">*</span>Not all policies, discounts, coverages and features are available in all states.</span></div><br>
            <div class="pl-1 pr-4 additional-features-text align-center"><span> <span aria-hidden="true">*</span>Coverages are not available in all policies. Where available, coverages may be subject to additional terms, conditions, or limitations.</span></div>
</div>
</div>
</div>
</main>
