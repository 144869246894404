import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

// Placeholder for all Global contants


@Injectable()
export class GlobalConstants {

    // APPLICATION
    public static APPID = 'BWC';
    public static APPID_OLD = 'BWM';
    public static TRANSCODE = 'RO';
    public static EDMR_SRC_SYSTEM = 'DAP';
    public static TFC_SRC_SYSTEM = '21CREN';
    public static SERVICE_OPS_NUMBER = '1-888-888-0080';
    public static POLICY_EMAIL_TYPE_REG_GP = 'G';
    public static POLICY_EMAIL_TYPE_REG_EMAIL = 'N';
    public static POLICY_EMAIL_REQ_TYPE = 'E';


    public static LOB_AUTO = 'APV';

    public static PH_VALIDATE_URL = 'validateNewUser';
    public static PH_INSERT_URL = 'api/phinsert';
    public static PH_AUTH_URL = 'authenticatePolicyHolder';
    public static PH_SECURITY_TOKEN_URL = 'getSecurityToken';

    public static PH_FUTURE_PAYMENTS = 'getFuturePayments';

    // Endorsement Constants
    public static EN_REPL_VEH_TYPE = '8';
    public static EN_REPL_VEH_TYPE_CD = 'RPV';
    public static EN_REPL_VEH_TYPE_DESC = 'Replace Vehicle';
    public static EN_ADD_VEH_TYPE = '1';
    public static EN_ADD_VEH_TYPE_CD = 'ADV';
    public static EN_ADD_VEH_TYPE_DESC = 'Add Vehicle';
    public static EN_REM_VEH_TYPE = '7';
    public static EN_REM_VEH_TYPE_CD = 'RMV';
    public static EN_REM_VEH_TYPE_DESC = 'Remove Vehicle';
    public static EN_POLICY_INTEREST_TYPE = '9';
    public static TAB_KEY_CODE = 9;
    public static ENTER_KEY_CODE = 13;

    // GOPAPERLESS API
    public static GP_AUTO_AUTH_URL = 'getAutoAuthValues';
    public static GP_UPDATE_EMAIL_URL = 'updateEpolicyEmail';
    public static GP_UNENROLL_URL = 'unEnrollEpolicy';
    public static IS_GPL_ROLLED_OUT_URL = 'isGPLRolledOut';
    public static GP_ENROLL_URL = 'createEpolicyCustomer';
    public static GP_SET_EPOLICY_FLAG = 'setEPolAuthFlag';

    public static GP_STATUS_V = 'V';
    public static GP_STATUS_V_DESC = 'Enrolled';
    public static GP_STATUS_R = 'R';
    public static GP_STATUS_R_DESC = 'Not Enrolled';

    public static GP_STATUS_P = 'P';
    public static GP_STATUS_P_DESC = 'Pending Enrollment';

    // CHAT API
    public static CHAT_GET_ID = 'getChatId';
    public static CHAT_GENERATE_TOKEN = 'generatechattoken';
    public static CHAT_GENERATE_PARAM_RECORD = 'generatechatparamrecord';

    // DIRECT SALES - BDQ
    public static GET_STATE_BY_ZIPCODE = 'getstatebyzipcode'; // keerthana
    public static GET_DIRECTSALES_DTLS = 'getDirectSalesVendorDtls';
    public static AUDIT_LOG_REQ_SYMBOL     = 'BDQ';
    public static AUDIT_LOG_REQ_POLICYNUM = '9999999';
    public static AUDIT_LOG_REQ_MOD = '00';
    public static AUDIT_LOG__REQ_MCO = '0';

    // BWSECURITY API
    public static BWSEC_CHANGE_EMAIL_URL = 'changeUserEmail';
    public static BWSEC_CHANGE_EMAIL_IN_STAGING_URL = 'changeUserEmailInStaging';
    public static BWSEC_ADD_USER_SSO_URL = 'addNewUserSSO';
    public static BWSEC_ADD_USER_SSO_URL_OKTA = 'addNewUserSSO?bwoktaEnabled=true';
    public static BWSEC_VALIDATENEWUSERPOINT = 'validateNewUserPoint';
    public static BWSEC_GET_POLICY_BY_EMAIL_URL = 'getPolicyByEmail';
    public static BWSEC_CHECK_REGISTRATION_STATUS = 'checkRegistrationStatus';
    public static BWSEC_GETPOLICYLOCATIONSTATUSANDMAXMOD = 'getPolicyLocationStatusandMaxMod';
    public static BW_OKTA_ENABLED_PARAM_KEY = 'bwoktaEnabled';
    public static BW_OKTA_ENABLED_PARAM_VALUE = 'true';
    // BWQUERIES API
    public static BWQRY_IS_CANCELLED_URL = 'isCancelledPolicy';
    public static BWQRY_GETPOLICYBASICCONTRACT = 'getPolicyBasicContract';
    public static BWQRY_GETPOLICYCURRENTMOD = 'getPolicyCurrentMod';
    public static BWQRY_GETPOLICYLOCATIONSTATUSANDMAXMOD = 'getPolicyLocationStatusAndMaxMod';
    public static BWQRY_GETPOLICYFEE = 'getPolicyFee';
    public static BWQRY_GETAGENTLIST = 'getAgents';
    public static BWQRY_IS_21CRENEWALOFFER = 'is21stPolicyRenewalOffer';
    public static BWQRY_IS_21CESIGELIGIBLE = 'is21stPolicyESigEligible';
    public static BWQRY_IS_WETSIGNED = 'hasBeenWetSigned';
    public static BWQRY_HAS_PAYMENTMADEOREFT = 'hasMadePaymentOrEFT';

    // BWIBIC API
    public static BWIBIC_GETALLPOLICYINFO = 'getAllPolicyInfo';

    // BWUTILITY API
    public static BWUTIL_SAVEFEEDBACK = 'saveFeedback';

    // ESIGN API
    public static ESIG_ISESIGIMPLEMENTED = 'isESigImplemented';
    public static ESIG_GETENVELOPES = 'getEnvelops';
    public static ESIG_GETCEREMONY_URL = 'getDocuSignURL';
    public static ESIG_CREATEENVELOPEANDWAITFORVENDOR = 'createEnvelopeAndWaitForVendor';
    public static ESIG_CREATEEMBEDDEDENVELOPE = 'createEmbeddedEnvelope';
    public static ESIG_GET_ELIGIBLE_STATUS = 'getEsignEligibilityStatus';

    // EMAILVALIDATE API
    public static EMAIL_VALIDATEEMAIAL = 'validateEmail';
    public static EMAIL_VALIDATEEMAIL_OKTA = 'validateEmail?bwoktaEnabled=true';

    // EDMR API
    public static EDMR_INVOKEDOCUMENTS = 'invokeEDMRDocuments';
    public static EDMR_GETDOCUMENT = 'getDocument';
    public static EDMR_GETPOLICYCONTRACT = 'getplcycontractbytes';
    public static UPLOAD_DOCUMENT = 'saveDocument';

    // POLICY DETAILS REST API
    public static GET_POLICYCOVERAGES = 'getPolicyCoverages';
    public static GET_VEHICLEDETAILS = 'getVehicleFullDetails';
    public static GET_DRIVERDETAILS = 'getDriverDetails';
    public static GET_VIOLATIONDETAILS = 'getViolationDetails';
    public static GET_DISCOUNTDETAILS = 'getDiscountDetails';
    public static GET_LEINHOLDERDETAILS = 'getLeinholderDetails';
    public static GET_POLICYHOLDERINFO = 'getPolicyHolderInfo';
    public static GET_CANCELPOLICYCHECKS = 'getRequestToCancelChecks';
    public static GET_CANCELPOLICYAMOUNT = 'getCancelAmount';
    public static GET_CANCEL_CREATE_EDMR_RECORD = 'createPrintRecodInEDMR';
    public static GET_VIN_DUP_CHECK_STATUS = 'getVinDupCheckStatus';
    public static AUDIT_LOG_CNX = 'CNX';
    public static AUDIT_LOG_RQC_MSG = 'Request to cancel completed';


    // ADDRESS TOOLS SERVICE API
    public static SCRUB_ADDRESS = 'scrubAddress';

    // PAYMENTS MOBILE API
    public static GET_VALIDATEPOLICY = 'getValidatePolicy';
    public static GET_VALIDATEPOLICYDETAILS = 'getValidatePolicyDetails';
    public static GET_PAYMENTSCHEDULE = 'getPaymentSchedule';
    public static GET_BANK_DETAILS = 'getbankdetail';
    public static GET_PAYMENT_PROFILES = 'payment-profiles';

    // IVR PAYMENTS SERVICE API
    public static PREVIOUS_PAYMENT = 'processPreviousPayment';
    public static CC_PAYMENT = 'processCC';
    public static ACH_PAYMENT = 'processACH';
    public static VALIDATE_CARDNUMBER = 'validateCardNumber';

    // DEBITCARD PAYMENT SERVICE API
    public static DBCARD_PROCESS_INSTALLEMENT = 'processCardInstlmntPayment';

    // DOCUMENTS API
    public static PROCESS_IDCARD = 'processidcard';
    public static GET_POLICYDOCUMENTS = 'getpolicydocuments';
    public static GET_POLICY_CONTRACT = 'getplcycontractdoc';
    public static GET_DOCS_TYPES = 'getTransactionTypesByStateAbbr';
    public static GET_EZL_TRAN_CODE = 'getEasylinkTransactionCode';
    public static CREATE_TASKINJ_REC = 'createTaskInjectorRecord';
    public static CREATE_UPLOAD_AUDIT = 'documentUploadAuditRecord';

    // MissingDocds API
    public static MISSING_DOCS_API = 'getMissingdocs';

    // POLICY EFT SERVICE API
    public static EFT_GET_STATUS = 'eftstatus';
    public static EFT_IS_ELIGIBLE = 'isEligible';
    public static EFT_PREPARE = 'prepareEFT';
    public static EFT_VOID = 'voidEFTEndorsement';
    public static EFT_ADD = 'addEFT';
    public static EFT_UPDATE = 'updateEFT';
    public static EFT_TERMINATE = 'terminateEFT';
    public static EFT_GET_CURRENTEFT = 'getCurrentEFT';
    public static EFT_GET_CURRENTEFT_INFO = 'getCurrentEFTInfo';
    public static STATECD_04 = '04';
    public static STATECD_52 = '52';
    public static STATE_CA = 'CA';
    public static STATE_HI = 'HI';
    public static MCO_90 = '90';
    public static MCO_91 = '91';
    public static MCO_92 = '92';
    public static MCO_44 = '44';

    public static EFT_ENROLLED = 'Active';
    public static EFT_NOT_ENROLLED = 'Not Enrolled';
    public static EFT_TERMINATED = 'Terminated';
    public static EFT_PENDING_ACTIVATION = 'Pending Activation';
    public static EFT_PENDING_UPDATE = 'Pending Update';
    public static EFT_TERMINATION_PENDING = 'Pending Termination';

    public static EFT_TYPE_ADD = 'ADD';
    public static EFT_TYPE_UPDATE = 'CHG';
    public static EFT_TYPE_TERMINATE = 'TRM';
    public static EFT_ACC_TYPE_CHK = 'CHK';
    public static EFT_ACC_TYPE_SAV = 'SAV';
    public static EFT_ACC_TYPE_CC = 'CC';
    public static EFT_ACC_TYPE_DC = 'DC';

    // POLICY MGMT SERVICE API
    public static CREATE_RTAFORM = 'createRTAForm';
    public static PLCY_MGMT_ADD_COMMENT = 'addComment';
    public static PLCY_MGMT_POLICY_MAIL = 'policyMail';
    public static PLCY_MGMT_AUTH_FOR_IDCARD = 'verifyPolicyForIDCards';
    public static PLCY_MGMT_CRT_WEBSSN_LOG = 'createWebSessionLogRecord';
    public static PLCY_MGMT_GET_MFA_DATE = 'getmfadate';
    public static PLCY_MGMT_UPDATE_MFA_DATE = 'updatemfadate';
    public static PLCY_MGMT_REQUEST_CANCEL = 'requestcancel';
    public static PLCY_MGMT_EXSTREAM_RECORD = 'createExstreamRecordPostCancellation';
    public static PLCY_MGMT_ENDORSE_POLICY = 'endorsePolicy';
    public static PLCY_MGMT_ENDORSE_VOID = 'endorseVoid';
    public static PLCY_MGMT_IS_ELIGIBLE_FOR_ENDORSEMENT = 'isEligibleForEndorsement';
    public static PLCY_MGMT_ENDORSE_BIND = 'endorsebind';
    public static PLCY_MGMT_ENDORSE_SAVE = 'savePolicyChanges';
    public static ENDORSE_USER_TYPE = 'INS';

    public static VEH_OWN_TYPE_OWN = 'OWN';
    public static VEH_OWN_TYPE_LEASED = 'LP';
    public static VEH_OWN_TYPE_FINANCED = 'AI';

    public static ENDORSE_EMAIL_STATUS = 'P';

    // CC TYPES
    public static DRIVERS_LICENCE = 'DL';
    public static SOCIAL_SECURITY = 'SSN';
    public static VISA_CARD = 'VISA';
    public static AMERICAN_EXPRESS = 'AMEX';
    public static MASTER_CARD = 'MASTER';
    public static DISCOVER_CARD = 'DISCOVER';

    // DOCUSIGN API
    public static DOCUSIGN_PARAMETER = '?eSigAction=';
    public static DOCUSIGN_TYPE_CANCELLED = 'Cancelled';
    public static DOCUSIGN_TYPE_DECLINED = 'Declined';
    public static DOCUSIGN_TYPE_EXCEPTION = 'Exception';
    public static DOCUSIGN_TYPE_IDCHECK = 'IDCheckFailed';
    public static DOCUSIGN_TYPE_SESSION_TIMEOUT = 'SessionTimedOut';
    public static DOCUSIGN_TYPE_COMPLETE = 'Complete';
    public static DOCUSIGN_TYPE_TTL_EXPIRED = 'TTLExpired';
    public static DOCUSIGN_TYPE_VIEWING = 'ViewingComplete';

    // POLICY DETAILS REST API
    public static GET_STATEPAPERDOCFEE = 'statepaperdocfee';

    // AGENTS
    public static EA_AGENT = 'EA';
    public static IA_AGENT = 'IA';

    // POLICY Holder REST API
    public static SSO_LOGIN = 'tokenLogin';
    public static FIND_PH_DETAILS = 'findPH';
    public static FIND_PH_DETAILS_OKTA = 'findPH?bwoktaEnabled=true';
    public static FORGOT_PH_PASSWORD = 'forgotPHPassword';
    public static CHECK_ANSWERS = 'checkAnswers';
    public static INSERT_PH = 'insertPH';
    public static REMOVE_PH = 'removePH';
    public static REMOVE_PH_OKTA = 'removePH?bwoktaEnabled=true';
    public static GET_POLICY_MCO = 'getPolicyMco';
    public static CHANGE_PH_PASSWORD = 'changePHPassword';
    public static CHANGE_PH_PASSWORD_OKTA = 'changePHPassword?bwoktaEnabled=true';
    
    public static RESET_PH_PASSWORD = 'resetPHPassword';
    public static RESET_PH_PASSWORD_OKTA = 'resetPHPassword';
    public static UPDATE_PH = 'updatePHInfo';
    public static UPDATE_PH_OKTA = 'updatePHInfo?bwoktaEnabled=true';
    public static LIENHOLDER_SEARCH = 'searchLienholder';

    // eFNOL Service
    public static IS_EFNOL_ROLLOUT = 'IseFNOLRolledOut';
    public static EFNOL_SAML_TOKEN = 'geteFNOLSAMLToken';
    public static EFNOL_SAML_TOKEN_VIEW = 'geteFNOLSAMLTokenView';
    public static IS_EFNOL_ROLLOUT_VIEW = 'IseFNOLRolledOutView';
    public static EFNOL_USERTYPE = 'Insured';

    // Text Alert REST API
    public static CHECK_STATE_ENABLED = 'checkStateEnabledRPC';
    public static GET_TEXT_ENROLLMENT_STATUS = 'getTextAlertEnrollmentStatusRPC';
    public static MANAGE_TEXT_ALERT_ENROLLMENT = 'manageTextAlertEnrollmentRPC';

    // Logger API
    public static LOGGER_INFO = 'info';
    public static LOGGER_ERROR = 'error';
    public static LOGGER_TIME = 'time';

    // read from prop - vary by environment
    public static get baseURL(): string { return 'http://localhost:4200/api'; }

    // policy opt
    public static HAS_OPTTED_PRIVACY = 'hasOptedOutPrivacyPolicy';
    public static VALIDATE_POLICY_OPT = 'validatePolicyPrivacyOptOutPOINT';
    public static LOG_PRIVICY_OPT = 'logPrivacyOptOut';

    // MFA
    public static MFA_DAYS = 90;
    public static CHECK_EACH_ANSWER = 'checkEachAnswer';

    // Vehicle Service
    public static VINTELL_RET_VIN = 'retrieveVehicleSpecificationByVIN';
    public static VINTELL_IMPLEMENTED = 'isVintelligenceImplemented';
    public static GET_SYMBOL = 'getSymbols';
    public static GET_21C_SYMBOL = 'get21CSymbols';
    public static GET_SYMBOL_SET = 'getSymbolSet';
    public static CONVERT_ATTR_VALUE = 'convertAttributeValue';
    public static ANTI_THEFT_YES = 'Y';
    public static ANTI_THEFT_NO = 'N';

    // Endorsements api
    public static PROCESS_ENDOREMENTS = 'processEndorsements';
    public static ENDORSE_POLICY = 'endorsePolicy';
    public static ENDORSE_SAVE_CHANGES_TO_POLICY = 'savePolicyChanges';
    public static ENDORSE_BIND = 'endorsebind';
    public static ENDORSE_VOID = 'endorseVoid';
    public static ENDORSE_ASYNC = 'postEndorsement';

    public static BW_ENDORSE_SAVE = 'processEndorsementSave';
    public static BW_ENDORSE_BIND = 'processEndorsementBind';
    public static BW_ENDORSE_VOID = 'processEndorsementVoid';

    // Audit log
    public static AUDIT_LOG_ACITIVITY_RESULT_S = 'S';
    public static AUDIT_LOG_ACITIVITY_REPLACE_VEHICLE = 'RPV';
    public static AUDIT_LOG_ACITIVITY_ADD_VEHICLE = 'ADV';
    public static AUDIT_LOG_ACITIVITY_ADDRESS_CHANGE = 'ADD';
    public static AUDIT_LOG_SERVICE = 'auditLogRecord';
    public static AUDIT_LOG_ACITIVITY_RESULT_K = 'K';
    public static AUDIT_LOG_ACITIVITY_RESULT_FAILURE = 'F';
    public static AUDIT_LOG_ACITIVITY_MESSAGE_SUCCESS = 'Address Endorsement completed';

    // Marketing ID Audit log
    public static AUDIT_LOG_MARKETING_ID_SYMBOL = 'XXX';
    public static AUDIT_LOG_MARKETING_ID_POLICY_NUMBER = 'XXXXXXX';
    public static AUDIT_LOG_MARKETING_ID_MODULE = 'XX';
    public static AUDIT_LOG_MARKETING_ID_MASTER_COMPANY = 'XX';
    public static AUDIT_LOG_MARKETING_ID_STATE = 'XX';
    public static AUDIT_LOG_MARKETING_ID_ACTIVITY = 'MRK';
    public static AUDIT_LOG_MARKETING_ID_ACTIVITY_RESULT = 'Y';

    // comment
    public static COMMENT_REQ_ACTIVITY_TYPE = 'EN';
    public static COMMENT_REQ_REASON_CODE = 'PC';
    public static COMMENT_REQ_RECYCLE = 'RECYCLE';
    public static COMMENT_REQ_PRINT_BYTE = 'P';
    // Endorsements api

    // Valid Values
    public static VALID_VALUES_RATEBOOK = 'getValidValuesForRatebook';
    public static VALID_VALUES_POLICY_COVERAGES = 'PolicyCoverage';
    public static VALID_VALUES_VEHICLE_COVERAGES = 'VehicleCoverage';
    public static VALID_VALUES_VEHICLE_COVERAGES_COMP = 'Comprehensive';
    public static VALID_VALUES_VEHICLE_COVERAGES_COLL = 'Collision';
    public static VALID_VALUES_VEHICLE_COVERAGES_RENTAL = 'Rental';
    public static VALID_VALUES_VEHICLE_COVERAGES_TOW = 'Towing & Road Service';
    public static VALID_VALUES_POLICY_COVERAGES_BI = 'Bodily Injury';
    public static VALID_VALUES_POLICY_COVERAGES_PD = 'Property Damage';
    public static VALID_VALUES_POLICY_COVERAGES_MED = 'Medical Coverage';
    public static VALID_VALUES_POLICY_COVERAGES_UMBI = 'Basic and Supplementary Uninsured/Underinsured Motorist';
    public static VALID_VALUES_POLICY_COVERAGES_PIP = 'Personal Injury Protection';
    public static VALID_VALUES_POLICY_COVERAGES_EXTPIP = 'Extra PIP Package';
    public static VALID_VALUES_VEHICLE_COVERAGES_UMPD = 'Uninsured Motorist PD w/o Collision';
    public static VALID_VALUES_VEHICLE_COVERAGES_ALL = 'Auto Loan Lease';

    public static AUDIT_LOG = 'auditLogRecord';

    // Audit log
    public static AUDIT_LOG_ACITIVITY_RESULT = 'S';

    // HELPTEXT API
    public static GET_HELPTEXT = 'getAllHelpText';
    public static GET_HELPTEXT_BYID = 'getAllHelpTextByIds';

    // TERRITORY EXCLUSION SERVICE API
    public static CHECK_ZIP_RESTRICTION = 'checkZipRestriction';
    public static GET_ZIP_RESTRICTION_FLAG = 'isZipCodeRestricted';

    // Knock out
    public static APPLY_KNOCKOUT_RULES =  'applyKnockoutRules';

    // anti theft
    public static GET_ANTI_THEFT_TYPES =  'getAntiTheftTypes';

    // Customer Reviews
    public static GET_CUSTOMER_REVIEWS =  'getCustomerReviews';
    public static CUSTOMER_REVIEWS_PAGE_TITLE =  'Customer Reviews';
    public static DRIVERS_PAGE_TITLE =  'Bristol West Policy - Drivers';
    public static COVERAGES_PAGE_TITLE =  'Bristol West Policy - Coverages';
    public static DISCOUNTS_PAGE_TITLE =  'Bristol West Policy - Discounts';
    public static VEHICLES_PAGE_TITLE =  'Bristol West Policy - Vehicles';
    public static PAYMENT_SCHEDULE_PAGE_TITLE =  'Bristol West Policy - Payment Schedule';
    public static AUTOPAY_PAGE_TITLE =  'Bristol West Policy - Manage Auto Pay';
    public static MAKE_A_PAYMENT_PAGE_TITLE =  'Bristol West Policy - Make A Payment';
    public static CLAIMS_PAGE_TITLE =  'Bristol West Policy - Claims';
    public static WHY_BRISTOL_PAGE_TITLE =  'Bristol West - Why Bristol';
    public static AUTO_INS_101_PAGE_TITLE =  'Bristol West - Auto Insurance 101';
    public static PRIVACY_PAGE_TITLE =  'Bristol West - Privacy';
    public static FAQ_PAGE_TITLE =  'Bristol West - FAQ';
    public static TERMS_PAGE_TITLE =  'Bristol West - Terms & Conditions';
    public static ABOUT_PAGE_TITLE =  'Bristol West - About Us';
    public static CONTACT_PAGE_TITLE =  'Bristol West - Contact Us';
    public static SITEMAP_PAGE_TITLE =  'Bristol West - Sitemap';
    public static FINDAGENT_PAGE_TITLE =  'Bristol West - Find Agent or Broker';

    // Meta Keywords/Description
    public static CUSTOMER_REVIEWS_META_DESCRIPTION =  'Customer Reviews for Bristol West Insurance.' +
    'Read reviews from Bristol West customers on rates, service, claims, and more.';
    public static CUSTOMER_REVIEWS_META_KEYWORDS =  'reviews, ratings, Bristolwest customer reviews, Bristolwest ratings , ' +
    'Bristolwest reviews , Bristol west customer reviews, Bristol west ratings , Bristol west reviews ,' +
    'auto Insurance reviews, car insurance reviews, customer reviews';

    public static ANY_PAGE_TITLE =  'Welcome to Bristol West Insurance Group';
    public static ANY_META_DESCRIPTION =  'Bristol West works to make Auto Insurance easy.';
    public static ANY_META_KEYWORDS =  'Car Insurance, Auto Insurance, Insurance, online car insurance, discount car insurance,' +
     'www.bristolwest.com, Bristol West Insurance';

    // MI Reform Bill  //keerthana
    public static STATE_MI = 'MI';
    public static BWR_SYSTEM = 'BWR';
    public static EDMR_SIGNEDDOCUMENT_CD = 'SD';
    public static EDMR_APPLICAIONPKG_CD = 'AP';
    public static GET_POLICYHOLDERINFO_PAYMENTUS = 'getPaymentUsStatus';

    //New payment endpoint from service wrapper //sayantan
    public static MAKE_NEW_PAYMENT_URL='epc/makePayment';

    //PaymentUs Iframe
    public static PAYMENTUS_IFRAME = 'epc/iframeUrl';

    //EFT 
    public static ENROLL_EFT = 'epc/autopay-enroll';
    public static UPDATE_EFT = 'epc/autopay-update';
    public static TERMINATE_EFT = 'epc/autopay-cancel';
    public static GET_EFT = 'epc/autopay-details/';

    //CES
    public static GET_TOKEN = 'ces/survey-token';
    public static UPDATE_NOTHANKS = 'ces/survey-no-thanks';

    //OKTA
    public static AUTHN = 'security/authn'; 
    public static SSO_OKTA = 'users/accesstoken'
    public static OKTA_DECODE_JWT = "security/decode-jwt"
    public static OKTA_USERTYPE="0001";
    public static OKTA_REDIRECT_URI=environment.oktaRedirectUriForSSO;
    public static OKTA_ISACCESSTOKENREQUIRED = true;
    public static STATE_TOKEN = 'users/statetoken';
    public static RESET_PASSWORD = 'users/reset/password';
    public static RECOVERY_PASSWORD = 'users/recovery/password';

    public  static status = {
        pendingCancelled : 'Pending Cancel',
        reinstatementAvailable : 'Cancelled - Reinstatement Available',
        active : 'Active',
        expired : 'Expired',
        cancelled : 'Cancelled',
        futureEffective : 'Future Effective'
      };
      
    public static STATES_4_0 = ['AZ','IL'];

    constructor() { }
}
