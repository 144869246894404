var PolicyNumberValidator = /** @class */ (function () {
    function PolicyNumberValidator() {
    }
    PolicyNumberValidator.validateBwPolicyNumber = function (control) {
        // policy number pattren with - or without -
        var policyNumPattern = /(^[a-zA-Z]{1}[0-9]{2}-[0-9]{7}-[0-9]{2}$)|(^[a-zA-Z]{1}[0-9]{2}[0-9]{7}[0-9]{2}$)/;
        // Tracker.log("Validator Input Value: "+control.value)
        if (control.value && !control.value.match(policyNumPattern)) {
            return { 'plcynum': true };
        }
        else if (control.value && control.value.match(policyNumPattern) && control.value.length === 12) {
            // if user enters policy number without -'s, then we are adding -'s like XXX-XXXXXXX-XX
            control.setValue(control.value.substring(0, 3) + '-'
                + control.value.substring(3, 10) + '-' + control.value.substring(10, 12));
        }
        return null;
    };
    return PolicyNumberValidator;
}());
export { PolicyNumberValidator };
