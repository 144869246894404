var RemovePHRequest = /** @class */ (function () {
    function RemovePHRequest(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return RemovePHRequest;
}());
export { RemovePHRequest };
var RemovePHResponse = /** @class */ (function () {
    function RemovePHResponse(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return RemovePHResponse;
}());
export { RemovePHResponse };
