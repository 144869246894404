
import * as BwPolicyCancelActions from '../actions/bwpolicycancel.actions';
import { CancelAmount } from '../../shared/model/api/policyinfo/requesttocancelamount.model';
import { RequestToCancelInfo } from '../../shared/model/api/policyinfo/requesttocancelchecksres.model';
import { RequesttocancelStoreInfo } from '../../shared/model/requesttocancelinfo.model';

const defaultPolicyCancelState: RequesttocancelStoreInfo = new RequesttocancelStoreInfo();
const removePolicyCancelState: RequesttocancelStoreInfo = {
 cancelDate: '',
 cancelTime: '',
 confirmationNumber: '',
 cancelChecks: new RequestToCancelInfo(''),
 cancelAmount: new CancelAmount('')
};

// Helper to create new state object as the state is immutable
const newState = (state, newDataObj) => {
  return Object.assign({}, state, newDataObj);
};

export function BwpolicycancelReducer(state: RequesttocancelStoreInfo = defaultPolicyCancelState,
                                      action: BwPolicyCancelActions.Actions) {
    switch (action.type) {
      case BwPolicyCancelActions.CREATE_CANCELPOLICYINFO:
        return newState(state, action.payload);

      case BwPolicyCancelActions.UPDATE_CANCELPOLICYINFO:
        return newState(state, action.payload);

      case BwPolicyCancelActions.REMOVE_CANCELPOLICYINFO:
        return newState(state, removePolicyCancelState);

      default:
        return state;
    }
}
