import { OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, NavigationStart } from '@angular/router';
import { CommonUtil } from '../../shared/utils/commonutil';
import { GoogleAnalyticsService } from '../../shared/services/analytics/google-analytics.service';
import { environment } from '../../../environments/environment';
import { MessagesService } from '../../shared/services/messages.service';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(route, googleAnalytics, router, msgservicec) {
        var _this = this;
        this.route = route;
        this.googleAnalytics = googleAnalytics;
        this.router = router;
        this.msgservicec = msgservicec;
        this.showhome = false;
        this.closed = false;
        // Logic to check if browser refresh
        router.events.subscribe(function (event) {
            if (event instanceof NavigationStart) {
                _this.showhome = event.url.includes('/externalRedirect') || event.url.includes('zip=') ? false : true;
            }
            if (event instanceof NavigationEnd) {
                _this.showhome = event.url.includes('/externalRedirect') || event.url.includes('zip=') ? false : true;
            }
        });
    }
    HomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.msgservicec.showbwcom.subscribe(function (val) {
            _this.showhome = val;
        });
        // this.dontSellUrl = `${environment.ccpaDontSell}`;
        this.route.queryParams.subscribe(function (params) {
            if (params) {
                var src = params.src;
                _this.welcomeMessage = CommonUtil.getLandingMessage('false', src);
            }
        });
        // BDQ- Get the query string parameter
        this.showQuickQuote = "" + environment.showQuickQuote;
        if (this.router.url !== undefined && this.router.url !== null && this.router.url.split('?')[0] === '/bdq') {
            this.firstQueryParam = this.route.snapshot.queryParamMap.get('marketingID');
            if (this.firstQueryParam === null) {
                this.firstQueryParam = 'none';
            }
        }
        // const secondParam: string = this.route.snapshot.queryParamMap.get('secondParamKey');
    };
    // This function will add the google tags for the events viewIdCard, Make apayment,Cliams with out login
    HomeComponent.prototype.addGATags = function (actionType) {
        if (actionType === 'idCard') {
            this.googleAnalytics.trackEvent(' Unauthenticated View IDCard', 'Home Screen', 'Unauthenticated view ID  Click', 200);
        }
        else if (actionType === 'payment') {
            this.googleAnalytics.trackEvent('Unauthenticated Make a payment', 'Home Screen', 'Unauthenticated Make a Payment Click', 200);
            // if (this.isPaymentusEnabled === 'true') {
            //   this.router.navigate(['../payments']);
            // } else {
            //   this.router.navigate(['/nologin'], {queryParams: { type: 'pay' }} );
            // }
        }
        else if (actionType === 'claims') {
            this.googleAnalytics.trackEvent('Unauthenticated View/Report', 'Home Screen', 'Unauthenticated report/view Claim', 200);
        }
    };
    return HomeComponent;
}());
export { HomeComponent };
