/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./confirm-changes.component";
import * as i2 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
var styles_ConfirmChangesComponent = [];
var RenderType_ConfirmChangesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmChangesComponent, data: {} });
export { RenderType_ConfirmChangesComponent as RenderType_ConfirmChangesComponent };
export function View_ConfirmChangesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"], ["id", "request-to-cancel-confirmation"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Confirmation"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵted(-1, null, [" --> "])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Confirm these changes"])), (_l()(), i0.ɵeld(10, 0, null, null, 4, "div", [["class", "modal-footer mt-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-secondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmChanges("cancel") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"])), (_l()(), i0.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmChanges("accept") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Accept"]))], null, null); }
export function View_ConfirmChangesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-confirm-changes", [], null, null, null, View_ConfirmChangesComponent_0, RenderType_ConfirmChangesComponent)), i0.ɵdid(1, 114688, null, 0, i1.ConfirmChangesComponent, [i2.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmChangesComponentNgFactory = i0.ɵccf("app-confirm-changes", i1.ConfirmChangesComponent, View_ConfirmChangesComponent_Host_0, {}, { emitService: "emitService" }, []);
export { ConfirmChangesComponentNgFactory as ConfirmChangesComponentNgFactory };
