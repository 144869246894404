import { HttpParams } from '@angular/common/http';
import { GlobalConstants } from '../../../constants/global.constant';
import { Textalertenrollmentreq } from '../../../shared/model/api/text-alerts/textalertenrollment.model';
import { Policynumber } from '../../../shared/model/policynumber';
import { BwappService } from '../../bwapp.service';
import * as i0 from "@angular/core";
import * as i1 from "../../bwapp.service";
var TextAlertsService = /** @class */ (function () {
    function TextAlertsService(appService) {
        this.appService = appService;
        this.globlalConstants = GlobalConstants;
    }
    TextAlertsService.prototype.checkStateEnabled = function (state, mco, rateBook) {
        var params = new HttpParams()
            .set('state', state)
            .set('mco', mco)
            .set('rateBook', rateBook);
        return this.appService.get(this.globlalConstants.CHECK_STATE_ENABLED, params);
    };
    TextAlertsService.prototype.GetTextAlertEnrollmentStatus = function (agentCode, agentType, mco, state, policyNumber) {
        this.policyNumber = new Policynumber(policyNumber);
        this.textAlertEnrollmentReq = new Textalertenrollmentreq();
        this.textAlertEnrollmentReq.agentCode = agentCode;
        this.textAlertEnrollmentReq.agentType = agentType;
        this.textAlertEnrollmentReq.applicationId = this.globlalConstants.APPID;
        this.textAlertEnrollmentReq.mco = mco;
        this.textAlertEnrollmentReq.policyMOD = this.policyNumber.module;
        this.textAlertEnrollmentReq.policyNumber = this.policyNumber.policy;
        this.textAlertEnrollmentReq.stateCode = state;
        this.textAlertEnrollmentReq.symbol = this.policyNumber.symbol;
        return this.appService.post(this.globlalConstants.GET_TEXT_ENROLLMENT_STATUS, this.textAlertEnrollmentReq);
    };
    TextAlertsService.prototype.ManageTextAlertEnrollment = function (agentCode, agentType, mco, state, policyNumber, phoneNumberDetails) {
        this.policyNumber = new Policynumber(policyNumber);
        this.textAlertEnrollmentReq = new Textalertenrollmentreq();
        this.textAlertEnrollmentReq.agentCode = agentCode;
        this.textAlertEnrollmentReq.agentType = agentType;
        this.textAlertEnrollmentReq.applicationId = this.globlalConstants.APPID;
        this.textAlertEnrollmentReq.mco = mco;
        this.textAlertEnrollmentReq.policyMOD = this.policyNumber.module;
        this.textAlertEnrollmentReq.policyNumber = this.policyNumber.policy;
        this.textAlertEnrollmentReq.stateCode = state;
        this.textAlertEnrollmentReq.symbol = this.policyNumber.symbol;
        this.textAlertEnrollmentReq.phoneNumberDetails = phoneNumberDetails;
        return this.appService.post(this.globlalConstants.MANAGE_TEXT_ALERT_ENROLLMENT, this.textAlertEnrollmentReq);
    };
    TextAlertsService.ngInjectableDef = i0.defineInjectable({ factory: function TextAlertsService_Factory() { return new TextAlertsService(i0.inject(i1.BwappService)); }, token: TextAlertsService, providedIn: "root" });
    return TextAlertsService;
}());
export { TextAlertsService };
