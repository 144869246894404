<main id="main" class="main">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mx-auto">
        <div *ngIf="this.message !== ''" class="alert alert-danger alert-dismissible fade show" role="alert">
            {{ this.message }}
            <button type="button" class="close align-top" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

        <div *ngIf="this.message === ''" class="card mt-2">
          <div class="card-header text-center">
              <span class="fa-2x text-success">
                  <i class="far fa-check-circle fa-lg text-success"></i>
              </span>
              <h2 class="heading-4">Payment Confirmation</h2>
              <p class="small">All payments processed online will be posted
                to your policy within 24 to 48 hours.
              </p>
          </div>
          <div class="card-body pr-4 pl-4">
            <div class="row">
              <div class="col-md-5 col-sm-3 font-weight-bold">Policy Number:</div>
              <div class="col-md-5 col-sm-3 ml-auto">{{ this.policyNumber }}</div>
            </div>
            <hr/>

            <div class="row">
              <div class="col-md-5 col-sm-3 font-weight-bold">Amount:</div>
              <div class="col-md-5 col-sm-3 ml-auto">${{ this.paidAmount }}</div>
            </div>
            <hr/>
            <div class="row">
              <div class="col-md-5 col-sm-3 font-weight-bold">Date:</div>
              <div class="col-md-5 col-sm-3 ml-auto">{{ this.paymentDate | date:'MM/dd/yyyy' }}</div>
            </div>
            <hr/>
            <!-- <div class="row">
              <div *ngIf="this.isCreditCard" class="col-md-5 col-sm-3 font-weight-bold">Card Holder:</div>
              <div *ngIf="!this.isCreditCard" class="col-md-5 col-sm-3 font-weight-bold">Account Holder:</div>
              <div class="col-md-5 col-sm-3 ml-auto">{{ this.cardHolder }}</div>
            </div>
            <hr/> -->
           <!-- <div class="row">
              <div *ngIf="this.isCreditCard" class="col-md-5 col-sm-3 font-weight-bold">Card Number:</div>
              <div *ngIf="!this.isCreditCard" class="col-md-5 col-sm-3 font-weight-bold">Account Number:</div>
              <div class="col-md-5 col-sm-3 ml-auto">{{ this.cardNumber }}</div>
            </div>
            <hr/> -->
            <div class="row">
              <div class="col-md-5 col-sm-3 font-weight-bold">Confirmation Number:</div>
              <div class="col-md-5 col-sm-3 ml-auto">{{ this.confirmationNumber }}</div>
            </div>
          </div>
          <div class="card-footer">
              <p class="small">Your balance will be updating accordingly once your payment is processed.</p>
            </div>
        </div>
      </div>
    </div>
  </div>
</main>

<!-- Modal -->




