import { Store } from '@ngrx/store';
import * as BwLoginActions from '../../store/actions/bwlogin.actions';
import * as BwPolicyInfoActions from '../../store/actions/bwpolicy.actions';
import * as NewUserActions from '../../store/actions/custregistration.actions';
import * as BwPolicyCancelActions from '../../store/actions/bwpolicycancel.actions';
import * as BwCoveragesActions from '../../store/actions/bwcoverages.actions';
import * as BwEndorseInfoActions from '../../store/actions/bwendorsement.action';
import { FullPolicyDetails } from '../model/api/bwibic/fullpolicydetails.model';
import { PolicyHolder } from '../model/authentication/authenticatephres';
import { RequesttocancelStoreInfo } from '../model/requesttocancelinfo.model';
import { ValidValuesResponse } from '../model/api/validvalues/valid-values-res.model';
import { EndorseVehicleInfo } from '../model/api/endorsement/endorse-vehicle-info';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
var BwstoreService = /** @class */ (function () {
    function BwstoreService(bwLoginStore, newUserStore, bwPolicyInfoStore, bwPolicyCancelInfoStore, bwEndorsementInfoStore, bwCoveragesStore) {
        this.bwLoginStore = bwLoginStore;
        this.newUserStore = newUserStore;
        this.bwPolicyInfoStore = bwPolicyInfoStore;
        this.bwPolicyCancelInfoStore = bwPolicyCancelInfoStore;
        this.bwEndorsementInfoStore = bwEndorsementInfoStore;
        this.bwCoveragesStore = bwCoveragesStore;
        this.bwCoverages = new ValidValuesResponse();
    }
    // LOGGEDIN USER STORE
    BwstoreService.prototype.createLoginInfo = function (payload) {
        // Tracker.log('Storing login info to store');
        this.bwLoginStore.dispatch(new BwLoginActions.CreateLoginInfo(payload));
        this.syncUserSessionToBrowserSession(payload);
    };
    BwstoreService.prototype.updateLoginInfo = function (payload) {
        this.bwLoginStore.dispatch(new BwLoginActions.UpdateLoginInfo(payload));
        this.syncUserSessionToBrowserSession(payload);
    };
    BwstoreService.prototype.deleteLoginInfo = function (payload) {
        this.bwLoginStore.dispatch(new BwLoginActions.RemoveLoginInfo(payload));
        this.removeUserSessionFromBrowserSession();
    };
    BwstoreService.prototype.getLoginInfoFromStore = function () {
        var _this = this;
        this.loggedInUser$ = this.bwLoginStore.select('policyHolder');
        this.loggedInUser$.subscribe(function (loginInfo) { _this.currentUserLoginInfo = loginInfo; });
        // Check if User Info not found in Store then check in Browser session - This is a backfill logic
        if (this.currentUserLoginInfo.jwtToken === undefined || this.currentUserLoginInfo.jwtToken === null) {
            var customerLoginInfo = JSON.parse(sessionStorage.getItem('BWUserSession'));
            if (customerLoginInfo && customerLoginInfo !== undefined &&
                customerLoginInfo.jwtToken !== undefined && customerLoginInfo.jwtToken != null && customerLoginInfo.jwtToken !== '') {
                this.bwLoginStore.dispatch(new BwLoginActions.CreateLoginInfo(customerLoginInfo));
                this.currentUserLoginInfo = customerLoginInfo;
            }
            // Tracker.log('Retrieving the Login Info ::: ', this.currentUserLoginInfo);
        }
        return this.currentUserLoginInfo;
    };
    BwstoreService.prototype.syncUserSessionToBrowserSession = function (payload) {
        sessionStorage.setItem('BWUserSession', JSON.stringify(payload));
    };
    BwstoreService.prototype.removeUserSessionFromBrowserSession = function () {
        sessionStorage.removeItem('BWUserSession');
    };
    BwstoreService.prototype.getBWUserTokenFromBrowser = function () {
        var customerLoginInfo = JSON.parse(sessionStorage.getItem('BWUserSession'));
        if (customerLoginInfo && customerLoginInfo !== undefined &&
            customerLoginInfo.jwtToken !== undefined && customerLoginInfo.jwtToken != null && customerLoginInfo.jwtToken !== '') {
            return customerLoginInfo.jwtToken;
        }
    };
    BwstoreService.prototype.updateStoreFromSession = function () {
        // const currentStore = this.getBWStoreFromSession();
        // this._store.merge(currentStore);
    };
    // REGISTRATION USER STORE
    BwstoreService.prototype.updateRegistrationState = function (payload) {
        this.newUserStore.dispatch(new NewUserActions.NewUserUpdate(payload));
    };
    BwstoreService.prototype.removeRegistrationState = function () {
        this.newUserStore.dispatch(new NewUserActions.NewUserRemove(1));
    };
    BwstoreService.prototype.getCurrentregistrationState = function () {
        var _this = this;
        this.newUser$ = this.newUserStore.select('newUser');
        this.newUser$.subscribe(function (newuserobj) { _this.newUser = newuserobj; });
        return this.newUser;
    };
    // POLICYINFO STORE FUNCTIONS
    BwstoreService.prototype.addPolicyInfo = function (payload) {
        // Tracker.log('Storing Policy info to store');
        this.bwPolicyInfoStore.dispatch(new BwPolicyInfoActions.CreatePolicyInfo(payload));
        this.syncPolicyInfoToBrowserSession(payload);
    };
    BwstoreService.prototype.updatePolicyInfo = function (payload) {
        this.bwPolicyInfoStore.dispatch(new BwPolicyInfoActions.UpdatePolicyInfo(payload));
        this.syncPolicyInfoToBrowserSession(payload);
    };
    BwstoreService.prototype.deletePolicyInfo = function (payload) {
        this.bwPolicyInfoStore.dispatch(new BwPolicyInfoActions.RemovePolicyInfo(payload));
        this.removePolicyFromBrowserSession();
    };
    BwstoreService.prototype.getPolicyInfoFromStore = function () {
        var _this = this;
        this.fullPolicydetails$ = this.bwPolicyInfoStore.select('bwPolicyDetails');
        this.fullPolicydetails$.subscribe(function (polInfoStore) {
            _this.fullPolicydetails = polInfoStore;
            // Tracker.log('Retrieving Policy Details from Store:::', this.fullPolicydetails);
        });
        // Check if Policy Info not found in Store then check in Browser session - This is a backfill logic
        if (!this.fullPolicydetails || (this.fullPolicydetails && !this.fullPolicydetails.policyInfo)) {
            var policyDetails = JSON.parse(sessionStorage.getItem('BWPolicyInfo'));
            // Tracker.log('Policy Details in Session 1::: ', policyDetails);
            if (policyDetails && policyDetails.policyInfo && policyDetails.policyInfo.POLICY0NUM !== '') {
                this.fullPolicydetails = policyDetails;
                // Tracker.log('Retrieving Policy Details from Session :::', this.fullPolicydetails);
                this.bwPolicyInfoStore.dispatch(new BwPolicyInfoActions.CreatePolicyInfo(policyDetails));
            }
        }
        // Tracker.log('Retrieving Policy Details from Store/Session 2:::', this.fullPolicydetails);
        return this.fullPolicydetails;
    };
    BwstoreService.prototype.syncPolicyInfoToBrowserSession = function (payload) {
        sessionStorage.setItem('BWPolicyInfo', JSON.stringify(payload));
    };
    BwstoreService.prototype.removePolicyFromBrowserSession = function () {
        sessionStorage.removeItem('BWPolicyInfo');
    };
    // REQUEST TO CANCEL STORE INFO
    BwstoreService.prototype.addRequestToCancelPolicyInfo = function (payload) {
        this.bwPolicyCancelInfoStore.dispatch(new BwPolicyCancelActions.CreatePolicyCancelInfo(payload));
        this.syncRequestToCancelPolicyInfoToBrowserSession(payload);
    };
    BwstoreService.prototype.updateRequestToCancelPolicyInfo = function (payload) {
        this.bwPolicyCancelInfoStore.dispatch(new BwPolicyCancelActions.UpdatePolicyCancelInfo(payload));
        this.syncRequestToCancelPolicyInfoToBrowserSession(payload);
    };
    BwstoreService.prototype.deleteRequestToCancelPolicyInfo = function (payload) {
        this.bwPolicyCancelInfoStore.dispatch(new BwPolicyCancelActions.RemovePolicyCancelInfo(payload));
        this.removeRequestToCancelPolicyInfoFromBrowserSession();
    };
    BwstoreService.prototype.geRequestToCancelPolicyInfoFromStore = function () {
        var _this = this;
        this.requestToCancelInfo$ = this.bwPolicyCancelInfoStore.select('requestToCancelInfo');
        this.requestToCancelInfo$.subscribe(function (cancelInfoStore) {
            _this.requestToCancelInfo = cancelInfoStore;
        });
        console.log('Retrieving the Cancel Info from Store :::: ');
        // Check if Request To Cancel Policy Info not found in Store then check in Browser session - This is a backfill logic
        if (!this.requestToCancelInfo || !this.requestToCancelInfo.cancelChecks) {
            var reqToCanceDetails = JSON.parse(sessionStorage.getItem('BWRequestToCancelPolicyInfo'));
            if (reqToCanceDetails) {
                this.requestToCancelInfo = reqToCanceDetails;
                this.bwPolicyCancelInfoStore.dispatch(new BwPolicyCancelActions.CreatePolicyCancelInfo(reqToCanceDetails));
            }
        }
        return this.requestToCancelInfo;
    };
    BwstoreService.prototype.syncRequestToCancelPolicyInfoToBrowserSession = function (payload) {
        sessionStorage.setItem('BWRequestToCancelPolicyInfo', JSON.stringify(payload));
    };
    BwstoreService.prototype.removeRequestToCancelPolicyInfoFromBrowserSession = function () {
        sessionStorage.removeItem('BWRequestToCancelPolicyInfo');
    };
    // ENDPORSEMENTS STORE FUNCTIONS
    BwstoreService.prototype.addBwEndorsementInfo = function (payload) {
        this.bwEndorsementInfoStore.dispatch(new BwEndorseInfoActions.CreateEndorsementInfo(payload));
        this.syncPayloadToBrowserSession(payload, 'BWEndorseInfo');
    };
    BwstoreService.prototype.updateBwEndorsementInfo = function (payload) {
        this.bwEndorsementInfoStore.dispatch(new BwEndorseInfoActions.UpdateEndorsementInfo(payload));
        this.syncPayloadToBrowserSession(payload, 'BWEndorseInfo');
    };
    BwstoreService.prototype.deleteBwEndorsementInfo = function (payload) {
        this.bwEndorsementInfoStore.dispatch(new BwEndorseInfoActions.RemoveEndorsementInfo(payload));
        this.removePayloadFromBrowserSession('BWEndorseInfo');
    };
    BwstoreService.prototype.getEndorsementInfoFromStore = function () {
        var _this = this;
        this.bwEndorsementInfo$ = this.bwEndorsementInfoStore.select('bwEndorsementInfo');
        this.bwEndorsementInfo$.subscribe(function (bwEndorsementInfo) {
            _this.bwEndorsementInfo = bwEndorsementInfo;
        });
        // Check if Endorsement Info not found in Store then check in Browser session - This is a backfill logic
        if (!this.bwEndorsementInfo || (this.bwEndorsementInfo && !this.bwEndorsementInfo.endorseType)) {
            var endorsementInfo = JSON.parse(sessionStorage.getItem('BWEndorseInfo'));
            if (endorsementInfo && endorsementInfo.endorseType !== '') {
                this.bwEndorsementInfo = endorsementInfo;
                this.bwEndorsementInfoStore.dispatch(new BwEndorseInfoActions.CreateEndorsementInfo(endorsementInfo));
            }
        }
        return this.bwEndorsementInfo;
    };
    BwstoreService.prototype.getEndorsVehicleInfoFromStore = function () {
        this.bwEndorsementInfo = this.getEndorsementInfoFromStore();
        var endorsementReq = this.bwEndorsementInfo.endorsementReqInfo;
        if (endorsementReq.endorseVehicleInfo) {
            return endorsementReq.endorseVehicleInfo;
        }
        else {
            return new EndorseVehicleInfo();
        }
    };
    /*** TODO: Need to delete all the below  VehicleEndtInfoStore functions - Starts  */
    // VEHICLE ENDORSMENT STORE INFO
    BwstoreService.prototype.addVehicleEndtDetail = function (payload) {
        this.syncVehicleEndtDetailToBrowserSession(payload);
    };
    BwstoreService.prototype.updateVehicleEndtDetail = function (payload) {
        // this.bwPolicyInfoStore.dispatch(new VehicleEndtInfoActions.UpdateVehicleEndtInfo(payload));
        this.syncVehicleEndtDetailToBrowserSession(payload);
    };
    BwstoreService.prototype.deleteVehicleEndtDetail = function (payload) {
        // this.bwPolicyInfoStore.dispatch(new VehicleEndtInfoActions.RemoveVehicleEndtInfo(payload));
        this.removeVehicleEndtDetailFromBrowserSession();
    };
    BwstoreService.prototype.getVehicleEndtDetailFromStore = function () {
        var _this = this;
        this.vehicleEndtInfo$ = this.bwPolicyInfoStore.select('vehicleEndtInfo');
        this.vehicleEndtInfo$.subscribe(function (vehicleEndtInfoStore) {
            _this.vehicleEndtInfo = vehicleEndtInfoStore;
        });
        console.log('Retrieving the VehicleEndt Info from Store :::: ');
        // Check if VehicleEndtInfo not found in Store then check in Browser session - This is a backfill logic
        if (!this.vehicleEndtInfo || !this.vehicleEndtInfo) {
            var vehEndtDetail = JSON.parse(sessionStorage.getItem('VehicleEndtInfo'));
            if (vehEndtDetail) {
                this.vehicleEndtInfo = vehEndtDetail;
                // this.bwPolicyInfoStore.dispatch(new VehicleEndtInfoActions.CreateVehicleEndtInfo(vehEndtDetail));
            }
        }
        return this.vehicleEndtInfo;
    };
    BwstoreService.prototype.syncVehicleEndtDetailToBrowserSession = function (payload) {
        sessionStorage.setItem('VehicleEndtInfo', JSON.stringify(payload));
    };
    BwstoreService.prototype.removeVehicleEndtDetailFromBrowserSession = function () {
        sessionStorage.removeItem('VehicleEndtInfo');
    };
    /*** TODO: Need to delete all the below  VehicleEndtInfoStore functions - Ends */
    // Delete all the Store Entities
    BwstoreService.prototype.deleteStore = function () {
        this.deleteLoginInfo(new PolicyHolder());
        this.deletePolicyInfo(new FullPolicyDetails());
        this.deleteRequestToCancelPolicyInfo(new RequesttocancelStoreInfo());
        this.removeBWCoverages();
    };
    // Valid Values STORE INFO
    BwstoreService.prototype.addBWCoverages = function (payload) {
        this.bwCoveragesStore.dispatch(new BwCoveragesActions.CreateValidValuesInfo(payload));
        sessionStorage.setItem('BWCoverages', JSON.stringify(payload));
    };
    BwstoreService.prototype.removeBWCoverages = function () {
        this.bwCoveragesStore.dispatch(new BwCoveragesActions.RemoveValidValuesInfo(new ValidValuesResponse()));
        sessionStorage.removeItem('BWCoverages');
    };
    BwstoreService.prototype.getBWCoveragesFromStore = function () {
        var _this = this;
        // sessionStorage.getItem('BWCoverages');
        this.bwCoverages$ = this.bwCoveragesStore.select('bwCoverages');
        this.bwCoverages$.subscribe(function (vvRes) {
            _this.bwCoverages = vvRes;
        });
        // Check if bwCoverages not found in Store then check in Browser session - This is a backfill logic
        if (!this.bwCoverages || this.bwCoverages !== undefined) {
            var bwCovgs = JSON.parse(sessionStorage.getItem('BWCoverages'));
            if (bwCovgs) {
                this.bwCoverages = bwCovgs;
            }
        }
        return this.bwCoverages;
    };
    // Global functions
    BwstoreService.prototype.syncPayloadToBrowserSession = function (payload, storageItemId) {
        sessionStorage.setItem(storageItemId, JSON.stringify(payload));
    };
    BwstoreService.prototype.removePayloadFromBrowserSession = function (storageItemId) {
        sessionStorage.removeItem(storageItemId);
    };
    BwstoreService.ngInjectableDef = i0.defineInjectable({ factory: function BwstoreService_Factory() { return new BwstoreService(i0.inject(i1.Store), i0.inject(i1.Store), i0.inject(i1.Store), i0.inject(i1.Store), i0.inject(i1.Store), i0.inject(i1.Store)); }, token: BwstoreService, providedIn: "root" });
    return BwstoreService;
}());
export { BwstoreService };
