export var CREATE_POLICYINFO = '[POLICYINFO] Add';
export var UPDATE_POLICYINFO = '[POLICYINFO] Update';
export var REMOVE_POLICYINFO = '[POLICYINFO] DELETE';
var CreatePolicyInfo = /** @class */ (function () {
    function CreatePolicyInfo(payload) {
        this.payload = payload;
        this.type = UPDATE_POLICYINFO;
    }
    return CreatePolicyInfo;
}());
export { CreatePolicyInfo };
var UpdatePolicyInfo = /** @class */ (function () {
    function UpdatePolicyInfo(payload) {
        this.payload = payload;
        this.type = CREATE_POLICYINFO;
    }
    return UpdatePolicyInfo;
}());
export { UpdatePolicyInfo };
var RemovePolicyInfo = /** @class */ (function () {
    function RemovePolicyInfo(payload) {
        this.payload = payload;
        this.type = REMOVE_POLICYINFO;
    }
    return RemovePolicyInfo;
}());
export { RemovePolicyInfo };
