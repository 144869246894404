import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router ,NavigationStart } from '@angular/router';
import { CommonUtil } from '../../shared/utils/commonutil';
import { GoogleAnalyticsService } from '../../shared/services/analytics/google-analytics.service';
import { environment } from '../../../environments/environment';
import { MessagesService } from '../../shared/services/messages.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
 
  showhome = false;
  closed = false;
  // dontSellUrl: string;
  welcomeMessage: string; // This has to be populated with Customer Communication messages also
  firstQueryParam: string; // keerthana
  
  constructor(private route: ActivatedRoute , private googleAnalytics: GoogleAnalyticsService,
     private router: Router , private msgservicec: MessagesService) {
      // Logic to check if browser refresh
      router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.showhome = event.url.includes('/externalRedirect') || event.url.includes('zip=') ? false : true;
        }
        if (event instanceof NavigationEnd) {
          this.showhome = event.url.includes('/externalRedirect') || event.url.includes('zip=') ? false : true;
        }
   });
  }
  showQuickQuote: string; // keerthana
  ngOnInit() {
    this.msgservicec.showbwcom.subscribe((val) => {
      this.showhome = val;
    });
    // this.dontSellUrl = `${environment.ccpaDontSell}`;
    this.route.queryParams.subscribe(params => {
      if (params) {
        const src = params.src;
        this.welcomeMessage = CommonUtil.getLandingMessage('false', src);
      }
    });

    // BDQ- Get the query string parameter
    this.showQuickQuote = `${environment.showQuickQuote}`;
    if (this.router.url !== undefined && this.router.url !== null && this.router.url.split('?')[0] === '/bdq') {
      this.firstQueryParam = this.route.snapshot.queryParamMap.get('marketingID');
      if (this.firstQueryParam === null) {
        this.firstQueryParam = 'none';
      }
    }
    // const secondParam: string = this.route.snapshot.queryParamMap.get('secondParamKey');
  }

  // This function will add the google tags for the events viewIdCard, Make apayment,Cliams with out login
  addGATags(actionType: string) {
    if (actionType === 'idCard') {
      this.googleAnalytics.trackEvent(' Unauthenticated View IDCard', 'Home Screen', 'Unauthenticated view ID  Click', 200);
    } else if (actionType === 'payment') {
      this.googleAnalytics.trackEvent('Unauthenticated Make a payment', 'Home Screen', 'Unauthenticated Make a Payment Click', 200);
       
    // if (this.isPaymentusEnabled === 'true') {
    //   this.router.navigate(['../payments']);
    // } else {
    //   this.router.navigate(['/nologin'], {queryParams: { type: 'pay' }} );
    // }
    } else if (actionType === 'claims') {
      this.googleAnalytics.trackEvent('Unauthenticated View/Report', 'Home Screen', 'Unauthenticated report/view Claim', 200);
    }
  }

}
