import { Injectable, ElementRef } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Keepalive } from '@ng-idle/keepalive';
import { Idle, EventTargetInterruptSource, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { SessionTimeoutComponent } from '../shared/components/modals/session-timeout/session-timeout.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../shared/services/authentication.service';
import { MessagesService } from '../shared/services/messages.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TimeoutService {

  /* Session Timeout Variables */
  idleState = 'NOT_STARTED';
  timedOut = false;
  lastPing?: Date = null;
  sessionTimeoutPopup: NgbModalRef;


  constructor(private route: ActivatedRoute, private router: Router,
      private modalService: NgbModal, private idle: Idle, private keepalive: Keepalive,
      private authService: AuthenticationService, private messagesService: MessagesService) { // private element: ElementRef,
  }

  start() {

    /* Session Timeout initialization */

    // sets an idle timeout of 60 minutes.
    this.idle.setIdle(environment.idletime);
    // sets a timeout period of 10 minutes.
    this.idle.setTimeout(environment.timeout);
    // sets the interrupts like Keydown, scroll, mouse wheel, mouse down, and etc
    /*this.idle.setInterrupts([
      new EventTargetInterruptSource(
        this.element.nativeElement, 'keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll')
     ]);*/
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    if (this.idleState !== 'TIMER_STOPPED') {
      this.idle.onIdleEnd.subscribe(() => {
        this.idleState = 'NO_LONGER_IDLE';
        this.timedOut = true;
      });

      this.idle.onTimeout.subscribe(() => {
        this.idleState = 'TIMED_OUT';
        this.timedOut = true;
        this.logout();
        this.closeProgressForm();
      });

      this.idle.onIdleStart.subscribe(() => {
        this.idleState = 'IDLE_START', this.openProgressForm(1);
      });

      this.idle.onTimeoutWarning.subscribe((countdown: any) => {
        this.idleState = 'IDLE_TIME_IN_PROGRESS';
        this.sessionTimeoutPopup.componentInstance.count = (Math.floor((countdown - 1) / 60) + 1);
        this.sessionTimeoutPopup.componentInstance.progressCount = this.reverseNumber(countdown);
        this.sessionTimeoutPopup.componentInstance.countMinutes = (Math.floor(countdown / 60));
        this.sessionTimeoutPopup.componentInstance.countSeconds = countdown % 60;
      });
    }


     // sets the ping interval to 15 seconds
     this.keepalive.interval(15);

    this.reset();
  }

  stop() {
    this.idle.stop();
    this.idleState = 'TIMER_STOPPED';
    this.timedOut = true;
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  /* Session Timeout Functions */
  reverseNumber(countdown: number) {
    return (environment.timeout - (countdown - 1));
  }



  openProgressForm(count: number) {
    this.sessionTimeoutPopup = this.modalService.open(SessionTimeoutComponent, {
      backdrop: 'static',
      keyboard: false
    });
    this.sessionTimeoutPopup.componentInstance.count = count;
    this.sessionTimeoutPopup.result.then((result: any) => {
      if (result !== '' && 'logout' === result) {
        this.logout();
      } else if (result !== '' && 'continue' === result) {
        this.reset();
        this.closeProgressForm();
      } else {
        this.stop();
        this.closeProgressForm();
      }
    });
  }

  logout() {
    this.stop();
    this.authService.logout();
    this.messagesService.clearAll();
    this.router.navigate(['']);
  }

  closeProgressForm() {
    this.sessionTimeoutPopup.close();
  }

  unsubsribeTimeout() {
    this.idle.stop();
    this.idle.onIdleStart.unsubscribe();
    this.idle.onTimeoutWarning.unsubscribe();
    this.idle.onTimeout.unsubscribe();
    this.idle.onIdleEnd.unsubscribe();
  }

}
