var ValidValuesResponse = /** @class */ (function () {
    function ValidValuesResponse(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return ValidValuesResponse;
}());
export { ValidValuesResponse };
var ValidValuesServiceResponse = /** @class */ (function () {
    function ValidValuesServiceResponse(values) {
        if (values === void 0) { values = {}; }
        Object.assign(this, values);
    }
    return ValidValuesServiceResponse;
}());
export { ValidValuesServiceResponse };
