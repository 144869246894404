import { Action } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { NewUser } from '../../shared/model/newuser.model';


export const ADD_USER = '[NEWUSER] Add';
export const UPDATE_USER = '[NEWUSER] Update';
export const REMOVE_USER = '[NEWUSER] Remove';


// Add User to the State
export class NewUserAdd implements Action {

   readonly type = ADD_USER;

   constructor(public payload: NewUser) {}
}


// Update User in the State
export class NewUserUpdate implements Action {
    readonly type = UPDATE_USER;

   constructor(public payload: NewUser) {}
}


// Remove User from State
export class NewUserRemove implements Action {
    readonly type = REMOVE_USER;

   constructor(public payload: number) {}
}

export type Actions = NewUserAdd | NewUserUpdate | NewUserRemove;

