import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Keepalive } from '@ng-idle/keepalive';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { SessionTimeoutComponent } from '../shared/components/modals/session-timeout/session-timeout.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../shared/services/authentication.service';
import { MessagesService } from '../shared/services/messages.service';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ng-bootstrap/ng-bootstrap/modal/modal";
import * as i3 from "@ng-idle/core/src/idle";
import * as i4 from "@ng-idle/keepalive/src/keepalive";
import * as i5 from "../shared/services/authentication.service";
import * as i6 from "../shared/services/messages.service";
var TimeoutService = /** @class */ (function () {
    function TimeoutService(route, router, modalService, idle, keepalive, authService, messagesService) {
        this.route = route;
        this.router = router;
        this.modalService = modalService;
        this.idle = idle;
        this.keepalive = keepalive;
        this.authService = authService;
        this.messagesService = messagesService;
        /* Session Timeout Variables */
        this.idleState = 'NOT_STARTED';
        this.timedOut = false;
        this.lastPing = null;
    }
    TimeoutService.prototype.start = function () {
        /* Session Timeout initialization */
        var _this = this;
        // sets an idle timeout of 60 minutes.
        this.idle.setIdle(environment.idletime);
        // sets a timeout period of 10 minutes.
        this.idle.setTimeout(environment.timeout);
        // sets the interrupts like Keydown, scroll, mouse wheel, mouse down, and etc
        /*this.idle.setInterrupts([
          new EventTargetInterruptSource(
            this.element.nativeElement, 'keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll')
         ]);*/
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        if (this.idleState !== 'TIMER_STOPPED') {
            this.idle.onIdleEnd.subscribe(function () {
                _this.idleState = 'NO_LONGER_IDLE';
                _this.timedOut = true;
            });
            this.idle.onTimeout.subscribe(function () {
                _this.idleState = 'TIMED_OUT';
                _this.timedOut = true;
                _this.logout();
                _this.closeProgressForm();
            });
            this.idle.onIdleStart.subscribe(function () {
                _this.idleState = 'IDLE_START', _this.openProgressForm(1);
            });
            this.idle.onTimeoutWarning.subscribe(function (countdown) {
                _this.idleState = 'IDLE_TIME_IN_PROGRESS';
                _this.sessionTimeoutPopup.componentInstance.count = (Math.floor((countdown - 1) / 60) + 1);
                _this.sessionTimeoutPopup.componentInstance.progressCount = _this.reverseNumber(countdown);
                _this.sessionTimeoutPopup.componentInstance.countMinutes = (Math.floor(countdown / 60));
                _this.sessionTimeoutPopup.componentInstance.countSeconds = countdown % 60;
            });
        }
        // sets the ping interval to 15 seconds
        this.keepalive.interval(15);
        this.reset();
    };
    TimeoutService.prototype.stop = function () {
        this.idle.stop();
        this.idleState = 'TIMER_STOPPED';
        this.timedOut = true;
    };
    TimeoutService.prototype.reset = function () {
        this.idle.watch();
        this.idleState = 'Started.';
        this.timedOut = false;
    };
    /* Session Timeout Functions */
    TimeoutService.prototype.reverseNumber = function (countdown) {
        return (environment.timeout - (countdown - 1));
    };
    TimeoutService.prototype.openProgressForm = function (count) {
        var _this = this;
        this.sessionTimeoutPopup = this.modalService.open(SessionTimeoutComponent, {
            backdrop: 'static',
            keyboard: false
        });
        this.sessionTimeoutPopup.componentInstance.count = count;
        this.sessionTimeoutPopup.result.then(function (result) {
            if (result !== '' && 'logout' === result) {
                _this.logout();
            }
            else if (result !== '' && 'continue' === result) {
                _this.reset();
                _this.closeProgressForm();
            }
            else {
                _this.stop();
                _this.closeProgressForm();
            }
        });
    };
    TimeoutService.prototype.logout = function () {
        this.stop();
        this.authService.logout();
        this.messagesService.clearAll();
        this.router.navigate(['']);
    };
    TimeoutService.prototype.closeProgressForm = function () {
        this.sessionTimeoutPopup.close();
    };
    TimeoutService.prototype.unsubsribeTimeout = function () {
        this.idle.stop();
        this.idle.onIdleStart.unsubscribe();
        this.idle.onTimeoutWarning.unsubscribe();
        this.idle.onTimeout.unsubscribe();
        this.idle.onIdleEnd.unsubscribe();
    };
    TimeoutService.ngInjectableDef = i0.defineInjectable({ factory: function TimeoutService_Factory() { return new TimeoutService(i0.inject(i1.ActivatedRoute), i0.inject(i1.Router), i0.inject(i2.NgbModal), i0.inject(i3.Idle), i0.inject(i4.Keepalive), i0.inject(i5.AuthenticationService), i0.inject(i6.MessagesService)); }, token: TimeoutService, providedIn: "root" });
    return TimeoutService;
}());
export { TimeoutService };
