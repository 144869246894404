import { ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PaymentService } from '../../../services/api/payment/payment.service';
import { filter, map } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { PaymentusIframePaymentMethodType } from '../../../constants/billing.constants';
import { LiveAnnouncer } from '@angular/cdk/a11y';
var PaymentusPaymentMethodDialogComponent = /** @class */ (function () {
    function PaymentusPaymentMethodDialogComponent(data, paymentService, dialog, liveAnnouncer) {
        var _this = this;
        this.data = data;
        this.paymentService = paymentService;
        this.dialog = dialog;
        this.liveAnnouncer = liveAnnouncer;
        this.CLOSE = 'close';
        this.OPEN = 'open';
        this.OPEN_CARD = 'collapsed link';
        this.CLOSE_CARD = 'expanded link';
        this.isAuthoriseInfoOpened = false;
        this.isSpinnerOn = true;
        this.authoriseInfoAnimationState = this.CLOSE;
        this.iFrameHeight = 0;
        this.FRAME_HEIGHT = 'frameHeight';
        this.selectedType = 'DD';
        this.EVENT_NAME = 'message';
        this.PAYMENTUS_IO = 'paymentus';
        this.PM_DETAILS = 'pmDetails';
        this.paymentusEvent$ = fromEvent(window, this.EVENT_NAME).pipe(filter(function (event) {
            return event.origin.includes(_this.PAYMENTUS_IO);
        }));
        this.tabs = [
            {
                label: 'Bank',
                type: 'DD',
                paymentType: PaymentusIframePaymentMethodType.DIRECT_DEBIT,
                url: '',
                heightOffset: 30,
            },
            {
                label: 'Credit/Debit Card',
                type: 'CC',
                paymentType: PaymentusIframePaymentMethodType.CREDIT_CARD,
                url: '',
                heightOffset: 30,
            },
        ];
    }
    PaymentusPaymentMethodDialogComponent.prototype.ngOnInit = function () {
        if (this.isSpinnerOn)
            this.liveAnnouncer.announce("Spinner is loading");
        this.changePaymentTypeDebitToCredit();
        this.getPaymentUsIframes();
        this.setIFrameHeightAndCancelSpinner();
        this.setIframesVisibility();
        this.selectDefaultTab();
        this.labelForCardArrow = "Information Storage Authorization " + (this.isAuthoriseInfoOpened ? this.CLOSE_CARD : this.OPEN_CARD);
        this.closeIFrameDialog();
    };
    PaymentusPaymentMethodDialogComponent.prototype.toggleAuthoriseInfo = function () {
        this.isAuthoriseInfoOpened = !this.isAuthoriseInfoOpened;
        this.authoriseInfoAnimationState = this.isAuthoriseInfoOpened ? this.OPEN : this.CLOSE;
        this.labelForCardArrow = "Information Storage Authorization " + (this.isAuthoriseInfoOpened ? this.CLOSE_CARD : this.OPEN_CARD);
    };
    PaymentusPaymentMethodDialogComponent.prototype.changePaymentTypeDebitToCredit = function () {
        if (this.data.paymentType === PaymentusIframePaymentMethodType.DEBIT_CARD) {
            this.data.paymentType = PaymentusIframePaymentMethodType.CREDIT_CARD;
        }
    };
    PaymentusPaymentMethodDialogComponent.prototype.getPaymentUsIframes = function () {
        var _this = this;
        this.tabs.forEach(function (tab) {
            var payload = {
                firstName: _this.data.firstName,
                lastName: _this.data.lastName,
                ownerId: _this.data.ownerId,
                pmCategory: tab.paymentType,
                postMessagePmDetailsOrigin: _this.data.postMessagePmDetailsOrigin,
                method: _this.data.autoPay ? "AutoPay" : (_this.data.addOrEdit == "Add" ? "AddPaymentMethod" : "EditPaymentMethod"),
                paymentToken: _this.data.paymentToken
            };
            _this.paymentService.getPaymentusIframe(payload).subscribe(function (paymentUsIframeResponse) {
                //if (tab.type === paymentUsIframeResponse.paymentType) {
                tab.url = paymentUsIframeResponse.url;
                //}
            });
        });
    };
    PaymentusPaymentMethodDialogComponent.prototype.setIframesVisibility = function () {
        var _this = this;
        if (this.data.paymentType) {
            this.tabs = this.tabs.filter(function (tab) { return _this.data.paymentType === tab.paymentType; });
        }
    };
    PaymentusPaymentMethodDialogComponent.prototype.selectDefaultTab = function () {
        var _this = this;
        if (this.data.paymentType) {
            this.tabs.forEach(function (tab, i) {
                if (tab.type === _this.data.paymentType) {
                    _this.selectedType = _this.tabs[i].type;
                }
            });
        }
    };
    PaymentusPaymentMethodDialogComponent.prototype.onSelectedTabChange = function (event) {
        this.selectedType = this.tabs[event.index].type;
        this.tcSection.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
    PaymentusPaymentMethodDialogComponent.prototype.getPaymentusHeight = function () {
        var _this = this;
        return this.paymentusEvent$.pipe(filter(function (event) {
            var eventData = JSON.stringify(event.data);
            var hasHeight = eventData.includes(_this.FRAME_HEIGHT);
            return hasHeight && +event.data.split(':')[1] > 0;
        }), map(function (message) {
            return +message.data.split(':')[1];
        }));
    };
    PaymentusPaymentMethodDialogComponent.prototype.setIFrameHeightAndCancelSpinner = function () {
        var _this = this;
        this.iFrameSubscription = this.getPaymentusHeight().subscribe(function (height) {
            _this.isSpinnerOn = false;
            if (!_this.isSpinnerOn)
                _this.liveAnnouncer.announce("Loading spinner closed");
            _this.iFrameHeight = height;
        });
    };
    PaymentusPaymentMethodDialogComponent.prototype.closeIFrameDialog = function () {
        var _this = this;
        this.paymentusEventSubscription = this.paymentusEvent$.pipe(filter(function (event) {
            var eventData = JSON.stringify(event.data);
            return eventData.includes(_this.PM_DETAILS);
        }))
            .subscribe(function (event) {
            try {
                if (event.data.includes(_this.PM_DETAILS))
                    _this.dialog.close();
            }
            catch (_a) { }
        });
    };
    PaymentusPaymentMethodDialogComponent.prototype.ngOnDestroy = function () {
        if (this.iFrameSubscription)
            this.iFrameSubscription.unsubscribe();
        if (this.paymentusEventSubscription)
            this.paymentusEventSubscription.unsubscribe();
    };
    return PaymentusPaymentMethodDialogComponent;
}());
export { PaymentusPaymentMethodDialogComponent };
