export var CREATE_ENDORSEMENTINFO = '[ENDORSEMENTINFO] Add';
export var UPDATE_ENDORSEMENTINFO = '[ENDORSEMENTINFO] Update';
export var REMOVE_ENDORSEMENTINFO = '[ENDORSEMENTINFO] Remove';
// Create Endorsement Info upon User initiates the endorsment
var CreateEndorsementInfo = /** @class */ (function () {
    function CreateEndorsementInfo(payload) {
        this.payload = payload;
        this.type = CREATE_ENDORSEMENTINFO;
    }
    return CreateEndorsementInfo;
}());
export { CreateEndorsementInfo };
// Update Login Info
var UpdateEndorsementInfo = /** @class */ (function () {
    function UpdateEndorsementInfo(payload) {
        this.payload = payload;
        this.type = UPDATE_ENDORSEMENTINFO;
    }
    return UpdateEndorsementInfo;
}());
export { UpdateEndorsementInfo };
// Remove LoginInfo upon User Logout
var RemoveEndorsementInfo = /** @class */ (function () {
    function RemoveEndorsementInfo(payload) {
        this.payload = payload;
        this.type = REMOVE_ENDORSEMENTINFO;
    }
    return RemoveEndorsementInfo;
}());
export { RemoveEndorsementInfo };
