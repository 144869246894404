import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Animations } from './paymentus-payment-method.animations'
import { PaymentService } from '../../../services/api/payment/payment.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { filter, map } from 'rxjs/operators';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { PaymentusPayloadModel } from '../../../shared/model/api/payment/paymentus.model';
import { PaymentusIframePaymentMethodType } from '../../../constants/billing.constants';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'app-paymentus-payment-method-dialog',
  templateUrl: './paymentus-payment-method-dialog.component.html',
  styleUrls: ['./paymentus-payment-method-dialog.component.scss'],
  animations: [Animations.smoothCollapse],
})

export class PaymentusPaymentMethodDialogComponent {
  @ViewChild('tcSection') tcSection: ElementRef;
  private readonly CLOSE = 'close';
  private readonly OPEN = 'open';
  private readonly OPEN_CARD = 'collapsed link';
  private readonly CLOSE_CARD = 'expanded link';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private paymentService: PaymentService,
    public dialog: MatDialogRef<PaymentusPaymentMethodDialogComponent>,
    private liveAnnouncer: LiveAnnouncer,
  ) { }

  iFrameSubscription: Subscription;
  paymentusEventSubscription: Subscription;
  labelForCardArrow: string;
  isAuthoriseInfoOpened = false;
  isSpinnerOn = true;
  authoriseInfoAnimationState = this.CLOSE;
  iFrameHeight = 0;
  FRAME_HEIGHT = 'frameHeight';
  selectedType: string = 'DD';
  EVENT_NAME = 'message';
  PAYMENTUS_IO = 'paymentus';
  PM_DETAILS = 'pmDetails';

  paymentusEvent$ = fromEvent(window, this.EVENT_NAME).pipe(
    filter((event: MessageEvent) => {
      return event.origin.includes(this.PAYMENTUS_IO)
    }),
  );

  ngOnInit(): void {
    if(this.isSpinnerOn) this.liveAnnouncer.announce("Spinner is loading");
    this.changePaymentTypeDebitToCredit();
    this.getPaymentUsIframes();
    this.setIFrameHeightAndCancelSpinner();
    this.setIframesVisibility();
    this.selectDefaultTab();
    this.labelForCardArrow = `Information Storage Authorization ${this.isAuthoriseInfoOpened ? this.CLOSE_CARD : this.OPEN_CARD}`;
    this.closeIFrameDialog();
  }

  tabs = [
    {
      label: 'Bank',
      type: 'DD',
      paymentType: PaymentusIframePaymentMethodType.DIRECT_DEBIT,
      url: '',
      heightOffset: 30,
    },
    {
      label: 'Credit/Debit Card',
      type: 'CC',
      paymentType: PaymentusIframePaymentMethodType.CREDIT_CARD,
      url: '',
      heightOffset: 30,
    },
  ];

  toggleAuthoriseInfo(): void {
    this.isAuthoriseInfoOpened = !this.isAuthoriseInfoOpened;
    this.authoriseInfoAnimationState = this.isAuthoriseInfoOpened ? this.OPEN : this.CLOSE;
    this.labelForCardArrow = `Information Storage Authorization ${this.isAuthoriseInfoOpened ? this.CLOSE_CARD : this.OPEN_CARD
      }`;
  }

  changePaymentTypeDebitToCredit(): void {
    if (this.data.paymentType === PaymentusIframePaymentMethodType.DEBIT_CARD) {
      this.data.paymentType = PaymentusIframePaymentMethodType.CREDIT_CARD;
    }
  }

  getPaymentUsIframes() {
    this.tabs.forEach(tab => {
      const payload: PaymentusPayloadModel = {
        firstName: this.data.firstName,
        lastName: this.data.lastName,
        ownerId: this.data.ownerId,
        pmCategory: tab.paymentType,
        postMessagePmDetailsOrigin: this.data.postMessagePmDetailsOrigin,
        method: this.data.autoPay ? "AutoPay" : (this.data.addOrEdit == "Add" ? "AddPaymentMethod" : "EditPaymentMethod"),
        paymentToken: this.data.paymentToken
      }
      this.paymentService.getPaymentusIframe(payload).subscribe(paymentUsIframeResponse => {
        //if (tab.type === paymentUsIframeResponse.paymentType) {
        tab.url = paymentUsIframeResponse.url;
        //}
      })
    })
  }

  setIframesVisibility(): void {
    if (this.data.paymentType) {
      this.tabs = this.tabs.filter((tab) => this.data.paymentType === tab.paymentType);
    }
  }

  selectDefaultTab(): void {
    if (this.data.paymentType) {
      this.tabs.forEach((tab, i) => {
        if (tab.type === this.data.paymentType) {
          this.selectedType = this.tabs[i].type;
        }
      });
    }
  }

  onSelectedTabChange(event: MatTabChangeEvent): void {
    this.selectedType = this.tabs[event.index].type;
    this.tcSection.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  getPaymentusHeight(): Observable<number> {
    return this.paymentusEvent$.pipe(
      filter((event: MessageEvent) => {
        const eventData = JSON.stringify(event.data);
        const hasHeight = eventData.includes(this.FRAME_HEIGHT);
        return hasHeight && +event.data.split(':')[1] > 0;
      }),
      map((message: MessageEvent) => {
        return +message.data.split(':')[1];
      }),
    );
  }

  setIFrameHeightAndCancelSpinner(): void {
    this.iFrameSubscription = this.getPaymentusHeight().subscribe((height) => {
      this.isSpinnerOn = false;
      if(!this.isSpinnerOn) this.liveAnnouncer.announce("Loading spinner closed");
      this.iFrameHeight = height;
    });
  }

  closeIFrameDialog() {
    this.paymentusEventSubscription = this.paymentusEvent$.pipe(
      filter((event: MessageEvent) => {
        const eventData = JSON.stringify(event.data);
        return eventData.includes(this.PM_DETAILS);
      }),
    )
      .subscribe((event: MessageEvent) => {
        try {
          if (event.data.includes(this.PM_DETAILS)) this.dialog.close();
        }
        catch { }
      })
  }

  ngOnDestroy() {
    if (this.iFrameSubscription) this.iFrameSubscription.unsubscribe();
    if (this.paymentusEventSubscription) this.paymentusEventSubscription.unsubscribe();
  }

}
