import { environment } from '../../../environments/environment';
import { GlobalConstants } from '../../constants/global.constant';
import { FindPHRequest } from '../../shared/model/api/policyholder/findph.model';
import { RemovePHRequest } from '../../shared/model/api/policyholder/removeph.model';
import { BWError } from '../../shared/model/error.model';
import { Policynumber } from '../../shared/model/policynumber';
import { PolicyholderService } from '../api/policyholder/policyholder.service';
import { BWErrorhandler } from '../errorhandler';
import * as i0 from "@angular/core";
import * as i1 from "../errorhandler";
import * as i2 from "../api/policyholder/policyholder.service";
var LoginService = /** @class */ (function () {
    function LoginService(errorHandler, phService) {
        this.errorHandler = errorHandler;
        this.phService = phService;
    }
    LoginService.prototype.forgotUserID = function (bwpolnum) {
        return new Promise(function (resolve, reject) {
            // invoke API level services here
        });
    };
    LoginService.prototype.forgotPassword = function (forgotPHPasswordRequest) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.phService.forgotPHPassword(forgotPHPasswordRequest).
                subscribe(function (forgotPwdRes) {
                // Tracker.log('forgotPassword response :: ', forgotPwdRes);
                if (forgotPwdRes !== null && forgotPwdRes.responseCode === '-1') {
                    // Tracker.log('Customer found....');
                }
                else {
                    // Tracker.log('The services retured something else....', forgotPwdRes);
                    return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '100',
                        'errordesc': 'Unable to locate your registration If the problem persists,please call Customer Service at ' +
                            GlobalConstants.SERVICE_OPS_NUMBER,
                        'source': 'LoginService' })));
                }
                resolve(forgotPwdRes);
            }, function (error) {
                // Tracker.log('Error Occurred in forgotPassword', error);
                return reject(error);
            });
        });
    };
    LoginService.prototype.verifySecurityAnswers = function (checkAnswersRequest) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.phService.checkAnswers(checkAnswersRequest).
                subscribe(function (checkAnsRes) {
                // Tracker.log('verifySecurityAnswers response :: ', checkAnsRes);
                if (checkAnsRes !== null && checkAnsRes.responseCode === '-1') {
                    // Tracker.log('Answers verified....');
                }
                else {
                    // Tracker.log('The services retured something else....', checkAnsRes);
                }
                resolve(checkAnsRes);
            }, function (error) {
                // Tracker.log('Error Occurred in verifySecurityAnswers', error);
                return reject(error);
            });
        });
    };
    LoginService.prototype.changePassword = function (changePHPasswordRequest) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.phService.changePassword(changePHPasswordRequest).
                subscribe(function (changePwdRes) {
                // Tracker.log('changePassword response :: ', changePwdRes);
                if (changePwdRes !== null && (changePwdRes.responseCode === '-1' || changePwdRes.responseCode === 'S')) {
                    // Tracker.log('Password Updated Successfully....');
                }
                else {
                    // Tracker.log('The services retured something else....', changePwdRes);
                    return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '100', 'errordesc': 'Unable to Update the password', 'source': 'LoginService' })));
                }
                resolve(changePwdRes);
            }, function (error) {
                // Tracker.log('Error Occurred in changePassword', error);
                return reject(error);
            });
        });
    };
    LoginService.prototype.resetPassword = function (resetPHPasswordRequest) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.phService.resetPassword(resetPHPasswordRequest).
                subscribe(function (resetPwdRes) {
                // Tracker.log('changePassword response :: ', resetPwdRes);
                if (resetPwdRes !== null && resetPwdRes.responseCode === '-1') {
                    // Tracker.log('Password Updated Successfully....');
                }
                else {
                    // Tracker.log('The services retured something else....', resetPwdRes);
                    return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': '100', 'errordesc': 'Unable to Update the password', 'source': 'LoginService' })));
                }
                resolve(resetPwdRes);
            }, function (error) {
                // Tracker.log('Error Occurred in resetPassword', error);
                return reject(error);
            });
        });
    };
    LoginService.prototype.processForgotUserIdWf = function (policyNum) {
        var _this = this;
        // Tracker.log('IN Policy Holder Service processForgotUserIdWf()');
        return new Promise(function (resolve, reject) {
            policyNum = policyNum.toLocaleUpperCase();
            // Tracker.log('start calling service operation getPolicyMco');
            // service call for getPolicyMCO
            _this.phService.getPolicyMco(policyNum).subscribe(function (mco) {
                if (mco != null) {
                    _this.policyNumber = new Policynumber(policyNum.toLocaleUpperCase());
                    _this.findPhReq = new FindPHRequest();
                    _this.findPhReq.pointHookID = mco.mco + _this.policyNumber.symbol + _this.policyNumber.policy;
                    // Tracker.log('start calling service operation findPH for ' + this.findPhReq.pointHookID);
                    // service call for findPh operation
                    _this.phService.findPH(_this.findPhReq).subscribe(function (phResponse) {
                        if (phResponse && (phResponse.responseCode === '-1' || phResponse.responseCode == 'S')) {
                            if (environment.bwOktaEnabled) {
                                _this.removePhReq = new RemovePHRequest();
                                _this.removePhReq.email = btoa(phResponse.email); // encoding it with Base64
                                //this.removePhReq.adminPassword = environment.removePhAdminPassword;--> not required to pass
                            }
                            else {
                                _this.removePhReq = new RemovePHRequest();
                                _this.removePhReq.email = phResponse.email;
                                _this.removePhReq.adminPassword = environment.removePhAdminPassword;
                            }
                            // Tracker.log('start calling service operation removePH for ' + phResponse.email);
                            // service call for removePH
                            _this.phService.removePH(_this.removePhReq).subscribe(function (removePhRes) {
                                if (removePhRes && (removePhRes.removeStatusCode === '-1' || removePhRes.removeStatusCode === '26' || phResponse.responseCode == 'S')) {
                                    // Tracker.log('Successfully removed policy holder', removePhRes);
                                    return resolve('Successfully removedPh');
                                }
                                else {
                                    // Tracker.log('removePh failed...');
                                    return reject('Policy Number ' + policyNum +
                                        ' is not registered.  Please re-enter your Policy Number.  If the problem persists, please call' +
                                        ' Customer Service at 1-888-888-0080');
                                }
                            });
                        }
                        else {
                            // Tracker.log('findPh failed...');
                            return reject('Your policy in not registered. Please select New User to register your policy.');
                        }
                    }, function (error) {
                        if (error.error.responseCode === 'E') {
                            return reject('Your policy in not registered. Please select New User to register your policy.');
                        }
                    });
                }
                else {
                    // Tracker.log('getPolicyMco failed...');
                    return reject('Your policy in not registered. Please select New User to register your policy.');
                }
            }, function (error) {
                return reject(error);
            });
        });
    };
    LoginService.ngInjectableDef = i0.defineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.inject(i1.BWErrorhandler), i0.inject(i2.PolicyholderService)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
