import { HttpParams, HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../../../constants/global.constant';
import { BwappService } from '../../bwapp.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../bwapp.service";
var EdmrService = /** @class */ (function () {
    function EdmrService(http, appService) {
        this.http = http;
        this.appService = appService;
        this.globlalConstants = GlobalConstants;
    }
    EdmrService.prototype.invokeEDMRDocuments = function (policynumber, transactioncode, srcsystem) {
        var params = new HttpParams()
            .set('policyNum', policynumber)
            .set('transactionCode', transactioncode)
            .set('sourceSystem', srcsystem);
        return this.appService.get(this.globlalConstants.EDMR_INVOKEDOCUMENTS, params);
    };
    EdmrService.prototype.getDocument = function (seqNumber) {
        var params = new HttpParams()
            .set('seqNumber', seqNumber);
        return this.appService.get(this.globlalConstants.EDMR_GETDOCUMENT, params);
    };
    EdmrService.prototype.getPolicyContract = function (url) {
        var params = new HttpParams()
            .set('url', url);
        return this.appService.get(this.globlalConstants.EDMR_GETPOLICYCONTRACT, params);
    };
    EdmrService.prototype.uploadDocument = function (saveDocumentReq, currentFileUpload) {
        // : Observable<HttpEvent<{}>> {
        var fileData = new FormData();
        fileData.append('uploadDoc', currentFileUpload, currentFileUpload.name);
        fileData.append('saveDocumentReq', new Blob([JSON.stringify(saveDocumentReq)], {
            type: 'application/json'
        }));
        /*const apipath =  GlobalConstants.UPLOAD_DOCUMENT;
        const req = new HttpRequest('POST', `${environment.apiServiceUrl}${apipath}`, fileData,  {
          reportProgress: true
        });
    
       return this.http.request(req);*/
        return this.appService.postwithmultiparams(this.globlalConstants.UPLOAD_DOCUMENT, fileData);
    };
    EdmrService.ngInjectableDef = i0.defineInjectable({ factory: function EdmrService_Factory() { return new EdmrService(i0.inject(i1.HttpClient), i0.inject(i2.BwappService)); }, token: EdmrService, providedIn: "root" });
    return EdmrService;
}());
export { EdmrService };
