import { HttpParams } from '@angular/common/http';
import { GlobalConstants } from '../../../constants/global.constant';
import { BwappService } from '../../bwapp.service';
import * as i0 from "@angular/core";
import * as i1 from "../../bwapp.service";
var PolicydetailsrestService = /** @class */ (function () {
    function PolicydetailsrestService(appService) {
        this.appService = appService;
        this.globlalConstants = GlobalConstants;
    }
    PolicydetailsrestService.prototype.getViolations = function (policynumber, mco) {
        // return this.appService.getlocaldata('assets/data/temp.json');
        var params = new HttpParams()
            .set('policyNumber', policynumber)
            .set('mco', mco);
        return this.appService.get(this.globlalConstants.GET_VIOLATIONDETAILS, params);
    };
    PolicydetailsrestService.prototype.getDrivers = function (policynumber, mco) {
        // return this.appService.getlocaldata('assets/data/temp2.json');
        var params = new HttpParams()
            .set('policyNumber', policynumber)
            .set('mco', mco);
        return this.appService.get(this.globlalConstants.GET_DRIVERDETAILS, params);
    };
    PolicydetailsrestService.prototype.getVehicleFullDetails = function (policynumber, mco) {
        var params = new HttpParams()
            .set('policyNumber', policynumber)
            .set('mco', mco);
        return this.appService.get(this.globlalConstants.GET_VEHICLEDETAILS, params);
    };
    PolicydetailsrestService.prototype.getDiscounts = function (policynumber, mco) {
        var params = new HttpParams()
            .set('policyNumber', policynumber)
            .set('mco', mco);
        return this.appService.get(this.globlalConstants.GET_DISCOUNTDETAILS, params);
    };
    PolicydetailsrestService.prototype.getPolicyCoverages = function (policynumber, mco) {
        // const coveragesPath = 'assets/data/coverages.json';
        // return this.appService.getlocaldata(coveragesPath);
        var params = new HttpParams()
            .set('policyNumber', policynumber)
            .set('mco', mco);
        return this.appService.get(this.globlalConstants.GET_POLICYCOVERAGES, params);
    };
    PolicydetailsrestService.prototype.getLeinholderInfo = function (policynumber, mco) {
        var params = new HttpParams()
            .set('policyNumber', policynumber)
            .set('mco', mco);
        return this.appService.get(this.globlalConstants.GET_LEINHOLDERDETAILS, params);
    };
    PolicydetailsrestService.prototype.getPolicyHolderInfo = function (policynumber, mco) {
        var params = new HttpParams()
            .set('policyNumber', policynumber)
            .set('mco', mco);
        return this.appService.get(this.globlalConstants.GET_POLICYHOLDERINFO, params);
    };
    PolicydetailsrestService.prototype.getRequestToCancelChecks = function (policynumber, mco) {
        var params = new HttpParams()
            .set('policyNumber', policynumber)
            .set('mco', mco);
        return this.appService.get(this.globlalConstants.GET_CANCELPOLICYCHECKS, params);
    };
    PolicydetailsrestService.prototype.getRequestToCancelAmount = function (policynumber, mco, canceldate) {
        var params = new HttpParams()
            .set('policyNumber', policynumber)
            .set('mco', mco)
            .set('cancelDate', canceldate);
        return this.appService.get(this.globlalConstants.GET_CANCELPOLICYAMOUNT, params);
    };
    PolicydetailsrestService.prototype.createDocRequestInEDMR = function (policynumber, mco) {
        var params = new HttpParams()
            .set('policyNumber', policynumber)
            .set('mco', mco);
        return this.appService.get(this.globlalConstants.GET_CANCEL_CREATE_EDMR_RECORD, params);
    };
    PolicydetailsrestService.prototype.getVinDupCheckStatus = function (agentCode, vin) {
        var params = new HttpParams()
            .set('agentCode', agentCode)
            .set('vin', vin);
        return this.appService.get(this.globlalConstants.GET_VIN_DUP_CHECK_STATUS, params);
    };
    PolicydetailsrestService.ngInjectableDef = i0.defineInjectable({ factory: function PolicydetailsrestService_Factory() { return new PolicydetailsrestService(i0.inject(i1.BwappService)); }, token: PolicydetailsrestService, providedIn: "root" });
    return PolicydetailsrestService;
}());
export { PolicydetailsrestService };
