export class PaymentOptionType {
    paymentMoniker: string;
    paymentType: string;
    paymentVendor: string;
    nativePaymentType: any[];
    attributes: any[];

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
