import { GlobalConstants } from '../../constants/global.constant';
import { MessageConstants } from '../../constants/message.constant';
import { BWError } from '../../shared/model/error.model';
import { GoogleAnalyticsService } from '../../shared/services/analytics/google-analytics.service';
import { BwstoreService } from '../../shared/services/bwstore.service';
import { AddresstoolsService } from '../api/addrtools/addresstools.service';
import { PolicydetailsrestService } from '../api/policy/policydetailsrest.service';
import { PolicymgtService } from '../api/policymgmt/policymgt.service';
import { BWErrorhandler } from '../errorhandler';
import { formatDate } from '@angular/common';
import { Tracker } from '../../shared/utils/tracker';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/bwstore.service";
import * as i2 from "../../shared/services/analytics/google-analytics.service";
import * as i3 from "../errorhandler";
import * as i4 from "../api/addrtools/addresstools.service";
import * as i5 from "../api/policymgmt/policymgt.service";
import * as i6 from "../api/policy/policydetailsrest.service";
var RequesttocancelService = /** @class */ (function () {
    function RequesttocancelService(locale, _storeService, googleAnalytics, errorHandler, addressService, policyMgmtService, policyDetailsService) {
        this.locale = locale;
        this._storeService = _storeService;
        this.googleAnalytics = googleAnalytics;
        this.errorHandler = errorHandler;
        this.addressService = addressService;
        this.policyMgmtService = policyMgmtService;
        this.policyDetailsService = policyDetailsService;
        this.globlalConstants = GlobalConstants;
    }
    RequesttocancelService.prototype.calculateCancelAmount = function (cancelCenturyDate, mco, policyNum) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.policyDetailsService.getRequestToCancelAmount(policyNum, mco, cancelCenturyDate)
                .subscribe(function (res) {
                if (res && res.Result.toUpperCase() === 'SUCCESS') {
                    _this.googleAnalytics.trackEvent('RequestCancel', 'RequestToCancelScreen', 'CalculateRefundAmountDueSuccess', 200);
                    resolve(res.RequestToCancelAmount);
                }
                else {
                    _this.googleAnalytics.trackEvent('RequestCancel', 'RequestToCancelScreen', 'CalculateRefundAmountDueError', 200);
                    return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'RequestToCancelAmtCalc', 'errordesc': MessageConstants.CANCEL_AMOUNT_CALC_ERROR,
                        'source': 'CalculateAmountService' })));
                }
            }, function (error) {
                return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'RequestToCancelAmtCalc', 'errordesc': MessageConstants.CANCEL_AMOUNT_CALC_ERROR,
                    'source': 'CalculateAmountService' })));
            });
        });
    };
    RequesttocancelService.prototype.scrubAddress = function (address, city, state, zip) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.addressService.scrubAddress(address, city, state, zip).subscribe(function (res) {
                Tracker.loginfo('RequesttocancelService', 'scrubAddress', 'scrubAddress Invoked ', JSON.stringify(res));
                if (res.status === 'FOUND_ADDRESS_CORRECT') {
                    if (res.parsedAddresses[0].state.toUpperCase().trim() !== state.toUpperCase().trim()) {
                        _this.googleAnalytics.trackEvent('RequestCancel', 'RequestToCancelScreen', 'Mailing Address not correct info', 200);
                        return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'RequestToCancelAddrScrub',
                            'errordesc': MessageConstants.CANCEL_ADDR_STATE_ERROR.replace('{0}', res.parsedAddresses[0].state),
                            'source': 'AddressToolsService' })));
                    }
                    else if (res.parsedAddresses[0].city.toUpperCase().trim() !== city.toUpperCase().trim()) {
                        _this.googleAnalytics.trackEvent('RequestCancel', 'RequestToCancelScreen', 'Mailing Address not correct info', 200);
                        return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'RequestToCancelAddrScrub',
                            'errordesc': MessageConstants.CANCEL_ADDR_CITY_ERROR.replace('{0}', res.parsedAddresses[0].city),
                            'source': 'AddressToolsService' })));
                    }
                    else {
                        resolve(true);
                    }
                }
                else if (res.status === 'FOUND_ADDRESS_INCORRECT_ZIP' && res.parsedAddresses[0].zipCode.trim() !== zip.trim()) {
                    _this.googleAnalytics.trackEvent('RequestCancel', 'RequestToCancelScreen', 'Mailing Address not correct info', 200);
                    return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'RequestToCancelAddrScrub',
                        'errordesc': MessageConstants.CANCEL_ADDR_ZIP_ERROR.replace('{0}', res.parsedAddresses[0].zipCode),
                        'source': 'AddressToolsService' })));
                }
                else if (res.status === 'SERVICE_FAILURE') {
                    return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'RequestToCancelAddrScrub',
                        'errordesc': 'Scrubing service failure',
                        'source': 'AddressToolsService' })));
                }
                else {
                    _this.googleAnalytics.trackEvent('RequestCancel', 'RequestToCancelScreen', 'Mailing Address bad Scrub', 200);
                    return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'RequestToCancelAddrScrub',
                        'errordesc': res.addressResultDescription,
                        'source': 'AddressToolsService' })));
                }
            });
        });
    };
    RequesttocancelService.prototype.requestToCancel = function (policyNum, cancelDate, cancelCenturyDate, mco, riskState, isMailingAddressChanged, addressLine1, city, state, zip, eftIndicator, requestToCancelInfo) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.policyMgmtService.requestCancel(policyNum, cancelDate, mco, riskState, isMailingAddressChanged, requestToCancelInfo.cancelChecks.IsShortRateState, addressLine1, city, state, zip).subscribe(function (res) {
                Tracker.loginfo('RequesttocancelService', 'requestToCancel', 'requestCancel Invoked ', JSON.stringify(res));
                if (res.status === '0') {
                    requestToCancelInfo.cancelDate = cancelDate;
                    var formattedCancelDate = formatDate(cancelDate, 'MM/dd/yyyy', _this.locale);
                    var formattedCurrentDate = formatDate(new Date(), 'MM/dd/yyyy', _this.locale);
                    if (formattedCancelDate !== formattedCurrentDate) {
                        requestToCancelInfo.cancelTime = '12:01 AM';
                    }
                    else {
                        requestToCancelInfo.cancelTime = formatDate(new Date(), 'hh:mm a', _this.locale);
                    }
                    var cancelDateTimstamp_1 = formattedCancelDate.concat(' ').concat(requestToCancelInfo.cancelTime);
                    requestToCancelInfo.confirmationNumber = res.errorMsg;
                    // Call the calculate cancelAmount
                    _this.calculateCancelAmount(cancelCenturyDate, mco, policyNum)
                        .then(function (cancelAmtRes) {
                        Tracker.loginfo('RequesttocancelService', 'requestToCancel', 'calculateCancelAmount Invoked ', JSON.stringify(cancelAmtRes));
                        // Store cancel amount in store
                        requestToCancelInfo.cancelAmount = cancelAmtRes;
                        _this._storeService.updateRequestToCancelPolicyInfo(requestToCancelInfo);
                        var renewalInd = requestToCancelInfo.cancelChecks.HasRenewal ? 'Yes' : 'No';
                        // Call the ExtreamRecordPostCancellation operation
                        _this.policyMgmtService.createExstreamRecordPostCancellation(mco, policyNum, cancelDateTimstamp_1, cancelAmtRes.AmountType.trim() === 'Amount Due' ? cancelAmtRes.cancelAmount : '-' + cancelAmtRes.cancelAmount, res.errorMsg, eftIndicator, renewalInd)
                            .subscribe(function (exstreamres) {
                            Tracker.loginfo('RequesttocancelService', 'requestToCancel', 'createExstreamRecordPostCancellation Invoked ', JSON.stringify(exstreamres));
                            if (exstreamres && exstreamres.status === '0') {
                                // Call Exstream to create a document in EDMR
                                _this.policyDetailsService.createDocRequestInEDMR(policyNum, mco)
                                    .subscribe(function (edmrres) {
                                    Tracker.loginfo('RequesttocancelService', 'requestToCancel', 'createDocRequestInEDMR Invoked ', JSON.stringify(edmrres));
                                    if (edmrres && edmrres.response.toUpperCase() === 'SUCCESS') {
                                        _this.googleAnalytics.trackEvent('RequestCancel', 'RequestToCancelScreen', 'CancelSubmitSuccessful', 200);
                                        resolve(true);
                                    }
                                });
                            }
                            else {
                                _this.googleAnalytics.trackEvent('RequestCancel', 'RequestToCancelScreen', 'Cannot process request Error', 200);
                                return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'RequestToCancel', 'errordesc': MessageConstants.CANCEL_REQUEST_TO_CANCEL_FAILED,
                                    'source': 'PolicyMgmtService' })));
                            }
                        });
                    });
                }
                else {
                    Tracker.loginfo('RequesttocancelService', 'requestToCancel', 'Request To Cancel', JSON.stringify(res));
                    if (res.errorMsg === null || res.errorMsg === '') {
                        _this.googleAnalytics.trackEvent('RequestCancel', 'RequestToCancelScreen', 'Cannot process request Error', 200);
                        return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'RequestToCancel', 'errordesc': MessageConstants.CANCEL_REQUEST_TO_CANCEL_FAILED,
                            'source': 'PolicyMgmtService' })));
                    }
                    else if (res.status === '2') {
                        _this.googleAnalytics.trackEvent('RequestCancel', 'RequestToCancelScreen', 'Pending Transaction Error', 200);
                        return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'RequestToCancel', 'errordesc': res.errorMsg, 'source': 'PolicyMgmtService' })));
                    }
                    else if (res.status === '3') {
                        _this.googleAnalytics.trackEvent('RequestCancel', 'RequestToCancelScreen', 'Future Cancel Error', 200);
                        return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'RequestToCancel', 'errordesc': res.errorMsg, 'source': 'PolicyMgmtService' })));
                    }
                    else {
                        _this.googleAnalytics.trackEvent('RequestCancel', 'RequestToCancelScreen', 'Cannot process request Error', 200);
                        return reject(_this.errorHandler.handleApplicationError(new BWError({ 'errorcode': 'RequestToCancel', 'errordesc': res.errorMsg, 'source': 'PolicyMgmtService' })));
                    }
                }
            });
        });
    };
    RequesttocancelService.ngInjectableDef = i0.defineInjectable({ factory: function RequesttocancelService_Factory() { return new RequesttocancelService(i0.inject(i0.LOCALE_ID), i0.inject(i1.BwstoreService), i0.inject(i2.GoogleAnalyticsService), i0.inject(i3.BWErrorhandler), i0.inject(i4.AddresstoolsService), i0.inject(i5.PolicymgtService), i0.inject(i6.PolicydetailsrestService)); }, token: RequesttocancelService, providedIn: "root" });
    return RequesttocancelService;
}());
export { RequesttocancelService };
