/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./error-redirects.component";
import * as i2 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
var styles_ErrorRedirectsComponent = [];
var RenderType_ErrorRedirectsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ErrorRedirectsComponent, data: {} });
export { RenderType_ErrorRedirectsComponent as RenderType_ErrorRedirectsComponent };
export function View_ErrorRedirectsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.errorModal.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Login Error"])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-warning"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.errorModal.dismiss("Close clicked") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Close "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorType; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.errormsg; _ck(_v, 5, 0, currVal_1); }); }
export function View_ErrorRedirectsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-error-redirects", [], null, null, null, View_ErrorRedirectsComponent_0, RenderType_ErrorRedirectsComponent)), i0.ɵdid(1, 114688, null, 0, i1.ErrorRedirectsComponent, [i2.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorRedirectsComponentNgFactory = i0.ɵccf("app-error-redirects", i1.ErrorRedirectsComponent, View_ErrorRedirectsComponent_Host_0, { errormsg: "errormsg", errorType: "errorType" }, {}, []);
export { ErrorRedirectsComponentNgFactory as ErrorRedirectsComponentNgFactory };
