import { Component, OnInit, Input, Output, EventEmitter, Inject, LOCALE_ID } from '@angular/core';
import { Mailingaddress } from '../../../shared/model/endorsements/addressedit/mailingaddress.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EndorsementService } from '../../../services/api/endorsement/endorsement.service';
import { EndorsementReq } from '../../../shared/model/api/endorsement/endorsement-req';
import { EndorsementRes } from '../../../shared/model/api/endorsement/endorsement-res';
import { EndorseAddressReq } from '../../../shared/model/api/endorsement/endorse-address-req';
import { EndorsePhoneReq } from '../../../shared/model/api/endorsement/endorse-phone-req';
import { BwstoreService } from '../../../shared/services/bwstore.service';
import { FullPolicyDetails, PolicyInfo } from '../../../shared/model/api/bwibic/fullpolicydetails.model';
import { AddresseditService } from '../../../services/addressedit/addressedit.service';
import { environment } from '../../../../environments/environment';
import { GlobalConstants } from '../../../constants/global.constant';
import { CommonUtil } from '../../../shared/utils/commonutil';
import { formatDate } from '@angular/common';
import { Tracker } from '../../../shared/utils/tracker';
import { BWError } from '../../../shared/model/error.model';
import { BWErrorhandler } from '../../../services/errorhandler';
import { MessageConstants } from '../../../constants/message.constant';
import { PolicyHolder } from '../../../shared/model/authentication/authenticatephres';
import { AutopayeftService } from '../../../services/mypolicy/autopayeft.service';
import { FuturePayment } from '../../../shared/model/api/policyeft/eftreturnres.model';
import { GoogleAnalyticsService } from '../../../shared/services/analytics/google-analytics.service';
import { PolicyholderService } from '../../../services/api/policyholder/policyholder.service';
import { VehicleService } from '../../../services/policydetails/vehicle.service';

@Component({
  selector: 'app-mailingaddr-accept',
  templateUrl: './mailingaddr-accept.component.html',
  styleUrls: ['./mailingaddr-accept.component.scss']
})
export class MailingaddrAcceptComponent implements OnInit {

  @Input() mailingAddress: Mailingaddress;
  @Input() addressEditModal: NgbActiveModal;

  @Output() updateStatus = new EventEmitter<string>();
  @Output() updateFuturePayments = new EventEmitter<FuturePayment []>();
  basicPolicyDetails: FullPolicyDetails;
  endorsementReq: EndorsementReq = new EndorsementReq();
  endorseAddressReq: EndorseAddressReq = new EndorseAddressReq();
  endorsePhoneReq: EndorsePhoneReq = new EndorsePhoneReq();
  private globlalConstants = GlobalConstants;
  isLoaded = true;
  policyInfo: PolicyInfo;
  submitted = false;
  showAddress = true;
  showPhoneNumber = false;
  // futurePayments: FuturePayment[];
  skip = false;
  authenticatedPhDetails: PolicyHolder;

  constructor(private endorsementService: EndorsementService,
    private _storeService: BwstoreService,
    private addressEditService: AddresseditService,
    @Inject(LOCALE_ID) private locale: string, private eftService: AutopayeftService,
    private errorHandler: BWErrorhandler, private googleAnalytics: GoogleAnalyticsService,
    private policyholderService: PolicyholderService, private vehicleService: VehicleService) {
    this.basicPolicyDetails = this._storeService.getPolicyInfoFromStore();
    this.policyInfo = this.basicPolicyDetails.policyInfo;
    this.authenticatedPhDetails = this._storeService.getLoginInfoFromStore();
    const policynumber = CommonUtil.getBWPolicyNumber(this.authenticatedPhDetails);
    const mco = CommonUtil.getMCO(this.authenticatedPhDetails);
    const vehicleids =  [];
    this.isLoaded = false;
    this.vehicleService.getVehicleFullDetails(policynumber, mco)
      .subscribe((vehiclesRes) => {
        this.isLoaded = true;
        Tracker.loginfo('MailingaddrAcceptComponent', 'ngOnInit', '',
          'Vehicle response ' + JSON.stringify(vehiclesRes));
        if (vehiclesRes.Result && vehiclesRes.Result.toLocaleLowerCase() === 'success') {
          vehiclesRes.Vehicles.forEach(function (vehicle) {
            vehicleids.push(vehicle.VehicleID);
          });
          this.endorseAddressReq.vehicleId = vehicleids;
        }
      },
        (error) => {
          Tracker.logerror('VehicleReplaceComponent', 'ngOnInit', 'getVehicleFullDetails',
            'Error getting Vehicle details for VehicleReplaceComponent', error);
        });
  }

  ngOnInit() {
    this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressAcceptScreen', 'MailingAddressAcceptScreen', 200);
    this.showAddress = this.mailingAddress.isAddressChanged;
    this.showPhoneNumber = this.mailingAddress.isPhoneNumberChanged;
    Tracker.loginfo('MailingaddrAcceptComponent', 'ngOnInit', '', 'Updated Address :'.concat(this.mailingAddress.street));
  }

  // this function will call service calls like endorse policy, saveChangesToPolicy,EndoseBind,EndorseVoid
  acceptAddressChange() {
    try {
          this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressAcceptScreen', 'MailingAddressAcceptButtonClick', 200);
          this.endorsementReq.policyID = this.basicPolicyDetails.policyInfo.SYMBOL + this.basicPolicyDetails.policyInfo.POLICY0NUM +
            this.basicPolicyDetails.policyInfo.MODULE;
          this.endorsementReq.mco = this.basicPolicyDetails.policyInfo.PolicyMasterCompany;
          this.endorsementReq.pco = this.basicPolicyDetails.policyInfo.PolicyCompany;
          this.endorsementReq.lob = this.basicPolicyDetails.policyInfo.LINE0BUS;
          this.endorsementReq.appName = this.globlalConstants.APPID;
          this.endorsementReq.userID = this.globlalConstants.APPID;
          this.endorsementReq.changeType = '2';
          this.endorsementReq.policyStatus = 'P'; // .basicPolicyDetails.policyInfo.PolicyStatus;
          this.endorsementReq.databaseContext = `${environment.pointContext}`;
          this.endorsementReq.user = this.globlalConstants.APPID;
          this.endorseAddressReq.addressLine1 = this.mailingAddress.street;
          this.endorseAddressReq.city = this.mailingAddress.city;
          this.endorseAddressReq.state = this.mailingAddress.state;
          this.endorseAddressReq.zipCode = this.mailingAddress.zip;
          this.endorsementReq.endoseAddress = this.endorseAddressReq;
          this.endorsePhoneReq.areaCode = this.mailingAddress.phone.split('-')[0];
          this.endorsePhoneReq.phoneOne = this.mailingAddress.phone.split('-')[1];
          this.endorsePhoneReq.phoneTwo = this.mailingAddress.phone.split('-')[2];
          this.endorsementReq.phoneNumber = this.endorsePhoneReq;
          this.endorsementReq.appSource = 8;
          this.endorsementReq.emailDiscount = false;
          this.endorsementReq.applyToRenewal = true;
          this.endorsementReq.endorseContext = `${environment.endorseContext}`;
          this.endorsementReq.producerCode = this.basicPolicyDetails.policyInfo.PolicyAgentCode;
          this.endorsementReq.userType = GlobalConstants.ENDORSE_USER_TYPE;
          this.endorsementReq.symbol = this.policyInfo.SYMBOL;
          this.endorsementReq.policyNum = this.policyInfo.POLICY0NUM;
          this.endorsementReq.mod = this.policyInfo.MODULE;
          this.endorsementReq.state = this.mailingAddress.state;
          this.isLoaded = false;
          if (this.endorseAddressReq.vehicleId && this.endorseAddressReq.vehicleId.length > 0) {

            this.addressEditService.endosePolicy(this.endorsementReq).then((res: EndorsementRes) => {
              if (res && res.status === '0') {
                // invoke save chnages to policy
                this.mailingAddress.bindingNumber = res.confirmationNum;
                this.endorsementReq.binderNumber = res.confirmationNum;
                this.addressEditService.saveChangesToPolicy(this.endorsementReq).then((saveChangesRes: EndorsementRes) => {
                  if (saveChangesRes.status === '0') {
                    this.mailingAddress.premiumAmount = saveChangesRes.premium.toFixed(2);
                    this.mailingAddress.proratedPremium = saveChangesRes.proratedPremium.toFixed(2);
                    // this error message gives the change in the change in the premium amount value
                    this.mailingAddress.errorMsg = saveChangesRes.errorMsg;
                    this.addressEditService.endorseBind(this.endorsementReq).then((endorsebindRes: EndorsementRes) => {
                      if (endorsebindRes.status === '0') {
                        this.addressEditService.getFuturePayments(
                          this.policyInfo.SYMBOL, this.policyInfo.POLICY0NUM, this.policyInfo.MODULE,
                          this.policyInfo.PolicyMasterCompany).then((futurePaymentsRes) => {
                          if (futurePaymentsRes.FuturePaymentList.length > 0) {
                            futurePaymentsRes.FuturePaymentList.forEach(payment => {
                              const formattedDt = formatDate(payment.PayDate, 'MM/dd/yyyy', 'en-US');
                              payment.PayDate = formattedDt;
                            });
                            this.updateFuturePayments.emit(futurePaymentsRes.FuturePaymentList);
                          }
                          // Update the behaviour subject
                          this.addressEditService.endorsementEligibilitySubject.next(false);
                          this.errorHandler.ClearApplicationError();
                          this.updateStatus.emit('success');
                          this.isLoaded = true;
                        }).catch(error => {
                          this.isLoaded = true;
                          // need to remove once properties file updated in QA
                        });

                        this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressAcceptScreen',
                        'MailingAddressChangeCompleted', 200);
                        this.isLoaded = true;
                        // Update the behaviour subject
                        this.addressEditService.endorsementEligibilitySubject.next(false);
                        // this.updateStatus.emit('success');
                      } else {
                        this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressAcceptScreen', 'MailingAddressChangeFailure'
                          , 200);

                        this.addressEditService.endorseVoid(this.endorsementReq).then((endorseVoidRes: EndorsementRes) => {
                          this.isLoaded = true;
                          // Audit Log entry for Failure
                          this.endorsementReq.activityResult = this.globlalConstants.AUDIT_LOG_ACITIVITY_RESULT_FAILURE;
                          this.endorsementReq.activity = this.globlalConstants.AUDIT_LOG_ACITIVITY_ADDRESS_CHANGE;
                          this.endorsementReq.activityMessage = endorsebindRes.errorMsg ;
                          this.endorsementService.createAuditLogRecord(this.endorsementReq).subscribe(
                            auditLogRes => {
                              CommonUtil.scrollToTop();
                              this.errorHandler.handleApplicationError(new BWError(
                                {
                                  'errorcode': 'Endorsement', 'errordesc': MessageConstants.ADDR_AGENT_ERROR_MSG + '<br>' +
                                  'Agent Name :' + this.basicPolicyDetails.agentInfo.AgentName1 + '<br>'
                                  + 'Agent Phone Number :' + this.basicPolicyDetails.agentInfo.ACNM_TELE,
                                  'source': 'Endorsebind'
                                }));
                          });
                        }, (endorseVoidError) => {
                          Tracker.logerror('MailingaddrAcceptComponent', 'Endorse Address', 'acceptAddressChange',
                            'Endorse Void Error.', endorseVoidError);
                          this.isLoaded = true;
                        });
                      }
                    }, (endorseBindError) => {
                      Tracker.logerror('MailingaddrAcceptComponent', 'Endorse Address', 'acceptAddressChange',
                        'Error during endorse bind.', endorseBindError);
                      this.isLoaded = true;
                    });
                  } else {
                    this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressAcceptScreen', 'MailingAddressChangeFailure'
                      , 200);
                    this.addressEditService.endorseVoid(this.endorsementReq).then((endorseVoidRes: EndorsementRes) => {
                      this.isLoaded = true;
                      // Audit Log entry for Failure
                      this.endorsementReq.activityResult = this.globlalConstants.AUDIT_LOG_ACITIVITY_RESULT_FAILURE;
                      this.endorsementReq.activity = this.globlalConstants.AUDIT_LOG_ACITIVITY_ADDRESS_CHANGE;
                      this.endorsementReq.activityMessage = saveChangesRes.errorMsg;
                      this.endorsementService.createAuditLogRecord(this.endorsementReq).subscribe(
                        auditLogRes => {
                          CommonUtil.scrollToTop();
                          this.errorHandler.handleApplicationError(new BWError(
                            {
                              'errorcode': 'Endorsement', 'errordesc': MessageConstants.ADDR_AGENT_ERROR_MSG + '<br>' +
                              'Agent Name :' + this.basicPolicyDetails.agentInfo.AgentName1 + '<br>'
                              + 'Agent Phone Number :' + this.basicPolicyDetails.agentInfo.ACNM_TELE,
                              'source': 'EndorsePolicySaveError'
                            }));
                      });
                    }, (endorseVoidError) => {
                      Tracker.logerror('MailingaddrAcceptComponent', 'Endorse Address', 'acceptAddressChange',
                        'Endorse Void Error.', endorseVoidError);
                      this.isLoaded = true;
                    });
                  }
                }, (saveChnagesError) => {
                  this.isLoaded = true;
                  Tracker.logerror('MailingaddrAcceptComponent', 'Endorse Address', 'acceptAddressChange',
                    'Error during saveChangesToPolicy policy.', saveChnagesError);
                });
              } else {
                this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressAcceptScreen', 'MailingAddressChangeFailure'
                  , 200);

                this.addressEditService.endorseVoid(this.endorsementReq).then((endorseVoidRes: EndorsementRes) => {
                  this.isLoaded = true;
                  // Audit Log entry for Failure
                  this.endorsementReq.activityResult = this.globlalConstants.AUDIT_LOG_ACITIVITY_RESULT_FAILURE;
                  this.endorsementReq.activity = this.globlalConstants.AUDIT_LOG_ACITIVITY_ADDRESS_CHANGE;
                  this.endorsementReq.activityMessage = res.errorMsg ;
                  this.endorsementService.createAuditLogRecord(this.endorsementReq).subscribe(
                    auditLogRes => {
                      CommonUtil.scrollToTop();
                      this.errorHandler.handleApplicationError(new BWError(
                      {
                        'errorcode': 'Endorsement', 'errordesc': MessageConstants.ADDR_AGENT_ERROR_MSG + '<br>' +
                        'Agent Name :' + this.basicPolicyDetails.agentInfo.AgentName1 + '<br>'
                        + 'Agent Phone Number :' + this.basicPolicyDetails.agentInfo.ACNM_TELE,
                        'source': 'EndorsePolicy'
                      }));
                  });
                }, (endorseVoidError) => {
                  this.isLoaded = true;
                  Tracker.logerror('MailingaddrAcceptComponent', 'Endorse Address', 'acceptAddressChange',
                    'Endorse Void Error.', endorseVoidError);
                });
              }
            }, (error) => {
              this.isLoaded = true;
              Tracker.logerror('MailingaddrAcceptComponent', 'Endorse Address', 'acceptAddressChange', 'Error endorse policy.', error);
            });
        } else {
              this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressAcceptScreen', 'MailingAddressChangeFailure'
                , 200);
              this.isLoaded = true;
              // Audit Log entry for Failure
              this.endorsementReq.activityResult = this.globlalConstants.AUDIT_LOG_ACITIVITY_RESULT_FAILURE;
              this.endorsementReq.activity = this.globlalConstants.AUDIT_LOG_ACITIVITY_ADDRESS_CHANGE;
              this.endorsementReq.activityMessage = 'Error while retrieving vehicle information';
              this.endorsementService.createAuditLogRecord(this.endorsementReq).subscribe(
                auditLogRes => {
                  CommonUtil.scrollToTop();
                  this.errorHandler.handleApplicationError(new BWError(
                    {
                      'errorcode': 'Endorsement', 'errordesc': MessageConstants.ADDR_AGENT_ERROR_MSG + '<br>' +
                      'Agent Name :' + this.basicPolicyDetails.agentInfo.AgentName1 + '<br>'
                      + 'Agent Phone Number :' + this.basicPolicyDetails.agentInfo.ACNM_TELE,
                      'source': 'EndorsePolicySaveError'
                    }));
              });
        }
    } catch (error) {
      Tracker.logerror('MailingaddrAcceptComponent', 'On Accept', 'endorse Address',
        'Error occurred.', error);
      this.isLoaded = true;
    }
  }

  cancelEndorsement() {
    this.googleAnalytics.trackEvent('MailingAddressChange', 'MailingAddressAcceptScreen', 'MailingAddressChangeCancelClick'
      , 200);
    this.submitted = true;
    this.errorHandler.ClearApplicationError();
    CommonUtil.scrollToTop();
    this.errorHandler.handleApplicationError(new BWError(
      {
        'errorcode': 'MailingAddressEdit', 'errordesc': MessageConstants.ADDR_CANCEL_MSG + '<br>' +
          'Agent Name :' + this.basicPolicyDetails.agentInfo.AgentName1 + '<br>'
          + 'Agent Phone Number :' + this.basicPolicyDetails.agentInfo.ACNM_TELE, 'source': 'isVehicleInThisLocation'
      }));
  }

  close(endorseModal: any) {
    this.errorHandler.ClearApplicationError();
    endorseModal.close();
  }
}
