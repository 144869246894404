import { Vehicles } from '../api/policyinfo/vehicle.model';
import { Lienholdercmpy } from '../api/policyholder/lienholdercmpy.model';

export class VehicleEndtInfoStore {  // TODO: This class needs to be deleted as we are no longer using it

    vinnumber: string;
    vehyear: string;
    vehmake: string;
    vehmodel: string;
    vehtype: string;
    rowveh: string;
    vehuse: string;
    isvehcustomized: string;
    doorCount: string;
    endtTransactionDt: string;
    compDeductible: string;
    colDeductible: string;
    towassistance: string;
    rental: string;
    glassdeductbuyback: string;
    leasedCompany: string;
    selectedLnCompany: Lienholdercmpy;

    // VIN attributes
    bodyStyle: string;
    type: string;
    subType: string;
    disablingDevice: string;
    passiveRestraint: string;
    antiLockBrake: string;
    costNew: string;
    isoCostSym: string;
    compSymb: string;
    collSymb: string;
    pdSymb: string;
    pipSymb: string;
    biSymb: string;
    umUIMSymb: string;
    vehicleSymb: string;
    isoSymb2: string;
    unitNum: string;
    // GarageAddress = new Address() { City = "MELBOURNE", Zip = "32924" }
    annualMileage: string;
    percentBusiness: string;
    vehicleRecovery: string;
    daytimeRunningLamps: string;
    customEquipCost: string;
    replacedVehUnitNum: string;

    // current vehicle to be replaced.
    replacedVehicle: Vehicles;

    constructor() {}

}
