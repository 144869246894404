/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./idcard.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import * as i4 from "../../../services/api/documents/documents.service";
import * as i5 from "../../services/bwstore.service";
import * as i6 from "../../services/analytics/google-analytics.service";
var styles_IdcardComponent = [];
var RenderType_IdcardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IdcardComponent, data: {} });
export { RenderType_IdcardComponent as RenderType_IdcardComponent };
function View_IdcardComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "app-loading"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "logoload"], ["style", "width:75px;height:75px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, ":svg:svg", [["class", "spinner"], ["style", "width:125px;height:125px;"], ["viewBox", "25 25 50 50"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, ":svg:circle", [["class", "path"], ["cx", "50"], ["cy", "50"], ["fill", "none"], ["r", "20"], ["stroke-miterlimit", "10"], ["stroke-width", "2"]], null, null, null, null, null))], null, null); }
function View_IdcardComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Close"]))], null, null); }
export function View_IdcardComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-body pb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IdcardComponent_1)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IdcardComponent_2)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.allowClose; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.allowClose; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.idCardMessage; _ck(_v, 3, 0, currVal_0); }); }
export function View_IdcardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-idcard", [], null, null, null, View_IdcardComponent_0, RenderType_IdcardComponent)), i0.ɵdid(1, 114688, null, 0, i2.IdcardComponent, [i3.NgbActiveModal, i4.DocumentsService, i5.BwstoreService, i6.GoogleAnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IdcardComponentNgFactory = i0.ɵccf("app-idcard", i2.IdcardComponent, View_IdcardComponent_Host_0, {}, {}, []);
export { IdcardComponentNgFactory as IdcardComponentNgFactory };
