import { OnDestroy, OnInit, AfterViewInit, PipeTransform, ElementRef, Renderer } from '@angular/core';
import { NgbDatepickerConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { TimeoutService } from './services/timeout.service';
import { GoogleAnalyticsService } from './shared/services/analytics/google-analytics.service';
import { AuthenticationService } from './shared/services/authentication.service';
import { Tracker } from './shared/utils/tracker';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CommonUtil } from './shared/utils/commonutil';
import { ChatService } from './shared/services/chat.service';
import { Chatparamreq } from './shared/model/api/chat/chatparamreq';
import { UsmsecurityService } from './services/api/usmsecurity/usmsecurity.service';
import { BwstoreService } from './shared/services/bwstore.service';
import { environment } from '../environments/environment';
import { DomSanitizer, Title, Meta } from '@angular/platform-browser';
import { MessagesService } from './shared/services/messages.service';
import 'core-js/es7/array';
import { GoogleSEOService } from './shared/services/googleseo.service';
var SafePipe = /** @class */ (function () {
    function SafePipe(sanitizer) {
        this.sanitizer = sanitizer;
    }
    SafePipe.prototype.transform = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    return SafePipe;
}());
export { SafePipe };
export var browserRefresh = false;
var AppComponent = /** @class */ (function () {
    /*@HostListener('window:beforeunload', ['$event']) unloadNotification($event: any) {
      }*/
    function AppComponent(router, modalService, ngbConfig, gaService, authService, timeoutService, usmSecurityService, _storeService, title, meta, chatService, renderer, route, seoService, msgservicec) {
        var _this = this;
        this.router = router;
        this.modalService = modalService;
        this.ngbConfig = ngbConfig;
        this.gaService = gaService;
        this.authService = authService;
        this.timeoutService = timeoutService;
        this.usmSecurityService = usmSecurityService;
        this._storeService = _storeService;
        this.title = title;
        this.meta = meta;
        this.chatService = chatService;
        this.renderer = renderer;
        this.route = route;
        this.seoService = seoService;
        this.msgservicec = msgservicec;
        this.isFeedbackClicked = false;
        this.routerUrl = '';
        this.commercialMCO = ['61', '62'];
        ngbConfig.minDate = { year: 1900, month: 1, day: 1 };
        ngbConfig.maxDate = { year: 2099, month: 12, day: 31 };
        // setting page title and meta stags for google SEO
        this.seoService.updateSEO();
        // Logic to check if browser refresh
        this.browserRefreshSubscription = router.events.subscribe(function (event) {
            if (event instanceof NavigationStart) {
                _this.bannerFlag = event.url === '/' ? true : false;
                _this.showPage = event.url.includes('/externalRedirect') || event.url.includes('zip=') ? false : true;
                browserRefresh = !router.navigated;
                _this.msgservicec.showbwcom.next(_this.showPage);
            }
            if (event instanceof NavigationEnd) {
                _this.showPage = event.url.includes('/externalRedirect') || event.url.includes('zip=') ? false : true;
                _this.msgservicec.showbwcom.next(_this.showPage);
            }
        });
        this.disableChatButton = false;
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.showPage = this.route.snapshot.queryParamMap.get('zip') ? false : true;
        this.mobileView = CommonUtil.findDeviceVIew().isMobileDevice();
        // enable GA then track the page views
        this.gaService.enableGA().then(function (res) {
            if (res) {
                // subscribe to the google analytics
                _this.gaService.subscribe();
            }
        }).catch(function (error) {
            Tracker.logerror('AppComponent', 'ngOnInit()', 'Enabling GA Tracking', 'Error Enabling GA Tracking', error);
        });
        this.authService.isUserLoggedIn.subscribe(function (authenticated) {
            _this.isUserLoggedIn = authenticated;
        });
        this.msgservicec.showbwcom.subscribe(function (val) {
            _this.showPage = val;
        });
        this.showChatButton();
        // Fallback Scenario when browser is refreshed
        if (this.isUserLoggedIn === undefined) {
            this.bwTokenFromSession = this.authService._bwToken();
            if (this.bwTokenFromSession !== undefined && this.bwTokenFromSession !== null && this.bwTokenFromSession !== '') {
                this.isUserLoggedIn = true;
                this.authService._setLoginStatus(true);
                // reset the showing the CHAT button once page refreshes
                if (this._storeService.getLoginInfoFromStore().riskstate !== undefined
                    // && this.stateStrArry.indexOf(this._storeService.getLoginInfoFromStore().riskstate ) > -1
                    && !this.commercialMCO.includes(this._storeService.getLoginInfoFromStore().pointHookID.substr(0, 2))) {
                    this.displayChat = true;
                }
                else {
                    this.displayChat = false;
                }
            }
        }
        this.router.events.forEach(function (event) {
            if (event instanceof NavigationEnd) {
                _this.routerUrl = _this.router.url;
                // console.log('this.routerUrl-' + this.routerUrl);
            }
            // NavigationEnd
            // NavigationCancel
            // NavigationError
            // RoutesRecognized
        });
    };
    AppComponent.prototype.ngAfterViewInit = function () {
        // this.createBwCookie();
    };
    AppComponent.prototype.ngOnDestroy = function () {
        // unsubscribe to the google analytics
        this.gaService.unsubscribe();
        this.timeoutService.unsubsribeTimeout();
        this.browserRefreshSubscription.unsubscribe();
    };
    AppComponent.prototype.onActivate = function (event) {
        window.scroll(0, 0);
    };
    AppComponent.prototype.getPage = function (outlet) {
        return outlet.activatedRouteData['page'] || 'one';
    };
    AppComponent.prototype.showFeedbackComp = function () {
        if (!this.modalRef) {
            this.modalRef = this.modalService.open(FeedbackComponent);
        }
    };
    AppComponent.prototype.skipLink = function () {
        this.renderer.invokeElementMethod(this.main.nativeElement, 'focus');
    };
    AppComponent.prototype.showFeedback = function () {
        var _this = this;
        this.modalService.open(FeedbackComponent).result.then(function (result) {
            _this.isFeedbackClicked = true;
            // console.log(`Closed with: ${result}`);
        }, function (reason) {
            if ("" + reason === 'Submit') {
                _this.isFeedbackClicked = true;
            }
            // console.log(`Dismissed ${reason}`);
        });
    };
    // This method is used to navigate mobile website
    AppComponent.prototype.goToMobileWeb = function (featureType) {
        var _this = this;
        this.isFeedbackClicked = true;
        this.authenticatedPhDetails = this._storeService.getLoginInfoFromStore();
        this.usmSecurityService.getSecurityToken(this.authenticatedPhDetails.sessionTicket).subscribe(function (response) {
            if (response != null) {
                _this.gaService.trackEvent('ViewPolicyDocumentsSSO', 'LoginSSOAttempt', 'LoginSSOAttemptSuccess', 200);
                window.location.href = "" + environment.cancelPolicyRelayStateURL + '?View=' + featureType + '&ssot=' + response.ssot;
            }
        }, function (error) {
            return null;
        });
    };
    AppComponent.prototype.showChat = function () {
        var _this = this;
        // Log to Google analytics
        this.gaService.trackEvent('Chat', 'ChatButtonHomeScreen', 'ChatButtonClick', 200);
        // the chat button will be disabled
        this.disableChatButton = true;
        // First get the token needed for next service call
        this.chatService.generateChatToken().subscribe(function (response) {
            if (response != null) {
                _this.chattokenres = response;
                console.log('Chat generate call resp is not null');
                _this.chatparamreq = new Chatparamreq();
                // Request params to call 2nd Chat API
                _this.chatparamreq.primaryInsuredFirstName__c = _this._storeService.getLoginInfoFromStore().firstName;
                _this.chatparamreq.primaryInsuredLastName__c = _this._storeService.getLoginInfoFromStore().lastName;
                _this.chatparamreq.policyContactNumber__c =
                    _this._storeService.getPolicyInfoFromStore().policyInfo.SYMBOL.concat(_this._storeService.getPolicyInfoFromStore().policyInfo.POLICY0NUM);
                _this.chatparamreq.access_token = _this.chattokenres.access_token;
                _this.chatparamreq.token_type = _this.chattokenres.token_type;
                _this.chatService.postChartParams(_this.chatparamreq, _this.chattokenres).subscribe(function (data) {
                    _this.chatparamres = data;
                    console.log('Chat param call:' + _this.chatparamres.success);
                    if (_this.chatparamres && _this.chatparamres.success) {
                        // Open chat window
                        _this.sourceURL = ("" + environment.srmChatWindowURL).concat(_this.chatparamres.id);
                        // default sizes
                        _this.w = 400;
                        _this.h = 440;
                        _this.win = window.open(_this.sourceURL, 'Chat_Window1', 'width=' + _this.w + ',height=' + _this.h);
                        // Check each 1 millisec whether chat window is closed, then enable the CHat button.
                        var chatInterval_1 = setInterval(function () {
                            if (_this.win !== null && _this.win.closed) {
                                _this.disableChatButton = false;
                                clearInterval(chatInterval_1);
                            }
                        }, 1000); // 1millisec
                    }
                    else {
                        // COULD NOT SAVE SRM CHAT PARAMS
                        console.log('FAILURE');
                    }
                }, function (error) {
                    return null;
                });
            }
        }, function (error) {
            return null;
        });
    };
    // Logic to when to show the Chat button
    AppComponent.prototype.showChatButton = function () {
        var _this = this;
        this.authService.phUserObject.subscribe(function (data) {
            _this.phUser = data;
            // show chat button only for 5 states and NOT for commercial policy(MCO=62)
            if (_this.phUser.riskstate !== undefined &&
                // this.stateStrArry.indexOf(this.phUser.riskstate ) > -1 &&
                _this.phUser.pointHookID !== undefined && !_this.commercialMCO.includes(_this.phUser.pointHookID.substr(0, 2))) {
                _this.displayChat = true;
            }
            else {
                _this.displayChat = false;
            }
        });
    };
    return AppComponent;
}());
export { AppComponent };
