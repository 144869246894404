import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

import { PaymentMethodAssetImgUrl, PaymentMethodTypeMapping, PaymentusIframePaymentMethodType } from '../../../constants/billing.constants';
import { GlobalConstants } from '../../../constants/global.constant';
import { Achpaymentreq } from '../../../shared/model/api/payment/achpaymentreq';
import { Bankdetailsres } from '../../../shared/model/api/payment/bankdetailsres.model';
import { Ccpaymentreq } from '../../../shared/model/api/payment/ccpaymentreq';
import { PaymentMethodInfo, PaymentMethodsModel, PMDetails, SortedPaymentArray } from '../../../shared/model/api/payment/payment-methods.model';
import { Paymentdetailsres } from '../../../shared/model/api/payment/paymentdetailsres';
import { PaymentusModel, PaymentusPayloadModel } from '../../../shared/model/api/payment/paymentus.model';

import { Previouspaymentreq } from '../../../shared/model/api/payment/previouspaymentreq';
import { BwappService } from '../../bwapp.service';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private globlalConstants = GlobalConstants;
  _baseURL: string;
  paymentDetailsRes: Paymentdetailsres;
  paymentMethods: PaymentMethodInfo[];
  private readonly CHECKING_ACCOUNT = 'CHQ';
  private readonly SAVINGS_ACCOUNT = 'SAV';
  private readonly CREDIT_CARD = 'CC';
  PM_DETAILS = 'pmDetails';
  paymentMethodFormValue$ = new Subject<PaymentMethodInfo>();

  constructor(private appService: BwappService, private http: HttpClient) { }

  previousPayment(previouspaymentreq: Previouspaymentreq) {
    // Tracker.log('IN PAYMENT SERVICE CCPAYMENT');
    return this.appService.post(this.globlalConstants.PREVIOUS_PAYMENT, previouspaymentreq);
  }

  achPayment(achpaymentreq: Achpaymentreq) {
    // Tracker.log('IN PAYMENT SERVICE ACH-PAYMENT');
    return this.appService.post(this.globlalConstants.ACH_PAYMENT, achpaymentreq);
  }

  ccPayment(ccpaymentreq: Ccpaymentreq) {
    // Tracker.log('IN PAYMENT SERVICE CCPAYMENT');
    return this.appService.post(this.globlalConstants.CC_PAYMENT, ccpaymentreq);
  }

  getPaymentSchedule(strZip: string, strPolicySym: string, strPolicyNum: string): Observable<Paymentdetailsres> {

    // Tracker.log('Getting payment schedule for policy number ' + strPolicySym);
    const params = new HttpParams()
      .set('szZipcode', strZip)
      .set('szPolicySymbol', strPolicySym)
      .set('szPolicyNumber', strPolicyNum);

    return this.appService.get(this.globlalConstants.GET_PAYMENTSCHEDULE, params);
  }

  getPaymentProfiles(accountNumber: string): Observable<PaymentMethodsModel> {
    return this.appService.getPaymentProfiles(this.globlalConstants.GET_PAYMENT_PROFILES, accountNumber);
  }

  setPaymentMethods(accountNumber: string): Observable<PaymentMethodInfo[]> {
    return this.getPaymentProfiles(accountNumber).pipe(
      map((customer) => {
        const methods = customer['data']['customer']['paymentMethods'];
        if (methods && methods.length) {
          this.paymentMethods = methods.map((paymentMethod) => {
            const paymentMethodInfo: PaymentMethodInfo = {
              bankName: paymentMethod.bankName,
              accountHolderName: paymentMethod.accountHolderName,
              accountNumber: paymentMethod.accountNumber.slice(-4),
              expiryMonth: paymentMethod.expiryMonth,
              expiryYear: paymentMethod.expiryYear,
              isDefault: paymentMethod.recurringEnabled,
              type: paymentMethod.type,
              paymentusIframePaymentType: PaymentMethodTypeMapping[paymentMethod.type],
              paymentToken: paymentMethod.paymentToken,
              bankRoutingNumber:paymentMethod.bankRoutingNumber,
            };

            return this.populatePaymentMethodAdditionalValues(paymentMethodInfo);
          });
          this.paymentMethods = this.sortPaymentMethods(this.paymentMethods);
          return this.paymentMethods;
        }
      })
    )
  }

  populatePaymentMethodAdditionalValues(paymentMethodInfo: PaymentMethodInfo): PaymentMethodInfo {
    let expirationDate = null;
    let imageUrl, imageAlt;
    const accountNumber = paymentMethodInfo.accountNumber;
    const expiryMonth = paymentMethodInfo.expiryMonth;
    const expiryYear = paymentMethodInfo.expiryYear;
    const type = paymentMethodInfo.type;
    const paymentusIframePaymentType = PaymentMethodTypeMapping[type]
    const bankName = paymentMethodInfo.bankName
    const hasExpirationDate = expiryMonth && expiryYear;
    const isBankAccount =
      accountNumber &&
      type &&
      paymentusIframePaymentType === PaymentusIframePaymentMethodType.DIRECT_DEBIT;
    const isCard =
      !isBankAccount &&
      accountNumber &&
      hasExpirationDate &&
      type &&
      (paymentusIframePaymentType === PaymentusIframePaymentMethodType.CREDIT_CARD ||
        paymentusIframePaymentType === PaymentusIframePaymentMethodType.DEBIT_CARD);
    if (PaymentMethodAssetImgUrl[type].url)
      imageUrl = PaymentMethodAssetImgUrl[type].url;
    if (PaymentMethodAssetImgUrl[type].adaLabel)
      imageAlt = PaymentMethodAssetImgUrl[type].adaLabel;
    if (hasExpirationDate) {
      expirationDate = `${expiryMonth}/${expiryYear.slice(-2)}`;
    }
    const detailsContent = isCard ? expirationDate : bankName;
    const accountHolderNameTitle = isCard ? 'Name on card' : 'Account holder';
    const detailsTitle = isCard ? 'Expires' : 'Bank Name';

    return {
      isCard,
      isBankAccount,
      imageUrl,
      imageAlt,
      accountHolderNameTitle,
      detailsContent,
      detailsTitle,
      ...paymentMethodInfo,
    };
  }

  validatePolicy(strZip: string, strPolicySym: string, strPolicyNum: string): Observable<Paymentdetailsres> {

    // Tracker.log('validatePolicy for policy number ' + strPolicySym);
    const params = new HttpParams()
      .set('szZipcode', strZip)
      .set('szPolicySymbol', strPolicySym)
      .set('szPolicyNumber', strPolicyNum);

    return this.appService.get(this.globlalConstants.GET_VALIDATEPOLICY, params);
  }

  validatePolicyDetails(strPolicySym: string, strPolicyNum: string, strZip: string, strLastName: string, strDOB: string,):
    Observable<Paymentdetailsres> {

    // Tracker.log('validatePolicy for policy number ' + strPolicySym);
    const params = new HttpParams()
      .set('szSymbol', strPolicySym)
      .set('szPolicyNumber', strPolicyNum)
      .set('szZipCode', strZip)
      .set('szLastName', strLastName)
      .set('szBirthDate', strDOB);

    return this.appService.get(this.globlalConstants.GET_VALIDATEPOLICYDETAILS, params);
  }

  getBankDetails(routingNumber: string): Observable<Bankdetailsres> {
    const params = new HttpParams()
      .set('routingNumber', routingNumber);
    return this.appService.get(this.globlalConstants.GET_BANK_DETAILS, params);
  }

  sortPaymentMethods(paymentMethods: PaymentMethodInfo[]): PaymentMethodInfo[] {
    let sortedMethodsObject: SortedPaymentArray = {
      checkingBankAccounts: [],
      savingsBankAccounts: [],
      businessBankAccounts: [],
      debitCardMethods: [],
      creditCardMethods: [],
      otherPaymentMethods: [],
    };
    paymentMethods.forEach((paymentMethod) => {
      sortedMethodsObject = this.insertPaymentMethodBasedOnType(paymentMethod, sortedMethodsObject);
    });
    return [
      ...sortedMethodsObject.checkingBankAccounts,
      ...sortedMethodsObject.savingsBankAccounts,
      ...sortedMethodsObject.businessBankAccounts,
      ...sortedMethodsObject.debitCardMethods,
      ...sortedMethodsObject.creditCardMethods,
      ...sortedMethodsObject.otherPaymentMethods,
    ];
  }

  insertPaymentMethodBasedOnType(paymentMethod: PaymentMethodInfo, sortedPaymentMethods: SortedPaymentArray): SortedPaymentArray {
    if (paymentMethod.isBankAccount) {
      switch (paymentMethod.type) {
        case this.CHECKING_ACCOUNT:
          sortedPaymentMethods.checkingBankAccounts.push(paymentMethod);
          break;
        case this.SAVINGS_ACCOUNT:
          sortedPaymentMethods.savingsBankAccounts.push(paymentMethod);
          break;
        default:
          sortedPaymentMethods.businessBankAccounts.push(paymentMethod);
      }
    } else if (paymentMethod.isCard) {
      switch (paymentMethod.paymentusIframePaymentType) {
        case this.CREDIT_CARD:
          sortedPaymentMethods.creditCardMethods.push(paymentMethod);
          break;
        default:
          sortedPaymentMethods.debitCardMethods.push(paymentMethod);
      }
    } else {
      sortedPaymentMethods.otherPaymentMethods.push(paymentMethod);
    }
    return sortedPaymentMethods;
  }

  getPaymentusIframe(payload: PaymentusPayloadModel): Observable<PaymentusModel> {
    let paymentusIframeUrl: string = environment.paymentUsIframeDomainUrl;
    return this.appService.post(this.globlalConstants.PAYMENTUS_IFRAME, payload)
      .pipe(map((response) => ({ url: paymentusIframeUrl + response.encryptedMessage, paymentType: payload.pmCategory })));
  }

  parsePMDetailsMessageFromIframe(paymentMethodDetails: string): PMDetails {
    if (!paymentMethodDetails) {
      return null;
    }
    const pmDetailsObjectString = paymentMethodDetails.substring(
      this.PM_DETAILS.length + paymentMethodDetails.indexOf(this.PM_DETAILS) + 1,
    );
    const pmDetailsObject = JSON.parse(pmDetailsObjectString);
    return {
      CardHolderName: pmDetailsObject.CardHolderName,
      BankName: pmDetailsObject.BankName,
      token: pmDetailsObject.Token,
      type: pmDetailsObject.Type,
      maskedAccountNumber: pmDetailsObject.MaskedAccountNumber,
      expiryDate: pmDetailsObject.ExpiryDate,
    };
  }

  buildPaymentMethodInfo(paymentMethodDetails: PMDetails): PaymentMethodInfo {
    if (!paymentMethodDetails) {
      return null;
    }
    let expiryMonth = null;
    let expiryYear = null;
    const paymentType = PaymentMethodTypeMapping[paymentMethodDetails.type];
    if (
      paymentType === PaymentusIframePaymentMethodType.CREDIT_CARD ||
      paymentType === PaymentusIframePaymentMethodType.DEBIT_CARD
    ) {
      expiryMonth = paymentMethodDetails.expiryDate.slice(0, 2);
      expiryYear = paymentMethodDetails.expiryDate.slice(-4);
    }

    const paymentMethodInfo: PaymentMethodInfo = {
      accountHolderName: paymentMethodDetails.CardHolderName,
      bankName: paymentMethodDetails.BankName,
      accountNumber: paymentMethodDetails.maskedAccountNumber.slice(-4),
      expiryMonth,
      expiryYear,
      isDefault: false,
      type: paymentMethodDetails.type,
      paymentusIframePaymentType: PaymentMethodTypeMapping[paymentMethodDetails.type],
      paymentToken: paymentMethodDetails.token,
    };

    return this.populatePaymentMethodAdditionalValues(paymentMethodInfo);
  }

  resetPaymentMethodsIsDefaultAndAddNew(
    paymentMethods: PaymentMethodInfo[],
    paymentMethodInfo: PaymentMethodInfo,
  ): PaymentMethodInfo[] {
    if (!paymentMethodInfo) {
      return;
    }
    if (paymentMethods && paymentMethods.length) {
      const index = paymentMethods
        .map((paymentDetails: PaymentMethodInfo) => paymentDetails.paymentToken)
        .indexOf(paymentMethodInfo.paymentToken);
      if (index > -1) {
        paymentMethods[index] = paymentMethodInfo;
      } else {
        paymentMethods.push(paymentMethodInfo);
      }
    } else {
      paymentMethods = [];
      paymentMethods.push(paymentMethodInfo);
    }
    this.paymentMethodFormValue$.next(paymentMethodInfo);
    return paymentMethods;
  }

}
